import React, { useState, useReducer, useMemo } from "react";

import Button from "components/Button";
import Checkbox from "b2b/components/Checkbox";
import Radio from "b2b/components/Radio";
import InfoPane from "b2b/components/InfoPane";

const reducer = (prev, action) => {
  switch (action.type) {
    default:
      return prev;
    case "set":
      return { ...prev, [action.key]: action.value };
    case "update":
      return {
        ...prev,
        [action.key]: prev[action.key]?.includes(action.value)
          ? prev[action.key]?.filter(item => item != action.value)
          : prev[action.key]?.concat(action.value)
      };
  }
};

const UpdateAccessibilityContent = ({ accessibilities, options = [], updateAccessibilities, close }) => {
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const [selected, dispatch] = useReducer(
    reducer,
    Object.assign({}, ...Object.keys(accessibilities).map(key => ({ [key]: accessibilities[key]?.selected_option })))
  );

  const questions = useMemo(() => {
    return Object.assign({}, ...options?.map(item => ({ [item.slug]: item })));
  }, [options]);

  const handleUpdate = () => {
    setProcessing(true);
    updateAccessibilities(selected).then(error => {
      setProcessing(false);
      if (error) {
        setErrors(error);
      } else close();
    });
  };

  const handleOption = e => {
    dispatch({ type: "set", key: e.target.name, value: e.target.value });
  };

  const handleCheckbox = e => {
    dispatch({ type: "update", key: e.target.name, value: e.target.value });
  };

  const disableSubmit = Object.keys(selected).some(key => !selected?.[key]);

  return (
    <>
      <div className="fz-text fz-text_md fz-text-color__black mb-1">{questions?.di_policy?.question}</div>
      <InfoPane.Group gray className="mb-2">
        {questions?.di_policy?.options?.map(item => (
          <InfoPane.Item
            key={item.description}
            className="m-0 p-2"
            customTitle={
              <Radio
                id={`di_policy_option_${item.value}`}
                name="di_policy"
                value={item.value}
                checked={item.value == selected?.di_policy}
                onChange={handleOption}
              >
                <span className="fz-text-color__black">{item.name}</span>
              </Radio>
            }
          />
        ))}
      </InfoPane.Group>
      {errors.di_policy && (
        <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{errors.di_policy}</div>
      )}

      <div className="fz-text fz-text_md fz-text-color__black mb-1">{questions?.organization_has?.question}</div>
      <InfoPane.Group gray className="mb-2">
        {questions?.organization_has?.options?.map(item => (
          <InfoPane.Item
            key={`organization_has_${item.value}`}
            className="m-0 p-2"
            customTitle={
              <Checkbox
                id={`organization_has_${item.value}`}
                name="organization_has"
                value={item.value}
                checked={selected?.organization_has?.includes(item.value)}
                onChange={handleCheckbox}
              >
                <span className="fz-text-color__black">{item.name}</span>
              </Checkbox>
            }
          />
        ))}
      </InfoPane.Group>
      {errors.organization_has && (
        <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{errors.organization_has}</div>
      )}

      {[
        "public_transport_accessible",
        "manually_operated_machinery",
        "inaccessible_software",
        "client_communication_required"
      ].map(key => (
        <>
          <div className="fz-text fz-text_md fz-text-color__black mb-1">{questions?.[key]?.question}</div>
          <InfoPane.Group gray className="mb-2">
            {questions?.[key]?.options?.map(item => (
              <InfoPane.Item
                key={item.description}
                className="m-0 p-2"
                customTitle={
                  <Radio
                    id={`${key}_option_${item.value}`}
                    name={key}
                    value={item.value}
                    checked={item.value == selected?.[key]}
                    onChange={handleOption}
                  >
                    <span className="fz-text-color__black">{item.name}</span>
                  </Radio>
                }
              />
            ))}
          </InfoPane.Group>
          {errors[key] && (
            <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{errors[key]}</div>
          )}
        </>
      ))}

      <div className="fz-modal__actions mt-0">
        <Button disabled={processing} color="gray" onClick={close}>
          Cancel
        </Button>
        <Button disabled={processing || disableSubmit} color="blue" onClick={handleUpdate}>
          Save
        </Button>
      </div>
    </>
  );
};

export default UpdateAccessibilityContent;
