import React, { useState } from "react";

import { Modal, Title, Description, Actions, Cancel, Remove } from "./styled";
import { useModal } from "../../../../../utils/hooks";
import IntegrationSuccessModal from "../IntegrationSuccessModal";

const RemovePromotion = ({ onRemove, id, close, getIntegrations }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const showSuccessModal = useModal(
    <IntegrationSuccessModal
      getIntegrations={getIntegrations}
      title="Post will be removed"
      description="We've sent a request to remove the post from the job board. Once they're processed, we'll update the Promotions tab and the post details. "
    />,
    ""
  );

  const handleRemove = () => {
    setIsDisabled(true);
    onRemove(id).then(() => {
      close();
      showSuccessModal();
    });
  };

  return (
    <Modal>
      <Title>Remove this promotion?</Title>
      <Description>
        This will immediately remove the job from the job board. <br />
        You can repost it if needed while the job is still open for applications
      </Description>
      <Actions>
        <Cancel onClick={close}>Cancel</Cancel>
        <Remove onClick={handleRemove} disabled={isDisabled}>
          Yes, remove
        </Remove>
      </Actions>
    </Modal>
  );
};

export default RemovePromotion;
