import styled from "styled-components";

export const Modal = styled.div`
  width: 100%;
  max-width: 540px;
  margin-top: -32px;

  @media screen and (min-width: 1008px) {
    max-width: 720px;
    width: 720px;
  }
`;

export const Title = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  margin: 0 0 24px 0;
`;

export const Description = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 8px;

  a {
    color: #408bfc;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 16px;
  }
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;
export const Add = styled.button`
  background: #408bfc;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  padding: 10px 40px;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const QuestionContainer = styled.div`
  padding-bottom: 24px;
  //border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

export const QuestionContent = styled.div`
  max-height: 468px;
  overflow-y: auto;
  padding-right: 20px;
`;

export const Tile = styled.div`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .fz-checkbox__label:before {
    background-color: #fff;
  }

  .fz-checkbox__input:checked ~ .fz-checkbox__label::before {
    background-color: #4690ff;
    border-color: #4690ff;
  }
`;

export const TileType = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;

  svg {
    width: 24px;
    height: 24px;
    fill: #666666;
  }
`;

export const TileTitle = styled.h6`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 8px 0;
`;

export const TileAnswers = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  flex-wrap: wrap;
`;

export const TileAnswersItem = styled.span`
  display: block;
  border-radius: 4px;
  background: #f3f8ff;
  padding: 2px 8px;
  color: #062c66;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  height: 24px;
  max-width: 640px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DescriptionLabel = styled.span`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  margin: 16px 0 4px;
`;

export const DescriptionAnswer = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0;
`;

export const SelectContainer = styled.div`
  margin-bottom: 24px;

  .fz-simple-select__control {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #c5c5c5;
    background: #fff;
    height: 52px;

    svg {
      fill: #656565;
    }
  }

  .fz-simple-select__placeholder,
  .fz-simple-select__single-value {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .fz-simple-select__menu {
    width: 100%;
    max-width: 100%;
  }

  .fz-simple-select {
    .fz-simple-select__option {
      padding: 12px;
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      background-color: transparent;

      &:hover {
        color: #666;
      }
    }

    .fz-simple-select__option--is-focused {
      color: #408bfc;

      &:hover {
        color: #408bfc;
      }
    }
  }
`;

export const SelectTitle = styled.h3`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 8px 0;
  padding-top: 24px;
  border-top: 1px solid #eee;
`;
