import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { MdThumbUp, MdThumbDown } from "react-icons/md";
import Button from "components/Button";

const VoteCounter = ({
  id,
  containerClassName,
  fullLayout,
  onLike,
  onDislike,
  voting: { like_count = 0, dislike_count = 0, is_like = false, is_dislike = false } = {}
}) => {
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);

  useEffect(() => {
    setLike(is_like);
    setDislike(is_dislike);
  }, [is_like, is_dislike, id]);

  const onLikeClickhandle = () => {
    setLike(!like);
    if (is_dislike || dislike) {
      onDislike();
      setDislike(false);
    }
    onLike();
  };

  const onDisikeClickhandle = () => {
    setDislike(!dislike);
    if (is_like || like) {
      onLike();
      setLike(false);
    }
    onDislike();
  };

  const wrapperClassNames = cn(
    "fz-like-counter",
    {
      "fz-like-counter_full": fullLayout
    },
    containerClassName
  );
  const likeButtonClassNames = useMemo(() => (like ? "fz-like-counter-btn_active" : ""), [like]);
  const likeButtonColor = useMemo(() => (like ? "green" : "gray"), [like]);
  const dislikeButtonClassNames = useMemo(() => (dislike ? "fz-like-counter-btn_active" : ""), [dislike]);
  const dislikeButtonColor = useMemo(() => (dislike ? "red" : "gray"), [dislike]);

  return (
    <div className={wrapperClassNames}>
      <div className="fz-like-counter__container">
        <div className="fz-like-counter-btn">
          <Button
            icon={<MdThumbUp />}
            className={likeButtonClassNames}
            size="sm"
            color={likeButtonColor}
            onClick={onLikeClickhandle}
            tabIndex="0"
            role="button"
          >
            <>
              {fullLayout && "Like"}
              <span className="fz-like-counter-btn__count">{like_count + like - is_like}</span>
            </>
          </Button>
        </div>
        <div className="fz-like-counter-btn">
          <Button
            icon={<MdThumbDown />}
            className={dislikeButtonClassNames}
            size="sm"
            color={dislikeButtonColor}
            onClick={onDisikeClickhandle}
            tabIndex="0"
            role="button"
          >
            <>
              {fullLayout && "Dislike"}
              <span className="fz-like-counter-btn__count">{dislike_count + dislike - is_dislike}</span>
            </>
          </Button>
        </div>
      </div>
    </div>
  );
};

VoteCounter.propTypes = {
  containerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullLayout: PropTypes.bool,

  onLike: PropTypes.func,
  onDislike: PropTypes.func,

  voting: PropTypes.shape({
    like_count: PropTypes.number,
    dislike_count: PropTypes.number,
    is_like: PropTypes.bool,
    is_dislike: PropTypes.bool
  })
};

VoteCounter.defaultProps = {
  containerClassName: "",
  fullLayout: false,
  voting: {
    like_count: 0,
    dislike_count: 0,
    is_like: false,
    is_dislike: false
  }
};

export default VoteCounter;
