import styled from "styled-components";

export const Content = styled.div``;

export const Tile = styled.div`
  border-bottom: 1px solid #eee;
  background: #fff;
  padding: 0 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const TileTitle = styled.h6`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 4px 0;
`;

export const TileType = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 16px 0;

  svg {
    width: 24px;
    height: 24px;
    fill: #666666;
  }
`;

export const TileDescriptionLabel = styled.span`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin: 0 0 4px 0;
`;

export const TileDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const TileAnswers = styled.div`
  margin-top: 16px;
`;

export const TileAnswersItem = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isEmpty }) => (isEmpty ? "#666" : "#333")};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
