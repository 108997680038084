import React from "react";
import { MdDone, MdDoneAll } from "react-icons/md";

import { withTooltip } from "components/Tooltip";

const SkillTag = ({ className = "fz-tag", text = "", check = 0 }) => {
  return (
    <div className={className}>
      <span className="fz-tag__text">{text}</span>
      {check === 1 && <MdDone className="fz-tag__icon" />}
      {check === 2 && <MdDoneAll className="fz-tag__icon" />}
    </div>
  );
};

export default withTooltip(SkillTag);
