import React from "react";

import Button from "components/Button";
import ContactDetails from "../../../../components/ContactDetails";

const ApplicantModalContent = ({
  close,
  state,
  email,
  phone,
  facebook,
  linkedin,
  twitter,
  website,
  callback,
  nationalId
}) => {
  switch (state) {
    default:
      return <></>;
    case "Contact":
      return (
        <ContactDetails
          email={email}
          phone={phone}
          facebook={facebook}
          linkedin={linkedin}
          twitter={twitter}
          website={website}
          nationalId={nationalId}
        />
      );
    case "Shortlist":
      return (
        <>
          <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">
            You can only view contact details of shortlisted candidates.
          </div>
          <div className="fz-modal__actions">
            <Button size="md" color="gray" onClick={() => close()}>
              Cancel
            </Button>
            <Button
              size="md"
              color="yellow"
              onClick={() => {
                callback();
                close();
              }}
            >
              {state}
            </Button>
          </div>
        </>
      );
    case "Reject":
    case "Remove":
      return (
        <>
          <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">
            Are you sure you want to {state.toLowerCase()} this candidate?
          </div>
          <div className="fz-modal__actions">
            <Button size="md" color="gray" onClick={() => close()}>
              Cancel
            </Button>
            <Button
              size="md"
              color="error"
              onClick={() => {
                callback();
                close();
              }}
            >
              {state}
            </Button>
          </div>
        </>
      );
  }
};

export default ApplicantModalContent;
