import React, { useMemo, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdArrowBack, MdArrowForward, MdClose, MdLock } from "react-icons/md";

import AppContext from "contexts/init";
import Button from "components/Button";

const UserCvNavBar = ({
  title,
  next,
  prev,
  loading = false,
  current = 1,
  total = 1,
  getNextList,
  getPrevList,
  sortedBy,
  filterCount = 0,
  isSharedCandidates,
  filterScope,
  handleChangeTabToProfile,
  commentsCount,
  clearAllComments
}) => {
  const {
    subscription: {
      limits: { unlocked_profiles_count = 0, unlock_profiles_limit }
    }
  } = isSharedCandidates
    ? {
        subscription: {
          limits: { unlocked_profiles_count: 0, unlock_profiles_limit: 0 }
        }
      }
    : useContext(AppContext);

  const { pathname } = useLocation();
  const path_mod = pathname.slice(0, pathname.lastIndexOf("/"));

  const backPathname = isSharedCandidates ? path_mod.replace("/candidates", "") : path_mod;

  const unlocksLeft = useMemo(
    () => Math.max(unlock_profiles_limit - unlocked_profiles_count, 0),
    [unlocked_profiles_count]
  );

  const handleNext = e => {
    if (handleChangeTabToProfile) {
      handleChangeTabToProfile();
    }

    if (commentsCount && clearAllComments) {
      clearAllComments();
    }
    if (current === total || loading) e.preventDefault();
    else if (!next) {
      e.preventDefault();
      getNextList();
    }
  };

  const handlePrev = e => {
    if (handleChangeTabToProfile) {
      handleChangeTabToProfile();
    }

    if (commentsCount && clearAllComments) {
      clearAllComments();
    }

    if (current === 1 || loading) e.preventDefault();
    else if (!prev) {
      e.preventDefault();
      getPrevList();
    }
  };

  const getBackPathname = useMemo(() => {
    return filterScope ? `${backPathname}?filters[scope]=${filterScope}` : backPathname;
  }, [filterScope]);

  return (
    <section className="fz-pane fz-pane_with-shadow mt-4 navigator fz-navigation-sticky-container">
      <div className="fz-pane__container fz-pane__container_case-two">
        <div className="fz-navigation-bar">
          {isSharedCandidates ? (
            <div className="fz-navigation-bar__left">
              <Link to={backPathname} tabIndex="0">
                <Button.Iconed
                  color="transparent"
                  tag="span"
                  style={{ width: "100%", fontSize: "14px", color: "#666666", fontWeight: 400 }}
                >
                  <MdArrowBack /> Back to Candidates list
                </Button.Iconed>
              </Link>
            </div>
          ) : (
            <>
              <div className="fz-navigation-bar__left">
                <p className="fz-text fz-text_lg fz-text-color__grey900 mb-0 mr-2">{title}</p>
                {sortedBy && (
                  <div className="fz-text_normal fz-text_s fz-text-color__grey900 mr-2">
                    by <b>{sortedBy}</b>
                  </div>
                )}
                {filterCount > 0 && (
                  <div className="fz-text_normal fz-text_s fz-text-color__secondary mr-2">
                    <b>{filterCount}</b> filters applied
                  </div>
                )}
              </div>
              <div className="fz-navigation-bar__center">
                <Link
                  onClick={handlePrev}
                  to={{
                    pathname: `${path_mod}/${prev}`,
                    index: current - 1
                  }}
                  tabIndex="0"
                >
                  <Button icon={<MdArrowBack />} size="md" disabled={current < 2} tag="span">
                    Previous
                  </Button>
                </Link>

                <p className="fz-text fz-text_md fz-text-color__grey900 mb-0 mr-2 ml-2">
                  {current || 1} of {total || 1}
                </p>

                <Link
                  onClick={handleNext}
                  to={{
                    pathname: `${path_mod}/${next}`,
                    index: current + 1
                  }}
                  tabIndex="0"
                >
                  <Button
                    icon={<MdArrowForward />}
                    iconAlign="right"
                    size="md"
                    disabled={current === total || !current}
                    tag="span"
                  >
                    Next
                  </Button>
                </Link>
              </div>
            </>
          )}

          <div className="fz-navigation-bar__right">
            {!isSharedCandidates && (
              <Button
                className="mr-1"
                size="md"
                color="gray"
                icon={<MdLock />}
                style={{ pointerEvents: "none" }}
                role="button"
                tabIndex="-1"
              >
                Unlocks left: {unlocksLeft}
              </Button>
            )}
            <Link to={getBackPathname} tabIndex="0">
              <Button.Iconed color="gray" tag="span">
                <MdClose />
              </Button.Iconed>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserCvNavBar;
