import styled from "styled-components";

export const KeysContent = styled.div`
  margin: 24px 0 0 0;
`;

export const Title = styled.div`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const Heading = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: #f3f3f3;
`;

export const HeadingItem = styled.span`
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex: none;
  width: 128px;

  &:nth-child(3) {
    width: 240px;
  }

  &:nth-child(3) {
    width: 240px;
  }

  &:nth-child(6) {
    width: ${({ $width }) => $width || "214px"};
  }
`;

export const Tile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
`;

export const TileItem = styled.div`
  flex: none;
  width: 128px;

  &:nth-child(3) {
    width: 240px;
  }

  &:nth-child(6) {
    width: ${({ $width }) => $width || "214px"};
  }
`;

export const TileText = styled.div`
  color: ${({ $color }) => $color || "#666"};
  font-size: ${({ $fontSize }) => $fontSize || "#14px"};
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const ButtonDelete = styled.button`
  background: #f3f3f3;
  padding: 2px 2px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #444;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  border: none;
  flex: none;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    flex: none;
    fill: #444;
  }

  &:hover {
    background: #eb3737;
    svg {
      fill: #fff;
    }
  }
`;

export const CreateToke = styled.button`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  background: #2496f5;
  text-align: center;
`;

export const EmptyState = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px 0;
  margin: 16px 0;
  border: 1px solid #eee;
  border-radius: 8px;

  svg {
    width: 52px;
    height: 52px;
  }

  span {
    color: #333;
    font-size: 14px;
    font-weight: 500;
  }
`;
