import styled from "styled-components";

export const Main = styled.div`
  .rdw-editor-wrapper {
    display: flex;
    flex-direction: column;
  }

  .rdw-editor-toolbar {
    padding: 0;
    border: none;
    margin: 0;
    display: flex;
    gap: 8px;
  }

  .rdw-inline-wrapper,
  .rdw-link-wrapper,
  .rdw-list-wrapper {
    display: flex;
    margin: 0;
    gap: 8px;
  }

  .rdw-link-wrapper,
  .rdw-link-wrapper {
    margin: 0;
  }

  .rdw-link-wrapper {
    position: relative;
  }

  .rdw-option-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #f3f3f3;
    min-width: 24px;
    width: 24px;
    height: 24px;

    img {
      max-width: 12px;
      filter: invert(38%) sepia(0%) saturate(183%) hue-rotate(182deg) brightness(102%) contrast(88%);
    }

    &:hover {
      box-shadow: none;
    }

    &.rdw-option-active {
      background: #e0edff;
      box-shadow: none;
      img {
        filter: brightness(0) saturate(100%) invert(40%) sepia(99%) saturate(579%) hue-rotate(184deg) brightness(104%)
          contrast(98%);
      }
    }
  }

  .public-DraftEditorPlaceholder-root {
    color: #a4a4a4;
    position: absolute;
    z-index: 0;
    top: 13px;
  }

  // .rdw-link-wrapper,
  .rdw-emoji-wrapper,
  .rdw-block-wrapper,
  .rdw-fontsize-wrapper,
  .rdw-fontfamily-wrapper,
  .rdw-text-align-wrapper,
  .rdw-colorpicker-wrapper,
  .rdw-embedded-wrapper,
  .rdw-image-wrapper,
  .rdw-remove-wrapper,
  .rdw-history-wrapper,
  .rdw-editor-wrapper .rdw-list-wrapper .rdw-option-wrapper[title="Indent"],
  // .rdw-editor-wrapper .rdw-link-wrapper .rdw-option-wrapper[title="Unlink"],
  .rdw-editor-wrapper .rdw-list-wrapper .rdw-option-wrapper[title="Outdent"],
  // .rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Strikethrough"],
  .rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Monospace"],
  .rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Superscript"],
  .rdw-editor-wrapper .rdw-inline-wrapper .rdw-option-wrapper[title="Subscript"] {
    display: none;
  }

  .rdw-link-modal {
    width: 280px;
    padding: 16px;
    height: 250px;
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid #eee;
    top: -45px;
    left: -193px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
  }

  .rdw-link-modal-input {
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    background: #fff;
    padding: 14px;
    margin-bottom: 16px;
    height: 32px;
    margin-top: 0;
    width: 100%;
  }

  .rdw-link-modal-label {
    color: #333;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
  }

  .rdw-link-modal-target-option {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;
  }

  .rdw-link-modal-target-option input {
    margin-top: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .rdw-link-modal-target-option span {
    color: #333;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
  }

  .rdw-link-modal-buttonsection {
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
    gap: 16px;
  }

  .rdw-link-modal-btn {
    border-radius: 8px;
    background: transparent;
    color: #408bfc;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    margin: 0;
    width: 46px;
    height: 30px;

    &:disabled {
      opacity: 0.4;
    }

    &:hover {
      box-shadow: none;
    }

    &:first-child {
      background: #408bfc;
      color: #fff;
    }
  }

  .public-DraftStyleDefault-block {
    margin: 12px 0;
  }

  .rdw-link-modal_button-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .editor-content {
    .rdw-link-decorator-wrapper {
      a {
        color: #408bfc;
      }
    }
  }

  .DraftEditor-root {
    min-height: 80px;
    max-height: 200px;
    overflow-y: auto;
    position: relative;
  }
`;
