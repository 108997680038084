import React, { useState, useMemo } from "react";
import { MdSearch, MdClose, MdPinDrop, MdPermIdentity } from "react-icons/md";
import { CheckCircle } from "@styled-icons/boxicons-solid";

import Button from "components/Button";
import Tooltip from "../../../components/Tooltip";

const CampaignsModalContent = ({
  handleSelectedAvailableJobForCandidate,
  availableJobsForCandidate: campaigns,
  inCampaigns = [],
  handleInviteToApply,
  handleClose
}) => {
  const [term, setTerm] = useState("");

  const sorted = useMemo(() => {
    return campaigns
      .map(campaign => ({ ...campaign, includes: inCampaigns.includes(campaign.id) }))
      .filter(campaign => campaign.title?.toLowerCase()?.includes(term?.toLowerCase()));
  }, [campaigns, inCampaigns, term]);

  return (
    <>
      <div className="fz-std-input fz-std-input_sm mb-2">
        <div className="fz-std-input__field">
          <MdSearch className="fz-std-input__icon" />
          <input
            type="text"
            placeholder="Campaign title"
            onChange={e => setTerm(e.target.value)}
            value={term}
            className="fz-std-input__control"
          />
        </div>
        <div role="button" tabIndex={-1} className="fz-campaign-searchbar__clear" onClick={() => setTerm("")}>
          <Button.Simple size="xs" icon={<MdClose fill="#C5C5C5" />} color="gray" className="fadeInFromLeft" />
        </div>
      </div>
      <div
        tabIndex="0"
        role="button"
        className="fz-modal__close"
        onClick={handleClose}
        aria-label="Close"
        style={{ position: "absolute", right: "10px", top: "-40px" }}
      >
        <MdClose />
      </div>
      <div className="headhunting_modal main">
        <div className="campaign-list">
          {sorted.length > 0 ? (
            sorted.map(campaign => (
              <div
                role="button"
                tabIndex={-1}
                className="campaign campaign_hover"
                style={{
                  pointerEvents: campaign.available_for_candidate ? "default" : "none"
                }}
                key={campaign.id}
                onClick={() => {
                  handleSelectedAvailableJobForCandidate(campaign);
                  handleInviteToApply();
                }}
              >
                <div
                  className="info ml-1"
                  style={{
                    opacity: campaign.available_for_candidate ? "1" : "0.5",
                    pointerEvents: campaign.available_for_candidate ? "default" : "none"
                  }}
                >
                  <h5>{campaign.title}</h5>
                  <div className="fz-data-row">
                    {campaign.location && (
                      <p className="fz-info-string mr-1">
                        <MdPinDrop className="fz-info-string__icon" />
                        <span className="fz-info-string__text">{campaign.location}</span>
                      </p>
                    )}
                    {campaign.owner_short_name && (
                      <p className="fz-info-string">
                        <MdPermIdentity className="fz-info-string__icon" />
                        <span className="fz-info-string__text">{campaign.owner_short_name}</span>
                      </p>
                    )}
                  </div>
                </div>
                {!campaign.available_for_candidate && (
                  <Tooltip
                    tooltipContent="Candidate has been already invited to apply."
                    contentStyle={{ margin: "auto 0" }}
                    tooltipPosition={["left center"]}
                  >
                    <CheckCircle style={{ width: "24px", height: "24px" }} className="includes_icon" />
                  </Tooltip>
                )}
              </div>
            ))
          ) : (
            <div className="folder">
              <h5 className="ml-1 fz-text-color__grey500">No matching campaigns</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignsModalContent;
