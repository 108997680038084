import React from "react";
import HHInfoIcon from "images/hh_info_icon.svg";

const InfoBar = ({ title = "", text = "" }) => (
  <div className="fz-informer fz-informer_white">
    <div className="fz-informer__container">
      <div className="fz-informer__content">
        <div className="fz-media-pane">
          <div className="fz-media-pane__icon">
            <HHInfoIcon />
          </div>
          <div className="fz-media-pane__info">
            <h5 className="fz-media-pane__title">{title}</h5>
            <p className="fz-media-pane__text">{text}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InfoBar;
