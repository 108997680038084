import React from "react";
import { Link } from "react-router-dom";
import { MdForward, MdStar, MdArrowBack } from "react-icons/md";

import ModalConsumer from "contexts/modal";
import Button from "../../../../../components/Button";
import Subscription from "../../../../components/Subscription";
import SaveModalContent from "../../../../components/SaveModalContent";
import ListContent from "../../../../components/ListContent";
import ContactOptionsModal from "../../../../components/ContactOptionsModal";
import AddTalentToJob from "../../../../components/AddTalentToJob";

const FindTalentList = ({ searchName, reset, allowSaving = true, handleInviteToApply }) => {
  const addToJob = (toggle, campaign, selectedTalents) => {
    return toggle(
      <AddTalentToJob
        selectedTalents={selectedTalents}
        toggle={toggle}
        addToJob={(campaignActive, selectedTalentsActive) => {
          addToJob(toggle, campaignActive, selectedTalentsActive);
        }}
        campaignActive={campaign}
      />,
      `Add talent${selectedTalents.length ? "s" : ""} to job`
    );
  };

  return (
    <ListContent
      Heading={
        searchName ? (
          <h3 className="fz-heading-title mt-5">
            <Link to="/headhunting/searches" onClick={reset}>
              <Button.Simple icon={<MdArrowBack />} color="gray500" className="fadeInFromLeft mr-2" />
            </Link>
            {searchName}
          </h3>
        ) : (
          <h3 className="fz-heading-title mt-5">Search results</h3>
        )
      }
      BatchContent={({ selectedTalents }) => (
        <>
          <ModalConsumer>
            {({ toggle }) => (
              <>
                <Subscription.Upgrade permission="headhunting_talent_pipeline" paymentCheck element="Talent pipeline">
                  <Button.Simple
                    className="mr-3"
                    icon={<MdStar />}
                    onClick={() =>
                      toggle(
                        <SaveModalContent selectedTalents={selectedTalents} close={toggle} />,
                        "Save talents to folder"
                      )
                    }
                  >
                    Save talents
                  </Button.Simple>
                </Subscription.Upgrade>
                <Subscription.Upgrade permission="headhunting_talent_pipeline" paymentCheck element="Talent pipeline">
                  <Button.Simple
                    icon={<MdForward />}
                    onClick={() =>
                      toggle(
                        <ContactOptionsModal
                          selectedTalents={selectedTalents}
                          close={toggle}
                          handleInviteToApply={() => handleInviteToApply(toggle, selectedTalents)}
                          addToJob={() => addToJob(toggle, "", selectedTalents)}
                        />,
                        "Do you also want to invite the candidates to apply?"
                      )
                    }
                  >
                    Add to job
                  </Button.Simple>
                </Subscription.Upgrade>
              </>
            )}
          </ModalConsumer>
        </>
      )}
      allowSaving={allowSaving}
    />
  );
};

export default FindTalentList;
