import React from "react";

import { Modal, Title, Description, Actions, Cancel, Remove } from "./styled";

const InterviewDiscard = ({ onCloseInterviewModal, close }) => {
  const handleClose = () => {
    onCloseInterviewModal();
    close();
  };

  return (
    <Modal>
      <Title>Discard this note?</Title>
      <Description>Everything you wrote in the note will be lost and cannot be recovered. </Description>
      <Actions>
        <Cancel onClick={close}>Keep writing</Cancel>
        <Remove onClick={handleClose}>Yes, discard it</Remove>
      </Actions>
    </Modal>
  );
};

export default InterviewDiscard;
