import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { MdForward, MdRemoveRedEye, MdDone, MdEmail, MdStar } from "react-icons/md";
import Dropdown from "components/Selects";
import Button from "components/Button";

import { convertMonths } from "utils/helpers";
import UserCvCircleMatch from "../../../../components/UserCvCircleMatch";
import Subscription from "../../../../components/Subscription";
import UserCard from "../../../../components/UserCard";
import { theme } from "../../../../../b2c/contexts/theme";
import Checkbox from "../../../../components/Checkbox";

const checkAndFix = value => (value ? Number(value).toFixed(0) : 0);

const TalentCard = ({
  age,
  avatar,
  country,
  current_position_title: currentPositionTitle,
  current_position_employer: currentPositionEmployer,
  highest_education_degree: highestEducationDegree,
  first_name: firstName,
  id,
  contact,
  total_match: totalMatch,
  talent_match: talentMatch,
  cv_match: cvMatch,
  viewed,
  new: isNew,
  selected,
  shortlist,
  move,
  options,
  index,
  campaign,
  removed,
  relevant_experience_months,
  profileUnlocked,
  isSharedCandidates,
  select,
  showSaveModalContent,
  isExternal
}) => {
  const [isHovered, hover] = useState(false);

  const fixedMatches = {
    total: checkAndFix(totalMatch),
    cv: checkAndFix(cvMatch),
    talent: checkAndFix(talentMatch)
  };

  const getInfoboxColor = value => (value > 75 ? "green" : value > 50 ? "yellow" : "red");

  const getExpColor = months => (months ? (months / 12 > 2 ? "green" : months / 12 > 1 ? "yellow" : "red") : "red");

  const { params } = useRouteMatch();

  return (
    <UserCard
      name={firstName}
      age={age}
      country={country}
      current_position_title={currentPositionTitle}
      current_position_employer={currentPositionEmployer}
      viewed={viewed}
      profile_unlocked={profileUnlocked}
      id={id}
      campaign={campaign}
      new={isNew}
      selected={selected}
      removed={removed}
      onMouseEnter={() => hover(true)}
      onMouseLeave={() => hover(false)}
      to={{
        pathname: `/jobs/${campaign}/headhunt/${id}`,
        index
      }}
      Checkarea={
        isSharedCandidates ? (
          <></>
        ) : (
          <div className="fz-panel__checkarea">
            <Checkbox checked={selected} onCheck={select} />
          </div>
        )
      }
      UserAvatar={
        <div className="fz-applicant__aside">
          <UserCvCircleMatch
            isShowCircleMatch={typeof totalMatch === "number"}
            avatar={avatar}
            percentValue={fixedMatches.total}
          />
          {fixedMatches.total ? (
            <div className="fz-applicant-match-info">
              <p className="fz-applicant-match-info__title">{`${fixedMatches.total} %`}</p>
              <p className="fz-applicant-match-info__text">Match</p>
            </div>
          ) : null}
        </div>
      }
      Details={
        <>
          <div className={`fz-infobox fz-infobox_${getInfoboxColor(fixedMatches.cv)} fz-infobox_thin`}>
            <div className="fz-infobox__title">{`${fixedMatches.cv} %`}</div>
            <div className="fz-infobox__info">CV</div>
          </div>
          {relevant_experience_months && (
            <div className={`fz-infobox fz-infobox_${getExpColor(relevant_experience_months)} fz-infobox_thin`}>
              <div className="fz-infobox__title">{convertMonths(relevant_experience_months)}</div>
              <div className="fz-infobox__info">Relevant exp.</div>
            </div>
          )}
          {highestEducationDegree && (
            <div className="fz-infobox fz-infobox_thin">
              <div className="fz-infobox__title">{highestEducationDegree}</div>
              <div className="fz-infobox__info">Education</div>
            </div>
          )}
        </>
      }
      Actions={
        <div className="fz-panel__actions">
          {!params.folder && !isExternal && (
            <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline" element="Talent pipeline">
              <Button
                size="sm"
                color="gray"
                onClick={showSaveModalContent}
                icon={<MdStar />}
                role="button"
                tabIndex="0"
              >
                Save talent
              </Button>
            </Subscription.Upgrade>
          )}
          <Subscription.Payment>
            <Button icon={<MdDone />} size="sm" color="gray" onClick={shortlist} role="button" tabIndex="0">
              Shortlist
            </Button>
          </Subscription.Payment>
          <Subscription.Payment>
            <Button icon={<MdEmail />} size="sm" color={theme.blue} onClick={contact} role="button" tabIndex="0">
              Contact
            </Button>
          </Subscription.Payment>
          <Dropdown selectOptions={options} hide={!isHovered} onSelect={move}>
            <Subscription.Payment>
              <Button icon={<MdForward />} size="sm" color={theme.grey500}>
                Move to
              </Button>
            </Subscription.Payment>
          </Dropdown>
          <Link
            to={{
              pathname: `/jobs/${campaign}/headhunt/${id}`,
              index
            }}
          >
            <Button icon={<MdRemoveRedEye />} size="sm" color={theme.grey500}>
              View CV
            </Button>
          </Link>
        </div>
      }
    />
  );
};

export default TalentCard;
