import React, { useEffect } from "react";
import { inject } from "mobx-react";
import { useLocation } from "react-router-dom";
import { isBaronaBrand } from "contexts/init";

import { ProfileTab } from "b2b/components/ProfileTab";
import { useModal } from "utils/hooks";
import Messenger from "pages/Messenger";
import Button from "components/Button";
import Layout from "../../components/Layout";
import { UserCvCard } from "../../components/UserCv";
import { getTalentDetails } from "../../utils/headhuntApi";

const MessagesScreen = ({ Messages, User, ...routeProps }) => {
  const { pathname } = useLocation();
  const isBarona = isBaronaBrand();

  useEffect(() => {
    const messagePathname = "/messages";
    const isMessagePathname = pathname === messagePathname;
    const footerHide = "fz-footer_hide";
    const footer = document.getElementsByClassName("fz-footer")?.[0];

    if (isMessagePathname) {
      footer.classList.add(footerHide);
    }

    return () => {
      if (isMessagePathname) {
        footer.classList.remove(footerHide);
      }
    };
  }, []);

  return (
    <Layout.Container type="wide" style={{ padding: "28px 0", height: 940 }}>
      <Messenger Messages={Messages} User={User} UserCvButton={UserCvButton} isBarona={isBarona} {...routeProps} />
    </Layout.Container>
  );
};

const CvContent = ({ user }) => (
  <section className="fz-page-container">
    <UserCvCard
      {...user}
      hasPersonality={!!user.personality}
      showDetails={user.contact_details_visible}
      profileUnlocked={user.profile_unlocked}
    />
    <div className="fz-container-with-sidebar">
      <div className="fz-container-with-sidebar__content">
        <ProfileTab tab="headhunt" talent={user} />
      </div>
    </div>
  </section>
);

const UserCvButton = ({ id }) => {
  const openCvModal = useModal(<CvContent />, "Profile");

  const getDetailsAndOpen = () => {
    getTalentDetails(id)
      .then(res => res.data.talent)
      .then(user => {
        openCvModal({
          user,
          customCv: user?.user_uploaded_cv_url,
          showDetails: user?.contact_details_visible,
          profileUnlocked: user?.profile_unlocked
        });
      });
  };

  return (
    <Button.Simple onClick={getDetailsAndOpen} style={{ marginBottom: 10 }}>
      View profile
    </Button.Simple>
  );
};

export default inject("Messages", "User")(MessagesScreen);
