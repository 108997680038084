import React, { useState, useEffect } from "react";
import cn from "classnames";
import { MdCheckCircle, MdErrorOutline } from "react-icons/md";

const MatcherChart = ({ title, score, stepCount }) => {
  const [stateStatus, setStateStatus] = useState("bad");
  const [activeScore, setActiveScore] = useState(0);

  useEffect(() => {
    switch (true) {
      case score <= 20 && score >= 0:
        setStateStatus("bad");
        break;
      case score < 60 && score > 20:
        setStateStatus("enought");
        break;
      default:
        setStateStatus("good");
        break;
    }

    setActiveScore(Math.ceil(score / (100 / stepCount)));
  }, [score, stepCount]);

  const wrapperClassNames = cn("fz-matcher-chart", {
    "fz-matcher-chart_red": stateStatus === "bad",
    "fz-matcher-chart_yellow": stateStatus === "enought"
  });

  return (
    <div className={wrapperClassNames}>
      <div className="fz-matcher-chart__icon">
        {stateStatus === "good" && <MdCheckCircle />}
        {stateStatus === "enought" && <MdCheckCircle />}
        {stateStatus === "bad" && <MdErrorOutline />}
      </div>
      <div className="fz-matcher-chart__info">
        <h5 className="fz-matcher-chart__title">{title}</h5>
        <div className="fz-matcher-chart__proggres">
          {[...Array(stepCount).keys()].map(i => (
            <div
              className={cn("fz-matcher-chart__step", {
                "active fadeInSimple": i + 1 <= activeScore
              })}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MatcherChart.defaultProps = {
  title: "",
  stepCount: 5
};

export default MatcherChart;
