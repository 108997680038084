import React, { useLayoutEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { MdDomain, MdArrowDownward, MdArrowUpward, MdRefresh } from "react-icons/md";

import Button from "components/Button";
import { useModal } from "utils/hooks";
import { withRoute } from "utils/hocs/withRoute";
import { stampToDateString } from "utils/helpers";
import UsersPlaceholder from "images/b2b/usersPlaceholder.svg";
import BrandSlugContent from "../BrandSlugContent";
import Layout from "../../../../components/Layout";
import Tag from "../../../../components/Tag";
import InfoPane from "../../../../components/InfoPane";
import Pane from "../../../../components/Pane";

const OrganizationsTab = ({
  Settings: {
    organizations,
    getOrganizations,
    inviteOrganization,
    removeOrganization,
    approveOrganizationInvite,
    resendOrganizationInvite,
    batchResend
  }
}) => {
  const [order, setOrder] = useState(false);
  const openHandleInviteModal = useModal(
    <BrandSlugContent update={inviteOrganization} ctaText="Send invite" />,
    <>
      Invite organization
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700">
        Please write in the below text field the prefix of the branded career page URL <br /> of the invited
        organization. The admin users of the invited organization will <br />
        receive an email invitation to be connected with your organization.
      </div>
    </>
  );

  useLayoutEffect(() => {
    getOrganizations();
  }, []);

  return organizations ? (
    <>
      <Pane.Two>
        <h2 className="fz-heading-title exp mb-2">
          <div>
            Organizations
            <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey700">
              Invite other organizations to become members of your organization or program.
            </p>
          </div>
          {organizations && (
            <Button size="md" icon={<MdDomain />} color="yellow" onClick={openHandleInviteModal}>
              Invite organization
            </Button>
          )}
        </h2>

        {organizations ? (
          <table className="fz-table">
            <thead>
              <tr>
                <th className="fz-table__headcell flex">
                  <span className="mr-2 m-t-auto m-b-auto">Organization</span>
                  <Button.Iconed color="transparent" onClick={() => setOrder(!order)}>
                    {!order ? <MdArrowDownward /> : <MdArrowUpward />}
                  </Button.Iconed>
                </th>
                <th className="fz-table__headcell">Role</th>
                <th className="fz-table__headcell">Access</th>
                <th className="fz-table__headcell">Status</th>
                <th className="fz-table__headcell" colSpan="2">
                  Invitation date
                </th>
              </tr>
            </thead>
            <tbody>
              {organizations
                .slice()
                .sort((a, b) => (a.name < b.name ? (order ? 1 : -1) : 0))
                .map(({ id, name, role, access, accepted, outgoing, created_at, token }) => (
                  <tr key={id}>
                    <td className="fz-table__cell fz-table_fit-content">{name}</td>
                    <td className="fz-table__cell fz-table_fit-content">{role}</td>
                    <td className="fz-table__cell fz-table_fit-content">{access}</td>
                    <td className="fz-table__cell">
                      {!accepted ? (
                        <Tag name="Waiting for approval" color="grey100" />
                      ) : (
                        <Tag name="Accepted" isActive />
                      )}
                    </td>
                    <td className="fz-table__cell fz-table_fit-content">
                      {stampToDateString(created_at, "en-GB", { month: "long", day: "numeric", year: "numeric" })}
                    </td>
                    <td className="fz-table__cell fz-table_fit-content text-right">
                      <>
                        {!accepted && (
                          <Button.Simple
                            className="ml-2"
                            {...(outgoing
                              ? { children: "Re-send", onClick: () => resendOrganizationInvite(id) }
                              : { children: "Approve", onClick: () => approveOrganizationInvite(id, token) })}
                          />
                        )}
                        <Button.Simple color="red" className="ml-2" onClick={() => removeOrganization(id)}>
                          Remove
                        </Button.Simple>
                      </>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <Layout.EmptyState
            narrow
            showImage
            customImage={<UsersPlaceholder />}
            title="Start by inviting first organization"
            subtitle="Invite organizations to become a member of your program. All invited organizations will appear hear."
            actionButton={
              <Button icon={<MdDomain />} color="yellow" onClick={openHandleInviteModal}>
                Invite organization
              </Button>
            }
          />
        )}
      </Pane.Two>
      <Pane.Two>
        <InfoPane.Item
          className="mt-2 pb-2"
          title="Re-send invitations"
          subtitle="Re-send invitation to all organizations that have not approved your invitation"
          actions={
            <Button
              icon={<MdRefresh />}
              disabled={!organizations.some(({ accepted }) => !accepted)}
              onClick={batchResend}
            >
              Re-send invitations
            </Button>
          }
        />
      </Pane.Two>
    </>
  ) : null;
};

export default withRoute(inject("Settings")(observer(OrganizationsTab)));
