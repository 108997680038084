import React, { useState, useEffect } from "react";

import { MdDelete } from "react-icons/md";
import { inject, observer } from "mobx-react";
import avatarPlaceholder from "images/empty_avatar.png";
import {
  Modal,
  Title,
  Text,
  ShowPersonalityContent,
  ShowPersonalityText,
  ShowPersonalityTitle,
  Submit,
  Actions,
  Banner,
  DeleteLink,
  CreatedBy,
  Label,
  UserInfo,
  Name,
  Info,
  Count,
  UserPicture
} from "./styled";
import Checkbox from "../../../../components/Checkbox";
import { useModal } from "../../../../../utils/hooks";
import ShareShortlistedSuccess from "../ShareShortlistedSuccess";
import DeleteShareShortlisted from "../DeleteShareShortlisted";
import { formatDate } from "../../../../utils/helpers";

const ShareShortlisted = ({ guestLinkThreshold, activeGuestLink, handleSubmit, isEdit, deleteGuestLink, close }) => {
  const expiresDate = isEdit
    ? formatDate(new Date(activeGuestLink.expires_at))
    : formatDate(new Date(new Date().getTime() + guestLinkThreshold * 1000));

  const [isActive, setIsActive] = useState(false);
  const showShareShortlistedSuccess = useModal(<ShareShortlistedSuccess />, "");
  const showDeleteShareShortlisted = useModal(<DeleteShareShortlisted deleteGuestLink={deleteGuestLink} />, "");

  const handleClick = () => {
    handleSubmit(isActive, isEdit ? close : showShareShortlistedSuccess);
  };

  useEffect(() => {
    if (isEdit && activeGuestLink.show_contact_details) {
      setIsActive(true);
    }
  }, []);

  return (
    <Modal>
      <Title> Share candidates</Title>
      {isEdit ? (
        <CreatedBy>
          <div>
            <Label>Created by</Label>
            <UserInfo>
              <UserPicture>
                <img src={avatarPlaceholder} alt="img" />
              </UserPicture>
              <div>
                <Name>{activeGuestLink.owner.name}</Name>
                <Info>{formatDate(new Date(activeGuestLink.created_at))}</Info>
              </div>
            </UserInfo>
          </div>
          <div>
            <Label>Activity</Label>
            <Count>{activeGuestLink.views_count}</Count>
            <Info>visits</Info>
          </div>
        </CreatedBy>
      ) : (
        <Text>Create a link to share with guests to review candidates.</Text>
      )}

      <ShowPersonalityContent>
        <div>
          <ShowPersonalityTitle>Show personal and contact details</ShowPersonalityTitle>
          <ShowPersonalityText>
            Shared candidate profile will show the full name, profile picture, contact details, references, and links.
          </ShowPersonalityText>
        </div>
        <Checkbox
          switchType
          checked={isActive}
          onCheck={() => setIsActive(true)}
          onUncheck={() => setIsActive(false)}
          isGray
        />
      </ShowPersonalityContent>
      <Banner>
        This link will automatically expire on <span>{expiresDate}</span>
      </Banner>
      <Actions>
        {isEdit && (
          <DeleteLink onClick={showDeleteShareShortlisted}>
            <MdDelete />
            Delete link
          </DeleteLink>
        )}
        <Submit onClick={handleClick} className="fz-btn_yellow">
          {isEdit ? "Update link" : "Create guest link"}
        </Submit>
      </Actions>
    </Modal>
  );
};

export default inject("CampaignsStore", "MyJobsStore")(observer(ShareShortlisted));
