import React from "react";
import Select from "react-select";

import { DropdownItem, DropdownInitials, DropdownName, DropdownEmail, AlreadySelected, dropdownStyles } from "./styled";

const getInitials = (name, lastName) => `${name.charAt(0)}${lastName.charAt(0)}`.toUpperCase();

const customOption = props => {
  const { data, innerRef, innerProps } = props;
  const colorPalette = ["#FBCF8E", "#D9EBA8", "#F7D5A8", "#E0A9B8", "#C8E4D4", "#F1C3CF"];
  const backgroundColor = colorPalette[data.id % colorPalette.length]; // Rotate colors

  return (
    <DropdownItem ref={innerRef} {...innerProps} $isAlreadySelected={data.isSelected}>
      <DropdownInitials
        style={{
          backgroundColor
        }}
      >
        {getInitials(data.name, data.lastName)}
      </DropdownInitials>
      <div>
        <DropdownName>{data.name}</DropdownName>
        <DropdownEmail>{data.email}</DropdownEmail>
      </div>
      {data.isSelected && <AlreadySelected>Already added</AlreadySelected>}
    </DropdownItem>
  );
};

const NewGuestLinkSelect = ({ handleSelect, preSelectedValues, selectedValueIds, collaboratorContacts }) => {
  const options = collaboratorContacts.map(item => {
    return {
      id: item.id,
      lastName: item.last_name || "",
      name: item.first_name || "",
      email: item.email
    };
  });

  return (
    <Select
      options={options.map(user => ({
        value: user.id,
        label: `${user.name} ${user.lastName}`,
        ...user,
        isSelected: selectedValueIds.includes(user.id)
      }))}
      isMulti
      components={{ Option: customOption, DropdownIndicator: null, IndicatorSeparator: null }}
      styles={dropdownStyles}
      placeholder="Type name or email to search or add"
      value={preSelectedValues}
      onChange={selected => handleSelect(selected)}
    />
  );
};

export default NewGuestLinkSelect;
