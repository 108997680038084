import React, { useContext, useRef, useState, useEffect, useMemo } from "react";
import { Collapse, Expand } from "@styled-icons/boxicons-regular";
import { MdClose, MdRefresh } from "react-icons/md";
import { inject, observer } from "mobx-react";

import RichTextTextarea from "b2b/components/RichTextTextarea";
import { interviewModalTypes } from "b2b/constants/campaign";
import AppContext from "contexts/init";
import { useModal } from "utils/hooks";
import InterviewDiscard from "../InterviewDiscard";
import InterviewPreview from "../InterviewPreview";
import {
  Container,
  Heading,
  HeadingTitle,
  HeadingActions,
  HeadingButton,
  Content,
  Label,
  Input,
  RichTextTextareaContent,
  Footer,
  Close,
  Discard,
  ChangesSaved,
  ChangesSaving,
  Save,
  Minimize,
  Overlay
} from "./styled";

const AddInterview = ({
  candidateName,
  numberOfInterview,
  interviewItem,
  CampaignsStore: {
    toggleInterviewModal,
    createInterviewNote,
    editInterviewNote,
    resetInterviewModalEditId,
    openEditInterviewModal,
    deleteInterviewNote,
    interviewNotes,
    resetIsShowPreviewModalWhenCancel,
    isShowPreviewModalWhenCancel,
    interviewModalCandidateId
  }
}) => {
  const area = useRef();
  const { current_user: currentUser } = useContext(AppContext);
  const [modalType, setModalType] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isEditMode = useMemo(() => Boolean(interviewItem.id), []);

  const openInterviewPreviewModal = useModal(
    <InterviewPreview
      openEditInterviewModal={openEditInterviewModal}
      deleteInterviewNote={deleteInterviewNote}
      candidateId={interviewModalCandidateId}
      interviewNotes={interviewNotes}
      interviewIndex={interviewNotes.findIndex(item => item.id === interviewItem.id)}
    />,
    ""
  );

  const handleCloseInterviewModal = () => {
    toggleInterviewModal(false);
  };

  const handleCancelInterviewModal = () => {
    toggleInterviewModal(false);

    if (isShowPreviewModalWhenCancel) {
      openInterviewPreviewModal({ isOverflowVisible: true });
    }
  };

  const openDiscardModal = useModal(<InterviewDiscard onCloseInterviewModal={handleCloseInterviewModal} />, "");

  const handleSetModalType = type => {
    setModalType(type);
  };

  const handleSave = () => {
    setIsLoading(true);
    if (interviewItem.id) {
      editInterviewNote(String(interviewModalCandidateId), interviewItem.id, {
        body: noteValue,
        title: titleValue
      }).then(() => {
        handleCloseInterviewModal();
      });
    } else {
      createInterviewNote(String(interviewModalCandidateId), { body: noteValue, title: titleValue }).then(() => {
        handleCloseInterviewModal();
      });
    }
  };

  const handleChangeNote = text => {
    setNoteValue(text);

    if (!isSaving) {
      setIsSaving(true);
    }
  };

  const handleChangeTitle = event => {
    setTitleValue(event.target.value);

    if (!isSaving) {
      setIsSaving(true);
    }
  };

  useEffect(() => {
    if (interviewItem.id) {
      setNoteValue(interviewItem.body);
      setTitleValue(interviewItem.title);
    } else if (!titleValue) {
      setTitleValue(`Interview ${numberOfInterview} ${currentUser.name} — ${candidateName || ""}`);
    }

    if (isEditMode) {
      setModalType(interviewModalTypes.modal);
    } else {
      setModalType(interviewModalTypes.docked);
    }

    return () => {
      resetInterviewModalEditId();
      resetIsShowPreviewModalWhenCancel();
      setModalType("");
    };
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setIsSaving(false);
    }, 800);

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [titleValue, noteValue]);

  const isDisabledSave = !noteValue || noteValue === "<p></p>" || !titleValue || (isEditMode ? false : isLoading);

  return (
    <>
      {modalType === interviewModalTypes.modal && <Overlay />}

      {modalType && (
        <Container $isModal={modalType === interviewModalTypes.modal}>
          <Heading>
            <HeadingTitle>{isEditMode ? "Edit note" : titleValue}</HeadingTitle>
            <HeadingActions>
              {isEditMode ? (
                <HeadingButton onClick={handleCloseInterviewModal}>
                  <MdClose />
                </HeadingButton>
              ) : (
                <>
                  {modalType === interviewModalTypes.collapse ? (
                    <HeadingButton onClick={() => handleSetModalType(interviewModalTypes.docked)}>
                      <Minimize $isBottomPosition />
                    </HeadingButton>
                  ) : (
                    <HeadingButton onClick={() => handleSetModalType(interviewModalTypes.collapse)}>
                      <Minimize />
                    </HeadingButton>
                  )}

                  {[interviewModalTypes.docked, interviewModalTypes.collapse].includes(modalType) && (
                    <HeadingButton onClick={() => handleSetModalType(interviewModalTypes.modal)}>
                      <Expand />
                    </HeadingButton>
                  )}

                  {modalType === interviewModalTypes.modal && (
                    <HeadingButton onClick={() => handleSetModalType(interviewModalTypes.docked)}>
                      <Collapse />
                    </HeadingButton>
                  )}

                  <HeadingButton onClick={openDiscardModal}>
                    <MdClose />
                  </HeadingButton>
                </>
              )}
            </HeadingActions>
          </Heading>
          {modalType !== interviewModalTypes.collapse && (
            <>
              <Content>
                <Label>Title</Label>
                <Input value={titleValue} onChange={handleChangeTitle} />
                <Label>Note</Label>
                <RichTextTextareaContent $minHeight={modalType === interviewModalTypes.modal ? "342px" : ""}>
                  <RichTextTextarea
                    area={area}
                    description={noteValue || interviewItem.body || ""}
                    onChange={handleChangeNote}
                  />
                </RichTextTextareaContent>
              </Content>
              <Footer>
                {isEditMode ? (
                  <Close onClick={handleCancelInterviewModal}>Cancel</Close>
                ) : (
                  <>
                    <Discard onClick={openDiscardModal}>Discard</Discard>

                    {isSaving ? (
                      <ChangesSaving>
                        <MdRefresh /> Saving...
                      </ChangesSaving>
                    ) : (
                      <ChangesSaved>All changes saved</ChangesSaved>
                    )}
                  </>
                )}
                <Save $isDisabled={isDisabledSave} onClick={handleSave}>
                  {isEditMode ? "Save changes" : "Save"}
                </Save>
              </Footer>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default inject("CampaignsStore")(observer(AddInterview));
