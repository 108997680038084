import React, { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { useParams, useHistory } from "react-router-dom";

import AppContext from "contexts/init";
import { b2cUrl } from "utils/helpers";
import { getMessageCandidatesPath } from "utils/urlHelpers";
import { TABS } from "constants/tabs";
import ActionHeader from "./components/ActionHeader";
import JobDetailContent from "./components/JobDetailContent";
import JobDetailHeading from "./components/JobDetailHeading";
import Subscription from "../../components/Subscription";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import { applicationTypes, guestLinkAccessType } from "../../constants/campaign";
import { useModal } from "../../../utils/hooks";
import HowFastApplyWork from "./components/HowFastApplyWork";

const JobDetailScreen = inject(
  "campaignsStore",
  "myJobsStore"
)(
  observer(
    ({
      campaignsStore: {
        candidates = [],
        reasons = [],
        selectedCampaign,
        navigationIds,
        updateList,
        getCandidateFilters,
        setUp,
        state,
        total,
        moveCandidates,
        shortlistCandidates,
        rejectCandidates,
        removeTalent,
        rateCandidate,
        candidateTag,
        appliedFilters: stateAppliedFilters = {},
        filters: stateFilters = [],
        setAppliedFilters,
        resetAppliedFilters,
        voteForCandidate,
        createPdfs,
        exportCsv,
        getJobDetails,
        getJobUpdates,
        filterCount,
        getCollaborators,
        collaborators,
        collaboratorContacts,
        changeCollaborationVisibility,
        inviteCollaborator,
        removeCollaborator,
        getCandidateComments,
        createComment,
        editComment,
        deleteComment,
        pageCount,
        moveTalents,
        shortlistTalents,
        unlockUser,
        rejectMessage,
        shortlistWithAssignments,
        shortlistWithAssignmentsTalents,
        markAsViewedFastApply,
        getAvailableJobForCandidate,
        getFolders,
        folders,
        removeFolder,
        addToFolder,
        createFolder,
        addFolderIdToCandidate,
        isSharedCandidates,
        createGuestLink,
        changeGuestLink,
        guestLinkData,
        deleteGuestLink,
        getCandidates,
        refreshFlows,
        isLoadingFolders,
        handleKeepPageInfo,
        keepPageInfo,
        getActiveCampaigns,
        getActiveCompanies,
        getIntegrations,
        getEmployerNames,
        integrationEmployerNames,
        getOccupationAutocomplete,
        getOccupationSearch,
        resetOccupation,
        integrations,
        getTMTIntegrations,
        occupationSearch,
        occupationAutocompleteList,
        createIntegration,
        updateIntegration,
        removeIntegration,
        integrationDetailForm,
        integrationDetailErrors,
        resetIntegrationDetailErrors,
        guestLinkContacts,
        resendGuestLink
      } = {},
      myJobsStore: { archiveCampaign, deleteCampaign } = {}
    }) => {
      // Selected candidates
      const [selectedCandidates, select] = useState([]);
      const [resetPrevApplied, forceReset] = useState(false);

      // Getting query parameters and browser history
      const params = useParams();
      const history = useHistory();
      const isPostPromotionsPage =
        history.location.pathname.includes("/post-promotions") ||
        history.location.pathname.includes("/edit-promotions");
      const isPromotions = history.location.pathname.includes("/promotions");

      // Parsing data layer properties as JS objects
      const campaign = useMemo(() => toJS(selectedCampaign), [selectedCampaign]);
      const applicants = useMemo(() => toJS(candidates), [candidates]);
      const filters = useMemo(() => toJS(stateFilters), [stateFilters]);
      const appliedFilters = toJS(stateAppliedFilters);
      const screenState = toJS(state);
      const isFastApply = applicationTypes.fastApply === campaign.application_type;
      const isShortlisted =
        appliedFilters.filters.scope === "shortlisted" && selectedCandidates.length === candidates.length;

      const handleHowFastApplyWork = useModal(
        <HowFastApplyWork markAsViewedFastApply={markAsViewedFastApply} />,
        "How Fast Apply campaigns work",
        () => ({})
      );

      // Filtering scopes
      const filteredScopes = useMemo(
        () =>
          filters.scopes && filters.scopes.length > 0
            ? filters.scopes.filter(scope => scope.value !== "all")
            : campaign.phases,
        [filters.scopes, campaign.phases]
      );

      // Loading message
      const loadingMsg = useMemo(() => {
        switch (params.tab) {
          default:
            return "Loading...";
          case "applicants":
            return "Just a moment! We are loading your candidates...";
          case "headhunt":
            return "Just a moment! We are loading your talents...";
        }
      }, [params.tab]);

      // Set up
      const { subscription, current_user, global_brand } = useContext(AppContext);
      const isLeadHr = current_user?.roles?.includes("lead_hr");

      const recruitmentPhase = global_brand.recruitment_phases
        ? global_brand.recruitment_phases.find(item => item.guest_access)
        : {};

      const rankingDisabled = !isSharedCandidates && !subscription?.permissions.job_matching_with_ranking;

      useEffect(() => {
        window.scrollTo(0, 0);
        setUp(params, history, rankingDisabled ? "created_at" : null);

        if (getCandidates && refreshFlows && params.tab !== TABS.HEADHUNT && !isPostPromotionsPage && !isPromotions) {
          refreshFlows(getCandidates(params.id));
        }
        forceReset(false);
      }, [params.tab, params.id]);

      useEffect(() => {
        if (campaign.title) {
          document.title = campaign.title;
        }
      }, [campaign.title]);

      useEffect(() => {
        select([]);
      }, [applicants]);

      useEffect(() => {
        const isGetFolders =
          !isLoadingFolders &&
          "is_current_user_external_collaborator" in campaign &&
          !campaign.is_current_user_external_collaborator &&
          !folders.length;

        if (isGetFolders) {
          getFolders();
        }
      }, [campaign.is_current_user_external_collaborator]);

      useEffect(() => {
        if (isFastApply && !current_user.fast_apply_tutorial_viewed) {
          handleHowFastApplyWork();
          markAsViewedFastApply();
        }
      }, [isFastApply, current_user?.fast_apply_tutorial_viewed]);

      // Applying and reseting filters
      const applyFilter = async filter => {
        await setAppliedFilters({ filters: { ...appliedFilters.filters, ...filter }, page: 1 });
        updateList(filters, history);
        getCandidateFilters(params.id, params.tab);
      };

      const resetAllFilters = async () => {
        await resetAppliedFilters();
        updateList(filters, history);
        getCandidateFilters(params.id, params.tab);
      };

      const scopeChangeReset = async filtersList => {
        await resetAppliedFilters();
        updateList(filtersList, history);
        clearSelection();
        forceReset(!resetPrevApplied);
        getCandidateFilters(params.id, params.tab);
      };

      const getMessagePath = candidateIdList => getMessageCandidatesPath(params.id, candidateIdList);

      // Candidate selection
      const selectCandidate = id => {
        !selectedCandidates.includes(id)
          ? select(prev => [...prev, id])
          : select(prev => prev.filter(item => item !== id));
      };

      const selectAll = useCallback(
        isSelectAllNavigation => {
          if (isSelectAllNavigation) {
            select(navigationIds.map(item => Number(item)));
          } else {
            select(applicants.map(app => app.id));
          }
        },
        [applicants]
      );

      const clearSelection = () => select([]);

      // Batch actions
      const printCVs = useCallback(isSelf => createPdfs(selectedCandidates, isSelf), [selectedCandidates]);

      const exportSelectedToCsv = () => exportCsv(selectedCandidates);

      const messageToSelectedPath = useMemo(() => getMessagePath(selectedCandidates), [selectedCandidates]);

      // Tag action
      const candidateTagAction = (id, tag, type) =>
        candidateTag(id, tag, type)
          .then(() => {
            forceReset(!resetPrevApplied);
          })
          .then(() => {
            getCandidateFilters(params.id, params.tab);
          });

      // Handle success redirection
      const handleRedirection = data => {
        if (params.tab !== "applicants")
          history.push(`/jobs/${params.id}/applicants?filters[scope]=${data.filters.scope}`);
        else updateList(data, history);
      };

      const handleCreateGuestLink = (guestAccessibleSections, showModal, contactIds, guestPitch) => {
        const expiresAt = new Date(new Date().getTime() + campaign.guest_link_threshold * 1000);
        return createGuestLink({
          campaignId: campaign.id,
          guestAccessibleSections,
          showModal,
          expiresAt,
          phase: recruitmentPhase?.slug,
          accessType: global_brand.guest_link_access_type || guestLinkAccessType.public,
          contactIds,
          guestPitch
        });
      };

      const handleChangeGuestLink = (guestAccessibleSections, showModal, contactIds, guestPitch) => {
        const expiresAt = new Date(new Date().getTime() + campaign.guest_link_threshold * 1000);

        return changeGuestLink({
          campaignId: campaign.id,
          tokenId: campaign.active_guest_link.id,
          guestAccessibleSections,
          showModal,
          expiresAt,
          accessType: global_brand.guest_link_access_type || guestLinkAccessType.public,
          contactIds,
          guestPitch
        });
      };

      const handleDeleteGuestLink = () => {
        deleteGuestLink(campaign.id, campaign.active_guest_link.id);
      };

      const integrationCompanyDetails = useMemo(() => {
        return {
          employer_logo: campaign.employer_logo,
          client_company_name: campaign.employer_name,
          title: campaign.title,
          job_url: b2cUrl(campaign.path),
          recruiter_email: campaign.recruiter_email,
          recruiter_last_name: campaign.recruiter_last_name,
          recruiter_first_name: campaign.recruiter_first_name
        };
      }, [campaign.employer_logo, campaign.employer_name]);

      return (
        <Subscription.Tab tab permission="candidate_list_access" element="Headhunted talent" redirectPath="/campaigns">
          <ScreenStateMessage
            state={screenState}
            loadingMessage={loadingMsg}
            scopes={filteredScopes}
            redirect={handleRedirection}
          >
            <ActionHeader
              id={params.id}
              phase={campaign.phase}
              employerName={campaign.employer_name}
              path={b2cUrl(campaign.path)}
              getCollaborators={getCollaborators}
              collaboratorContacts={toJS(collaboratorContacts)}
              collaborators={toJS(collaborators)}
              isCurrentUserOwner={campaign.is_current_user_owner}
              isCurrentUserExternalCollaborator={campaign.is_current_user_external_collaborator}
              colVisibility={campaign.collaboration_visibility}
              archiveCampaign={archiveCampaign}
              deleteCampaign={deleteCampaign}
              changeVisibility={changeCollaborationVisibility}
              invite={inviteCollaborator}
              remove={removeCollaborator}
              isLeadHr={isLeadHr}
              guestLinkThreshold={campaign.guest_link_threshold}
              activeGuestLink={campaign.active_guest_link}
              handleCreateGuestLink={handleCreateGuestLink}
              deleteGuestLink={handleDeleteGuestLink}
              changeGuestLink={handleChangeGuestLink}
              guestLinkContacts={toJS(guestLinkContacts)}
              resendGuestLink={contactId => resendGuestLink(campaign.id, campaign.active_guest_link.id, contactId)}
            />
            {!isPostPromotionsPage && (
              <JobDetailHeading
                id={params.id}
                tab={params.tab}
                phase={campaign.phase}
                left={campaign.days_remaining}
                employer={campaign.employer_name}
                location={campaign.location}
                owner={campaign.owner_short_name}
                title={campaign.title}
                promotionStatus={campaign.integration_health_status}
                applicantCount={campaign.applicants_count}
                isOwner={campaign.is_current_user_owner}
                isExternal={campaign.external_application}
                isFastApply={isFastApply}
                handleHowFastApplyWork={handleHowFastApplyWork}
                isShowShare={isShortlisted}
                createGuestLink={handleCreateGuestLink}
                deleteGuestLink={handleDeleteGuestLink}
                changeGuestLink={handleChangeGuestLink}
                guestLinkData={guestLinkData}
                activeGuestLink={campaign.active_guest_link}
                guestLinkThreshold={campaign.guest_link_threshold}
                isLeadHr={isLeadHr}
              />
            )}

            <JobDetailContent
              tab={params.tab}
              paramsId={params.id}
              type={screenState.type}
              campaign={campaign}
              reasons={reasons}
              rejectMessage={rejectMessage}
              loading={screenState.loading}
              candidates={applicants}
              updateList={data => updateList(data, history)}
              selectedCandidates={selectedCandidates}
              select={selectCandidate}
              selectAll={selectAll}
              clearSelection={clearSelection}
              shortlist={shortlistCandidates}
              shortlistWithAssignments={shortlistWithAssignments}
              shortlistWithAssignmentsTalents={shortlistWithAssignmentsTalents}
              move={moveCandidates}
              moveTalents={moveTalents}
              shortlistTalents={shortlistTalents}
              reject={rejectCandidates}
              getActiveCampaigns={getActiveCampaigns}
              getActiveCompanies={getActiveCompanies}
              removeTalent={removeTalent}
              rate={rateCandidate}
              message={id => getMessagePath([id])}
              candidateTag={candidateTagAction}
              appliedFilters={appliedFilters}
              applyFilter={applyFilter}
              resetAllFilters={resetAllFilters}
              filters={filters}
              voteForCandidate={voteForCandidate}
              messageToSelectedPath={messageToSelectedPath}
              printCVs={printCVs}
              exportCsv={exportSelectedToCsv}
              total_count={total}
              getJobDetails={options => getJobDetails(params.id, options)}
              getJobUpdates={options => getJobUpdates(params.id, options)}
              filterCount={filterCount}
              resetPrevApplied={resetPrevApplied}
              getCandidateComments={getCandidateComments}
              createComment={createComment}
              editComment={editComment}
              deleteComment={deleteComment}
              pageCount={pageCount}
              scopeChangeReset={scopeChangeReset}
              unlockUser={unlockUser}
              prioritize_industry_experience={campaign.prioritize_industry_experience}
              navigationIds={navigationIds}
              isNewSelectAll
              industryApplied={
                appliedFilters.filters.industry_ids &&
                appliedFilters.filters.industry_ids.includes(campaign.industry_id)
              }
              isFastApply={isFastApply}
              getAvailableJobForCandidate={getAvailableJobForCandidate}
              folders={folders}
              removeFolder={removeFolder}
              addToFolder={addToFolder}
              createFolder={createFolder}
              getFolders={getFolders}
              addFolderIdToCandidate={addFolderIdToCandidate}
              isSharedCandidates={isSharedCandidates}
              handleKeepPageInfo={handleKeepPageInfo}
              keepPageInfo={keepPageInfo}
              getIntegrations={() => getIntegrations(params.id)}
              getEmployerNames={() => getEmployerNames(params.id)}
              integrationCompanyDetails={integrationCompanyDetails}
              integrationEmployerNames={integrationEmployerNames}
              getOccupationSearch={search => getOccupationSearch(params.id, search)}
              resetOccupation={resetOccupation}
              getOccupationAutocomplete={search => getOccupationAutocomplete(params.id, search)}
              getTMTIntegrations={promotionId => getTMTIntegrations(params.id, promotionId)}
              createIntegration={body => createIntegration(params.id, body)}
              updateIntegration={(body, promotionId) => updateIntegration(params.id, body, promotionId)}
              removeIntegration={promotionId => removeIntegration(params.id, promotionId)}
              integrationDetailForm={integrationDetailForm}
              occupationAutocompleteList={occupationAutocompleteList}
              occupationSearch={occupationSearch}
              integrations={integrations}
              jobB2CPath={b2cUrl(campaign.path)}
              integrationDetailErrors={integrationDetailErrors}
              resetIntegrationDetailErrors={resetIntegrationDetailErrors}
            />
          </ScreenStateMessage>
        </Subscription.Tab>
      );
    }
  )
);

export default JobDetailScreen;
