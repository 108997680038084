import React, { useState, useEffect } from "react";
import { MdSend } from "react-icons/md";

import Button from "components/Button";
import { onEnterPress } from "utils/helpers";
import { validateEmail } from "utils/validation";
import Layout from "../../../../components/Layout";

const CollaborationSearch = ({ users, onCollaboratorSelect }) => {
  const [inputValue, setInputValue] = useState("");
  const [matchedUsers, setMatchedUsers] = useState([]);
  const [showEmailError, setShowEmailError] = useState(false);

  useEffect(() => {
    setMatchedUsers(
      users.filter(
        ({ name, email }) =>
          (email.toLowerCase().includes(inputValue.toLowerCase()) ||
            name.toLowerCase().includes(inputValue.toLowerCase())) &&
          inputValue.length > 0
      )
    );
  }, [inputValue]);

  const onSelectHandle = (val, user) => {
    const findedUser = user === null ? users.find(({ email }) => email === inputValue) || null : user;

    if (!validateEmail(inputValue).test) {
      setShowEmailError(true);
    } else {
      onCollaboratorSelect(val, findedUser);
    }
  };

  const renderMatchedUsers = matchedUsers.map((itm, i) => (
    <div
      role="button"
      tabIndex={-1}
      key={`matched_${itm.id}_${i}`}
      className="fz-collaborate-search__itm"
      onClick={() => onSelectHandle(null, itm)}
    >
      <Layout.UserPane name={itm.name} info={itm.email} />
    </div>
  ));

  const onChangeHandle = ({ target: { value } = {} }) => {
    setInputValue(value);

    if (showEmailError) {
      setShowEmailError(false);
    }
  };

  return (
    <div className="fz-collaborate-search">
      <div className="fz-std-input">
        <div className="fz-std-input__field">
          {inputValue.length > 0 && (
            <div
              role="button"
              tabIndex={-1}
              className="fz-std-input__action fadeInFromLeft"
              onClick={() => onSelectHandle(inputValue, null)}
            >
              <Button.Simple icon={<MdSend />} color="lightgray" />
            </div>
          )}
          <input
            value={inputValue}
            type="text"
            className={`fz-std-input__control ${showEmailError ? "fz-std-input__control_error" : ""}`}
            placeholder="Invite user by email or name"
            onChange={onChangeHandle}
            onKeyDown={e => onEnterPress(e, inputValue, () => onSelectHandle(inputValue, null))}
          />
          {matchedUsers.length > 0 && (
            <div className="fz-collaborate-search__list fadeInFromBottom">{renderMatchedUsers}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollaborationSearch;
