import React from "react";
import { observer, inject } from "mobx-react";
import { MdAdd } from "react-icons/md";

import { useModal } from "utils/hooks";
import { capitalize } from "utils/helpers";
import Ico2 from "images/b2b/ico_2.svg";
import Button from "components/Button";
import AddLinkContent from "../AddLinkContent";
import Layout from "../../../../components/Layout";
import InfoPane from "../../../../components/InfoPane";
import Pane from "../../../../components/Pane";

const Links = ({ Settings: { employer, deleteSocialLink } }) => {
  const openAddLinkModal = useModal(<AddLinkContent />, "Edit link");

  return (
    <Pane.Two>
      <h2 className="fz-heading-title exp mb-3">
        Social media and websites
        {employer.social_links?.length > 0 && (
          <Button size="md" icon={<MdAdd />} color="yellow" onClick={openAddLinkModal}>
            Add new link
          </Button>
        )}
      </h2>

      {employer.social_links?.length > 0 ? (
        <InfoPane.Group gray className="mb-1">
          {employer.social_links.map(link => (
            <InfoPane.Item
              key={link.id}
              className="m-0 p-2"
              customTitle={
                <div className="fz-text fz-text_md fz-text_normal fz-text-color__black m-0">
                  {capitalize(link?.social_type?.replace("_", " "))}
                </div>
              }
              data={link.url}
              actions={
                <>
                  <Button.Simple className="ml-2" onClick={() => openAddLinkModal({ link })}>
                    Edit
                  </Button.Simple>
                  <Button.Simple className="ml-2" color="red" onClick={() => deleteSocialLink(link.id)}>
                    Remove
                  </Button.Simple>
                </>
              }
            />
          ))}
        </InfoPane.Group>
      ) : (
        <Layout.EmptyState
          narrow
          showImage
          customImage={<Ico2 />}
          title="Add your links"
          subtitle="Add links to your social accounts and get noticed more."
          actionButton={
            <Button icon={<MdAdd />} color="yellow" onClick={openAddLinkModal}>
              Add a link
            </Button>
          }
        />
      )}
    </Pane.Two>
  );
};

export default inject("Settings")(observer(Links));
