import React from "react";

import { useModal } from "utils/hooks";
import Folder from "../Folder";
import RemoveFolder from "../RemoveFolder";

const FolderList = ({ folders = [], deleteFolder }) => {
  const openAddContactDetailsModal = useModal(<RemoveFolder deleteFolder={deleteFolder} />, "");

  return (
    <>
      <h3 className="fz-heading-title mt-5 mb-1">Talent pool</h3>
      <div className="panel-list pb-2">
        <div className="row">
          {folders.map(folder => (
            <Folder key={folder.id} deleteFolder={openAddContactDetailsModal} {...folder} />
          ))}
        </div>
      </div>
    </>
  );
};

export default FolderList;
