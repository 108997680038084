import React, { useState, useMemo } from "react";
import cn from "classnames";
import { MdCheckCircle } from "react-icons/md";
import Layout from "../Layout";
import SkillTag from "../SkillTag";

const transform = (arrA, arrB) => arrA.filter(item => !arrB.some(elem => elem.name === item.name));

const SkillTooltipContent = item => (
  <div className="fz-tag__tooltip">
    {item.certificate_id !== null && (
      <div>
        <MdCheckCircle />
        <span>Has certificate</span>
      </div>
    )}
    {item.has_references && (
      <div>
        <MdCheckCircle />
        <span>Has reference</span>
      </div>
    )}
    {item.has_relevant_work_experience && (
      <div>
        <MdCheckCircle />
        <span>Has relevant working experience</span>
      </div>
    )}
  </div>
);

const sortArr = (arr, sortInactive) => {
  return arr
    .sort((a, b) => a.name > b.name)
    .sort(item => (item.matched_to_campaign ? -1 : 0))
    .map((itm, i) => {
      const wrapperClasses = cn(
        "fz-tag",
        {
          "fz-tag_active": itm.matched_to_campaign
        },
        {
          "fz-tag_grey100": !itm.matched_to_campaign && sortInactive
        }
      );

      return (
        <SkillTag
          className={wrapperClasses}
          key={i}
          text={itm.name}
          check={itm.has_references + itm.has_relevant_work_experience}
          tooltipContent={
            (itm.has_references || itm.has_relevant_work_experience || itm.certificate_id !== null) && (
              <SkillTooltipContent {...itm} />
            )
          }
        />
      );
    });
};

const UserCvRequiredSkills = ({ name = "", core = [], skills = [], required = [] }) => {
  const [isExpanded, setVisible] = useState(false);

  const collaspeClickHandle = () => {
    setVisible(!isExpanded);
  };

  const modifiedRequired = useMemo(() => transform(required, core), [required, core]);

  const modifiedSkills = useMemo(() => transform(skills, required), [required, skills]);

  const renderCoreSkills = useMemo(() => sortArr(core, true), [core]);

  const renderRequiredSkills = useMemo(() => sortArr(modifiedRequired, true), [modifiedRequired]);

  const renderUserSkills = useMemo(() => sortArr(modifiedSkills), [modifiedSkills]);

  const matchedSkills = arr => arr.filter(el => el.matched_to_campaign).length;

  return (
    <div className="fz-pane">
      {core.length > 0 && required.length > 0 ? (
        <>
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">
              Core Skills
              <div className="fz-badge fz-badge_grey200 ml-1">
                {matchedSkills(core)} of {core.length}
              </div>
            </h2>

            <div className="fz-tag-container">{renderCoreSkills}</div>

            <h2 className="fz-heading-title">
              Required Skills
              <div className="fz-badge fz-badge_grey200 ml-1">
                {matchedSkills(modifiedRequired)} of {modifiedRequired.length}
              </div>
            </h2>
            <div className="fz-tag-container">{renderRequiredSkills}</div>
            {isExpanded && (
              <>
                <h2 className="fz-heading-title mt-3">
                  Other {name}’s Skills
                  <div className="fz-badge fz-badge_grey200 ml-1">{skills.length}</div>
                </h2>
                <div className="fz-tag-container">{renderUserSkills}</div>
              </>
            )}
          </div>

          <Layout.CollapseButton
            expanded={isExpanded}
            expandText="Show less"
            collapseText={`Show all ${name}’s skills`}
            onClickHandle={collaspeClickHandle}
            withoutShadow
          />
        </>
      ) : (
        <div className="fz-pane__container fz-pane__container_case-two">
          <h2 className="fz-heading-title">
            All {name}’s Skills
            <div className="fz-badge fz-badge_grey200 ml-1">{skills.length}</div>
          </h2>

          <div className="fz-tag-container">{renderUserSkills}</div>
        </div>
      )}
    </div>
  );
};

export default UserCvRequiredSkills;
