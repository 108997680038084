import React from "react";
import { Lock } from "@styled-icons/boxicons-solid";
import { Label } from "./styled";

const LabelUseForUnlock = ({ isUnlocked }) => {
  if (isUnlocked) {
    return null;
  }

  return (
    <Label>
      <Lock width="12px" height="16px" color="#fff" style={{ marginRight: "6px" }} />
      Use <strong>1</strong> unlock
    </Label>
  );
};

export default LabelUseForUnlock;
