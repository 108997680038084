import React, { useState } from "react";
import Button from "components/Button";

const HandleDescriptionContent = ({ description, updateDescription, close }) => {
  const [error, setErrors] = useState();
  const [processing, setProcessing] = useState(false);
  const [body, setBody] = useState(description);

  const handleDescriptionUpdate = () => {
    setProcessing(true);
    updateDescription(body).then(errors => {
      setProcessing(false);
      if (errors) setErrors(errors);
      else close();
    });
  };

  return (
    <>
      <textarea
        onFocus={() => setErrors()}
        name="description"
        rows="7"
        className={`fz-textarea mb-1${error ? " error" : ""}`}
        placeholder="Enter your text here"
        value={body}
        disabled={processing}
        onChange={e => setBody(e.target.value)}
      />
      {error && <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{error}</div>}
      <div className="fz-modal__actions mt-0">
        <Button color="gray" disabled={processing} onClick={close}>
          Cancel
        </Button>
        <Button color="blue" disabled={processing} onClick={handleDescriptionUpdate}>
          Save
        </Button>
      </div>
    </>
  );
};

export default HandleDescriptionContent;
