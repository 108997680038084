import React, { useMemo } from "react";
import Select from "react-select";
import { MdArrowUpward } from "react-icons/md";
import Subscription from "../../b2b/components/Subscription";

const SortingSelect = ({ options = [], defaultOption, sortOrder = "desc", onChangeSorting, onChangeOrder }) => {
  const SelectComponent = useMemo(
    () => (
      <Select
        defaultValue={defaultOption || options[0]}
        options={options}
        value={defaultOption}
        isMulti={false}
        className="fz-sorting-select"
        classNamePrefix="fz-sorting-select"
        isSearchable={false}
        role="button"
        tabIndex="0"
        onChange={({ value }) => onChangeSorting(value)}
      />
    ),
    [defaultOption]
  );

  const onExtraFilterClick = () => {
    onChangeOrder(sortOrder === "desc");
  };

  return (
    <Subscription.Upgrade permission="sorting">
      <div className="fz-sorting-select-container">
        {SelectComponent}
        <div
          className={`fz-iconed-flat-btn ml-1 ${sortOrder === "desc" ? "fz-iconed-flat-btn_rotated" : ""}`}
          onClick={onExtraFilterClick}
          role="button"
          tabIndex="0"
        >
          <MdArrowUpward />
        </div>
      </div>
    </Subscription.Upgrade>
  );
};

export default SortingSelect;
