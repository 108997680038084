import styled from "styled-components";

export const SelectViewComponent = styled.div`
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: ${({ $isNewPopup }) => ($isNewPopup ? "0" : "10px 12px")};
  max-width: ${({ $width }) => $width || "240px"};
  width: 100%;
  flex: none;

  .fz-dropdown__trigger {
    width: 100%;
  }

  .fz-dropdown-nav_with-search {
    width: 270px;
  }

  .fz-dropdown-itm:first-child {
    margin-bottom: ${({ $isCustomPromp }) => ($isCustomPromp ? "0" : "9px")};
    border-bottom: 1px solid #eeeeee;
    color: #408bfc;
  }

  ${({ $isCustomPromp }) =>
    $isCustomPromp
      ? `
        .fz-dropdown-itm:nth-child(2) {
            color: #333333;
            pointer-events: none;
          }
        `
      : ""}

  ${({ $dividerLength }) =>
    $dividerLength
      ? `
        .fz-dropdown-itm:nth-child(${$dividerLength}) {
            margin-bottom: 9px;
            border-bottom: 1px solid #eeeeee;
          }
        `
      : ""}

  ${({ $disabled }) =>
    $disabled
      ? `
        background-color: #EEE;
        pointer-events: none;
        `
      : ""}

  ${({ $isAddDots }) =>
    $isAddDots &&
    `
      .fz-simple-btn span {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      
      .fz-dropdown-nav__list {
        overflow: hidden;
      }
  `}

  .fz-dropdown-itm:nth-child(4) {
    margin-bottom: ${({ $isShowPriority }) => ($isShowPriority ? "9px" : "0")};
    border-bottom: ${({ $isShowPriority }) => ($isShowPriority ? "1px solid #eeeeee" : "none")};
  }
`;

export const Label = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
`;

export const SelectViewContainer = styled.div`
  max-height: 300px;
  overflow: none;
`;

export const Trigger = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;

  svg {
    fill: #656565;
    width: 26px;
    height: 18px;
  }
`;
