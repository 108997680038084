import React from "react";
import Checkbox from "../Checkbox";

export const Shortcuts = ({ shortcutsList, tags, handleCheck, handleUncheck }) => {
  if (!shortcutsList.length) {
    return <div className="fz-info-text__text">No available shortcuts</div>;
  }

  return shortcutsList.map((sc, i) => (
    <div key={`sc_${sc}`} className="fz-info-text" role="listbox">
      <Checkbox
        checked={tags.includes(sc)}
        onCheck={() => handleCheck(sc)}
        onUncheck={() => handleUncheck(sc)}
        id={`sc_${i}`}
        labelClass="tagscope"
      >
        {sc}
      </Checkbox>
    </div>
  ));
};
