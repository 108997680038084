import React from "react";

import FlashMessage from "../FlashMessage";
import Loading from "../../../../assets/images/loading.svg";

const ScreenStateMessage = ({ state, children }) => {
  return (
    <FlashMessage
      type={state.type}
      hide={state.hidden}
      buttons={null}
      closeButton={null}
      message={state.message}
      reject={state.reject}
      image={<Loading className="spin" />}
    >
      {children}
    </FlashMessage>
  );
};

export default ScreenStateMessage;
