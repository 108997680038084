import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

import blueRightArrow from "images/blue_right-arrow.png";
import { navigateTo } from "utils/urlHelpers";
import fuzu_logo from "images/new_fuzu_logo_black.png";
import Logo from "components/Logo";
import { Card, Image, Text } from "../../../b2c/components/Core";
import { LoadingContent } from "../../../b2c/components/Core/Spinner";
import BaronaLogo from "../../components/BaronaLogo";
import { theme } from "../../../b2c/contexts/theme";

const getHeight = () => {
  const { body } = document;
  const html = document.documentElement;

  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
};

const Current = ({ User: { getEmployersList, selectEmployer, employers } }) => {
  const [height, setHeight] = useState(getHeight);

  useEffect(() => {
    setHeight(getHeight());
  }, [employers]);

  const handleEmployerClick = id => {
    selectEmployer({ employer_id: id }).then(() => {
      navigateTo("/jobs");
    });
  };

  useEffect(() => {
    if (employers && employers.length === 1) {
      handleEmployerClick(employers[0].id);
    }
  }, [employers]);

  useEffect(() => {
    document.body.style.margin = "0px";
    getEmployersList();
    return () => {
      document.body.style.margin = "8px";
    };
  }, []);

  return (
    <LoadingContent loading={!employers || employers.length === 1} size="50px" margin="50px 0 0 0">
      <Content height={height} backgroundColor={theme.primaryEmployerBackground}>
        <LogoWrapper>
          <BaronaLogo>
            <Logo logo={fuzu_logo} />
          </BaronaLogo>
        </LogoWrapper>
        <StyledCard>
          <Title fontSize={20} fontWeight={600} lineHeight="26px">
            Select the company you want to manage
          </Title>

          {employers.map(({ logo, name, id }) => {
            return (
              <EmployerCard key={id} onClick={() => handleEmployerClick(id)}>
                {logo && <EmployerLogo src={logo} alt="fuzu_logo" height={44} width={44} />}
                <EmployerTitle fontSize={14} fontWeight={500} lineHeight="26px">
                  {name}
                </EmployerTitle>
                <Arrow src={blueRightArrow} alt="blue Right Arrow" height={15} width={18} />
              </EmployerCard>
            );
          })}
          <div />
        </StyledCard>
      </Content>
    </LoadingContent>
  );
};

const EmployerLogo = styled(Image)`
  top: 6px;
  left: 17px;
  position: absolute;
`;

const EmployerTitle = styled(Text)`
  top: 16px;
  left: 113px;
  position: absolute;
`;

const Arrow = styled(Image)`
  top: 21px;
  right: 17px;
  position: absolute;
`;

const Content = styled.div`
  background: ${({ backgroundColor }) => backgroundColor || "#FFE140"};
  height: ${({ height }) => height}px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  height: auto;
  width: 498px;
  background: #fcfcfc;
  margin: 45px auto;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: overlay;
`;

const Title = styled(Text)`
  margin: 0 auto;
  padding-top: 28px;
`;

const EmployerCard = styled(Card)`
  box-shadow: 0px 5px 15px 3px rgba(33, 33, 33, 0.05);
  border-radius: 5px;
  height: 58px;
  background: #ffffff;
  margin: 20px;
  cursor: pointer;
  position: relative;
`;

const LogoWrapper = styled.div`
  text-align: center;
  padding: 20px;
`;

export default inject("User")(observer(Current));
