import styled from "styled-components";

export const Talent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  background: #408bfc;
  border-radius: 13px;
  padding: 6px 12px;
`;

export const TalentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: ${({ isShortHeight }) => (isShortHeight ? "72px" : "auto")};
  align-items: baseline;
  overflow: hidden;
`;

export const ShowAll = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #62a1ff;
  margin-top: 16px;
  background: transparent;
`;
