import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import Button from "components/Button";
import FlashMessage from "../FlashMessage";

const defaults = {
  type: "default",
  hidden: true,
  message: ""
};

const ScreenStateMessage = ({ state, loadingMessage = "Loading...", scopes = [], children, update, redirect }) => {
  const { loading, error, message = loadingMessage, type = "", callback } = state;
  // Defining flash message popper state
  const [popperState, setPopper] = useState(defaults);

  const handleRedirect = () => {
    setPopper({ ...popperState, hidden: true });
    redirect({ filters: { scope: callback() } });
    if (update) update();
  };

  const handleCallback = () => {
    setPopper({ ...popperState, hidden: true });
    callback();
    if (update) update();
  };

  const SuccessButton = useMemo(() => {
    const newScope = scopes.find(scope => type.includes(scope.value));

    return (
      <>
        {newScope && (
          <Button.Simple color="blue" style={{ marginRight: 8 }} onClick={handleRedirect}>
            Go to {newScope.label}
          </Button.Simple>
        )}
        {/search_save_success/.test(type) && (
          <Button.Simple
            color="blue"
            style={{ marginRight: 8 }}
            onClick={() => {
              redirect();
              setPopper(defaults);
            }}
          >
            Saved searches
          </Button.Simple>
        )}
        {/_talent_shortlist_success/.test(type) && (
          <Button.Simple
            color="blue"
            style={{ marginRight: 8 }}
            onClick={() => {
              redirect();
              setPopper(defaults);
            }}
          >
            Go to campaign
          </Button.Simple>
        )}
        <Button.Simple
          color="gray"
          onClick={() => {
            setPopper(defaults);
            if (!type.includes("_user_unlocked_success") && update) update();
          }}
        >
          OK
        </Button.Simple>
      </>
    );
  }, [scopes, type, callback]);

  // Conditional rendering of flash message buttons
  const renderFmButtons = useMemo(() => {
    switch (popperState.type) {
      default:
        return SuccessButton;
      case "loading":
        return null;
      case "error":
        return (
          <>
            <Button.Simple color="red" onClick={handleCallback}>
              RETRY
            </Button.Simple>
            <Button.Simple className="ml-1" color="red" onClick={() => setPopper(defaults)}>
              CLOSE
            </Button.Simple>
          </>
        );
    }
  }, [popperState.type, loading, type, error]);

  // Closing state message
  const renderCloseButton = useMemo(() => {
    switch (popperState.type) {
      default:
        return;
      case "loading":
        return;
      case "error":
        return <span className="fz-simple-btn fz-simple-btn_red ml-1">CLOSE</span>;
    }
  }, [popperState.type]);

  // Defining loading state flash message popper behaviour
  useEffect(() => {
    if (!error && !type.includes("success")) {
      const currentState = {
        ...popperState,
        type: "loading",
        message: loadingMessage
      };

      setPopper(currentState);

      const popperTimeout = setTimeout(() => {
        setPopper({
          ...currentState,
          hidden: !loading
        });
      }, 500);

      return () => clearTimeout(popperTimeout);
    }
  }, [loading, error, type, loadingMessage]);

  // Defining default flash message behaviour
  useEffect(() => {
    if ((!loading && popperState.hidden && message) || error) {
      setPopper({
        type: error ? "error" : type === "success" ? type : "default",
        hidden: false,
        message
      });

      if (!error && update && type.includes("_user_unlocked_success")) {
        update();
      }
    }

    // show success message
    if (
      !loading &&
      !popperState.hidden &&
      message &&
      !error &&
      type.includes("success") &&
      !type.includes("loading") &&
      state.isShowSuccessMessage
    ) {
      setPopper({
        type: "success",
        hidden: true,
        message
      });
    }
  }, [type, error]);

  return (
    <FlashMessage
      type={popperState.type}
      hide={popperState.hidden}
      buttons={loading ? null : renderFmButtons}
      closeButton={renderCloseButton}
      message={popperState.message}
      reject={state.reject}
    >
      {children}
    </FlashMessage>
  );
};

ScreenStateMessage.propTypes = {
  /** screen state */
  state: PropTypes.object.isRequired,
  /** loading message */

  loadingMessage: PropTypes.string
};

export default ScreenStateMessage;
