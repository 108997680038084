import styled from "styled-components";
import { theme } from "../../../../../b2c/contexts/theme";

export const Modal = styled.div`
  max-width: 600px;
  min-height: 200px;
  margin-top: -55px;

  @media screen and (min-width: 768px) {
    min-width: 672px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 24px 0;
`;

export const Text = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin: 0 0 24px 0;

  span {
    color: #333;
  }
`;

export const Submit = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  width: 102px;
  flex: none;
  border-radius: 8px;
  background: ${theme.primaryButtonBackground};
  color: ${theme.primaryButtonLabelColor};
  display: block;
  margin: 24px 0 0 auto;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CopyLinkContent = styled.div`
  margin-bottom: 24px;
  display: flex;
  gap: 16px;
`;

export const CopyLinkField = styled.span`
  overflow: hidden;
  color: #666;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 10px 12px;
`;

export const CopyLinkButton = styled.button`
  border-radius: 8px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 82px;
  gap: 8px;
  flex: none;

  svg {
    width: 16px;
    height: 16px;
    fill: #444444;
  }
`;

export const CreatedLinkBanner = styled.div`
  border-radius: 6px;
  background: #f3f8ff;
  padding: 14px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  flex: none;

  span {
    color: #333;
    font-weight: 600;
  }
`;
