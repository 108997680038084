import React, { useState, useEffect, useMemo } from "react";
import ProfileAvatar from "components/ProfileAvatar";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import cn from "classnames";
import {
  MdRestore,
  MdModeEdit,
  MdLink,
  MdContentCopy,
  MdArchive,
  MdPinDrop,
  MdBusiness,
  MdPermIdentity,
  MdWarning,
  MdCheck
} from "react-icons/md";
import { InfoCircle, Link as LinkIcon } from "@styled-icons/boxicons-regular";

import { getEditCampaignPath, getDuplicateCampaignPath, getConversationPath } from "utils/urlHelpers";
import { b2cUrl, copyCodeToClipboard, daysSince } from "utils/helpers";
import Button from "components/Button";
import Status from "../../../../components/Status";
import Subscription from "../../../../components/Subscription";
import Placeholder from "../JobItemPlaceholder";
import FastApply from "../../../../components/FastApply";
import { applicationTypes, promotionStatuses } from "../../../../constants/campaign";
import {
  PromotionActive,
  PromotionContent,
  PromotionExpired,
  TooltipContent,
  tooltipContentStyles
} from "../../../JobDetailScreen/components/JobDetailHeading/styled";
import Tooltip from "../../../../../components/Tooltip";

const JobItem = ({
  path,
  applicants_count,
  conversations,
  days_left,
  days_remaining,
  employer_name,
  id,
  location,
  new_applicants_count,
  owner_short_name,
  shortlisted_count,
  phase,
  title = "",
  views_count,
  archiveCampaign,
  viewed,
  hide = false,
  isExternal,
  removed = false,
  application_type: applicationType,
  fast_apply_candidates_count: fastApplyCandidatesCount,
  integration_health_status: promotionStatus,
  has_guest_link: isShared
}) => {
  const Title = useMemo(() => (title ? parse(title) : ""), [title]);
  const [isRemoved, setRemoved] = useState(false);
  const [content, setContent] = useState(true);
  const isFastApply = applicationTypes.fastApply === applicationType;

  useEffect(() => {
    if (removed && !isRemoved) setRemoved(true);
  }, [removed]);

  const copyPath = b2cUrl(path);

  const jobPhase = useMemo(() => {
    switch (phase) {
      default:
        return phase.toUpperCase();
      case "active":
        return "OPEN";
      case "inactive":
        return "CLOSED";
    }
  }, [phase]);

  // X.XK views formatter
  const viewsCount = useMemo(
    () =>
      views_count
        ? Math.abs(views_count) > 999
          ? `${(Math.abs(views_count) / 1000).toFixed(1).replace(".", ",")}K`
          : Math.abs(views_count)
        : 0,
    [views_count]
  );

  const renderPromotionStatus = () => {
    if (promotionStatus === promotionStatuses.failed) {
      return (
        <PromotionContent>
          <PromotionExpired>
            <MdWarning /> Job board issues
          </PromotionExpired>
          <Tooltip
            classNamePopup="fz-tooltip-content_modal"
            tooltipPosition={["right center"]}
            contentStyle={tooltipContentStyles}
            className="tooltipTrigger"
            tooltipContent={
              <TooltipContent>
                Post to this job board was added via API and cannot be edited directly in the app.
              </TooltipContent>
            }
          >
            <InfoCircle />
          </Tooltip>
        </PromotionContent>
      );
    }

    if (promotionStatus === promotionStatuses.active) {
      return (
        <PromotionActive>
          <MdCheck />
          Posted on job boards
        </PromotionActive>
      );
    }

    return null;
  };

  return (
    <CSSTransition in={!isRemoved} timeout={500} onExited={() => setContent(false)} classNames="panel">
      {content ? (
        <div
          className={cn("fz-panel fadeInFromBottom", { "fz-panel_visited": viewed }, { hide })}
          data-test-scope="campaign-card"
        >
          <div className="fz-panel__header">
            <div className="fz-panel__statuses">
              <Status type={phase !== "active" ? "gray-outline" : ""}>{jobPhase}</Status>
              {phase !== "draft" && typeof days_remaining === "number" && (
                <Status type={days_left <= 0 ? "gray" : "secondary"} icon={<MdRestore />}>
                  {daysSince(days_remaining)}
                </Status>
              )}
              {renderPromotionStatus()}

              {isShared && (
                <PromotionActive>
                  <LinkIcon />
                  Shared
                </PromotionActive>
              )}
            </div>
            <Subscription.Upgrade permission="edit_job">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="fz-panel__actions" data-test-scope="card-actions">
                  {!isExternal && (
                    <Button
                      icon={<MdModeEdit />}
                      size="sm"
                      color="gray"
                      data-test-target="edit-action"
                      tag="a"
                      tabIndex="0"
                      role="link"
                      href={getEditCampaignPath(id)}
                    >
                      Edit job
                    </Button>
                  )}
                  <Button
                    icon={<MdLink />}
                    size="sm"
                    color="gray"
                    onClick={() => copyCodeToClipboard(copyPath)}
                    data-test-target="copy-action"
                    role="button"
                    tabIndex="0"
                  >
                    Copy job link
                  </Button>
                  {!isExternal && (
                    <>
                      <Button
                        icon={<MdContentCopy />}
                        size="sm"
                        color="gray"
                        data-test-target="duplicate-action"
                        tag="a"
                        href={getDuplicateCampaignPath(id)}
                        role="link"
                        tabIndex="0"
                      >
                        Duplicate job
                      </Button>
                      {phase !== "archived" && (
                        <Button
                          icon={<MdArchive />}
                          size="sm"
                          color="warning"
                          onClick={() => archiveCampaign(id)}
                          data-test-target="archive-action"
                          role="button"
                          tabIndex="0"
                        >
                          Archive
                        </Button>
                      )}
                    </>
                  )}
                  {phase === "archived" && (
                    <Button
                      icon={<MdLink />}
                      size="sm"
                      color="gray"
                      onClick={() => copyCodeToClipboard(id)}
                      data-test-target="copy-action"
                      role="button"
                      tabIndex="0"
                    >
                      Copy job link
                    </Button>
                  )}
                </div>
                <FastApply isShow={isFastApply} margin="0 0 0 10px" />{" "}
              </div>
            </Subscription.Upgrade>
          </div>
          <div className="fz-panel__container">
            <div className="fz-panel__content">
              <Subscription.Upgrade permission="candidate_list_access">
                <Link className="fz-panel__name" to={`/jobs/${id}/applicants`} replace role="link" tabIndex="0">
                  {Title}
                </Link>
              </Subscription.Upgrade>
              <div className="fz-panel__info">
                <p className="fz-info-string">
                  <MdPinDrop className="fz-info-string__icon" />
                  <span className="fz-info-string__text">{location}</span>
                </p>
                <p className="fz-info-string">
                  <MdBusiness className="fz-info-string__icon" />
                  <span className="fz-info-string__text">{employer_name}</span>
                </p>
                <p className="fz-info-string">
                  <MdPermIdentity className="fz-info-string__icon" />
                  <span className="fz-info-string__text">{owner_short_name}</span>
                </p>
              </div>
            </div>
            <div className="fz-panel__additional">
              <div className="fz-infobox">
                <h5 className="fz-infobox__title">{viewsCount}</h5>
                <p className="fz-infobox__info">Views</p>
              </div>
              <div className="fz-infobox">
                <h5 className="fz-infobox__title">
                  {applicants_count}
                  {new_applicants_count > 0 && <div className="fz-badge">{new_applicants_count}</div>}
                </h5>
                <p className="fz-infobox__info">Applicants</p>
              </div>
              <div className="fz-infobox">
                <h5 className="fz-infobox__title">{shortlisted_count}</h5>
                <p className="fz-infobox__info">Shortlisted</p>
              </div>

              {isFastApply && fastApplyCandidatesCount.applied ? (
                <div className="fz-infobox">
                  <h5 className="fz-infobox__title">
                    {fastApplyCandidatesCount.completed} of {fastApplyCandidatesCount.applied}
                  </h5>
                  <p className="fz-infobox__info">Completed</p>
                </div>
              ) : null}
            </div>
          </div>
          {conversations.length > 0 && (
            <div className="fz-panel__footer">
              <div className="fz-unread-list">
                <div className="fz-unread-list__counter">
                  <div className="fz-infobox fz-infobox_simple">
                    <h5 className="fz-infobox__title">{conversations.length}</h5>
                    <p className="fz-infobox__info">Messages</p>
                  </div>
                </div>
                <div className="fz-unread-list__info">
                  <div className="fz-profile-list">
                    {conversations.map((conv, index) => (
                      <a href={getConversationPath(conv.id)} key={`${id}_thread_${index}`}>
                        <ProfileAvatar avatar={conv.participant_photo_url} badgeCounter={conv.unread_messages_count} />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
};

JobItem.propTypes = {
  /** array of conversation (actually only the count is needed) */
  conversations: PropTypes.arrayOf(PropTypes.object),
  /** number of days left for this position */
  applicants_count: PropTypes.number,
  /** name of employer */
  employer_name: PropTypes.string,
  /** campaign id */
  id: PropTypes.number,
  /** campaign location */
  location: PropTypes.string,
  /** number of new applicants */
  new_applicants_count: PropTypes.number,
  /** owner's short name */
  owner_short_name: PropTypes.string,
  /** number of shortlisted applicants */
  shortlisted_count: PropTypes.number,
  /** campaign phase (string) */
  phase: PropTypes.string,
  /** campaign title */
  title: PropTypes.string,
  /** total view count */
  views_count: PropTypes.number,
  /** function which archives campaign */
  archiveCampaign: PropTypes.func,
  /** whether the campaign was viewed before */
  viewed: PropTypes.bool,
  /** whether to hide itm from list */
  hide: PropTypes.bool
};

Object.assign(JobItem, { Placeholder });

export default JobItem;
