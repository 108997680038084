import styled from "styled-components";

export const Content = styled.div`
  margin-top: 20px;
`;

export const ImageContent = styled.div`
  width: 106px;
  height: 138px;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #333333;
  display: block;
  margin-top: 24px;
`;
