import React from "react";
import styled from "styled-components";

const Pane = ({ className, children }) => {
  return <div className={`fz-pane ${className}`}>{children}</div>;
};

const SyledPane = styled(Pane)`
  ${({ extraCss }) => extraCss}
`;

const One = ({ children, extraCss }) => {
  return (
    <SyledPane extraCss={extraCss}>
      <div className="fz-pane__container fz-pane__container_case-one">{children}</div>
    </SyledPane>
  );
};

const Two = ({ children, extraCss }) => {
  return (
    <SyledPane extraCss={extraCss}>
      <div className="fz-pane__container fz-pane__container_case-two">{children}</div>
    </SyledPane>
  );
};

const Split = ({ children, extraCss }) => {
  return (
    <SyledPane extraCss={extraCss}>
      {React.Children.map(children, child => (
        <div className="fz-pane__container fz-pane__container_case-two fz-pane__container_split">{child}</div>
      ))}
    </SyledPane>
  );
};

export default Object.assign(SyledPane, { One, Two, Split });
