import React from "react";
import { MdHourglassEmpty } from "react-icons/md";

import { convertMonths } from "utils/helpers";
import UserCvCircleMatch from "b2b/components/UserCvCircleMatch";
import Subscription from "b2b/components/Subscription";
import MatcherChart from "b2b/components/MatcherChart";
import { EmptyStateTest, EmptyStateTestName, EmptyStateTestStatus } from "./styled";

const UserCvInfo = ({
  cv_match,
  total_match,
  talent_match,
  personality_match,
  highest_education_degree,
  relevant_experience_months,
  isExistPersonality,
  isExistTalent
}) => {
  const renderPersonalityTests = permitted => {
    if (isExistPersonality) {
      if (Number(personality_match) === personality_match) {
        return <MatcherChart title="Personality Score" score={Math.round(permitted ? personality_match : 0)} />;
      }

      return (
        <EmptyStateTest>
          <EmptyStateTestName>
            <MdHourglassEmpty />
            Personality score
          </EmptyStateTestName>
          <EmptyStateTestStatus>Pending</EmptyStateTestStatus>
        </EmptyStateTest>
      );
    }
  };

  const renderTalentTests = permitted => {
    if (isExistTalent && talent_match) {
      if (Number(talent_match) === talent_match) {
        return <MatcherChart title="Talent Match" score={Math.round(permitted ? talent_match : 0)} />;
      }

      return (
        <EmptyStateTest>
          <EmptyStateTestName>
            <MdHourglassEmpty />
            Talent match
          </EmptyStateTestName>
          <EmptyStateTestStatus>Pending</EmptyStateTestStatus>
        </EmptyStateTest>
      );
    }
    return null;
  };

  return (
    <Subscription className="fz-pane" horizontal permission="job_matching_with_ranking" element="Candidate ranking">
      {permitted => (
        <div className="fz-pane__container">
          <div className="fz-scoring-section ">
            {Boolean(total_match) && (
              <div className="fz-scoring-section__main">
                <UserCvCircleMatch
                  isShowCircleMatch={typeof total_match === "number"}
                  percentValue={Math.round(total_match || 0)}
                  innerStats
                />
              </div>
            )}
            <div className="fz-scoring-section__additional">
              <div className="fz-scoring-section__info">
                {cv_match ? <MatcherChart title="CV Match" score={Math.round(permitted ? cv_match : 0)} /> : <></>}
                {renderPersonalityTests(permitted)}
                {renderTalentTests(permitted)}
              </div>
              <span className="fz-info-text mr-3">
                <span className="fz-info-text__text">
                  <b>
                    {permitted
                      ? relevant_experience_months
                        ? convertMonths(relevant_experience_months)
                        : "None"
                      : "No permission"}
                  </b>{" "}
                  Relevant exp.
                </span>
              </span>
              {highest_education_degree && (
                <span className="fz-info-text">
                  <span className="fz-info-text__text">
                    <b>{permitted ? highest_education_degree : "No permission"} degree </b> Education
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </Subscription>
  );
};

export default UserCvInfo;
