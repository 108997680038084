import React from "react";
import { Group, BellRing, Conversation, Like, CommentError } from "@styled-icons/boxicons-solid";

import howItWorks1 from "images/b2b/howItWorks1.png";
import howItWorks2 from "images/b2b/howItWorks2.png";
import howItWorks3 from "images/b2b/howItWorks3.png";
import { Title, Text, Image, Button } from "b2c/components/Core";
import { Modal, HowFastApplyWorkElement, ImageContent, Cycle } from "./styled";

const HowFastApplyWork = () => {
  return (
    <Modal>
      <HowFastApplyWorkElement>
        <Cycle>
          <Group width="20px" height="20px" />
        </Cycle>
        <Title
          fontSize="18px"
          fontWeight="500"
          lineHeight="21px"
          color="#333333"
          margin="0 0 16px 0"
          style={{ display: "block" }}
        >
          Candidate applies to your job
        </Title>
        <Text fontSize="14px" lineHeight="140%" color="#666666" margin="0 0 16px 0" style={{ display: "block" }}>
          After you have published the campaign, candidates submit their Fuzu profiles for your review. You can access
          the profiles and review which candidates look interesting.
        </Text>
        <ImageContent>
          <Image src={howItWorks1} alt="logo" borderRadius="0" />
        </ImageContent>
      </HowFastApplyWorkElement>
      <HowFastApplyWorkElement>
        <Cycle>
          <Like width="20px" height="20px" />
        </Cycle>
        <Title
          fontSize="18px"
          fontWeight="500"
          lineHeight="21px"
          color="#333333"
          margin="0 0 16px 0"
          style={{ display: "block" }}
        >
          Shortlist interesting candidates
        </Title>
        <Text fontSize="14px" lineHeight="140%" color="#666666" margin="0 0 16px 0" style={{ display: "block" }}>
          Shortlist the interesting candidates and they will be asked to complete tests and other assignments.
        </Text>
        <ImageContent>
          <Image src={howItWorks2} alt="logo" borderRadius="0" />
        </ImageContent>
      </HowFastApplyWorkElement>
      <HowFastApplyWorkElement>
        <Cycle>
          <BellRing width="20px" height="20px" />
        </Cycle>
        <Title
          fontSize="18px"
          fontWeight="500"
          lineHeight="21px"
          color="#333333"
          margin="0 0 16px 0"
          style={{ display: "block" }}
        >
          We notify you when shortlisted candidates have completed their assignments
        </Title>
        <Text fontSize="14px" lineHeight="140%" color="#666666" margin="0 0 16px 0" style={{ display: "block" }}>
          We will send you an email notification as soon as all the shortlisted candidates have completed their
          assignments and they are ready for your final review.
        </Text>
        <ImageContent>
          <Image src={howItWorks3} alt="logo" borderRadius="0" />
        </ImageContent>
      </HowFastApplyWorkElement>
      <HowFastApplyWorkElement hideLine>
        <Cycle>
          <Conversation width="20px" height="20px" />
        </Cycle>
        <Title
          fontSize="18px"
          fontWeight="500"
          lineHeight="21px"
          color="#333333"
          margin="0 0 16px 0"
          style={{ display: "block" }}
        >
          Start communicating directly with the best candidates
        </Title>
        <Text fontSize="14px" lineHeight="140%" color="#666666" margin="0 0 16px 0" style={{ display: "block" }}>
          To attract the attention of the best candidates you can start communicating with them directly through the
          platform chat.
        </Text>
      </HowFastApplyWorkElement>
      <Button.Link
        to="/"
        fontSize="14px"
        fontWeight="500"
        color="secondary200"
        theme="body"
        style={{ textAlign: "center", lineHeight: "16px" }}
        padding="15px 10px"
        width="calc(100% + 52px)"
        margin="0 -26px -36px -26px"
        height="56px"
      >
        <CommentError width="24px" height="24px" color="#408BFC" style={{ margin: "0 10px 0 0" }} />
        Contact us to share feedback or to report a bug
      </Button.Link>
    </Modal>
  );
};

export default HowFastApplyWork;
