import styled from "styled-components";

export const Modal = styled.div`
  max-width: 680px;
  min-height: 300px;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    min-width: 680px;
  }
`;

export const Cycle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe140;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export const HowFastApplyWorkElement = styled.div`
  margin-bottom: 30px;
  padding-left: 52px;
  padding-top: 12px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: #f3f3f3;
    top: 40px;
    left: 20px;
    margin-left: -2px;
    display: ${({ hideLine }) => (hideLine ? "none" : "block")};
  }

  &:last-child&:before {
    display: none;
  }
`;

export const ImageContent = styled.div`
  background-color: #f3f3f3;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  justify-content: center;
`;
