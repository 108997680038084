import React, { useState } from "react";

import { Modal, Description, Token, CheckboxText, Actions, Cancel, Delete } from "./styled";
import Checkbox from "../../../../components/Checkbox";

const DeleteToken = ({ close, keyCode, handleSubmit }) => {
  const [isActive, setIsActive] = useState(false);

  const onSubmit = () => {
    if (isActive) {
      handleSubmit();
      close();
    }
  };

  return (
    <Modal>
      <Token>{keyCode}</Token>
      <Description>
        All services currently using this key will no longer function. Deleting an API key cannot be reverted.
      </Description>

      <Checkbox checked={isActive} onCheck={() => setIsActive(true)} onUncheck={() => setIsActive(false)}>
        <CheckboxText>I understand that deleting the key is permanent and irreversible</CheckboxText>
      </Checkbox>

      <Actions>
        <Cancel onClick={close}>Keep it</Cancel>
        <Delete onClick={onSubmit} isDisabled={!isActive}>
          Yes, delete
        </Delete>
      </Actions>
    </Modal>
  );
};

export default DeleteToken;
