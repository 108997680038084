import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";

import ProfileAvatar from "components/ProfileAvatar";
import Button from "components/Button";

const Folder = ({ id, name, candidates_count, candidates = [], deleteFolder }) => {
  const [isRemoved, setRemoved] = useState(false);
  const remaining = candidates_count - candidates.length;

  return (
    <CSSTransition in={!isRemoved} timeout={500} classNames="panel-horizontal">
      <div className="fz-panel folder fadeInFromBottom ">
        <div className="fz-panel__container pt-4 pb-4">
          <div className="fz-applicant">
            <div className="fz-applicant__content">
              <Link to={`/headhunting/saved/folders/${id}`} className="fz-applicant__name fz-applicant__name_fixed">
                {name}
              </Link>
              <div className="fz-avatar-slim-list mt-5">
                <div className="fz-avatar-slim-list__info">
                  <div className="fz-infobox fz-infobox_simple">
                    <h5 className="fz-infobox__title">{candidates_count}</h5>
                    <p className="fz-infobox__info">Talents</p>
                  </div>
                </div>
                <div className="fz-avatar-slim-list__content">
                  {candidates.map(({ avatar }, index) => (
                    <ProfileAvatar key={`${name}-${index}`} avatar={avatar} />
                  ))}

                  {remaining > 0 && <div className="fz-avatar-slim-list__counter">+{remaining}</div>}
                </div>
              </div>
            </div>
            <div className="fz-applicant__actions">
              <div className="fz-panel__actions">
                <Button
                  icon={<MdClose />}
                  size="sm"
                  color="warning"
                  // .then(() => setRemoved(true))
                  onClick={() => deleteFolder({ id, name, count: candidates_count, setRemoved })}
                  role="button"
                  tabIndex="0"
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Folder;
