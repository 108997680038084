import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { MdSave } from "react-icons/md";
import { toJS } from "mobx";
import { useRouteMatch } from "react-router-dom";

import Button from "components/Button";
import ModalConsumer from "contexts/modal";
import { TABS } from "constants/tabs";
import Layout from "../Layout";
import TalentList from "../TalentList";
import FilterTools from "../FilterTools";
import BatchActions from "../BatchActions";
import Paginator from "../Paginator";
import TalentListTools from "../TalentListTools";
import SearchModalContent from "../SearchModalContent";
import Subscription from "../Subscription";

const ListContent = inject("store")(
  observer(
    ({
      store: {
        state: { loading = true },
        params: { page = 1, per_page = 10 },
        talents,
        applyParams,
        applyFilter,
        searchTerm,
        appliedFilters,
        talentFilters,
        talentCount,
        appliedFilterCount,
        resetFilters,
        setCurrentTab,
        removeFromFolder,
        getAvailableJobForCandidate,
        availableJobsForCandidate,
        getComments,
        addComment,
        createShareCandidateUserForComment,
        createCommentShareCandidate,
        removeComment,
        addTag,
        removeTag,
        unlockUser,
        handleStopUpdatePage
      },
      Heading = () => null,
      BatchContent = () => null,
      allowSaving = true,
      isShowMove,
      stageRelationsOptions,
      moveToStage,
      activeFolderStageId
    }) => {
      const { path } = useRouteMatch();
      const tab = path.slice(path.lastIndexOf("/") + 1, path.length);
      const [selectedTalents, setSelected] = useState([]);
      const [removed, setRemoved] = useState([]);
      const pageCount = Math.ceil(talentCount / +per_page);

      useEffect(() => {
        setSelected([]);
      }, [removed]);

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        setCurrentTab(tab);
      }, [tab]);

      useEffect(() => {
        if (removed.length > 0 && removed.length === talents.length) {
          setRemoved([]);
          applyParams({ page, per_page });
        }
      }, [removed]);

      const handleSelection = id => {
        setSelected(prev => (prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]));
      };

      return (
        <>
          {Heading}

          <BatchActions
            selectedSomeone={selectedTalents.length > 0}
            selected={selectedTalents.length > 0 && selectedTalents.length === +per_page}
            clearSelection={() => setSelected([])}
            selectAll={() => setSelected(talents.map(talent => talent.id))}
          >
            <BatchContent
              selectedTalents={selectedTalents}
              removeFromFolder={removeFromFolder}
              setRemoved={setRemoved}
            />
          </BatchActions>
          <Layout.ContainerWithSidebar
            mainContent={
              <>
                <TalentListTools
                  talentCount={talentCount}
                  applyParams={applyParams}
                  applyFilter={applyFilter}
                  per_page={per_page}
                  isOpenApplication={tab === TABS.OPEN}
                  currentSortby={appliedFilters.sort_by}
                />
                <TalentList
                  tab={tab}
                  loading={loading}
                  page={page}
                  per_page={per_page}
                  talents={talents}
                  handleSelection={handleSelection}
                  selectedTalents={selectedTalents}
                  removed={removed}
                  removeFromFolder={(folder, ids) =>
                    removeFromFolder(folder, ids).then(() => setRemoved(prev => prev.concat(ids)))
                  }
                  getComments={getComments}
                  addComment={addComment}
                  createShareCandidateUserForComment={createShareCandidateUserForComment}
                  createCommentShareCandidate={createCommentShareCandidate}
                  removeComment={removeComment}
                  addTag={addTag}
                  removeTag={removeTag}
                  getAvailableJobForCandidate={getAvailableJobForCandidate}
                  availableJobsForCandidate={availableJobsForCandidate}
                  unlockUser={unlockUser}
                  handleStopUpdatePage={handleStopUpdatePage}
                  isShowMove={isShowMove}
                  stageRelationsOptions={stageRelationsOptions}
                  moveToStage={moveToStage}
                  activeFolderStageId={activeFolderStageId}
                />
                <Paginator
                  page={+page}
                  pageCount={pageCount}
                  updateList={applyParams}
                  hiddenLastPage={pageCount > 10 && path === "/headhunting/find"}
                />
              </>
            }
            sideContent={
              <>
                <FilterTools
                  applyFilter={applyFilter}
                  appliedFilters={toJS(appliedFilters)}
                  filters={toJS(talentFilters)}
                  filterCount={appliedFilterCount}
                  resetAllFilters={() => resetFilters(false, false)}
                  loading={loading}
                />
                {allowSaving && (appliedFilterCount > 0 || searchTerm) && tab !== "open" && (
                  <ModalConsumer>
                    {({ toggle }) => (
                      <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline">
                        <Button
                          style={{ width: "100%", margin: "25px 0" }}
                          size="md"
                          icon={<MdSave />}
                          color="blue"
                          onClick={() => toggle(<SearchModalContent close={() => toggle()} />, "Save search")}
                        >
                          {`Save search ${
                            appliedFilterCount > 0
                              ? `${appliedFilterCount} filter${appliedFilterCount > 1 ? "s" : ""} applied`
                              : ""
                          }`}
                        </Button>
                      </Subscription.Upgrade>
                    )}
                  </ModalConsumer>
                )}
              </>
            }
          />
        </>
      );
    }
  )
);

export default ListContent;
