import React, { useState } from "react";

import Button from "components/Button";
import { MdFileUpload } from "react-icons/md";
import Upload from "b2b/components/Upload";

const UploadImageContent = ({ updateImage, close }) => {
  const [error, setErrors] = useState();
  const [processing, setProcessing] = useState(false);
  const [file, setFile] = useState();

  const handleImage = e => {
    e.preventDefault();
    e.persist();
    setErrors();

    setFile(e.target.files[0]);
  };

  const handleUpdate = () => {
    setProcessing(true);
    updateImage(file).then(errors => {
      setProcessing(false);
      if (errors) setErrors(errors);
      else close();
    });
  };

  return (
    <>
      <Upload accept="image/png, image/jpeg" onChange={handleImage}>
        <Button className="w-100 mb-1" color="blue" icon={<MdFileUpload />}>
          {file?.name || "Choose a file"}
        </Button>
      </Upload>
      {error && <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{error}</div>}
      {file && (
        <div className="fz-modal__actions mt-0">
          <Button color="gray" onClick={close}>
            Cancel
          </Button>
          <Button color="blue" disabled={processing} onClick={handleUpdate}>
            Save
          </Button>
        </div>
      )}
    </>
  );
};

export default UploadImageContent;
