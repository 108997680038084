import React, { useState, useMemo } from "react";

import { thousandSeparator } from "utils/helpers";
import Pane from "../../../../components/Pane";
import Layout from "../../../../components/Layout";

const BrowseList = ({ category = "Category", list = [], applyFilter }) => {
  const [isExpanded, setVisible] = useState(false);
  const letterGroups = useMemo(() => {
    return [...new Set(list.map(item => item.name?.toUpperCase().charAt(0)))];
  }, [list]);
  const extraCss = "margin: 40px 0;";

  const itemClickHandler = (e, id) => {
    e.preventDefault();
    applyFilter(id);
  };

  const collaspeClickHandle = () => {
    setVisible(!isExpanded);
  };

  const renderList = lists =>
    lists.map(({ name, id, count }, index) => (
      <div
        role="button"
        tabIndex={-1}
        className="fz-browse-list-itm"
        key={`${name}_${index}`}
        onClick={e => itemClickHandler(e, id)}
      >
        <div className="fz-browse-list-itm__content">
          <div href="#" className="fz-browse-list-itm__name">
            {name}
          </div>
          <div className="fz-browse-list-itm__count">{thousandSeparator(count)}</div>
        </div>
      </div>
    ));

  return (
    <Pane extraCss={extraCss}>
      <div className="fz-pane__container fz-pane__container_case-two">
        <h2 className="fz-heading-title">Browse by {category}</h2>
        <div
          className="fz-browse-list"
          style={{
            gridTemplateRows: `repeat(${isExpanded ? Math.ceil((list.length + letterGroups.length) / 3) : 4}, auto)`
          }}
        >
          {isExpanded
            ? letterGroups.map(letter => (
                <>
                  <h5 key={letter}>{letter}</h5>
                  {renderList(list.filter(item => item?.name?.toUpperCase()?.charAt(0) === letter))}
                </>
              ))
            : renderList(list.slice(0, 12))}
        </div>
      </div>
      <Layout.CollapseButton
        expanded={isExpanded}
        expandText="Show less"
        collapseText="Show all"
        onClickHandle={collaspeClickHandle}
        withoutShadow
      />
    </Pane>
  );
};

export default BrowseList;
