import styled from "styled-components";

export const Modal = styled.div`
  max-width: 640px;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }

  @media screen and (min-width: 1008px) {
    min-width: 748px;
    max-width: 748px;
  }
`;

export const StepCount = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 8px;
`;

export const Title = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  display: block;
  margin: 0 0 16px 0;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const Content = styled.div`
  margin: 16px 0 24px;
  padding: 24px 0;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
`;

export const SubTitle = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  display: block;
  margin: 0 0 16px 0;
`;

export const Label = styled.label`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 8px 0;

  svg {
    width: 16px;
    height: 16px;
  }

  & > div {
    cursor: pointer;
  }

  span {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Input = styled.input`
  color: #333;
  padding: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${({ isError }) => (isError ? "#EB3737" : "#c5c5c5")};
  background-color: #fff;

  &::placeholder {
    color: #a4a4a4;
  }

  &:focus {
    outline: 1px solid #4690ff;
  }

  &:disabled {
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
    pointer-events: none;
  }
`;

export const RequiredPhone = styled.span`
  font-size: 14px;
  line-height: 140%;
  color: #c30000;
  position: absolute;
  right: 0;
  bottom: -22px;
`;

export const Required = styled.span`
  font-size: 14px;
  line-height: 140%;
  color: #c30000;
  margin-top: 8px;
  display: block;
`;

export const Relative = styled.div`
  position: relative;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
`;

export const Back = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
  margin-right: auto;
`;

export const Next = styled.button`
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 10px 40px;

  ${({ isDisabled }) =>
    isDisabled
      ? `
      opacity: 0.4;
      pointer-events: none;
      `
      : ""}
`;

export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 48px 0;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const InputContent = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  flex: 1 1 0;
  margin-bottom: 16px;

  & > div {
    width: 50%;
  }

  .fz-dropdown__new-container {
    height: 52px;
  }
`;

export const FormContent = styled.div`
  max-height: 600px;
  height: 100%;
  overflow-y: auto;
  padding-right: 40px;
  padding-left: 1px;
`;

export const TooltipText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #f3f3f3;
  width: 240px;
  padding: 3px;
`;

export const FoundUserContent = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ isSelectUser }) => (isSelectUser ? "#408BFC" : "#c5c5c5")};
  background: #fff;
  position: relative;
`;

export const FoundUserCheckbox = styled.div`
  position: absolute;
  top: 16px;
  right: 4px;
`;

export const FoundUserHeading = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
`;

export const FoundUserAvatar = styled.span`
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #99c2ff;
  background: #e0edff;
  color: #408bfc;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const FoundUserName = styled.h6`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 2px 0;
  display: block;
`;

export const FoundUserEmail = styled.p`
  display: block;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 8px 0;
`;

export const FoundUserLocation = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  display: flex;
  align-items: center;
`;
export const Dot = styled.span`
  width: 4px;
  height: 4px;
  background-color: #c5c5c5;
  border-radius: 50%;
`;

export const FoundUserTitle = styled.h6`
  color: ${({ isError }) => (isError ? "#EB3737" : "#333")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 16px 0;
`;

export const FoundUserFooter = styled.div`
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FoundUserFooterItem = styled.div`
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  gap: 4px;
  margin-bottom: 0;

  svg {
    width: 16px;
    height: 16px;
    fill: #666666;
  }
`;

export const CityContainer = styled.div`
  height: 54px;
  width: 100%;

  .select__clear-indicator,
  .select__indicator-separator {
    display: none;
  }
`;
