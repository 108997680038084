// todo all commented code will be used in the next phase
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useHistory, Redirect, Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { MdArrowBack, MdClose } from "react-icons/md";
import qs from "qs";

import { ProfileTab } from "b2b/components/ProfileTab";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import { UserCvNavBar, UserCvCustom, UserCvCard } from "../../components/UserCv";
import { PersonalityTalent } from "./components/PersonalityTalent";
import TalentCvActions from "./components/TalentCvActions";
import Comments from "../../components/Comments";
import TagList from "../../components/TagList";
import AppContext from "../../../contexts/init";
import SelectJobForInviteContent from "../../components/SelectJobForInviteContent";
import InviteToApplyContent from "../../components/InviteToApplyContent";
import { candidateSourceType, tabs } from "../../constants/campaign";
import { getCurrentUserAndEmployer } from "../../utils/helpers";
import ActivityTab from "../ApplicantCvScreen/components/ActivityTab";
import Button from "../../../components/Button";

const TalentCvScreen = inject("store")(
  observer(
    ({
      store: {
        state,
        talents,
        params,
        getTalent,
        resetTalent,
        currentTalent,
        applyParams,
        currentTab,
        setCurrentTab,
        talentCount = 1,
        setFolder,
        folderName,
        addComment,
        removeComment,
        addTag,
        removeTag,
        unlockUser,
        isStopUpdatePage,
        getUserPersonality,
        getUserTalents,
        personality,
        talentResults,
        exportCvProfile,
        getTalentActivity,
        getSearchCandidatesApplications,
        getCVUrl,
        applicationHistoryMeta,
        applicationHistory,
        getSearchCandidatesDetail,
        getAvailableJobForCandidate,
        getFolders
        // getActiveCampaigns,
        // getActiveCompanies,
      } = {},
      location: { index = 1 } = {},
      isSearchCandidates
    }) => {
      // Handling url parameters & tabs
      const [activeTab, setActiveTab] = useState("Profile");

      const { id, folder } = useParams();
      const history = useHistory();
      const isHeadHunting = history.location.pathname.includes("/headhunting/find");
      const { type } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      const path = history.location.pathname.slice(0, history.location.pathname.lastIndexOf("/"));
      const tab = path.slice(path.lastIndexOf("/") + 1, path.length);

      // Parsing store properties
      const numId = Number(id);
      const talent = useMemo(() => toJS(currentTalent), [currentTalent]);
      const { page } = useMemo(() => toJS(params), [params]);
      const talentList = useMemo(() => toJS(talents), [talents]);
      const isHeadHuntingPage = history.location.pathname.includes("/headhunting");
      const isSavedFoldersPage = history.location.pathname.includes("/headhunting/saved/folders");
      const { current_employer: currentEmployer, current_user: currentUser } = useContext(AppContext);

      useEffect(() => {
        if (isSearchCandidates && !type) {
          history.push("/candidates");
        }
      }, []);

      useEffect(() => {
        const matchId = id ? id.match(/\d+/) : id;
        const currentId = matchId ? matchId[0] : null;

        if (window.scrollY) {
          window.scrollTo(0, 0);
        }

        if (String(tab) !== String(currentTab)) {
          setCurrentTab(tab);
        }

        if (isSearchCandidates) {
          getSearchCandidatesDetail(currentId, type);
          getSearchCandidatesApplications(currentId, 1, true, null, type);
          // getActiveCompanies();
          if (type !== candidateSourceType.prospect) {
            // getActiveCampaigns(currentEmployer.id);
            getFolders();
          }
        } else {
          getTalent(id, true, currentEmployer.id);
          getTalentActivity(id, 1, true);
        }
        if (type !== candidateSourceType.prospect) {
          getUserPersonality(currentId);
          getUserTalents(currentId);
        }
      }, [id, type]);

      useEffect(() => {
        if (folder) {
          setFolder(+folder);
        }
      }, [folder]);

      useEffect(() => {
        return () => {
          resetTalent();
        };
      }, []);

      useEffect(() => {
        if (talent.name) {
          document.title = talent.name;
        }
      }, [talent.name]);

      // Handling next and previous talents
      const nextCandidate = useMemo(
        () =>
          talentList?.length > 0 && talent.id !== talentList[talentList.length - 1].id
            ? talentList.some(item => item.id === talent.id)
              ? talentList[talentList.findIndex(item => item.id === talent.id) + 1].id
              : talentList[0].id
            : null,
        [talent.id]
      );

      const prevCandidate = useMemo(() => {
        const talentId = talent.id || id;

        return talentList?.length > 0 && talentId && String(talentId) !== String(talentList[0].id)
          ? talentList.some(item => item.id === talent.id)
            ? talentList[talentList.findIndex(item => item.id === talent.id) - 1].id
            : talentList[0].id
          : null;
      }, [talent.id]);

      const getNextList = () => applyParams({ page: +(page || 1) + 1 });

      const getPrevList = () => applyParams({ page: +(page || 1) - 1 });

      useEffect(() => {
        if (talentList?.length > 0 && String(id) === String(talent.id)) {
          if (!nextCandidate) {
            history.push({
              pathname: `${path}/${talentList[0].id}`,
              index: index + 1
            });
          }
          if (!prevCandidate && !isStopUpdatePage) {
            history.push({
              pathname: `${path}/${talentList[talentList.length - 1].id}`,
              index: index - 1
            });
          }
        }

        return () => {
          if (
            !isSavedFoldersPage &&
            talentList.length > 0 &&
            (String(id) === String(talentList[talentList.length - 1].id) || String(numId) === String(talentList[0].id))
          ) {
            resetTalent();
          }
        };
      }, [talentList]);

      const tagList = useMemo(() => {
        if (talent.type > 1) return talent.tags.concat("From talent pool");
        return talent.tags;
      }, [talent.tags, talent.type]);

      const handleRedirection = () => {
        if (/shortlist/.test(state.type))
          window.location.replace(
            `${window.location.origin}/jobs/${state.callback}/applicants?filters[scope]=shortlisted`
          );
      };

      const handleOpenSelectJobForInvite = (toggle, currentId) => {
        toggle(
          <SelectJobForInviteContent
            selectedTalents={[currentId]}
            handleInviteToApply={() => handleInviteToApply(toggle, currentId)}
            handleClose={() => handleInviteToApply(toggle, currentId)}
          />,
          "Select job",
          false
        );
      };

      const handleInviteToApply = (toggle, currentId) => {
        toggle(
          <InviteToApplyContent
            currentId={currentId}
            currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
            selectedCandidatesIds={currentId}
            toggle={toggle}
            talents={talents}
            addToJob={() => handleOpenSelectJobForInvite(toggle, currentId)}
            handleInviteToApply={() => handleInviteToApply(toggle, currentId)}
          />,
          "Invite talent to apply"
        );
      };

      return index !== 0 ? (
        <ScreenStateMessage
          state={toJS(state)}
          loadingMessage="Just a moment! We are loading talent details..."
          redirect={handleRedirection}
        >
          <section className="fz-page-container">
            {!isSearchCandidates ? (
              <UserCvNavBar
                current={index <= talentCount ? index : talentCount}
                loading={state.loading}
                sortedBy={folder ? folderName : tab === "open" ? "Open application" : "Search results"}
                total={talentCount}
                id={numId}
                next={nextCandidate}
                prev={prevCandidate}
                getNextList={getNextList}
                getPrevList={getPrevList}
              />
            ) : (
              <section className="fz-pane fz-pane_with-shadow mt-4 navigator fz-navigation-sticky-container">
                <div className="fz-pane__container fz-pane__container_case-two">
                  <div className="fz-navigation-bar">
                    <div className="fz-navigation-bar__left">
                      <Link to={`/candidates${window.location.search}`} tabIndex="0">
                        <Button.Iconed
                          color="transparent"
                          tag="span"
                          style={{ width: "100%", fontSize: "14px", color: "#666666", fontWeight: 400 }}
                        >
                          <MdArrowBack /> Back to Candidates list
                        </Button.Iconed>
                      </Link>
                    </div>
                    <div className="fz-navigation-bar__right">
                      <Link to={`/candidates${window.location.search}`} tabIndex="0">
                        <Button.Iconed color="gray" tag="span">
                          <MdClose />
                        </Button.Iconed>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <UserCvCard
              {...talent}
              candidateSource={talent.candidate_source || type}
              isExternalCandidate={(talent.candidate_source || type) === candidateSourceType.prospect}
              hasPersonality
              customCv={talent.user_uploaded_cv_url}
              handleTabChange={tabItem => setActiveTab(tabItem)}
              activeTab={activeTab}
              showDetails={talent.contact_details_visible}
              isHeadHunting={isHeadHunting}
              profileUnlocked={talent.profile_unlocked}
              isSearchCandidates={isSearchCandidates}
              AdditionalContent={
                !isSearchCandidates ? (
                  <TagList
                    tags={tagList}
                    addTag={tag => addTag(talent.id, tag)}
                    removeTag={tag => removeTag(talent.id, tag)}
                  />
                ) : null
              }
            >
              <TalentCvActions
                id={talent.id}
                userId={talent.user_id}
                name={talent.name}
                path={path}
                tab={tab}
                email={talent.email}
                phone_number={talent.phone_number}
                facebook={talent.facebook}
                linkedin={talent.linkedin}
                twitter={talent.twitter}
                website={talent.website}
                nationalId={talent?.details?.passport_number}
                inFolders={talent.headhunting_folder_ids}
                updateTalent={() => getTalent(id, true, currentEmployer?.id)}
                unlockUser={unlockUser}
                profileUnlocked={talent.profile_unlocked}
                handleInviteToApply={handleInviteToApply}
                exportCvProfile={exportCvProfile}
                state={toJS(state)}
                isSearchCandidates={isSearchCandidates}
                getAvailableJobForCandidate={getAvailableJobForCandidate}
                isSearchCandidatesExternalCandidate={isSearchCandidates && type === candidateSourceType.prospect}
              />
            </UserCvCard>
            <div className="fz-container-with-sidebar">
              <div className="fz-container-with-sidebar__content">
                {activeTab === "Profile" && <ProfileTab talent={talent} isHeadHuntingPage={isHeadHuntingPage} />}
                {activeTab === "Personality and Talent" && (
                  <PersonalityTalent
                    talentResults={talentResults}
                    personality={personality}
                    name={talent.first_name}
                    isHeadHunting={isHeadHunting}
                  />
                )}
                {activeTab === tabs.cv && <UserCvCustom getCVUrl={getCVUrl} cvUrl={talent.user_uploaded_cv_url} />}
                {/* tab !== "headhunt" */}
                {activeTab === tabs.activity && (
                  <ActivityTab
                    applicationHistory={toJS(applicationHistory)}
                    applicationHistoryMeta={toJS(applicationHistoryMeta)}
                    getCandidateApplicationHistory={(page, isShowOnlyNew, currentEmployer) => {
                      const matchId = id ? id.match(/\d+/) : id;
                      const currentId = matchId ? matchId[0] : null;

                      if (isSearchCandidates) {
                        return getSearchCandidatesApplications(currentId, page, isShowOnlyNew, currentEmployer, type);
                      }
                      return getTalentActivity(id, page, isShowOnlyNew, currentEmployer);
                    }}
                    campaignName={currentEmployer.name}
                    candidateName={talent.name}
                    isExternalCandidate={false}
                    isHeadHunting
                  />
                )}
              </div>

              {!isSearchCandidates && (
                <div className="fz-container-with-sidebar__aside fz-container-with-sidebar__aside_big">
                  <Comments
                    comments={talent.comments}
                    add={(body, visibility) => addComment(talent.user_id || talent.id, body, visibility)}
                    remove={ids => removeComment(talent.user_id || talent.id, ids)}
                    isRemovePublic
                  />
                </div>
              )}
            </div>
          </section>
        </ScreenStateMessage>
      ) : (
        <Redirect
          to={{
            pathname: `${path}/${id}`,
            index: 1
          }}
        />
      );
    }
  )
);

export default TalentCvScreen;
