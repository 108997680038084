import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Image } from "b2c/components/Core";
import { Banner, FastApplyComponent } from "./styled";
import InitContext from "../../../contexts/init";
import { theme } from "../../../b2c/contexts/theme";
import { nameImages } from "../../../b2c/constants/images";

const FastApply = ({ isShow, margin, onClick }) => {
  const initContext = useContext(InitContext);

  const buildImageUrlS3 = imageName => `${initContext?.global_brand?.assets_store}/images/${imageName}`;

  if (!isShow) {
    return null;
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <FastApplyComponent margin={margin}>
      <Banner
        color={theme.primaryButtonLabelColor}
        backgroundColor={theme.primaryButtonBackground}
        onClick={handleClick}
        tabIndex="0"
        role="button"
      >
        <Image src={buildImageUrlS3(nameImages.fastApply)} width="16px" height="16px" />
        Fast Apply
      </Banner>
    </FastApplyComponent>
  );
};

export default observer(FastApply);
