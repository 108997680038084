import React, { useState, useMemo } from "react";
import { MdSearch, MdClose } from "react-icons/md";

import Button from "components/Button";
import withStore from "../../hocs/withStore";

const CompaniesModalContent = ({
  companies = [],
  getActiveCampaigns,
  selectedTalents,
  recipientsIds,
  inCampaigns = [],
  addToJob
}) => {
  const [term, setTerm] = useState("");

  // Sorted campaigns
  const sortedCompanies = useMemo(() => {
    return companies
      .map(campaign => ({ ...campaign, includes: inCampaigns.includes(campaign.id) }))
      .filter(campaign => campaign.name?.toLowerCase()?.includes(term?.toLowerCase()));
  }, [companies, inCampaigns, term]);

  return (
    <>
      <div className="fz-std-input fz-std-input_sm mb-2">
        <div className="fz-std-input__field">
          <MdSearch className="fz-std-input__icon" />
          <input
            type="text"
            placeholder="Search companies"
            onChange={e => setTerm(e.target.value)}
            value={term}
            className="fz-std-input__control"
          />
        </div>
        <div role="button" tabIndex={-1} className="fz-campaign-searchbar__clear" onClick={() => setTerm("")}>
          <Button.Simple size="xs" icon={<MdClose fill="#C5C5C5" />} color="gray" className="fadeInFromLeft" />
        </div>
      </div>
      <div className="headhunting_modal main">
        <div className="campaign-list">
          {sortedCompanies.length > 0 ? (
            sortedCompanies.map(company => (
              <div
                className="campaign campaign_hover"
                key={company.id}
                role="button"
                tabIndex={-1}
                onClick={() => {
                  getActiveCampaigns(company.id, company, recipientsIds).then(() => {
                    addToJob(company, selectedTalents);
                  });
                }}
              >
                <div className="info ml-1">
                  <h5>{company.name}</h5>
                  <div className="fz-data-row">
                    <p className="fz-info-string mr-1">
                      <span className="fz-info-string__text">{company.count} active jobs</span>
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="folder">
              <h5 className="ml-1 fz-text-color__grey500">No matching companies</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withStore(CompaniesModalContent);
