import React from "react";
import ReactPaginate from "react-paginate";

const Paginator = ({ pageCount = 0, updateList, page = 1, hiddenLastPage = false }) => {
  if (pageCount <= 1) {
    return null;
  }

  const handleUpdate = selected => {
    window.scrollTo(0, 0);
    updateList({ page: selected });
  };

  return (
    <ReactPaginate
      previousLabel="previous"
      nextLabel="next"
      breakLabel="..."
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={4}
      onPageChange={({ selected }) => handleUpdate(selected + 1)}
      containerClassName={`fz-paginator${hiddenLastPage ? " hidden-last-page" : ""}`}
      subContainerClassName="fz-paginator__container"
      pageClassName="fz-paginator__itm"
      activeClassName="fz-paginator__itm_active"
      previousClassName="fz-paginator__itm fz-paginator__itm_simple"
      nextClassName="fz-paginator__itm fz-paginator__itm_simple"
      disabledClassName="fz-paginator__itm_disabled"
      breakClassName="fz-paginator__itm fz-paginator__itm_simple"
      forcePage={page - 1}
    />
  );
};

export default Paginator;
