import React, { useContext, useEffect } from "react";

import withStoreCampaigns from "../../../../hocs/withStoreCampaigns";
import InviteToApply from "../../../../components/InviteToApply";
import AppContext from "../../../../../contexts/init";

export const InviteToApplyContent = ({
  store: {
    selectedAvailableJobForCandidate,
    getAvailableJobForCandidate,
    inviteCandidate,
    availableJobsForCandidate,
    companies,
    getActiveCampaigns,
    selectedEmployer,
    resetSelectedEmployer
  },
  currentId,
  currentUserAndEmployer,
  toggle,
  talents,
  addToJob,
  campaign,
  isJobHeadhuntPage,
  handleInviteToApply
}) => {
  const { current_user: currentUser } = useContext(AppContext);
  const isLeadHr = currentUser?.roles?.includes("lead_hr");

  useEffect(() => {
    if (!selectedEmployer?.id) {
      getAvailableJobForCandidate([currentId]);
    }
  }, []);

  return (
    <InviteToApply
      currentId={currentId}
      currentUserAndEmployer={currentUserAndEmployer}
      toggle={toggle}
      talents={talents}
      getAvailableJobForCandidate={getAvailableJobForCandidate}
      addToJob={addToJob}
      selectedAvailableJobForCandidate={selectedAvailableJobForCandidate}
      inviteCandidate={inviteCandidate}
      isJobHeadhunt
      isJobHeadhuntPage={isJobHeadhuntPage}
      campaign={campaign}
      availableJobsForCandidate={availableJobsForCandidate}
      handleInviteToApply={handleInviteToApply}
      isHideCompany={!isLeadHr}
      selectedEmployer={selectedEmployer}
      resetSelectedEmployer={resetSelectedEmployer}
      companies={companies}
      getActiveCampaigns={getActiveCampaigns}
    />
  );
};

export default withStoreCampaigns(InviteToApplyContent);
