import React from "react";
import { useLocation } from "react-router-dom";

import emptyPersonalty from "images/b2b/empty-personality.png";
import emptyScore from "images/b2b/emptyScore.png";
import {
  Attachments,
  CoverLetter,
  UserCvAttachments,
  UserCvCoverLetter,
  UserCvEducation,
  UserCvRecommendations,
  UserCvRequiredSkills,
  UserCvWorkExp,
  Questions
} from "../../../../components/UserCv";
import UserCvInfo from "../UserCvInfo";
import UserCvReference from "../UserCvReference";
import { ExternalAddedState, ExternalAddedTitle, ExternalAddedText, EmptyState, EmptyIcon, EmptyText } from "./styled";

const ProfileTab = ({
  candidate,
  tab,
  isFastApply,
  showModalInviteCandidate,
  isAssignment,
  left,
  messageButton,
  campaignEndDate,
  formatDate,
  isSharedCandidates,
  isExternalCandidate
}) => {
  const location = useLocation();
  const isHeadHundPage = location.pathname.includes("/headhunt");
  const isShowCoverLetter = !candidate.cover_letter && isFastApply && !isHeadHundPage;

  const professionalCertificates = candidate?.professional_certificates?.map(item => ({
    name: item.name,
    url: item.attachment?.url
  }));

  return (
    <>
      {isExternalCandidate ? (
        <>
          <ExternalAddedState>
            <div>
              <ExternalAddedTitle>Added to campaign</ExternalAddedTitle>
              {candidate.updated_at && (
                <ExternalAddedText>
                  {formatDate(new Date(candidate.updated_at))}

                  {candidate?.owner && (
                    <>
                      {" "}
                      by <img src={candidate.owner.avatar} alt={candidate.owner.first_name} />{" "}
                      {` ${candidate.owner.first_name} ${candidate.owner.last_name}`}
                    </>
                  )}
                </ExternalAddedText>
              )}
            </div>
            {candidate.source && (
              <div>
                <ExternalAddedTitle>Source</ExternalAddedTitle>
                <ExternalAddedText>{candidate.source}</ExternalAddedText>
              </div>
            )}
          </ExternalAddedState>

          <EmptyState>
            <EmptyIcon>
              <img src={emptyScore} alt="empty" />
            </EmptyIcon>
            <EmptyText>Calculating match scores for external candidates is not yet supported</EmptyText>
          </EmptyState>

          <EmptyState isBigIndent>
            <EmptyIcon>
              <img src={emptyPersonalty} alt="empty" />
            </EmptyIcon>
            <EmptyText>
              Detailed profile data (work experience, skills, education) is not yet available for external candidates
            </EmptyText>
          </EmptyState>
        </>
      ) : (
        <>
          {!isSharedCandidates && (
            <UserCvInfo
              personality_match={candidate.personality_match}
              total_match={candidate.total_match}
              cv_match={candidate.cv_match}
              talent_match={candidate.talent_match}
              highest_education_degree={candidate.highest_education_degree}
              current_position_experience={candidate.current_position_experience}
              relevant_experience_months={candidate.relevant_experience_months}
              isExistPersonality={"personality_match" in candidate}
              isExistTalent={"talent_match" in candidate}
            />
          )}
          {tab !== "headhunt" && candidate.cover_letter && <UserCvCoverLetter letter={candidate.cover_letter} />}
          {candidate.prescreening_answers && <Questions questions={candidate.prescreening_answers} />}

          {isShowCoverLetter && (
            <CoverLetter
              isShow={isFastApply}
              showModalInviteCandidate={showModalInviteCandidate}
              isAssignment={isAssignment}
              left={left}
              messageButton={messageButton}
              campaignEndDate={campaignEndDate}
              formatDate={formatDate}
              isSharedCandidates={isSharedCandidates}
            />
          )}
          <UserCvRequiredSkills
            name={candidate.first_name}
            skills={candidate.skills}
            core={candidate.core_skills}
            required={candidate.required_skills}
          />
          <UserCvWorkExp exp={candidate.work_experience_items} />
          <UserCvEducation education={candidate.education_items} languages={candidate.language_items} />
          <UserCvRecommendations recommendations={candidate.recommendations} />
          <UserCvAttachments
            attachments={
              professionalCertificates?.length ? [...candidate?.attachments, ...professionalCertificates] : []
            }
          />
          {!candidate.attachments?.length && isFastApply && (
            <Attachments
              isShow={isFastApply}
              showModalInviteCandidate={showModalInviteCandidate}
              isAssignment={isAssignment}
              left={left}
              messageButton={messageButton}
              campaignEndDate={campaignEndDate}
              formatDate={formatDate}
              isSharedCandidates={isSharedCandidates}
            />
          )}
          <UserCvReference reference={candidate.reference_items} />
        </>
      )}
    </>
  );
};

export default ProfileTab;
