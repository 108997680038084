import styled from "styled-components";

export const Label = styled.div`
  margin-left: 8px;
  color: #fff;
  background-color: #408bfc;
  font-size: 12px;
  border-radius: 8px;
  padding: 1.5px 8px;
  max-width: 110px;
`;
