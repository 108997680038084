import styled from "styled-components";

export const ExternalCandidateAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 1px solid #f3f8ff;
  background: #f3f8ff;
  color: #408bfc;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
`;

export const ExternalCandidateLabel = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: ${({ backgroundColor }) => backgroundColor || "#f9eeff"};
  color: #3c0b53;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 24px;

  svg {
    width: 16px;
    height: 16px;
    fill: #3c0b53;
  }
`;

export const TooltipText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #f3f3f3;
  width: 240px;
  padding: 3px;
`;

export const EditButton = styled.button`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #eee;
  background-color: #fff;
  color: #444;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    fill: #444;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 12px;
`;
