import React, { useState } from "react";

import Button from "components/Button";
import Input from "b2b/components/Input";

const ChangePasswordContent = ({ updatePassword, close }) => {
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const [form, setForm] = useState({});

  const handleUpdate = () => {
    setProcessing(true);
    updatePassword(form).then(error => {
      setProcessing(false);
      if (error) setErrors(error);
      else close();
    });
  };

  const handleChange = (name, value) => {
    setForm(prev => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors(prev => ({ ...prev, [name]: "" }));
  };

  const disableUpdate = ["current_password", "password", "password_confirmation"].some(key => !form[key]);

  const renderError = field => {
    return (
      errors[field] && (
        <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{errors[field]}</div>
      )
    );
  };

  return (
    <>
      <div className="fz-form-group">
        <label className="fz-std-label">Old password</label>
        <Input type="password" onChange={value => handleChange("current_password", value)} />
        {renderError("current_password")}
      </div>
      <div className="fz-form-group">
        <label className="fz-std-label">New password</label>
        <Input type="password" onChange={value => handleChange("password", value)} />
        {!errors.password && <div className="fz-text">Password must be minimum 8 letters.</div>}
        {renderError("password")}
      </div>
      <div className="fz-form-group">
        <label className="fz-std-label">Confirm new password</label>
        <Input type="password" onChange={value => handleChange("password_confirmation", value)} />
        {renderError("password_confirmation")}
      </div>

      <div className="fz-modal__actions mt-0">
        <Button color="gray" disabled={processing} onClick={close}>
          Cancel
        </Button>
        <Button color="blue" disabled={disableUpdate || processing} onClick={handleUpdate}>
          Update password
        </Button>
      </div>
    </>
  );
};

export default ChangePasswordContent;
