import React, { useState, useMemo } from "react";
import { MdCheck, MdSearch, MdClose, MdPinDrop, MdPermIdentity } from "react-icons/md";

import Button from "components/Button";
import withStore from "../../hocs/withStore";

const CampaignsModalContent = ({
  store: { campaigns = [], shortlistTalents, getCampaignFilters },
  add = true,
  selectedTalents,
  inCampaigns = [],
  close,
  addToJob
}) => {
  const [term, setTerm] = useState("");

  // Sorted campaigns
  const sorted = useMemo(() => {
    return campaigns
      .map(campaign => ({ ...campaign, includes: inCampaigns.includes(campaign.id) }))
      .sort(campaign => (campaign.includes ? 1 : -1))
      .filter(campaign => campaign.title?.toLowerCase()?.includes(term?.toLowerCase()));
  }, [campaigns, inCampaigns, term]);

  return (
    <>
      <div className="fz-std-input fz-std-input_sm mb-2">
        <div className="fz-std-input__field">
          <MdSearch className="fz-std-input__icon" />
          <input
            type="text"
            placeholder="Campaign title"
            onChange={e => setTerm(e.target.value)}
            value={term}
            className="fz-std-input__control"
          />
        </div>
        <div role="button" tabIndex={-1} className="fz-campaign-searchbar__clear" onClick={() => setTerm("")}>
          <Button.Simple size="xs" icon={<MdClose fill="#C5C5C5" />} color="gray" className="fadeInFromLeft" />
        </div>
      </div>
      <div className="headhunting_modal main">
        <div className="campaign-list">
          {sorted.length > 0 ? (
            sorted.map(campaign => (
              <div
                className="campaign campaign_hover"
                key={campaign.id}
                role="button"
                tabIndex={-1}
                style={{
                  pointerEvents: campaign.includes ? "none" : "default"
                }}
                onClick={() => {
                  if (addToJob) {
                    addToJob(campaign, selectedTalents);
                  } else {
                    shortlistTalents(campaign.id, selectedTalents).then(() => {
                      close();
                    });
                  }
                }}
              >
                <div className="info ml-1">
                  <h5>{campaign.title}</h5>
                  <div className="fz-data-row">
                    {campaign.location && (
                      <p className="fz-info-string mr-1">
                        <MdPinDrop className="fz-info-string__icon" />
                        <span className="fz-info-string__text">{campaign.location}</span>
                      </p>
                    )}
                    {campaign?.owner?.name && (
                      <p className="fz-info-string">
                        <MdPermIdentity className="fz-info-string__icon" />
                        <span className="fz-info-string__text">{campaign.owner.name}</span>
                      </p>
                    )}
                  </div>
                </div>
                {add ? (
                  campaign.includes && <MdCheck className="includes_icon" />
                ) : (
                  <Button.Iconed
                    onClick={() => {
                      getCampaignFilters(campaign.id);
                      close();
                    }}
                  >
                    <MdSearch fill="#408bfc" />
                  </Button.Iconed>
                )}
              </div>
            ))
          ) : (
            <div className="folder">
              <h5 className="ml-1 fz-text-color__grey500">No matching campaigns</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withStore(CampaignsModalContent);
