import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .select__input-container,
  .select__single-value,
  .select__placeholder {
    padding-left: 44px;
    margin: 0;
  }

  .select__value-container {
    padding-left: 0;
  }

  .select__single-value {
    text-transform: capitalize;
  }
`;

export const Close = styled.button`
  background: transparent;
  padding: 0;
  border: none;
  width: 44px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  svg {
    width: 16px;
    height: 16px;
    fill: #a4a4a4;
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  left: 16px;
  top: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
    fill: #a4a4a4;
  }
`;
