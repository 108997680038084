import { makeAutoObservable } from "mobx";
import CSRFManager from "../../utils/defaults/csrfToken";
import * as API from "../utils/api";

class UserStore {
  user = {};

  employers = [];

  state = {
    processing: false,
    error: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  setUser = user => {
    this.user = { ...this.user, ...user };
  };

  get adminAccess() {
    return this.user?.roles?.includes("admin");
  }

  getLeadHrAccess() {
    return this?.user?.roles?.includes("lead_hr");
  }

  setState = newState => {
    this.state = { ...this.state, ...newState };
    return this.state;
  };

  createAccount = (body, valid) => {
    if (valid) {
      this.validation_errors = {};
      this.setState({ processing: true });
      return API.createAccountRequest(body)
        .then(res => {
          this.setUser(res.data.user);
          this.setState({ processing: false });
          return true;
        })
        .catch(err => {
          console.error(err);
          this.setState({ error: err });
          this.setState({ processing: false });
          return false;
        });
    }
    return Promise.resolve();
  };

  loginUser = (body, valid) => {
    if (valid) {
      this.validation_errors = {};
      this.setState({ processing: true });
      return API.loginUserRequest(body)
        .then(res => {
          if (res?.data?.csrf_meta_tags?.csrf_token) {
            CSRFManager.setCsrfToken(res.data.csrf_meta_tags.csrf_token);
          }
          this.setUser(res.data.user);
          this.setState({ processing: false, error: null });
          return true;
        })
        .catch(err => {
          console.error(err);
          const error = err?.response?.data?.error || "Failed to log in";
          this.setState({ processing: false, error });
          throw error;
        });
    }
  };

  getEmployersList = () => {
    this.setState({ processing: true });
    return API.getEmployersListRequest()
      .then(res => {
        this.setState({ processing: false });
        this.employers = res.data.employers;
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
        this.setState({ error: "Could not retrieve Employers" });
      });
  };

  selectEmployer = body => {
    return API.selectEmployerRequest(body)
      .then(() => {
        this.setState({ processing: true });
        return true;
      })
      .catch(error => {
        this.setState({ processing: false });
        this.setState({ error });
        return false;
      });
  };

  forgotPassword = body => {
    this.setState({ processing: true });
    return API.forgotPasswordRequest(body)
      .then(() => {
        this.setState({ processing: false, error: null });
        return true;
      })
      .catch(err => {
        const error = err?.response?.data?.error || "Something went wrong";
        this.setState({ processing: false, error });
        throw error;
      });
  };

  updatePassword = body => {
    this.setState({ processing: true });
    return API.updatePasswordRequest(body)
      .then(() => {
        this.setState({ processing: false, error: null });
        return true;
      })
      .catch(err => {
        const error = err?.response?.data?.error || "Something went wrong";
        this.setState({ processing: false, error });
        throw error;
      });
  };

  acceptInvitation = body => {
    this.setState({ processing: true });
    return API.acceptInvitationRequest(body)
      .then(() => {
        this.setState({ processing: false, error: null });
        return true;
      })
      .catch(err => {
        console.error(err);
        const error = err?.response?.data?.error || "Failed to log in";
        this.setState({ processing: false, error });
        throw error;
      });
  };
}

export default new UserStore();
