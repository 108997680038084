import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import Subscription from "../Subscription";

const TabNavigation = ({ tabItems, className, onChange, prevent = true, isHeadHunting }) => {
  const handleTab = (e, name) => {
    if (onChange) {
      if (prevent) e.preventDefault();
      onChange(name);
    }
  };

  const Prompt = ({ name, permission, profileUnlocked, unlockHandler, children }) =>
    profileUnlocked ? (
      <Subscription.Unlock key={name} element={name} unlocked={profileUnlocked} unlockHandler={unlockHandler}>
        {children}
      </Subscription.Unlock>
    ) : (
      <Subscription.Upgrade className="fz-tab-nav__itm" key={name} permission={permission}>
        {children}
      </Subscription.Upgrade>
    );

  const renderTabs = () =>
    tabItems.map(({ name, permission, profileUnlocked, unlockHandler, count, active = false, to = "#", icon }) => (
      <Prompt
        key={name}
        name={name}
        permission={permission}
        profileUnlocked={profileUnlocked}
        unlockHandler={unlockHandler}
      >
        <Link
          onClick={e => handleTab(e, name)}
          className={cn("fz-tab-nav__itm fz-tab-nav__itm_navigation", {
            "fz-tab-nav__itm_active": active
          })}
          to={to}
        >
          {icon && <div className="fz-tab-nav__icon">{icon}</div>}
          {isHeadHunting && name === "Personality and Talent" ? "Personality" : name}
          {count && count > 0 ? <div className={cn("fz-badge", { "fz-badge_light-gray": !active })}>{count}</div> : ""}
        </Link>
      </Prompt>
    ));
  return <nav className={`fz-tab-nav ${className}`}>{tabItems && renderTabs()}</nav>;
};

export default TabNavigation;
