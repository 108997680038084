import React, { useState, useEffect, useCallback, useRef } from "react";

import { MdSearch, MdClose } from "react-icons/md";
import Button from "components/Button";

const SearchInput = ({ placeholder = "Search", onChange, initValue = "" }) => {
  const [value, setValue] = useState("");
  const [active, setActive] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);

  const inputRef = useRef();

  useEffect(() => {
    if (active) {
      clearTimeout(timeoutId);
      onSearchInput(value);
    }
  }, [value]);

  useEffect(() => {
    return clearTimeout(timeoutId);
  }, []);

  const onSearchInput = useCallback(
    text => {
      const timeOut = setTimeout(() => onChange({ term: text }), 600);
      setTimeoutId(timeOut);
    },
    [onChange]
  );

  const handleSearchInput = ({ target: { value: text } = {} }) => {
    setActive(true);
    setValue(text);
  };

  const hadnleClearInput = () => {
    inputRef.current.value = "";
    onSearchInput("");
  };

  const handleInputFocus = e => e.target.select();

  return (
    <div className="fz-campaign-searchbar" data-test-scope="term-search" role="search">
      <input
        ref={inputRef}
        defaultValue={initValue}
        className="fz-campaign-searchbar__field"
        placeholder={placeholder}
        type="text"
        onChange={e => handleSearchInput(e)}
        onFocus={e => handleInputFocus(e)}
        name="search"
        aria-label="On this page"
      />
      <div className="fz-campaign-searchbar__btn">
        <MdSearch />
      </div>
      {inputRef.current && inputRef.current.value.length > 0 && (
        <div role="button" tabIndex={-1} className="fz-campaign-searchbar__clear" onClick={hadnleClearInput}>
          <Button.Simple icon={<MdClose />} color="gray" className="fadeInFromLeft" />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
