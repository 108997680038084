import styled from "styled-components";

export const SelectAllContent = styled.div`
  margin-left: 16px;
  padding-left: 16px;
  position: relative;
  display: flex;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 8px;
    width: 1px;
    background: #c5c5c5;
    margin-top: -4px;
  }
`;
