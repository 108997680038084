import React, { useEffect } from "react";

import emptyPersonalty from "images/b2b/empty-personality.png";
import Subscription from "../../../../components/Subscription";
import { UserCvPersonality } from "../../../../components/UserCv";
import UserCvTalentProfile from "../UserCvTalentProfile";
import PersonalityAndTalent from "../PersonalityAndTalent";
import { useModal } from "../../../../../utils/hooks";
import { Content, Description, ImageContent } from "../../../TalentCvScreen/components/PersonalityTalent/styled";

const PersonalityTab = ({
  personality,
  talent,
  avatar,
  isFastApply,
  showModalInviteCandidate,
  isAssignment,
  candidate,
  messageButton,
  isCandidateAssignmentsCompleted,
  left,
  formatDate,
  campaignEndDate,
  isCandidateFastApplyApplied,
  changeTab,
  isShowPersonality,
  isShowTalentTests,
  getPersonality,
  isSharedCandidates,
  isHeadhunt,
  isExternalCandidate,
  guestAccessibleSections
}) => {
  const handleChangeTab = () => {
    changeTab();
  };

  const handlePersonalityAndTalent = useModal(
    <PersonalityAndTalent
      isShow={isFastApply}
      showModalInviteCandidate={showModalInviteCandidate}
      isAssignment={isAssignment}
      left={left}
      messageButton={messageButton}
      formatDate={formatDate}
      campaignEndDate={campaignEndDate}
      handleChangeTab={handleChangeTab}
      isSharedCandidates={isSharedCandidates}
      firstName={candidate.first_name}
    />,
    "Personality and talent",
    false,
    false
  );

  useEffect(() => {
    const isShowModal =
      (candidate.talent?.length &&
        Object.keys(candidate?.personality || [])?.length &&
        isFastApply &&
        !isCandidateAssignmentsCompleted) ||
      isCandidateFastApplyApplied;

    if (isShowModal) {
      handlePersonalityAndTalent();
    }
  }, [isFastApply, candidate]);

  useEffect(() => {
    if (getPersonality) {
      getPersonality();
    }
  }, []);

  return (
    <>
      {isExternalCandidate ? (
        <>
          <div className="fz-pane">
            <div className="fz-pane__container fz-pane__container_case-two">
              <Content>
                <ImageContent>
                  <img src={emptyPersonalty} alt="empty" />
                </ImageContent>
                <Description>
                  Personality profile and Talent tests are not available for external candidates
                </Description>
              </Content>
            </div>
          </div>
        </>
      ) : (
        <>
          {isSharedCandidates ? (
            <>
              {guestAccessibleSections.personality_results ? (
                <UserCvPersonality
                  avatar={avatar}
                  personality={personality || []}
                  name={candidate.name}
                  isShowPersonality={isShowPersonality}
                />
              ) : null}
              {guestAccessibleSections.talent_results ? (
                <UserCvTalentProfile
                  talent={talent || []}
                  name={candidate.name}
                  isShowTalentTests={isShowTalentTests}
                />
              ) : null}
            </>
          ) : (
            <>
              <Subscription permission="psychometric_test">
                {permitted => (
                  <UserCvPersonality
                    avatar={avatar}
                    {...(permitted ? { personality } : {})}
                    name={candidate.name}
                    isShowPersonality={isShowPersonality}
                  />
                )}
              </Subscription>

              {talent.length ? (
                <Subscription permission="aptitude_test">
                  {permitted => (
                    <UserCvTalentProfile
                      talent={permitted ? talent : []}
                      name={candidate.name}
                      isShowTalentTests={isShowTalentTests && !isHeadhunt}
                    />
                  )}
                </Subscription>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PersonalityTab;
