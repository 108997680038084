import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  max-width: 380px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 30px auto;

  @media screen and (min-width: 1008px) {
    margin: 60px auto;
  }
`;

export const Image = styled.p`
  width: 110px;
  height: 148px;
  margin-bottom: 24px;

  img {
    max-width: 100%;
  }
`;

export const Title = styled.h6`
  color: #333;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin: 0 0 8px 0;
`;

export const Description = styled.p`
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 24px;
  text-align: center;
`;

export const Button = styled.p`
  border-radius: 8px;
  background: #ffe140;
  display: flex;
  gap: 4px;
  //height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  width: 240px;
`;
