import React from "react";
import { InfoCircle } from "@styled-icons/boxicons-regular";

import { ModeOrAndComponent, Mode, Switcher, SwitcherItem, TooltipText } from "./styled";
import Tooltip from "../../../components/Tooltip";

const ModeOrAnd = ({ handleChangeOrAndMode, isActiveAndMode }) => {
  return (
    <ModeOrAndComponent>
      <Mode>Mode</Mode>
      <Tooltip
        contentStyle={{ width: "20px" }}
        tooltipContent={
          <div>
            <TooltipText>
              Use <span>AND</span> to find talent that meets all selected criteria.
            </TooltipText>
            <TooltipText>
              Use <span>OR</span> to find talent that meets at least one of the criteria.
            </TooltipText>
          </div>
        }
      >
        <InfoCircle style={{ width: "16px", height: "16px" }} className="ModeOrAndComponent_icon" />
      </Tooltip>
      <Switcher onClick={() => handleChangeOrAndMode(!isActiveAndMode)}>
        <SwitcherItem isActive={isActiveAndMode}>And</SwitcherItem>
        <SwitcherItem isActive={!isActiveAndMode}>Or</SwitcherItem>
      </Switcher>
    </ModeOrAndComponent>
  );
};

export default ModeOrAnd;
