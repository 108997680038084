import React, { useLayoutEffect, useContext } from "react";
import { observer, inject } from "mobx-react";

import InitContext from "contexts/init";
import { withRoute } from "utils/hocs/withRoute";
import Layout from "../../components/Layout";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import Loading from "../../components/Loading";
import PersonalInfoTab from "./components/PersonalInfoTab";

const MyProfile = ({ Settings: { state, employer, initializeSettings } }) => {
  const { current_employer, current_user } = useContext(InitContext);

  useLayoutEffect(() => {
    initializeSettings(current_employer, current_user);
  }, []);

  return (
    <ScreenStateMessage state={state}>
      <Layout.PageHeading className="fz-page-heading_tall mb-4">
        <Layout.Container>
          <h1 className="fz-title mt-0 mb-5">My profile</h1>
        </Layout.Container>
      </Layout.PageHeading>

      <Layout.Container>
        <Loading loading={!employer}>
          <PersonalInfoTab />
        </Loading>
      </Layout.Container>
    </ScreenStateMessage>
  );
};

export default withRoute(inject("Settings")(observer(MyProfile)));
