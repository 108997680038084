import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Content, Title, Text, InputName, Continue, Cancel } from "./styled";

const GetGuestCommentName = ({ onClose, createShareCandidateUserForComment, setLoggedUser, handleOpenCommentArea }) => {
  const [value, setValue] = useState("");
  const { id } = useParams();

  const handleSubmit = () => {
    if (value) {
      createShareCandidateUserForComment(id, value).then(res => {
        setLoggedUser(res.data.user);
        handleOpenCommentArea();
      });
    }
  };

  return (
    <Content>
      <Title>Add your name</Title>
      <Text>Share your name, so others know who wrote this comment</Text>
      <InputName placeholder="Your name" value={value} onChange={event => setValue(event.target.value)} />
      <Continue disabled={!value} onClick={handleSubmit}>
        Continue
      </Continue>
      <Cancel onClick={onClose}>Cancel</Cancel>
    </Content>
  );
};

export default GetGuestCommentName;
