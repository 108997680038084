import React from "react";
import { MdDone, MdForward, MdStar } from "react-icons/md";
import { useRouteMatch } from "react-router-dom";

import Dropdown from "components/Selects";
import Button from "components/Button";
import BatchActions from "../../../../components/BatchActions";
import Subscription from "../../../../components/Subscription";

const CardControlBarRecommendedTalent = ({
  selected = false,
  selectedSomeone,
  selectedCandidates,
  selectAll,
  clearSelection,
  options = [],
  shortlist,
  move,
  navigationIds,
  isNewSelectAll,
  candidatesLength,
  showSaveModalContent,
  isExternal
}) => {
  const { params } = useRouteMatch();

  return (
    <BatchActions
      selected={selected}
      selectedSomeone={selectedSomeone}
      selectAll={selectAll}
      clearSelection={clearSelection}
      navigationIds={navigationIds}
      selectedCandidates={selectedCandidates}
      isNewSelectAll={isNewSelectAll}
      candidatesLength={candidatesLength}
    >
      {!params.folder && !isExternal && (
        <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline" element="Talent pipeline">
          <Button.Simple onClick={showSaveModalContent} icon={<MdStar />} className="mr-3">
            Save talent
          </Button.Simple>
        </Subscription.Upgrade>
      )}
      <Subscription.Payment>
        <Button.Simple icon={<MdDone />} className="mr-3" onClick={shortlist}>
          Shortlist
        </Button.Simple>
      </Subscription.Payment>
      <Dropdown selectOptions={options} onSelect={move}>
        <Subscription.Payment>
          <Button.Simple icon={<MdForward />} className="mr-3">
            Move to
          </Button.Simple>
        </Subscription.Payment>
      </Dropdown>
    </BatchActions>
  );
};

export default CardControlBarRecommendedTalent;
