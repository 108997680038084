import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e0edff;
  background-color: #f3f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 16px;
  position: relative;
`;

export const EmptyState = styled.div`
  svg {
    width: 32px;
    height: 32px;
    fill: #408bfc;
    display: flex;
    margin: 0 auto 16px;
  }
`;

export const TransparentButton = styled.button`
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: transparent;
  border: none;
  color: transparent;
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

export const EmptyStateTitle = styled.h6`
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin: 0 0 2px 0;
`;

export const EmptyStateText = styled.p`
  color: #666;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const FileContent = styled.div`
  border-radius: 4px;
  border: 1px solid #e0edff;
  background-color: #f3f8ff;
  padding: 15px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileName = styled.p`
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  span {
    color: #666;
  }
`;

export const FileBlankIcon = styled.div`
  svg {
    width: 24px;
    height: 24px;
    fill: #408bfc;
  }
`;

export const CloseIcon = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 24px;
  height: 24px;

  svg {
    width: 16px;
    height: 16px;
    fill: #666666;
  }
`;
