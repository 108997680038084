import styled from "styled-components";
import { Link } from "react-router-dom";

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const TabItem = styled.button`
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;

  span {
    background: #c5c5c5;
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
    padding: 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
    flex: none;
    margin-left: 8px;
  }

  ${({ isActive }) =>
    isActive
      ? `
      background: rgba(255, 225, 64, 0.3);
      border-color: #FFE140;
      color: #333333;

      span {
        background: #FFE140;
        color: #333333;
      }
      `
      : ""}

  &:hover {
    border-color: #ffe140;
  }
`;

export const Heading = styled.div`
  max-width: 1320px;
  padding: 32px 20px;
  margin: 0 auto;
  // flex: 1 0 0;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    width: 100vw;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background: #ffffff;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const BackToTalentPool = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  position: relative;
  z-index: 1;
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  &:hover {
    color: #408bfc;
    text-decoration: none;
  }
`;

export const TalentPoolName = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 8px 0;
  position: relative;
  z-index: 1;
`;

export const Counter = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  position: relative;
  z-index: 1;
  margin: 0;
`;

export const EditFolder = styled.button`
  background: #f3f3f3;
  border-radius: 8px;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #444444;
  position: relative;
  z-index: 1;

  svg {
    width: 15px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const HeadingTalent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
    margin-top: 24px;
  }
`;
