import React, { useMemo, useState, useEffect, useContext } from "react";
import { toJS } from "mobx";
import cn from "classnames";

import AppContext from "contexts/init";
import { TABS } from "constants/tabs";
import Button from "components/Button";

import FilterTools from "../../../../components/FilterTools";
import Paginator from "../../../../components/Paginator";
import UpdatesTab from "../UpdatesTab";
import Layout from "../../../../components/Layout";
import JobDetailModalContent from "../JobDetailModalContent";
import RejectionMessagesModalContent from "../RejectionMessagesModalContent";
import DetailsTab from "../DetailsTab";
import RenderCandidates from "../RenderCandidates";
import CardControlBar from "../CardControlBar";
import CardControlBarRecommendedTalent from "../CardControlBarRecommendedTalent";
import InfoBar from "../InfoBar";
import SortingFilter from "../../../../components/SortingFilter";
import StatusFiltersBar from "../../../../components/StatusFiltersBar";
import { useModal } from "../../../../../utils/hooks";
import InviteCandidate from "../../../MyJobsScreen/components/InviteCandidate";
import ContactOptionsModal from "../../../../components/ContactOptionsModal";
import MessageButton from "../../../../components/MessageButton";
import Subscription from "../../../../components/Subscription";
import SelectJobForInviteContent from "../SelectJobForInviteContent";
import InviteToApplyContent from "../InviteToApplyContent";
import SaveModalContent from "../../../../components/SaveModalContentProvider";
import JobDetailShare from "../JobDetailShare";
import Introduction from "../Introduction";
import ShareCandidatesSortingFiltration from "../ShareCandidatesSortingFiltration";
import { Empty } from "./styled";
import { getCurrentUserAndEmployer } from "../../../../utils/helpers";
import Promotions from "../Promotions";
import FormPromotions from "../FormPromotions";

const JobDetailContent = ({
  candidates = [],
  reasons,
  type = "",
  tab = "applicants",
  filters,
  loading,
  updateList,
  scopeChangeReset,
  message,
  shortlist,
  shortlistWithAssignments,
  shortlistWithAssignmentsTalents,
  shortlistTalents,
  move,
  moveTalents,
  reject,
  removeTalent,
  rate,
  candidateTag,
  voteForCandidate,
  campaign,
  applyFilter,
  resetAllFilters,
  selectedCandidates,
  select,
  selectAll,
  clearSelection,
  rejectMessage,
  messageToSelectedPath,
  printCVs,
  exportCsv,
  total_count = 0,
  appliedFilters,
  getJobDetails,
  getJobUpdates,
  filterCount = 0,
  resetPrevApplied,
  getCandidateComments,
  getDetailShareCandidateComments,
  createComment,
  deleteComment,
  editComment,
  unlockUser,
  prioritize_industry_experience = false,
  industryApplied = false,
  pageCount = 0,
  isFastApply,
  getActiveCampaigns,
  getActiveCompanies,
  folders,
  removeFolder,
  addToFolder,
  createFolder,
  getFolders,
  addFolderIdToCandidate,
  isSharedCandidates,
  jobDetailShare,
  activeGuestLinkToken,
  initialState,
  navigationIds,
  createShareCandidateUserForComment,
  createCommentShareCandidate,
  isNewSelectAll,
  handleKeepPageInfo,
  getIntegrations,
  integrations,
  getTMTIntegrations,
  getEmployerNames,
  paramsId,
  getOccupationSearch,
  getOccupationAutocomplete,
  occupationSearch,
  occupationAutocompleteList,
  integrationEmployerNames,
  integrationCompanyDetails,
  createIntegration,
  updateIntegration,
  integrationDetailForm,
  removeIntegration,
  resetOccupation,
  jobB2CPath,
  integrationDetailErrors,
  resetIntegrationDetailErrors,
  activeGuestLinkDate,
  activeGuestLinkPitch
}) => {
  const {
    page,
    per_page: perPage,
    filters: { sort_by: currentSortBy = "", sort_order: sortOrder, scope } = {}
  } = appliedFilters;
  // Candidates removed from current phase
  const [filtered, setFiltered] = useState([]);
  // Commented count
  const [commented, setCommented] = useState([]);
  const [modifiedScopes, updateModifiedScopes] = useState(campaign.phases);
  const {
    current_employer: currentEmployer = {},
    current_user: currentUser = {},
    feature_toggles
  } = useContext(AppContext);
  const { assignments, employer_name: employerName, title: jobTitle, days_remaining: daysRemaining } = campaign;
  const isEnablePromotions = feature_toggles?.employer_promotions;

  const isShowGuestLinkPitch = useMemo(() => {
    return activeGuestLinkPitch?.length && activeGuestLinkPitch?.length !== 8 && isSharedCandidates;
  }, [activeGuestLinkPitch, isSharedCandidates]);

  const setKeepPageInfo = () => {
    handleKeepPageInfo({ page, scope });
  };

  // Candidate removal
  const handleRemoval = (pendingPromise, ids = [], toPhase) => {
    pendingPromise.then(() => {
      setFiltered(prev => prev.concat(ids.map(id => ({ id, toPhase }))));
    });
  };

  const handleRemovalShortlist = id => {
    const candidateIds = typeof id === "object" ? id : [id];

    handleRemoval(shortlist(candidateIds), candidateIds, "shortlisted");
  };

  const handleRemovalShortlistTalents = id => {
    const candidateIds = typeof id === "object" ? id : [id];

    handleRemoval(shortlistTalents(candidateIds), candidateIds, "shortlisted");
  };

  const handleGetComments = (id, isOtherCampaigns) => {
    if (isSharedCandidates) {
      return getDetailShareCandidateComments(id);
    }
    return getCandidateComments(id, isOtherCampaigns);
  };

  const handleOpenSelectJobForInvite = (toggle, current) => {
    toggle(
      <SelectJobForInviteContent
        selectedTalents={[current.user.id]}
        handleInviteToApply={() => handleInviteToApply(toggle, current)}
        handleClose={() => handleInviteToApply(toggle, current)}
      />,
      "Select job",
      false
    );
  };

  const handleInviteToApply = (toggle, current) => {
    toggle(
      <InviteToApplyContent
        currentId={tab === TABS.HEADHUNT ? current.user.user_id : current.user.id}
        currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
        toggle={toggle}
        talents={candidates}
        addToJob={() => handleOpenSelectJobForInvite(toggle, current)}
        handleInviteToApply={() => handleInviteToApply(toggle, current)}
        isJobHeadhunt
        campaign={campaign}
      />,
      "Invite talent to apply"
    );
  };

  const showModalInviteCandidate = useModal(
    <InviteCandidate
      assignments={assignments}
      candidates={candidates}
      selectedCandidates={selectedCandidates}
      navigationIds={navigationIds}
      employerName={employerName}
      jobTitle={jobTitle}
      left={daysRemaining}
      shortlistWithAssignments={shortlistWithAssignments}
      handleRemoval={handleRemoval}
      handleRemovalShortlist={handleRemovalShortlist}
    />,
    "Shortlist and send assignments to candidates"
  );

  const messageButton = candidate => {
    return (
      <MessageButton
        isUnlocked={candidate.profile_unlocked}
        unlockHandler={() => unlockUser(candidate?.user?.id, candidate.id, true, campaign.employer_id)}
        type={tab === TABS.HEADHUNT ? "talent" : "candidate"}
        campaignEmployerId={campaign.employer_id}
        users={[
          {
            id: tab === TABS.HEADHUNT ? candidate.user?.user_id : candidate?.user?.id,
            name: tab === TABS.HEADHUNT ? candidate.user?.name : currentUser.name
          }
        ]}
        Component={props => (
          <Subscription.Upgrade permission="messaging">
            <Button className="fz-btn-secondary fz-btn-secondary_yellow mr-1" {...props}>
              <span className="fz-btn-secondary__text">Next</span>
            </Button>
          </Subscription.Upgrade>
        )}
      />
    );
  };

  const showContactOptionsModal = (toggle, candidate) => {
    return toggle(
      <ContactOptionsModal
        messageButton={messageButton}
        handleInviteToApply={() => handleInviteToApply(toggle, candidate)}
        candidate={candidate}
        getAvailableJobForCandidate={getActiveCampaigns}
        getActiveCompanies={getActiveCompanies}
        isJobHeadhunt
        isJobHeadhuntPage
        isUnlocked={candidate.profile_unlocked}
        unlockHandler={() => unlockUser(candidate?.user?.id, candidate.id, true, campaign.employer_id)}
      />,
      "How do you want to contact the candidate?"
    );
  };

  const showModalInviteCandidateTalents = useModal(
    <InviteCandidate
      assignments={assignments}
      candidates={candidates}
      employerName={employerName}
      jobTitle={jobTitle}
      left={daysRemaining}
      shortlistWithAssignments={shortlistWithAssignmentsTalents}
      handleRemoval={handleRemoval}
      handleRemovalShortlist={handleRemovalShortlistTalents}
    />,
    "Shortlist and send assignments to candidates"
  );

  const showSaveModalContent = (toggle, candidate) => {
    const selectedUserId = candidates
      .filter(item => selectedCandidates.includes(item.id))
      .map(item => item.user.user_id || item.user.id);

    return toggle(
      <SaveModalContent
        selectedTalents={selectedCandidates.length ? selectedUserId : [candidate.user.user_id || candidate.user.id]}
        inFolders={selectedCandidates.length ? [] : candidate.headhunting_folder_ids}
        close={toggle}
        folders={folders}
        removeFolder={removeFolder}
        addToFolder={addToFolder}
        createFolder={createFolder}
        getFolders={getFolders}
        callBack={folderId =>
          addFolderIdToCandidate(selectedCandidates.length ? selectedCandidates : candidate.id, folderId)
        }
      />,
      ""
    );
  };

  const showSaveModalContentRecommended = useModal(
    <SaveModalContent
      selectedTalents={candidates
        .filter(item => selectedCandidates.includes(item.id))
        .map(item => item.user.user_id || item.user.id)}
      inFolders={[]}
      folders={folders}
      removeFolder={removeFolder}
      addToFolder={addToFolder}
      createFolder={createFolder}
      getFolders={getFolders}
      callBack={folderId => addFolderIdToCandidate(selectedCandidates, folderId)}
    />,
    ""
  );

  useEffect(() => {
    setCommented([]);
    setFiltered([]);
  }, [candidates]);

  useEffect(() => {
    setFiltered(prev => (prev.length > 0 && !loading ? [] : prev));
  }, [filters.scopes, loading]);

  useEffect(() => {
    if (!loading && filters.scopes)
      updateModifiedScopes(
        filters.scopes.map(elem => ({
          ...elem,
          count:
            (elem.count ? elem.count : 0) +
            (elem.value === scope ? -filtered.length : filtered.filter(item => item.toPhase === elem.value).length)
        }))
      );
  }, [filtered, filters.scopes, loading]);

  // Computing scopes
  const scopeOptions = useMemo(
    () =>
      filters.scopes && filters.scopes.length > 0
        ? filters.scopes.filter(({ value }) => value !== "all" && value !== scope)
        : campaign.phases,
    [filters.scopes, scope]
  );

  // Candidate rejection confirmation dialog
  const handleRejection = (toggle, ids, remove = false) => {
    toggle(
      <JobDetailModalContent
        type={remove ? "remove" : "reject"}
        toggle={toggle}
        reject={() =>
          remove ? handleRemoval(removeTalent(ids), [ids], "deleted") : handleRemoval(reject(ids), ids, "rejected")
        }
        message={`Are you sure you want to ${remove ? "remove" : "reject"} ${
          ids.length > 1 ? "these candidates" : "this candidate"
        }?`}
      />
    );
  };

  const handleRejectionMessages = (toggle, ids) => {
    toggle(
      <RejectionMessagesModalContent
        reasons={reasons}
        ids={ids}
        campaign={campaign}
        rejectMessage={rejectMessage}
        currentEmployer={currentEmployer}
        currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
        selectedCandidates={selectedCandidates?.length ? selectedCandidates : ids}
        navigationIds={navigationIds}
        candidates={candidates}
        toggle={toggle}
      />,
      "Reject and provide candidates with feedback"
    );
  };

  const addComment = (id, body, visibility) =>
    createComment(id, body, visibility).then(comment => {
      const modified = commented.find(item => item.id === id);
      const index = commented.indexOf(modified);
      setCommented(prev => {
        if (index !== -1) {
          prev[index] = { ...modified, count: modified.count + 1 };
          return prev;
        }
        return prev.concat({ id, count: 1 });
      });
      return comment;
    });

  const handleEditComment = (id, body, visibility, commentId) => {
    return editComment(id, body, visibility, commentId).then(comment => {
      const modified = commented.find(item => item.id === id);
      const index = commented.indexOf(modified);
      setCommented(prev => {
        if (index !== -1) {
          prev[index] = { ...modified, count: modified.count + 1 };
          return prev;
        }
        return prev.concat({ id, count: 1 });
      });
      return comment;
    });
  };

  const removeComment = (id, commentId) =>
    deleteComment(id, commentId).then(() => {
      const modified = commented.find(item => item.id === id);
      const index = commented.indexOf(modified);
      setCommented(prev => {
        if (index !== -1) {
          prev[index] = { ...modified, count: modified.count - 1 };
          return prev;
        }
        return prev.concat({ id, count: -1 });
      });
    });

  // Checking if any candidates got retrieved
  const gotCandidates = useMemo(
    () => loading || !(candidates.length === 0 && filterCount === 0 && appliedFilters.filters.term.length === 0),
    [loading, candidates, filterCount, appliedFilters.filters.term]
  );

  const handleShortlist = (id, isSelectedCandidates) => {
    const selectedCandidatesIds = isSelectedCandidates
      ? selectedCandidates
      : Array.from(new Set([id, ...selectedCandidates]));

    if (isFastApply) {
      showModalInviteCandidate({
        handleShortlist: () => handleRemovalShortlist(id),
        selectedCandidatesIds
      });
    } else {
      handleRemovalShortlist(selectedCandidatesIds);
    }
  };

  const handleShortlistTalent = id => {
    const selectedCandidatesIds = Array.from(new Set([id, ...selectedCandidates]));

    if (isFastApply) {
      showModalInviteCandidateTalents({
        handleShortlist: () => handleRemovalShortlistTalents(id),
        selectedCandidatesIds
      });
    } else {
      handleRemovalShortlistTalents(selectedCandidatesIds);
    }

    showModalInviteCandidateTalents({
      handleShortlist: () => handleRemovalShortlistTalents(id),
      selectedCandidatesIds
    });
  };

  const employerBusinessId = "2808477-9";

  if (type !== "500") {
    switch (tab) {
      default:
      case "applicants":
      case "headhunt":
        return (
          <section className={cn("fz-page-wide-container", isSharedCandidates ? "fz-page-wide-container_guest" : "")}>
            {!isSharedCandidates ? (
              <StatusFiltersBar
                tab={tab}
                loading={loading}
                onChange={filtersList => scopeChangeReset({ filters: filtersList, page: 1 })}
                onSearch={filtersList => applyFilter(filtersList)}
                placeholder="Search candidates"
                scopes={modifiedScopes}
                currentScope={scope}
                filtered={filtered}
                term={appliedFilters.filters.term}
              />
            ) : (
              <>
                {candidates?.length || appliedFilters.filters.term ? (
                  <ShareCandidatesSortingFiltration
                    term={appliedFilters.filters.term}
                    update={updateList}
                    onSearch={filtersList => applyFilter(filtersList)}
                    placeholder="Search by name or title"
                    perPage={perPage}
                    date={activeGuestLinkDate}
                    totalCount={total_count}
                  />
                ) : (
                  <Empty />
                )}
              </>
            )}
            {!isSharedCandidates && gotCandidates && tab !== "headhunt" && (
              <CardControlBar
                isNewSelectAll={isNewSelectAll}
                campaignEmployerId={campaign.employer_id}
                tab={tab}
                scope={scope}
                candidate
                candidatesLength={candidates.length}
                selected={selectedCandidates.length > 0 && selectedCandidates.length === candidates.length}
                candidates={candidates
                  .filter(({ id }) => selectedCandidates.includes(id))
                  .map(item => ({
                    id: item.id,
                    name: item.user?.name,
                    prospectId: item.prospect_id
                  }))}
                selectedSomeone={selectedCandidates.length > 0}
                selectedCandidates={selectedCandidates}
                selectAll={selectAll}
                clearSelection={clearSelection}
                options={scopeOptions}
                shortlist={() => handleShortlist(false, true)}
                move={(phase, toggle) =>
                  phase === "rejected"
                    ? handleRejection(toggle, selectedCandidates)
                    : handleRemoval(move(selectedCandidates, phase, "", true), selectedCandidates, phase)
                }
                messageToSelectedPath={messageToSelectedPath}
                printCVs={printCVs}
                exportCsv={exportCsv}
                reject={(toggle, id) => handleRejectionMessages(toggle, [id])}
                navigationIds={navigationIds}
                showSaveModalContent={showSaveModalContent}
              />
            )}
            {!isSharedCandidates && gotCandidates && tab === "headhunt" && (
              <CardControlBarRecommendedTalent
                isNewSelectAll={isNewSelectAll}
                candidatesLength={candidates.length}
                selected={selectedCandidates.length > 0 && selectedCandidates.length === candidates.length}
                selectedSomeone={selectedCandidates.length > 0}
                selectedCandidates={selectedCandidates}
                selectAll={selectAll}
                clearSelection={clearSelection}
                // options={scopeOptions}
                options={scopeOptions?.filter(option => !/rejected|shortlisted|applied|all/.test(option.value))}
                shortlist={() => handleShortlist(false, true)}
                move={(phase, toggle) =>
                  phase === "rejected"
                    ? handleRejection(toggle, selectedCandidates)
                    : handleRemoval(move(selectedCandidates, phase, "", true), selectedCandidates, phase)
                }
                navigationIds={navigationIds}
                showSaveModalContent={showSaveModalContentRecommended}
                isExternal={campaign.is_current_user_external_collaborator}
              />
            )}

            <div className="fz-container-with-sidebar">
              <div className="fz-container-with-sidebar__content" style={{ minHeight: "460px" }}>
                {!isSharedCandidates && gotCandidates && (
                  <SortingFilter
                    sortOrder={sortOrder}
                    currentSortBy={currentSortBy}
                    fields={
                      filters.sorting.fields &&
                      filters.sorting.fields.filter(
                        item =>
                          !(tab === TABS.HEADHUNT && (item.value === "comments_count" || item.value === "created_at"))
                      )
                    }
                    update={updateList}
                    perPage={perPage}
                  />
                )}
                {!isSharedCandidates && prioritize_industry_experience && industryApplied && (
                  <InfoBar
                    title={<span>Please note!</span>}
                    text="This campaign prioritises candidates with work experience in your line of industry.
                  If you want to see all the candidates, please reset the industry filter."
                  />
                )}

                <div className="fz-panel-list">
                  {isShowGuestLinkPitch ? <Introduction activeGuestLinkPitch={activeGuestLinkPitch} /> : null}

                  <RenderCandidates
                    tab={tab}
                    isFastApply={isFastApply}
                    total={total_count}
                    loading={loading}
                    isApplied={
                      filterCount > 0 || (appliedFilters.filters.term && appliedFilters.filters.term.length > 0)
                    }
                    page={+page}
                    selectedCandidates={selectedCandidates}
                    select={select}
                    campaign={campaign.id}
                    campaignEmployerId={campaign.employer_id}
                    shortcuts={campaign.tags_available}
                    perPage={perPage}
                    candidateTag={candidateTag}
                    message={message}
                    contact={(toggle, candidate) => showContactOptionsModal(toggle, candidate)}
                    shortlist={id => handleShortlist(id)}
                    shortlistTalents={id => handleShortlistTalent(id)}
                    move={(id, phase) => handleRemoval(move([id], phase), [id], phase)}
                    moveTalents={(id, phase) => handleRemoval(moveTalents([id], phase), [id], phase)}
                    reject={(toggle, id) => handleRejectionMessages(toggle, [id])}
                    removeTalent={(toggle, id) => handleRejection(toggle, id, true)}
                    rate={rate}
                    options={scopeOptions}
                    candidates={candidates}
                    filtered={filtered}
                    createShareCandidateUserForComment={createShareCandidateUserForComment}
                    createCommentShareCandidate={createCommentShareCandidate}
                    voteForCandidate={voteForCandidate}
                    getComments={(id, isOtherCampaigns) => handleGetComments(id, isOtherCampaigns)}
                    add={(id, body, visibility) =>
                      isSharedCandidates
                        ? createCommentShareCandidate(activeGuestLinkToken, id, body)
                        : addComment(id, body, visibility)
                    }
                    remove={(id, commentId) => removeComment(id, commentId)}
                    editComment={(id, body, visibility, commentId) =>
                      handleEditComment(id, body, visibility, commentId)
                    }
                    scope={scope}
                    commented={commented}
                    unlockUser={unlockUser}
                    showSaveModalContent={showSaveModalContent}
                    isSharedCandidates={isSharedCandidates}
                    activeGuestLinkToken={activeGuestLinkToken}
                    appliedFiltersScope={appliedFilters.filters.scope}
                    setKeepPageInfo={setKeepPageInfo}
                  />
                  <Paginator pageCount={pageCount} updateList={updateList} page={page} />
                </div>
              </div>

              {!isSharedCandidates && gotCandidates && (
                <div className="fz-container-with-sidebar__aside">
                  <FilterTools
                    loading={loading}
                    applyFilter={applyFilter}
                    resetAllFilters={resetAllFilters}
                    filterCount={filterCount}
                    appliedFilters={appliedFilters.filters}
                    filters={filters}
                    requiredSkills={campaign.required_skill_ids}
                    industry={campaign.industry_id}
                    job={campaign.job_category_id}
                    defaultAgeMin={campaign.candidates_age_min}
                    defaultAgeMax={campaign.candidates_age_max}
                    defaultExpMin={campaign.candidates_experience_min}
                    defaultExpMax={campaign.candidates_experience_max}
                    tagShortcuts={campaign.tags_available}
                    resetPrevApplied={resetPrevApplied}
                  />
                </div>
              )}
            </div>
          </section>
        );
      case "details":
        return (
          <section className={cn("fz-page-wide-container", isSharedCandidates ? "fz-page-wide-container_guest" : "")}>
            <div className="fz-filter-bar">
              {isSharedCandidates ? (
                <JobDetailShare jobDetail={jobDetailShare} initialState={initialState} />
              ) : (
                <DetailsTab getJobDetails={getJobDetails} />
              )}
            </div>
          </section>
        );
      case "updates":
        return (
          <section className="fz-page-wide-container">
            <div className="fz-filter-bar">
              <UpdatesTab getJobUpdates={getJobUpdates} />
            </div>
          </section>
        );
      case "promotions":
        return (
          <>
            {isEnablePromotions ? (
              <Promotions
                integrations={integrations}
                getIntegrations={getIntegrations}
                id={paramsId}
                employerBusinessId={employerBusinessId}
                removeIntegration={removeIntegration}
                jobB2CPath={jobB2CPath}
              />
            ) : null}
          </>
        );
      case "post-promotions":
        return (
          <>
            {isEnablePromotions ? (
              <FormPromotions
                getOccupationSearch={getOccupationSearch}
                getOccupationAutocomplete={getOccupationAutocomplete}
                getEmployerNames={getEmployerNames}
                integrationCompanyDetails={integrationCompanyDetails}
                id={paramsId}
                employerBusinessId={employerBusinessId}
                occupationAutocompleteList={toJS(occupationAutocompleteList)}
                occupationSearch={occupationSearch}
                integrationEmployerNames={integrationEmployerNames}
                handleSubmitIntegration={createIntegration}
                integrationDetailErrors={integrationDetailErrors}
                resetOccupation={resetOccupation}
                resetIntegrationDetailErrors={resetIntegrationDetailErrors}
              />
            ) : null}
          </>
        );
      case "edit-promotions":
        return (
          <>
            {isEnablePromotions ? (
              <FormPromotions
                getOccupationSearch={getOccupationSearch}
                getOccupationAutocomplete={getOccupationAutocomplete}
                getEmployerNames={getEmployerNames}
                integrationCompanyDetails={integrationCompanyDetails}
                id={paramsId}
                employerBusinessId={employerBusinessId}
                occupationAutocompleteList={toJS(occupationAutocompleteList)}
                occupationSearch={occupationSearch}
                integrationEmployerNames={integrationEmployerNames}
                handleSubmitIntegration={updateIntegration}
                getTMTIntegrations={getTMTIntegrations}
                integrationDetailForm={toJS(integrationDetailForm)}
                resetOccupation={resetOccupation}
                integrationDetailErrors={integrationDetailErrors}
                resetIntegrationDetailErrors={resetIntegrationDetailErrors}
                isEditPromotion
              />
            ) : null}
          </>
        );
    }
  } else return <Layout.Error message="Internal server error" onClick={() => updateList()} />;
};

export default JobDetailContent;
