import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
`;

export const Tile = styled.div`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 24px;
  //display: flex;
  //justify-content: space-between;
  margin-bottom: 16px;
`;

export const TileFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const TileLabel = styled.span`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin: 0 0 4px 0;
`;

export const TileDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 16px 0;
`;

export const TileCount = styled.span`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #eee;
  color: #444;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const TileType = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;

  svg {
    width: 24px;
    height: 24px;
    fill: #666666;
  }
`;

export const TileTitle = styled.h6`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 4px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TileAnswers = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

export const TileAnswersItem = styled.span`
  display: block;
  border-radius: 4px;
  background: #f3f8ff;
  padding: 2px 8px;
  color: #062c66;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  height: 24px;
  max-width: 640px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const HeadingFlex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Remove = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e71d36;
  cursor: pointer;
  background-color: #fff;

  svg {
    width: 16px;
    height: 16px;
    fill: #e71d36;
  }
`;
