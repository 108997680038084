import React, { useContext } from "react";
import { observer, inject } from "mobx-react";

import { getCurrentUserAndEmployer } from "b2b/utils/helpers";
import FindTalentCategory from "../FindTalentCategory";
import FindTalentList from "../FindTalentList";
import Layout from "../../../../components/Layout";
import InviteToApplyContent from "../../../../components/InviteToApplyContent";
import AppContext from "../../../../../contexts/init";
import SelectJobForInviteContent from "../../../../components/SelectJobForInviteContent";

const FindTalent = inject("store")(
  observer(({ store: { applyFilter, paramsCount, talents, unlockUser } }) => {
    const { current_employer: currentEmployer, current_user: currentUser } = useContext(AppContext);

    const handleInviteToApply = (toggle, ids) => {
      toggle(
        <InviteToApplyContent
          currentId={ids}
          currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
          selectedCandidatesIds={ids}
          toggle={toggle}
          talents={talents}
          addToJob={() => handleOpenSelectJobForInvite(toggle, ids)}
          handleInviteToApply={() => handleInviteToApply(toggle, ids)}
        />,
        "Invite talent to apply"
      );
    };

    const handleOpenSelectJobForInvite = (toggle, ids) => {
      toggle(
        <SelectJobForInviteContent
          selectedTalents={ids}
          handleInviteToApply={() => handleInviteToApply(toggle, ids)}
          handleClose={() => handleInviteToApply(toggle, ids)}
        />,
        "Select job",
        false
      );
    };

    return (
      <Layout.Container type={paramsCount > 0 ? "wide" : "default"}>
        {paramsCount > 0 ? (
          <FindTalentList
            handleOpenSelectJobForInvite={handleOpenSelectJobForInvite}
            handleInviteToApply={handleInviteToApply}
            unlockUser={unlockUser}
          />
        ) : (
          <FindTalentCategory applyFilter={applyFilter} />
        )}
      </Layout.Container>
    );
  })
);

export default FindTalent;
