import React, { useEffect, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";

import { theme } from "b2c/contexts/theme";
import { isBaronaBrand } from "contexts/init";
import {
  HeadingComponent,
  CountContent,
  Count,
  CountDescription,
  Title,
  Content,
  FilterContent,
  FilterSearchContent,
  FilterSearch,
  FilterSearchIcon,
  FilterCloseIcon,
  SearchInput,
  SearchButton
} from "./styled";
import Filters from "../Filters";
import { onEnterPress } from "../../../../../utils/helpers";

const Heading = ({ initFilters, totalCount, onChange, appliedFilters, getSearchCandidatesUserFilter }) => {
  const [term, setTerm] = useState("");
  const isBarona = isBaronaBrand();

  const handleSubmit = () => {
    onChange({ ...appliedFilters, term });
  };

  const handleChange = value => {
    setTerm(value);
  };

  const handleClear = () => {
    setTerm("");
    onChange({ ...appliedFilters, term: "" });
  };

  useEffect(() => {
    if (appliedFilters.term !== term) {
      setTerm(appliedFilters.term);
    }
  }, [appliedFilters.term]);

  return (
    <HeadingComponent background={isBarona ? "linear-gradient(90deg, #6CBEFF 37%, #2496F5 85%, #1075D1 100%)" : ""}>
      <Content>
        <Title>Candidates</Title>
        <CountContent>
          <Count>{totalCount}</Count>
          <CountDescription>candidates</CountDescription>
        </CountContent>
        <FilterContent>
          <FilterSearchContent>
            <FilterSearch>
              <FilterSearchIcon>
                <MdSearch />
              </FilterSearchIcon>
              <SearchInput
                type="text"
                placeholder="Search by name, title, education, email, or phone"
                onChange={e => handleChange(e.target.value)}
                onKeyDown={e => onEnterPress(e, term, () => handleSubmit())}
                value={term}
              />
              {term && (
                <FilterCloseIcon onClick={handleClear}>
                  <MdClose fill="#666666" />
                </FilterCloseIcon>
              )}
            </FilterSearch>
            <SearchButton
              onClick={handleSubmit}
              background={theme.primaryButtonBackground}
              color={theme.primaryButtonLabelColor}
            >
              Search
            </SearchButton>
          </FilterSearchContent>

          <Filters
            initFilters={initFilters}
            onChange={onChange}
            appliedFilters={appliedFilters}
            getSearchCandidatesUserFilter={getSearchCandidatesUserFilter}
          />
        </FilterContent>
      </Content>
    </HeadingComponent>
  );
};

export default Heading;
