import React, { useState, useContext } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { InfoCircle } from "@styled-icons/boxicons-regular";

import { ModalContext } from "contexts/modal";
import SingleConversation from "pages/Messenger/components/SingleConversation";
import Button from "components/Button";
import badge_small from "images/badge_small.png";
import warning from "images/fa-warning-red.png";
import Checkbox from "../Checkbox";
import { TagContent, Tag, Info } from "./styled";

const settingsMap = {
  read_only: "Don't let the applicant to reply to your message",
  anonymous: "Hide your name in the conversation (only show name of the company)",
  pinned: "Pin conversation",
  separate: "Send as separate conversations"
};

const ConversationModalContent = ({
  Messages: {
    state: { processing },
    addEmployerConversation,
    addBatchConversation
  },
  type = "candidate",
  users,
  campaignEmployerId,
  selectedCandidates,
  navigationIds
}) => {
  const { toggle } = useContext(ModalContext);
  const [messageBody, setBody] = useState("");
  const separate = true;
  const [messageSettings, setSettings] = useState({
    anonymous: true,
    read_only: false,
    pinned: false
  });

  const selectedCandidatesLength = selectedCandidates?.length;
  const navigationIdsLength = navigationIds?.length;
  const isSelectedAllNavigation =
    selectedCandidatesLength && navigationIdsLength && selectedCandidatesLength === navigationIdsLength;
  const selectedCandidatesOther = users?.length && navigationIdsLength ? navigationIdsLength - users?.length : 0;
  const externalCandidates = users.filter(item => Boolean(item.prospectId));

  const handleSettingToggle = key => {
    setSettings(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleSubmission = () => {
    const addConversation = () => {
      return type === "batch"
        ? addBatchConversation(
            isSelectedAllNavigation ? navigationIds : users.filter(item => !item.prospectId).map(({ id }) => id),
            messageBody,
            messageSettings,
            campaignEmployerId,
            separate
          )
        : addEmployerConversation(users[0].id, messageBody, messageSettings, type, campaignEmployerId);
    };

    addConversation().then(response => {
      if (response.conversation) {
        toggle(<SingleConversation campaignEmployerId={campaignEmployerId} conversation={response.conversation} />, "");
      } else {
        toggle(<ResultContent onClose={() => toggle()} {...response} />, "Conversation status");
      }
    });
  };

  return (
    <div className="conversation-content">
      <div className="section-label">Recipients</div>
      <div className="recipient-list">
        <TagContent>
          {users.map(participant => (
            <Tag key={participant.id} isNotActive={participant.prospectId}>
              {participant.name}
            </Tag>
          ))}
        </TagContent>
        {isSelectedAllNavigation && Number(selectedCandidatesOther) ? (
          <div
            key={isSelectedAllNavigation}
            style={{
              background: "#EEE",
              color: "#333",
              display: "inline-block",
              padding: "6px 12px",
              borderRadius: "13px",
              margin: "10px 7px"
            }}
          >
            {`+ ${selectedCandidatesOther} more`}
          </div>
        ) : null}
      </div>

      {externalCandidates.length ? (
        <Info>
          <InfoCircle />
          <div>
            <p>
              Your selection includes <span>{externalCandidates.length}</span> external candidate.
            </p>
            <p>Sending messages to external candidates isn`t supported yet.</p>
          </div>
        </Info>
      ) : null}

      <div className="section-label">Your message</div>
      <textarea rows={10} value={messageBody} onChange={({ target: { value } }) => setBody(value)} />

      {/*
            {type == "batch" &&
                <Checkbox
                    onCheck={() => setSeparate(!separate)}
                    checked={separate}
                >
                    {settingsMap.separate}
                </Checkbox>
            }
            */}

      {Object.keys(messageSettings)
        .filter(key => key === "anonymous" || key === "read_only")
        .map(key => (
          <Checkbox key={key} onCheck={() => handleSettingToggle(key)} checked={messageSettings[key]}>
            {settingsMap[key]}
          </Checkbox>
        ))}

      <Button disabled={!messageBody || processing} color="yellow" size="md" onClick={handleSubmission}>
        {processing ? "Processing" : "Send"}
      </Button>
    </div>
  );
};

const ResultContent = ({ type = "success", message = "", onClose }) => {
  return (
    <div className="conversation-content">
      <div className="info-row">
        <img src={type === "success" ? badge_small : warning} alt="Status image" />
        <span>{message}</span>
      </div>
      {type === "success" && (
        <Link to="/messages">
          <Button color="blue" size="md" onClick={onClose}>
            Go to messages
          </Button>
        </Link>
      )}
    </div>
  );
};

export default inject("Messages")(observer(ConversationModalContent));
