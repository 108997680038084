import React from "react";

import { Modal, Title, Text, Delete, Flex, Cancel } from "./styled";

const DeleteShareShortlisted = ({ close, deleteGuestLink, handleOpenShareCampaignModal, openGuestLinkModalEdit }) => {
  const handleDelete = () => {
    deleteGuestLink();
    close();
  };

  const handleClose = () => {
    close();
    if (openGuestLinkModalEdit) {
      openGuestLinkModalEdit();
    } else {
      handleOpenShareCampaignModal();
    }
  };

  return (
    <Modal>
      <Title>Delete this link?</Title>

      <Text>Everyone you’ve shared this link with will lose access immediately.</Text>

      <Flex>
        <Cancel onClick={handleClose}>Cancel</Cancel>
        <Delete onClick={handleDelete}>Yes, delete it</Delete>
      </Flex>
    </Modal>
  );
};

export default DeleteShareShortlisted;
