import React, { useEffect, useState, useMemo, useContext } from "react";
import { Lock } from "@styled-icons/boxicons-solid";
import { InfoCircle } from "@styled-icons/boxicons-regular";
import { MdArrowForward, MdClose } from "react-icons/md";

import Button from "components/Button";
import { Textarea, Text, Spinner, SwitchInput } from "b2c/components/Core";
import { theme } from "b2c/contexts/theme";
import {
  SelectFromActiveJob,
  UnlockBlock,
  UnlockItem,
  UnlockText,
  UnlockDescription,
  UnlockCount,
  Notification
} from "./styled";
import SelectedTalents from "../SelectedTalents";
import CompaniesModalContent from "../CompaniesModalContent";
import AppContext, { isBaronaBrand } from "../../../contexts/init";

const InviteToApply = ({
  inviteCandidate,
  selectedAvailableJobForCandidate,
  selectedCandidatesIds,
  currentId,
  getAvailableJobForCandidate,
  addToJob,
  toggle,
  talents,
  currentUserAndEmployer,
  isJobHeadhunt,
  campaign,
  availableJobsForCandidate,
  isJobHeadhuntPage,
  selectedEmployer,
  campaignActive,
  handleInviteToApply,
  resetSelectedEmployer,
  isHideCompany,
  getActiveCampaigns,
  companies
}) => {
  const [jobId, setJobId] = useState();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState();
  const [replyMessage, setReplyMessage] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const recipientsIds = selectedCandidatesIds?.length ? selectedCandidatesIds : [currentId];
  const recipientsNames = useMemo(
    () =>
      talents
        .filter(item => recipientsIds.includes(item.user?.user_id || item?.user_id || item.id))
        .map(item => (isJobHeadhunt && !isJobHeadhuntPage ? item.user.name : item.name)),
    [talents.length, recipientsIds.length]
  );
  const isBarona = isBaronaBrand();

  const {
    current_user: currentUser,
    subscription: {
      limits: { unlocked_profiles_count = 0, unlock_profiles_limit }
    }
  } = useContext(AppContext);
  const isLeadHr = currentUser?.roles?.includes("lead_hr");
  const unlocksLeft = Math.max(unlock_profiles_limit - unlocked_profiles_count, 0);
  const isDontHaveEnoughUnlocks = recipientsIds.length > unlocksLeft;
  const prefilledJobDetail = useMemo(
    () =>
      isJobHeadhunt &&
      availableJobsForCandidate.length &&
      availableJobsForCandidate.find(item => item.id === campaign.id),
    [availableJobsForCandidate]
  );

  const talentTeamText = isBarona ? "Barona Lahjakkuus -tiimi" : "Fuzu Talent team";

  const senderAndCompanyName = isLeadHr
    ? `${currentUserAndEmployer[1].name}, ${talentTeamText}`
    : `${currentUserAndEmployer[1].name}${currentUserAndEmployer[0].name ? `, ${currentUserAndEmployer[0].name}` : ""} `;

  const isPrefilledJob = Boolean(prefilledJobDetail?.id);
  const campaignId = isJobHeadhunt && isPrefilledJob ? prefilledJobDetail.id : selectedAvailableJobForCandidate.id;
  const messageName = recipientsNames.length > 1 ? "#applicant_first_name" : recipientsNames[0];
  const jobName = isPrefilledJob ? prefilledJobDetail.title : selectedAvailableJobForCandidate.title;

  const messageEnglish = `Dear ${messageName || ""}, \n\nWhen searching for potential candidates for the ${
    jobName || ""
  }, I came across your profile. I think your background and professional skills would match the position very well. I suggest you take a look at the role and, if interested, submit your application. \n\nThank you for investing a few minutes in this message, and we look forward to reviewing your application over the coming days! \n\nBest regards, \n${senderAndCompanyName}`;

  const messageFinish = `Hei ${messageName},
Olen etsimässä sopivia ehdokkaita ${jobName || ""} -tehtävään, ja profiilisi kiinnitti huomioni.
Taustasi ja osaamisesi voisivat sopia tähän rooliin erinomaisesti.
Suosittelen lämpimästi tutustumaan tehtävään ja lähettämään hakemuksen, mikäli kiinnostuit siitä. Jos tehtävä ei juuri nyt tunnu sopivalta, voit jättää tämän viestin huomiotta. 
Toivottavasti kuulemme sinusta pian!

Ystävällisin terveisin,
${senderAndCompanyName}
Baronan rekrytointitiimi
`;

  const message = isBarona ? messageFinish : messageEnglish;

  const handleNotify = () => {
    setNotifyEmail(prev => !prev);
  };

  const handleReply = () => {
    setReplyMessage(prev => !prev);
  };

  const handleSubmit = () => {
    setLoading(true);
    const params = {
      candidate_ids: recipientsIds,
      notify_requested: notifyEmail,
      reply_allowed: replyMessage,
      message: text
    };
    inviteCandidate(campaignId, params).then(() => {
      setLoading(false);
      toggle();
      if (resetSelectedEmployer) {
        resetSelectedEmployer();
      }
    });
  };

  const handleClickSelectCompany = () => {
    toggle(
      <CompaniesModalContent
        selectedTalents={recipientsIds}
        close={toggle}
        addToJob={handleInviteToApply}
        recipientsIds={recipientsIds}
        companies={companies}
        getActiveCampaigns={getActiveCampaigns}
      />,
      "Select company"
    );
  };

  useEffect(() => {
    if (!isJobHeadhunt && !selectedEmployer.id) {
      getAvailableJobForCandidate(recipientsIds);
    }
    if (!jobId) {
      setJobId(currentUserAndEmployer[0].id);
    }
  }, []);

  useEffect(() => {
    setText(message);
  }, []);

  return (
    <div className="subscription-upgrade">
      <div
        tabIndex="0"
        role="button"
        className="fz-modal__close"
        onClick={() => {
          toggle();
          if (resetSelectedEmployer) {
            resetSelectedEmployer();
          }
        }}
        aria-label="Close"
        style={{ position: "absolute", right: "0", top: "-41px" }}
      >
        <MdClose />
      </div>
      <div
        className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0"
        style={{ color: theme.black300 }}
      >
        Select job and edit the invite message if needed. Invited talents will be highlighted in the candidates list.
      </div>
      {!isHideCompany && isLeadHr && (
        <div style={{ marginTop: "24px" }}>
          <Text fontWeight="600" style={{ display: "block", marginBottom: "10px" }}>
            Company
          </Text>
          <SelectFromActiveJob onClick={handleClickSelectCompany} isActive={campaignActive} role="button" tabIndex={-1}>
            {selectedEmployer?.name || currentUserAndEmployer[0].name} <MdArrowForward style={{ width: "18px" }} />
          </SelectFromActiveJob>
        </div>
      )}
      <div style={{ marginTop: "24px" }}>
        <Text fontWeight="600" style={{ display: "block", marginBottom: "10px" }}>
          Job
        </Text>
        <SelectFromActiveJob
          onClick={addToJob}
          isActive={selectedAvailableJobForCandidate.title}
          role="button"
          tabIndex={-1}
        >
          {isPrefilledJob ? (
            <>{prefilledJobDetail.title}</>
          ) : (
            <>
              {selectedAvailableJobForCandidate.title || "Select from active jobs"}
              <MdArrowForward style={{ width: "18px" }} />
            </>
          )}
        </SelectFromActiveJob>
      </div>
      <div style={{ margin: "24px 0" }}>
        <Text margin="0 0 8px 0" fontWeight={600}>
          Recipients
        </Text>

        <SelectedTalents talents={recipientsNames} />
      </div>
      <Textarea
        borderRadius="4px"
        style={{ outline: "none", width: "600px", height: "280px", borderColor: "#c5c5c5" }}
        value={text}
        onChange={e => setText(e.target.value)}
        label="Message"
        name="message"
        placeholder="Write a message..."
        inputColor="grey700"
      />
      <UnlockBlock>
        <UnlockItem isDisabled={!isLeadHr && isDontHaveEnoughUnlocks}>
          <div>
            {isLeadHr ? (
              <>
                <UnlockText>Allow talent to reply</UnlockText>
                <UnlockDescription>Talent will be able to reply to this message</UnlockDescription>
              </>
            ) : (
              <>
                <UnlockText>
                  Unlock talent and allow to reply
                  <UnlockCount>
                    <Lock
                      width="18px"
                      height="18px"
                      style={{ margin: "0 3px 3px 0", fill: isDontHaveEnoughUnlocks ? "#DADADA" : "#408BFC" }}
                    />
                    <strong>{recipientsIds.length}</strong> unlock{recipientsIds.length > 1 ? "s" : ""}
                  </UnlockCount>
                </UnlockText>
                <UnlockDescription>
                  Talent will be able to reply to this message and your team will have access to their full profile.
                </UnlockDescription>
              </>
            )}
          </div>
          <SwitchInput
            style={{ outline: "none", display: "inline-block", margin: "0" }}
            aria-label="Allow candidate to reply to this message"
            checked={replyMessage}
            onChange={handleReply}
            id="replyMessage"
            baseColor="grey800"
          />
        </UnlockItem>

        {!isLeadHr && isDontHaveEnoughUnlocks && (
          <Notification>
            <InfoCircle color="#408BFC" style={{ width: "17px", height: "17px", marginRight: "10px" }} />
            You don’t have enough unlocks
          </Notification>
        )}

        {!isLeadHr && (
          <UnlockItem>
            <div>
              <UnlockText>Notify me when the talent applies</UnlockText>
              <UnlockDescription>You will receive an email, once the talent submits an application </UnlockDescription>
            </div>
            <SwitchInput
              style={{ outline: "none", display: "inline-block", zIndex: "1" }}
              aria-label="Notify me through email when candidate submits application"
              checked={notifyEmail}
              onChange={handleNotify}
              id="notifyEmail"
              baseColor="grey800"
            />
          </UnlockItem>
        )}
      </UnlockBlock>
      <div className="fz-modal__actions">
        <Button
          onClick={() => {
            toggle();
            if (resetSelectedEmployer) {
              resetSelectedEmployer();
            }
          }}
          size="md"
          style={{ color: "#333333", background: "#EEEEEE", width: "130px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={loading || !campaignId}
          size="md"
          style={{ color: theme.black900, background: "#FFE040", width: "160px" }}
        >
          Send invite{recipientsIds.length > 1 ? "s" : ""}
          {loading && (
            <Spinner
              size="25px"
              primaryFill="secondary200"
              secondaryFill="transparent"
              style={{
                position: "absolute",
                top: "12%",
                right: "50%",
                transform: "translateX(50%)"
              }}
            />
          )}
        </Button>
      </div>
    </div>
  );
};

export default InviteToApply;
