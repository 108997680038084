import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px 8px 0 0;
  border: 1px solid #c5c5c5;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 120px 240px 0 rgba(0, 0, 0, 0.16), 0 32px 60px 0 rgba(0, 0, 0, 0.08), 0 16px 18px 0 rgba(0, 0, 0, 0.04);

  width: 640px;
  position: fixed;
  bottom: 0;
  right: 16vw;
  display: flex;
  flex-direction: column;
  z-index: 13;

  ${({ $isModal }) =>
    $isModal &&
    `
      box-shadow: none;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
      max-width: 800px;
      width: 100%; 
      

      @media screen and (min-width: 1240px) {
        max-width: 1000px;
      } 
    `}}
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 12;
  opacity: 0.5;
  background: #333;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 17px 20px;
`;

export const HeadingTitle = styled.h2`
  color: #333;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  margin: 0;
  min-height: 21px;
  width: calc(100% - 120px);
  word-break: break-all;
`;

export const HeadingActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const HeadingButton = styled.button`
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 24px;
  width: 24px;

  svg {
    width: 24px;
    height: 24px;
    fill: #676767;
  }
`;

export const Minimize = styled.span`
  display: block;
  width: 18px;
  height: 2px;
  background-color: #666;
  position: absolute;
  bottom: 2px;
  left: 50%;
  margin-left: -9px;
  cursor: pointer;

  ${({ $isBottomPosition }) =>
    $isBottomPosition &&
    `
      bottom: auto;
      top: 2px;
    `}
`;

export const Content = styled.div`
  padding: 16px 20px;
`;

export const Label = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
`;

export const Input = styled.input`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #c5c5c5;
  background: #fff;
  margin-bottom: 16px;
  display: block;
  width: 100%;
  text-align: left;
`;

export const RichTextTextareaContent = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #c5c5c5;
  background: #fff;

  .DraftEditor-root {
    min-height: ${({ $minHeight }) => $minHeight || "180px"};
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #eee;
  padding: 16px 20px;
  margin-top: auto;
`;

export const Discard = styled.button`
  border-radius: 8px;
  background: #eee;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  padding: 8px 16px;
`;

export const Close = styled.button`
  border-radius: 8px;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid #c5c5c5;
  margin-left: auto;
  margin-right: 12px;
`;

export const Save = styled.button`
  border-radius: 8px;
  background: #408bfc;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  padding: 8px 16px;

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
       opacity: 0.5;
       pointer-events: none;
  `}
`;

export const ChangesSaved = styled.span`
  color: #666;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  margin: 0 12px 0 auto;

  &:after {
    content: "";
    width: 6px;
    height: 6px;
    background: #00b55e;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    left: -10px;
  }
`;

export const ChangesSaving = styled.span`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  color: #666;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  margin: 0 12px 0 auto;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    animation: spin 1s linear infinite;
    width: 12px;
    height: 12px;
  }
`;
