import React from "react";

import {
  UserCvAttachments,
  UserCvCoverLetter,
  UserCvEducation,
  UserCvRecommendations,
  UserCvRequiredSkills,
  UserCvWorkExp
} from "../UserCv";

export const ProfileTab = ({ talent, isHeadHuntingPage }) => {
  const isShowCoverLetter = talent.cover_letter && !isHeadHuntingPage;

  return (
    <>
      {isShowCoverLetter && <UserCvCoverLetter letter={talent.cover_letter} />}
      <UserCvRequiredSkills
        name={talent.name ? talent.name.split(" ")[0] : ""}
        skills={talent.skills}
        core={talent.core_skills}
        required={talent.required_skills}
      />
      <UserCvWorkExp exp={talent.work_experience_items} totalWorkExperience={talent.total_work_experience} />

      {(talent.education_items || talent.language_items) && (
        <UserCvEducation education={talent.education_items} languages={talent.language_items} />
      )}
      <UserCvRecommendations recommendations={talent.recommendations} />
      <UserCvAttachments attachments={talent.attachments} />
    </>
  );
};
