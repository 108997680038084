import styled from "styled-components";

export const SelectsViewComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`;

export const SelectsViewFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: calc(100% - 130px);
  flex: none;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
    max-width: none;
  }

  @media screen and (min-width: 1008px) {
    flex-wrap: nowrap;

    & > div {
      width: calc(100% - 8px);
      max-width: 190px;
    }
  }

  @media screen and (min-width: 1240px) {
    flex-wrap: nowrap;

    & > div {
      width: calc(100% - 8px);
      max-width: 240px;

      &:first-child {
        max-width: 200px;
      }
    }
  }
`;

export const ClearAll = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#408BFC" : "#c5c5c5")};
  margin-left: 50px;
  background-color: transparent;
  width: calc(100% - 130px);
`;

export const CountryContainer = styled.div`
  height: 41.6px;
  width: 100%;

  .select__indicator-separator,
  .select__indicator {
    display: none;
  }
`;
