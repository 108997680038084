import React, { useContext } from "react";
import { observer, inject } from "mobx-react";

import Button from "components/Button";
import InitContext from "contexts/init";
import { useModal } from "utils/hooks";
import { b2cUrl, copyCodeToClipboard } from "utils/helpers";
import Checkbox from "../../../../components/Checkbox";
import InfoPane from "../../../../components/InfoPane";
import Pane from "../../../../components/Pane";
import UploadImageContent from "../UploadImageContent";
import ImagePreview from "../ImagePreview";
import BrandSlugContent from "../BrandSlugContent";

const PublicProfile = ({
  Settings: { employer, updateBanner, deleteBanner, updateBrandSlug, toggleCareerPortal, updateBrandParams }
}) => {
  const { base_domain } = useContext(InitContext);
  const { protocol, port } = window.location;
  const domain = `${base_domain}${port ? `:${port}` : ""}`;

  const openBrandSlugModal = useModal(
    <BrandSlugContent slug={employer?.brand?.slug} update={updateBrandSlug} />,
    <>
      Branded career page URL
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700 max-width-500px">
        Choose a prefix that is short and easy to remember. It can be edited later, but if you do so, you need to
        remember to update the link address in all the locations that refer to it.
      </div>
    </>
  );

  const openUploadBannerModal = useModal(
    <UploadImageContent updateImage={updateBanner} />,
    <>
      Upload a banner
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700">
        {}
        Image dimensions should be 1750  ×  500 pixels.
        <br />
        The maximum size per file is 5 MB.
      </div>
    </>
  );

  const previewBanner = useModal(
    <ImagePreview image={employer?.banner} onChange={openUploadBannerModal} />,
    "Banner preview"
  );

  const copyPath = b2cUrl(employer?.path);

  return (
    <>
      <Pane.Two>
        <h2 className="fz-heading-title">Public profile</h2>

        <InfoPane.Item
          className="mt-2 pb-2 mb-2"
          title="Public profile URL"
          data={employer?.slug ? b2cUrl(employer?.path) : ""}
          actions={
            <>
              {employer?.slug && (
                <Button.Simple className="ml-2" onClick={() => copyCodeToClipboard(copyPath)}>
                  Copy
                </Button.Simple>
              )}
            </>
          }
        />

        <InfoPane.Item
          className="mt-2 pb-2"
          title="Banner"
          subtitle="This image will be shown as a backdrop banner on your company profile page. The image should be <br /> a in a horizontal banner format, clearly more wide than tall."
          actions={
            employer?.banner ? (
              <>
                <Button.Simple onClick={previewBanner}>Preview</Button.Simple>
                <Button.Simple className="ml-2" onClick={openUploadBannerModal}>
                  Change
                </Button.Simple>
                <Button.Simple color="red" className="ml-2" onClick={deleteBanner}>
                  Remove
                </Button.Simple>
              </>
            ) : (
              <Button.Simple className="ml-2" onClick={openUploadBannerModal}>
                Upload
              </Button.Simple>
            )
          }
        />
      </Pane.Two>
      <Pane.Two>
        <h2 className="fz-heading-title">Branded career page</h2>
        <InfoPane.Item
          className="mt-2 pb-2"
          title="Use branded career page"
          subtitle="Any application started from branded career page is fully branded for your company.<br/>You can easily integrate Fuzu to your website by using the career portal URL.<br/>Please note that activating a branded career portal link might take a few minutes"
          actions={
            <Checkbox
              switchType
              checked={employer?.brand?.enabled}
              onCheck={() => toggleCareerPortal(true)}
              onUncheck={() => toggleCareerPortal(false)}
            />
          }
        />
        <InfoPane.Item
          className="mt-2 pb-2"
          title="Enable CV scraper for jobseeker's CV"
          subtitle="Enable CV scraper for jobseeker's CV"
          actions={
            <Checkbox
              switchType
              checked={employer?.brand?.enable_cv_scraper}
              onCheck={() => updateBrandParams({ enable_cv_scraper: true })}
              onUncheck={() => updateBrandParams({ enable_cv_scraper: false })}
            />
          }
        />
        <InfoPane.Item
          className="mt-2 pb-2 mb-2"
          title="Branded career page URL"
          subtitle="You can't change career page URL after enabling it."
          data={employer?.brand?.slug ? `${protocol}//${employer?.brand?.slug}.${domain}` : ""}
          actions={
            <>
              {employer?.brand?.slug && (
                <Button.Simple
                  className="ml-2"
                  onClick={() => copyCodeToClipboard(`${protocol}//${employer?.brand?.slug}.${domain}`)}
                >
                  Copy
                </Button.Simple>
              )}
              <Button.Simple className="ml-2" onClick={openBrandSlugModal}>
                {employer?.brand?.slug ? "Edit" : "Add"}
              </Button.Simple>
            </>
          }
        />
      </Pane.Two>
    </>
  );
};

export default inject("Settings")(observer(PublicProfile));
