import React, { useContext } from "react";
import { MdEdit } from "react-icons/md";
import { inject, observer } from "mobx-react";

import AppContext from "contexts/init";
import { useModal } from "utils/hooks";
import { formatDate } from "b2b/utils/helpers";
import InterviewPreview from "../InterviewPreview";
import {
  InterviewItemActions,
  InterviewItemButton,
  InterviewItemContainer,
  InterviewItemContent,
  InterviewItemDate,
  InterviewItemFooter,
  InterviewItemHeading,
  InterviewItemInfoLabel,
  InterviewItemInfoName,
  InterviewItemInfoStatus,
  InterviewItemName
} from "./styled";

const InterviewItems = ({
  CampaignsStore: { interviewNotes, deleteInterviewNote, openEditInterviewModal },
  candidateId,
  handleCloseInterviewModal,
  isShowPreviewModalWhenCancel,
  isSharedCandidates
}) => {
  return (
    <InterviewItemContainer>
      {interviewNotes.map((interview, index) => {
        return (
          <InterviewItem
            key={interview.created_at}
            openEditInterviewModal={openEditInterviewModal}
            interview={interview}
            deleteInterviewNote={deleteInterviewNote}
            candidateId={candidateId}
            interviewNotes={interviewNotes}
            index={index}
            handleCloseInterviewModal={handleCloseInterviewModal}
            isShowPreviewModalWhenCancel={isShowPreviewModalWhenCancel}
            isSharedCandidates={isSharedCandidates}
          />
        );
      })}
    </InterviewItemContainer>
  );
};

const InterviewItem = ({
  interview,
  deleteInterviewNote,
  candidateId,
  openEditInterviewModal,
  interviewNotes,
  index,
  handleCloseInterviewModal,
  isShowPreviewModalWhenCancel,
  isSharedCandidates
}) => {
  const { current_user: currentUser } = useContext(AppContext);

  const {
    title,
    updated_at: date,
    owner: { avatar, name, id: ownerId },
    position_phase: status,
    id: nodeId
  } = interview;

  const openAddContactDetailsModal = useModal(
    <InterviewPreview
      openEditInterviewModal={openEditInterviewModal}
      deleteInterviewNote={deleteInterviewNote}
      candidateId={candidateId}
      interviewNotes={interviewNotes}
      interviewIndex={index}
      isSharedCandidates={isSharedCandidates}
    />,
    ""
  );

  const handleView = () => {
    openAddContactDetailsModal({ isOverflowVisible: true });
  };

  const handleOpenEditInterviewModal = event => {
    event.stopPropagation();
    openEditInterviewModal(nodeId, isShowPreviewModalWhenCancel, candidateId);
    if (handleCloseInterviewModal) {
      handleCloseInterviewModal();
    }
  };

  return (
    <InterviewItemContent role="button" tabIndex={-1} onClick={handleView}>
      <InterviewItemHeading>
        <div>
          <InterviewItemDate>{formatDate(new Date(date))}</InterviewItemDate>
          <InterviewItemName>{title}</InterviewItemName>
        </div>
        <InterviewItemActions>
          {currentUser?.id === ownerId && !isSharedCandidates && (
            <InterviewItemButton onClick={handleOpenEditInterviewModal}>
              <MdEdit />
              Edit
            </InterviewItemButton>
          )}

          <InterviewItemButton onClick={handleView}>View</InterviewItemButton>
        </InterviewItemActions>
      </InterviewItemHeading>
      <InterviewItemFooter>
        <div>
          <InterviewItemInfoLabel>Written by</InterviewItemInfoLabel>
          <InterviewItemInfoName>
            <img src={avatar} alt="" /> {name}
          </InterviewItemInfoName>
        </div>
        <div>
          <InterviewItemInfoLabel>Created at stage</InterviewItemInfoLabel>
          <InterviewItemInfoStatus>{status}</InterviewItemInfoStatus>
        </div>
      </InterviewItemFooter>
    </InterviewItemContent>
  );
};

export default inject("CampaignsStore")(observer(InterviewItems));
