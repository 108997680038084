import React from "react";
import { Switch, Route } from "react-router-dom";

import Subscription from "../../../../components/Subscription";
import FindTalent from "../FindTalent";
import SavedTalent from "../SavedTalent";
import OpenApplications from "../OpenApplications";
import SavedSearch from "../SavedSearch";

const TabRouter = () => {
  return (
    <Switch>
      <Route exact path="/headhunting/find" component={FindTalent} />
      <Route
        exact
        path={["/headhunting/saved", "/headhunting/saved/folders", "/headhunting/saved/folders/:folder"]}
        component={SavedTalent}
      />
      <Subscription.Route
        exact
        path="/headhunting/open"
        to="/headhunting"
        component={OpenApplications}
        permission="open_applications"
      />
      <Route exact path="/headhunting/searches" component={SavedSearch} />
    </Switch>
  );
};

export default TabRouter;
