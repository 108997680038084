import styled from "styled-components";

export const SelectedListInitials = styled.span`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eee;
  margin-right: 16px;
`;
