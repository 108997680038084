import React, { useState, useReducer } from "react";

import Button from "components/Button";
import Checkbox from "b2b/components/Checkbox";

const reducer = (prev, id) => {
  return prev.includes(id) ? prev.filter(item => item != id) : prev.concat(id);
};

const CompanyIndustryContent = ({ industries, updateIndustries, close }) => {
  const [error, setErrors] = useState();
  const [processing, setProcessing] = useState(false);
  const [selected, dispatch] = useReducer(
    reducer,
    industries.filter(item => item.selected).map(item => item.id)
  );

  const handleUpdate = () => {
    setProcessing(true);
    updateIndustries(selected).then(errors => {
      setProcessing(false);
      if (errors) {
        setErrors(errors);
      } else close();
    });
  };

  return (
    <>
      {industries?.map(({ id, name }) => (
        <div key={id} className="mb-1">
          <Checkbox checked={selected.includes(id)} onCheck={() => dispatch(id)}>
            <div className="fz-text fz-text-color__black fz-lh_1-4">{name}</div>
          </Checkbox>
        </div>
      ))}
      {error && <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{error}</div>}
      <div className="fz-modal__actions mt-0">
        <Button disabled={processing} color="gray" onClick={close}>
          Cancel
        </Button>
        <Button disabled={processing || selected.length < 1} color="blue" onClick={handleUpdate}>
          Save
        </Button>
      </div>
    </>
  );
};

export default CompanyIndustryContent;
