import React, { useMemo, useEffect } from "react";
import { MdArrowBack, MdForward, MdClose, MdFileDownload } from "react-icons/md";
import { ChevronsRight } from "@styled-icons/boxicons-regular";
import { Pencil } from "@styled-icons/boxicons-solid";

import ModalConsumer from "contexts/modal";
import Button from "components/Button";
import Subscription from "../../../../components/Subscription";
import ListContent from "../../../../components/ListContent";
import ContactOptionsModal from "../../../../components/ContactOptionsModal";
import AddTalentToJob from "../../../../components/AddTalentToJob";
import { Tab, TabItem, Heading, BackToTalentPool, TalentPoolName, Counter, EditFolder, HeadingTalent } from "./styled";
import Dropdown from "../../../../../components/Selects";
import { useModal } from "../../../../../utils/hooks";
import EditFolderModal from "../EditFolder";
import ExportProfileModal from "../ExportProfile";
import SearchTalent from "../SearchTalent";

const SavedTalentList = ({
  name,
  folder,
  reset,
  removeFromFolder,
  handleInviteToApply,
  folders,
  applyFilter,
  appliedFilters,
  moveToStage,
  getFolderStages,
  folderStages,
  renameFolder,
  exportBatchCvProfile,
  isLeadHr
}) => {
  const currentFolder = useMemo(() => folders.find(item => String(item.id) === folder), [folders, folder]);
  const openEditModal = useModal(<EditFolderModal renameFolder={renameFolder} folderId={currentFolder?.id} />, "");

  const handleOpenExportProfileModal = (toggle, selectedTalents) => {
    return toggle(
      <ExportProfileModal
        toggle={toggle}
        exportProfile={isIncognito => exportBatchCvProfile(selectedTalents, isIncognito)}
        selectedTalents={selectedTalents}
      />,
      ""
    );
  };

  const addToJob = (toggle, campaign, selectedTalents) => {
    return toggle(
      <AddTalentToJob
        selectedTalents={selectedTalents}
        toggle={toggle}
        addToJob={(campaignActive, selectedTalentsActive) => {
          addToJob(toggle, campaignActive, selectedTalentsActive);
        }}
        campaignActive={campaign}
      />,
      `Add talent${selectedTalents.length ? "s" : ""} to job`
    );
  };

  const stageRelationsOptions = currentFolder?.stage_relations?.length
    ? currentFolder?.stage_relations
        .sort((a, b) => a - b)
        .map(item => {
          return {
            value: item.id,
            label: item.stage.name
          };
        })
    : [];

  const stageRelationsList = currentFolder?.stage_relations?.length
    ? currentFolder.stage_relations
        .sort((a, b) => a - b)
        .map(item => {
          return (
            <TabItem
              key={`${item.id}+${item.position}`}
              onClick={() => applyFilter({ headhunting_folder_stage_relation_ids: [item.id] })}
              isActive={String(appliedFilters.headhunting_folder_stage_relation_ids) === String(item.id)}
            >
              {item.stage.name}
              {folderStages[item.id] && <span>{folderStages[item.id].count}</span>}
            </TabItem>
          );
        })
    : null;

  useEffect(() => {
    getFolderStages(folder, true);
  }, []);

  return (
    <>
      <Heading>
        <div>
          <BackToTalentPool to="/headhunting/saved" onClick={reset}>
            <MdArrowBack />
            Back to talent pool
          </BackToTalentPool>
          <TalentPoolName>{name}</TalentPoolName>
          {folderStages.all && <Counter>{folderStages.all.total_count} talents</Counter>}
        </div>
        <EditFolder onClick={() => openEditModal({ defaultFolderName: name })}>
          <Pencil />
          Edit folder
        </EditFolder>
      </Heading>
      <ListContent
        Heading={
          <>
            <HeadingTalent>
              {currentFolder?.stage_relations?.length ? (
                <Tab>
                  <>{stageRelationsList}</>
                  <TabItem
                    key="all"
                    onClick={() => applyFilter({ headhunting_folder_stage_relation_ids: [] })}
                    isActive={!appliedFilters.headhunting_folder_stage_relation_ids?.length}
                  >
                    All
                    {folderStages.all && <span>{folderStages.all.total_count}</span>}
                  </TabItem>
                </Tab>
              ) : null}

              <SearchTalent />
            </HeadingTalent>
          </>
        }
        BatchContent={({ selectedTalents, setRemoved }) => (
          <>
            <Dropdown
              selectOptions={stageRelationsOptions}
              onSelect={stageId => moveToStage(stageId, selectedTalents, folder)}
            >
              <Button.Simple className="mr-3" icon={<ChevronsRight width="18px" />} role="button" tabIndex="0">
                Move
              </Button.Simple>
            </Dropdown>
            <ModalConsumer>
              {({ toggle }) => (
                <>
                  <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline" element="Talent pipeline">
                    <Button.Simple
                      className="mr-3"
                      icon={<MdForward />}
                      onClick={() =>
                        toggle(
                          <ContactOptionsModal
                            selectedTalents={selectedTalents}
                            toggle={toggle}
                            handleInviteToApply={() => handleInviteToApply(toggle, {}, selectedTalents)}
                            addToJob={() => addToJob(toggle, "", selectedTalents)}
                          />,
                          "Do you also want to invite the candidates to apply?"
                        )
                      }
                    >
                      Add to job
                    </Button.Simple>
                  </Subscription.Upgrade>
                  {isLeadHr && (
                    <Button.Simple
                      className="mr-3"
                      icon={<MdFileDownload width="18px" />}
                      role="button"
                      tabIndex="0"
                      onClick={() => handleOpenExportProfileModal(toggle, selectedTalents)}
                    >
                      {`Export profile${selectedTalents.length > 1 ? "s" : ""}`}
                    </Button.Simple>
                  )}
                </>
              )}
            </ModalConsumer>
            <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline" element="Talent pipeline">
              <Button.Simple
                icon={<MdClose />}
                onClick={() => {
                  removeFromFolder(folder, selectedTalents).then(() => {
                    setRemoved(prev => prev.concat(selectedTalents));
                  });
                }}
              >
                Remove
              </Button.Simple>
            </Subscription.Upgrade>
          </>
        )}
        allowSaving={false}
        isShowMove
        stageRelationsOptions={stageRelationsOptions}
        moveToStage={moveToStage}
        activeFolderStageId={appliedFilters.headhunting_folder_stage_relation_ids?.[0]}
      />
    </>
  );
};

export default SavedTalentList;
