import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "mobx-react";

import MyJobsStore from "../../stores/MyJobsStore";
import CampaignsStore from "../../stores/CampaignsStore";
import SearchCandidates from "../../pages/SearchCandidates";
import OnboardingStore from "../../stores/Onboarding";
import ApplicantCvScreen from "../../pages/TalentCvScreen";
import store from "../../stores/Headhunt";

const SharedCandidates = ({ initialState }) => {
  if (!initialState.feature_toggles.employer_candidate_search) {
    return <Redirect to="/campaigns" />;
  }
  return (
    <Provider myJobsStore={MyJobsStore} campaignsStore={CampaignsStore} onboardingStore={OnboardingStore} store={store}>
      <Switch>
        <Route exact path="/candidates" component={SearchCandidates} />
        <Route exact path="/candidates/:id" render={props => <ApplicantCvScreen {...props} isSearchCandidates />} />
      </Switch>
    </Provider>
  );
};

export default SharedCandidates;
