import styled from "styled-components";

export const ExternalCandidateLabel = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #f9eeff;
  color: #3c0b53;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 32px;
  top: 12px;
  width: calc(100% - 44px);

  svg {
    width: 16px;
    height: 16px;
    fill: #3c0b53;
  }
`;

export const AvailableLabel = styled.div`
  color: #3c0b53;
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: -10px;
    top: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #8e32b9;
  }
`;

export const TooltipText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #f3f3f3;
  width: 240px;
  padding: 3px;
`;

export const Interview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #f5f5f5;
  padding: 8px 12px;
  margin-top: 22px;
`;

export const InterviewCount = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    fill: #666666;
  }
`;

export const InterviewViewButton = styled.button`
  padding: 0;
  color: #408bfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
`;
