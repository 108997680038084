import React, { useState, useEffect } from "react";

const DetailsTab = ({ getJobDetails }) => {
  const [Inner, setInner] = useState(<></>);

  useEffect(() => {
    const abortController = new AbortController();

    getJobDetails({ signal: abortController.signal })
      .then(page => {
        setInner(page);
      })
      .catch(err => console.error(err));

    return () => {
      abortController.abort();
    };
  }, []);

  return Inner;
};

export default DetailsTab;
