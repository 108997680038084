import styled from "styled-components";

export const Styled = styled.div`
  background: #f3f3f3;
  border-radius: 4px;
  padding: 8px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ resetAll }) => (resetAll ? 0 : 10)}px;
  margin-bottom: 12px;

  > .count {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  > .reset {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #408bfc;
  }
`;
