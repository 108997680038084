import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";

import { theme } from "b2c/contexts/theme";
import "./toats.css";
import { useQueryParams } from "utils/hooks";
import { validateEmail } from "utils/validation";
import InitContext, { getCompanyName } from "contexts/init";
import { Input, Text } from "../../../b2c/components/Core";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";
import { Form, LoginButton, SSOAuthenticationUrl } from "./styled";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import AppContext from "../../../contexts/init";

const defaultValidation = {
  email: { error: false },
  password: { error: false }
};

const validatePasswordLength = (input, limit) => {
  if (input) {
    if (input.length < limit) {
      return { test: false, error: `Password should be at least ${limit} characters long` };
    }
    return { test: true };
  }

  return { test: false, error: "Please enter your password" };
};

const validateForm = ({ email, password }) => {
  const validation = {
    email: validateEmail(email),
    password: validatePasswordLength(password, 8)
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};

const Login = ({ User: { loginUser, state }, toastMessage }) => {
  const { error, processing } = state;
  const { invitation } = useContext(InitContext);
  const { token } = useQueryParams();
  const history = useHistory();
  const [validationState, setValidation] = useState(defaultValidation);
  const [formState, setFormState] = useState({
    email: "",
    password: ""
  });

  const { global_brand } = useContext(AppContext);

  const customAuthenticationUrl = global_brand.custom_authentication_b2b_url;

  const validation_errors = {};

  const handleSubmission = e => {
    e.preventDefault();
    setValidation(defaultValidation);

    const { valid, validation } = validateForm(formState);
    setValidation(validation);
    if (valid) {
      const body = {
        user: formState
      };
      loginUser(body, valid).then(() => {
        if (invitation) {
          history.replace(`/accept-invitation?token=${token}`);
        } else {
          history.replace("/employers/select");
        }
      });
    }
  };

  return (
    <ScreenStateMessage
      state={{
        type: "error",
        message: toastMessage?.error,
        loading: false,
        error: Boolean(toastMessage?.error),
        hidden: Boolean(toastMessage?.error)
      }}
      loadingMessage={toastMessage?.error}
    >
      <CreateLoginFlowWrapper title={`Login to your ${getCompanyName()} employer account`}>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <Form onSubmit={handleSubmission} noValidate>
          {!customAuthenticationUrl ? (
            <>
              <div>
                <Input
                  width="400px"
                  key="email"
                  type="email"
                  id="user_email"
                  label={
                    <Text fontSize={14} fontWeight={600} lineHeight="21px">
                      Email address
                    </Text>
                  }
                  autoComplete="email"
                  fieldRequired={false}
                  value={formState?.email}
                  onChange={({ target: { value } }) =>
                    setFormState(prev => ({
                      ...prev,
                      email: value
                    }))
                  }
                  errorMessage={validationState?.email?.error || validation_errors?.email}
                />
              </div>

              <Input
                width="400px"
                key="password"
                id="user_password"
                label={
                  <Text fontSize={14} fontWeight={600} lineHeight="21px">
                    Password
                  </Text>
                }
                type="password"
                fieldRequired={false}
                value={formState?.password}
                errorMessage={validationState?.password?.error || validation_errors?.password}
                onChange={({ target: { value } }) => {
                  setFormState(prev => ({
                    ...prev,
                    password: value
                  }));
                }}
              />

              {error && (
                <Text color="accent300" margin="4px 0 0">
                  {error}
                </Text>
              )}
              <LoginButton
                processing={processing}
                type="submit"
                fontSize="14px"
                margin="15px 0"
                width="400px"
                height="48px"
                color={theme.primaryButtonLabelColor}
                background={theme.primaryButtonBackground}
              >
                Login
              </LoginButton>

              <Text fontSize="16px" fontWeight="400">
                Forgot your password?
                <Link
                  to="/forgot-password"
                  style={{
                    color: "#0e50b5",
                    fontWeight: "600",
                    fontSize: "16px",
                    display: "block"
                  }}
                >
                  Reset Password
                </Link>
              </Text>
            </>
          ) : (
            <SSOAuthenticationUrl
              href={customAuthenticationUrl}
              $color={theme.primaryButtonLabelColor}
              $background={theme.primaryButtonBackground}
            >
              Login
            </SSOAuthenticationUrl>
          )}
        </Form>
      </CreateLoginFlowWrapper>
    </ScreenStateMessage>
  );
};

export default inject("User")(observer(Login));
