import React, { useLayoutEffect, useContext } from "react";
import { observer, inject } from "mobx-react";
import { Switch } from "react-router-dom";

import InitContext from "contexts/init";
import { withRoute } from "utils/hocs/withRoute";
import Layout from "../../components/Layout";
import TabNavigation from "../../components/TabNavigation";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import Loading from "../../components/Loading";
import AccessibilityTab from "./components/AccessibilityTab";
import ApplicationsTab from "./components/ApplicationsTab";
import ApiTab from "./components/ApiTab";
import OrganizationsTab from "./components/OrganizationsTab";
import UsersTab from "./components/UsersTab";
import ProfileTab from "./components/ProfileTab";

const AccountSettingsScreen = ({
  Settings: { state, employer, initializeSettings, currentUser },
  MyJobsStore: { getCommonCountries },
  location: { pathname },
  match: { url }
}) => {
  const {
    current_employer,
    current_user,
    subscription: { permissions }
  } = useContext(InitContext);

  const isAdmin = currentUser?.roles?.includes("employer_admin");

  useLayoutEffect(() => {
    initializeSettings(current_employer, current_user);
    getCommonCountries();
  }, []);

  const tabs = [
    isAdmin && {
      name: "Company Profile",
      to: `${url}`,
      active: pathname === url
    },
    isAdmin && {
      name: "Users",
      to: `${url}/users`,
      active: pathname === `${url}/users`
    },
    isAdmin &&
      permissions.advanced_admin && {
        name: "Application settings",
        to: `${url}/application`,
        active: pathname === `${url}/application`
      },
    isAdmin &&
      permissions.subsidiary_logic && {
        name: "Organizations",
        to: `${url}/organizations`,
        active: pathname === `${url}/organizations`
      },
    isAdmin &&
      permissions.api_integration && {
        name: "API",
        to: `${url}/api`,
        active: pathname === `${url}/api`
      },
    isAdmin && {
      name: "Accessibility",
      to: `${url}/accessibility`,
      active: pathname === `${url}/accessibility`
    }
  ].filter(Boolean);

  return (
    <ScreenStateMessage state={state}>
      <Layout.PageHeading className="fz-page-heading_tall mb-4">
        <Layout.Container>
          <h1 className="fz-title mt-0 mb-5">Company settings</h1>
          <TabNavigation tabItems={tabs} />
        </Layout.Container>
      </Layout.PageHeading>

      <Layout.Container>
        <Loading loading={!employer}>
          <Switch>
            <ProfileTab exact path={`${url}`} />
            <UsersTab exact path={`${url}/users`} />
            <ApplicationsTab exect path={`${url}/application`} />
            <OrganizationsTab exact path={`${url}/organizations`} />
            <ApiTab exact path={`${url}/api`} />
            <AccessibilityTab exact path={`${url}/accessibility`} />
          </Switch>
        </Loading>
      </Layout.Container>
    </ScreenStateMessage>
  );
};

export default withRoute(inject("Settings", "MyJobsStore")(observer(AccountSettingsScreen)));
