import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useQueryParams } from "utils/hooks";
import { Input, Text } from "../../../b2c/components/Core";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";
import PasswordFooter from "../../components/PasswordFooter";
import { Form, StyledButton } from "./styled";

const defaultValidation = {
  password: { error: false }
};

const validatePasswordLength = (input, minLength = 8) => {
  if (input) {
    if (input.length < minLength) {
      return { test: false, error: `Min length is ${minLength} characters` };
    }
    return { test: true };
  }

  return { test: false, error: "Field is required" };
};
const validateForm = ({ password }) => {
  const validation = {
    password: validatePasswordLength(password)
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};

const NewPassword = ({ User: { updatePassword, state } }) => {
  const { error, processing } = state;
  const history = useHistory();
  const { reset_password_token } = useQueryParams();
  const [validationState, setValidation] = useState(defaultValidation);
  const [formState, setFormState] = useState({
    password: ""
  });

  const validation_errors = {};

  const handleSubmission = e => {
    e.preventDefault();
    setValidation(defaultValidation);

    const { valid, validation } = validateForm(formState);
    setValidation(validation);
    if (valid) {
      const body = {
        user: {
          password: formState.password,
          password_confirmation: formState.password,
          reset_password_token
        }
      };
      updatePassword(body).then(() => {
        history.replace("/login");
      });
    }
  };

  return (
    <CreateLoginFlowWrapper
      title="Create new password"
      description="Enter a new password for your Fuzu employer account."
    >
      <Helmet>
        <title>Create new password</title>
      </Helmet>
      <Form onSubmit={handleSubmission} noValidate>
        <Input
          key="password"
          placeholder="Minimum 8 characters"
          label={
            <Text fontSize={14} fontWeight={600} lineHeight="21px">
              Create a password
            </Text>
          }
          type="password"
          fieldRequired={false}
          value={formState?.password}
          errorMessage={validationState?.password?.error || validation_errors?.password}
          onChange={({ target: { value } }) => {
            if (value.length <= 25) {
              setFormState(prev => ({ ...prev, password: value }));
            }
          }}
        />

        {error && (
          <Text color="accent300" margin="4px 0 0">
            {error}
          </Text>
        )}

        <StyledButton processing={processing} type="submit" fontSize="14px" margin="15px 0" width="100%" height="48px">
          Reset password
        </StyledButton>
      </Form>
      <PasswordFooter />
    </CreateLoginFlowWrapper>
  );
};

export default inject("User")(observer(NewPassword));
