import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import cn from "classnames";

import Error from "./PageError";
import EmptyState from "./EmptyState";
import CollapseButton from "./CollapseButton";
import Expander from "./Expander";
import UserPane from "./UserPane";

const Popper = styled.div`
  ${({ fixed, top = "50%", extraCss = "" }) => `
      position: ${fixed ? "fixed" : "relative"};
      display: flex;
      width: inherit;
      height: fit-content;
      justify-content: center;
      z-index: 100;
      top: ${top};

      ${extraCss}
  `}
`;

const Centered = styled.div`
  color: #444444;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;

  height: 156px;
  width: 100%;
  display: flex;
  justify-content: center;

  span {
    margin: auto;
  }
`;

const PageHeading = ({ children, className }) => {
  return <section className={`fz-page-heading ${className}`}>{children}</section>;
};

const Container = ({ children, type, tag = "section", ...props }) => {
  const wrapperClassNames = cn(
    { "fz-page-container": type === "default" },
    { "fz-page-wide-container": type === "wide" }
  );

  const Tag = tag;

  return (
    <Tag className={wrapperClassNames} {...props}>
      {children}
    </Tag>
  );
};

Container.propTypes = {
  type: PropTypes.oneOf(["default", "wide"])
};
Container.defaultProps = {
  type: "default"
};

const ContainerWithSidebar = ({ mainContent, sideContent }) => {
  return (
    <section className="fz-container-with-sidebar">
      <div className="fz-container-with-sidebar__content">{mainContent}</div>
      <div className="fz-container-with-sidebar__aside">{sideContent}</div>
    </section>
  );
};
ContainerWithSidebar.propTypes = {
  mainContent: PropTypes.element,
  sideContent: PropTypes.element
};
ContainerWithSidebar.defaultProps = {
  mainContent: {},
  sideContent: {}
};

export default {
  Popper,
  Centered,
  Error,
  PageHeading,
  Container,
  ContainerWithSidebar,
  EmptyState,
  CollapseButton,
  Expander,
  UserPane
};
