import styled from "styled-components";
import { theme } from "../../../../../b2c/contexts/theme";

export const Modal = styled.div`
  max-width: 600px;
  min-height: 100px;
  margin-top: -55px;

  @media screen and (min-width: 768px) {
    min-width: 672px;
  }

  @media screen and (min-width: 840px) {
    min-width: 800px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding-top: 24px;
  border-top: 1px solid #eee;
`;

export const Submit = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 10px;
  width: 172px;
  flex: none;
  border-radius: 8px;
  background: ${theme.primaryButtonBackground};
  color: ${theme.primaryButtonLabelColor};

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  padding: 0;
  margin: 0 0 24px 0;
`;

export const Progress = styled.div`
  border-radius: 8px;
  background: #eee;
  width: 100%;
  height: 6px;
  margin-bottom: 24px;

  span {
    display: block;
    height: 100%;
    width: ${({ $width }) => $width};
    background: ${({ $background }) => $background};
  }
`;

export const StepHeading = styled.h2`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 24px;
`;

export const StepCount = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const Content = styled.div`
  margin-bottom: 24px;
`;

export const SubTitle = styled.h4`
  color: #333;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 4px 0;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 16px;
`;

export const Banner = styled.div`
  border-radius: 6px;
  background: #f3f8ff;
  padding: 12px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  span {
    font-weight: 500;
    color: #333;
  }
`;

export const GuestLinkTitle = styled.span`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  background: #f3f3f3;
  display: block;
  padding: 14px 12px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  justify-content: flex-start;
  margin: ${({ $margin }) => $margin};
`;

export const AddPeople = styled.button`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 16px;
  margin-left: auto;
  align-self: center;
  white-space: nowrap;
`;

export const HideAccessDropdown = styled.button`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 16px;
  align-self: center;
  white-space: nowrap;
  height: 48px;
`;

export const Add = styled.button`
  border-radius: 8px;
  background: #eee;
  width: 65px;
  flex: none;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 14px 0;
  text-align: center;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SelectedListItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  gap: 16px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SelectedListInitials = styled.span`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eee;
`;

export const SelectedListName = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 4px 0;
  color: #333;
`;

export const SelectedListEmail = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const SelectedListRemove = styled.span`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: auto;
  align-self: center;

  svg {
    width: 24px;
    height: 24px;
    fill: #666666;
  }
`;

export const DeleteLink = styled.button`
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #eb3737;
  margin-right: auto;

  svg {
    width: 16px;
    height: 16px;
    fill: #eb3737;
  }
`;

export const Back = styled.button`
  color: #666;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  margin-right: auto;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Info = styled.div`
  padding: 12px;
  gap: 10px;
  color: #444;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  background: #f3f8ff;
  margin-bottom: 16px;

  span {
    font-weight: 500;
  }
`;

export const CheckboxContent = styled.div`
  margin-bottom: 20px;
`;

export const CheckboxTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 2px 0;
`;

export const CheckboxText = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
`;

export const AccessEveryone = styled.div`
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  background: rgba(245, 245, 245, 0.6);
  padding: 12px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  svg {
    width: 24px;
    height: 24px;
    fill: #666666;
  }
`;

export const CreatedBy = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 24px 0;
`;

export const CreatedByTitle = styled.h1`
  color: #333;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 8px 0;
`;

export const CreatedByContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  gap: 16px;
`;

export const CreatedByViews = styled.p`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: block;
  margin: 0;
  width: 120px;
  text-align: left;

  span {
    display: block;
    color: #333;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    margin-top: 8px;
  }
`;

export const CreatedByAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #eee;
`;

export const CreatedByName = styled.h2`
  color: #333;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0 0 2px 0;
`;

export const CreatedByDate = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  text-align: left;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #eee;
  margin-bottom: 24px;
`;

export const TabsItem = styled.div`
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 16px;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive
      ? `
          border-bottom: 2px solid #2496F5;
          color: #333;
        `
      : ""}
`;

export const ButtonEdit = styled.button`
  width: 32px;
  height: 32px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  margin: auto 0 auto auto;

  svg {
    fill: #666666;
    width: 16px;
    height: 16px;
  }
`;

export const EditContent = styled.div`
  border: 1px solid #c5c5c5;
  box-shadow: 0 5px 15px 3px rgba(33, 33, 33, 0.05);
  border-radius: 8px;
  position: absolute;
  right: 16px;
  top: -98px;
  background-color: #fff;
  z-index: 1;
  padding: 8px 0;
`;

export const EditShareButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  padding: 8px 12px;
  width: 100%;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: #666666;
  }
`;

export const RemoveShareButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #eb3737;
  padding: 8px 12px;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: #eb3737;
  }
`;

export const PitchContainer = styled.div`
  margin: 24px 0;
`;

export const ReachTextContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  margin-bottom: 8px;

  .rdw-link-wrapper {
    display: none;
  }

  .DraftEditor-root {
    max-height: 162px;
    min-height: 86px;
  }
`;

export const CharacterCount = styled.span`
  color: #a4a4a4;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  justify-content: flex-end;
`;
