import React from "react";

const JobItemPlaceholder = ({ className }) => (
  <div className={`fz-panel placeholder ${className}`}>
    <div className="fz-panel__header">
      <div className="fz-panel__statuses">
        <div className="fz-status" />
        <div className="fz-status" style={{ width: "102px" }} />
      </div>
    </div>
    <div className="fz-panel__container">
      <div className="fz-panel__content">
        <div className="fz-panel__name" />
        <div className="fz-panel__info" />
      </div>
      <div className="fz-panel__additional">
        <div className="fz-infobox" />
        <div className="fz-infobox" style={{ width: "82px" }} />
        <div className="fz-infobox" style={{ width: "82px" }} />
      </div>
    </div>
  </div>
);

export default JobItemPlaceholder;
