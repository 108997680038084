import React, { useState, useEffect } from "react";
import { MdShortText, MdRadioButtonChecked, MdCheckBox, MdFormatAlignLeft } from "react-icons/md";

import { questionsType } from "../../../../../constants/questions";
import {
  Modal,
  Title,
  Description,
  Actions,
  Add,
  QuestionContainer,
  QuestionContent,
  Tile,
  TileAnswers,
  TileAnswersItem,
  TileTitle,
  TileType,
  DescriptionLabel,
  DescriptionAnswer,
  SelectContainer,
  SelectTitle
} from "./styled";
import Checkbox from "../../../../components/Checkbox";
import HelpFul from "../HelpFul";
import { getCompanyName } from "../../../../../contexts/init";
import { SimpleSelect } from "../../../../../components/Selects";

const AddQuestion = ({ close, questions, postQuestions, positionQuestions, availableQuestionLanguages }) => {
  const anyOption = { label: "Any", value: "Any" };
  const [activeQuestions, setActiveQuestions] = useState([]);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [language, setLanguage] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);

  const handleCheckActiveOptions = item => {
    const selectedQuestions = [...activeQuestions, item];

    setActiveQuestions(selectedQuestions);
  };
  const handleUncheckActiveOptions = item => {
    const selectedQuestions = activeQuestions.filter(currentItem => currentItem !== item);
    setActiveQuestions(selectedQuestions);
  };

  const handlePost = () => {
    const selectedQuestion = questions
      .filter(item => activeQuestions.includes(item.id))
      .map((item, index) => {
        const options = item.options ? { options: item.options } : {};

        return {
          question_id: item.id,
          item_order: index,
          mandatory: true,
          ...options
        };
      });

    setIsDisableSubmit(true);
    postQuestions({ questions: selectedQuestion }).then(() => {
      setIsDisableSubmit(false);
      close();
    });
  };

  const handleSelectLanguage = selectLanguage => {
    const isSelectLanguageIncludeAny = Boolean(selectLanguage.find(item => item.label === anyOption.label)?.value);
    const isLanguageIncludeAny = Boolean(language.find(item => item.label === anyOption.label)?.value);
    const isSelectLanguageLanguageMoreThanOne = selectLanguage.length > 1;
    let updateLanguageList = [];
    if ((!isLanguageIncludeAny && isSelectLanguageIncludeAny) || !selectLanguage.length) {
      updateLanguageList = [anyOption];
    } else if (isLanguageIncludeAny && isSelectLanguageIncludeAny && isSelectLanguageLanguageMoreThanOne) {
      updateLanguageList = selectLanguage.filter(item => item.label !== anyOption.label);
    } else {
      updateLanguageList = selectLanguage;
    }

    if (updateLanguageList.find(item => item.label === anyOption.label)?.value) {
      setQuestionsList(questions);
    } else {
      const mapUpdateLanguageList = updateLanguageList.map(item => item.value);
      setQuestionsList(questions.filter(item => mapUpdateLanguageList.includes(item.question_language)));
    }

    setLanguage(updateLanguageList);
  };

  useEffect(() => {
    if (positionQuestions.length) {
      const selectedQuestions = positionQuestions.map(item => item.question_id);

      setActiveQuestions(selectedQuestions);
    }

    if (!language.value) {
      setLanguage([anyOption]);
    }

    if (!questionsList.length) {
      setQuestionsList(questions);
    }
  }, []);

  const renderQuestions = questionsList.map(item => {
    if (item.question_type === questionsType.multiChoice) {
      return (
        <Tile key={`multi-choice-${item.text}`}>
          <div>
            <TileTitle>{item.text}</TileTitle>
            <TileType>
              <MdCheckBox />
              {item.question_type_label}
            </TileType>

            {item.description && (
              <>
                <DescriptionLabel>Description</DescriptionLabel>
                <DescriptionAnswer>{item.description}</DescriptionAnswer>
              </>
            )}
            <TileAnswers>
              {item.options.map(option => {
                return <TileAnswersItem key={`multi-choice-${option.text}`}>{option.text}</TileAnswersItem>;
              })}
            </TileAnswers>
          </div>
          <Checkbox
            checked={activeQuestions.includes(item.id)}
            onCheck={() => handleCheckActiveOptions(item.id)}
            onUncheck={() => handleUncheckActiveOptions(item.id)}
          />
        </Tile>
      );
    }

    if (item.question_type === questionsType.optionChoice) {
      return (
        <Tile key={`option-choice-${item.text}`}>
          <div>
            <TileTitle>{item.text}</TileTitle>
            <TileType>
              <MdRadioButtonChecked />
              {item.question_type_label}
            </TileType>

            {item.description && (
              <>
                <DescriptionLabel>Description</DescriptionLabel>
                <DescriptionAnswer>{item.description}</DescriptionAnswer>
              </>
            )}
            <TileAnswers>
              {item.options.map(option => {
                return <TileAnswersItem key={`option-choice-${option.text}`}>{option.text}</TileAnswersItem>;
              })}
            </TileAnswers>
          </div>

          <Checkbox
            checked={activeQuestions.includes(item.id)}
            onCheck={() => handleCheckActiveOptions(item.id)}
            onUncheck={() => handleUncheckActiveOptions(item.id)}
          />
        </Tile>
      );
    }

    if (item.question_type === questionsType.shortText) {
      return (
        <Tile key={`short-text-${item.text}`}>
          <div>
            <TileTitle>{item.text}</TileTitle>
            <TileType>
              <MdShortText />
              {item.question_type_label}
            </TileType>

            {item.description && (
              <>
                <DescriptionLabel>Description</DescriptionLabel>
                <DescriptionAnswer>{item.description}</DescriptionAnswer>
              </>
            )}
          </div>
          <Checkbox
            checked={activeQuestions.includes(item.id)}
            onCheck={() => handleCheckActiveOptions(item.id)}
            onUncheck={() => handleUncheckActiveOptions(item.id)}
          />
        </Tile>
      );
    }

    return (
      <Tile key={`text-${item.text}`}>
        <div>
          <TileTitle>{item.text}</TileTitle>
          <TileType>
            <MdFormatAlignLeft />
            {item.question_type_label}
          </TileType>

          {item.description && (
            <>
              <DescriptionLabel>Description</DescriptionLabel>
              <DescriptionAnswer>{item.description}</DescriptionAnswer>
            </>
          )}
        </div>
        <Checkbox
          checked={activeQuestions.includes(item.id)}
          onCheck={() => handleCheckActiveOptions(item.id)}
          onUncheck={() => handleUncheckActiveOptions(item.id)}
        />
      </Tile>
    );
  });

  return (
    <Modal>
      <Title>Add question</Title>
      <div>
        <Description>
          Select from the questions list created by {getCompanyName()} experts. You can’t edit the question but you can
          remove it or add more questions if needed.
        </Description>
        <Description>
          Didn`t find a question you’re looking for? Contact your account manager or our support at{" "}
          <a href="mailto:business@fuzu.com">business@fuzu.com</a>
        </Description>
      </div>

      <HelpFul title="Learn about the question types" isModal />

      <SelectTitle>Question language</SelectTitle>
      <SelectContainer>
        <SimpleSelect
          selectOptions={[
            anyOption,
            ...availableQuestionLanguages.map(item => ({ label: item.name, value: item.code }))
          ]}
          getFilteredList={handleSelectLanguage}
          value={language}
          noOptionsMessage={() => null}
          isSendFullObject
          isMulti
        />
      </SelectContainer>

      <QuestionContainer>
        <QuestionContent>{renderQuestions}</QuestionContent>
      </QuestionContainer>
      <Actions>
        <Add disabled={!activeQuestions.length || isDisableSubmit} onClick={handlePost}>
          Add selected {activeQuestions.length ? `(${activeQuestions.length})` : ""}
        </Add>
      </Actions>
    </Modal>
  );
};

export default AddQuestion;
