import React from "react";
import { MdPeopleOutline } from "react-icons/md";
import DOMPurify from "dompurify";

import { Modal, Title, Description, Actions, Cancel, Delete, Container } from "./styled";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { getTimeSpanString } from "../../../utils/helpers";

const DeleteComment = ({ handleClick, close, created_at, avatar, name, body }) => {
  const handleDelete = () => {
    handleClick();
    close();
  };

  return (
    <Modal>
      <Title>Delete comment?</Title>
      <Description>
        Make sure your team have seen this comment before deleting.
        <br />
        This cannot be undone.
      </Description>
      <Container>
        <div className="fz-message__content fz-message__content_public-modal">
          <div className="fz-message__header">
            <ProfileAvatar avatar={avatar} />

            <div>
              <h6 className="fz-message__name">{name}</h6>
              <span className="fz-message__time">
                {created_at && <>{`${getTimeSpanString(new Date() - new Date(created_at))} `}</>}
              </span>
            </div>
          </div>
          <>
            <div className="fz-message__tag fz-message__tag_public">
              <MdPeopleOutline />
              Public
            </div>
            <p
              className="fz-message__text fz-message__text_public-modal"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
            />
          </>
        </div>
      </Container>
      <Actions>
        <Cancel onClick={close}>Keep it</Cancel>
        <Delete onClick={handleDelete}>Yes, delete it</Delete>
      </Actions>
    </Modal>
  );
};

export default DeleteComment;
