import React from "react";
import ErrorImage from "@images/jobs/server_error.svg";
import styled from "styled-components";

import Button from "components/Button";

const PageError = ({ message = "", onClick }) => {
  return (
    <Wrapper>
      <ErrorImage />
      <span>Whoops!</span>
      <span>{message}</span>
      <Button onClick={onClick} color="blue">
        Try again
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
    height: fit-content;
    display: flex;
    flex-direction: column;

    span:nth-of-type(2) {
        font-weight: 400;
        font-size: 14px;
    }

    svg {
        height 105px;
        margin: 20px 0;
    }

    .fz-btn {
        width: fit-content;
        margin: 16px auto;
    }

`;

export default PageError;
