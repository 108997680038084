import styled from "styled-components";

export const Modal = styled.div`
  max-width: 750px;
  min-height: 100px;
  margin-top: -55px;

  @media screen and (min-width: 768px) {
    min-width: 672px;
    min-height: 270px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 24px 0;
`;

export const Text = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin: 0 0 24px 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
`;

export const Confirm = styled.button`
  background: #ffe140;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  width: 170px;
  flex: none;
  border-radius: 8px;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .fz-campaign-searchbar {
    width: 100%;
  }

  .fz-simple-select {
    background-color: #fff;
    width: 100%;
  }

  .select__indicator-separator,
  .select__indicator {
    display: none;
  }

  .select__menu {
    max-height: 136px;
    position: absolute;
    bottom: 44px;
    left: 0;
  }

  .select__menu-list {
    max-height: 136px;
    background-color: #fff;
    z-index: 1;
    border-radius: 8px;
    border: 1px solid #c5c5c5;
    box-shadow: 0 8px 16px 8px rgba(66, 76, 95, 0.05);
  }

  .select__control {
    height: 44px;
    border-radius: 8px;
  }
`;

export const SearchButton = styled.button`
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 16px;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Cancel = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  background: #eeeeee;
  border-radius: 8px;
  margin: 0;
  width: 131px;
  padding: 10px;
`;

export const Info = styled.button`
  border-radius: 8px;
  background: #f3f8ff;
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
  padding: 16px;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 24px;

  svg {
    fill: #408bfc;
    width: 20px;
    height: 20px;
  }
`;

export const ResultsLabel = styled.span`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin: 24px 0;
`;

export const Results = styled.div`
  padding-bottom: 24px;
  overflow: auto;
  max-height: 420px;
  height: 100%;
  margin-bottom: 24px;
`;

export const Tile = styled.button`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ $isActive }) => ($isActive ? "#408BFC" : "#eee")};
  background: #fff;
  margin-bottom: 8px;
  width: calc(100% - 24px);
`;

export const TileTitle = styled.h2`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 4px 0;
  text-align: left;
`;

export const TileDescription = styled.p`
  color: #666;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: left;
  max-height: 50px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
`;
