import React, { useState, useEffect } from "react";
import { MdExpandMore } from "react-icons/md";

import Select from "components/Selects";
import { SelectViewComponent, Label, Trigger, SelectViewContainer } from "./styled";

const SelectView = ({
  options,
  prompt,
  customPrompt,
  onSelect,
  initial,
  width,
  isShowPriority,
  dividerLength,
  isNewPopup,
  currentActiveActions,
  setCurrentActiveActions,
  refContainer,
  disabled,
  isAddDots
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const defaultOption = [
    { value: null, label: prompt },
    customPrompt ? { value: null, label: customPrompt } : {},
    ...options
  ];

  const handleClose = () => {
    setIsOpenPopup(false);
  };

  const handleOpen = () => {
    setIsOpenPopup(true);
  };

  const findInitial =
    initial &&
    defaultOption.some(item => String(item.value) === String(initial)) &&
    defaultOption.find(item => String(item.value) === String(initial)).label;

  const [ddLabel, setLabel] = useState("");

  const handleSelect = label => {
    const isSelectPrompt = prompt === label;
    const activeOption = isSelectPrompt ? "" : options.find(item => item.label === label)?.value;

    setLabel(label);
    onSelect(activeOption);
  };

  useEffect(() => {
    if (findInitial) {
      setLabel(findInitial);
    } else {
      setLabel(defaultOption[0].label);
    }
  }, [findInitial]);

  return (
    <SelectViewComponent
      $width={width}
      $isShowPriority={isShowPriority}
      $isCustomPromp={customPrompt}
      $dividerLength={dividerLength}
      $isNewPopup={isNewPopup}
      $disabled={disabled}
      $isAddDots={isAddDots}
    >
      <SelectViewContainer>
        <Select.Searchbar
          open={isOpenPopup}
          onClose={handleClose}
          onOpen={handleOpen}
          options={defaultOption.map(item => item.label).filter(item => Boolean(item))}
          isNewPopup={isNewPopup}
          setCurrentActiveActions={setCurrentActiveActions}
          refContainer={refContainer}
          currentActiveActions={currentActiveActions}
          prompt={prompt}
          Trigger={() => (
            <Trigger>
              <Label>{ddLabel}</Label>
              <MdExpandMore />
            </Trigger>
          )}
          position="bottom center"
          onSelect={handleSelect}
        />
      </SelectViewContainer>
    </SelectViewComponent>
  );
};

export default SelectView;
