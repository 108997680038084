import React from "react";
import { Hourglass } from "@styled-icons/boxicons-regular";

import { Text, Button } from "b2c/components/Core";
import { Content, Icon } from "./styled";

const Attachments = ({
  isShow,
  isAssignment,
  showModalInviteCandidate,
  messageButton,
  formatDate,
  campaignEndDate,
  isSharedCandidates
}) => {
  if (!isShow) {
    return null;
  }

  const formattedDate = formatDate(campaignEndDate);

  const renderText = () => {
    if (isSharedCandidates) {
      return (
        <Text fontWeight="600" fontSize="16px" line-height="140%" textAlign="center" color="grey750" width="auto">
          No attachments have been submitted yet
        </Text>
      );
    }

    if (isAssignment) {
      return (
        <Text fontWeight="400" fontSize="13px" line-height="140%" textAlign="center" color="black200">
          You have shortlisted the candidate and requested them to complete the assignments (the deadline was set to{" "}
          {formattedDate}).
          {messageButton}
          to remind them to submit their attachments.
        </Text>
      );
    }
    return (
      <Text fontWeight="400" fontSize="13px" line-height="140%" textAlign="center" color="black200">
        In case of a Fast Apply campaign, only the shortlisted candidates will upload required attachments.
        <Button
          fontWeight="400"
          fontSize="13px"
          line-height="140%"
          textAlign="center"
          color="secondary200"
          theme="transparent"
          height="20px"
          padding="0 4px"
          onClick={showModalInviteCandidate}
          style={{ minHeight: "auto" }}
          noBackground
          className="fz-btn_blue"
        >
          Shortlist candidate
        </Button>
        to request them to upload necessary attachments.
      </Text>
    );
  };

  return (
    <div className="fz-pane">
      <div className="fz-pane__container">
        <h2 className="fz-heading-title">Attachments</h2>
        <Content>
          <Icon>
            <Hourglass />
          </Icon>
          {renderText()}
        </Content>
      </div>
    </div>
  );
};

export default Attachments;
