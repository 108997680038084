import styled from "styled-components";

export const Content = styled.div`
  padding-top: 16px;
  border-top: 1px solid #eee;
`;

export const Title = styled.h6`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
`;

export const Text = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 16px;
`;

export const InputName = styled.input`
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid #c5c5c5;
  background: #fff;
  margin-bottom: 16px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  width: 100%;

  &:placeholder {
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Continue = styled.button`
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  border-radius: 5px;
  background: #ffe140;
  padding: 12px;
  display: block;
  width: 100%;
  margin-bottom: 16px;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const Cancel = styled.button`
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  border-radius: 8px;
  background: #eee;
  padding: 12px;
  display: block;
  width: 100%;
`;
