import React, { useState, useReducer } from "react";
import { MdFileUpload, MdSend, MdClose } from "react-icons/md";

import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { validateEmail } from "utils/validation";
import { csvParse, onEnterPress } from "utils/helpers";
import Input from "b2b/components/Input";

const inviteReducer = (prev, action) => {
  switch (action.type) {
    default:
    case "add":
      return prev.includes(action.payload) ? prev : prev.concat(action.payload);
    case "remove":
      return prev.filter(item => item !== action.payload);
    case "concat":
      return prev.concat(action.payload.filter(item => !prev.includes(item)));
  }
};

const HandleInviteContent = ({ inviteUsers, close }) => {
  const [processing, setProcessing] = useState(false);
  const [field, setField] = useState("");
  const [error, setError] = useState("");
  const [invitees, dispatch] = useReducer(inviteReducer, []);

  const addInvitee = () => {
    dispatch({ type: "add", payload: field });
    setError(invitees.includes(field) ? "Already in invitees" : "");
    setField("");
  };

  const sendInvites = () => {
    setProcessing(true);
    inviteUsers(invitees).then(success => {
      setProcessing(false);
      if (success) {
        close();
      }
    });
  };

  const hanldeCsv = e => {
    csvParse(e).then(payload => {
      if (
        payload.every(email => {
          const { test } = validateEmail(email);
          return email === "" || test;
        })
      )
        dispatch({ type: "concat", payload });
      else setError("Invalid file format");
    });
  };

  return (
    <>
      <div className="fz-form-row">
        <div className="fz-form-group width-100 mb-1">
          <label className="fz-std-label">Add invite</label>
          <Input
            type="email"
            placeholder="Email"
            value={field}
            onChange={setField}
            onKeyDown={e => onEnterPress(e, "", addInvitee)}
          />
        </div>
        <div className="fz-form-group mb-1">
          <Button onClick={addInvitee}>Add</Button>
        </div>
      </div>
      {error && !field && <div className="fz-text_xs fz-text-color__error fz-lh_1-4 mb-1">{error}</div>}
      <Tooltip
        tooltipContent={
          <>
            This option is for making multiple invites at one time.
            <br />
            You can create a .csv file with Excel or Google Docs.
            <br />
            Create a new file with email-addresses on Column A.
            <br />
            Save and upload the document here.
          </>
        }
      >
        <Input.File accept=".csv" onChange={hanldeCsv}>
          <Button.Simple className="mb-2" icon={<MdFileUpload />}>
            Invite multiple contacts with .CSV file
          </Button.Simple>
        </Input.File>
      </Tooltip>

      {invitees.length > 0 && (
        <>
          <h5 className="mb-2">
            Invitees
            <div className="fz-badge fz-badge_light-gray ml-1">{invitees.length}</div>
          </h5>
          <div className="folder-list mb-1">
            {invitees?.map(email => (
              <div className="folder" key={email}>
                <h5 className="ml-1">{email}</h5>
                <Button.Iconed onClick={() => dispatch({ type: "remove", payload: email })}>
                  <MdClose fill="#C5C5C5" />
                </Button.Iconed>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="fz-modal__actions mt-0">
        <Button color="gray" onClick={close}>
          Cancel
        </Button>
        <Button color="blue" icon={<MdSend />} disabled={invitees.length == 0 || processing} onClick={sendInvites}>
          {processing ? "Processing" : "Send invites"}
        </Button>
      </div>
    </>
  );
};

export default HandleInviteContent;
