import { makeAutoObservable } from "mobx";
import * as State from "utils/storeHelpers";
import { formatSecondsIn360Days } from "b2b/utils/helpers";
import * as API from "../utils/api";

const deriveErrors = err =>
  Object.values(err?.response?.data?.errors || {})
    ?.flat()
    .join(", ");

class SettingsStore {
  constructor() {
    makeAutoObservable(this);
  }

  state = {
    loading: false,
    processing: false,
    type: "",
    status: 200,
    message: "",
    error: false
  };

  employer;

  user;

  industries = [];

  currentUser;

  users;

  usersMeta;

  organizations;

  codes;

  sizes;

  countries;

  linkTypes;

  activeGlobalBrands = [];
  apiKeys = [];

  get industriesSubset() {
    return this.industries.map(item => ({
      ...item,
      selected: this.employer?.industries?.some(({ id }) => id == item.id)
    }));
  }

  initializeSettings = (employer, user) => {
    if (!this.industries || !this.employer || !this.currentUser) {
      this.state = State.setLoading(this.state);
      this.employer = employer;
      this.currentUser = user;
      const isAdmin = user?.roles?.some(role => ["admin", "employer_admin"].includes(role));
      const requests = [API.getIndustries()];
      if (isAdmin) {
        requests.push(API.getBrandDetails());
      }

      return Promise.all(requests)
        .then(res => {
          this.industries = res[0]?.data?.industries;
          this.employer.brand = res[1]?.data?.brand;

          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not initialize settings", () =>
            this.initializeSettings()
          );
        });
    }
  };

  initializeDetails = () => {
    return Promise.all([
      !this.sizes ? API.getEmployerSizes() : Promise.resolve(),
      !this.codes ? API.getPhoneCodes() : Promise.resolve()
    ])
      .then(res => {
        if (res[0]) this.sizes = res[0]?.data?.employer_sizes;
        if (res[1]) this.codes = res[1]?.data?.codes?.priorities.concat(res[1]?.data?.codes?.common);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not initialize details", () => this.initializeDetails());
      });
  };

  getPhoneCodes = () => {
    return API.getPhoneCodes()
      .then(res => {
        this.codes = res?.data?.codes?.priorities.concat(res?.data?.codes?.common);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not get phone codes", () => this.getPhoneCodes());
      });
  };

  getSocialLinkTypes = () => {
    if (!this.linkTypes) {
      return API.getSocialLinkTypes()
        .then(res => {
          this.linkTypes = res.data.social_link_types;
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve link types", () =>
            this.getSocialLinkTypes()
          );
        });
    }
  };

  updateIndustries = ids => {
    return API.updateEmployerIndustries(ids)
      .then(res => {
        this.employer = res?.data?.employer;
        this.state = State.setSuccess(this.state, res, "Industries were updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update industries", () => this.updateIndustries(ids));

        return deriveErrors(err);
      });
  };

  updateDescription = description => {
    return API.updateEmployerDescription(description)
      .then(res => {
        this.employer = res?.data?.employer;
        this.state = State.setSuccess(this.state, res, "Description was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update description", () =>
          this.updateDescription(description)
        );

        return deriveErrors(err);
      });
  };

  updateLogo = logo => {
    const formData = new FormData();
    formData.append("logo", logo);
    return API.updateEmployerLogo(formData)
      .then(res => {
        this.employer = res?.data?.employer;
        this.state = State.setSuccess(this.state, res, "Logo was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update logo", () => this.updateLogo(logo));

        return deriveErrors(err);
      });
  };

  deleteLogo = () => {
    return API.deleteEmployerLogo()
      .then(res => {
        this.employer.logo = null;
        this.state = State.setSuccess(this.state, res, "Logo was deleted successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete logo", () => this.deleteLogo());
      });
  };

  updateContactDetails = details => {
    return API.updateEmployerContactDetails(details)
      .then(res => {
        this.employer = { ...this.employer, ...res.data.employer };
        this.state = State.setSuccess(this.state, res, "Contact details were updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update contact details", () =>
          this.updateContactDetails(details)
        );

        return err.response?.data?.errors;
      });
  };

  updateSocialLink = link => {
    return API.updateEmployerSocialLink(link)
      .then(res => {
        this.employer = res.data.employer;
        this.state = State.setSuccess(this.state, res, "Social link was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update social link", () => this.updateSocialLink(link));

        return deriveErrors(err);
      });
  };

  deleteSocialLink = id => {
    return API.deleteEmployerSocialLink(id)
      .then(res => {
        this.employer = res.data.employer;
        this.state = State.setSuccess(this.state, res, "Social link was deleted successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete social link", () => this.deleteSocialLink(id));

        return deriveErrors(err);
      });
  };

  updateBanner = banner => {
    const formData = new FormData();
    formData.append("banner", banner);
    return API.updateEmployerBanner(formData)
      .then(res => {
        this.employer = res?.data?.employer;
        this.state = State.setSuccess(this.state, res, "Banner was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update banner", () => this.updateBanner(banner));

        return deriveErrors(err);
      });
  };

  deleteBanner = () => {
    return API.deleteEmployerBanner()
      .then(res => {
        this.employer.banner = null;
        this.state = State.setSuccess(this.state, res, "Banner was deleted successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete banner", () => this.deleteBanner());
      });
  };

  updateBrandSlug = slug => {
    return API.updateBrandSlug(slug)
      .then(res => {
        this.employer.brand = res?.data?.brand;
        this.state = State.setSuccess(this.state, res, "Brand slug was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update brand slug", () => this.updateBrandSlug(slug));
      });
  };

  updateBrandParams = params => {
    return API.updateBrandParams(params)
      .then(res => {
        this.employer.brand = res?.data?.brand;
        this.state = State.setSuccess(this.state, res, "Brand was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update brand", () => this.updateBrandParams(params));
      });
  };

  toggleCareerPortal = enable => {
    if (enable) {
      this.state = State.setSuccess(
        this.state,
        {},
        "Please note that activating a branded career portal link might take a few minutes"
      );
    }
    return API.toggleCareerPortal(enable)
      .then(res => {
        this.employer.brand = res?.data?.brand;
        this.state = State.setSuccess(this.state, res, "Brand enabled was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update career portal", () =>
          this.toggleCareerPortal(enable)
        );
      });
  };

  getUserList = page => {
    this.state = State.setLoading(this.state);
    return API.getUserList(page)
      .then(res => {
        this.users = res?.data?.users;
        this.usersMeta = res?.data?.meta;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retieve user list", () => this.getUserList());
      });
  };

  removeUser = id => {
    return API.removeUser(id)
      .then(res => {
        this.users = this.users.filter(user => user.id != id);
        this.state = State.setSuccess(this.state, res, "User successfully removed");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove user", () => this.removeUser(id));
      });
  };

  grantAdmin = id => {
    return API.grantAdmin(id)
      .then(res => {
        const userIndex = this.users.findIndex(user => user.id == id);
        const tempUsers = [...this.users];
        tempUsers[userIndex] = { ...tempUsers[userIndex], admin: true };
        this.users = tempUsers;
        this.state = State.setSuccess(this.state, res, "Admin status granted");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to grant admin status", () => this.grantAdmin(id));
      });
  };

  revokeAdmin = id => {
    return API.revokeAdmin(id)
      .then(res => {
        const userIndex = this.users.findIndex(user => user.id == id);
        const tempUsers = [...this.users];
        tempUsers[userIndex] = { ...tempUsers[userIndex], admin: false };
        this.users = tempUsers;
        this.state = State.setSuccess(this.state, res, "Admin status revoked");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to revoke admin status", () => this.revokeAdmin(id));
      });
  };

  inviteUsers = invites => {
    return API.inviteUsers(invites)
      .then(res => {
        this.users = res?.data?.users?.concat(this.users);
        this.state = State.setSuccess(this.state, res, "Invites sent successfully");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to invite users", () => this.inviteUsers(invites));

        return false;
      });
  };

  resendUserInvite = id => {
    return API.resendUserInvite(id)
      .then(res => {
        this.users = this.users.map(user => (user.id === id ? { ...user, invite_path: res?.data?.path } : user));
        this.state = State.setSuccess(this.state, res, "Invites resent successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to resend invite", () => this.resendUserInvite(id));
      });
  };

  getOrganizations = () => {
    if (!this.organizations) {
      this.state = State.setLoading(this.state);
      return API.getOrganizations()
        .then(res => {
          this.organizations = res?.data?.organizations;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retieve organizations", () =>
            this.getOrganizations()
          );
        });
    }
  };

  inviteOrganization = slug => {
    return API.inviteOrganization(slug)
      .then(res => {
        this.organizations = this.organizations.concat(res?.data?.invitation);
        this.state = State.setSuccess(this.state, res, "Invites sent successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to invite organization", () =>
          this.inviteOrganization(slug)
        );

        return deriveErrors(err);
      });
  };

  removeOrganization = id => {
    return API.removeOrganization(id)
      .then(res => {
        this.organizations = this.organizations.filter(organization => organization.id != id);
        this.state = State.setSuccess(this.state, res, "Organization successfully removed");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove organization", () =>
          this.removeOrganization(id)
        );
      });
  };

  approveOrganizationInvite = (id, token) => {
    return API.approveOrganizationInvite(token)
      .then(res => {
        const orgIndex = this.organizations.findIndex(org => org.id == id);
        const tempOrgs = [...this.organizations];
        tempOrgs[orgIndex] = { ...tempOrgs[orgIndex], accepted: true };
        this.organizations = tempOrgs;
        this.state = State.setSuccess(this.state, res, "Approved succesfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to approve", () => this.approveOrganizationInvite(token));
      });
  };

  resendOrganizationInvite = id => {
    return API.resendOrganizationInvite(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Invite successfully resent");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to resend invite", () =>
          this.resendOrganizationInvite(id)
        );
      });
  };

  batchResend = () => {
    this.state = State.setProcessing(this.state);
    return Promise.all(
      this.organizations.filter(({ accepted }) => !accepted).map(({ id }) => API.resendOrganizationInvite(id))
    ).then(() => {
      this.state = {
        loading: false,
        processing: false,
        type: `batch_resend_${this.organizations.filter(({ accepted }) => !accepted).map(({ id }) => id)}_success`,
        status: 200,
        message: "Invites successfully resent",
        error: false
      };
    });
  };

  getApplicationSettings = () => {
    if (!this.employer.applicationSettings) {
      this.state = State.setLoading(this.state);
      return API.getApplicationSettings()
        .then(res => {
          this.employer.applicationSettings = res?.data?.settings;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retieve application settings", () =>
            this.getApplicationSettings()
          );
        });
    }
  };

  toggleOpenApplications = () => {
    return API.toggleOpenApplications()
      .then(res => {
        this.employer.applicationSettings = res?.data?.settings;
        this.state = State.setSuccess(this.state, res, "Setting was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update setting", () => this.toggleOpenApplications());
      });
  };

  toggleExternalAts = () => {
    return API.toggleExternalAts()
      .then(res => {
        this.employer.applicationSettings = res?.data?.settings;
        this.state = State.setSuccess(this.state, res, "Setting was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update setting", () => this.toggleExternalAts());
      });
  };

  getUserInfo = () => {
    if (!this.currentUser) {
      this.state = State.setLoading(this.state);
      return API.getUserInfo()
        .then(res => {
          this.currentUser = res?.data?.current_user;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to get user info", () => this.getUserInfo());
        });
    }
  };

  updateUserName = user => {
    return API.updateUserName(user)
      .then(res => {
        this.currentUser = res?.data?.current_user;
        this.state = State.setSuccess(this.state, res, "Name was updated successfully");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update user name", () => this.updateUserName(user));
      });
  };

  updateUserAvatar = value => {
    const formData = new FormData();
    formData.append("user[photo_attributes][value]", value);
    return API.updateUserAvatar(formData)
      .then(res => {
        this.currentUser = res?.data?.current_user;
        this.state = State.setSuccess(this.state, res, "Photo was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update photo", () => this.updateUserAvatar(value));

        return deriveErrors(err);
      });
  };

  updateUserPassword = form => {
    const formData = new FormData();

    Object.keys(form).map(key => {
      formData.append(`user[${key}]`, form[key]);
    });
    return API.updateUserPassword(formData)
      .then(res => {
        this.currentUser = res?.data?.current_user;
        this.state = State.setSuccess(this.state, res, "Password was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update password", () => this.updateUserAvatar(form));

        return err?.response?.data?.errors;
      });
  };

  updateUserEmail = email => {
    return API.updateUserEmail({ email })
      .then(res => {
        this.currentUser = res?.data?.current_user;
        this.state = State.setSuccess(this.state, res, "Email was updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update user email", () => this.updateUserEmail(email));

        return deriveErrors(err);
      });
  };

  getAccessibilityOptions = () => {
    if (!this.accessibilityOptions) {
      this.state = State.setLoading(this.state);
      return API.getAccessibilityOptions()
        .then(res => {
          this.accessibilityOptions = res?.data?.questions;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to get user info", () => this.getAccessibilityOptions());
        });
    }
  };

  updateAccessibilities = body => {
    return API.updateAccessibities(body)
      .then(res => {
        this.accessibilityOptions = res?.data?.questions;
        Object.keys(this.employer.accessibilities).forEach(key => {
          this.employer.accessibilities[key].selected_option = body[key];
          return this.employer.accessibilities[key].selected_option;
        });
        this.state = State.setSuccess(this.state, res, "Accessbility settings were updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update accessbility settings", () =>
          this.updateAccessibilities(body)
        );

        return err?.response?.data?.errors;
      });
  };

  getActiveGlobalBrands = () => {
    return API.getActiveGlobalBrands()
      .then(res => {
        this.state = State.setNeutral(this.state, res);
        this.activeGlobalBrands = res.data;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve active global brands", () =>
          this.getActiveGlobalBrands()
        );
      });
  };

  getApiKeys = () => {
    if (!this.employer.token) {
      this.state = State.setLoading(this.state);
      return API.getApiKeys()
        .then(res => {
          this.apiKeys = res.data.api_keys;

          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve API token", () => this.getApiKeys());
        });
    }
  };

  createApiKeys = id => {
    this.state = State.setLoading(this.state);

    return API.createApiKeys({ global_brand_id: id })
      .then(res => {
        this.state = State.setNeutral(this.state, res);

        const apiKey = res.data.api_key;
        const createKey = { ...apiKey, openKey: apiKey.key, key: apiKey.masked_key };
        this.apiKeys = [...this.apiKeys, createKey];

        return createKey;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to create global_brand_tokens token", () =>
          this.createApiKeys(id)
        );
      });
  };

  deleteApiKeys = id => {
    this.state = State.setLoading(this.state);
    return API.deleteApiKeys(id)
      .then(res => {
        this.state = State.setNeutral(this.state, res);

        this.apiKeys = this.apiKeys.map(item => (String(item.id) === String(id) ? { ...item, active: false } : item));
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete global_brand_tokens token", () =>
          this.deleteApiKeys(id)
        );
      });
  };

  // TODO remove or connect
  regenerateApiToken = id => {
    this.state = State.setLoading(this.state);
    return API.regenerateApiToken(id)
      .then(res => {
        const globalBrandTokens = this.employer.globalBrandTokens.filter(item => {
          if (item.id === res?.data.id) {
            return res?.data;
          }
          return item;
        });

        this.employer.globalBrandTokens = globalBrandTokens.map(item => {
          if (item.expires_in && typeof item.expires_in === "number") {
            return { ...item, expires_in: formatSecondsIn360Days(item.expires_in, true) };
          }

          return item;
        });

        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to regenerate global_brand_tokens token", () =>
          this.regenerateApiToken(id)
        );
      });
  };

  generateApiToken = () => {
    return API.generateApiToken()
      .then(res => {
        this.employer.token = res?.data?.token;
        this.state = State.setSuccess(this.state, res, "API token generated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to generate API token", () => this.generateApiToken());
      });
  };
}

export default new SettingsStore();
