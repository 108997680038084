import React, { useState, useEffect } from "react";

import { MdDone } from "react-icons/md";
import Button from "components/Button";
import Layout from "../Layout";

const toLocale = str =>
  new Date(str).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric"
  });

const getMonths = (start, end) => {
  const startDate = new Date(start);
  const endDate = end ? new Date(end) : new Date();
  const yearDefference = endDate.getFullYear() - startDate.getFullYear();

  return endDate.getMonth() + yearDefference * 12 - startDate.getMonth();
};

const UserCvWorkExp = ({ exp = [], totalWorkExperience }) => {
  const [state, setAllExp] = useState([]);

  useEffect(() => {
    if (exp.length > 0) setAllExp(Array(exp.length).fill(false));
  }, [exp]);

  const getDuration = total_months => {
    const years = Math.floor(total_months / 12);
    const months = total_months % 12;

    return `${years > 0 ? `${years} years ` : ""}${months} months`;
  };

  const totalExp = () => {
    const total_months =
      exp.length > 0 ? exp.reduce((acc, current) => acc + getMonths(current.start_date, current.end_date), 0) : 0;
    return getDuration(total_months);
  };

  const handleExpand = index => {
    setAllExp(prev => {
      const newState = prev;
      newState[index] = !newState[index];
      return [...newState];
    });
  };

  return (
    <div className="fz-pane">
      <div className="fz-pane__container fz-pane__container_case-two">
        <h2 className="fz-heading-title exp">
          Work experience
          <Button.Simple
            onClick={() =>
              setAllExp(prev => (prev.some(item => item === true) ? prev.map(() => false) : prev.map(() => true)))
            }
          >
            {state.some(item => item === true) ? "Hide" : "Show"} all experience
          </Button.Simple>
        </h2>
        <div className="fz-info-text mb-3">
          <span className="fz-info-text__text">
            Total experience <strong>{totalWorkExperience || totalExp()}</strong>
          </span>
        </div>

        {exp.map((item, index) => (
          <div key={`wexp_${item.job_title}_${index}`} className="fz-info-pane">
            <div className="fz-info-pane__header">
              <div className="fz-info-pane__cell">
                <h3 className="fz-heading-subtitle">{item.job_title}</h3>
                <div className="fz-info-text mb-0">
                  <span className="fz-info-text__text">
                    <strong>{item.employer}</strong>
                  </span>
                  <span className="fz-info-text__separator">•</span>
                  <span className="fz-info-text__text">{item.employer_size}</span>
                </div>
              </div>
              <div className="fz-info-pane__cell">
                <div className="fz-info-text mt-1 mb-0">
                  <span className="fz-info-text__text">
                    {toLocale(item.start_date)} – {item.end_date ? toLocale(item.end_date) : "Present"}
                  </span>
                  <span className="fz-info-text__separator">•</span>
                  <span className="fz-info-text__text pr-0">
                    {getDuration(getMonths(item.start_date, item.end_date))}
                  </span>
                </div>
              </div>
            </div>
            <div className="fz-info-pane__body">
              <Layout.Expander expanded={!state[index]} onExpand={() => handleExpand(index)} collapseHeight={57}>
                <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-0">
                  {item.description}
                </p>
              </Layout.Expander>
              <div className="fz-tag-container mt-1">
                {item.skills.map((skill, indexItem) => (
                  <div key={`skill_${skill.id}_${indexItem}`} className="fz-tag">
                    <span className="fz-tag__text">{skill.name}</span>
                    {skill.matched_to_campaign && <MdDone className="fz-tag__icon" />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserCvWorkExp;
