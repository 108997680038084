import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Modal from "components/Modal";
import { theme } from "b2c/contexts/theme";
import Subscription from "../../components/Subscription";
import MessagesScreen from "../../pages/MessagesScreen";
import AccountSettingsScreen from "../../pages/AccountSettingsScreen";
import MyProfile from "../../pages/MyProfile";
import HeadhuntApp from "../HeadhuntApp";
import JobRouter from "../JobRouter";
import AccountCreation from "../AccountCreation";
import SharedCandidates from "../SharedCandidates";
import SearchCandidates from "../SearchCandidates";
import PreScreeningQuestion from "../../pages/PreScreeningQuestion";

const RootRouter = ({ initialState }) => {
  const { global_brand } = initialState;
  const { colors } = global_brand || {};

  if (colors && theme.switchColors) {
    theme.switchColors(colors);
  }

  return (
    <Router>
      <Modal />
      <Switch>
        <Route
          path={[
            "/create-account",
            "/login",
            "/accept-invitation",
            "/forgot-password",
            "/check-email",
            "/invalid-invitation-token",
            "/used-invitation-token",
            "/new-password",
            "/employers/select",
            "/invalid-reset-link"
          ]}
          component={props => <AccountCreation {...props} toastMessage={initialState?.toast_message} />}
        />
        <AccountSettingsScreen path="/account-settings" />
        <MyProfile path="/my-profile" />
        <Route path="/messages" component={props => <MessagesScreen b2b {...props} />} />
        <Route
          path={["/campaigns/:id/steps/prescreening", "/campaigns/:id/steps/single/prescreening"]}
          component={props => <PreScreeningQuestion {...props} initialState={initialState} />}
        />
        <Route path={["/jobs", "/tutorials", "/messages", "/campaigns"]} component={JobRouter} />
        <Route
          path={["/share-candidates"]}
          component={props => <SharedCandidates {...props} initialState={initialState} />}
        />
        <Route
          path={["/candidates", "/candidates/:candidateId"]}
          component={props => <SearchCandidates {...props} initialState={initialState} />}
        />
        <Subscription.Route
          element="Headhunting"
          permission="headhunting"
          path="/headhunting"
          to="/jobs"
          component={HeadhuntApp}
        />
      </Switch>
    </Router>
  );
};

export default RootRouter;
