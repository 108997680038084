import styled from "styled-components";

export const EmptyStateTest = styled.div`
  padding: 0 32px 0 0;
`;

export const EmptyStateTestName = styled.p`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 4px;
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-left: -3px;
  }
`;

export const EmptyStateTestStatus = styled.span`
  color: #666;
  font-size: 12px;
  font-weight: 400;
`;
