import React, { useContext } from "react";
import { observer, inject } from "mobx-react";
import { MdEmail } from "react-icons/md";

import { ModalContext } from "contexts/modal";
import Button from "components/Button";
import SingleConversation from "pages/Messenger/components/SingleConversation";

import InitContext from "contexts/init";
import ConversationModalContent from "../ConversationModalContent";
import Subscription from "../Subscription";

const DefaultButton = props => {
  return (
    <Button icon={<MdEmail />} size="sm" color="gray" {...props}>
      Message
    </Button>
  );
};

const MessageButton = ({
  Messages: { lastAdded },
  isUnlocked,
  unlockHandler,
  messageUrl,
  type = "candidate",
  users = [],
  campaignEmployerId,
  selectedCandidates,
  navigationIds,
  Component = props => <DefaultButton {...props} />
}) => {
  const {
    enabled_features: { b2b_unfinished_features }
  } = useContext(InitContext);
  const { toggle } = useContext(ModalContext);

  const createConversation = () => {
    if (b2b_unfinished_features) {
      toggle(
        <ConversationModalContent
          campaignEmployerId={campaignEmployerId}
          type={type}
          users={users}
          selectedCandidates={selectedCandidates}
          navigationIds={navigationIds}
        />,
        type === "batch" ? "New message" : "Create conversation"
      );
    }
  };

  const openExistingConversation = () => {
    if (b2b_unfinished_features) {
      toggle(
        <SingleConversation
          campaignEmployerId={campaignEmployerId}
          conversation={users[0].conversation || lastAdded.conversation}
        />,
        ""
      );
    }
  };

  const singleConversation =
    users.length === 1 && (users[0].conversation || String(users[0].id) === String(lastAdded?.id));

  return (
    <Subscription.Unlock unlocked={isUnlocked} paymentCheck unlockHandler={unlockHandler} element="Messaging">
      <Component
        tag={b2b_unfinished_features ? "div" : "a"}
        href={messageUrl}
        role="button"
        tabIndex="0"
        onClick={singleConversation ? openExistingConversation : createConversation}
      />
    </Subscription.Unlock>
  );
};

export default inject("Messages")(observer(MessageButton));
