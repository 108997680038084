import styled from "styled-components";
import { theme } from "../../../../../b2c/contexts/theme";

export const Modal = styled.div`
  max-width: 600px;
  min-height: 100px;
  margin-top: -55px;
  max-height: 500px;

  @media screen and (min-width: 768px) {
    min-width: 672px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 24px 0;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #eee;
  margin-bottom: 24px;
`;

export const Tab = styled.button`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 16px;
  cursor: pointer;
  border-bottom: 2px solid ${({ $isActive }) => ($isActive ? theme.primaryButtonBackground : "transparent")};
  color: ${({ $isActive }) => ($isActive ? "#333" : "#666")};
  background-color: transparent;
`;

export const SubTitle = styled.h3`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || "4px"};
`;

export const SubDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 24px;
`;

export const DropdownContent = styled.div`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 11px 12px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  margin-bottom: 24px;

  .fz-simple-select .fz-simple-select__menu {
    max-width: 100%;
    width: 100%;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Member = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

export const MemberLogoContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  img {
    max-width: 100%;
  }
`;

export const MemberTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 4px;
`;

export const MemberCount = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const EmptyImageContent = styled.div`
  margin: 24px auto 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`;

export const EmptyStateDescription = styled.p`
  color: #666;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const CreateGuestLink = styled.button`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  padding: 9px 40px;
`;

export const GuestLinkItem = styled.div`
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #fff;
  gap: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
  position: relative;
`;

export const GuestLinkItemIcon = styled.span`
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  background: #f3f8ff;
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    fill: #408bfc;
  }
`;

export const GuestLinkItemName = styled.h2`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 8px 0;
`;

export const GuestLinkItemInfoFlex = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

export const GuestLinkItemInfo = styled.span`
  gap: 8px;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const EditShareButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  padding: 8px 12px;
  width: 100%;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: #666666;
  }
`;

export const RemoveShareButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #eb3737;
  padding: 8px 12px;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: #eb3737;
  }
`;

export const ButtonEdit = styled.button`
  width: 32px;
  height: 32px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;

  svg {
    fill: #666666;
    width: 16px;
    height: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-self: center;
  gap: 16px;
  margin-left: auto;
`;

export const ButtonCopy = styled.button`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 8px;

  svg {
    fill: #666666;
    width: 16px;
    height: 16px;
  }
`;

export const EditContent = styled.div`
  border: 1px solid #c5c5c5;
  box-shadow: 0 5px 15px 3px rgba(33, 33, 33, 0.05);
  border-radius: 8px;
  position: absolute;
  right: 16px;
  top: -98px;
  background-color: #fff;
  z-index: 1;
  padding: 8px 0;
`;

export const CollaborationList = styled.div`
  .fz-profile-avatar {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #eee;
  }

  .fz-simple-btn_lightgray {
    border-radius: 8px;
    background: #eee;
    height: 32px;
    width: 34px;
    display: flex;
    align-self: center;
    justify-content: center;

    svg {
      fill: #444444;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const InviteCollaboratorsContent = styled.div`
  margin-bottom: 24px;
`;

export const Input = styled.input`
  border-radius: 8px;
  border: 1px solid ${({ $isError }) => ($isError ? "#EB3737" : "#c5c5c5")};
  background: #fff;
  padding: 13px 12px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
`;

export const Invite = styled.button`
  border-radius: 8px;
  background: #ffe140;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 14px 16px;
  margin-left: auto;
  align-self: center;
  white-space: nowrap;
  border: none;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Error = styled.span`
  color: #eb3737;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-top: 8px;
`;

export const Remove = styled.button`
  color: #ff2727;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-self: center;
  gap: 8px;
  background: #fff;
  padding: 8px 12px;

  svg {
    width: 20px;
    height: 20px;
    fill: #ff2727;
  }
`;

export const ActionContent = styled.div`
  padding: 7px 0;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0 5px 15px 3px rgba(33, 33, 33, 0.05);
`;
