import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AlreadyShareCopyField = styled.div`
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  width: 224px;
  flex: none;
  padding: 7px 8px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: #f3f3f3;
`;

export const ButtonCopy = styled.button`
  width: 32px;
  height: 32px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;

  svg {
    fill: #666666;
    width: 14px;
    height: 14px;
  }
`;
