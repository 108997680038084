import React from "react";
import withStore from "../../hocs/withStore";
import SelectJobForInvite from "../SelectJobForInvite";

const SelectJobForInviteContent = ({
  store: { availableJobsForCandidate, handleSelectedAvailableJobForCandidate },
  selectedTalents,
  handleInviteToApply,
  handleClose
}) => {
  return (
    <SelectJobForInvite
      handleClose={handleClose}
      selectedTalents={selectedTalents}
      handleInviteToApply={handleInviteToApply}
      availableJobsForCandidate={availableJobsForCandidate}
      handleSelectedAvailableJobForCandidate={handleSelectedAvailableJobForCandidate}
    />
  );
};

export default withStore(SelectJobForInviteContent);
