import styled from "styled-components";

export const SingleConversationWrapper = styled.div`
  display: flex;
  overflow: auto;
  width: 768px;
  height: 80vh;
  flex: 1 0 0;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #0b0a0a;
  border-radius: 8px;
  margin: -60px -26px -32px;

  @media screen and (max-width: 767px) {
    box-shadow: none;
    background: none;
    width: auto;
  }

  button:focus {
    outline: none;
  }
`;
