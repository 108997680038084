import styled from "styled-components";

export const ModeOrAndComponent = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-bottom: 18px;

  .fz-tooltip-content {
    background: #333333;
    width: 264px !important;
    opacity: 1;
    padding: 8px 12px;
  }

  .ModeOrAndComponent_icon {
    display: block;
    margin-left: 6px;
    cursor: pointer;

    &:hover {
      fill: #408bfc;
      fill: #408bfc;
    }
  }
`;

export const Mode = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
`;

export const Switcher = styled.button`
  width: 80px;
  display: flex;
  overflow: hidden;
  margin-left: auto;
  border: none;
  background: none;
`;

export const SwitcherItem = styled.span`
  width: 50%;
  flex: none;
  border: 1px solid #c5c5c5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  background-color: #fff;
  height: 24px;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:nth-child(2) {
    border-radius: 0 8px 8px 0;
  }

  ${({ isActive }) =>
    isActive
      ? `
        background-color: #408BFC;
        border-color: #408BFC;
        color: #fff;
      `
      : ""}
`;

export const TooltipText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #c5c5c5;
  display: block;
  margin-bottom: 8px;

  span {
    font-weight: 600;
    color: #fff;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
