import styled from "styled-components";

export const Modal = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }

  @media screen and (min-width: 1008px) {
    min-width: 800px;
  }

  @media screen and (min-width: 1240px) {
    min-width: 1000px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  max-height: 600px;
  overflow-y: auto;

  @media screen and (min-width: 1008px) {
    display: flex;
    gap: 48px;
    width: calc(100% + 24px);
  }
`;

export const LeftSide = styled.div`
  width: calc(100% - 268px);

  @media screen and (min-width: 1008px) {
    width: calc(100% - 358px);
  }
`;

export const RightSide = styled.div`
  @media screen and (min-width: 1008px) {
    width: 220px;
    flex: none;
    padding-left: 24px;
    border-left: 1px solid #eee;
  }
`;

export const InfoItem = styled.div`
  margin-top: 16px;

  &:first-child {
    margin-bottom: 0;
  }
`;

export const InfoLabel = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 4px;
`;

export const InfoDescription = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;

  img {
    border-radius: 50%;
    border: 1px solid #eee;
    width: 24px;
    height: 24px;
  }
`;

export const InfoStatus = styled.span`
  border-radius: 8px;
  background: rgba(243, 243, 243, 0.9);
  padding: 4px 8px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;

export const InfoYouLabel = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const Label = styled.span`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`;

export const Delete = styled.button`
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ff2727;
  padding: 8px 15px;
  border: 1px solid #c5c5c5;
  background: #fff;
`;

export const Edit = styled.button`
  background: #408bfc;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 8px 16px;
`;

const arrow = `
  border-radius: 8px;
  opacity: 0.8;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
    fill: #fff;
  }
`;

export const ArrowLeft = styled.button`
  ${arrow};
  left: -82px;
`;

export const ArrowRight = styled.button`
  ${arrow};
  right: -82px;
`;
