import styled from "styled-components";

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  display: block;
  margin-bottom: 24px;
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  display: block;
  margin-bottom: 8px;
`;

export const SelectJob = styled.div`
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 15px 20px 15px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ isActive }) => (isActive ? "#333333" : "#666666")};
  margin-bottom: 24px;
  cursor: pointer;
`;

export const AddTalentToJobComponent = styled.div`
  max-width: 580px;
`;
