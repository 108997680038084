import React, { useState } from "react";
import Layout from "../Layout";

const UserCvCoverLetter = ({ letter }) => {
  const [state, setState] = useState(false);

  return (
    <div className="fz-pane">
      <div className="fz-pane__container fz-pane__container_case-two">
        <h2 className="fz-heading-title">Cover letter</h2>

        <Layout.Expander expanded={!state} collapseHeight={130} innerButtons={false}>
          <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-2">{letter}</p>
        </Layout.Expander>
      </div>
      <div
        role="button"
        tabIndex={-1}
        className="fz-collapse-btn fz-collapse-btn_clear"
        onClick={() => setState(!state)}
      >
        <div className="fz-collapse-btn__text">{!state ? "Read more" : "Show less"}</div>
      </div>
    </div>
  );
};

export default UserCvCoverLetter;
