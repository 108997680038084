import React from "react";
import styled from "styled-components";

import inviteEmail from "images/invite_email.png";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";

const CheckEmail = () => {
  return (
    <CreateLoginFlowWrapper
      title="Check your email"
      description="Please check your inbox and click the link in the email to reset your password. If you cannot find the email in your inbox, please also check junk mail."
    >
      <EmailImageContent>
        <EmailImageWrapper>
          <Image src={inviteEmail} alt="invite email" />
        </EmailImageWrapper>
      </EmailImageContent>
    </CreateLoginFlowWrapper>
  );
};

const EmailImageWrapper = styled.div`
  background: #f3f3f3;
  height: 120px;
  width: 120px;
  border: none;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
`;

const EmailImageContent = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Image = styled.img`
  margin-top: 18px;
`;

export default CheckEmail;
