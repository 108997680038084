import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { MdClear } from "react-icons/md";

import Loading from "images/loading.svg";
import thumbs from "images/jobs/thumbs-up.png";
import error from "images/jobs/error-smile.png";
import SuccessIcon from "images/jobs/iconSuccess.svg";

let Toast;

const FlashMessage = ({
  type = "default",
  image = <img src={thumbs} alt="thumbs" />,
  buttons,
  closeButton,
  hide = false,
  message = "",
  children,
  autoClose = 4000,
  reject
}) => {
  const [visible, show] = useState(false);
  const [animated, setAnimated] = useState(false);
  const isBigMessage = message.includes("successfully moved to");
  const isMovingCandidates = message.includes("Moving candidates...");

  const toastType = useMemo(() => {
    switch (type) {
      default:
      case "default":
        return "default";
      case "error":
        return "error";
      case "loading":
        return "info";
      case "success":
        return "success";
      case "warning":
        return "warning";
    }
  }, [type]);

  useEffect(() => {
    show(!hide);
    setAnimated(!(animated && hide));
  }, [hide]);

  useEffect(() => {
    let aniTimer;
    if (animated) aniTimer = setTimeout(() => setAnimated(false), 250);
    return () => clearTimeout(aniTimer);
  }, [animated]);

  useEffect(() => {
    visible ? showToast() : toast.dismiss();
  }, [visible]);

  const showToast = () => {
    if (!toast.isActive(Toast)) {
      Toast = toast(toastContent, {
        type: toastType || "default",
        autoClose,
        closeOnClick: false,
        closeButton: <CloseButton />,
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const CloseButton = () => (
    <div className="fz-toast__actions">
      {buttons}

      {!buttons &&
        (closeButton ? (
          <span role="button" tabIndex={-1} onClick={() => show(false)}>
            {closeButton}
          </span>
        ) : (
          <span
            role="button"
            tabIndex={-1}
            className={`Toastify__close-button Toastify__close-button--${type}`}
            onClick={() => show(false)}
          >
            <MdClear />
          </span>
        ))}
    </div>
  );

  const RejectButton = () => (
    <>
      <CloseButton />
      <span
        style={{
          color: "#408BFC",
          display: "inline-block",
          fontSize: "12px",
          fontWeight: "500",
          margin: "3px 0 0 20px"
        }}
      >
        <a style={{ color: "#408BFC" }} href={location.pathname + location.search.replace("applied", "rejected")}>
          VIEW REJECTED
        </a>
      </span>
    </>
  );

  const icon = useMemo(() => {
    switch (type) {
      default:
      case "default":
        return image;
      case "success":
        return <SuccessIcon style={{ width: "20px", height: "20px" }} />;
      case "error":
        return <img src={error} alt="error" />;
      case "loading":
        return <Loading className="spin" />;
    }
  }, [type]);

  const toastContent = () => {
    return (
      <div className="fz-toast__container">
        <span className="fz-toast__icon">{isMovingCandidates ? <Loading className="spin" /> : <>{icon}</>}</span>
        <div className="fz-toast__content">{message}</div>
      </div>
    );
  };

  useEffect(() => {
    if (message)
      toast.update(Toast, {
        render: toastContent,
        type: toastType,
        closeButton: reject ? <RejectButton /> : <CloseButton />,
        className: isBigMessage ? "Toastify__toast_big" : ""
      });
  }, [toastContent, toastType, buttons]);

  return <>{children}</>;
};

FlashMessage.propTypes = {
  /** `type` property defines type of flash image */
  type: PropTypes.oneOf(["default", "success", "error", "loading"]),
  /** alternative buttons parsed as JSX.Element */
  buttons: PropTypes.element,
  /** alternative image parsed as JSX.Element */
  image: PropTypes.element,
  /** additional styling, extends and overwrites existing one */
  extraCss: PropTypes.string
};

export default FlashMessage;
