import styled from "styled-components";

export const SortingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const SortingContainerDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  span {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const SortingSelectContainer = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .fz-simple-select__control {
    border-radius: 8px;
    border: 1px solid #c5c5c5;
    background: #fff;
    color: #333;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 9px 16px;
    width: 250px;
  }

  .fz-simple-select .fz-simple-select__single-value {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: left;
  }

  .fz-simple-select .fz-simple-select__control:hover,
  .fz-simple-select .fz-simple-select__control:focus,
  .fz-simple-select .fz-simple-select__control--is-focused {
    background: #fff;
  }

  .fz-simple-select_flat .fz-simple-select__control:hover .fz-simple-select__single-value,
  .fz-simple-select_flat .fz-simple-select__control:focus .fz-simple-select__single-value,
  .fz-simple-select_flat .fz-simple-select__control--is-focused .fz-simple-select__single-value {
    color: #333;
  }

  .fz-simple-select .fz-simple-select__indicator {
    color: #333;
    margin-left: 6px;
  }

  .fz-simple-select__menu {
    width: 100%;
    max-width: 100%;
  }

  .fz-simple-select .fz-simple-select__option {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .fz-simple-select .fz-simple-select__option--is-focused,
  .fz-simple-select .fz-simple-select__option:hover {
    background: transparent;
  }

  .fz-simple-select .fz-simple-select__option--is-selected {
    color: #2496f5;
    font-size: 14px;
    font-weight: 500;
  }
`;
