import React, { useState } from "react";

import Pane from "b2b/components/Pane";
import TalentTest from "b2b/components/TalentTest";
import Subscription from "b2b/components/Subscription";
import { ShowMore } from "./styled";
import { Content, Description, ImageContent } from "../../../TalentCvScreen/components/PersonalityTalent/styled";
import emptyTalent from "../../../../../../assets/images/b2b/empty-talent.png";

const UserCvTalentProfile = ({ talent = [], name, isShowTalentTests }) => {
  const [showMore, setShowMore] = useState(false);
  const talents = showMore ? talent : talent.slice(0, 5);
  const isNotEmpty = talent.length > 0;
  const toggleShowMore = () => {
    setShowMore(prev => !prev);
  };

  const subscriptionClassName = talent.length > 5 ? "fz-pane__container_talent-profile" : "fz-pane__container_case-two";

  return (
    <>
      {isNotEmpty && isShowTalentTests ? (
        <Pane>
          <Subscription
            className={`fz-pane__container ${subscriptionClassName}`}
            permission="aptitude_test"
            element="Talent test results"
          >
            {() => (
              <>
                <h2 className="fz-heading-title">Talent profile</h2>
                {talents.map(item => (
                  <TalentTest {...item} key={`talent_${item.test_id}`} />
                ))}
                {talent.length > 5 && (
                  <ShowMore onClick={toggleShowMore}>
                    {showMore ? "Show less" : <>{`Show all ${talent.length} tests`}</>}
                  </ShowMore>
                )}
              </>
            )}
          </Subscription>
        </Pane>
      ) : null}

      {isNotEmpty && !isShowTalentTests ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Talent profile</h2>
            <Content>
              <ImageContent>
                <img src={emptyTalent} alt="empty" />
              </ImageContent>
              <Description>This campaign doesn`t require Talent tests</Description>
            </Content>
          </div>
        </div>
      ) : null}

      {!isNotEmpty ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Talent profile</h2>
            <Content>
              <ImageContent>
                <img src={emptyTalent} alt="empty" />
              </ImageContent>
              <Description>{name} hasn’t taken any tests yet</Description>
            </Content>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserCvTalentProfile;
