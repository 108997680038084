import styled from "styled-components";

export const Modal = styled.div`
  width: 100%;
  max-width: 450px;
  margin-top: -32px;

  @media screen and (min-width: 1008px) {
    max-width: 590px;
    width: 590px;
  }
`;

export const Title = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  margin: 0 0 24px 0;
`;

export const Description = styled.span`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 8px;
  display: block;
  margin: 0 0 24px 0;
`;

export const Actions = styled.div`
  padding-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  position: relative;

  &:before {
    content: "";
    width: calc(100% + 52px);
    height: 1px;
    position: absolute;
    top: 0;
    left: -26px;
    background-color: #eee;
  }
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
`;

export const Delete = styled.button`
  background: #eb3737;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  padding: 10px 40px;
`;

export const Container = styled.div`
  max-height: 440px;
  overflow-y: auto;
`;
