import React, { useEffect, useState } from "react";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import cn from "classnames";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Main } from "./styled";

const RichTextTextarea = ({
  description,
  onChange,
  isFieldFocus,
  area,
  placeholder = "Share your thoughts",
  maxLength
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEdited, setIsEdited] = useState(false);
  const textHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  const text = convertToRaw(editorState.getCurrentContent());
  const errors = validate(text.blocks[0].text);

  const handleChange = e => {
    setEditorState(e);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  useEffect(() => {
    if (description) {
      const blocksFromHTML = convertFromHTML(description);

      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
      const editorStateDefault = EditorState.createWithContent(contentState);

      setEditorState(editorStateDefault);
    }
  }, []);

  const handleBeforeInput = () => {
    if (maxLength) {
      if (editorState.getCurrentContent().getPlainText().length >= maxLength + 4) {
        return "handled";
      }

      return "not-handled";
    }
  };

  const handlePastedText = val => {
    if (maxLength) {
      const textLength = editorState.getCurrentContent().getPlainText().length;
      return val.length + textLength - 8 >= maxLength;
    }
  };

  const addLink = event => {
    event.preventDefault();

    window.setTimeout(() => {
      document.querySelector(".rdw-link-modal-label[for='linkTitle']").innerHTML = "Text to display";
      document.querySelector(".rdw-link-modal-label[for='linkTarget']").innerHTML = "URL";
      document.querySelector(".rdw-link-modal-btn").innerHTML = "Save";
      document.querySelector(".rdw-link-modal-target-option span").innerHTML = "Open link in new tab";
    }, 20);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (textHtml && isEdited) {
        onChange(textHtml);
      }
    }, 100);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [textHtml]);

  const blockStyleFn = () => {
    let textBlock;

    if (isEdited) {
      textBlock = textHtml;
    } else {
      textBlock = description;
    }

    let alignment = "left";
    if (textBlock.includes("text-align:right;")) {
      alignment = "right";
    } else if (textBlock.includes("text-align:center;")) {
      alignment = "center";
    } else if (textBlock.includes("text-align:justify;")) {
      alignment = "justify";
    }
    return `editor-alignment-${alignment} editor-content`;
  };

  useEffect(() => {
    const linkButton = document.querySelectorAll(
      ".rdw-editor-wrapper .rdw-link-wrapper .rdw-option-wrapper[title='Link']"
    )[0];

    linkButton.addEventListener("click", function (event) {
      addLink(event);
    });
  }, []);

  return (
    <Main>
      <Editor
        editorState={editorState}
        editorClassName={cn("editor-class", errors.bioHasLinks ? "editor-class_error" : "")}
        onEditorStateChange={handleChange}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        placeholder={placeholder}
        blockStyleFn={blockStyleFn}
        focus={isFieldFocus}
        ref={area}
      />
    </Main>
  );
};

const validate = values => {
  const errors = {};

  const URLRegex =
    /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@'/?]*)?)(\s+|$)/;
  if (values.match(URLRegex)) {
    errors.bioHasLinks = true;
  }

  return errors;
};

export default RichTextTextarea;
