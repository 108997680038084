import React from "react";
import { SimpleSelect } from "components/Selects";
import { thousandSeparator } from "utils/helpers";
import { isBaronaBrand } from "../../../contexts/init";

const TalentListTools = ({
  talentCount = 0,
  applyFilter,
  // applyParams,
  // per_page = 10,
  currentSortby = "created_at",
  isOpenApplication
}) => {
  const isBarona = isBaronaBrand();

  const sorting = [
    { id: 1, label: "Sort by date", value: "created_at" },
    { id: 2, label: "Total match", value: "total_match" },
    { id: 3, label: "CV match", value: "cv_match" },
    { id: 4, label: "Talent match", value: "talent_match" },
    { id: 5, label: "Relevant experience", value: "current_position_experience" },
    { id: 6, label: "Age", value: "age" },
    { id: 7, label: "Comment count", value: "comments_count" }
  ];

  const defaultSortingOption = isBarona
    ? sorting.filter(item => item.value !== "age")
    : sorting.find(({ value }) => value === currentSortby);

  return (
    <div className="fz-filter-bar pt-0">
      <div className="fz-filter-bar__main">
        <div className="fz-filter-bar__cell">
          <h5 className="fz-text fz-text_normal fz-text-color__grey900 mr-1">
            <b>{thousandSeparator(talentCount)}</b> candidates
          </h5>
        </div>
        {isOpenApplication && (
          <SimpleSelect
            selectOptions={isBarona ? sorting.filter(item => item.value !== "age") : sorting}
            role="sort_by"
            getFilteredList={applyFilter}
            defaultOption={defaultSortingOption}
            flatView
          />
        )}
      </div>
      {/* hide for this ticket https://app.shortcut.com/fuzu/story/3099/b2b-fix-candidate-profile-page-navigation
        <div className="fz-filter-bar__additional">
        <div className="fz-filter-bar__cell">
          <h5 className="fz-text fz-text_normal fz-text-color__grey900 mr-1">Show entries</h5>
          <SimpleSelect
            getFilteredList={applyParams}
            value={{ label: per_page, value: per_page }}
            role="per_page"
            selectOptions={[
              { label: "10", value: 10 },
              { label: "20", value: 20 },
              { label: "50", value: 50 },
              { label: "100", value: 100 }
            ]}
            flatView
          />
        </div>
      </div>
      */}
    </div>
  );
};

export default TalentListTools;
