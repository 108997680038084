import styled from "styled-components";

export const AlreadyShareContent = styled.div`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  position: relative;
  margin-top: 20px;
`;

export const AlreadyShareTitleContent = styled.h5`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: #408bfc;
  }
`;

export const AlreadyShareBanner = styled.div`
  background: #f3f8ff;
  border-radius: 6px;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  margin-bottom: 8px;

  span {
    color: #333333;
    font-weight: 500;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AlreadyShareCopyField = styled.div`
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  width: 224px;
  flex: none;
  padding: 6px 8px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ButtonCopy = styled.button`
  width: 32px;
  height: 32px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;

  svg {
    fill: #666666;
    width: 16px;
    height: 16px;
  }
`;

export const ButtonEdit = styled.button`
  width: 32px;
  height: 32px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;

  svg {
    fill: #666666;
    width: 16px;
    height: 16px;
  }
`;

export const EditContent = styled.div`
  border: 1px solid #c5c5c5;
  box-shadow: 0px 5px 15px 3px rgba(33, 33, 33, 0.05);
  border-radius: 8px;
  position: absolute;
  right: 16px;
  bottom: -98px;
  background-color: #fff;
`;

export const EditShareButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  padding: 16px 12px;
  border-bottom: 1px solid #eeeeee;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;

  svg {
    width: 16px;
    height: 16px;
    fill: #666666;
  }
`;

export const RemoveShareButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #eb3737;
  padding: 16px 12px;
  background-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: #eb3737;
  }
`;

export const TooltipText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #f3f3f3;
  width: 240px;
  padding: 3px;
`;
