import styled from "styled-components";

export const SelectPart = styled.span`
  border-radius: 2px;
  background: #fff;
  height: 2px;
  width: 16px;
  top: 10px;
  left: 4px;
`;
