import React, { useState, useEffect } from "react";
import cn from "classnames";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const FilterExpander = ({ isFilterOpen, expandHeight = 0, sectionTitle, children }) => {
  const [isOpen, setOpen] = useState(isFilterOpen);
  const [maxHeight, setMaxHeight] = useState("none");

  useEffect(() => {
    setOpen(isFilterOpen);
    if (!isFilterOpen && !isOpen) {
      setMaxHeight("auto");
    }
  }, [isFilterOpen, expandHeight]);

  return (
    <>
      <div className="fz-filter-section__header">
        <h4 className="fz-filter-section__title">{sectionTitle}</h4>
        <div
          role="button"
          tabIndex={-1}
          className={cn("fz-square-btn", "fz-square-btn_sm")}
          onClick={() => setOpen(!isOpen)}
        >
          {isOpen ? (
            <MdKeyboardArrowUp className="fz-square-btn__icon m-0" />
          ) : (
            <MdKeyboardArrowDown className="fz-square-btn__icon m-0" />
          )}
        </div>
      </div>

      <div className="fz-filter-section__container">
        <div
          className={cn(
            "fz-filter-section__content fz-filter-section__content_hide",
            isOpen ? "fz-filter-section__content_show" : ""
          )}
          style={{ maxHeight }}
          onClickCapture={() => {
            if (!isOpen) setOpen(true);
          }}
        >
          {children(isOpen)}
        </div>
      </div>
    </>
  );
};

export default FilterExpander;
