import React, { useState, useEffect, useMemo, useContext } from "react";
import parse from "html-react-parser";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import {
  Modal,
  Title,
  Actions,
  Delete,
  Edit,
  Content,
  RightSide,
  Label,
  Description,
  LeftSide,
  InfoItem,
  InfoLabel,
  InfoDescription,
  InfoYouLabel,
  InfoStatus,
  ArrowLeft,
  ArrowRight
} from "./styled";
import { formatDate } from "../../../../utils/helpers";
import AppContext from "../../../../../contexts/init";
import { useModal } from "../../../../../utils/hooks";
import InterviewDelete from "../InterviewDelete";

const InterviewPreview = ({
  close,
  deleteInterviewNote,
  candidateId,
  openEditInterviewModal,
  interviewNotes,
  interviewIndex,
  isSharedCandidates
}) => {
  const [activeInterviewIndex, setActiveInterviewIndex] = useState();
  const handleNext = () => {
    const currentIndex = activeInterviewIndex === interviewNotes.length - 1 ? 0 : activeInterviewIndex + 1;

    setActiveInterviewIndex(currentIndex);
  };

  const handlePrev = () => {
    const currentIndex = activeInterviewIndex === 0 ? interviewNotes.length - 1 : activeInterviewIndex - 1;

    setActiveInterviewIndex(currentIndex);
  };

  const currentInterview = useMemo(() => interviewNotes[activeInterviewIndex], [interviewNotes, activeInterviewIndex]);

  useEffect(() => {
    if (!activeInterviewIndex) {
      setActiveInterviewIndex(interviewIndex);
    }
  }, []);

  return (
    <Modal>
      <ArrowLeft onClick={handlePrev}>
        <MdKeyboardArrowLeft />
      </ArrowLeft>

      {currentInterview?.id ? (
        <InterviewPreviewItem
          close={close}
          deleteInterviewNote={deleteInterviewNote}
          candidateId={candidateId}
          openEditInterviewModal={openEditInterviewModal}
          interview={currentInterview}
          isSharedCandidates={isSharedCandidates}
        />
      ) : null}

      <ArrowRight onClick={handleNext}>
        <MdKeyboardArrowRight />
      </ArrowRight>
    </Modal>
  );
};

const InterviewPreviewItem = ({
  interview,
  deleteInterviewNote,
  openEditInterviewModal,
  candidateId,
  isSharedCandidates,
  close
}) => {
  const { current_user: currentUser } = useContext(AppContext);
  const {
    title,
    body,
    created_at: createdDate,
    updated_at: updatedDate,
    position_phase: status,
    owner: { avatar, name, id: ownerId },
    id: noteId
  } = interview;
  const isOwner = currentUser?.id === ownerId;

  const handleRemove = () => {
    deleteInterviewNote(candidateId, noteId).then(() => {
      close();
    });
  };

  const openInterviewDelete = useModal(<InterviewDelete handleRemove={handleRemove} />, "");

  const handleOpenEditInterviewModal = () => {
    close();
    openEditInterviewModal(noteId, true, candidateId);
  };

  return (
    <>
      <Title>{title}</Title>

      <Content $marginBottom={isSharedCandidates ? "0px" : "24px"}>
        <LeftSide>
          <Label>Note</Label>
          <Description>{parse(body)}</Description>
        </LeftSide>
        <RightSide>
          <div>
            <InfoItem>
              <InfoLabel>Written by</InfoLabel>
              <InfoDescription>
                <img src={avatar} alt="" />
                {name}
                {isOwner && <InfoYouLabel>(you)</InfoYouLabel>}
              </InfoDescription>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Created at stage</InfoLabel>
              <InfoStatus>{status}</InfoStatus>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Date & time</InfoLabel>
              <InfoDescription>{formatDate(new Date(createdDate))}</InfoDescription>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Last updated</InfoLabel>
              <InfoDescription>{formatDate(new Date(updatedDate))}</InfoDescription>
            </InfoItem>
          </div>
        </RightSide>
      </Content>
      {isOwner && !isSharedCandidates && (
        <Actions>
          <Delete onClick={openInterviewDelete}>Delete</Delete>
          <Edit onClick={handleOpenEditInterviewModal}>Edit</Edit>
        </Actions>
      )}
    </>
  );
};

export default InterviewPreview;
