import React, { useContext } from "react";
import cn from "classnames";

import InitContext from "../../../contexts/init";

const StatusFiltersBarOption = ({ scope: { label = "", value = "", count = 0 } = {}, currentScope, onChange }) => {
  const initContext = useContext(InitContext);
  const globalBrandName =
    initContext && initContext?.global_brand ? initContext.global_brand?.name?.toLowerCase() : null;

  const onChangeHandler = () => {
    onChange();
  };

  const badgeClassNames = cn("fz-badge", {
    "fz-badge_light-gray": value !== currentScope
  });

  return (
    <div className="fz-flat-checkbox">
      <input
        checked={String(value) === String(currentScope)}
        className="fz-flat-checkbox__control"
        id={value}
        name="filterGroup"
        type="radio"
        onChange={onChangeHandler}
      />
      <label
        className={cn("fz-flat-checkbox__label", `fz-flat-checkbox__label_${globalBrandName}`)}
        htmlFor={value}
        role="radio"
        aria-checked={String(value) === String(currentScope)}
        tabIndex="0"
      >
        {label}
        {count > 0 && <div className={badgeClassNames}>{count}</div>}
      </label>
    </div>
  );
};

export default StatusFiltersBarOption;
