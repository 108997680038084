import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import parse from "html-react-parser";
import { useHistory, Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { MdLock, MdLocationOn } from "react-icons/md";
import { InfoCircle, Note } from "@styled-icons/boxicons-regular";

import ProfileAvatar from "components/ProfileAvatar";
import Tooltip from "components/Tooltip";
import Placeholder from "./Placeholder";
import {
  ExternalCandidateLabel,
  TooltipText,
  AvailableLabel,
  Interview,
  InterviewCount,
  InterviewViewButton
} from "./styled";
import { getCompanyName } from "../../../contexts/init";

const UserCard = ({
  name = "",
  age = 0,
  country = "",
  applied_at,
  avatar,
  current_position_title = "",
  current_position_employer = "",
  viewed = false,
  id = 0,
  Badge = <></>,
  campaign = 0,
  profile_unlocked = false,
  new: isNew,
  selected = false,
  Checkarea = <></>,
  UserAvatar = <ProfileAvatar avatar={avatar} />,
  Details = <></>,
  Actions = <></>,
  Footer = <></>,
  to = {
    pathname: `/jobs/${campaign}/candidates/${id}`
  },
  onMouseEnter,
  onMouseLeave,
  removed = false,
  handleStopUpdatePage,
  isExternalCandidate,
  canSwitchCandidate,
  email,
  interviewNotesCount,
  showInterviewNotesModal,
  setKeepPageInfo,
  isSharedCandidates
}) => {
  const [isRemoved, setRemoved] = useState(false);
  const [content, setContent] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (removed && !isRemoved) {
      setRemoved(true);
    }
  }, [removed]);

  const Name = useMemo(() => (name ? parse(name) : ""), [name]);

  const handleOpenPage = () => {
    history.push(to);
    if (handleStopUpdatePage) {
      handleStopUpdatePage();
    }

    if (setKeepPageInfo) {
      setKeepPageInfo();
    }
  };

  return (
    <CSSTransition in={!isRemoved} timeout={500} onExited={() => setContent(false)} classNames="panel">
      {content ? (
        <div
          className={cn(
            "fz-panel fz-panel_indent fadeInFromBottom",
            { "fz-panel_visited": viewed },
            { "fz-panel_highlighted": isNew },
            { "fz-panel_checked": selected }
          )}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            className={cn(
              "fz-panel__container",
              isExternalCandidate ? "fz-panel__container_external-candidate" : "pt-3"
            )}
          >
            {isExternalCandidate ? (
              <>
                <ExternalCandidateLabel>
                  External candidate
                  <Tooltip
                    tooltipPosition={["right center"]}
                    tooltipContent={
                      <TooltipText>
                        Found on an external source (e.g., LinkedIn) and manually added to campaign
                      </TooltipText>
                    }
                  >
                    <InfoCircle />
                  </Tooltip>
                  {canSwitchCandidate ? <AvailableLabel>{getCompanyName()} profile available</AvailableLabel> : null}
                </ExternalCandidateLabel>
              </>
            ) : null}

            {Checkarea}

            <div className="fz-applicant">
              <Link to={to} className="fz-applicant__avatar-content" onClick={setKeepPageInfo}>
                {UserAvatar}
              </Link>

              <div className="fz-applicant__content">
                <span className="fz-applicant__name">
                  {Name}
                  {!profile_unlocked && <MdLock />}
                  {Badge}
                </span>

                <div className="fz-info-text" onClick={handleOpenPage} tabIndex={-1} role="button">
                  {current_position_title ||
                    (current_position_employer && (
                      <span className="fz-info-text__text fz-info-text__text_position">
                        {current_position_title} ${current_position_title && current_position_employer ? " at " : " "}
                        {current_position_employer}
                      </span>
                    ))}

                  {country && (
                    <div className="fz-info-text__content">
                      <span className="fz-info-text__text fz-info-text__text_light fz-info-text__text_icon">
                        <MdLocationOn />
                        {country}
                      </span>

                      {!isExternalCandidate && !isSharedCandidates && age ? (
                        <>
                          <span className="fz-info-text__separator">•</span>
                          <span className="fz-info-text__text">{age} years</span>
                        </>
                      ) : null}
                    </div>
                  )}

                  {applied_at && (
                    <>
                      <span className="fz-info-text__separator">•</span>
                      <span className="fz-info-text__text">
                        Applied on{" "}
                        {new Date(applied_at).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric"
                        })}
                      </span>
                    </>
                  )}

                  {isExternalCandidate && email && <div className="fz-info-text__text">{email}</div>}
                </div>

                <div className="fz-applicant__details" onClick={handleOpenPage} tabIndex={-1} role="button">
                  {Details}
                </div>

                {interviewNotesCount ? (
                  <Interview>
                    <InterviewCount>
                      <Note />
                      {`${interviewNotesCount} Interview notes`}
                    </InterviewCount>
                    <InterviewViewButton onClick={showInterviewNotesModal}>View</InterviewViewButton>
                  </Interview>
                ) : null}
              </div>

              <div className="fz-applicant__actions fz-applicant__actions_blur-side">{Actions}</div>
            </div>
          </div>

          {Footer}
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
};

UserCard.Placeholder = Placeholder;

export default UserCard;
