import React, { useMemo } from "react";

import ModalConsumer from "contexts/modal";

import Layout from "../../../../components/Layout";
import ApplicantCard from "../ApplicantCard";

const RenderCandidates = ({
  candidates = [],
  loading,
  perPage,
  page,
  select,
  selectedCandidates,
  scope = "",
  showSaveModalContent,
  appliedFiltersScope,
  unlockUser,
  handleInviteToApply,
  getActiveCompanies,
  filters
}) => {
  const placeholders = useMemo(() => [...Array(Number(perPage)).keys()], [perPage]);

  const empty = useMemo(() => {
    return {
      title: "You have no search candidates yet.",
      subtitle: "New search candidates will appear here"
    };
  }, []);

  const handleSelection = id => {
    select(prev => (prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]));
  };

  return (
    <>
      {loading ? (
        placeholders.map(key => <ApplicantCard.Placeholder key={`jb_p${key}`} />)
      ) : candidates.length ? (
        <ModalConsumer>
          {({ toggle }) =>
            candidates.map((candidate, index) => (
              <ApplicantCard
                {...candidate?.user}
                {...candidate}
                candidate={candidate}
                key={`${candidate?.name}-${candidate.id}`}
                scope={scope}
                index={index + 1 + 10 * (page - 1)}
                selected={selectedCandidates.includes(candidate.id)}
                selectedTalents={selectedCandidates.includes(candidate.id)}
                select={() => handleSelection(candidate.id)}
                showSaveModalContent={() => showSaveModalContent(toggle, candidate)}
                appliedFiltersScope={appliedFiltersScope}
                unlockUser={unlockUser}
                handleInviteToApply={handleInviteToApply}
                userId={candidate.id}
                getActiveCompanies={getActiveCompanies}
                filters={filters}
              />
            ))
          }
        </ModalConsumer>
      ) : (
        <Layout.EmptyState showImage title={empty.title} subtitle={empty.subtitle} />
      )}
    </>
  );
};

export default RenderCandidates;
