import React from "react";

import { MdTurnedIn } from "react-icons/md";
import TextExpander from "../TextExpander";

const UserCvEducation = ({ education = [], languages = [] }) => {
  const year = str => new Date(str).getFullYear();

  return (
    <div className="fz-pane">
      <div className="fz-pane__container fz-pane__container_case-two">
        <div className="fz-pane-group">
          {education.length > 0 && (
            <>
              <h2 className="fz-heading-title">Education</h2>
              {education.map((item, index) => (
                <div key={`edu_${item.school_name}_${index}`} className="fz-info-pane">
                  <div className="fz-info-pane__header">
                    <div className="fz-info-pane__cell">
                      <h3 className="fz-heading-subtitle">{item.degree_name}</h3>
                      <div className="fz-info-text mb-0">
                        <span className="fz-info-text__text">{item.school_name}</span>
                      </div>
                    </div>
                    <div className="fz-info-pane__cell">
                      <div className="fz-info-text mt-1 mb-0">
                        <span className="fz-info-text__text">
                          {year(item.start_date)} – {year(item.end_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="fz-info-pane__body">
                    <TextExpander minHeight="57px">
                      <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-0">
                        {item.description}
                      </p>
                    </TextExpander>
                    {item.certificate_url && (
                      <div className="fz-tag-container mt-1">
                        <a href={item.certificate_url || "#"} target="_blank" rel="noreferrer">
                          <div className="fz-tag fz-tag_md fz-tag_clickable">
                            <MdTurnedIn className="fz-tag__icon" />
                            <span className="fz-tag__text">
                              {item.school_name} {item.degree_name} degree cetrificate
                            </span>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        {languages.length > 0 && (
          <div className="fz-pane-group">
            <h2 className="fz-heading-title">Languages</h2>
            <div className="fz-info-pane">
              {languages.map(lang => (
                <div className="fz-pane_columns" key={`edu_${lang.name}`}>
                  <div className="fz-info-text">
                    <div className="fz-info-text__text">
                      <strong>{lang.name}</strong>
                    </div>
                    <span className="fz-info-text__text">Spoken: {lang.spoken_level}</span>
                    <span className="fz-info-text__separator">•</span>
                    <span className="fz-info-text__text">Written: {lang.written_level}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCvEducation;
