import React, { useState, useEffect } from "react";

import { Modal, Title, Actions, Cancel, Update, Label, Input, Error } from "./styled";

const EditFolder = ({ close, defaultFolderName, renameFolder, folderId }) => {
  const [folderName, setFolderName] = useState("");

  const handleChange = event => {
    setFolderName(event.target.value);
  };

  const isError = !folderName;

  const handleRename = () => {
    renameFolder(folderId, folderName).then(() => {
      close();
    });
  };

  useEffect(() => {
    setFolderName(defaultFolderName);
  }, [defaultFolderName]);

  return (
    <Modal>
      <Title>Edit folder</Title>

      <Label>Folder name</Label>
      <Input value={folderName} onChange={handleChange} isError={isError} />
      {isError && <Error>Please add a folder name</Error>}

      <Actions>
        <Cancel onClick={close}>Cancel</Cancel>
        <Update isDisabled={isError} onClick={handleRename}>
          Update
        </Update>
      </Actions>
    </Modal>
  );
};

export default EditFolder;
