import styled from "styled-components";

export const ExternalAddedState = styled.div`
  padding: 24px;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 48px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

export const ExternalAddedTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ExternalAddedText = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
  }
`;

export const EmptyState = styled.div`
  padding: ${({ isBigIndent }) => (isBigIndent ? "48px" : "24px")};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

export const EmptyIcon = styled.div`
  img {
    max-width: 100%;
  }
`;

export const EmptyText = styled.p`
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;
