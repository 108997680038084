import React from "react";
import { MdClose } from "react-icons/md";

import emptyPersonalty from "images/b2b/empty-personality.png";
import { Text } from "b2c/components/Core";
import { Content, ButtonClose, ButtonShortList, ImageContent } from "./styled";

const PersonalityAndTalent = ({
  isShow,
  isAssignment,
  showModalInviteCandidate,
  messageButton,
  campaignEndDate,
  formatDate,
  handleChangeTab,
  close,
  isSharedCandidates,
  firstName
}) => {
  if (!isShow) {
    return null;
  }

  const handleClose = () => {
    close();
    handleChangeTab();
  };

  const formattedDate = formatDate(campaignEndDate);

  const renderText = () => {
    if (isSharedCandidates) {
      return (
        <Text fontWeight="400" fontSize="16px" line-height="140%" textAlign="center" color="grey750" width="auto">
          {firstName} has not yet taken the personality test
        </Text>
      );
    }
    if (isAssignment) {
      return (
        <Text fontWeight="400" fontSize="13px" line-height="140%" textAlign="center" color="black200">
          You have shortlisted the candidate and requested them to complete the tests (the deadline was set to{" "}
          {formattedDate}).
          {messageButton}
          to remind them to submit their responses.
        </Text>
      );
    }
    return (
      <Text fontWeight="400" fontSize="13px" line-height="140%" textAlign="center" color="black200">
        In case of a Fast Apply campaign, only the shortlisted candidates will complete tests.
        <ButtonShortList onClick={showModalInviteCandidate}>Shortlist candidates</ButtonShortList>
        to ask them to complete test assignments.
      </Text>
    );
  };

  return (
    <Content>
      <ButtonClose type="button" onClick={handleClose} aria-label="Close">
        <MdClose style={{ width: "25px", height: "25px" }} />
      </ButtonClose>
      <ImageContent>
        <img src={emptyPersonalty} alt="empty" />
      </ImageContent>
      {renderText()}
    </Content>
  );
};

export default PersonalityAndTalent;
