import styled from "styled-components";

export const SenderContent = styled.div`
  margin: 8px 0 24px 0;
`;

export const SenderItem = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ isActive }) => (isActive ? "#408BFC" : "#c5c5c5")};
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .fz-radio__label {
    padding-left: 0;
    padding-right: 40px;
    width: 100%;

    &:before {
      left: auto;
      right: 0;
      top: 50%;
      margin-top: -12px;
    }
  }

  .fz-radio__label-icon {
    left: auto;
    right: 5px;
    top: 50%;
    margin-top: -7px;
  }
`;

export const SenderTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 4px 0;
`;

export const SenderText = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const Info = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  background: #f3f3f3;
  margin: 24px 0;

  p {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      color: #333;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #408bfc;
    flex: none;
  }
`;

export const TagContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const Tag = styled.span`
  color: ${({ isNotActive }) => (isNotActive ? "#A4A4A4" : "#fff")};
  background: ${({ isNotActive }) => (isNotActive ? "#EEE" : "#408bfc")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 8px;
`;

export const SelectContainer = styled.div`
  margin-bottom: 20px;

  .select__indicators {
    display: none;
  }

  .select__control {
    height: 48px;
  }
`;
