// todo all commented code will be used in the next phase
import React from "react";
// MdForward,
import { MdEmail, MdStar, MdRemoveRedEye } from "react-icons/md";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import ModalConsumer from "contexts/modal";
import Button from "components/Button";
import UserCvCircleMatch from "../../../../components/UserCvCircleMatch";
// import Checkbox from "../../../../components/Checkbox";
import UserCard from "../../../../components/UserCard";
import ContactOptionsModal from "../../../../components/ContactOptionsModal";
// import AddTalentToJob from "../../../../components/AddTalentToJob";
import SaveModalContent from "../../../../components/SaveModalContent";
import { theme } from "../../../../../b2c/contexts/theme";
import { candidateSourceType } from "../../../../constants/campaign";

const ApplicantCard = ({
  avatar,
  country,
  name,
  id,
  new: isNew,
  /*
  selected,
  select,
  */
  index,
  scope,
  selectedTalents,
  current_position_title: currentPositionTitle,
  current_position_employer: currentPositionEmployer,
  headhunting_folder_ids: headhuntingFolderIds,
  handleInviteToApply,
  unlockUser,
  candidate,
  userId,
  candidate_source,
  filters
  // getActiveCompanies
}) => {
  const params = filters ? `${filters}&type=${candidate_source}` : `?type=${candidate_source}`;
  const pathname = `/candidates/${id}${params}`;
  const candidatePlural = `${selectedTalents && selectedTalents.length > 1 ? "s" : ""}`;
  const history = useHistory();
  const isExternalCandidate = candidate_source === candidateSourceType.prospect;

  // const addToJob = (toggle, campaign) => {
  //   return toggle(
  //     <AddTalentToJob
  //       selectedTalents={selectedTalents.length ? selectedTalents : [id]}
  //       toggle={toggle}
  //       addToJob={campaignActive => {
  //         addToJob(toggle, campaignActive);
  //       }}
  //       campaignActive={campaign}
  //     />,
  //     `Add talent${selectedTalents.length ? "s" : ""} to job`
  //   );
  // };

  const handleViewDetail = () => {
    history.push(pathname);
  };

  return (
    <UserCard
      name={name}
      showInterviewNotesModal={() => ({})}
      country={country}
      id={id}
      profile_unlocked={candidate.profile_unlocked}
      selected={selectedTalents}
      current_position_title={currentPositionTitle}
      isExternalCandidate={candidate.candidate_source === candidateSourceType.prospect}
      canSwitchCandidate
      age={null}
      to={{
        pathname,
        index
      }}
      Badge={scope !== "all" && isNew ? <div className="fz-badge">NEW</div> : null}
      Checkarea={
        null
        /* <div className="fz-panel__checkarea">
          <Checkbox checked={selected} onCheck={select} />
        </div> */
      }
      UserAvatar={
        <div className="fz-applicant__aside">
          <UserCvCircleMatch avatar={avatar} />
        </div>
      }
      Details={
        <>
          {currentPositionEmployer ? <div className="fz-infobox__info_position">{currentPositionEmployer}</div> : <></>}
        </>
      }
      Actions={
        <ModalConsumer>
          {({ toggle }) => (
            <div className="fz-panel__actions">
              {!isExternalCandidate && (
                <>
                  <Button
                    size="sm"
                    color="gray"
                    onClick={() =>
                      toggle(
                        <SaveModalContent
                          selectedTalents={[id]}
                          inFolders={headhuntingFolderIds || []}
                          close={toggle}
                        />,
                        ""
                      )
                    }
                    icon={<MdStar />}
                    role="button"
                    tabIndex="0"
                  >
                    Save talent
                  </Button>
                  {/*
                  <Button
                    size="sm"
                    color="gray"
                    onClick={() =>
                      toggle(
                        <ContactOptionsModal
                          selectedTalents={selectedTalents.length ? selectedTalents : [id]}
                          close={toggle}
                          handleInviteToApply={() =>
                            handleInviteToApply(toggle, selectedTalents.length ? selectedTalents : [id])
                          }
                          isUnlocked={candidate.profile_unlocked}
                          unlockHandler={() => unlockUser(userId, id, true)}
                          addToJob={() => addToJob(toggle)}
                          getActiveCompanies={getActiveCompanies}
                        />,
                        `Do you also want to invite the candidate${candidatePlural} to apply?`
                      )
                    }
                    icon={<MdForward />}
                    role="button"
                    tabIndex="0"
                  >
                    Add to job
                  </Button>
                  */}

                  <Button
                    size="sm"
                    color={theme.blue}
                    onClick={() =>
                      toggle(
                        <ContactOptionsModal
                          selectedTalents={selectedTalents.length ? selectedTalents : [id]}
                          close={toggle}
                          candidate={{
                            ...candidate,
                            details: { phone_number: candidate.phone, email: candidate.email }
                          }}
                          isJobHeadhuntPage
                          isJobHeadhunt
                          messageButton={null}
                          handleInviteToApply={() =>
                            handleInviteToApply(toggle, selectedTalents.length ? selectedTalents : [id])
                          }
                          isUnlocked={candidate.profile_unlocked}
                          unlockHandler={() => unlockUser(userId, id, true)}
                          isSearchCandidates
                        />,
                        `How do you want to contact the candidate${candidatePlural}?
                        `
                      )
                    }
                    icon={<MdEmail />}
                    role="button"
                  >
                    Contact
                  </Button>

                  <div className="fz-panel__actions-separator fz-panel__actions-separator_small-indent" />
                </>
              )}
              <Button
                onClick={handleViewDetail}
                className="fz-btn_small-padding"
                size="sm"
                color="gray"
                icon={<MdRemoveRedEye />}
                role="button"
                tabIndex="0"
              >
                View Profile
              </Button>
            </div>
          )}
        </ModalConsumer>
      }
      Footer={null}
    />
  );
};

ApplicantCard.Placeholder = UserCard.Placeholder;

export default inject("CampaignsStore")(observer(ApplicantCard));
