import React from "react";
import { Plus } from "@styled-icons/boxicons-regular";
import { Add } from "./styled";

export const AddIconButton = ({ onClick }) => {
  return (
    <Add onClick={onClick}>
      <Plus />
    </Add>
  );
};
