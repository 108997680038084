import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  background: #f3f8ff;
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
`;

export const Button = styled.button`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    fill: #666666;
    width: 24px;
    height: 24px;
    margin-left: auto;
  }

  span {
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      fill: #408bfc;
      margin-left: 0;
    }
  }
`;

export const Content = styled.div`
  margin: 6px 0 0 30px;

  ul {
    padding-left: 20px;
  }

  * {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 4px;
  }
`;

export const ContentItem = styled.div`
  margin: 8px 0;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;

  span {
    color: #333;
  }
`;
