import React from "react";

const Select = ({ className = "", children, wrapperStyle, ...props }) => {
  return (
    <div className={`fz-std-select ${className}`} style={wrapperStyle}>
      <select className="fz-std-select__control" {...props}>
        {children}
      </select>
    </div>
  );
};

export default Select;
