import React from "react";
import { observer, inject } from "mobx-react";
import { MdModeEdit, MdAdd } from "react-icons/md";
import { toJS } from "mobx";

import Button from "components/Button";
import { useModal } from "utils/hooks";
import Ico1 from "images/b2b/ico.svg";
import Pane from "../../../../components/Pane";
import InfoPane from "../../../../components/InfoPane";
import Layout from "../../../../components/Layout";
import HandleContactDetailsContent from "../HandleContactDetailsContent";

const employerReducer = employer => [
  { name: "Company name", value: employer?.name },
  { name: "Company size", value: employer?.employer_size?.name },
  { name: "Company phone number", value: employer?.phone_number },
  { name: "Physical address 1", value: employer?.address?.line1 },
  { name: "Physical address 2", value: employer?.address?.line2 },
  { name: "Country", value: employer?.address?.country?.name },
  { name: "Town", value: employer?.address?.town_record?.name },
  { name: "Postal code", value: employer?.address?.post_code },
  { name: "TAX ID", value: employer?.tax_code }
];

const Details = ({ Settings: { employer }, MyJobsStore: { commonCountries, getCommonCities, commonCities } }) => {
  const openAddContactDetailsModal = useModal(
    <HandleContactDetailsContent
      commonCountries={toJS(commonCountries)}
      getCommonCities={getCommonCities}
      commonCities={toJS(commonCities)}
    />,
    "Edit contact details"
  );

  const details = employerReducer(employer);

  return (
    <Pane.Two>
      <h2 className="fz-heading-title exp mb-3">
        Contact details
        {employer.name && (
          <Button size="md" icon={<MdModeEdit />} color="yellow" onClick={openAddContactDetailsModal}>
            Edit details
          </Button>
        )}
      </h2>
      {employer.name ? (
        <InfoPane.Group gray className="mb-1">
          {details.map(item => (
            <InfoPane.Item
              key={item.name}
              className="m-0 p-2"
              customTitle={
                <div className="fz-text fz-text_md fz-text_normal fz-text-color__black m-0">{item.name}</div>
              }
              data={item.value}
            />
          ))}
        </InfoPane.Group>
      ) : (
        <Layout.EmptyState
          narrow
          showImage
          customImage={<Ico1 />}
          title="Add your company details"
          subtitle="Valid contact details should be added in order to publish open positions."
          actionButton={
            <Button icon={<MdAdd />} color="yellow" onClick={openAddContactDetailsModal}>
              Add contact details
            </Button>
          }
        />
      )}
    </Pane.Two>
  );
};

export default inject("Settings", "MyJobsStore")(observer(Details));
