import React from "react";

import withStore from "../../hocs/withStore";
import InviteToApply from "../InviteToApply";

export const InviteToApplyContent = ({
  store: {
    selectedAvailableJobForCandidate,
    getAvailableJobForCandidate,
    inviteCandidate,
    selectedEmployer,
    resetSelectedEmployer,
    companies,
    getActiveCampaigns
  },
  currentId,
  currentUserAndEmployer,
  toggle,
  talents,
  addToJob,
  selectedCandidatesIds,
  handleInviteToApply
}) => {
  return (
    <InviteToApply
      selectedCandidatesIds={selectedCandidatesIds}
      currentId={currentId}
      currentUserAndEmployer={currentUserAndEmployer}
      toggle={toggle}
      talents={talents}
      getAvailableJobForCandidate={getAvailableJobForCandidate}
      addToJob={addToJob}
      selectedAvailableJobForCandidate={selectedAvailableJobForCandidate}
      inviteCandidate={inviteCandidate}
      selectedEmployer={selectedEmployer}
      handleInviteToApply={handleInviteToApply}
      resetSelectedEmployer={resetSelectedEmployer}
      getActiveCampaigns={getActiveCampaigns}
      companies={companies}
    />
  );
};

export default withStore(InviteToApplyContent);
