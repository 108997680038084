import styled from "styled-components";

export const LogoWrapper = styled.div`
  img {
    margin: 0;
  }
`;

export const HeaderAdditionalContent = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;

  hr {
    width: 1px;
    height: 16px;
    background: #ffffff;
    margin: 0 8px;
  }
`;

export const CollaboratorsList = styled.div`
  display: flex;
  margin: 0 11px 0 3px;
  cursor: pointer;

  .fz-profile-avatar {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin: 0 -3px;
    overflow: hidden;
    border: 1px solid #666666;
  }

  .fz-profile-avatar__img {
    border-radius: 0;
  }
`;

export const ProfileEmpty = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: #408bfc;
  background-color: #e0edff;
  border: 1px solid #e0edff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -3px;
  position: relative;

  &:hover {
    background-color: #99c2ff;
    border-color: #e0edff;
  }
`;

export const GrayButton = styled.button`
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background: #eeeeee;
  border: none;
  outline: none;
  color: #333333;

  svg {
    fill: #333333;
    width: 16px;
    height: 16px;
  }
`;

export const AddCandidate = styled.button`
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  outline: none;
  background: ${({ background }) => background};
  color: ${({ color }) => color};

  svg {
    fill: ${({ color }) => color};
    width: 16px;
    height: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MoreDivider = styled.span`
  height: 1px;
  background: #eeeeee;
  width: 100%;
  margin: 8px 0;
`;

export const Share = styled.button`
  background: #f3f3f3;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
`;
