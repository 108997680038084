import styled from "styled-components";

export const BaronaLogoContainer = styled.div`
  svg {
    width: 110px;
    height: 24px;
  }

  path {
    fill: ${({ color }) => color || "#18181b"};
  }
`;
