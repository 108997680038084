import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { MdClose } from "react-icons/md";

const Tag = ({
  name,
  size,
  color,
  removable = true,
  onRemove,
  isActive,
  isHidden = false,
  onTagClick,
  rounded,
  clickable,
  dataTestId,
  isCandidateTag,
  isShowMore
}) => {
  const wrapperClassNames = cn(
    "fz-tag",
    { "fz-tag_active": isActive },
    { "fz-tag_clickable": clickable },
    { "fz-tag_rounded": rounded },
    { "fz-tag_hidden": isHidden },
    { "fz-tag_show-more": isShowMore },
    {
      [`fz-tag_${size}`]: size
    },
    {
      [`fz-tag_${color}`]: color
    },
    { "fz-tag_candidate": isCandidateTag }
  );

  return (
    <div role="button" tabIndex={-1} className={wrapperClassNames} onClick={onTagClick} data-testid={dataTestId}>
      <div className="fz-tag__text">{name}</div>
      {onRemove && isActive && removable && (
        <div className="fz-tag__remove">
          <MdClose className="fz-tag__icon" onClick={onRemove} />
        </div>
      )}
    </div>
  );
};

Tag.propTypes = {
  size: PropTypes.oneOfType([PropTypes.oneOf(["md"]), PropTypes.string]),
  color: PropTypes.oneOfType([PropTypes.oneOf(["grey100", "primary100", "accent", "highlight"]), PropTypes.string]),

  onRemove: PropTypes.func,
  onTagClick: PropTypes.func,

  isActive: PropTypes.bool,
  rounded: PropTypes.bool,
  clickable: PropTypes.bool
};

Tag.defaultProps = {
  size: "",
  color: "",
  isActive: false,
  rounded: false,
  clickable: false
};

export default Tag;
