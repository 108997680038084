import React, { useEffect } from "react";
import { MdModeEdit, MdRemoveCircleOutline, MdWarning, MdInfoOutline } from "react-icons/md";
import { FacebookSquare, LinkedinSquare, Twitter } from "@styled-icons/boxicons-logos";
import { Hourglass } from "@styled-icons/boxicons-regular";

import { promotionListStatus, promotionTypes } from "b2b/constants/campaign";
import { copyCodeToClipboard } from "utils/helpers";
import emptyImage from "../../../../../../assets/images/b2b/emptyPromotions.png";
import Tooltip from "../../../../../components/Tooltip";
import { formatDate } from "../../../../utils/helpers";
import RemovePromotionModal from "../RemovePromotion";

import {
  // ItemCompany,
  TooltipContent,
  tooltipContentStyles,
  ViewCandidateUnavailable,
  Content,
  Count,
  Title,
  Description,
  FlexHeading,
  Heading,
  ImageContent,
  Item,
  ItemDate,
  ItemRemovedBy,
  ItemFooter,
  ItemHeading,
  ItemHeadingFlex,
  ItemLabel,
  ItemName,
  ViewCandidate,
  AddInterviewButton,
  ListType,
  ShareBlock,
  Label,
  CopyLinkContent,
  CopyLinkInput,
  CopyLinkButton,
  Socials,
  SocialsFlex,
  SocialsItemFacebook,
  SocialsItemLinkedin,
  SocialsItemTwitter,
  FlexActions,
  RemovePromotion,
  PendingStatus,
  FailedStatus,
  Detail,
  DetailLabel,
  DetailInfo,
  TMTIcon,
  DUIcon,
  JOIcon
} from "./styled";
import { useModal } from "../../../../../utils/hooks";

const Promotions = ({ getIntegrations, integrations, removeIntegration, id, jobB2CPath }) => {
  const showRemoveModal = useModal(
    <RemovePromotionModal getIntegrations={getIntegrations} onRemove={removeIntegration} />,
    ""
  );

  useEffect(() => {
    getIntegrations();
  }, []);

  const handleCopy = () => {
    copyCodeToClipboard(jobB2CPath);
  };

  const renderIntegrationStatus = integration => {
    const isRemovedStatus = integration.status === promotionListStatus.removed;
    const isUpdatedStatus = integration.status === promotionListStatus.updated;

    if (isUpdatedStatus) {
      return (
        <>
          <ItemLabel>Last edited</ItemLabel>
          {integration.metadata.updated_at && (
            <ItemDate>
              {formatDate(new Date(integration.metadata.updated_at))} by{" "}
              <img src={integration.metadata.updated_by.avatar} alt="" /> {integration.metadata.updated_by.name}
            </ItemDate>
          )}
        </>
      );
    }

    if (isRemovedStatus) {
      return (
        <>
          <ItemLabel>Removed</ItemLabel>
          {integration.metadata.updated_at && (
            <ItemDate>
              {formatDate(new Date(integration.metadata.updated_at))}{" "}
              <ItemRemovedBy>{`(Removed by ${integration.metadata.created_by.name})`}</ItemRemovedBy>
            </ItemDate>
          )}
        </>
      );
    }

    return (
      <>
        <ItemLabel>Last edited</ItemLabel>
        {integration.metadata.created_at && (
          <ItemDate>
            {formatDate(new Date(integration.metadata.created_at))} by{" "}
            <img src={integration.metadata.updated_by.avatar} alt="" /> {integration.metadata.updated_by.name}
          </ItemDate>
        )}
      </>
    );
  };

  const renderPromotionIcon = type => {
    if (type === promotionTypes.tmt) {
      return <TMTIcon>TM</TMTIcon>;
    }

    if (type === promotionTypes.duunitori) {
      return <DUIcon>DT</DUIcon>;
    }

    if (type === promotionTypes.jobly) {
      return <JOIcon>JO</JOIcon>;
    }
  };

  const renderPromotionTitle = type => {
    if (type === promotionTypes.tmt) {
      return "Työmarkkinatori";
    }

    if (type === promotionTypes.duunitori) {
      return "Duunitori";
    }

    if (type === promotionTypes.jobly) {
      return "Jobly";
    }
  };

  const renderIntegrations = list => {
    return list?.length
      ? list.map(item => {
          const isRemovedStatus = item.status === promotionListStatus.removed;
          const isPendingStatus = item.status === promotionListStatus.pending;
          const isFailedStatus = item.status === promotionListStatus.failed;

          return (
            <Item item={`${item.id}-${item.metadata.campaign_id}-${item.metadata.updated_at}`}>
              {isFailedStatus && (
                <FailedStatus>
                  <MdWarning />
                  This job post was rejected by the job board. Try editing the required fields or job details and repost
                  the job.
                </FailedStatus>
              )}
              <ItemHeading>
                <ItemHeadingFlex>
                  {renderPromotionIcon(item.integration_type)}
                  <Heading>
                    <div>
                      <ItemName>{renderPromotionTitle(item.integration_type)}</ItemName>
                      {/* <ItemCompany>{item.employer_name}</ItemCompany> */}
                      <Detail>
                        {item.occupation && (
                          <div>
                            <DetailLabel>ESCO occupation</DetailLabel>
                            <DetailInfo>{item.occupation.preferred_label}</DetailInfo>
                          </div>
                        )}

                        {item.title && (
                          <div>
                            <DetailLabel>Job post title</DetailLabel>
                            <DetailInfo>{item.title}</DetailInfo>
                          </div>
                        )}
                      </Detail>
                      {item.description && (
                        <div>
                          <DetailLabel>Job summary</DetailLabel>
                          <DetailInfo>{item.description}</DetailInfo>
                        </div>
                      )}
                    </div>

                    {!isRemovedStatus ? (
                      <>
                        {item.integration_type === promotionTypes.tmt ? (
                          <FlexActions>
                            <ViewCandidate to={`/jobs/${id}/edit-promotions?id=${item.id}`}>
                              <MdModeEdit />
                              Edit
                            </ViewCandidate>
                            {/*
                        <ViewCandidate target="_blank" to={`/jobs/${item.campaign_id}/applicants/${item.id}`}>
                          View
                        </ViewCandidate>
                        */}
                            <RemovePromotion onClick={() => showRemoveModal({ id: item.id })}>
                              <MdRemoveCircleOutline />
                              Remove
                            </RemovePromotion>
                          </FlexActions>
                        ) : (
                          <FlexActions>
                            <ViewCandidateUnavailable>
                              Posted via API
                              <Tooltip
                                classNamePopup="fz-tooltip-content_modal"
                                tooltipPosition={["top right"]}
                                contentStyle={tooltipContentStyles}
                                tooltipContent={
                                  <TooltipContent>
                                    Post to this job board was added via API and cannot be edited directly in the app.
                                  </TooltipContent>
                                }
                              >
                                <MdInfoOutline />
                              </Tooltip>
                            </ViewCandidateUnavailable>

                            <RemovePromotion onClick={() => showRemoveModal({ id: item.id })}>
                              <MdRemoveCircleOutline />
                              Remove
                            </RemovePromotion>
                          </FlexActions>
                        )}
                      </>
                    ) : null}
                  </Heading>
                </ItemHeadingFlex>
              </ItemHeading>
              <ItemFooter>
                {isPendingStatus ? (
                  <PendingStatus>
                    <Hourglass />
                    Pending
                  </PendingStatus>
                ) : (
                  <div>{renderIntegrationStatus(item)}</div>
                )}
              </ItemFooter>
            </Item>
          );
        })
      : null;
  };

  const activeIntegrations = integrations?.filter(item => item.status !== promotionListStatus.removed);
  const removedIntegrations = integrations?.filter(item => item.status === promotionListStatus.removed);

  return (
    <div className="fz-page-wide-container fz-page-wide-container_indent">
      <div className="fz-container-with-sidebar">
        <div className="fz-container-with-sidebar__content">
          <div>
            <div className="fz-pane">
              <div className="fz-pane__container fz-pane__container_activity">
                <FlexHeading>
                  <div>
                    <h2 className="fz-heading-title">Promotions</h2>
                    <Count>
                      Increase your job`s visibility and reach more applicants by sharing the link or posting it on
                      multiple job boards.
                    </Count>
                  </div>

                  <AddInterviewButton $margin="0" to={`/jobs/${id}/post-promotions`}>
                    Post to job board
                  </AddInterviewButton>
                </FlexHeading>

                <ShareBlock>
                  <div>
                    <Label>Job link</Label>
                    <CopyLinkContent>
                      <CopyLinkInput value={jobB2CPath} readOnly />
                      <CopyLinkButton onClick={handleCopy}>Copy link</CopyLinkButton>
                    </CopyLinkContent>
                  </div>

                  <Socials>
                    <Label>Share on Social media</Label>

                    <SocialsFlex>
                      <SocialsItemFacebook url={jobB2CPath}>
                        <FacebookSquare /> Facebook
                      </SocialsItemFacebook>
                      <SocialsItemLinkedin url={jobB2CPath}>
                        <LinkedinSquare /> Linkedin
                      </SocialsItemLinkedin>
                      <SocialsItemTwitter url={jobB2CPath}>
                        <Twitter /> Twitter
                      </SocialsItemTwitter>
                    </SocialsFlex>
                  </Socials>
                </ShareBlock>

                {activeIntegrations?.length || removedIntegrations?.length ? (
                  <>
                    {activeIntegrations.length ? (
                      <>
                        <ListType>Active promotions</ListType>

                        {renderIntegrations(activeIntegrations)}
                      </>
                    ) : null}

                    {removedIntegrations.length ? (
                      <>
                        <ListType>Removed</ListType>

                        {renderIntegrations(removedIntegrations, true)}
                      </>
                    ) : null}
                  </>
                ) : (
                  <Content>
                    <ImageContent>
                      <img src={emptyImage} alt="empty" />
                    </ImageContent>
                    <Title>Get more candidates faster</Title>
                    <Description>
                      Expand your reach and attract top talent by posting across popular job boards
                    </Description>
                    <AddInterviewButton to={`/jobs/${id}/post-promotions`} $margin="0 auto">
                      Post to job board
                    </AddInterviewButton>
                  </Content>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
