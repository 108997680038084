import React, { useState, useEffect, useMemo } from "react";
import { MdArrowBack, MdClose, MdError, MdInfoOutline } from "react-icons/md";
import { observer } from "mobx-react";
import { Pencil } from "@styled-icons/boxicons-solid";
import { useHistory } from "react-router-dom";
import { toJS } from "mobx";

import { promotionTypes } from "b2b/constants/campaign";
import { validateEmail } from "utils/validation";
import { useQueryParams } from "utils/hooks";
import {
  // OccupationTypes,
  Container,
  Content,
  Back,
  Description,
  TilesContent,
  Tile,
  TileTitle,
  TileDescription,
  TMTIcon,
  DUIcon,
  JOIcon,
  Actions,
  Cancel,
  Button,
  PageTitle,
  DetailTitle,
  DetailSubTitle,
  DetailContent,
  DetailDescription,
  DropdownArea,
  Input,
  CheckboxContent,
  JobCreatedBanner,
  JobCreatedBannerDescription,
  JobCreatedBannerFlex,
  JobCreatedBannerIcon,
  JobCreatedBannerTitle,
  TextArea,
  ContactFlex,
  ContactLabel,
  ContactInput,
  ContactItem,
  OccupationContent,
  OccupationHeading,
  OccupationTitle,
  OccupationEdit,
  OccupationDescription,
  RemoveEmployer,
  Error,
  ErrorTitle,
  ErrorDescription,
  ErrorItem,
  ErrorFooter,
  InputIdMask,
  Info,
  InfoTitle,
  InfoDescription
} from "./styled";
import Checkbox from "../../../../components/Checkbox";
import { theme } from "../../../../../b2c/contexts/theme";
import { useModal } from "../../../../../utils/hooks";
import EscoOccupationsModal from "../EscoOccupationsModal";
import IntegrationSuccessModal from "../IntegrationSuccessModal";
import Dropdown from "../../../../../components/Selects";

const FormPromotions = ({
  id,
  getEmployerNames,
  // employerBusinessId,
  getOccupationSearch,
  getOccupationAutocomplete,
  occupationSearch,
  occupationAutocompleteList,
  integrationEmployerNames,
  integrationCompanyDetails,
  handleSubmitIntegration,
  getTMTIntegrations,
  isEditPromotion,
  integrationDetailForm,
  resetOccupation,
  integrationDetailErrors,
  resetIntegrationDetailErrors
}) => {
  const [selectedIntegration, setSelectedIntegration] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const integrationCompanyDetailsJS = toJS(integrationCompanyDetails);
  const history = useHistory();
  const queryParams = useQueryParams();

  const handleSelectIntegration = activePromotion => {
    const promotionValues = selectedIntegration.includes(activePromotion)
      ? selectedIntegration.filter(v => v !== activePromotion)
      : [...selectedIntegration, activePromotion];

    setSelectedIntegration(promotionValues);
  };

  const integrationOptions = useMemo(() => {
    const tmtOption = {
      Icon: TMTIcon,
      iconInitials: "TM",
      title: "Työmarkkinatori",
      type: promotionTypes.tmt,
      description: "An official Finnish platform linking employers with job seekers"
    };

    const duOption = isEditPromotion
      ? {}
      : {
          Icon: DUIcon,
          iconInitials: "DU",
          title: "Duunitori",
          type: promotionTypes.duunitori,
          description: "Finland’s largest job board for advertising jobs and attracting talent."
        };

    const joOption = isEditPromotion
      ? {}
      : {
          Icon: JOIcon,
          iconInitials: "JO",
          title: "Jobly",
          type: promotionTypes.jobly,
          description: "Jobly connects employers with candidates actively seeking opportunities."
        };

    return [duOption, joOption, tmtOption];
  }, []);

  const successRedirect = () => {
    history.push(`/jobs/${id}/promotions`);
  };

  const showSuccessModal = useModal(
    <IntegrationSuccessModal
      successRedirect={successRedirect}
      title={isEditPromotion ? "Post will be updated" : "Job submitted to selected boards"}
      description={
        isEditPromotion
          ? "We've sent your changes to the job board. Once they're processed, we'll update the Promotions tab and the post details. "
          : "We've prepared your job for posting. Once the job boards processes it, we'll update the Promotions tab."
      }
    />,
    ""
  );

  const [form, setForm] = useState({
    occupation: "",
    employer_business_id: "",
    employer_id: "",
    client_business_id: "",
    occupation_search_value: "",
    title: "",
    description: "",
    recruiter_first_name: "",
    recruiter_last_name: "",
    recruiter_email: "",
    anonymous: false,
    employer_company_name: ""
  });

  const handleSubmit = () => {
    setIsSubmitted(true);
    const { occupation, occupation_search_value, ...restForm } = form;

    const formBody = {
      ...restForm,
      selected_occupation_concept_uri: form.occupation.concept_uri,
      application_url: integrationCompanyDetailsJS.job_url,
      employer_company_name:
        typeof form.employer_company_name === "string" ? form.employer_company_name : form.employer_company_name.label
    };

    const integrationsBody = selectedIntegration.reduce((acc, key) => {
      acc[key] = key === promotionTypes.tmt ? formBody : {};
      return acc;
    }, {});

    handleSubmitIntegration(!isEditPromotion ? { integrations: integrationsBody } : formBody, queryParams.id)
      .then(response => {
        setIsSubmitted(false);

        if (response) {
          showSuccessModal();
        }
      })
      .catch(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    getEmployerNames();

    if (queryParams.id && isEditPromotion) {
      getTMTIntegrations(queryParams.id);
    }

    return () => {
      resetOccupation();
      resetIntegrationDetailErrors();
    };
  }, []);

  useEffect(() => {
    if (integrationDetailForm?.form_data?.recruiter_email && isEditPromotion && !form.recruiter_email) {
      const {
        // employer_business_id,
        // employer_company_name,
        // selected_occupation_concept_uri,
        title,
        anonymous,
        client_business_id,
        description,
        employer_id,
        recruiter_email,
        recruiter_first_name,
        recruiter_last_name,
        occupation_search_value
      } = integrationDetailForm.form_data;

      setForm(prev => ({
        ...prev,
        // employer_business_id: employer_business_id || "",
        // employer_company_name,
        title,
        anonymous,
        client_business_id,
        description,
        employer_id: employer_id || "",
        recruiter_email,
        recruiter_first_name,
        recruiter_last_name,
        occupation_search_value,
        occupation: integrationDetailForm.occupation
      }));
    }
  }, [integrationDetailForm?.form_data?.recruiter_email, isEditPromotion]);

  useEffect(() => {
    if (integrationCompanyDetailsJS.title && !form.title && !isEditPromotion) {
      setForm(prev => ({
        ...prev,
        title: integrationCompanyDetailsJS.title,
        recruiter_email: integrationCompanyDetailsJS.recruiter_email,
        recruiter_last_name: integrationCompanyDetailsJS.recruiter_last_name,
        recruiter_first_name: integrationCompanyDetailsJS.recruiter_first_name
      }));
    }
  }, [integrationCompanyDetailsJS.title, integrationCompanyDetailsJS.recruiter_email]);

  useEffect(() => {
    if (
      integrationDetailForm?.form_data?.employer_company_name &&
      !form.employer_company_name &&
      integrationEmployerNames?.length &&
      isEditPromotion
    ) {
      const currentEmployer = toJS(integrationEmployerNames).find(
        item => item.label === integrationDetailForm.form_data.employer_company_name
      );

      setForm(prev => ({
        ...prev,
        employer_company_name: currentEmployer,
        employer_business_id: currentEmployer.value,
        employer_id: currentEmployer.id
      }));
    }
  }, [
    integrationDetailForm?.form_data,
    integrationDetailForm?.form_data?.employer_company_name,
    integrationEmployerNames?.length,
    form.employer_company_name,
    isEditPromotion
  ]);

  const emailValidation = validateEmail(form.recruiter_email);

  const isFieldAllData = useMemo(
    () =>
      Object.keys(form)
        .filter(item => {
          return item === "description" ||
            item === "anonymous" ||
            item === "client_business_id" ||
            item === "occupation_search_value"
            ? null
            : item;
        })
        .filter(item => !form[item]).length,
    [form]
  );

  const isSelectedAdditionalPromos = useMemo(() => {
    const additionalPromos = [promotionTypes.duunitori, promotionTypes.jobly];

    return selectedIntegration.some(item => additionalPromos.includes(item));
  }, [selectedIntegration.length]);

  const isSelectedOnlyNotSupportedPromotions = useMemo(() => {
    const newPromotions = [promotionTypes.duunitori, promotionTypes.jobly];

    return selectedIntegration.length ? selectedIntegration.every(item => newPromotions.includes(item)) : false;
  }, [selectedIntegration.length]);
  const isNotFilledBusinessId = form.employer_business_id
    ? form.employer_business_id.replace("-", "").length < 8
    : true;
  const isNotFilledClientBusinessId = form.client_business_id
    ? form.client_business_id.replace("-", "").length < 8
    : true;
  const isClientIdNotRequired = form.anonymous ? true : !isNotFilledClientBusinessId;
  const isDisableSubmit =
    isFieldAllData || isSubmitted || emailValidation.error || !isClientIdNotRequired || isNotFilledBusinessId;

  const normalizeErrors = useMemo(() => {
    return Object.values(integrationDetailErrors).flat();
  }, [Object.keys(integrationDetailErrors).length]);

  return (
    <Container>
      <Back to={`/jobs/${id}/promotions`}>
        <MdArrowBack />
        Back
      </Back>
      <Content>
        <PageTitle>{isEditPromotion ? "Edit promotion" : "Post to job board"} </PageTitle>
        {!isEditPromotion && (
          <Description>
            Increase your job`s visibility and reach more candidates by publishing it across multiple job boards.
          </Description>
        )}

        <TilesContent>
          {isSelectedAdditionalPromos ? (
            <Info>
              <MdInfoOutline />
              <div>
                <InfoTitle>Before You Proceed</InfoTitle>
                <InfoDescription>
                  Duunitori and Jobly are paid job boards. Charges depend on your agreement with them and will apply
                  according to their terms. Please make sure you have the necessary approvals before proceeding.
                </InfoDescription>
              </div>
            </Info>
          ) : null}

          {integrationOptions
            .filter(item => item.title)
            .map(({ Icon, title, type, description, iconInitials }) => {
              return (
                <Tile>
                  {!isEditPromotion && (
                    <Checkbox
                      checked={selectedIntegration.includes(title)}
                      onCheck={() => handleSelectIntegration(type)}
                    />
                  )}
                  <Icon>{iconInitials}</Icon>
                  <div>
                    <TileTitle>{title}</TileTitle>
                    <TileDescription>{description}</TileDescription>
                  </div>
                </Tile>
              );
            })}
        </TilesContent>

        {normalizeErrors.length ? (
          <Error>
            <MdError />
            <div>
              <ErrorTitle>Missing job details</ErrorTitle>
              <ErrorDescription>We couldn`t post your job because some essential details are missing:</ErrorDescription>
              <ul>
                {normalizeErrors.map(item => {
                  return <ErrorItem key={item}>{item}</ErrorItem>;
                })}
              </ul>
              <ErrorFooter>
                Please update these fields in your job settings{" "}
                <a href={`/campaigns/${id}/steps/single/one`}>job settings</a>
              </ErrorFooter>
            </div>
          </Error>
        ) : null}

        {(selectedIntegration.length || isEditPromotion) && !isSelectedOnlyNotSupportedPromotions && (
          <Detail
            occupationAutocompleteList={occupationAutocompleteList}
            occupationSearch={occupationSearch}
            getOccupationSearch={getOccupationSearch}
            getOccupationAutocomplete={getOccupationAutocomplete}
            integrationEmployerNames={toJS(integrationEmployerNames)}
            integrationCompanyDetails={integrationCompanyDetailsJS}
            form={form}
            setForm={setForm}
            isEditPromotion={isEditPromotion}
          />
        )}

        <Actions>
          <Cancel to={`/jobs/${id}/promotions`}>Cancel</Cancel>
          {isSelectedOnlyNotSupportedPromotions ? (
            <Button
              $background={theme.primaryButtonBackground}
              $color={theme.primaryButtonLabelColor}
              onClick={handleSubmit}
            >
              {isEditPromotion ? "Update" : "Post"}
            </Button>
          ) : (
            <Button
              disabled={isDisableSubmit}
              $background={theme.primaryButtonBackground}
              $color={theme.primaryButtonLabelColor}
              onClick={handleSubmit}
            >
              {isEditPromotion ? "Update" : "Post"}
            </Button>
          )}
        </Actions>
      </Content>
    </Container>
  );
};

const Detail = observer(
  ({
    getOccupationAutocomplete,
    getOccupationSearch,
    occupationSearch,
    occupationAutocompleteList,
    integrationEmployerNames,
    integrationCompanyDetails,
    form,
    setForm,
    isEditPromotion
  }) => {
    const selectOccupation = (occupationValue, searchValue) => {
      setForm(prev => ({ ...prev, occupation: occupationValue, occupation_search_value: searchValue }));
    };

    const selectEmployer = event => {
      setForm(prev => ({
        ...prev,
        employer_company_name: event,
        employer_business_id: event.value,
        employer_id: event.id
      }));
    };

    const handleRemoveEmployerName = () => {
      setForm(prev => ({ ...prev, employer_company_name: "", employer_business_id: "", employer_id: "" }));
    };

    const handleChangeInput = event => {
      const { name, value } = event.target;

      setForm(prev => ({ ...prev, [name]: value }));
    };

    const handleChangeTextarea = event => {
      const { value } = event.target;

      if (value.length <= 255) {
        setForm(prev => ({ ...prev, description: value.slice(0, 255) }));
      }
    };

    const showShareShortlistedSuccess = useModal(
      <EscoOccupationsModal
        getOccupationSearch={getOccupationSearch}
        getOccupationAutocomplete={getOccupationAutocomplete}
        occupationAutocompleteList={occupationAutocompleteList}
        occupationSearch={occupationSearch}
        selectOccupation={selectOccupation}
        selectedOccupation={form.occupation}
        form={form}
        isEditPromotion={isEditPromotion}
      />,
      ""
    );

    const handleChangeHideClient = () => {
      setForm(prev => ({ ...prev, anonymous: !prev.anonymous }));
    };

    return (
      <div>
        <DetailContent>
          <DetailTitle>Complete required fields</DetailTitle>
          <DetailDescription>
            Some job boards require additional info before you can post the job. Please fill in the fields below for the
            boards you’ve selected.
          </DetailDescription>
        </DetailContent>

        <DetailContent>
          <DetailSubTitle>Occupation according to ESCO</DetailSubTitle>
          <DetailDescription $margin="0 0 16px 0">
            ESCO (European Skills, Competences, Qualifications and Occupations) is a standardized system for accurately
            categorizing job roles.
          </DetailDescription>
          {form.occupation?.id ? (
            <OccupationContent>
              <OccupationHeading>
                <div>
                  <OccupationTitle>
                    {form.occupation.code} {form.occupation.preferred_label}
                  </OccupationTitle>
                </div>
                <OccupationEdit onClick={showShareShortlistedSuccess}>
                  <Pencil />
                  Edit
                </OccupationEdit>
              </OccupationHeading>
              <OccupationDescription>{form.occupation.description}</OccupationDescription>
            </OccupationContent>
          ) : (
            <Button
              $background={theme.primaryButtonBackground}
              $color={theme.primaryButtonLabelColor}
              onClick={showShareShortlistedSuccess}
            >
              Find occupation
            </Button>
          )}
        </DetailContent>

        <DetailContent>
          <DetailSubTitle>Recruitment party / Contractor</DetailSubTitle>
          <DetailDescription $margin="0 0 8px 0">
            Select the company that handles recruitment process or employment contracts for this job. Could be the same
            as the client if you handle the entire process.
          </DetailDescription>

          <Dropdown
            selectOptions={integrationEmployerNames?.length ? integrationEmployerNames : []}
            onSelect={selectEmployer}
            value={form.employer_company_name?.label || ""}
            isSelectObject
          >
            <DropdownArea>
              {form.employer_company_name?.label || "Select employer"}{" "}
              {form.employer_company_name?.label ? (
                <RemoveEmployer onClick={handleRemoveEmployerName}>
                  <MdClose />
                </RemoveEmployer>
              ) : null}
            </DropdownArea>
          </Dropdown>
        </DetailContent>

        <DetailContent>
          <DetailSubTitle>Recruitment party/Contractor business ID</DetailSubTitle>
          <DetailDescription $margin="0 0 8px 0">
            Provide the Business ID (Y-tunnus) of the company handling the recruitment process or employment contracts
            for this job.
          </DetailDescription>
          <InputIdMask
            value={form.employer_business_id}
            name="employer_business_id"
            onChange={handleChangeInput}
            placeholder="1234567-8"
            disabled={form.employer_company_name?.value}
            mask="9999999-9"
            maskChar={null}
          />
          <CheckboxContent onClick={handleChangeHideClient} role="button" tabIndex={-1}>
            <Checkbox checked={form.anonymous} />
            <div>
              <DetailSubTitle>Hide client information in the job post</DetailSubTitle>
              <DetailDescription>
                Client name, address, and other details will not appear in the job post. When selected, the business ID
                and information from Recruitment party/Contractor will be used instead.
              </DetailDescription>
            </div>
          </CheckboxContent>
        </DetailContent>

        <DetailContent $isDisable={form.anonymous}>
          <DetailSubTitle>Client’s business ID</DetailSubTitle>
          <DetailDescription $margin="0 0 8px 0">
            Provide the business ID (Y-tunnus) of the company the job is created for. Could be the same as the
            Recruitment party/Contractor business ID if if you handle the entire process.
          </DetailDescription>

          <JobCreatedBanner>
            <JobCreatedBannerDescription>
              Job is created for
              <JobCreatedBannerFlex>
                <JobCreatedBannerIcon>
                  <img src={integrationCompanyDetails.employer_logo} alt="" />
                </JobCreatedBannerIcon>
                <JobCreatedBannerTitle>{integrationCompanyDetails.client_company_name}</JobCreatedBannerTitle>
              </JobCreatedBannerFlex>
            </JobCreatedBannerDescription>
          </JobCreatedBanner>

          <InputIdMask
            value={form.client_business_id}
            name="client_business_id"
            onChange={handleChangeInput}
            placeholder="1234567-8"
            mask="9999999-9"
            maskChar={null}
          />
        </DetailContent>

        <DetailContent>
          <DetailSubTitle>Job post title</DetailSubTitle>
          <DetailDescription>This title will be used on selected job boards. 300 characters max.</DetailDescription>

          <Input value={form.title} name="title" onChange={handleChangeInput} placeholder="" />
        </DetailContent>

        <DetailContent>
          <DetailSubTitle>
            Job summary <span>(Optional)</span>
          </DetailSubTitle>
          <DetailDescription>
            Summary will appear next to the job title on selected job boards. 255 characters max.
          </DetailDescription>
          <TextArea
            value={form.description}
            name="description"
            onChange={handleChangeTextarea}
            placeholder="Write a brief summary of the role, highlighting key values to attract applicants"
          />
        </DetailContent>

        <DetailContent>
          <DetailSubTitle>Recruiter contact details</DetailSubTitle>
          <ContactFlex>
            <ContactItem>
              <ContactLabel>First name</ContactLabel>
              <ContactInput
                value={form.recruiter_first_name}
                name="recruiter_first_name"
                onChange={handleChangeInput}
                placeholder="Job"
              />
            </ContactItem>
            <ContactItem>
              <ContactLabel>Last name</ContactLabel>
              <ContactInput
                value={form.recruiter_last_name}
                name="recruiter_last_name"
                onChange={handleChangeInput}
                placeholder="Creator"
              />
            </ContactItem>
            <ContactItem>
              <ContactLabel>Email</ContactLabel>
              <ContactInput
                value={form.recruiter_email}
                name="recruiter_email"
                onChange={handleChangeInput}
                placeholder="job.creator@acmecorp.com"
                type="email"
              />
            </ContactItem>
          </ContactFlex>
        </DetailContent>
      </div>
    );
  }
);

export default observer(FormPromotions);
