import styled from "styled-components";
import { Link } from "react-router-dom";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
`;

export const ModalPreloader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(243, 243, 243, 0.9);
  z-index: 1048;
`;

export const ModalCommentContainer = styled.div`
  max-height: 1008px;
  padding: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1049;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(51, 51, 51, 0.16);
  height: ${({ isSetHeight }) => (isSetHeight ? "90vh" : "auto")};

  .fz-comments__list {
    max-height: 520px;
    overflow-y: auto;
    margin-bottom: 24px;
  }

  .fz-comments__content {
    max-height: initial;
  }

  .fz-comments__container {
    max-height: 400px;
    margin-top: auto;
  }

  .fz-comments__textarea-content {
    max-height: initial;
  }

  .fz-type-control {
    height: 260px;
  }

  .fz-fz-comments__typearea {
    height: 70px;
  }

  @media screen and (min-width: 1008px) {
    width: 800px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalTitle = styled.div`
  color: #333;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
`;

export const ModalHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const AddComment = styled.button`
  display: flex;
  height: 40px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border-radius: 5px;
  background: #ffe140;
  width: 100%;
`;

export const GuestLabel = styled.div`
  margin-bottom: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
`;

export const GuestLabelContent = styled.span`
  border-radius: 8px;
  border: 1px solid #fff9e9;
  background: #fff9e9;
  padding: 16px;
  color: #333;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: block;
`;

export const Tabs = styled.div`
  display: flex;
  gap: 24px;
  border-bottom: 1px solid #eee;
  margin-bottom: 16px;
`;

export const TabsItem = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 8px;
  border-bottom: ${({ $isActive }) => ($isActive ? "1px solid #FFE140" : "1px solid transparent")};

  span {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Job = styled(Link)`
  display: block;
  border-radius: 6px;
  border: 1px solid #cdcdcd;
  background: #fff;
  padding: 12px 16px;
  margin-bottom: 16px;
  text-decoration: none;

  &:focus {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    border-color: #99c2ff;

    span:first-child {
      color: #408bfc;
    }
  }
`;

export const JobTitle = styled.span`
  color: #333;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 2px;
  cursor: pointer;
  display: block;
`;

export const JobDescription = styled.span`
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  display: block;
`;

export const TooltipHeading = styled.div`
  display: flex;
  align-content: center;
  gap: 8px;
  margin-bottom: 8px;

  .fz-status {
    margin: 0;
  }
`;

export const PromotionActive = styled.span`
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #c5c5c5;
  background: #fff;

  svg {
    width: 16px;
    height: 16px;
    fill: #666;
  }
`;
export const TooltipContent = styled.div`
  width: 320px;
  padding: 4px 0;
`;

export const TooltipTitle = styled.h6`
  color: #f3f3f3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 2px;
`;

export const TooltipDescription = styled.p`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 8px;
  opacity: 0.7;
`;

export const TooltipStats = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TooltipStatsItem = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Overlay = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
`;
