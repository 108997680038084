import React, { useState, useMemo, useEffect, useContext } from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import qs from "qs";

import ModalConsumer from "contexts/modal";
import ScreenStateMessage from "../../components/SimpleScreenStateMessage";
import AppContext from "../../../contexts/init";
import Heading from "./components/Heading";
import RenderCandidates from "./components/RenderCandidates";
import { getCurrentUserAndEmployer } from "../../utils/helpers";
import InviteToApplyContent from "../../components/InviteToApplyContent";
import SelectJobForInviteContent from "../../components/SelectJobForInviteContent";
import Paginator from "../../components/Paginator";
import Sorting from "./components/Sorting";
import { Container } from "./styled";

const SearchCandidates = inject("store")(
  observer(
    ({
      store: {
        candidateSearchAppliedFilters: appliedFilters,
        state,
        getSearchCandidates,
        getSearchCandidatesUserFilter,
        searchCandidatesMeta,
        searchCandidates,
        getFolders,
        unlockUser,
        candidateSearchFilters,
        getActiveCompanies
        // getActiveCampaigns,
      } = {}
    }) => {
      const { current_user: currentUser, current_employer: currentEmployer } = useContext(AppContext);
      const screenState = toJS(state);
      const { loading } = screenState;
      const [selectedTalents, setSelected] = useState([]);
      const history = useHistory();

      const handleFilterChange = filtersList => {
        getSearchCandidates({ ...filtersList, search_after: null, page: 1, per_page: 10 }, history);
      };

      const handlePageChange = value => {
        getSearchCandidates(
          { ...appliedFilters, search_after: searchCandidatesMeta.search_after, page: value.page, per_page: 10 },
          history
        );
      };

      const handleChangeSort = value => {
        getSearchCandidates(
          { ...appliedFilters, search_after: null, page: 1, per_page: 10, sort_order: value.sort_by },
          history
        );
      };

      useEffect(() => {
        const parsedFiltersState = qs.parse(history.location.search.slice(1)) || {};
        const { filters = {}, ...restFilters } = parsedFiltersState;

        getSearchCandidates({ ...filters, ...restFilters }, history);
        getSearchCandidatesUserFilter({}, { ...filters, ...restFilters });
        getFolders();
        // getActiveCompanies();
        // getActiveCampaigns(currentEmployer.id);
      }, []);

      const handleOpenSelectJobForInvite = (toggle, ids) => {
        toggle(
          <SelectJobForInviteContent
            selectedTalents={ids}
            handleInviteToApply={() => handleInviteToApply(toggle, ids)}
            handleClose={() => handleInviteToApply(toggle, ids)}
          />,
          "Select job",
          false
        );
      };

      const handleInviteToApply = (toggle, ids) => {
        toggle(
          <InviteToApplyContent
            currentId={ids}
            currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
            selectedCandidatesIds={ids}
            toggle={toggle}
            talents={searchCandidates}
            handleInviteToApply={() => handleInviteToApply(toggle, ids)}
            addToJob={() => handleOpenSelectJobForInvite(toggle, ids)}
          />,
          "Invite talent to apply"
        );
      };

      const pageCount = useMemo(() => {
        return Math.ceil(searchCandidatesMeta?.total_count / 10);
      }, [searchCandidatesMeta?.total_count]);

      return (
        <ScreenStateMessage state={screenState}>
          <Heading
            initFilters={toJS(candidateSearchFilters)}
            totalCount={searchCandidatesMeta.count}
            onChange={handleFilterChange}
            appliedFilters={appliedFilters || {}}
            getSearchCandidatesUserFilter={getSearchCandidatesUserFilter}
            autoClose={1000}
          />

          <ModalConsumer>
            {() => (
              <Container className="fz-page-container">
                <div className="fz-panel-list">
                  <Sorting
                    applyFilter={handleChangeSort}
                    foundCount={searchCandidatesMeta.total_count}
                    currentSortBy={appliedFilters?.sort || "desc"}
                  />
                  <RenderCandidates
                    candidates={toJS(searchCandidates)}
                    loading={loading}
                    perPage="10"
                    page="1"
                    select={setSelected}
                    selectedCandidates={selectedTalents}
                    scope="no-all"
                    filtered={{}}
                    showSaveModalContent={() => ({})}
                    appliedFiltersScope={{}}
                    unlockUser={unlockUser}
                    handleInviteToApply={handleInviteToApply}
                    getActiveCompanies={getActiveCompanies}
                    filters={history.location.search}
                  />
                  <Paginator pageCount={pageCount} updateList={handlePageChange} page={appliedFilters?.page} />
                </div>
              </Container>
            )}
          </ModalConsumer>
        </ScreenStateMessage>
      );
    }
  )
);

export default SearchCandidates;
