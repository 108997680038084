import React, { useState } from "react";
import Dropdown from "components/Selects";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";

import Button from "components/Button";
import Subscription from "../../../../components/Subscription";
import Layout from "../../../../components/Layout";
import CollaborationMessage from "../CollaborationMessage";
import CollaborationSearch from "../CollaborationSearch";

const CollaborationTool = ({
  colVisibility,
  visibilityOptions = [],
  collaborators,
  externalCollaborators,
  changeVisibility,
  invite,
  remove,
  isCurrentUserExternalCollaborator
}) => {
  const [inviting, setInviting] = useState();
  const [currentActiveActions, setCurrentActiveActions] = useState("");

  const Option = ({ label, icon, innerRef }) => {
    return (
      <button type="button" ref={innerRef} className="fz-dropdown-itm">
        {icon}
        <span className="fz-dropdown-itm__text">{label}</span>
      </button>
    );
  };

  const handleInvitation = (val, user) => {
    setInviting(user || val);
  };

  const renderAcceptedUsers = collaborators.map(({ id, owner, removable, accepted, email, user }, index) => (
    <div className="fz-collaborate__itm" key={`colaborate_${id}_${index}`}>
      <Layout.UserPane
        name={user?.name || email}
        info={email}
        avatar={user?.avatar}
        inactive={!accepted}
        status={owner ? "Owner" : accepted ? "" : "Pending"}
        external={false}
        setCurrentActiveActions={setCurrentActiveActions}
        currentActiveActions={currentActiveActions}
        isNewPopup
        additionalActions={
          !owner &&
          removable &&
          !isCurrentUserExternalCollaborator && (
            <div className="fz-dropdown-nav" role="listbox">
              <button type="button" className="fz-dropdown-itm" onClick={() => remove(id)} tabIndex="0">
                <MdClose className="fz-dropdown-itm__icon" />
                <span className="fz-dropdown-itm__text">Remove</span>
              </button>
            </div>
          )
        }
      />
    </div>
  ));

  return (
    <Subscription className="fz-dropdown__container" permission="collaboration" element="Collaboration tools">
      {permitted => (
        <>
          {inviting ? (
            <CollaborationMessage
              user={inviting}
              isExternal={isCurrentUserExternalCollaborator}
              cancelAction={() => setInviting(false)}
              invite={invite}
            />
          ) : (
            <>
              <div className="fz-collaborate__header">
                <h5 className="m-t-0 m-b-0">Collaborate with others</h5>
                {colVisibility.value === "invited" && !isCurrentUserExternalCollaborator && (
                  <CollaborationSearch
                    users={permitted ? externalCollaborators : []}
                    onCollaboratorSelect={handleInvitation}
                  />
                )}
              </div>
              <div className="fz-collaborate__list">{renderAcceptedUsers}</div>

              <div className="fz-collaborate__access">
                {!isCurrentUserExternalCollaborator && (
                  <Dropdown
                    className="fz-dropdown"
                    Option={Option}
                    selectOptions={visibilityOptions}
                    onSelect={changeVisibility}
                  >
                    <span className="fz-dropdown__trigger">
                      <Button.Simple icon={colVisibility.icon} color="gray" role="button" tabIndex="0">
                        {colVisibility.label}
                      </Button.Simple>
                      <MdKeyboardArrowDown className="fz-btn__icon" />
                    </span>
                  </Dropdown>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Subscription>
  );
};

export default CollaborationTool;
