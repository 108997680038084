import React, { useState } from "react";

import Button from "components/Button";
import Layout from "../../../../components/Layout";

const CollaborationMessage = ({ user, cancelAction, invite, isExternal }) => {
  const [inputValue, setInputValue] = useState("");

  const onChangeHandle = ({ target: { value } = {} }) => {
    setInputValue(value);
  };

  const handleSubmit = () => {
    invite(typeof user === "object" ? user.email : user, inputValue);
    cancelAction();
  };

  return (
    <>
      <div className="fz-collaborate__header" style={{ borderBottom: "none" }}>
        <h5 className="m-t-0 m-b-0">Collaborate with others</h5>
      </div>
      <div className="fz-collaborate-message fadeInFromBottom">
        <div className="fz-collaborate-message__itm">
          {typeof user === "object" ? (
            <Layout.UserPane name={user.name} info={user.email} avatar={user.avatar} />
          ) : (
            <div className="fz-outlined-bar">{user}</div>
          )}
        </div>
        {!isExternal && (
          <div className="fz-collaborate-message__content">
            <textarea
              name="message"
              rows="7"
              className="fz-textarea"
              placeholder="Add a message"
              onChange={onChangeHandle}
            />
          </div>
        )}
        <div className="fz-collaborate-message__actions">
          <Button size="md" color="gray" onClick={cancelAction}>
            Cancel
          </Button>
          <Button size="md" color="blue" onClick={handleSubmit}>
            Send invite
          </Button>
        </div>
      </div>
    </>
  );
};

export default CollaborationMessage;
