import React, { useState, useLayoutEffect, useMemo, useEffect } from "react";
import { observer, inject } from "mobx-react";

import Button from "components/Button";
import Input from "b2b/components/Input";
import Select from "b2b/components/Select";
import { Modal, CountryContainer } from "./styled";
import SelectSearch from "../../../Campaigns/components/SelectSearch";
import { buildOptionsByIdAndName, buildOptionsByIdCountryCodeAndName, sortByAlphabet } from "../../../../utils/helpers";
import { priorityCountries } from "../../../../constants/headhunting";

const initForm = employer => ({
  name: employer?.name,
  employer_size_id: employer?.employer_size?.id,
  tax_code: employer?.tax_code,
  phone: employer?.phone,
  geoname_id: employer?.geoname_id,
  address: {
    line1: employer?.address?.line1,
    line2: employer?.address?.line2,
    country: employer?.address?.country?.country_code,
    town_id: employer?.address?.town_record?.id,
    post_code: employer?.address?.post_code
  }
});

const HandleContactDetailsContent = ({
  Settings: { employer, sizes, codes, initializeDetails, updateContactDetails },
  MyJobsStore: { commonCities },
  commonCountries,
  getCommonCities,
  close
}) => {
  const countries = buildOptionsByIdCountryCodeAndName(commonCountries || []);
  const cities = buildOptionsByIdAndName(commonCities || []);

  const countryOptions = useMemo(() => {
    return sortByAlphabet(countries).sort(({ label }) => (priorityCountries.includes(label) ? -1 : 1));
  }, [commonCountries]);

  const [details, setForm] = useState(initForm(employer));
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const [citySearchValue, setCitySearchValue] = useState("");
  const [activeCity, setActiveCity] = useState("");
  const [countrySearchValue, setCountrySearchValue] = useState("");
  const [activeCountry, setActiveCountry] = useState("");

  const handleCitySearch = value => {
    setCitySearchValue(value);
  };

  const handleCountrySearch = value => {
    setCountrySearchValue(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (citySearchValue.length >= 3) {
        getCommonCities(activeCountry.value, citySearchValue);
      }
    }, 800);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [citySearchValue]);

  useLayoutEffect(() => {
    initializeDetails();

    if (details.address.country && !activeCountry) {
      const findCountry = countries.find(item => item.countryCode === details.address.country);
      if (findCountry) {
        setActiveCountry(findCountry);
      }
    }

    if (details.address.town_id && !activeCity && employer?.address?.town_record.name) {
      setActiveCity({ value: details.address.town_id, label: employer?.address.town_record?.name });
    }
  }, []);

  const handleUpdate = () => {
    setProcessing(true);
    updateContactDetails(details).then(error => {
      setProcessing(false);
      if (error) setErrors(error);
      else close();
    });
  };

  const setDetails = (property, value) => {
    if (errors[property]) setErrors(prev => ({ ...prev, [property]: null }));
    setForm(prev => ({ ...prev, [property]: value }));
  };

  const setAddress = (property, value) => {
    if (errors?.address?.[property]) setErrors(prev => ({ ...prev, address: { ...prev.address, [property]: null } }));
    setForm(prev => ({ ...prev, address: { ...prev.address, [property]: value } }));
  };

  const setPhone = (property, value) => {
    if (errors?.phone?.[property]) setErrors(prev => ({ ...prev, phone: { ...prev.phone, [property]: null } }));
    setForm(prev => ({ ...prev, phone: { ...prev.phone, [property]: value } }));
  };

  const renderError = (...args) => {
    let error = errors;

    for (const key of args) {
      error = error?.[key];
    }
    return (
      Array.isArray(error) && (
        <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{error.join(", ")}</div>
      )
    );
  };

  const handleChangeLocation = value => {
    setAddress("country", value.countryCode);
    setActiveCountry(value);
  };

  const handleChangeCity = value => {
    setAddress("geoname_id", value.value);
    setAddress("town_id", value.value);
    setActiveCity(value);
  };

  return (
    <Modal>
      <div className="fz-form-group">
        <label className="fz-std-label">
          Company name
          <i className="fz-text-color__grey500 ml-1">(required)</i>
        </label>
        <Input value={details?.name} onChange={value => setDetails("name", value)} />
        {renderError("name")}
      </div>

      <div className="fz-form-group">
        <label className="fz-std-label">
          Company size
          <i className="fz-text-color__grey500 ml-1">(required)</i>
        </label>
        <Select
          name="employer_size_id"
          disabled={!sizes}
          value={details.employer_size_id || "default"}
          onChange={e => setDetails(e.target?.name, e.target?.value)}
        >
          {!sizes ? (
            <option value={details.employer_size_id || "default"} disabled>
              Loading options
            </option>
          ) : (
            <>
              <option value="default" disabled>
                None selected
              </option>
              {sizes
                .slice()
                .sort((a, b) => a.order > b.order)
                .map(item => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </>
          )}
        </Select>
        {renderError("employer_size_id")}
      </div>

      <label className="fz-std-label">
        Company phone number
        <i className="fz-text-color__grey500 ml-1">(required)</i>
      </label>
      <div className="fz-form-row">
        <div className="fz-form-group">
          <Select
            name="code"
            disabled={!codes}
            value={details.phone?.code || "default"}
            onChange={e => setPhone(e.target?.name, e.target?.value)}
          >
            {!codes ? (
              <option value={details.phone.code || "default"} disabled>
                ...
              </option>
            ) : (
              <>
                <option value="default" disabled>
                  ...
                </option>
                {codes.slice().map(item => (
                  <option key={item.code} value={item.phone_code}>
                    {item.phone_code}
                  </option>
                ))}
              </>
            )}
          </Select>
        </div>
        <div className="fz-form-group">
          <Input type="tel" value={details.phone?.number} onChange={value => setPhone("number", value)} />
        </div>
      </div>
      {renderError("phone", "code")}
      {renderError("phone", "number")}

      <div className="fz-form-group">
        <label className="fz-std-label">Physical address 1</label>
        <Input value={details?.address?.line1} onChange={value => setAddress("line1", value)} />
        {renderError("address", "line1")}
      </div>

      <div className="fz-form-group">
        <label className="fz-std-label">Physical address 2</label>
        <Input value={details?.address?.line2} onChange={value => setAddress("line2", value)} />
        {renderError("address", "line2")}
      </div>

      <div className="fz-form-group">
        <label className="fz-std-label">
          Country
          <i className="fz-text-color__grey500 ml-1">(required)</i>
        </label>
        <CountryContainer>
          <SelectSearch
            value={activeCountry}
            defaultValue="All locations"
            options={countrySearchValue.length > 2 ? countryOptions : []}
            onInputChange={handleCountrySearch}
            onSelect={handleChangeLocation}
            inputValue={countrySearchValue}
            isSearchable
          />
        </CountryContainer>
        {renderError("address", "country")}
      </div>

      <div className="fz-form-group">
        <label className="fz-std-label">Town</label>

        <CountryContainer>
          <SelectSearch
            value={activeCity}
            defaultValue="City"
            options={cities}
            onInputChange={handleCitySearch}
            onSelect={handleChangeCity}
            inputValue={citySearchValue}
            noOptionsMessage={() => "Select country first"}
            isSearchable
          />
        </CountryContainer>
        {renderError("address", "town_id")}
      </div>

      <div className="fz-form-group">
        <label className="fz-std-label">Postal code</label>
        <Input value={details?.address?.post_code} onChange={value => setAddress("post_code", value)} />
        {renderError("address", "post_code")}
      </div>
      <div className="fz-form-group">
        <label className="fz-std-label">TAX ID</label>
        <Input value={details?.tax_code} onChange={value => setDetails("tax_code", value)} />
        {renderError("tax_code")}
      </div>

      <div className="fz-modal__actions mt-0">
        <Button color="gray" disabled={processing} onClick={close}>
          Cancel
        </Button>
        <Button color="blue" disabled={processing} onClick={handleUpdate}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default inject("Settings", "MyJobsStore")(observer(HandleContactDetailsContent));
