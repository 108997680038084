import React, { useContext, useState } from "react";

import Button from "components/Button";
import InitContext from "contexts/init";
import Input from "b2b/components/Input";

const BrandSlugContent = ({ slug, update, ctaText = "Save", close }) => {
  const [error, setErrors] = useState();
  const [processing, setProcessing] = useState(false);
  const [body, setBody] = useState("");

  const { base_domain } = useContext(InitContext);
  const { protocol, port } = window.location;
  const domain = `${base_domain}${port ? `:${port}` : ""}`;

  const handleDescriptionUpdate = () => {
    setProcessing(true);
    update(body).then(errors => {
      setProcessing(false);
      if (errors) setErrors(errors);
      else close();
    });
  };

  return (
    <>
      <div className="fz-form-row mb-2">
        <div className="fz-text fz-text-color__black m-b-auto m-t-auto">{`${protocol}//`}</div>
        <Input
          onFocus={() => setErrors()}
          name="slug"
          placeholder="slug"
          defaultValue={slug}
          disabled={processing}
          onChange={setBody}
        />
        <div className="fz-text fz-text-color__black m-b-auto m-t-auto">{`.${domain}`}</div>
      </div>
      {error && <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{error}</div>}
      <div className="fz-modal__actions mt-0">
        <Button color="gray" disabled={processing} onClick={close}>
          Cancel
        </Button>
        <Button color="blue" disabled={processing || !body} onClick={handleDescriptionUpdate}>
          {ctaText}
        </Button>
      </div>
    </>
  );
};

export default BrandSlugContent;
