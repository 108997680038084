import React from "react";
import withStore from "../../hocs/withStore";
import SaveModalContentProvider from "../SaveModalContentProvider";

const SaveModalContent = ({
  store: { folders = [], getFolders, addToFolder, createFolder },
  selectedTalents,
  inFolders = [],
  close
}) => {
  return (
    <SaveModalContentProvider
      selectedTalents={selectedTalents}
      folders={folders}
      getFolders={getFolders}
      addToFolder={addToFolder}
      createFolder={createFolder}
      inFolders={inFolders}
      close={close}
    />
  );
};

export default withStore(SaveModalContent);
