import { makeAutoObservable } from "mobx";
import * as API from "../utils/campaignCreation";

class CampaignCreation {
  constructor() {
    makeAutoObservable(this);
  }

  state = {
    loading: false,
    message: "",
    error: false,
    type: "",
    processing: false
  };

  selectedPositionQuestions = [];

  isShowInitialState = true;

  nextStep = "";

  setState = newState => {
    this.state = { ...this.state, ...newState };
    return this.state;
  };

  postQuestions = (id, query) => {
    this.setState({ processing: true });
    return API.postQuestions(id, query)
      .then(res => {
        this.selectedPositionQuestions = res.data.questions;
        this.nextStep = res.data.next_step;

        if (!res.data.questions.length) {
          this.isShowInitialState = false;
        }

        this.setState({ processing: false, error: null, message: res.data.success });
      })
      .catch(err => {
        const error = err?.response?.data?.error || "Something went wrong";
        this.setState({ processing: false, error });
        throw error;
      });
  };
}

export default new CampaignCreation();
