import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Dropdown from "components/Selects";
import Tag from "../Tag";
import Footer from "../FooterFilterTags";
import { Divider } from "./styled";

const FilterTags = ({
  tags,
  priority = [],
  isOpen = true,
  tagClickHandle,
  limit = 6,
  isShowPriority,
  priorityList
}) => {
  const [sortedTags, setSorted] = useState([]);
  const [currentActiveActions, setCurrentActiveActions] = useState("");

  useEffect(() => {
    const activeLength = tags.filter(tag => tag.active).length;

    const getTags = () => {
      if (isShowPriority) {
        return tags
          .sort((a, b) =>
            priorityList.includes(a.name) ? (priority.includes(b.name) ? (a.name < b.name ? -1 : 0) : -1) : 1
          )
          .slice(0, limit);
      }

      return tags
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .sort((a, b) => (priority.includes(+a.id) ? (priority.includes(+b.id) ? (a.name < b.name ? -1 : 0) : -1) : 1))
        .sort(tag => (tag.active ? -1 : 1))
        .slice(0, activeLength >= limit ? activeLength : limit);
    };

    setSorted(getTags());
  }, [tags]);

  const priorityTags = isShowPriority ? sortedTags.filter(item => priorityList.includes(item.name)) : sortedTags;
  const noPriorityTags = isShowPriority ? sortedTags.filter(item => !priorityList.includes(item.name)) : [];

  return (
    <>
      <div className="fz-tag-container">
        {priorityTags.map(({ name, active }) => (
          <Tag
            key={`tag-${name}`}
            name={name}
            clickable
            isActive={active}
            isHidden={!isOpen && !active}
            onTagClick={() => tagClickHandle(name)}
            onRemove={() => tagClickHandle(name)}
            dataTestId="tag"
          />
        ))}
        {noPriorityTags.length > 0 && isShowPriority && (
          <>
            <Divider />
            {noPriorityTags.map(({ name, active }) => (
              <Tag
                key={`tag-${name}`}
                name={name}
                clickable
                isActive={active}
                isHidden={!isOpen && !active}
                onTagClick={() => tagClickHandle(name)}
                onRemove={() => tagClickHandle(name)}
                dataTestId="tag"
              />
            ))}
          </>
        )}
      </div>
      {tags.length > sortedTags.length && isOpen && (
        <Dropdown.Searchbar
          Trigger={() => <Tag key="tag-more" name="Show more" clickable isShowMore />}
          options={tags.filter(tag => !sortedTags.includes(tag)).map(item => item.name)}
          priority={tags.filter(tag => priority.includes(+tag.id)).map(tag => tag.name)}
          onSelect={tagClickHandle}
          position="bottom left"
          currentActiveActions={currentActiveActions}
          setCurrentActiveActions={setCurrentActiveActions}
        />
      )}
    </>
  );
};

FilterTags.propTypes = {
  tags: PropTypes.array,
  tagClickHandle: PropTypes.func
};

FilterTags.defaultProps = {
  tags: [],
  tagClickHandle: () => {}
};

export default Object.assign(FilterTags, { Footer });
