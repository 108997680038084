import React from "react";
import cn from "classnames";
import UserCvCircleMatch from "../UserCvCircleMatch";

const Placeholder = ({ className }) => {
  const panelClasses = cn("fz-panel", "placeholder", className);

  return (
    <div className={panelClasses}>
      <div className="fz-panel__container pt-3">
        <div className="fz-applicant">
          <div className="fz-applicant__aside">
            <UserCvCircleMatch placeholder />
          </div>
          <div className="fz-applicant__content">
            {}
            <h4
              className="fz-panel__name"
              style={{
                width: "136px"
              }}
            />
            <div
              className="fz-panel__info"
              style={{
                width: "270px",
                marginBottom: "8px"
              }}
            />
            <div
              className="fz-panel__info"
              style={{
                width: "211px",
                marginBottom: "16px"
              }}
            />
            <div className="fz-applicant__details">
              <div
                className="fz-infobox"
                style={{
                  width: "65px"
                }}
              />
              <div
                className="fz-infobox"
                style={{
                  width: "82px"
                }}
              />
              <div
                className="fz-infobox"
                style={{
                  width: "82px"
                }}
              />
              <div
                className="fz-infobox"
                style={{
                  width: "72px"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Placeholder;
