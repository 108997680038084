import React, { useEffect, useMemo, useState, useCallback, useContext } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import AppContext from "contexts/init";
import { getMessageCandidatesPath } from "utils/urlHelpers";
import { capitalize } from "utils/helpers";
import { TABS } from "constants/tabs";
import { Button } from "b2c/components/Core";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import { UserCvNavBar, UserCvCustom } from "../../components/UserCv";
import PersonalityTab from "./components/PersonalityTab";
import ActivityTab from "./components/ActivityTab";
import InterviewsTab from "./components/InterviewsTab";
import Comments from "../../components/Comments";
import Subscription from "../../components/Subscription";
import VoteCounter from "../../components/VoteCounter";
import ApplicantCvCard from "./components/ApplicantCvCard";
import ProfileTab from "./components/ProfileTab";
import { applicationTypes, candidateSourceType, tabs } from "../../constants/campaign";
import { useModal, useQueryParams } from "../../../utils/hooks";
import InviteCandidate from "../MyJobsScreen/components/InviteCandidate";
import MessageButton from "../../components/MessageButton";
import { ButtonContainer } from "./styled";
import { Content, Description, ImageContent } from "../TalentCvScreen/components/PersonalityTalent/styled";
import emptyPersonalty from "../../../../assets/images/b2b/empty-personality.png";
import AddInterview from "./components/AddInterview";

const ApplicantCvScreen = inject("campaignsStore")(
  observer(
    ({
      campaignsStore: {
        candidates,
        navigationIds,
        state,
        currentCandidate = {},
        selectedCampaign,
        getCandidate,
        getTalent,
        updateList,
        moveCandidates,
        shortlistCandidates,
        rejectCandidates,
        removeTalent,
        moveTalents,
        shortlistTalents,
        rateCandidate,
        candidateTag,
        appliedFilters,
        createComment,
        clearAllComments,
        editComment,
        deleteComment,
        createPdfs,
        unlockUser,
        reasons,
        rejectMessage,
        filters: filterSet,
        filterCount,
        total,
        shortlistWithAssignments,
        shortlistWithAssignmentsTalents,
        getAvailableJobForCandidate,
        getCandidatePersonality,
        removeItemFromNavigationId,
        getCandidateApplicationHistory,
        applicationHistory,
        applicationHistoryMeta,
        isActiveInterviewModal,
        toggleInterviewModal,
        getInterviewNotes,
        interviewModalEditId,
        interviewNotes,
        interviewNotesMeta,
        setDefaultActiveTab,
        defaultActiveTab,
        getActiveCompanies,
        getCandidateComments,
        getCVUrl
      } = {},
      location: { index = 1 } = {}
    }) => {
      // Handling url parameters & tabs
      const [currentTab, changeTab] = useState("Profile");
      const queryParams = useQueryParams();

      const { id, tab, candidateId } = useParams();
      const history = useHistory();

      // Parsing store properties
      const candidate = useMemo(() => toJS(currentCandidate), [currentCandidate]);
      const numCandidateId = Number(candidate.candidateIdStore || candidateId);
      const campaign = useMemo(() => toJS(selectedCampaign), [selectedCampaign]);
      const appFilters = useMemo(() => toJS(appliedFilters), [appliedFilters]);
      const applicants = useMemo(() => toJS(candidates), [candidates]);
      const filters = useMemo(() => toJS(filterSet), [filterSet]);
      const showPersonalityAndTalentTab = Boolean(Object.keys(candidate.personality || {}).length);
      const backPathname = history.location.pathname.slice(0, history.location.pathname.lastIndexOf("/"));

      const {
        assignments,
        employer_name: employerName,
        title: jobTitle,
        application_type: applicationType,
        days_remaining: daysRemaining
      } = campaign;

      const loadingMessage = useMemo(
        () => `Just a moment! We are loading ${tab === TABS.APPLICANTS ? "candidate" : "talent"} details...`,
        [tab]
      );

      const { subscription } = useContext(AppContext);
      const commentingEnabled = subscription?.permissions?.commenting;

      const moveTo = (moveId, candidatesIds) => {
        const findCurrentIndex = candidatesIds?.findIndex(item => String(moveId).includes(String(item)));
        const nextElement = candidatesIds?.[findCurrentIndex + 1];
        const prevElement = candidatesIds?.[findCurrentIndex - 1];
        const isNextElement = Boolean(nextElement);
        const isOneElement = candidatesIds?.length === 1 || !candidatesIds?.length;

        removeItemFromNavigationId(moveId);

        if (isOneElement) {
          history.push(`${backPathname}`);
          return;
        }

        if (!isNextElement) {
          history.push(`${backPathname}/${prevElement}`);
          return;
        }

        history.push(`${backPathname}/${nextElement}`);
      };

      // Set up
      const getUserCV = useCallback(
        (candidateIdItem, idItem, isFirstLoading) => {
          switch (tab) {
            default:
            case "applicants":
              return getCandidate(
                candidateIdItem,
                idItem,
                commentingEnabled,
                isFirstLoading,
                queryParams?.filters?.scope
              );
            case "headhunt":
              return getTalent(candidateIdItem, idItem, tab);
          }
        },
        [tab]
      );

      useEffect(() => {
        window.scrollTo(0, 0);
      }, [candidateId]);

      useEffect(() => {
        if (defaultActiveTab) {
          changeTab(defaultActiveTab);
          setDefaultActiveTab("");
        }
      }, [defaultActiveTab]);

      useEffect(() => {
        getUserCV(candidateId, id, true);
        if (tab !== "headhunt") {
          getCandidateApplicationHistory(candidateId, 1, true, "", queryParams?.filters?.scope);
        }
      }, [candidateId]);

      useEffect(() => {
        if (candidate.name) {
          document.title = candidate.name;
        }
      }, [candidate.name]);

      const handleBeforeUnloadListener = event => {
        const confirmationMessage = "Changes you made may not be saved.";

        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      };

      const handleBeforeUnload = () => {
        if (isActiveInterviewModal) {
          window.addEventListener("beforeunload", handleBeforeUnloadListener);
        } else {
          window.removeEventListener("beforeunload", handleBeforeUnloadListener);
        }
      };

      useEffect(() => {
        handleBeforeUnload();

        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnloadListener);
        };
      }, [isActiveInterviewModal]);

      useEffect(() => {
        if (applicants && applicants.length > 0 && numCandidateId === candidate?.id) {
          if (!nextCandidate) {
            history.push({
              pathname: `/jobs/${id}/${tab}/${applicants[0]?.id}`,
              index: index + 1
            });
          }
          if (!prevCandidate) {
            history.push({
              pathname: `/jobs/${id}/${tab}/${applicants[applicants.length - 1]?.id}`,
              index: index - 1
            });
          }
        }
      }, [applicants]);

      const isFastApply = applicationTypes.fastApply === applicationType;
      const isCandidateFastApplyApplied = applicationTypes.fastApplyApplied === candidate.fast_apply_status;
      const isCandidateAssignmentsCompleted = applicationTypes.assignmentsCompleted === candidate.fast_apply_status;
      const getMessagePath = candidateIdList => getMessageCandidatesPath(id, candidateIdList);

      // Filtering scopes
      const filteredScopes = useMemo(
        () =>
          filters.scopes && filters.scopes.length > 0
            ? filters.scopes.filter(scope => scope.value !== candidate.current_phase && scope.value !== "all")
            : campaign.phases,
        [filters.scopes, campaign.phases, candidate]
      );

      // Handling next and previous candidates
      const nextCandidateOld = useMemo(
        () =>
          applicants &&
          applicants.length > 0 &&
          candidate?.id &&
          candidate.id !== applicants[applicants.length - 1].user.id
            ? applicants.some(item => item?.user?.id === candidate.id)
              ? applicants[applicants.findIndex(item => item?.user?.id === candidate?.id) + 1]?.id
              : applicants[0]?.id
            : null,
        [candidate?.id]
      );

      const nextCandidate = useMemo(() => {
        const findCurrentIndex = navigationIds?.findIndex(item => String(item) === String(numCandidateId));
        // const isScopeAll = queryParams?.filters?.scope === "all";
        const filterScopeQuery = queryParams?.filters?.scope ? `?filters[scope]=${queryParams?.filters?.scope}` : "";
        const navigationId = navigationIds?.[findCurrentIndex + 1];

        return filterScopeQuery ? `${navigationId}${filterScopeQuery}` : navigationId;
      }, [candidate?.id, navigationIds]);

      const prevCandidate = useMemo(() => {
        const findCurrentIndex = navigationIds?.findIndex(item => String(item) === String(numCandidateId));
        // const isScopeAll = queryParams?.filters?.scope === "all";
        const filterScopeQuery = queryParams?.filters?.scope ? `?filters[scope]=${queryParams?.filters?.scope}` : "";
        const navigationId = navigationIds?.[findCurrentIndex - 1];

        return filterScopeQuery ? `${navigationId}${filterScopeQuery}` : navigationId;
      }, [candidate?.id, navigationIds]);

      const prevCandidateOld = useMemo(
        () =>
          applicants && applicants.length > 0 && candidate?.id && candidate.id !== +applicants[0]?.user?.id
            ? applicants.some(item => item?.user?.id === candidate?.id)
              ? applicants[applicants.findIndex(item => item?.user?.id === candidate?.id) - 1]?.id
              : applicants[0]?.id
            : null,
        [candidate?.id]
      );

      const getNextList = () => updateList({ page: appFilters?.page + 1 });
      const getPrevList = () => updateList({ page: appFilters?.page - 1 });

      // Handle success redirection
      const handleRedirection = data => {
        updateList(data, history, "applicants");
      };

      // Candidate removal
      const handleRemoval = pendingPromise => {
        pendingPromise.then(() => {
          getUserCV(candidateId, id);
        });
      };

      const handleShortlist = () => {
        if (tab === TABS.APPLICANTS) {
          shortlistCandidates([numCandidateId]).then(() => moveTo(candidateId, navigationIds));
        } else {
          shortlistTalents([numCandidateId]).then(() => getUserCV(candidateId, id));
        }
      };

      const handleShortlistWithAssignments = () => {
        if (tab === TABS.APPLICANTS) {
          return shortlistWithAssignments;
        }

        return shortlistWithAssignmentsTalents;
      };

      const handleChangeTabToProfile = () => {
        if (currentTab !== "Profile") {
          changeTab("Profile");
        }
      };

      const getPersonality = () => {
        if (tab !== "headhunt") {
          getCandidatePersonality(candidate.candidateIdStore || candidate.id);
        }
      };

      const formatDate = date =>
        date
          ? new Date(date * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })
          : "";

      const showModalInviteCandidate = useModal(
        <InviteCandidate
          selectedCandidatesIds={[numCandidateId]}
          assignments={assignments}
          candidates={candidates}
          candidateName={candidate.name}
          employerName={employerName}
          jobTitle={jobTitle}
          left={daysRemaining}
          shortlistWithAssignments={handleShortlistWithAssignments()}
          handleRemoval={handleRemoval}
          handleRemovalShortlist={handleShortlist}
        />,
        "Shortlist and send assignments to candidates"
      );

      const messageButton = (
        <MessageButton
          isUnlocked={candidate.profile_unlocked}
          unlockHandler={() => unlockUser(candidate.id, candidateId, false, campaign.employer_id)}
          messageUrl={() => getMessagePath([numCandidateId])}
          type={tab === TABS.HEADHUNT ? "talent" : "candidate"}
          campaignEmployerId={campaign.employer_id}
          styles={{}}
          users={[
            {
              id: tab === TABS.HEADHUNT ? candidate.id : candidateId,
              name: candidate.name,
              conversation: candidate.conversation
            }
          ]}
          Component={props => (
            <Subscription.Upgrade permission="messaging">
              <ButtonContainer>
                <Button
                  fontWeight="400"
                  fontSize="13px"
                  line-height="140%"
                  textAlign="center"
                  color="secondary200"
                  theme="transparent"
                  height="20px"
                  padding="3px 4px"
                  style={{ display: "inline-block", minHeight: "20px" }}
                  {...props}
                >
                  Message candidate
                </Button>
              </ButtonContainer>
            </Subscription.Upgrade>
          )}
        />
      );

      return index !== 0 ? (
        <ScreenStateMessage
          state={state}
          loadingMessage={loadingMessage}
          scopes={filteredScopes}
          update={() => getUserCV(candidateId, id)}
          redirect={handleRedirection}
        >
          <section className="fz-page-container">
            <UserCvNavBar
              handleChangeTabToProfile={handleChangeTabToProfile}
              title={
                candidate.current_phase && tab === TABS.APPLICANTS ? capitalize(candidate.current_phase) : "Talent"
              }
              filterCount={filterCount}
              current={
                navigationIds?.length
                  ? navigationIds?.findIndex(item => String(item) === String(numCandidateId)) + 1
                  : index <= total
                    ? index
                    : total
              }
              total={total}
              clearAllComments={clearAllComments}
              commentsCount={currentCandidate.comments?.length}
              sortedBy={
                appFilters.filters &&
                appFilters.filters.sort_by &&
                capitalize(appFilters.filters.sort_by.split("_").join(" "))
              }
              candidateId={candidate?.id}
              campaign={id}
              next={navigationIds?.length ? nextCandidate : nextCandidateOld}
              prev={navigationIds?.length ? prevCandidate : prevCandidateOld}
              getNextList={getNextList}
              getPrevList={getPrevList}
              filterScope={queryParams?.filters?.scope}
            />

            <ApplicantCvCard
              tab={tab}
              {...candidate}
              id={candidateId}
              isExternalCandidate={Boolean(candidate.prospect_id)}
              reasons={reasons}
              candidate={candidate}
              systemTags={candidate.system_tags}
              campaign={campaign}
              campaignEmployerId={campaign.employer_id}
              rejectMessage={(campaignId, body) => {
                return rejectMessage(campaignId, body).then(() => moveTo(candidateId, navigationIds));
              }}
              move={phase =>
                tab === TABS.APPLICANTS
                  ? moveCandidates([numCandidateId], phase, candidate?.name).then(() =>
                      moveTo(candidateId, navigationIds)
                    )
                  : moveTalents([numCandidateId], phase).then(() => getUserCV(candidateId, id))
              }
              shortlist={handleShortlist}
              reject={() => rejectCandidates([numCandidateId]).then(() => moveTo(candidateId, navigationIds))}
              removeTalent={() =>
                removeTalent(numCandidateId).then(() =>
                  history.push({
                    pathname: `/jobs/${id}/${tab}/${nextCandidate}`,
                    index: index + 1
                  })
                )
              }
              scopes={filteredScopes}
              candidateTag={(tag, operation = "add") => candidateTag(numCandidateId, tag, operation)}
              message={() => getMessagePath([numCandidateId])}
              createPdf={() => createPdfs([numCandidateId])}
              handleTabChange={tabItem => changeTab(tabItem)}
              activeTab={currentTab}
              customCv={
                Boolean(candidate.prospect_id) && candidate.file ? candidate.file.url : candidate.user_uploaded_cv_url
              }
              showDetails={candidate.contact_details_visible}
              shortcuts={campaign.tags_available}
              profileUnlocked={candidate.profile_unlocked}
              unlockUser={unlockUser}
              showModalInviteCandidate={showModalInviteCandidate}
              isFastApply={isFastApply}
              getAvailableJobForCandidate={getAvailableJobForCandidate}
              showPersonalityAndTalentTab={showPersonalityAndTalentTab}
              isHeadhunt={tab === "headhunt"}
              getActiveCompanies={getActiveCompanies}
            />
            <div className="fz-container-with-sidebar">
              <div className="fz-container-with-sidebar__content">
                {currentTab === "Profile" && (
                  <ProfileTab
                    showModalInviteCandidate={showModalInviteCandidate}
                    tab={tab}
                    candidate={candidate}
                    isFastApply={isFastApply}
                    isAssignment={candidate.current_phase === "shortlisted"}
                    left={campaign.days_remaining}
                    messageButton={messageButton}
                    campaignEndDate={campaign.campaign_end_date}
                    formatDate={formatDate}
                    isExternalCandidate={candidate.candidate_source === candidateSourceType.prospect}
                  />
                )}
                {currentTab === "Personality and Talent" && (
                  <PersonalityTab
                    isExternalCandidate={candidate.candidate_source === candidateSourceType.prospect}
                    candidate={candidate}
                    isAssignment={candidate.current_phase === "shortlisted"}
                    showModalInviteCandidate={showModalInviteCandidate}
                    avatar={candidate.avatar}
                    personality={candidate.personality || []}
                    talent={candidate.talent || []}
                    isFastApply={isFastApply}
                    isCandidateFastApplyApplied={isCandidateFastApplyApplied}
                    isCandidateAssignmentsCompleted={isCandidateAssignmentsCompleted}
                    left={campaign.days_remaining}
                    isShowPersonality={campaign.personality_traits_any}
                    isShowTalentTests={campaign.talent_tests_any}
                    messageButton={messageButton}
                    campaignEndDate={campaign.campaign_end_date}
                    formatDate={formatDate}
                    changeTab={() => changeTab("Profile")}
                    getPersonality={getPersonality}
                    isHeadhunt={tab === "headhunt"}
                  />
                )}
                {currentTab === tabs.cv && (
                  <UserCvCustom
                    getCVUrl={getCVUrl}
                    cvUrl={
                      Boolean(candidate.prospect_id) && candidate.file
                        ? candidate.file.url
                        : candidate.user_uploaded_cv_url
                    }
                  />
                )}
                {currentTab === tabs.interviews && tab !== "headhunt" && (
                  <InterviewsTab
                    campaignName={toJS(campaign.employer_name)}
                    candidateName={candidate.name}
                    toggleInterviewModal={toggleInterviewModal}
                    isActiveInterviewModal={isActiveInterviewModal}
                    getInterviewNotes={getInterviewNotes}
                    interviewNotes={interviewNotes}
                    candidateId={candidateId}
                    interviewNotesMeta={interviewNotesMeta}
                  />
                )}

                {currentTab === tabs.activity && tab !== "headhunt" && (
                  <>
                    {candidate.candidate_source === candidateSourceType.prospect ? (
                      <div className="fz-pane">
                        <div className="fz-pane__container fz-pane__container_case-two">
                          <Content>
                            <ImageContent>
                              <img src={emptyPersonalty} alt="empty" />
                            </ImageContent>
                            <Description>
                              Application history and other activity data is not yet available for external candidates
                            </Description>
                          </Content>
                        </div>
                      </div>
                    ) : (
                      <ActivityTab
                        applicationHistory={toJS(applicationHistory)}
                        applicationHistoryMeta={toJS(applicationHistoryMeta)}
                        getCandidateApplicationHistory={(page, isShowOnlyNew, employer) =>
                          getCandidateApplicationHistory(candidateId, page, isShowOnlyNew, employer)
                        }
                        campaignName={campaign.employer_name}
                        candidateName={candidate.name}
                        isExternalCandidate={Boolean(candidate.prospect_id)}
                      />
                    )}
                  </>
                )}
              </div>

              {tab !== "headhunt" && currentTab !== tabs.activity && currentTab !== tabs.interviews && (
                <div className="fz-container-with-sidebar__aside fz-container-with-sidebar__aside_big">
                  <div className="fz-pane">
                    <div className="fz-pane__container">
                      <VoteCounter
                        fullLayout
                        id={candidate.id}
                        voting={candidate.voting}
                        onLike={() => rateCandidate(numCandidateId, "like")}
                        onDislike={() => rateCandidate(numCandidateId, "dislike")}
                      />
                    </div>
                  </div>
                  <Subscription className="" permission="commenting" element="Commenting">
                    {() => (
                      <Comments
                        comments={candidate.comments}
                        commentsMeta={candidate.commentsMeta || {}}
                        getCandidateComments={isOtherCampaign => getCandidateComments(numCandidateId, isOtherCampaign)}
                        isShowTabsOtherCampaigns
                        add={(body, visibility) => createComment(numCandidateId, body, visibility)}
                        remove={ids => deleteComment(numCandidateId, ids)}
                        editComment={(body, visibility, commentId) =>
                          editComment(numCandidateId, body, visibility, commentId)
                        }
                      />
                    )}
                  </Subscription>
                </div>
              )}
            </div>
          </section>

          {isActiveInterviewModal && (
            <AddInterview
              candidateName={candidate?.name}
              candidateId={numCandidateId}
              numberOfInterview={interviewNotes.length + 1}
              interviewItem={
                interviewNotes.length && interviewModalEditId
                  ? interviewNotes.find(item => item.id === interviewModalEditId)
                  : {}
              }
            />
          )}
        </ScreenStateMessage>
      ) : (
        <Redirect
          to={{
            pathname: `/jobs/${id}/${tab}/${candidateId}`,
            index: 1
          }}
        />
      );
    }
  )
);

export default ApplicantCvScreen;
