import React from "react";

import Button from "components/Button";
import { Styled } from "./styled";

const Footer = ({ count = 0, range = false, onReset, resetAll }) => {
  return count > 0 || range ? (
    <Styled resetAll={resetAll}>
      <div className="count">{count > 0 && <>{count} applied</>}</div>

      <Button.Simple onClick={onReset} className="reset">
        {resetAll ? "Clear all" : "Clear"}
      </Button.Simple>
    </Styled>
  ) : (
    <></>
  );
};

export default Footer;
