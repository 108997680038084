import React, { useState, useEffect, useRef } from "react";
import Button from "components/Button";

const Expander = ({ expanded = false, collapseHeight = 100, innerButtons = true, onExpand, children }) => {
  const [state, setState] = useState(false);
  const [isExpandable, setExpandable] = useState(false);

  const innerRef = useRef();

  useEffect(() => {
    setState(expanded);
  }, [expanded]);

  useEffect(() => {
    setState(isExpandable);
  }, [isExpandable]);

  useEffect(() => {
    setExpandable(innerRef.current.getBoundingClientRect().height > collapseHeight);
  }, []);

  return (
    <div
      ref={innerRef}
      style={{
        height: state ? collapseHeight + 20 : "auto",
        overflowY: "hidden",
        position: "relative"
      }}
    >
      {isExpandable && state && (
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            background: "linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 20%)",
            height: "100%",
            width: "100%"
          }}
        />
      )}
      {children}
      {isExpandable &&
        innerButtons &&
        (state ? (
          <div
            style={{
              position: "absolute",
              top: collapseHeight,
              right: 0,
              padding: "0 10px 0 40px",
              background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%)"
            }}
          >
            <Button.Simple onClick={() => (onExpand ? onExpand() : setState(false))}>...read more</Button.Simple>
          </div>
        ) : (
          <div style={{ bottom: 0, left: 0, marginTop: 16 }}>
            <Button.Simple onClick={() => (onExpand ? onExpand() : setState(true))}>Show less</Button.Simple>
          </div>
        ))}
    </div>
  );
};

export default Expander;
