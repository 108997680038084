import React, { useRef, useState } from "react";
import { MoreHorizontalOutline } from "@styled-icons/evaicons-outline";
import { MdContentCopy, MdDelete, MdEdit } from "react-icons/md";
import Tooltip from "components/Tooltip";

import { useModal, useOnclickOutside } from "utils/hooks";
import { InfoCircle } from "@styled-icons/boxicons-regular";
import {
  AlreadyShareContent,
  AlreadyShareBanner,
  AlreadyShareTitleContent,
  AlreadyShareCopyField,
  Flex,
  ButtonCopy,
  ButtonEdit,
  EditContent,
  EditShareButton,
  RemoveShareButton,
  TooltipText
} from "./styled";
import { copyCodeToClipboard } from "../../../../../utils/helpers";
import DeleteShareShortlisted from "../DeleteShareShortlisted";
import ShareShortlistedModal from "../ShareShortlisted";
import { getGuestLinkUrl, formatDate } from "../../../../utils/helpers";

const AlreadyShare = ({ activeGuestLink, deleteGuestLink, changeGuestLink }) => {
  const [active, setActive] = useState(false);
  const ref = useRef();
  const showDeleteShareShortlisted = useModal(<DeleteShareShortlisted deleteGuestLink={deleteGuestLink} />, "");
  const showShareShortlistedEdit = useModal(
    <ShareShortlistedModal
      isEdit
      activeGuestLink={activeGuestLink}
      handleSubmit={changeGuestLink}
      deleteGuestLink={deleteGuestLink}
    />,
    ""
  );

  const guestLink = getGuestLinkUrl(`share-candidates/${activeGuestLink.token}`);
  const handleCopy = () => {
    copyCodeToClipboard(guestLink, () => ({}));
  };

  const toggleActiveEdit = () => {
    setActive(prev => !prev);
  };

  const handleClose = () => {
    if (active) {
      setActive(false);
    }
  };

  useOnclickOutside(ref, () => handleClose());

  return (
    <AlreadyShareContent ref={ref}>
      <AlreadyShareTitleContent>
        Guest link
        <Tooltip
          tooltipPosition={["right center"]}
          className="already-share-tooltip"
          contentStyle={{ width: "20px" }}
          tooltipContent={<TooltipText>Share this link with the client to provide access to candidates.</TooltipText>}
        >
          <InfoCircle />
        </Tooltip>
      </AlreadyShareTitleContent>
      <AlreadyShareBanner>
        Expires on <span>{formatDate(new Date(activeGuestLink.expires_at))}</span>
      </AlreadyShareBanner>
      <Flex>
        <AlreadyShareCopyField>{guestLink}</AlreadyShareCopyField>
        <ButtonCopy onClick={handleCopy}>
          <MdContentCopy />
        </ButtonCopy>
        <ButtonEdit onClick={toggleActiveEdit}>
          <MoreHorizontalOutline />
        </ButtonEdit>
      </Flex>
      {active && (
        <EditContent>
          <EditShareButton onClick={showShareShortlistedEdit}>
            <MdEdit />
            Edit link settings
          </EditShareButton>
          <RemoveShareButton onClick={showDeleteShareShortlisted}>
            <MdDelete />
            Delete this link
          </RemoveShareButton>
        </EditContent>
      )}
    </AlreadyShareContent>
  );
};

export default AlreadyShare;
