import React from "react";
import { MdClose } from "react-icons/md";

import { Modal, Title, Text, Confirm, Flex, Close } from "./styled";
import { theme } from "../../../../../b2c/contexts/theme";

const IntegrationSuccessModal = ({ close, successRedirect, title, description, getIntegrations }) => {
  const handleClose = () => {
    if (successRedirect) {
      successRedirect();
    }

    if (getIntegrations) {
      getIntegrations();
    }
    close();
  };

  return (
    <Modal>
      <Close onClick={handleClose}>
        <MdClose />
      </Close>

      <Title>{title}</Title>

      <Text>{description}</Text>

      <Flex>
        <Confirm
          $background={theme.primaryButtonBackground}
          $color={theme.primaryButtonLabelColor}
          onClick={handleClose}
        >
          Ok
        </Confirm>
      </Flex>
    </Modal>
  );
};

export default IntegrationSuccessModal;
