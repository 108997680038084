import React from "react";

import { MdInsertDriveFile } from "react-icons/md";

const UserCvAttachments = ({ attachments = [] }) => {
  return attachments.length > 0 ? (
    <div className="fz-pane">
      <div className="fz-pane__container fz-pane__container_case-two">
        <h2 className="fz-heading-title">Attachments</h2>
        <div className="fz-info-pane">
          <div className="fz-info-pane__body">
            <div className="fz-tag-container">
              {attachments.map((item, index) => (
                <a key={`att_${index}`} href={item.url} target="_blank" rel="noreferrer">
                  <div className="fz-tag fz-tag_clickable fz-tag_md fz-tag_primary100">
                    <MdInsertDriveFile className="fz-tag__icon" />
                    <span className="fz-tag__text">{item.name}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UserCvAttachments;
