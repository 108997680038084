import styled from "styled-components";

export const Modal = styled.div`
  max-width: 600px;
  min-height: 200px;
  margin-top: -55px;

  @media screen and (min-width: 768px) {
    min-width: 672px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 24px 0;
`;

export const Text = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin: 0 0 24px 0;
`;

export const Copy = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: ${({ background }) => background || "#ffe140"};
  color: ${({ color }) => color || "#333333"};
  padding: 10px 40px;
  width: 156px;
  flex: none;
  border-radius: 8px;
`;

export const Banner = styled.div`
  background-color: #f3f8ff;
  border-radius: 6px;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin-top: 24px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Created = styled.div`
  padding: 4px 12px 4px 4px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CreatedLink = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CreatedLinkBanner = styled.div`
  background: #eeeeee;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  flex: none;

  span {
    color: #3333333;
    font-weight: 600;
  }
`;
