import React from "react";
import { observer } from "mobx-react";

import { withRoute } from "utils/hocs/withRoute";
import Basics from "../Basics";
import Details from "../Details";
import Links from "../Links";
import PublicProfile from "../PublicProfile";

const ProfileTab = () => {
  return (
    <>
      <Basics />
      <Details />
      <Links />
      <PublicProfile />
    </>
  );
};

export default withRoute(observer(ProfileTab));
