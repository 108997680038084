import React, { useState, useLayoutEffect } from "react";
import Button from "components/Button";
import Input from "b2b/components/Input";

const UpdateNameContent = ({ first_name, last_name, updateUserName, close }) => {
  const [processing, setProcessing] = useState(false);
  const [body, setBody] = useState({});

  useLayoutEffect(() => {
    setBody({
      firstname_attributes: { value: first_name },
      lastname_attributes: { value: last_name }
    });
  }, []);

  const handleInput = (name, value) => {
    setBody(prev => ({ ...prev, [name]: { value } }));
  };

  const handleUpdate = () => {
    setProcessing(true);
    updateUserName(body).then(success => {
      if (success) {
        setProcessing(false);
        close();
      }
    });
  };

  return (
    <>
      <div className="fz-form-row">
        <div className="fz-form-group">
          <label className="fz-std-label">First name</label>
          <Input
            value={body?.firstname_attributes?.value}
            onChange={value => handleInput("firstname_attributes", value)}
          />
        </div>
        <div className="fz-form-group">
          <label className="fz-std-label">Last name</label>
          <Input
            value={body?.lastname_attributes?.value}
            onChange={value => handleInput("lastname_attributes", value)}
          />
        </div>
      </div>

      <div className="fz-modal__actions mt-0">
        <Button color="gray" onClick={close} disabled={processing}>
          Cancel
        </Button>
        <Button
          color="blue"
          disabled={!body.firstname_attributes?.value || !body.lastname_attributes?.value || processing}
          onClick={handleUpdate}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default UpdateNameContent;
