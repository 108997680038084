import React, { useState } from "react";

import Checkbox from "b2b/components/Checkbox";
import {
  Modal,
  Title,
  Description,
  CheckboxText,
  Actions,
  Cancel,
  Remove,
  FolderInfo,
  FolderName,
  FolderCount
} from "./styled";

const RemoveFolder = ({ deleteFolder, id, name, count, setRemoved, close }) => {
  const [select, setSelect] = useState(false);

  const handleClose = () => {
    deleteFolder(id).then(() => {
      close();
      setRemoved(true);
    });
  };

  return (
    <Modal>
      <Title>Permanently delete this folder?</Title>
      <FolderInfo>
        <FolderName>{name}</FolderName>
        <FolderCount>{`${count} talent${count > 1 ? "s" : ""}`}</FolderCount>
      </FolderInfo>
      <Description>
        You`ll have to manually find all added talents again. We can’t recover this folder once you delete.
      </Description>
      <Checkbox checked={select} onCheck={() => setSelect(true)} onUncheck={() => setSelect(false)}>
        <CheckboxText>I understand that deleting the folder is permanent and can’t be undone</CheckboxText>
      </Checkbox>
      <Actions>
        <Cancel onClick={close}>Cancel</Cancel>
        <Remove isDisabled={!select} onClick={handleClose}>
          Yes, delete it
        </Remove>
      </Actions>
    </Modal>
  );
};

export default RemoveFolder;
