import React, { useState, useEffect } from "react";
import { MdContentCopy, MdCheck } from "react-icons/md";

import { Modal, Description, Title, Submit, Label, CopyContent, CopyButton, CopyFlex } from "./styled";
import { copyCodeToClipboard } from "../../../../../utils/helpers";

const SuccessToken = ({ close, activeKey }) => {
  const key = activeKey.openKey;
  const brand = activeKey.brand.name;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    copyCodeToClipboard(key, null);
    setIsCopied(true);
  };

  useEffect(() => {
    const handleBeforeUnload = event => {
      const confirmationMessage = "Changes you made may not be saved.";

      event.preventDefault();
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Modal>
      <Title>Your API key is ready!</Title>
      <Description>
        You must copy this key and store it somewhere safe. This is the <span>only time to view your API key.</span>
        <br />
        <p>If you lose it, you’ll need to create a new key.</p>
      </Description>

      <Label>
        Global brand: <span>{brand}</span>
      </Label>

      <CopyFlex>
        <CopyContent onClick={handleCopy}>
          <span>{key}</span>
        </CopyContent>

        <CopyButton onClick={handleCopy} $isCopied={isCopied}>
          {isCopied ? <MdCheck /> : <MdContentCopy />}
          {isCopied ? "Copied" : "Copy key"}
        </CopyButton>
      </CopyFlex>

      <Submit onClick={close}>Done</Submit>
    </Modal>
  );
};

export default SuccessToken;
