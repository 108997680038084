import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0 24px 0;
`;

export const Title = styled.h2`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .fz-campaign-searchbar {
    width: 400px;

    &__field {
      border-radius: 8px;
      border: 1px solid #c5c5c5;
      background: #fff;
      height: 40px;

      &::placeholder {
        color: #a4a4a4;
      }
    }
  }
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
