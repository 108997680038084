import React from "react";

import { MdBrightness1 } from "react-icons/md";

const Radio = ({ id, labelClass = "", children, disabled, style = {}, ...rest }) => {
  return (
    <div className={`fz-radio ${disabled ? "disabled" : ""}`} style={style}>
      <input id={id} type="radio" className="fz-radio__input" {...rest} />
      <label className={`fz-radio__label ${labelClass}`} htmlFor={id}>
        <MdBrightness1 className="fz-radio__label-icon" />
        {children}
      </label>
    </div>
  );
};

export default Radio;
