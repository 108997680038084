import React, { useState, useLayoutEffect } from "react";
import { observer, inject } from "mobx-react";

import Button from "components/Button";
import Input from "b2b/components/Input";
import Select from "b2b/components/Select";

const AddLinkContent = ({
  Settings: { linkTypes, getSocialLinkTypes, updateSocialLink },
  link = { social_type: "", url: "" },
  onUnmount,
  close
}) => {
  const [socialLink, setSocialLink] = useState(link);
  const [error, setError] = useState();
  const [processing, setProcessing] = useState(false);

  useLayoutEffect(() => {
    getSocialLinkTypes();

    return onUnmount;
  }, []);

  const handleUpdate = () => {
    setProcessing(true);
    updateSocialLink(socialLink).then(errors => {
      setProcessing(false);
      if (error) setError(errors);
      else close();
    });
  };

  const handleTypeChange = e => {
    e.persist();
    setSocialLink(prev => ({ ...prev, social_type: e.target?.value }));
  };

  return (
    <>
      <div className="fz-form-row">
        <div className="fz-form-group">
          <label className="fz-std-label">Link type</label>
          <Select
            name="social_type"
            disabled={!linkTypes}
            value={socialLink?.social_type || "default"}
            onChange={handleTypeChange}
          >
            {!linkTypes ? (
              <option value={socialLink?.social_type || "default"} disabled>
                Loading options
              </option>
            ) : (
              <>
                <option value="default" disabled>
                  None selected
                </option>
                {linkTypes.slice().map(item => (
                  <option key={item.type} value={item.type}>
                    {item.name.replace("_", " ")}
                  </option>
                ))}
              </>
            )}
          </Select>
        </div>
        <div className="fz-form-group">
          <label className="fz-std-label">URL</label>
          <Input value={socialLink.url} onChange={url => setSocialLink(prev => ({ ...prev, url }))} />
        </div>
      </div>

      <div className="fz-modal__actions mt-0">
        <Button color="gray" disabled={processing} onClick={close}>
          Cancel
        </Button>
        <Button color="blue" disabled={processing} onClick={handleUpdate}>
          Save
        </Button>
      </div>
    </>
  );
};

export default inject("Settings")(observer(AddLinkContent));
