import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Text } from "../../../b2c/components/Core";

const PasswordFooter = () => {
  const history = useHistory();
  return (
    <Footer>
      <Text
        fontSize={14}
        width="100%"
        fontWeight={600}
        style={{
          color: "#656565",
          textAlign: "center",
          marginTop: "20px"
        }}
      >
        Changed your mind?
        <a href="" onClick={() => history.replace("/login")}>
          <Text
            fontSize={14}
            width="100%"
            fontWeight={600}
            style={{
              display: "inline",
              color: "#0e50b5",
              width: "100%",
              marginTop: "20px",
              fontFamily: "Poppins",
              fontStyle: "normal"
            }}
          >
            {" Back to log in"}
          </Text>
        </a>
      </Text>
    </Footer>
  );
};

const Footer = styled.div`
  height: 60px;
  border-top: 2px solid #b9b9b9;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export default PasswordFooter;
