import React, { useState, useMemo } from "react";
import { MdExpandMore } from "react-icons/md";

import Select from "components/Selects";
import Layout from "../../../../components/Layout";

const JobsFilterSelect = ({ options, position, onSelect, initial }) => {
  const [ddLabel, setLabel] = useState(initial || options[0].label);

  const handleSelect = label => {
    setLabel(label);
    onSelect(options.find(item => item.label === label).value);
  };

  return (
    <Select.Searchbar
      options={options.map(item => item.label)}
      Trigger={() => (
        <>
          <div className="fz-simple-select__label">{ddLabel}</div>
          <MdExpandMore className="fz-simple-select__arrow" />
        </>
      )}
      onSelect={handleSelect}
      position={position}
    />
  );
};

const JobsFilter = ({ role, options, position, prompt, prefix, onSelect, initial }) => {
  if (options.length < 2) {
    return (
      <>
        <div className="fz-heading-filter__title">{`${prefix} `}</div>
        <div className="fz-simple-select__placeholder">{options.length > 0 ? options[0].label : prompt}</div>
      </>
    );
  }

  const defaultOption = { value: null, label: prompt };
  options = [defaultOption, ...options];

  return (
    <>
      <div className="fz-heading-filter__title">{prefix}</div>
      <div className="fz-heading-filter__select">
        <JobsFilterSelect
          initial={
            initial &&
            options.some(item => item.value === initial) &&
            options.find(item => item.value === initial).label
          }
          role={role}
          prefix={prefix}
          options={options}
          position={position}
          onSelect={value => onSelect({ [role]: value })}
        />
      </div>
    </>
  );
};

const JobsFiltersView = ({
  employers = [],
  locations = [],
  owners = [],
  onChange,
  applied: { owner_id, employer_id, location_id }
}) => {
  const options = useMemo(
    () => employers.map(({ id, name }) => ({ value: id, label: name })).sort((a, b) => (a.label > b.label ? 1 : -1)),
    [employers]
  );
  const locationOptions = useMemo(
    () => locations.map(({ id, name }) => ({ value: id, label: name })).sort((a, b) => (a.label > b.label ? 1 : -1)),
    [locations]
  );
  const ownerOptions = useMemo(
    () => owners.map(({ id, name }) => ({ value: id, label: name })).sort((a, b) => (a.label > b.label ? 1 : -1)),
    [owners]
  );

  return (
    <Layout.PageHeading className="jobs">
      <div className="fz-heading-filter">
        <div className="fz-heading-filter__row">
          <div className="fz-heading-filter__cell">
            <div className="fz-heading-filter__title">Jobs</div>
          </div>

          <div className="fz-heading-filter__cell">
            <JobsFilter
              role="owner_id"
              initial={+owner_id}
              options={ownerOptions}
              position="bottom left"
              prompt="All Members"
              prefix="by"
              onSelect={onChange}
            />
          </div>
          <div className="fz-heading-filter__cell">
            <JobsFilter
              role="employer_id"
              initial={+employer_id}
              options={options}
              prompt="All Employers"
              prefix="for"
              onSelect={onChange}
            />
          </div>
          <div className="fz-heading-filter__cell">
            <JobsFilter
              role="location_id"
              initial={+location_id}
              options={locationOptions}
              prompt="Any Location"
              prefix="in"
              onSelect={onChange}
            />
          </div>
        </div>
      </div>
    </Layout.PageHeading>
  );
};

export default JobsFiltersView;
