import styled from "styled-components";

export const Modal = styled.div`
  width: 320px;
  margin-top: -44px;

  @media screen and (min-width: 768px) {
    width: 720px;
  }
`;

export const Title = styled.h1`
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
`;

export const Description = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 14px;
`;

export const Submit = styled.button`
  border-radius: 8px;
  background: #2496f5;
  padding: 10px 12px;
  width: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "default")};
  margin-left: auto;

  ${({ $disabled }) =>
    $disabled
      ? `
        pointer-events: none;
        opacity: 0.5;
        `
      : ""}
`;

export const SelectContainer = styled.div`
  margin-bottom: 48px;

  .fz-simple-select__control {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #c5c5c5;
    background: #fff;

    svg {
      fill: #656565;
    }
  }

  .fz-simple-select__single-value {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .fz-simple-select__menu {
    width: 100%;
    max-width: 100%;
  }

  .fz-simple-select {
    .fz-simple-select__option {
      padding: 12px;
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      background-color: transparent;

      &:hover {
        color: #666;
      }
    }

    .fz-simple-select__option--is-focused {
      color: #408bfc;

      &:hover {
        color: #408bfc;
      }
    }
  }
`;
