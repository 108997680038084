import React, { useMemo } from "react";
import { inject, observer } from "mobx-react";

import Heading from "./components/Heading";
import Content from "./components/Content";
import Navigation from "./components/Navigation";
// import Empty from "./components/Empty";

const PreScreeningQuestion = ({ CampaignCreation, initialState, match }) => {
  const {
    params: { id }
  } = match;
  const {
    prescreening: { questions, position_questions, available_question_languages: availableQuestionLanguages },
    subscription: { free_forever: freeForever }
  } = initialState;

  const { postQuestions, selectedPositionQuestions, isShowInitialState } = CampaignCreation;
  const isUserHaveAccessToMessage = !freeForever;

  const positionsQuestions = useMemo(() => {
    if (selectedPositionQuestions.length) {
      return selectedPositionQuestions;
    }

    return isShowInitialState ? position_questions : [];
  }, [isShowInitialState, selectedPositionQuestions.length, position_questions.length]);

  return (
    <>
      <Heading backHref={`/campaigns/${id}/steps/two`} nextHref={`/campaigns/${id}/steps/three`} />
      <Content
        positionQuestions={positionsQuestions}
        questions={questions}
        postQuestions={selectedQuestions => postQuestions(id, selectedQuestions)}
        isUserHaveAccessToMessage={isUserHaveAccessToMessage}
        availableQuestionLanguages={availableQuestionLanguages}
      />
      <Navigation backHref={`/campaigns/${id}/steps/two`} nextHref={`/campaigns/${id}/steps/three`} />
    </>
  );
};

export default inject("CampaignCreation")(observer(PreScreeningQuestion));
