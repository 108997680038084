import React, { useContext, useState, useEffect } from "react";
import { MdQuestionAnswer, MdCall, MdArrowForward } from "react-icons/md";

import caseIcon from "images/case.png";
import Button from "components/Button";
import { useModal } from "utils/hooks";
import { ModalContext } from "contexts/modal";
import ContactDetails from "b2b/components/ContactDetails";
import { contactOptionsModal } from "../../constants/contactOptionsSteps";
import { theme } from "../../../b2c/contexts/theme";
import { iconStyle, Option, OptionText, OptionTitle, CaseIcon } from "./styled";
import LabelUseForUnlock from "../LabelUseForUnlock";
import Subscription from "../Subscription";

const ContactOptionsModal = ({
  toggle,
  addToJob,
  messageButton,
  candidate,
  selectedTalents,
  handleInviteToApply,
  isJobHeadhunt,
  getAvailableJobForCandidate,
  getActiveCompanies,
  isJobHeadhuntPage,
  isUnlocked,
  unlockHandler,
  isSearchCandidates
}) => {
  const [selectedOption, setSelectedOption] = useState(
    addToJob ? contactOptionsModal.addToJobStep : contactOptionsModal.directMessageStep
  );
  const { toggle: toggleModal } = useContext(ModalContext);
  const candidatePlural = `${selectedTalents && selectedTalents.length > 1 ? "s" : ""}`;

  const openContactDetails = useModal(
    <ContactDetails
      email={
        isJobHeadhunt
          ? candidate?.user?.details?.email || candidate?.details?.email || candidate?.email
          : candidate?.email || candidate?.details?.email
      }
      phone={
        isJobHeadhunt
          ? candidate?.user?.details?.phone_number || candidate?.details?.phone_number || candidate?.phone_number
          : candidate?.phone_number || candidate?.details?.phone_number
      }
      nationalId={
        isJobHeadhunt
          ? candidate?.user?.details?.passport_number || candidate?.details?.passport_number
          : candidate?.user_details?.passport_number || candidate?.user_details?.passport_number
      }
    />,
    "Contact Details"
  );

  const onSelectHandler = option => {
    setSelectedOption(option);
  };

  const onCloseModal = () => (toggleModal ? toggleModal() : toggle());

  const onAddToJob = () => selectedOption && addToJob();

  useEffect(() => {
    if (isJobHeadhunt && getAvailableJobForCandidate) {
      getAvailableJobForCandidate([isJobHeadhuntPage ? candidate?.id : candidate?.user?.id]);

      if (getActiveCompanies) {
        getActiveCompanies();
      }
    }
  }, []);

  return (
    <div>
      {!addToJob && (
        <>
          {!isSearchCandidates && (
            <Option
              onClick={() => onSelectHandler(contactOptionsModal.inviteToApply)}
              selected={selectedOption === contactOptionsModal.inviteToApply}
              height="auto"
            >
              <CaseIcon src={caseIcon} alt="case" />
              <OptionText>
                <OptionTitle>Invite to apply</OptionTitle>
                Found an interesting candidate{candidatePlural} to promote your open position directly? Send a message
                to prompt the candidate to apply.
              </OptionText>
            </Option>
          )}

          {(!isJobHeadhunt || !isJobHeadhuntPage) && (
            <Option
              onClick={() => onSelectHandler(contactOptionsModal.directMessageStep)}
              selected={selectedOption === contactOptionsModal.directMessageStep}
              height="auto"
            >
              <MdQuestionAnswer style={iconStyle} fill={theme.grey500} />
              <OptionText>
                <OptionTitle>
                  Send direct message
                  <LabelUseForUnlock isUnlocked={isUnlocked} />
                </OptionTitle>
                Looks like it’s a perfect candidate? Don’t wait for him/her to apply and start direct chat conversation
                now.
              </OptionText>
            </Option>
          )}

          <Option
            onClick={() => onSelectHandler(contactOptionsModal.detailsStep)}
            selected={selectedOption === contactOptionsModal.detailsStep}
            height="auto"
          >
            <MdCall style={iconStyle} fill={theme.grey500} />
            <OptionText>
              <OptionTitle>
                View contact details <LabelUseForUnlock isUnlocked={isUnlocked} />
              </OptionTitle>
              Unlock candidate to view their contact details such as phone number and email address.
            </OptionText>
          </Option>
        </>
      )}

      {addToJob && (
        <>
          <Option
            onClick={() => onSelectHandler(contactOptionsModal.addToJobStep)}
            selected={selectedOption === contactOptionsModal.addToJobStep}
            height="auto"
          >
            <MdArrowForward style={iconStyle} fill={theme.grey500} />
            <OptionText>
              <OptionTitle>Only add the candidate{candidatePlural} to the job</OptionTitle>
              Candidate{candidatePlural} will be only added to the job and will appear among the shortlisted people.
              Candidate{candidatePlural} will not receive any notification and you’ll need to handle all communication
              with them separately.
            </OptionText>
          </Option>
          <Option
            onClick={() => onSelectHandler(contactOptionsModal.inviteToApply)}
            selected={selectedOption === contactOptionsModal.inviteToApply}
            height="auto"
          >
            <CaseIcon src={caseIcon} alt="case" />
            <OptionText>
              <OptionTitle>Invite to apply</OptionTitle>
              Found an interesting candidate{candidatePlural} to promote your open position directly? Send a message to
              prompt the candidate{candidatePlural} to apply.
            </OptionText>
          </Option>
        </>
      )}

      <div className="fz-modal__actions">
        <Button onClick={onCloseModal} size="md" style={{ color: theme.black500, background: theme.grey600 }}>
          Cancel
        </Button>

        {(selectedOption === contactOptionsModal.directMessageStep ||
          selectedOption === contactOptionsModal.detailsStep) &&
          !isUnlocked && (
            <Subscription.Unlock unlocked={isUnlocked} paymentCheck unlockHandler={unlockHandler} element="Messaging">
              <Button
                disabled={!selectedOption}
                size="md"
                onClick={onAddToJob}
                className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
              >
                Next
              </Button>
            </Subscription.Unlock>
          )}

        {selectedOption === contactOptionsModal.directMessageStep && messageButton && isUnlocked && (
          <>{messageButton(candidate)}</>
        )}

        {addToJob && selectedOption !== contactOptionsModal.inviteToApply && (
          <Button
            disabled={!selectedOption}
            size="md"
            onClick={onAddToJob}
            className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
          >
            Next
          </Button>
        )}

        {selectedOption === contactOptionsModal.detailsStep && isUnlocked && (
          <Button
            disabled={!selectedOption}
            size="md"
            onClick={openContactDetails}
            className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
          >
            Next
          </Button>
        )}

        {selectedOption === contactOptionsModal.inviteToApply && (
          <Button
            disabled={!selectedOption}
            size="md"
            onClick={handleInviteToApply}
            className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default ContactOptionsModal;
