import styled from "styled-components";
import { Button } from "../../../b2c/components/Core";

export const Form = styled.form`
  width: 420px;
  margin: 10px auto;
`;

const buttonStyles = `
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};

  &:hover {
    background-color: ${({ background }) => background};
    opacity: 0.8;
  }

`;

export const LoginButton = styled(Button)`
  ${buttonStyles}
`;

export const SSOAuthenticationUrl = styled.a`
  font-size: 14px;
  margin: 15px 0;
  width: 400px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${({ $color }) => $color};
  background-color: ${({ $background }) => $background};
  ${buttonStyles}
`;
