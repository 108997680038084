import React from "react";
import withStoreCampaigns from "../../../../hocs/withStoreCampaigns";
import SelectJobForInvite from "../../../../components/SelectJobForInvite";

const SelectJobForInviteContent = ({
  store: { availableJobsForCandidate, handleSelectedAvailableJobForCandidate },
  selectedTalents,
  handleInviteToApply,
  handleClose
}) => {
  return (
    <SelectJobForInvite
      selectedTalents={selectedTalents}
      handleInviteToApply={handleInviteToApply}
      availableJobsForCandidate={availableJobsForCandidate}
      handleSelectedAvailableJobForCandidate={handleSelectedAvailableJobForCandidate}
      handleClose={handleClose}
    />
  );
};

export default withStoreCampaigns(SelectJobForInviteContent);
