import React, { useContext, useMemo } from "react";
import { MdArrowForward } from "react-icons/md";

import { Description, Label, SelectJob, AddTalentToJobComponent } from "./styled";
import CampaignsModalContent from "../CampaignsModalContent";
import CompaniesModalContent from "../CompaniesModalContent";
import SelectedTalents from "../SelectedTalents";
import withStore from "../../hocs/withStore";
import Button from "../../../components/Button";
import { ModalContext } from "../../../contexts/modal";
import AppContext from "../../../contexts/init";

const AddTalentToJob = ({
  store: { talents, shortlistTalents, selectedEmployer, resetSelectedEmployer, companies, getActiveCampaigns },
  toggle,
  selectedTalents,
  addToJob,
  campaignActive
}) => {
  const { toggle: toggleModal } = useContext(ModalContext);
  const { current_employer: currentEmployer } = useContext(AppContext);
  const { current_user: currentUser } = useContext(AppContext);
  const isLeadHr = currentUser?.roles?.includes("lead_hr");

  const recipientsNames = useMemo(
    () => talents.filter(item => selectedTalents.includes(item.id)).map(item => item.name),
    [talents]
  );

  const handleSelectJob = () => {
    shortlistTalents(campaignActive?.id, selectedTalents).then(() => {
      toggle();
      resetSelectedEmployer();
    });
  };

  const handleClickSelectJob = () => {
    toggle(
      <CampaignsModalContent selectedTalents={selectedTalents} close={toggle} addToJob={addToJob} />,
      "Select job"
    );
  };

  const handleClickSelectCompany = () => {
    toggle(
      <CompaniesModalContent
        companies={companies}
        selectedTalents={selectedTalents}
        close={toggle}
        addToJob={addToJob}
        getActiveCampaigns={getActiveCampaigns}
      />,
      "Select company"
    );
  };

  return (
    <AddTalentToJobComponent>
      <Description>
        Select company and job. Talents are added to Shortlisted candidates, but won`t receive a notification about it.
        Remember to communicate with them separately.
      </Description>
      {isLeadHr && (
        <>
          {" "}
          <Label>Company</Label>
          <SelectJob isActive={campaignActive} onClick={handleClickSelectCompany} role="button" tabIndex={-1}>
            {selectedEmployer?.name || currentEmployer?.name} <MdArrowForward style={{ width: "18px" }} />
          </SelectJob>
        </>
      )}
      <Label>Job</Label>
      <SelectJob isActive={campaignActive} onClick={handleClickSelectJob} role="button" tabIndex={-1}>
        {campaignActive?.title || "Select from active jobs"} <MdArrowForward style={{ width: "18px" }} />
      </SelectJob>
      <Label>Selected talents</Label>
      <SelectedTalents talents={recipientsNames} />
      <div className="fz-modal__actions">
        <Button
          onClick={() => (toggleModal ? toggleModal() : toggle())}
          size="md"
          style={{ color: "#333333", background: "#EEEEEE", width: "130px", fontWeight: "500" }}
        >
          Cancel
        </Button>

        <Button
          disabled={!campaignActive}
          size="md"
          onClick={handleSelectJob}
          className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
          style={{ width: "150px", fontWeight: "500" }}
        >
          Add to job
        </Button>
      </div>
    </AddTalentToJobComponent>
  );
};

export default withStore(AddTalentToJob);
