import styled from "styled-components";

export const Modal = styled.div`
  max-width: 640px;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 24px 0;
`;

export const Label = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
`;

export const List = styled.ul`
  padding-left: 14px;
  margin-top: 4px;
`;

export const ListItem = styled.li`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
`;

export const Update = styled.button`
  background: #ffe140;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 10px 40px;
`;

export const ShowPersonalityContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #c5c5c5;
`;

export const ShowPersonalityTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0 0 4px 0;
`;

export const ShowPersonalityText = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin: 0;
`;
