import React, { useState } from "react";

import Button from "components/Button";
import Input from "b2b/components/Input";

const SingleFieldContent = ({ field, label, updateField, close }) => {
  const [error, setErrors] = useState();
  const [processing, setProcessing] = useState(false);
  const [body, setBody] = useState(field);

  const handleDescriptionUpdate = () => {
    setProcessing(true);
    updateField(body).then(errors => {
      setProcessing(false);
      if (errors) setErrors(errors);
      else close();
    });
  };

  return (
    <>
      <div className="fz-form-group">
        <label className="fz-std-label">{label}</label>
        <Input defaultValue={field} onChange={setBody} />
      </div>
      {error && <div className="fz-text fz-text_sm fz-text_normal fz-text-color__error mb-2">{error}</div>}
      <div className="fz-modal__actions mt-0">
        <Button color="gray" disabled={processing} onClick={close}>
          Cancel
        </Button>
        <Button color="blue" disabled={processing || !body} onClick={handleDescriptionUpdate}>
          Save
        </Button>
      </div>
    </>
  );
};

export default SingleFieldContent;
