import React from "react";

import { Container, Content, Actions, Back, Next, SubTitle, StepsCounter, Title, Stepper, StepperItem } from "./styled";

const Heading = ({ backHref, nextHref }) => {
  const handleClickNavigation = () => {
    window.removeEventListener("beforeunload", window.beforeUnloadHandler);
  };

  return (
    <Container>
      <Content>
        <div>
          <SubTitle>New campaign</SubTitle>
          <StepsCounter>Step 4 of 6</StepsCounter>
          <Title>Screening questions</Title>
          <Stepper>
            <StepperItem $active />
            <StepperItem $active />
            <StepperItem $active />
            <StepperItem $active />
            <StepperItem />
            <StepperItem />
          </Stepper>
        </div>
        <Actions>
          <Back href={backHref} onClick={handleClickNavigation}>
            Back
          </Back>
          <Next href={nextHref} onClick={handleClickNavigation}>
            Next
          </Next>
        </Actions>
      </Content>
    </Container>
  );
};

export default Heading;
