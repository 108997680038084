import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { MdSupervisorAccount, MdStar, MdMenu, MdAssignment, MdFeedback, MdBusinessCenter } from "react-icons/md";

import ModalConsumer from "contexts/modal";
import screen_one from "images/jobs/scrn_1.png";
import screen_two from "images/jobs/scrn_2.png";
import screen_three from "images/jobs/scrn_3.png";

const Onboarding = () => {
  const viewed = window.localStorage.getItem("viewedOnboarding");

  return !viewed || viewed === "show" ? (
    <ModalConsumer>{({ toggle }) => <OnboardingContent toggle={toggle} viewed={viewed} />}</ModalConsumer>
  ) : null;
};

export const OnboardingContent = ({ toggle, viewed }) => {
  useEffect(() => {
    const timer = setTimeout(
      () => {
        toggle(<OnboradingModalContent />, "Welcome to the all new Fuzu!");
        window.localStorage.setItem("viewedOnboarding", "hide");
      },
      viewed === "show" ? 0 : 2000
    );

    return () => clearTimeout(timer);
  }, []);

  return null;
};

const OnboradingModalContent = ({ className }) => (
  <>
    <StyledDiv className={className}>
      <div className="ob-item">
        <div className="ob-left">
          <div className="ob-icon" style={{ backgroundColor: "#FFBE00" }}>
            <MdStar fill="#FFF5D6" />
          </div>
          <div className="ob-line" />
        </div>
        <div className="ob-right">
          <div className="ob-title">
            <span>Take a video tour to see what’s new</span>
          </div>
          <video
            src="https://s3-eu-west-1.amazonaws.com/fuzu.com-videos/onboarding.mp4"
            width={580}
            height={328}
            controls
            style={{ borderRadius: "12px", overflow: "hidden", outline: "none" }}
          />
        </div>
      </div>
      <div className="ob-item">
        <div className="ob-left">
          <OnboardingIcon Icon={MdMenu} />
          <div className="ob-line" />
        </div>
        <div className="ob-right">
          <div className="ob-title">
            <span>Employer side navigation update</span>
          </div>
          <div className="ob-text">
            New menu structure allows you to access your account settings by clicking the organization name on the top
            menu.
          </div>
        </div>
      </div>
      <div className="ob-item">
        <div className="ob-left">
          <OnboardingIcon Icon={MdBusinessCenter} />
          <div className="ob-line" />
        </div>
        <div className="ob-right">
          <div className="ob-title">
            <span>New home for all your jobs</span>
          </div>
          <div className="ob-text">
            <img src={screen_one} alt="screenshot" />
            <ul>
              <li>Search all your campaigns with a powerful text search</li>
              <li>Filter jobs by the admin name, name of the hiring organization or location</li>
              <li>See with one look if there are updates with individual campaigns</li>
              <li>Sort campaigns by end or publishing date</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ob-item">
        <div className="ob-left">
          <OnboardingIcon Icon={MdSupervisorAccount} />
          <div className="ob-line" />
        </div>
        <div className="ob-right">
          <div className="ob-title">
            <span>New candidate list with powerful search tools</span>
          </div>
          <img src={screen_two} alt="screenshot" />
          <div className="ob-text">
            <ul>
              <li>Features for managing your recruitment process in stages</li>
              <li>Advanced collaboration tools to get your colleagues participating in candidate review</li>
              <li>Powerful search and filtering tools for finding your dream candidate</li>
              <li>Updated bulk actions for rejecting and shortlisting candidates and for downloading candidate data</li>
              <li>New filters will allow to find the perfect candidate in the most flexible way</li>
              <li>Completely new headhunted talent section providing immediate access to top talent</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ob-item">
        <div className="ob-left">
          <OnboardingIcon Icon={MdAssignment} />
        </div>
        <div className="ob-right">
          <div className="ob-title">
            <span>Completely new Candidate CV page</span>
          </div>
          <div className="ob-text">
            <img src={screen_three} alt="placeholder" />
            <ul>
              <li>Updated general information section with advanced tagging, messaging and downloading features</li>
              <li>
                New tab structure with Fuzu profile, personality and talent scores and original CV accessible with one
                click
              </li>
              <li>Easy access to talent test scores and responses</li>
              <li>Fast navigation from one candidate CV page to the next</li>
              <li>Advanced commenting and voting features to store your and your colleagues’ feedback</li>
            </ul>
          </div>
        </div>
      </div>
    </StyledDiv>
    <StyledFooter>
      <div className="ob-footer-inner">
        <MdFeedback fill="#408BFC" />
        <a href="mailto:support@fuzu.com">Contact us if you found a bug or have a feedback</a>
      </div>
    </StyledFooter>
  </>
);

const OnboardingIcon = ({ Icon }) => {
  const [iconState, setFill] = useState({
    fill: "#FFBE00",
    color: "#FFF5D6"
  });

  const ref = useRef();

  useEffect(() => {
    const element = ref.current;
    const modal = element.parentElement.parentElement.parentElement;

    const listener = () => {
      if (element) {
        const rect = element.getBoundingClientRect();
        const view = modal.getBoundingClientRect().height;
        setFill(
          rect.bottom < view * 0.6 && rect.top >= view * 0.1
            ? {
                fill: "#FFF5D6",
                color: "#FFBE00"
              }
            : {
                fill: "#FFBE00",
                color: "#FFF5D6"
              }
        );
      }
    };

    modal.addEventListener("scroll", listener, { passive: true });

    return () => {
      modal.removeEventListener("scroll", listener, { passive: true });
    };
  }, [ref]);

  return (
    <div ref={ref} className="ob-icon" style={{ backgroundColor: iconState.color }}>
      <Icon fill={iconState.fill} />
    </div>
  );
};

const StyledFooter = styled.div`
  position: absolute;
  height: 60px;
  width: 100%;
  margin-left: -26px;
  margin-top: -26px;
  background: #f3f3f3;
  border-radius: 0 0 8px 8px;
  display: flex;

  .ob-footer-inner {
    margin: auto;
    display: inline-flex;

    svg {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }

    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 0;
      color: #408bfc;
      margin: auto 0;
    }
  }
`;

const StyledDiv = styled.div`
  padding-top: 20px;
  padding-bottom: 26px;
  width: 680px;
  height: 780px;
  overflow-y: auto;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  .ob-item {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    display: inline-flex;
    width: 100%;

    :not(:first-of-type) {
      padding: 12px 0;
    }

    .ob-left {
      .ob-icon {
        display: flex;
        height: 40px;
        width: 40px;
        background: #fff5d6;
        border-radius: 100px;

        svg {
          width: 20px;
          height: 20px;
          margin: auto;
        }
      }

      .ob-line {
        margin-left: 18px;
        border-left: 4px solid #f3f3f3;
        height: 100%;
      }
    }

    .ob-right {
      margin-left: 12px;

      .ob-title {
        display: flex;
        height: 40px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #333333;
        margin-bottom: 24px;

        > span {
          margin: auto 0;
        }
      }

      .ob-text {
        max-width: 95%;
        font-size: 16px;
        line-height: 140%;
        color: #666666;

        ul {
          padding-inline-start: 20px;
          margin-block-end: 0;

          li {
            margin: 4px 0;
          }
        }
      }

      img {
        width: 580px;
        margin: 0 auto 12px auto;
      }
    }
  }
`;

export default Onboarding;
