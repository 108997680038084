import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  MdKeyboardArrowDown,
  MdLink,
  MdVisibility,
  MdDateRange,
  MdEdit,
  MdDelete,
  MdContentCopy
} from "react-icons/md";
import { TrashAlt } from "@styled-icons/boxicons-regular";
import { inject, observer } from "mobx-react";
import format from "date-fns/format";
import { MoreHorizontalOutline } from "@styled-icons/evaicons-outline";
import { toJS } from "mobx";

import { copyCodeToClipboard } from "utils/helpers";
import ModalConsumer from "contexts/modal";
import Subscription from "../../../../components/Subscription";
import {
  Modal,
  Title,
  Tabs,
  Tab,
  SubTitle,
  SubDescription,
  DropdownContent,
  DropdownContainer,
  Member,
  MemberLogoContainer,
  MemberTitle,
  MemberCount,
  EmptyImageContent,
  EmptyState,
  EmptyStateDescription,
  CreateGuestLink,
  GuestLinkItem,
  GuestLinkItemIcon,
  GuestLinkItemName,
  GuestLinkItemInfoFlex,
  GuestLinkItemInfo,
  EditShareButton,
  RemoveShareButton,
  ButtonEdit,
  ButtonCopy,
  EditContent,
  CollaborationList,
  Actions,
  InviteCollaboratorsContent,
  Flex,
  Input,
  Invite,
  Error,
  Remove,
  ActionContent
} from "./styled";
import { theme } from "../../../../../b2c/contexts/theme";
import Dropdown from "../../../../../components/Selects";
import Button from "../../../../../components/Button";
import AppContext, { isBaronaBrand } from "../../../../../contexts/init";
import Layout from "../../../../components/Layout";
import { useModal } from "../../../../../utils/hooks";
import GuestLinkModal from "../GuestLinkModal";
import DeleteShareShortlisted from "../DeleteShareShortlisted";
import GuestLinkRemoveAccess from "../GuestLinkRemoveAccess";
import EmptyScore from "../../../../../../assets/images/b2b/no-share-links.svg";
import { getGuestLinkUrl } from "../../../../utils/helpers";
import { guestLinkAccessType } from "../../../../constants/campaign";
import { validateEmail } from "../../../../../utils/validation";

const tabOptions = {
  invite: "invite",
  share: "share"
};

const ShareCampaign = ({
  CampaignsStore: { selectedCampaign, collaborators },
  defaultTab,
  visibilityOptions = [],
  changeVisibility,
  colVisibility,
  isCurrentUserExternalCollaborator,
  // collaborators,
  guestLinkThreshold,
  handleCreateGuestLink,
  activeGuestLink,
  remove,
  deleteGuestLink,
  changeGuestLink,
  collaboratorContacts,
  getActiveVisibility,
  handleInviteCollaborators,
  handleOpenShareCampaignModal,
  guestLinkContacts,
  resendGuestLink
}) => {
  const { global_brand, current_user: currentUser } = useContext(AppContext);
  const isAccessTypeList = global_brand.guest_link_access_type === guestLinkAccessType.list;
  const [activeVisibility, setActiveVisibility] = useState({});
  const [collaboratorsValue, setCollaboratorsValue] = useState("");
  const [isShowEmailError, setIsShowEmailError] = useState(false);
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const { current_employer: currentEmployer } = useContext(AppContext);
  const [currentActiveActions, setCurrentActiveActions] = useState("");
  const campaign = useMemo(() => toJS(selectedCampaign), [selectedCampaign]);
  const guestLink = getGuestLinkUrl(`share-candidates/${selectedCampaign.active_guest_link?.token}`);
  const isLeadHr = currentUser?.roles?.includes("lead_hr");
  const isBarona = isBaronaBrand();

  const handleCopy = () => {
    copyCodeToClipboard(guestLink, () => ({}));
  };

  const handleChangeCollaborators = event => {
    setCollaboratorsValue(event.target.value);
  };

  const openGuestLinkModal = useModal(
    <GuestLinkModal
      activeGuestLink={activeGuestLink}
      handleCreateGuestLink={handleCreateGuestLink}
      guestLinkThreshold={guestLinkThreshold}
      collaboratorContacts={collaboratorContacts}
      guestLinkContacts={guestLinkContacts}
      resendGuestLink={resendGuestLink}
    />,
    ""
  );

  const showDeleteShareShortlisted = useModal(
    <DeleteShareShortlisted
      handleOpenShareCampaignModal={handleOpenShareCampaignModal}
      deleteGuestLink={deleteGuestLink}
    />,
    ""
  );

  const openGuestLinkModalEdit = useModal(
    <GuestLinkModal
      activeGuestLink={activeGuestLink}
      handleCreateGuestLink={changeGuestLink}
      guestLinkThreshold={guestLinkThreshold}
      isEdit
      showDeleteShareShortlisted={() => showDeleteShareShortlisted({ openGuestLinkModalEdit })}
      collaboratorContacts={collaboratorContacts}
      defaultSelectedValue={activeGuestLink?.contacts}
      guestLinkContacts={guestLinkContacts}
      resendGuestLink={resendGuestLink}
    />,
    ""
  );

  const handleOpenGuestLinkModalEdit = () => {
    openGuestLinkModalEdit({ showGuestLinkRemoveAccess });
  };

  const showGuestLinkRemoveAccess = useModal(
    <GuestLinkRemoveAccess
      deleteGuestLink={deleteGuestLink}
      handleOpenGuestLinkModalEdit={handleOpenGuestLinkModalEdit}
    />,
    "",
    false,
    false
  );

  const Option = ({ label, icon, innerRef }) => {
    return (
      <button type="button" ref={innerRef} className="fz-dropdown-itm">
        {icon}
        <span className="fz-dropdown-itm__text">{label}</span>
      </button>
    );
  };

  const handleChangeTab = tab => () => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSubmitInviteCollaborators = () => {
    if (!validateEmail(collaboratorsValue).test) {
      setIsShowEmailError(true);
    } else {
      handleInviteCollaborators(collaboratorsValue, null, () => setCollaboratorsValue(""));

      if (isShowEmailError) {
        setIsShowEmailError(false);
      }
    }
  };

  const toggleActiveEdit = () => {
    setActive(prev => !prev);
  };

  const renderAcceptedUsers = collaborators.map(({ id, owner, removable, accepted, email, user }, index) => {
    const backgroundColor = ["#FBCF8E", "#D9EBA8", "#F7D5A8", "#E0A9B8", "#C8E4D4", "#F1C3CF"][(index + 1) % 6];

    return (
      <div className="" key={`collaborate_${id}_${index}`}>
        <Layout.UserPane
          name={user?.name || email}
          lastName={user?.last_name || email}
          info={email}
          avatar={user?.avatar}
          inactive={!accepted}
          status={owner ? "Owner" : accepted ? "" : "Pending"}
          external={false}
          setCurrentActiveActions={setCurrentActiveActions}
          currentActiveActions={currentActiveActions}
          isNewEmptyAvatar={!user?.avatar || user?.avatar.includes("/assets/default_profile_photo")}
          backgroundColor={backgroundColor}
          isNewPopup
          additionalActions={
            !owner &&
            removable &&
            !isCurrentUserExternalCollaborator && (
              <ActionContent role="listbox">
                <Remove type="button" onClick={() => remove(id)} tabIndex="0">
                  <TrashAlt />
                  Remove
                </Remove>
              </ActionContent>
            )
          }
        />
      </div>
    );
  });

  useEffect(() => {
    const visibility = getActiveVisibility(campaign.collaboration_visibility);

    if (visibility.value !== colVisibility.value) {
      setActiveVisibility(visibility);
    } else {
      setActiveVisibility(colVisibility);
    }
  }, [campaign.collaboration_visibility]);

  useEffect(() => {
    if (!activeTab && defaultTab) {
      setActiveTab(defaultTab);
    } else {
      setActiveTab(tabOptions.invite);
    }
  }, []);

  return (
    <Modal>
      <Title>Share this campaign?</Title>

      <Tabs>
        <Tab $isActive={activeTab === tabOptions.invite} onClick={handleChangeTab(tabOptions.invite)}>
          Invite to collaborate
        </Tab>
        {isLeadHr && (
          <Tab $isActive={activeTab === tabOptions.share} onClick={handleChangeTab(tabOptions.share)}>
            Share externally
          </Tab>
        )}
      </Tabs>

      {activeTab === tabOptions.invite ? (
        <>
          <SubTitle>Who has access</SubTitle>
          <SubDescription>Control who can find, view or edit this campaign</SubDescription>

          <ModalConsumer>
            {({ toggle }) => (
              <>
                {!isCurrentUserExternalCollaborator && (
                  <DropdownContainer>
                    <Dropdown
                      Option={Option}
                      selectOptions={visibilityOptions}
                      onSelect={value => changeVisibility(value, activeVisibility, toggle)}
                    >
                      <DropdownContent>
                        <Button.Simple icon={activeVisibility.icon} color="gray" role="button" tabIndex="0">
                          {activeVisibility.label}
                        </Button.Simple>

                        <MdKeyboardArrowDown className="fz-btn__icon" />
                      </DropdownContent>
                    </Dropdown>
                  </DropdownContainer>
                )}
              </>
            )}
          </ModalConsumer>

          {!isBarona && !isCurrentUserExternalCollaborator && activeVisibility.value === "invited" && (
            <Subscription permission="collaboration" element="Collaboration tools">
              {permitted =>
                permitted ? (
                  <>
                    <SubTitle $marginBottom="0 0 16px 0">Add collaborators</SubTitle>

                    <InviteCollaboratorsContent>
                      <Flex>
                        <Input
                          placeholder="Invite collaborators by email"
                          onChange={handleChangeCollaborators}
                          value={collaboratorsValue}
                          $isError={isShowEmailError}
                        />

                        <Invite disabled={!collaboratorsValue} onClick={handleSubmitInviteCollaborators}>
                          Invite
                        </Invite>
                      </Flex>
                      {isShowEmailError && (
                        <Error>Please enter a valid email. It should have `@` and a domain (e.g., `.com`)</Error>
                      )}
                    </InviteCollaboratorsContent>
                  </>
                ) : null
              }
            </Subscription>
          )}

          {activeVisibility.value === "everyone" && (
            <Member>
              <MemberLogoContainer>
                <img src={currentEmployer.logo} alt="" />
              </MemberLogoContainer>
              <div>
                <MemberTitle>Members of {campaign.employer_name}</MemberTitle>
                <MemberCount>{collaborators?.length} people</MemberCount>
              </div>
            </Member>
          )}

          <CollaborationList>{renderAcceptedUsers}</CollaborationList>
        </>
      ) : (
        <>
          {activeGuestLink?.id ? (
            <GuestLinkItem>
              <GuestLinkItemIcon>
                <MdLink />
              </GuestLinkItemIcon>
              <div>
                <GuestLinkItemName>{`Guest link ${format(
                  new Date(activeGuestLink.created_at),
                  "dd MMM EEEE"
                )}`}</GuestLinkItemName>

                <GuestLinkItemInfoFlex>
                  <GuestLinkItemInfo>
                    <MdVisibility />
                    {activeGuestLink.views_count} views
                  </GuestLinkItemInfo>

                  <GuestLinkItemInfo>
                    <MdDateRange />
                    Expires
                    {` ${format(new Date(activeGuestLink.expires_at), "dd MMM EEEE 'at' hh:mm")}`}
                  </GuestLinkItemInfo>
                </GuestLinkItemInfoFlex>
              </div>

              <Actions>
                {!isAccessTypeList && (
                  <ButtonCopy onClick={handleCopy}>
                    <MdContentCopy />
                    Copy link
                  </ButtonCopy>
                )}

                <ButtonEdit onClick={toggleActiveEdit}>
                  <MoreHorizontalOutline />
                </ButtonEdit>
              </Actions>

              {active && (
                <EditContent>
                  <EditShareButton onClick={() => openGuestLinkModalEdit({ showGuestLinkRemoveAccess })}>
                    <MdEdit />
                    Edit link settings
                  </EditShareButton>
                  <RemoveShareButton onClick={showDeleteShareShortlisted}>
                    <MdDelete />
                    Delete link
                  </RemoveShareButton>
                </EditContent>
              )}
            </GuestLinkItem>
          ) : (
            <EmptyState>
              <EmptyImageContent>
                <EmptyScore />
              </EmptyImageContent>
              <EmptyStateDescription>
                Share a link with external reviewers to view and comment <br />
                on your candidates
              </EmptyStateDescription>
              <CreateGuestLink
                $background={theme.primaryButtonBackground}
                $color={theme.primaryButtonLabelColor}
                onClick={openGuestLinkModal}
              >
                Create guest link
              </CreateGuestLink>
            </EmptyState>
          )}
        </>
      )}
    </Modal>
  );
};

export default inject("CampaignsStore")(observer(ShareCampaign));
