import styled from "styled-components";

export const Add = styled.button`
  border-radius: 6px;
  background: #408bfc;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: auto 4px;
  transition: 0.5s ease;

  &:hover {
    filter: brightness(115%);
  }

  svg {
    fill: #fff;
  }
`;
