import React from "react";
import { MdCheckBox, MdFormatAlignLeft, MdRadioButtonChecked, MdShortText } from "react-icons/md";

import CheckboxActive from "images/b2b/checkboxActive.svg";
import CheckboxEmpty from "images/b2b/checkboxEmpty.svg";
import RadioButtonActive from "images/b2b/radioButtonActive.svg";
import RadioButtonEmpty from "images/b2b/radioButtonEmpty.svg";
import { questionsType } from "../../../../../constants/questions";
import {
  Content,
  Tile,
  TileAnswers,
  TileAnswersItem,
  TileTitle,
  TileType,
  TileDescription,
  TileDescriptionLabel
} from "./styled";

const Questions = ({ questions }) => {
  const renderQuestions = questions.map(item => {
    if (item.question_type === questionsType.multiChoice) {
      return (
        <Tile key={`multi-choice-${item.text}`}>
          <div>
            <TileTitle>{item.text}</TileTitle>
            <TileType>
              <MdCheckBox />
              {item.question_type_label}
            </TileType>

            <TileDescriptionLabel>Response</TileDescriptionLabel>

            <TileAnswers>
              {item.options.map(option => {
                return (
                  <TileAnswersItem key={`multi-choice-${option.text}`} isEmpty={!option.selected}>
                    {option.selected ? <CheckboxActive /> : <CheckboxEmpty />} {option.text}
                  </TileAnswersItem>
                );
              })}
            </TileAnswers>
          </div>
        </Tile>
      );
    }

    if (item.question_type === questionsType.optionChoice) {
      return (
        <Tile key={`option-choice-${item.text}`}>
          <div>
            <TileTitle>{item.text}</TileTitle>
            <TileType>
              <MdRadioButtonChecked />
              {item.question_type_label}
            </TileType>
            <TileDescriptionLabel>Response</TileDescriptionLabel>
            <TileAnswers>
              {item.options.map(option => {
                return (
                  <TileAnswersItem key={`option-choice-${option.text}`} isEmpty={!option.selected}>
                    {option.selected ? <RadioButtonActive /> : <RadioButtonEmpty />} {option.text}
                  </TileAnswersItem>
                );
              })}
            </TileAnswers>
          </div>
        </Tile>
      );
    }

    if (item.question_type === questionsType.shortText) {
      return (
        <Tile key={`short-text-${item.text}`}>
          <div>
            <TileTitle>{item.text}</TileTitle>
            <TileType>
              <MdShortText />
              {item.question_type_label}
            </TileType>
            <TileDescriptionLabel>Response</TileDescriptionLabel>
            <TileDescription>{item.response}</TileDescription>
          </div>
        </Tile>
      );
    }

    return (
      <Tile key={`text-${item.text}`}>
        <div>
          <TileTitle>{item.text}</TileTitle>
          <TileType>
            <MdFormatAlignLeft />
            {item.question_type_label}
          </TileType>
          <TileDescriptionLabel>Response</TileDescriptionLabel>
          <TileDescription>{item.response}</TileDescription>
        </div>
      </Tile>
    );
  });

  return (
    <div className="fz-pane">
      <div className="fz-pane__container">
        <h2 className="fz-heading-title">Screening responses</h2>
        <Content>{renderQuestions}</Content>
      </div>
    </div>
  );
};

export default Questions;
