import styled from "styled-components";

export const Content = styled.div`
  margin-top: 20px;
`;

export const FlexHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .fz-dropdown-itm {
    height: auto;
    min-height: 36px;

    .fz-dropdown-itm__text {
      word-break: break-word;
      white-space: break-spaces;
      line-height: 18px;
    }
  }

  .fz-heading-title {
    margin: 0 0 8px 0;
  }
`;

export const ImageContent = styled.div`
  width: 106px;
  height: 138px;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-content: center;
  gap: 16px;
  margin-top: 24px;
`;

export const FiltersItem = styled.button`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  padding: 9px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #c5c5c5;
  background: #f3f3f3;

  span {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    border-radius: 6px;
    background: #c5c5c5;
    padding: 2px 4px;
  }

  ${({ $isActive }) =>
    $isActive &&
    `
        border: 1px solid #ffe140;
        background: rgba(255, 225, 64, 0.3);
        color: #300;
        span {
          background: #FFE140;
        }
  `}
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #333333;
  display: block;
  margin: 24px 0 16px 0;
`;

export const AddInterviewButton = styled.button`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  padding: 8px 12px;
  border-radius: 8px;
  margin: ${({ $margin }) => $margin || "16px auto 0"};
  background: #444;
  text-align: center;
  display: block;

  ${({ $disabled }) =>
    $disabled &&
    `
      background-color: #EEE;
      pointer-events: none;
  `}
`;

export const ShowAllButton = styled.button`
  color: #408bfc;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  padding: 12px;
  background: #f3f3f3;
  width: calc(100% + 30px);
  margin: 0 -15px -8px -15px;
  display: block;
`;
