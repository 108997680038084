import styled from "styled-components";

export const Content = styled.div`
  margin: 25px 0;
  @media screen and (min-width: 768px) {
    width: 80%;
    margin: 25px auto;
  }
`;

export const Icon = styled.span`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f3f8ff;
  margin: 0 auto 10px;

  svg {
    width: 24px;
    height: 24px;
    fill: #408bfc;
  }
`;
