import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AddIconButton } from "./AddIconButton";
import { RenderTags } from "./RenderTags";
import { TagInput } from "./TagInput";
import Subscription from "../Subscription";
import Tag from "../Tag";

const TagList = ({
  tags,
  shortcuts = [],
  visible = false,
  addTag,
  removeTag,
  onCancel,
  systemTags = [],
  withButton = true
}) => {
  const [adding, newTag] = useState(false);
  const [list, changeList] = useState([]);

  useEffect(() => {
    newTag(visible);
  }, [visible]);

  useEffect(() => {
    if (tags) {
      changeList(tags);
    }
  }, [tags?.length]);

  const handleNewTags = (tagsList, toRemove = []) => {
    changeList(tagsList);
    tagsList.forEach(tag => addTag(tag));
    toRemove.forEach(tag => removeTag(tag));
    newTag(false);
  };

  const handleRemove = tag => {
    changeList(prev => [...prev].filter(t => t !== tag));
    removeTag(tag);
    newTag(false);
  };

  const handleCancel = () => {
    newTag(false);
    !!onCancel && onCancel();
  };

  return (
    <Subscription.Upgrade permission="tagging">
      <div className="fz-tag-container">
        {systemTags.length > 0
          ? systemTags.map((tag, index) => (
              <Tag isCandidateTag key={`tag-${tag}-${index}`} name={tag} removable={false} size="md" color="accent" />
            ))
          : null}

        {list.length > 0 ? (
          <RenderTags list={list} remove={handleRemove} removable isActive />
        ) : withButton && !systemTags.length ? (
          <div
            onClick={() => newTag(true)}
            className="fz-square-btn fz-square-btn_blue fz-square-btn_candidate"
            tabIndex="0"
            role="button"
          >
            Add tag
          </div>
        ) : null}
        {adding ? (
          <TagInput
            shortcuts={[...new Set(shortcuts.map(sc => sc.name))]}
            list={list}
            add={handleNewTags}
            cancel={handleCancel}
          />
        ) : (
          (list.length || systemTags?.length) > 0 && <AddIconButton onClick={() => newTag(true)} />
        )}
      </div>
    </Subscription.Upgrade>
  );
};

TagList.propTypes = {
  /** list of applicant tags */

  tags: PropTypes.arrayOf(PropTypes.string)
};

export default TagList;
