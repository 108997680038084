import styled from "styled-components";

export const InterviewItemContainer = styled.section`
  margin-top: 24px;
`;

export const InterviewItemContent = styled.div`
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  padding: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: #99c2ff;
  }
`;

export const InterviewItemHeading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
`;

export const InterviewItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const InterviewItemButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #408bfc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    fill: #408bfc;
  }
`;

export const InterviewItemDate = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 8px;
`;

export const InterviewItemName = styled.h4`
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

export const InterviewItemFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  padding-top: 16px;
`;

export const InterviewItemInfoLabel = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 4px;
`;

export const InterviewItemInfoName = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;

  img {
    border-radius: 50%;
    border: 1px solid #eee;
    width: 24px;
    height: 24px;
  }
`;

export const InterviewItemInfoStatus = styled.span`
  border-radius: 8px;
  background: rgba(243, 243, 243, 0.9);
  padding: 4px 8px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;
