import React, { useContext } from "react";
import { observer, inject } from "mobx-react";

import Button from "components/Button";
import { useModal } from "utils/hooks";
import { withRoute } from "utils/hocs/withRoute";
import Pane from "b2b/components/Pane";
import InfoPane from "b2b/components/InfoPane";
import ImagePreview from "b2b/pages/AccountSettingsScreen/components/ImagePreview";
import UploadImageContent from "b2b/pages/AccountSettingsScreen/components/UploadImageContent";
import UpdateNameContent from "../UpdateNameContent";
import SingleFieldContent from "../SingleFieldContent";
import ChangePasswordContent from "../ChangePasswordContent";
import AppContext from "../../../../../contexts/init";

const PersonalInfoTab = ({
  Settings: { currentUser, updateUserName, updateUserAvatar, updateUserPassword, updateUserEmail }
}) => {
  const { global_brand } = useContext(AppContext);
  const isHideInformationForSSOUsers = global_brand?.custom_authentication_b2b_url;

  const openUpdateNameModal = useModal(
    <UpdateNameContent
      first_name={currentUser?.first_name}
      last_name={currentUser?.last_name}
      updateUserName={updateUserName}
    />,
    "Edit name"
  );

  const openUpdateAvatarModal = useModal(
    <UploadImageContent updateImage={updateUserAvatar} />,
    <>
      Upload a profile photo
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700">
        Recommended size is 280 x 280 pixels.
        <br /> The maximum size per file is 5 MB.
      </div>
    </>
  );

  const previewAvatar = useModal(
    <ImagePreview image={currentUser?.avatar} onChange={openUpdateAvatarModal} />,
    "Avatar preview"
  );

  const openUpdateEmailModal = useModal(
    <SingleFieldContent field={currentUser?.email} label="Email" updateField={updateUserEmail} />,
    "Change email"
  );

  const openChangePasswordModal = useModal(
    <ChangePasswordContent updatePassword={updateUserPassword} />,
    <>
      Change password
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700">
        Password will be changed to the account you are now using:
        <br />
        <b>{currentUser?.email}</b>
      </div>
    </>
  );

  return currentUser ? (
    <Pane.Two>
      <h2 className="fz-heading-title">Basics</h2>

      <InfoPane.Item
        className="mt-2 pb-2 mb-2"
        title="Name"
        data={`${currentUser.first_name} ${currentUser.last_name}`}
        actions={
          <Button.Simple className="ml-2" onClick={openUpdateNameModal}>
            Edit
          </Button.Simple>
        }
      />

      <InfoPane.Item
        className="mt-2 pb-2 mb-2"
        title="Profile photo"
        actions={
          <>
            {currentUser?.avatar && <Button.Simple onClick={previewAvatar}>Preview</Button.Simple>}
            <Button.Simple className="ml-2" onClick={openUpdateAvatarModal}>
              {currentUser?.avatar ? "Change" : "Upload"}
            </Button.Simple>
          </>
        }
      />

      <InfoPane.Item
        className="mt-2 pb-2"
        title="Email"
        data={!isHideInformationForSSOUsers ? currentUser.email : ""}
        actions={
          <>
            {isHideInformationForSSOUsers ? (
              <p className="fz-text fz-text-color__black fz-text_normal">Manage your email at my.barona.fi</p>
            ) : (
              <Button.Simple className="ml-2" onClick={openUpdateEmailModal}>
                {currentUser.email ? "Change" : "Add"}
              </Button.Simple>
            )}
          </>
        }
      />

      <InfoPane.Item
        className="mt-2 pb-2"
        title="Password"
        actions={
          <>
            {isHideInformationForSSOUsers ? (
              <p className="fz-text fz-text-color__black fz-text_normal">Manage your email at my.barona.fi</p>
            ) : (
              <Button.Simple className="ml-2" onClick={openChangePasswordModal}>
                Change
              </Button.Simple>
            )}
          </>
        }
      />
    </Pane.Two>
  ) : null;
};

export default withRoute(inject("Settings")(observer(PersonalInfoTab)));
