import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdLightbulbOutline } from "react-icons/md";

import { Container, Button, Content, ContentItem } from "./styled";
import { getCompanyName } from "../../../../../contexts/init";

const HelpFul = ({ isDefaultActive, isModal }) => {
  const [activeHelpFul, setActiveHelpFul] = useState(false);

  const toggleActiveHelpFul = () => {
    setActiveHelpFul(prev => !prev);
  };

  useEffect(() => {
    if (isDefaultActive && !activeHelpFul) {
      setActiveHelpFul(true);
    }
  }, []);

  return (
    <Container>
      <Button onClick={toggleActiveHelpFul}>
        <span>
          <MdLightbulbOutline />
          {isModal ? "Learn about the question types" : "What information does the application include by default?"}
        </span>
        {activeHelpFul ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
      </Button>

      {activeHelpFul && (
        <>
          {isModal ? (
            <>
              <ContentItem>
                <span>Short answer:</span>
                collect short phrases, dates or specific numbers
              </ContentItem>
              <ContentItem>
                <span>Long answer:</span>
                gather longer, detailed answers or opinions from candidates.
              </ContentItem>
              <ContentItem>
                <span>Single choice:</span>
                candidates can choose only one answer from the set of options
              </ContentItem>
              <ContentItem>
                <span>Multi-choice:</span>
                candidates can select multiple answers from the set of options
              </ContentItem>
            </>
          ) : (
            <Content>
              <p>
                We`ve got the basics covered! We`ve already collected this key information from all candidates in their
                {getCompanyName()} profile:
              </p>
              <ul>
                <li>Full name, location and email</li>
                <li>Work experience and education</li>
                <li>Skills and languages</li>
              </ul>
            </Content>
          )}
        </>
      )}
    </Container>
  );
};

export default HelpFul;
