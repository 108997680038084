import React, { useState, useRef, useEffect } from "react";
import parse from "html-react-parser";

import { IntroductionButton, IntroductionContainer, IntroductionContent, IntroductionTitle } from "./styled";

const Introduction = ({ activeGuestLinkPitch }) => {
  const textRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [isShowMoreButton, setIsShowMoreButton] = useState(false);
  const maxHeight = 112;
  const handleClick = () => {
    setIsActive(prev => !prev);
  };

  useEffect(() => {
    if (textRef.current && textRef.current.scrollHeight > maxHeight) {
      setIsShowMoreButton(true);
    }
  }, []);

  return (
    <IntroductionContainer>
      <IntroductionTitle>Introduction</IntroductionTitle>
      <IntroductionContent $isActive={isActive} ref={textRef}>
        {parse(activeGuestLinkPitch)}
      </IntroductionContent>
      {isShowMoreButton && (
        <IntroductionButton onClick={handleClick}>{isActive ? "Show less" : "Show more"}</IntroductionButton>
      )}
    </IntroductionContainer>
  );
};

export default Introduction;
