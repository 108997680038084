import styled from "styled-components";

export const Modal = styled.div`
  width: 100%;
  min-width: 540px;
  margin-bottom: -20px;
`;

export const Line = styled.div`
  background-color: #cdcdcd;
  margin: 0 -24px;
  height: 1px;
`;

export const Includes = styled.div`
  display: flex;
  margin-top: 36px;
`;

export const IncludesItem = styled.div`
  flex: 50%;
  padding-left: 24px;
  border-left: 1px solid #e1e1e1;
  &:first-child {
    padding-left: 0;
    border-left: none;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ButtonContent = styled.div`
  display: flex;
  padding-top: 16px;
`;
