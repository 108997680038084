import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 32px 20px;
  background: #fff;
  border-bottom: 1px solid #c5c5c5;
  margin-bottom: 32px;

  @media screen and (min-width: 1240px) {
    padding: 32px 0;
  }
`;
export const Content = styled.div`
  max-width: 1206px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const SubTitle = styled.h5`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  margin: 0 0 16px 0;
`;

export const StepsCounter = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 4px;
`;

export const Title = styled.h1`
  color: #333;
  font-size: 32px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const Stepper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StepperItem = styled.span`
  border-radius: 100px;
  width: 32px;
  height: 6px;
  background: ${({ $active }) => ($active ? "#408bfc" : "#E0EDFF")};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: auto;
`;

export const Back = styled.a`
  border-radius: 8px;
  background: #eee;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;

  &:hover {
    color: #333;
    text-decoration: none;
  }
`;

export const Next = styled.a`
  border-radius: 8px;
  background: #333;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;
