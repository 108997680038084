import React from "react";
import ProgressChart from "b2b/components/ProgressChart";
import { Content, Description, ImageContent } from "../../pages/TalentCvScreen/components/PersonalityTalent/styled";
import emptyPersonalty from "../../../../assets/images/b2b/empty-personality.png";

const legendValues = {
  Creativity: { left: "Creative", right: "Practical" },
  "Team work": { left: "Compassionate", right: "Individualistic" },
  "Stress tolerance": { left: "Likes pressure", right: "Safety seeking" },
  "Social situations": { left: "Expressive", right: "Observant" },
  Persistence: { left: "Goal driven", right: "Easy-going" }
};

const ApplicantCvPersonality = ({ personality = {}, avatar, name, isShowPersonality }) => {
  const isNotEmpty = Object.keys(personality)?.length;

  return (
    <>
      {isNotEmpty && isShowPersonality ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Personality profile</h2>

            <>
              {Object.keys(personality).map(key => (
                <ProgressChart {...personality[key]} avatar={avatar} title={key} legend={legendValues[key]} key={key} />
              ))}
            </>
          </div>
        </div>
      ) : null}

      {isNotEmpty && !isShowPersonality ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Personality profile</h2>
            <Content>
              <ImageContent>
                <img src={emptyPersonalty} alt="empty" />
              </ImageContent>
              <Description>This campaign doesn`t require a Personality Profile test</Description>
            </Content>
          </div>
        </div>
      ) : null}

      {!isNotEmpty ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Personality profile</h2>
            <Content>
              <ImageContent>
                <img src={emptyPersonalty} alt="empty" />
              </ImageContent>
              <Description>{name} hasn’t taken the personality test yet</Description>
            </Content>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ApplicantCvPersonality;
