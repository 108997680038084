import React from "react";
import Button from "components/Button";
import { capitalize } from "utils/helpers";

const MyJobsModalContent = ({ modalCallback, handleRemoval, close }) => {
  const type = modalCallback.typeItem;
  return (
    <>
      <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">{`Are you sure you want to ${type} this job?`}</div>
      <div className="fz-modal__actions">
        <Button size="md" color="gray" onClick={close}>
          Cancel
        </Button>
        <Button
          size="md"
          color="error"
          onClick={() => {
            handleRemoval();
            close();
          }}
        >
          {capitalize(type)}
        </Button>
      </div>
    </>
  );
};

export default MyJobsModalContent;
