import React from "react";
import { SortingSelect } from "components/Selects";
/* hide for this ticket https://app.shortcut.com/fuzu/story/3099/b2b-fix-candidate-profile-page-navigation
SimpleSelect,
const perPageOptions = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];
*/

const SortingFilter = ({ fields = [], currentSortBy = "", sortOrder = "", update }) => {
  // perPage
  const onChangeSorting = sort_by => update({ filters: { sort_by } });

  const onChangeOrder = isReverseSorting => update({ filters: { sort_order: isReverseSorting ? "asc" : "desc" } });

  /*
  const perPageSelectProps = {
    getFilteredList: update,
    selectOptions: perPageOptions,
    role: "per_page",
    value: { value: perPage, label: perPage }
  };
  */

  const options = fields.map(sort => ({
    ...sort,
    haveExtraFilter: true
  }));
  // removed sorting 14/05/2024 .sort((a, b) => (a.id > b.id ? -1 : 1));

  const selectProps = {
    onChangeSorting,
    onChangeOrder,
    options,
    sortOrder
  };

  if (currentSortBy.length > 0) {
    const selectedValue = options.find(({ value }) => value === currentSortBy);
    selectProps.defaultOption = selectedValue;
  }

  return (
    <div className="fz-filter-bar pt-0">
      <div className="fz-filter-bar__main">{fields.length > 0 && <SortingSelect {...selectProps} />}</div>
      {/*
      <div className="fz-filter-bar__additional">
        <div className="fz-filter-bar__cell">
          <h5 className="fz-text fz-text_normal fz-text-color__grey700 mr-1">Show entries</h5>
          <SimpleSelect {...perPageSelectProps} flatView />
        </div>
      </div>
      */}
    </div>
  );
};

export default SortingFilter;
