import React, { useMemo } from "react";

import { getCompanyName } from "contexts/init";
import { candidateSourceType, tabs } from "b2b/constants/campaign";
import ProfileAvatar from "components/ProfileAvatar";
import { MdLock, MdModeEdit, MdShuffle } from "react-icons/md";
import { InfoCircle } from "@styled-icons/boxicons-regular";
import TabNavigation from "../TabNavigation";
import { ExternalCandidateAvatar, ExternalCandidateLabel, TooltipText, EditButton, Actions } from "./styled";
import Tooltip from "../../../components/Tooltip";
import { useModal } from "../../../utils/hooks";
import AddCandidateFlow from "../../pages/JobDetailScreen/components/AddCandidateFlow";
import ExternalSwitchToProfile from "../../pages/JobDetailScreen/components/ExternalSwitchToProfile";

const UserCvCard = ({
  name = "",
  isHeadhunt,
  avatar,
  country = "",
  current_position_title = "",
  current_position_employer = null,
  age = 0,
  activeTab = "Profile",
  handleTabChange,
  customCv,
  profileUnlocked = false,
  unlockHandler,
  hasPersonality = true,
  AdditionalContent = <></>,
  isHeadHunting,
  children,
  isSharedCandidates,
  isExternalCandidate,
  externalCandidateEditData,
  canSwitchCandidate,
  switchCandidateData,
  candidateSource,
  isSearchCandidates,
  guestAccessibleSections
}) => {
  const openAddCandidateFlow = useModal(<AddCandidateFlow isEdit editForm={externalCandidateEditData} />, "");
  const openExternalSwitchToProfile = useModal(
    <ExternalSwitchToProfile candidate={{ ...externalCandidateEditData, ...switchCandidateData }} />,
    ""
  );

  const openUserSwitchToExternal = useModal(
    <ExternalSwitchToProfile
      isCandidateSourceUser={candidateSource === candidateSourceType.user}
      isSwitchToExternal
      candidate={{ ...externalCandidateEditData, ...switchCandidateData }}
    />,
    ""
  );

  const getInitials = useMemo(() => {
    return name
      .split(" ")
      .map(word => word.charAt(0))
      .join("")
      .toUpperCase();
  }, [name]);

  const tabItems = useMemo(() => {
    const isShowInterviewsTab = isSharedCandidates
      ? guestAccessibleSections.interview_notes
      : !isHeadhunt && !isHeadHunting && !isSearchCandidates;

    const isShowPersonalityTab = isSharedCandidates
      ? guestAccessibleSections.personality_results || guestAccessibleSections.talent_results
      : true;

    const isShowProfileTab = isSharedCandidates
      ? guestAccessibleSections.personality_results ||
        guestAccessibleSections.talent_results ||
        guestAccessibleSections.interview_notes ||
        guestAccessibleSections.personal_details
      : true;

    const isShowCvTab = isSharedCandidates ? guestAccessibleSections.personal_details && customCv : customCv;

    const items = [];

    if (isShowProfileTab) {
      items.push({
        name: "Profile",
        to: "#",
        active: activeTab === "Profile"
      });
    }

    if (isShowPersonalityTab) {
      items.push({
        name: "Personality and Talent",
        to: "#",
        active: activeTab === "Personality and Talent"
      });
    }

    if (isShowCvTab) {
      items.push({
        name: tabs.cv,
        to: "#",
        active: activeTab === tabs.cv,
        profileUnlocked,
        unlockHandler
      });
    }

    if (isShowInterviewsTab) {
      items.push({
        name: tabs.interviews,
        to: "#",
        active: activeTab === tabs.interviews
      });
    }

    if (!isHeadhunt && !isSharedCandidates) {
      items.push({
        name: "Activity",
        to: "#",
        active: activeTab === "Activity"
      });
    }

    return items;
  }, [activeTab, customCv, hasPersonality]);

  return (
    <div className="fz-pane">
      <div className="fz-pane__container fz-pane__container_case-one">
        {canSwitchCandidate && candidateSource === candidateSourceType.user && (
          <ExternalCandidateLabel backgroundColor="#F3F8FF">
            This application was originally linked to an external candidate
            {!isSharedCandidates && (
              <Actions>
                {canSwitchCandidate && !isSearchCandidates && (
                  <EditButton onClick={openUserSwitchToExternal}>
                    <MdShuffle />
                    Switch to External candidate
                  </EditButton>
                )}
              </Actions>
            )}
          </ExternalCandidateLabel>
        )}

        {isExternalCandidate && candidateSource === candidateSourceType.prospect && (
          <ExternalCandidateLabel>
            External candidate
            <Tooltip
              tooltipPosition={["right center"]}
              tooltipContent={
                <TooltipText>Found on an external source (e.g., LinkedIn) and manually added to campaign</TooltipText>
              }
            >
              <InfoCircle />
            </Tooltip>
            {!isSharedCandidates && !isSearchCandidates && (
              <Actions>
                {canSwitchCandidate && (
                  <EditButton onClick={openExternalSwitchToProfile}>
                    <MdShuffle />
                    Switch to {getCompanyName()} profile
                  </EditButton>
                )}

                <EditButton onClick={openAddCandidateFlow}>
                  <MdModeEdit />
                  Edit candidate
                </EditButton>
              </Actions>
            )}
          </ExternalCandidateLabel>
        )}

        <div className="fz-applicant fz-applicant_indent">
          <div className="fz-applicant__aside">
            {isExternalCandidate ? (
              <ExternalCandidateAvatar>{getInitials}</ExternalCandidateAvatar>
            ) : (
              <ProfileAvatar avatar={avatar} />
            )}
          </div>
          <div className="fz-applicant__content">
            <h4 className="fz-applicant__name">
              {name}
              {!profileUnlocked && <MdLock />}
            </h4>
            <div className="fz-info-text">
              <span className="fz-info-text__text">{country}</span>
              {!isSharedCandidates && age && (
                <>
                  <span className="fz-info-text__separator">•</span>
                  <span className="fz-info-text__text">{age} years</span>
                </>
              )}

              <div className="fz-info-text__text">
                <strong>{current_position_title}</strong>{" "}
                <span className="fz-info-text__text fz-info-text__text_light">
                  {current_position_employer && `at ${current_position_employer}`}
                </span>
              </div>

              {AdditionalContent}
            </div>

            <TabNavigation
              className="mt-5"
              onChange={handleTabChange}
              tabItems={tabItems}
              isHeadHunting={isHeadHunting}
            />
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default UserCvCard;
