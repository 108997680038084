import React, { useState, useMemo, useContext } from "react";
import { onEnterPress } from "utils/helpers";
import { ChevronRight, ChevronLeft } from "@styled-icons/boxicons-solid";
import { MdCheck, MdSearch, MdClose } from "react-icons/md";

import DefaultEmployerLogo from "images/default_employer_logo.svg";
import Button from "components/Button";

import {
  Folder,
  FolderTitle,
  FolderButton,
  CreateFolder,
  CreateAndSave,
  iconChecked,
  Company,
  CompanyImage,
  CompanyTalent,
  CompanyTitle,
  Title,
  TitleBack,
  Stages,
  Stage,
  StageTitle
} from "./styled";
import AppContext from "../../../contexts/init";

const SaveModalContentProvider = ({
  folders = [],
  getFolders,
  addToFolder,
  createFolder,
  selectedTalents,
  inFolders = [],
  close,
  callBack
}) => {
  const [create, setCreate] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [activeFolder, setActiveFolder] = useState({});
  const [term, setTerm] = useState("");
  const [createMessage, setMessage] = useState({
    message: "Save candidate to a new folder",
    error: false
  });
  const { current_employer: currentEmployer } = useContext(AppContext);

  const { name, logo } = currentEmployer;

  // Sorted folders
  const sorted = useMemo(() => {
    return folders
      .map(folder => ({ ...folder, includes: inFolders.includes(folder.id) }))
      .sort(folder => (folder.includes ? 1 : -1))
      .filter(folder => folder.name.toLowerCase().includes(term.toLowerCase()));
  }, [folders, inFolders, term]);

  const handleFolderCreation = () => {
    if (folderName.length > 3) {
      createFolder(folderName)
        .then(folder => addToFolder(folder.id, selectedTalents))
        .then(() => {
          getFolders();
          close();
        });
    } else {
      setMessage({
        message: "Folder name is too short",
        error: true
      });
      setFolderName("");
    }
  };

  const handleAddFolder = (folder, stageId) => {
    addToFolder(folder.id, selectedTalents, stageId).then(() => {
      if (callBack) {
        callBack(folder.id);
      }

      getFolders();
      close();
    });
  };

  const foldersList = sorted.map((folder, index) => (
    <Folder key={`${folder.name}-${index}`} onClick={() => setActiveFolder(folder)} tabIndex={-1} role="button">
      <FolderTitle>{folder.name}</FolderTitle>
      {folder.includes ? <MdCheck style={iconChecked} /> : <ChevronRight />}
    </Folder>
  ));

  const stagesList = activeFolder.stage_relations?.map(item => {
    return (
      <Stage key={item.stage.name}>
        <StageTitle>{item.stage.name}</StageTitle>

        {!item.stage.editable && !item.stage.removable && <span>Default</span>}
        <FolderButton onClick={() => handleAddFolder(activeFolder, item.id)}>Save</FolderButton>
      </Stage>
    );
  });

  return (
    <>
      {activeFolder.name ? (
        <div>
          <TitleBack onClick={() => setActiveFolder({})}>
            <ChevronLeft /> {activeFolder.name}
          </TitleBack>
          <Stages>{stagesList}</Stages>
        </div>
      ) : (
        <>
          <Title>Save to Talent pool</Title>
          {!create && (
            <>
              <Company>
                <CompanyImage>{logo ? <img src={logo} alt="Status image" /> : <DefaultEmployerLogo />}</CompanyImage>
                <CompanyTitle>{name}</CompanyTitle>
                <ChevronRight fill="#666666" width="24px" />
                <CompanyTalent>Talent pool</CompanyTalent>
              </Company>
              <div className="fz-std-input fz-std-input_sm mb-2">
                <div className="fz-std-input__field">
                  <MdSearch className="fz-std-input__icon" />
                  <input
                    type="text"
                    placeholder="Search folders"
                    onChange={e => setTerm(e.target.value)}
                    value={term}
                    className="fz-std-input__control"
                  />
                </div>
                {term && (
                  <div role="button" tabIndex={-1} className="fz-campaign-searchbar__clear" onClick={() => setTerm("")}>
                    <Button.Simple
                      size="xs"
                      icon={<MdClose fill="#C5C5C5" />}
                      color="gray"
                      className="fadeInFromLeft"
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <div className={`headhunting_modal${create ? "" : " main"}`}>
            {!create ? (
              <>
                <div className="folder-list">
                  {sorted.length > 0 ? (
                    <>{foldersList}</>
                  ) : (
                    <div className="folder">
                      <h5 className="ml-1 fz-text-color__grey500">No matching folders</h5>
                    </div>
                  )}
                </div>
                <CreateFolder onClick={() => setCreate(true)}>Create a new folder</CreateFolder>
              </>
            ) : (
              <>
                <div className={`modal_info ${createMessage.error ? "error" : ""}`}>{createMessage.message}</div>
                <h5>Folder name</h5>
                <input
                  className="modal_input"
                  onKeyDown={e => onEnterPress(e, folderName, handleFolderCreation)}
                  onChange={e => setFolderName(e.target.value)}
                  value={folderName}
                />
                <div className="fz-modal__actions">
                  <Button size="md" color="gray" onClick={() => setCreate(false)}>
                    Cancel
                  </Button>
                  <CreateAndSave onClick={handleFolderCreation}>Create and Save</CreateAndSave>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SaveModalContentProvider;
