import React, { useContext } from "react";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useQueryParams } from "utils/hooks";
import InitContext from "contexts/init";
import { Input, Text } from "../../../b2c/components/Core";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";
import { Form, StyledButton } from "./styled";

const Invite = ({ User: { acceptInvitation, state } }) => {
  const { error, processing } = state;
  const { token } = useQueryParams();
  const { invitation } = useContext(InitContext);
  const history = useHistory();

  const handleSubmission = e => {
    e.preventDefault();
    acceptInvitation({ token }).then(() => {
      history.replace("/employers/select");
    });
  };

  const buildDescription = () => {
    const employerName = invitation?.employer?.name;
    const jobName = invitation?.position?.title;

    if (invitation.collaboration) {
      return (
        <Text fontSize={14} fontWeight={400} width="100%" margin="14px 0 0 0" textAlign="center">
          You have been invited to collaborate on the recruitment of&nbsp;
          <b>{jobName}</b>&nbsp;at&nbsp;
          <b>{employerName}</b>.
        </Text>
      );
    }

    return (
      <Text fontSize={14} fontWeight={400} width="100%" margin="14px 0 0 0" textAlign="center">
        {"You have been invited to manage the employer account for "}
        <b>{employerName}</b>.
      </Text>
    );
  };

  return (
    <CreateLoginFlowWrapper title="Welcome to Fuzu for Employers!" description={buildDescription()}>
      <Helmet>
        <title>Invite</title>
      </Helmet>
      <Form onSubmit={handleSubmission} noValidate>
        <div>
          <Input
            disabled
            key="email"
            type="email"
            autoComplete="email"
            fieldRequired={false}
            value={invitation?.email || ""}
          />
        </div>

        {error && (
          <Text color="accent300" margin="4px 0 0">
            {error}
          </Text>
        )}

        <StyledButton processing={processing} type="submit" fontSize="14px" margin="15px 0" width="100%" height="48px">
          Accept invitation
        </StyledButton>
      </Form>
    </CreateLoginFlowWrapper>
  );
};

export default inject("User")(observer(Invite));
