import React, { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { useParams, useHistory } from "react-router-dom";

import AppContext from "contexts/init";
import { b2cUrl } from "utils/helpers";
import { getMessageCandidatesPath } from "utils/urlHelpers";
import { TABS } from "constants/tabs";
import Subscription from "../../components/Subscription";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import { applicationTypes } from "../../constants/campaign";
import { useModal } from "../../../utils/hooks";
import ActionHeader from "../JobDetailScreen/components/ActionHeader";
import JobDetailContent from "../JobDetailScreen/components/JobDetailContent";
import JobDetailHeading from "../JobDetailScreen/components/JobDetailHeading";
import HowFastApplyWork from "../JobDetailScreen/components/HowFastApplyWork";

const ShareCandidates = inject(
  "campaignsStore",
  "myJobsStore"
)(
  observer(
    ({
      campaignsStore: {
        candidates = [],
        reasons = [],
        selectedCampaign,
        updateList,
        getCandidateFilters,
        state,
        guestLinkMeta,
        moveCandidates,
        shortlistCandidates,
        rejectCandidates,
        removeTalent,
        rateCandidate,
        candidateTag,
        appliedFilters: stateAppliedFilters = {},
        filters: stateFilters = [],
        setAppliedFilters,
        resetAppliedFilters,
        voteForCandidate,
        createPdfs,
        exportCsv,
        getJobDetails,
        getJobUpdates,
        filterCount,
        getCollaborators,
        collaborators,
        changeCollaborationVisibility,
        inviteCollaborator,
        removeCollaborator,
        getCandidateComments,
        getDetailShareCandidateComments,
        createComment,
        deleteComment,
        editComment,
        moveTalents,
        shortlistTalents,
        unlockUser,
        rejectMessage,
        shortlistWithAssignments,
        shortlistWithAssignmentsTalents,
        markAsViewedFastApply,
        getAvailableJobForCandidate,
        getFolders,
        folders,
        removeFolder,
        addToFolder,
        createFolder,
        addFolderIdToCandidate,
        createGuestLink,
        jobDetailShare,
        setGuestInitialState,
        deleteGuestLink,
        createShareCandidateUserForComment,
        createCommentShareCandidate,
        getGuestCandidates,
        handleKeepPageInfo,
        keepPageInfo
      } = {},
      myJobsStore: { archiveCampaign, deleteCampaign } = {},
      initialState
    }) => {
      const pageCount = useMemo(() => {
        return Math.ceil(guestLinkMeta.total_count / 10);
      }, [guestLinkMeta.total_count]);

      // Selected candidates
      const [selectedCandidates, select] = useState([]);
      const [resetPrevApplied, forceReset] = useState(false);

      // Getting query parameters and browser history
      const params = useParams();
      const history = useHistory();

      // Parsing data layer properties as JS objects
      const campaign = useMemo(() => toJS(selectedCampaign), [selectedCampaign]);
      const applicants = useMemo(() => toJS(candidates), [candidates]);
      const filters = useMemo(() => toJS(stateFilters), [stateFilters]);
      const appliedFilters = toJS(stateAppliedFilters);
      const screenState = toJS(state);
      const isFastApply = applicationTypes.fastApply === campaign.application_type;
      const isShortlisted =
        appliedFilters.filters.scope === "shortlisted" && selectedCandidates.length === candidates.length;
      const { id: shareCandidatesToken } = useParams();

      const handleHowFastApplyWork = useModal(
        <HowFastApplyWork markAsViewedFastApply={markAsViewedFastApply} />,
        "How Fast Apply campaigns work",
        () => ({})
      );

      // Filtering scopes
      const filteredScopes = useMemo(
        () =>
          filters.scopes && filters.scopes.length > 0
            ? filters.scopes.filter(scope => scope.value !== "all")
            : campaign.phases,
        [filters.scopes, campaign.phases]
      );

      // Loading message
      const loadingMsg = useMemo(() => {
        switch (params.tab) {
          default:
            return "Loading...";
          case "applicants":
            return "Just a moment! We are loading your candidates...";
          case "headhunt":
            return "Just a moment! We are loading your talents...";
        }
      }, [params.tab]);

      // Set up subscription
      const { global_brand } = useContext(AppContext);

      useEffect(() => {
        window.scrollTo(0, 0);
        forceReset(false);
      }, [params.tab, params.id]);

      useEffect(() => {
        if (campaign.title) {
          document.title = campaign.title;
        }
      }, [campaign.title]);

      useEffect(() => {
        select([]);
      }, [applicants]);

      useEffect(() => {
        setGuestInitialState(initialState);
      }, []);

      const handleGetShareCandidatesComments = id => {
        return getDetailShareCandidateComments(shareCandidatesToken, id);
      };

      // Applying and reseting filters
      const applyFilter = async filter => {
        await setAppliedFilters({ filters: { ...appliedFilters.filters, ...filter }, page: 1 });
        getGuestCandidates(params.id, filter);
      };

      const updateListShared = filter => {
        setAppliedFilters({
          ...appliedFilters,
          page: filter?.page || appliedFilters.page || 1,
          per_page: filter.per_page || appliedFilters.per_page || 10
        });
        getGuestCandidates(params.id, filter);
      };

      const resetAllFilters = async () => {
        await resetAppliedFilters();
        updateList(filters, history);
        getCandidateFilters(params.id, params.tab);
      };

      const scopeChangeReset = async filtersList => {
        await resetAppliedFilters();
        updateList(filtersList, history);
        clearSelection();
        forceReset(!resetPrevApplied);
        getCandidateFilters(params.id, params.tab);
      };

      const getMessagePath = candidateIdList => getMessageCandidatesPath(params.id, candidateIdList);

      // Candidate selection
      const selectCandidate = id => {
        !selectedCandidates.includes(id)
          ? select(prev => [...prev, id])
          : select(prev => prev.filter(item => item !== id));
      };

      const selectAll = useCallback(() => {
        selectedCandidates.length < applicants.length ? select(applicants.map(app => app.id)) : select([]);
      }, [applicants]);

      const clearSelection = () => select([]);

      // Batch actions
      const printCVs = useCallback(isSelf => createPdfs(selectedCandidates, isSelf), [selectedCandidates]);

      const exportSelectedToCsv = () => exportCsv(selectedCandidates);

      const messageToSelectedPath = useMemo(() => getMessagePath(selectedCandidates), [selectedCandidates]);

      // Tag action
      const candidateTagAction = (id, tag, type) =>
        candidateTag(id, tag, type)
          .then(() => {
            forceReset(!resetPrevApplied);
          })
          .then(() => {
            getCandidateFilters(params.id, params.tab);
          });

      // Handle success redirection
      const handleRedirection = data => {
        if (params.tab !== "applicants")
          history.push(`/jobs/${params.id}/applicants?filters[scope]=${data.filters.scope}`);
        else updateList(data, history);
      };

      const recruitmentPhase = global_brand.recruitment_phases
        ? global_brand.recruitment_phases.find(item => item.guest_access)
        : {};

      const handleCreateGuestLink = (isShowPersonality, showModal) => {
        createGuestLink({ campaignId: campaign.id, phase: recruitmentPhase.slug, isShowPersonality, showModal });
      };

      const handleRemoveGuestLink = tokenId => {
        deleteGuestLink(campaign.id, tokenId);
      };

      return (
        <Subscription.Tab tab={params.tab === TABS.HEADHUNT} permission="headhunted_talent" element="Headhunted talent">
          <ScreenStateMessage
            state={screenState}
            loadingMessage={loadingMsg}
            scopes={filteredScopes}
            redirect={handleRedirection}
          >
            <ActionHeader
              id={params.id}
              phase={campaign.phase}
              path={b2cUrl(campaign.path)}
              getCollaborators={getCollaborators}
              collaborators={collaborators}
              isCurrentUserOwner={campaign.is_current_user_owner}
              isCurrentUserExternalCollaborator={campaign.is_current_user_external_collaborator}
              colVisibility={campaign.collaboration_visibility}
              archiveCampaign={archiveCampaign}
              deleteCampaign={deleteCampaign}
              changeVisibility={changeCollaborationVisibility}
              invite={inviteCollaborator}
              remove={removeCollaborator}
              isSharedCandidates
              isLeadHr={false}
            />
            <JobDetailHeading
              id={params.id}
              tab={params.tab}
              phase={campaign.phase}
              left={campaign.days_remaining}
              employer={campaign.company_name}
              location={campaign.location}
              owner={campaign.owner_short_name}
              title={campaign.title}
              applicantCount={guestLinkMeta.total_count}
              isOwner={campaign.is_current_user_owner}
              isExternal={campaign.external_application}
              isFastApply={isFastApply}
              handleHowFastApplyWork={handleHowFastApplyWork}
              isShowShare={isShortlisted}
              createGuestLink={handleCreateGuestLink}
              deleteGuestLink={handleRemoveGuestLink}
              activeGuestLink={campaign.active_guest_link}
              isSharedCandidates
              isLeadHr={false}
              totalCount={guestLinkMeta.total_count}
            />
            <JobDetailContent
              tab={params.tab}
              type={screenState.type}
              campaign={campaign}
              reasons={reasons}
              rejectMessage={rejectMessage}
              loading={screenState.loading}
              candidates={applicants}
              handleKeepPageInfo={handleKeepPageInfo}
              keepPageInfo={keepPageInfo}
              // updateList={data => updateList(data, history)}
              selectedCandidates={selectedCandidates}
              select={selectCandidate}
              selectAll={selectAll}
              clearSelection={clearSelection}
              shortlist={shortlistCandidates}
              shortlistWithAssignments={shortlistWithAssignments}
              shortlistWithAssignmentsTalents={shortlistWithAssignmentsTalents}
              move={moveCandidates}
              moveTalents={moveTalents}
              shortlistTalents={shortlistTalents}
              reject={rejectCandidates}
              removeTalent={removeTalent}
              rate={rateCandidate}
              message={id => getMessagePath([id])}
              candidateTag={candidateTagAction}
              appliedFilters={appliedFilters}
              applyFilter={applyFilter}
              updateList={updateListShared}
              resetAllFilters={resetAllFilters}
              filters={filters}
              voteForCandidate={voteForCandidate}
              messageToSelectedPath={messageToSelectedPath}
              printCVs={printCVs}
              exportCsv={exportSelectedToCsv}
              total_count={guestLinkMeta.total_count}
              getJobDetails={options => getJobDetails(params.id, options)}
              getJobUpdates={options => getJobUpdates(params.id, options)}
              filterCount={filterCount}
              resetPrevApplied={resetPrevApplied}
              getCandidateComments={getCandidateComments}
              getDetailShareCandidateComments={handleGetShareCandidatesComments}
              createComment={createComment}
              deleteComment={deleteComment}
              editComment={editComment}
              createShareCandidateUserForComment={createShareCandidateUserForComment}
              createCommentShareCandidate={createCommentShareCandidate}
              pageCount={pageCount}
              scopeChangeReset={scopeChangeReset}
              unlockUser={unlockUser}
              prioritize_industry_experience={campaign.prioritize_industry_experience}
              industryApplied={
                appliedFilters.filters.industry_ids &&
                appliedFilters.filters.industry_ids.includes(campaign.industry_id)
              }
              isFastApply={isFastApply}
              getAvailableJobForCandidate={getAvailableJobForCandidate}
              folders={folders}
              removeFolder={removeFolder}
              addToFolder={addToFolder}
              createFolder={createFolder}
              getFolders={getFolders}
              addFolderIdToCandidate={addFolderIdToCandidate}
              jobDetailShare={jobDetailShare}
              isSharedCandidates
              activeGuestLinkToken={campaign.active_guest_link?.token}
              activeGuestLinkPitch={campaign.active_guest_link?.guest_pitch}
              activeGuestLinkDate={guestLinkMeta.last_updated_at}
              initialState={initialState}
            />
          </ScreenStateMessage>
        </Subscription.Tab>
      );
    }
  )
);

export default ShareCandidates;
