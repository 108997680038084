import React from "react";
import TextExpander from "../TextExpander";

const UserCvRecommendations = ({ recommendations = [] }) => {
  return recommendations.length > 0 ? (
    <div className="fz-pane">
      <div className="fz-pane__container fz-pane__container_case-two">
        <h2 className="fz-heading-title">Recommendations</h2>

        {recommendations.map(item => (
          <div key={`rec_${item.id}`} className="fz-info-pane">
            <div className="fz-info-pane__header">
              <div className="fz-info-pane__cell">
                <h3 className="fz-heading-subtitle">{item.name}</h3>
                <div className="fz-info-text mb-0">
                  <span className="fz-info-text__text">
                    <strong>{item.relation}</strong> at {item.employer}
                  </span>
                </div>
              </div>
              <div className="fz-info-pane__cell">
                <div className="fz-info-text mt-1 mb-0">
                  <span className="fz-info-text__text">October 6, 2018</span>
                </div>
              </div>
            </div>
            <div className="fz-info-pane__body">
              <TextExpander minHeight="57px">
                <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-0">
                  {item.message}
                </p>
              </TextExpander>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UserCvRecommendations;
