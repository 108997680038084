require("../utils/defaults");

const componentRequireContext = require.context("b2b", true);
const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);

if (module.hot && !process.env.RAILS_ENV === "test") {
  module.hot.accept(["b2b/App"]);
}
