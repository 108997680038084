import React, { useState, useEffect, useContext, useMemo } from "react";
import { observer, inject } from "mobx-react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import { MdSearch, MdPinDrop, MdArrowDropDown, MdKeyboardArrowLeft } from "react-icons/md";

import { priorityCountries } from "b2b/constants/headhunting";
import Button from "components/Button";
import Dropdown from "components/Selects";
import { onEnterPress, thousandSeparator } from "utils/helpers";
import { TABS } from "constants/tabs";
import InitContext from "../../../../contexts/HeadhuntContext";

const SearchBar = inject("store")(
  observer(
    ({
      store: {
        applyFilter,
        applyTerm,
        appliedFilters: { location_ids = [] },
        searchTerm,
        resetFilters
      },
      checkType
    }) => {
      const [searchInput, setInput] = useState("");
      const [country, setCountry] = useState({ label: "Any location" });

      const { tab } = useParams();

      const { total_count = 0, count_per_week = 0, countries = [] } = useContext(InitContext);

      const countryOptions = useMemo(() => {
        const normalizeCountry = countries
          .map(({ id, name }) => ({ label: name, value: id }))
          .sort((a, b) => (a.label < b.label ? -1 : 1));

        normalizeCountry.unshift({ label: "Any location", value: null });

        return normalizeCountry
          .sort(({ label }) => (priorityCountries.includes(label) ? -1 : 1))
          .sort(({ value }) => (value === 232 ? -1 : 1))
          .sort(({ value }) => (value === 1 ? -1 : 1));
      }, [countries]);

      useEffect(() => {
        if (location_ids.length === 0 && country.value) setCountry(countryOptions[0]);
        else if (location_ids.length === 1 && !country.value)
          setCountry(prev =>
            countryOptions.some(option => option.value === +location_ids[0])
              ? countryOptions.find(option => option.value === +location_ids[0])
              : prev
          );
        else if (location_ids.length > 1) setCountry({ label: "Multiple locations", value: "multiple" });
      }, [countryOptions, location_ids]);

      useEffect(() => {
        setInput(searchTerm || "");
      }, [searchTerm]);

      const countryHandler = value => {
        applyFilter(value ? { location_ids: [value] } : { location_ids: [] });
      };

      const wrapperClassNames = cn("fz-searchbar", {
        "fz-searchbar_highlighted": !checkType
      });

      return (
        <div className={wrapperClassNames}>
          {!checkType && (
            <div className="fz-searchbar__info">
              <div className="fz-info-text m-0">
                <span className="fz-info-text__text">
                  <strong>{thousandSeparator(total_count)}</strong> active candidates
                </span>
                <span className="fz-info-text__text">
                  <strong>{thousandSeparator(count_per_week)}</strong> new members last week
                </span>
              </div>
              {/* <Button.Simple
            icon={<MdAddCircle />}
            onClick={() => applyParams({ page: 1, per_page: 10 })}
          >
            Add search filters
          </Button.Simple> */}
            </div>
          )}
          <div className="fz-searchbar__content">
            {checkType && (
              <Link
                onClick={() => {
                  setInput("");
                  resetFilters(true, true, true);
                }}
                className="fz-simple-btn"
                to="/headhunting/find"
              >
                <Button.Simple icon={<MdKeyboardArrowLeft />} color="gray" className="fadeInFromLeft mr-2" tag="span">
                  Go back
                </Button.Simple>
              </Link>
            )}
            <div className="fz-std-input fz-std-input_lg">
              <div className="fz-std-input__field">
                <MdSearch className="fz-std-input__icon" />
                <input
                  disabled={tab === TABS.SAVED}
                  value={searchInput}
                  onKeyDown={e => onEnterPress(e, searchInput, () => applyTerm(searchInput))}
                  onChange={e => setInput(e.target.value)}
                  type="text"
                  className={`fz-std-input__control${tab === TABS.SAVED ? " disabled" : ""}`}
                  placeholder="Search by keyword"
                  role="search"
                />
              </div>
              <div className="fz-searchbar__additional">
                <div className="fz-searchbar__separator" />

                <Dropdown
                  selectOptions={countryOptions}
                  onSelect={countryHandler}
                  className="fz-simple-select fz-simple-select__full-width"
                  value={country.value ? country : null}
                >
                  <div className="fz-searchbar__location">
                    <Button.Simple
                      style={{ width: "100%" }}
                      icon={<MdPinDrop />}
                      size="sm"
                      color="gray"
                      role="button"
                      tabIndex="0"
                    >
                      {country.label}
                    </Button.Simple>
                    <MdArrowDropDown color="#A4A4A4" size="20px" />
                  </div>
                </Dropdown>

                <div className="fz-searchbar__action">
                  <Button size="wide" onClick={() => applyTerm(searchInput)} color="yellow" role="button" tabIndex="0">
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);

export default SearchBar;
