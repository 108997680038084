import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #f3a900;
  background: rgba(255, 251, 232, 0.5);
  width: 100%;
  padding: 16px;
  margin-top: 32px;
`;

export const Title = styled.h6`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
`;

export const List = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  padding-left: 10px;

  li {
    margin-bottom: 4px;
  }

  span {
    font-weight: 500;
  }
`;
