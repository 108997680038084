import React, { useRef, useState } from "react";
import Popup from "reactjs-popup";
import { MdMoreHoriz } from "react-icons/md";

import { useOnclickOutside } from "utils/hooks";
import MoveToDropdown from "../MoveToDropdown";
import DownloadDropdown from "../DownloadDropdown";

const ActionsDropdown = ({ options, move, phase, hideActions = false, customCv, isExternalCandidate }) => {
  const isRender = customCv || (phase !== "shortlisted" && !hideActions);

  if (!isRender) {
    return null;
  }

  const [dropdownState, setDropdownState] = useState(false);
  const ddRef = useRef();

  useOnclickOutside(ddRef, () => setDropdownState(false));

  return (
    <Popup
      disabled={dropdownState}
      className="fz-dropdown"
      trigger={
        <div className="fz-btn-secondary">
          <MdMoreHoriz className="fz-btn-secondary__icon" />
        </div>
      }
      position="bottom right"
      arrow={false}
    >
      <div className="fz-dropdown-nav" ref={ddRef}>
        {phase !== "shortlisted" && !hideActions && (
          <MoveToDropdown
            options={options}
            onSelect={value => {
              move(value);
              setDropdownState(true);
            }}
            isShowListByDefault={isExternalCandidate}
          />
        )}
        <DownloadDropdown customCv={customCv} />
      </div>
    </Popup>
  );
};

export default ActionsDropdown;
