import styled from "styled-components";

export const Modal = styled.div`
  max-width: 640px;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 24px 0;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  display: block;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  padding: 15px 16px;
  display: block;
  width: 100%;

  &:focus {
    outline-color: #408bfc;
  }

  ${({ isError }) =>
    isError
      ? `
        border-color:  #B00020;
      `
      : ""}
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
`;

export const Update = styled.button`
  background: #ffe140;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 10px 40px;

  ${({ isDisabled }) =>
    isDisabled
      ? `
      opacity: 0.4;
      pointer-events: none;
      `
      : ""}
`;

export const Error = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #b00020;
  display: block;
  margin: 8px 0 0 0;
`;
