import React from "react";
import Status from "../../../../components/Status";

const JobPhase = ({ phase }) => {
  switch (phase) {
    default:
      return <Status>{phase.toUpperCase()}</Status>;
    case "active":
      return <Status type="active">OPEN</Status>;
    case "inactive":
      return <Status type="closed">Closed</Status>;
  }
};

export default JobPhase;
