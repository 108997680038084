// todo all commented code will be used in the next phase
import React, { useLayoutEffect, useContext, useMemo } from "react";
import { observer, inject } from "mobx-react";
import { MdDelete } from "react-icons/md";
import { toJS } from "mobx";

import EmptyPersonalty from "images/b2b/empty-keys.svg";
import { withRoute } from "utils/hocs/withRoute";
import InitContext, { isBaronaBrand } from "contexts/init";
import {
  Heading,
  HeadingItem,
  Title,
  KeysContent,
  CreateToke,
  ButtonDelete,
  Flex,
  Tile,
  TileItem,
  TileText,
  EmptyState
} from "./styled";
import Pane from "../../../../components/Pane";
import { formatDate } from "../../../../utils/helpers";
import { useModal } from "../../../../../utils/hooks";
import DeleteToken from "../DeleteToken";
import CreateToken from "../CreateToken";

// regenerateApiToken,
const ApiTab = ({
  Settings: {
    getApiKeys,
    apiKeys,
    activeGlobalBrands,
    getActiveGlobalBrands,
    createApiKeys,
    deleteApiKeys
    // generateApiToken
  }
}) => {
  const { current_brand } = useContext(InitContext);
  const isBarona = isBaronaBrand();
  const isBaronaCompany = current_brand.name?.includes("Barona");
  const isShowUserApiTab = isBarona && isBaronaCompany;
  const showDeleteTokenModal = useModal(<DeleteToken />, "Delete this key?");

  const showCreateTokenModal = useModal(
    <CreateToken
      createApiKeys={createApiKeys}
      activeGlobalBrands={toJS(activeGlobalBrands).map(item => ({ id: item.id, label: item.name }))}
    />,
    ""
  );

  useLayoutEffect(() => {
    getApiKeys();
    getActiveGlobalBrands();
  }, []);

  const activeKeys = useMemo(() => {
    return toJS(apiKeys).filter(item => item.active);
  }, [apiKeys]);

  const expiredKeys = useMemo(() => {
    return toJS(apiKeys).filter(item => !item.active);
  }, [apiKeys]);

  const renderActiveKeys = activeKeys.map(item => {
    return (
      <Tile key={item.key}>
        <TileItem>
          <TileText>{item.brand.name}</TileText>
        </TileItem>
        <TileItem>
          <TileText>{item.key}</TileText>
        </TileItem>
        <TileItem>
          {item?.created_by ? (
            <>
              <TileText $color="#333">{item.created_by.name}</TileText>
              <TileText $fontSize="12px">{item.created_by.email}</TileText>
            </>
          ) : (
            <TileText $color="#333">-</TileText>
          )}
        </TileItem>
        <TileItem>
          <TileText>{formatDate(new Date(item.created_at))}</TileText>
        </TileItem>
        <TileItem>
          <TileText>{item.last_used_at == "Never" ? "Never" : formatDate(new Date(item.last_used_at))}</TileText>
        </TileItem>
        <TileItem>
          <Flex>
            <TileText>{formatDate(new Date(item.expires_at))}</TileText>
            <ButtonDelete
              onClick={() => showDeleteTokenModal({ handleSubmit: () => deleteApiKeys(item.id), keyCode: item.key })}
            >
              <MdDelete />
            </ButtonDelete>
          </Flex>
        </TileItem>
      </Tile>
    );
  });

  const renderExpireKeys = expiredKeys.map(item => {
    return (
      <Tile key={item.key}>
        <TileItem>
          <TileText>{item.brand.name}</TileText>
        </TileItem>
        <TileItem>
          <TileText>{item.key}</TileText>
        </TileItem>
        <TileItem>
          {item?.created_by ? (
            <>
              <TileText $color="#333">{item.created_by.name}</TileText>
              <TileText $fontSize="12px">{item.created_by.email}</TileText>
            </>
          ) : (
            <TileText $color="#333">-</TileText>
          )}
        </TileItem>
        <TileItem />
        <TileItem />
        <TileItem $width="128px">
          <TileText>{formatDate(new Date(item.expires_at))}</TileText>
        </TileItem>
      </Tile>
    );
  });

  return (
    <>
      {isShowUserApiTab && (
        <Pane.Two>
          <h2 className="fz-heading-title exp">Data sync API</h2>

          <div className="max-width-60-sm">
            <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey700 mb-2 fz-text">
              <span>Data sync API</span> provides an access to interact with candidates, campaigns and employers data.
              You can create and manage your API keys here.
            </p>
          </div>

          <CreateToke onClick={showCreateTokenModal}>Create API key</CreateToke>

          {activeKeys?.length || expiredKeys?.length ? (
            <KeysContent>
              <Title>Active keys</Title>
              <Heading>
                <HeadingItem>Brand</HeadingItem>
                <HeadingItem>Key</HeadingItem>
                <HeadingItem>Created by</HeadingItem>
                <HeadingItem>Created at</HeadingItem>
                <HeadingItem>Last used</HeadingItem>
                <HeadingItem>Expires</HeadingItem>
              </Heading>
              {activeKeys?.length ? (
                <div>{renderActiveKeys}</div>
              ) : (
                <EmptyState>
                  <EmptyPersonalty />
                  <span>You don’t have active API keys</span>
                </EmptyState>
              )}
            </KeysContent>
          ) : null}

          {expiredKeys?.length ? (
            <KeysContent>
              <Title>Expired keys</Title>
              <Heading>
                <HeadingItem>Brand</HeadingItem>
                <HeadingItem>Key</HeadingItem>
                <HeadingItem>Created by</HeadingItem>
                <HeadingItem />
                <HeadingItem />
                <HeadingItem $width="128px">Expired</HeadingItem>
              </Heading>
              <div>{renderExpireKeys}</div>
            </KeysContent>
          ) : null}
        </Pane.Two>
      )}
    </>
  );
};

export default withRoute(inject("Settings")(observer(ApiTab)));
