import React, { useContext, useLayoutEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { MdGroupAdd, MdBuild, MdEmail, MdDelete, MdPerson, MdContentCopy } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import Button from "components/Button";
import { useModal, useQueryParams } from "utils/hooks";
import { withRoute } from "utils/hocs/withRoute";
import { b2bUrl, copyCodeToClipboard } from "utils/helpers";
import UsersPlaceholder from "images/b2b/usersPlaceholder.svg";
import HandleInviteContent from "../HandleInviteContent";
import Layout from "../../../../components/Layout";
import InfoPane from "../../../../components/InfoPane";
import Pane from "../../../../components/Pane";
import { Pagination } from "../../../../../b2c/components/Core";
import AppContext from "../../../../../contexts/init";

const UsersTab = ({
  Settings: { users, usersMeta, getUserList, resendUserInvite, removeUser, grantAdmin, revokeAdmin, inviteUsers }
}) => {
  const { page } = useQueryParams();
  const history = useHistory();
  const { global_brand } = useContext(AppContext);
  const isHideInformationForSSOUsers = global_brand?.custom_authentication_b2b_url;

  const openHandleInviteModal = useModal(
    <HandleInviteContent inviteUsers={inviteUsers} />,
    "Invite users to your team"
  );

  useLayoutEffect(() => {
    getUserList(page ? +page : 1);
  }, [page]);

  const handlePagination = pages => {
    history.push(`?page=${pages}`);
  };

  return users ? (
    <Pane.Two>
      <h2 className="fz-heading-title exp mb-0">
        Users
        {!isHideInformationForSSOUsers && users && (
          <Button size="md" icon={<MdGroupAdd />} color="yellow" onClick={openHandleInviteModal}>
            Invite users
          </Button>
        )}
      </h2>
      <div className="fz-info-text mb-2">
        <span className="fz-info-text__text fz-info-text__text_light">
          Invite users to the employer account and manage their admin rights here.
        </span>
      </div>
      {users?.length === 0 ? (
        <>
          <Layout.EmptyState
            narrow
            showImage
            customImage={<UsersPlaceholder />}
            title="Start by inviting your first user"
            subtitle="Invite users to collaborate together. All invited users will appear hear."
            actionButton={
              <>
                {!isHideInformationForSSOUsers ? (
                  <Button icon={<MdGroupAdd />} color="yellow" onClick={openHandleInviteModal}>
                    Invite user
                  </Button>
                ) : null}
              </>
            }
          />
        </>
      ) : (
        <div>
          {users.map(user => (
            <UserActions
              user={user}
              resendUserInvite={resendUserInvite}
              removeUser={removeUser}
              grantAdmin={grantAdmin}
              revokeAdmin={revokeAdmin}
              isHideInformationForSSOUsers={isHideInformationForSSOUsers}
            />
          ))}
          {usersMeta && (
            <Pagination
              padding="32px 0"
              page={page ? +page : 1}
              last={Math.ceil(usersMeta?.total_count / 10)}
              setPage={handlePagination}
            />
          )}
        </div>
      )}
    </Pane.Two>
  ) : null;
};

const UserActions = ({
  user: { id, user, admin, accepted, email, invite_path },
  resendUserInvite,
  removeUser,
  grantAdmin,
  revokeAdmin,
  isHideInformationForSSOUsers
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeAndExecute = action => () => {
    setIsOpen(false);
    action();
  };
  const inviteUrl = b2bUrl(invite_path);

  return (
    <InfoPane.Item
      key={`user-${id}`}
      className="m-0 p-0"
      customTitle={
        <Layout.UserPane
          inactive={!accepted}
          name={user ? `${user?.first_name} ${user?.last_name}` : ""}
          info={email || user?.email}
          avatar={user?.avatar}
          status={admin && <MdBuild className="ml-2" />}
        />
      }
      actions={
        <>
          <div className="fz-user-bar__actions">
            <Popup
              className="fz-dropdown"
              trigger={
                <button type="button" className="fz-simple-btn fz-simple-btn_lightgray">
                  Actions
                </button>
              }
              position="bottom right"
              arrow={false}
              open={isOpen}
              onOpen={() => setIsOpen(!isOpen)}
            >
              <div className="fz-dropdown-nav" role="listbox">
                {!accepted && !isHideInformationForSSOUsers && (
                  <>
                    <button
                      type="button"
                      className="fz-dropdown-itm"
                      onClick={closeAndExecute(() => resendUserInvite(id))}
                    >
                      <MdEmail className="fz-dropdown-itm__icon" />
                      <span className="fz-dropdown-itm__text">Re-send invite</span>
                    </button>
                    <button
                      type="button"
                      className="fz-dropdown-itm"
                      onClick={closeAndExecute(() => copyCodeToClipboard(inviteUrl))}
                    >
                      <MdContentCopy className="fz-dropdown-itm__icon" />
                      <span className="fz-dropdown-itm__text">Copy invite link</span>
                    </button>
                  </>
                )}
                {user &&
                  (admin ? (
                    <button type="button" className="fz-dropdown-itm" onClick={closeAndExecute(() => revokeAdmin(id))}>
                      <MdPerson className="fz-dropdown-itm__icon" />
                      <span className="fz-dropdown-itm__text">Revoke admin</span>
                    </button>
                  ) : (
                    <button type="button" className="fz-dropdown-itm" onClick={closeAndExecute(() => grantAdmin(id))}>
                      <MdPerson className="fz-dropdown-itm__icon" />
                      <span className="fz-dropdown-itm__text">Make admin</span>
                    </button>
                  ))}

                <button type="button" className="fz-dropdown-itm" onClick={closeAndExecute(() => removeUser(id))}>
                  <MdDelete className="fz-dropdown-itm__icon" />
                  <span className="fz-dropdown-itm__text">Remove user</span>
                </button>
              </div>
            </Popup>
          </div>
        </>
      }
    />
  );
};

export default withRoute(inject("Settings")(observer(UsersTab)));
