import styled, { css } from "styled-components";

export const Folder = styled.div`
  padding: 8px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background-color: #fff;
  transition: all 0.3s ease;

  button {
    opacity: 0;
  }

  &:hover {
    background-color: #e0edff;
    border-color: #e0edff;
    h5 {
      color: #408bfc;
    }
    button {
      opacity: 1;
    }
  }
`;

export const FolderTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
  margin: 0;
  transition: all 0.3s ease;
`;

const ButtonStyle = css`
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  background-color: #408bfc;
  border-radius: 8px;
  padding: 6px 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  ${({ $styled }) => $styled}
`;

export const FolderButton = styled.button`
  opacity: 0;

  ${ButtonStyle}
`;

export const CreateFolder = styled.button`
  ${ButtonStyle}
  width: max-content;
  margin: 24px auto 0;
`;

export const CreateAndSave = styled.button`
  ${ButtonStyle}
  width: max-content;
  margin-left: 10px;
`;

export const iconChecked = {
  height: "32px",
  width: "32px",
  padding: "10px",
  fill: "#408bfc"
};
