import styled from "styled-components";

export const Modal = styled.div`
  width: 100%;
  max-width: 540px;
  margin-top: -32px;

  @media screen and (min-width: 1008px) {
    max-width: 840px;
    width: 840px;
  }
`;

export const Title = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  margin: 0 0 24px 0;
`;

export const Description = styled.span`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 8px;
  border: 1px solid #fff9e9;
  background: #fff9e9;
  padding: 16px;
  display: block;
  margin: 0 0 24px 0;
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
`;

export const Post = styled.button`
  background: #ffe140;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333;
  padding: 10px 40px;

  &:disabled {
    opacity: 0.4;
  }
`;
