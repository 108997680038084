import React, { useContext } from "react";

import InitContext from "../../../../contexts/HeadhuntContext";
import BrowseCampaigns from "../BrowseCampaigns";
import BrowseList from "../BrowseList";

const FindTalentCategory = ({ applyFilter }) => {
  const { industries, job_categories } = useContext(InitContext);

  return (
    <>
      <BrowseCampaigns />
      <BrowseList
        category="Job category"
        list={job_categories && job_categories.sort((a, b) => (a.name < b.name ? -1 : 1))}
        applyFilter={id => applyFilter({ job_category_ids: [id] })}
      />
      <BrowseList
        category="Industry"
        list={industries && industries.sort((a, b) => (a.name < b.name ? -1 : 1))}
        applyFilter={id => applyFilter({ industry_ids: [id] })}
      />
    </>
  );
};

export default FindTalentCategory;
