import React, { useState } from "react";
import Button from "components/Button";
import { MdHighlightOff, MdRadioButtonUnchecked } from "react-icons/md";
import QuestionCounterChart from "../QuestionCounterChart";
import InfoPane from "../InfoPane";

const TalentTest = ({ answers = [], average_score, max_score, test_id, test_name, top_score, user_score }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <InfoPane>
      <QuestionCounterChart
        average_score={average_score}
        max_score={max_score}
        test_id={test_id}
        test_name={test_name}
        top_score={top_score}
        user_score={user_score}
      />
      {answers.length > 0 && user_score !== max_score && (
        <>
          {expanded && <Answers answers={answers} />}
          <div className="flex">
            <Button.Simple className="m-l-auto" onClick={() => setExpanded(!expanded)}>
              {`${!expanded ? "Show" : "Hide"} answers`}
            </Button.Simple>
          </div>
        </>
      )}
    </InfoPane>
  );
};

const Answers = ({ answers }) => (
  <div className="fz-test-answers">
    <div className="fz-info-text__text fz-text_md m-t-8 m-b-8">
      <strong>Incorrect answers</strong>
    </div>
    {answers.map(
      (answer, index) =>
        answer.options?.length &&
        !answer.options.some(item => item.correct && item.selected) &&
        !answer.answers_lost && (
          <div className="answer">
            <p className="question">
              {`${index + 1}) `}
              {answer.question}
            </p>
            {answer.options && answer.options.length > 0 && (
              <div className="option-list">
                {answer.options
                  .filter(option => option.selected)
                  .map(option => (
                    <Option {...option} />
                  ))}
              </div>
            )}
          </div>
        )
    )}
  </div>
);

const Option = ({ answer_description, selected }) => (
  <div className={`option ${selected ? "selected" : ""}`}>
    {!selected ? <MdRadioButtonUnchecked /> : <MdHighlightOff />}
    <span>{answer_description}</span>
  </div>
);

export default TalentTest;
