import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "../../pages/Login";
import ForgotPassword from "../../pages/ForgotPassword";
import NewPassword from "../../pages/NewPassword";
import UsedToken from "../../pages/UsedToken";
import UsedResetLink from "../../pages/UsedResetLink";
import Current from "../../pages/Current";
import Invite from "../../pages/Invite";
import Invalid from "../../pages/Invalid";
import CheckEmail from "../../pages/CheckEmail";
import CreateAccount from "../../pages/CreateAccount";

const AccountCreation = ({ toastMessage }) => {
  return (
    <Switch>
      <Route path="/create-account" component={props => <CreateAccount {...props} />} />
      <Route path="/accept-invitation" component={props => <Invite {...props} />} />
      <Route path="/login" component={props => <Login {...props} toastMessage={toastMessage} />} />
      <Route path="/invalid-reset-link" component={props => <UsedResetLink {...props} />} />
      <Route path="/forgot-password" component={props => <ForgotPassword {...props} />} />
      <Route path="/check-email" component={props => <CheckEmail {...props} />} />
      <Route path="/invalid-invitation-token" component={props => <Invalid {...props} />} />
      <Route path="/used-invitation-token" component={props => <UsedToken {...props} />} />
      <Route path="/new-password" component={props => <NewPassword {...props} />} />
      <Route path="/employers/select" component={props => <Current {...props} />} />
    </Switch>
  );
};

export default AccountCreation;
