import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import { getMessageCandidatesPath } from "utils/urlHelpers";
import { b2cUrl, capitalize } from "utils/helpers";
import { TABS } from "constants/tabs";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import { UserCvNavBar, UserCvCustom } from "../../components/UserCv";
import PersonalityTab from "../ApplicantCvScreen/components/PersonalityTab";
import Comments from "../../components/Comments";
import VoteCounter from "../../components/VoteCounter";
import ApplicantCvCard from "../ApplicantCvScreen/components/ApplicantCvCard";
import ProfileTab from "../ApplicantCvScreen/components/ProfileTab";
import { applicationTypes, tabs } from "../../constants/campaign";
import { useModal } from "../../../utils/hooks";
import InviteCandidate from "../MyJobsScreen/components/InviteCandidate";
import ActionHeader from "../JobDetailScreen/components/ActionHeader";
import InterviewsTab from "../ApplicantCvScreen/components/InterviewsTab";

const ApplicantCvScreen = inject("campaignsStore")(
  observer(
    ({
      campaignsStore: {
        candidates,
        state,
        currentCandidate = {},
        selectedCampaign,
        getCandidate,
        // getTalent,
        updateList,
        moveCandidates,
        shortlistCandidates,
        rejectCandidates,
        removeTalent,
        moveTalents,
        createShareCandidateUserForComment,
        createCommentShareCandidate,
        shortlistTalents,
        rateCandidate,
        candidateTag,
        appliedFilters,
        // createComment,
        deleteComment,
        createPdfs,
        unlockUser,
        reasons,
        rejectMessage,
        filters: filterSet,
        filterCount,
        total,
        shortlistWithAssignments,
        shortlistWithAssignmentsTalents,
        getAvailableJobForCandidate,
        getDetailShareCandidate,
        getCVUrl,
        jobDetailShare,
        setGuestInitialState,
        toggleInterviewModal,
        isActiveInterviewModal,
        getShareCandidatesInterviewNotes,
        interviewNotes,
        interviewNotesMeta
      } = {},
      location: { index = 1 } = {},
      isSharedCandidates,
      initialState
    }) => {
      // Handling url parameters & tabs
      const [currentTab, changeTab] = useState("Profile");
      const [comments, setComments] = useState([]);

      const { id, tab, candidateId } = useParams();
      const history = useHistory();

      // Parsing store properties
      const numCandidateId = Number(candidateId);
      const candidate = useMemo(() => toJS(currentCandidate), [currentCandidate]);
      const campaign = useMemo(() => toJS(selectedCampaign), [selectedCampaign]);
      const appFilters = useMemo(() => toJS(appliedFilters), [appliedFilters]);
      const applicants = useMemo(() => toJS(candidates), [candidates]);
      const filters = useMemo(() => toJS(filterSet), [filterSet]);
      const showPersonalityAndTalentTab = Boolean(Object.keys(candidate.personality || {}).length);

      const guestAccessibleSections = useMemo(() => {
        const guestAccessible = campaign.active_guest_link?.guest_accessible_sections || [];
        return Object.fromEntries(guestAccessible.map(item => [item, true]));
      }, [campaign.active_guest_link?.guest_accessible_sections]);

      const {
        assignments,
        employer_name: employerName,
        title: jobTitle,
        application_type: applicationType,
        days_remaining: daysRemaining
      } = campaign;

      const loadingMessage = useMemo(
        () => `Just a moment! We are loading ${tab === TABS.APPLICANTS ? "candidate" : "talent"} details...`,
        [tab]
      );

      // Set up
      const getUserCV = useCallback(
        (candidateIdItem, idItem, isFirstLoading) => {
          switch (tab) {
            default:
            case "applicants":
              return getCandidate(candidateIdItem, idItem, true, isFirstLoading);
            case "headhunt":
            // return getTalent(candidateIdItem, idItem, tab);
          }
        },
        [tab]
      );

      useEffect(() => {
        window.scrollTo(0, 0);
      }, [candidateId]);

      useEffect(() => {
        if (candidate.name) {
          document.title = candidate.name;
        }
      }, [candidate.name]);

      useEffect(() => {
        getDetailShareCandidate(id, candidateId);
      }, []);

      useEffect(() => {
        if (initialState) {
          setGuestInitialState(initialState);
        }
      }, []);

      const isFastApply = applicationTypes.fastApply === applicationType;
      const isCandidateFastApplyApplied = applicationTypes.fastApplyApplied === candidate.fast_apply_status;
      const isCandidateAssignmentsCompleted = applicationTypes.assignmentsCompleted === candidate.fast_apply_status;
      const getMessagePath = candidateIdList => getMessageCandidatesPath(id, candidateIdList);

      // Filtering scopes
      const filteredScopes = useMemo(
        () =>
          filters.scopes && filters.scopes.length > 0
            ? filters.scopes.filter(scope => scope.value !== candidate.current_phase && scope.value !== "all")
            : campaign.phases,
        [filters.scopes, campaign.phases, candidate]
      );

      // Handling next and previous candidates
      const nextCandidate = useMemo(
        () =>
          applicants &&
          applicants.length > 0 &&
          candidate.id &&
          candidate.id !== applicants[applicants.length - 1].user.id
            ? applicants.some(item => item?.user?.id === candidate.id)
              ? applicants[applicants.findIndex(item => item?.user.id === candidate?.id) + 1].id
              : applicants[0].id
            : null,
        [candidate.id]
      );

      const prevCandidate = useMemo(
        () =>
          applicants && applicants.length > 0 && candidate.id && candidate.id !== +applicants[0].user.id
            ? applicants.some(item => item?.user?.id === candidate.id)
              ? applicants[applicants.findIndex(item => item?.user?.id === candidate?.id) - 1]?.id
              : applicants[0].id
            : null,
        [candidate.id]
      );

      const getNextList = () => updateList({ page: appFilters?.page + 1 });
      const getPrevList = () => updateList({ page: appFilters?.page - 1 });

      useEffect(() => {
        setComments(candidate.comments);
      }, [candidate.comments?.length]);

      const addCommentShareCandidate = body => {
        return createCommentShareCandidate(campaign.active_guest_link?.token, numCandidateId, body).then(comment => {
          setComments(prev => [...prev, comment]);
          return comment;
        });
      };

      // Handle success redirection
      const handleRedirection = data => {
        updateList(data, history, "applicants");
      };

      // Candidate removal
      const handleRemoval = pendingPromise => {
        pendingPromise.then(() => {
          getUserCV(candidateId, id);
        });
      };

      const handleShortlist = () => {
        if (tab === TABS.APPLICANTS) {
          shortlistCandidates([numCandidateId]).then(() => getUserCV(candidateId, id));
        } else {
          shortlistTalents([numCandidateId]).then(() => getUserCV(candidateId, id));
        }
      };

      const handleShortlistWithAssignments = () => {
        if (tab === TABS.APPLICANTS) {
          return shortlistWithAssignments;
        }

        return shortlistWithAssignmentsTalents;
      };

      const formatDate = date =>
        date
          ? new Date(date * 1000).toLocaleString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })
          : "";

      const showModalInviteCandidate = useModal(
        <InviteCandidate
          selectedCandidatesIds={[Number(candidateId)]}
          assignments={assignments}
          candidates={candidates}
          employerName={employerName}
          jobTitle={jobTitle}
          left={daysRemaining}
          shortlistWithAssignments={handleShortlistWithAssignments()}
          handleRemoval={handleRemoval}
          handleRemovalShortlist={handleShortlist}
        />,
        "Shortlist and send assignments to candidates"
      );

      const messageButton = <></>;

      return index !== 0 ? (
        <ScreenStateMessage
          state={state}
          loadingMessage={loadingMessage}
          scopes={filteredScopes}
          update={() => getUserCV(candidateId, id)}
          redirect={handleRedirection}
        >
          <ActionHeader
            id={id}
            phase={campaign.phase}
            path={b2cUrl(campaign.path)}
            getCollaborators={() => ({})}
            collaborators={[]}
            isCurrentUserOwner={campaign.is_current_user_owner}
            isCurrentUserExternalCollaborator={campaign.is_current_user_external_collaborator}
            colVisibility={campaign.collaboration_visibility}
            archiveCampaign={() => ({})}
            deleteCampaign={() => ({})}
            changeVisibility={() => ({})}
            invite={() => ({})}
            remove={() => ({})}
            isSharedCandidates
          />
          <section className="fz-page-container">
            <UserCvNavBar
              title={
                candidate.current_phase && tab === TABS.APPLICANTS ? capitalize(candidate.current_phase) : "Talent"
              }
              filterCount={filterCount}
              current={index <= total ? index : total}
              total={total}
              sortedBy={
                appFilters.filters &&
                appFilters.filters.sort_by &&
                capitalize(appFilters.filters.sort_by.split("_").join(" "))
              }
              candidateId={candidate.id}
              campaign={id}
              next={nextCandidate}
              prev={prevCandidate}
              getNextList={getNextList}
              getPrevList={getPrevList}
              isSharedCandidates
            />

            <ApplicantCvCard
              tab={tab}
              {...candidate}
              id={candidateId}
              reasons={reasons}
              candidate={candidate}
              isExternalCandidate={Boolean(candidate.prospect_id)}
              campaign={campaign}
              campaignEmployerId={campaign.employer_id}
              rejectMessage={rejectMessage}
              move={phase =>
                tab === TABS.APPLICANTS
                  ? moveCandidates([numCandidateId], phase).then(() => getUserCV(candidateId, id))
                  : moveTalents([numCandidateId], phase).then(() => getUserCV(candidateId, id))
              }
              shortlist={handleShortlist}
              reject={() => rejectCandidates([numCandidateId])}
              removeTalent={() =>
                removeTalent(numCandidateId).then(() =>
                  history.push({
                    pathname: `/jobs/${id}/${tab}/${nextCandidate}`,
                    index: index + 1
                  })
                )
              }
              scopes={filteredScopes}
              candidateTag={tag => candidateTag(numCandidateId, tag, "add")}
              message={() => getMessagePath([numCandidateId])}
              createPdf={() => createPdfs([numCandidateId])}
              handleTabChange={tabItem => changeTab(tabItem)}
              activeTab={currentTab}
              customCv={candidate.user_uploaded_cv_url}
              showDetails={candidate.contact_details_visible}
              shortcuts={campaign.tags_available}
              profileUnlocked={candidate.profile_unlocked}
              unlockUser={unlockUser}
              showModalInviteCandidate={showModalInviteCandidate}
              isFastApply={isFastApply}
              getAvailableJobForCandidate={getAvailableJobForCandidate}
              showPersonalityAndTalentTab={showPersonalityAndTalentTab}
              guestAccessibleSections={guestAccessibleSections}
              isSharedCandidates
            />

            {candidate.comments || candidate.voting || candidate.id ? (
              <div className="fz-container-with-sidebar">
                <div className="fz-container-with-sidebar__content">
                  {currentTab === "Profile" && (
                    <ProfileTab
                      showModalInviteCandidate={showModalInviteCandidate}
                      tab={tab}
                      candidate={candidate}
                      isFastApply={isFastApply}
                      isAssignment={candidate.current_phase === "shortlisted"}
                      left={campaign.days_remaining}
                      messageButton={messageButton}
                      campaignEndDate={campaign.campaign_end_date}
                      formatDate={formatDate}
                      isSharedCandidates
                    />
                  )}

                  {currentTab === "Personality and Talent" && (
                    <PersonalityTab
                      candidate={candidate}
                      isAssignment={candidate.current_phase === "shortlisted"}
                      showModalInviteCandidate={showModalInviteCandidate}
                      avatar={candidate.avatar}
                      personality={candidate.personality}
                      talent={candidate.talent}
                      isShowPersonality={jobDetailShare.personality_traits_any}
                      isShowTalentTests={jobDetailShare.talent_tests_any}
                      isFastApply={isFastApply}
                      isCandidateFastApplyApplied={isCandidateFastApplyApplied}
                      isCandidateAssignmentsCompleted={isCandidateAssignmentsCompleted}
                      left={campaign.days_remaining}
                      messageButton={messageButton}
                      campaignEndDate={campaign.campaign_end_date}
                      formatDate={formatDate}
                      changeTab={() => changeTab("Profile")}
                      guestAccessibleSections={guestAccessibleSections}
                      isSharedCandidates
                    />
                  )}

                  {currentTab === tabs.cv && (
                    <UserCvCustom getCVUrl={getCVUrl} cvUrl={candidate.user_uploaded_cv_url} />
                  )}

                  {currentTab === tabs.interviews && (
                    <InterviewsTab
                      campaignName={toJS(campaign.employer_name)}
                      candidateName={candidate.name}
                      toggleInterviewModal={toggleInterviewModal}
                      isActiveInterviewModal={isActiveInterviewModal}
                      getInterviewNotes={(candidateId, page, isShowOnlyNew) =>
                        getShareCandidatesInterviewNotes(id, candidateId, page, isShowOnlyNew)
                      }
                      interviewNotes={interviewNotes}
                      candidateId={candidateId}
                      interviewNotesMeta={interviewNotesMeta}
                      isSharedCandidates
                    />
                  )}
                </div>

                {currentTab !== tabs.interviews && (
                  <div className="fz-container-with-sidebar__aside fz-container-with-sidebar__aside_big">
                    {tab !== "headhunt" && !isSharedCandidates && (
                      <div className="fz-container-with-sidebar__aside fz-container-with-sidebar__aside_big">
                        <div className="fz-pane">
                          <div className="fz-pane__container">
                            <VoteCounter
                              fullLayout
                              id={candidate.id}
                              voting={candidate.voting}
                              onLike={() => rateCandidate(numCandidateId, "like")}
                              onDislike={() => rateCandidate(numCandidateId, "dislike")}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Comments
                      comments={comments}
                      add={body => addCommentShareCandidate(body)}
                      remove={ids => deleteComment(numCandidateId, ids)}
                      createShareCandidateUserForComment={createShareCandidateUserForComment}
                      isSharedCandidates
                    />
                  </div>
                )}
              </div>
            ) : null}
          </section>
        </ScreenStateMessage>
      ) : (
        <Redirect
          to={{
            pathname: `/share-candidates/${id}/${tab}/${candidateId}`,
            index: 1
          }}
        />
      );
    }
  )
);

export default ApplicantCvScreen;
