import React from "react";

import Button from "components/Button";
import { MdQuestionAnswer } from "react-icons/md";
import SubscriptionBar from "../SubscriptionBar";

const Upgrade = ({ close, route = false, subscription }) => {
  return (
    <div className="subscription-upgrade">
      <SubscriptionBar subscription={subscription} alternative />
      <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">
        Please, upgrade your license in order to unlock all features and tools.
      </div>
      <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">
        Contact our business support for the details and upgrade.
      </div>
      <div className="fz-modal__actions">
        <Button size="md" color="gray" onClick={() => close()} role="button" tabIndex="0">
          Continue with {subscription.type || "Free license"}
        </Button>
        <Button
          className="intercom-trigger"
          size="md"
          color="yellow"
          icon={<MdQuestionAnswer />}
          role="button"
          tabIndex="0"
          {...(!route ? { onClick: () => setTimeout(() => close(), 200) } : {})}
        >
          Contact business support
        </Button>
      </div>
    </div>
  );
};

const Unlock = ({ close, unlockHandler, updateSubscription, left = 0, element = "" }) => {
  const onUnlock = () => {
    if (left > 0) {
      unlockHandler().then(data => {
        updateSubscription(data);
        close();
      });
    }
  };

  return (
    <div className="subscription-unlock">
      <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">
        Use <b>1 unlock</b> to see {element}
      </div>

      <div className="fz-modal__actions alt">
        <div>
          <Button
            size="md"
            color="gray"
            onClick={() => {
              close();
            }}
            role="button"
            tabIndex="0"
          >
            Cancel
          </Button>
          <Button size="md" color="yellow" disabled={left === 0} onClick={onUnlock} role="button" tabIndex="0">
            Unlock
          </Button>
        </div>
      </div>
    </div>
  );
};

const Payment = ({ close }) => {
  return (
    <>
      <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">
        This feature requires payment.
      </div>

      <div className="fz-modal__actions">
        <Button size="md" color="gray" onClick={() => close()} role="button" tabIndex="0">
          Close
        </Button>
        <Button
          className="intercom-trigger"
          onClick={() => setTimeout(() => close(), 200)}
          size="md"
          color="yellow"
          icon={<MdQuestionAnswer />}
          role="button"
          tabIndex="0"
        >
          Contact business support
        </Button>
      </div>
    </>
  );
};

export default { Unlock, Upgrade, Payment };
