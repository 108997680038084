import styled from "styled-components";

export const Modal = styled.div`
  width: 320px;
  margin-top: -44px;

  @media screen and (min-width: 768px) {
    width: 720px;
  }
`;

export const Title = styled.h1`
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
`;

export const Description = styled.div`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 14px;

  span {
    font-weight: 500;
    color: #333;
  }

  p {
    display: block;
    margin: 8px 0 0 0;
  }
`;

export const Submit = styled.button`
  border-radius: 8px;
  background: #2496f5;
  padding: 10px 12px;
  width: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "default")};
  margin-left: auto;
`;

export const Label = styled.label`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: #666;
    font-weight: 400;
  }
`;

export const CopyFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 48px;
`;

export const CopyContent = styled.button`
  padding: 11px 12px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  text-align: left;
  overflow: hidden;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const CopyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  width: 106px;
  flex: none;

  ${({ $isCopied }) =>
    !$isCopied
      ? `
        background: #f3f3f3;
        color: #444;
      `
      : `
         background: #EEFFF7;
         color: #018747;
      `}

  svg {
    width: 18px;
    height: 18px;
    fill: ${({ $isCopied }) => ($isCopied ? "#018747" : "#444;")};
  }
`;
