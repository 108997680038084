import styled from "styled-components";

export const DropdownItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  margin-bottom: 16px;

  ${({ $isAlreadySelected }) =>
    $isAlreadySelected
      ? `
      opacity: 0.6;
      pointer-events: none;
    `
      : ""}}
`;

export const DropdownInitials = styled.span`
  display: flex;
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  border: 1px solid #eee;
`;

export const DropdownName = styled.h2`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 4px;
`;

export const DropdownEmail = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const AlreadySelected = styled.span`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-left: auto;
  align-self: auto;
`;

export const dropdownStyles = {
  container: base => ({
    ...base,
    width: "100%"
  }),
  control: base => ({
    ...base,
    borderColor: "#007BFF",
    boxShadow: "none",
    "&:hover": { borderColor: "#0056b3" },
    width: "100%",
    minHeight: "auto"
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#f1f1f1" : "#fff",
    color: "#000"
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#F5F5F5",
    borderRadius: 4,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "140%",
    margin: 0,
    padding: "4px 8px",
    gap: "4px",
    color: "#333333",

    div: {
      padding: 0,
      "&:hover": { background: "transparent", svg: { fill: "#333" } }
    }
  }),
  menu: base => ({
    ...base,
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #EEE",
    background: "#FFF",
    boxShadow: "0px 8px 16px 8px rgba(66, 76, 95, 0.05)"
  }),
  indicatorsContainer: base => ({
    ...base,
    display: "none"
  }),
  valueContainer: base => ({
    ...base,
    padding: "10px 12px",
    minHeight: "48px",
    gap: "8px"
  }),
  input: base => ({
    ...base,
    margin: 0
  }),
  placeholder: base => ({
    ...base,
    margin: 0
  })
};
