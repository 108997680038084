import React from "react";

import Button from "components/Button";
import { capitalize } from "utils/helpers";

const JobDetailModalContent = ({ type = "", message, reject, toggle }) => {
  return (
    <>
      <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">{message}</div>
      <div className="fz-modal__actions">
        <Button size="md" color="gray" onClick={() => toggle(false)}>
          Cancel
        </Button>
        <Button
          size="md"
          color="error"
          onClick={() => {
            toggle(false);
            reject();
          }}
        >
          {capitalize(type)}
        </Button>
      </div>
    </>
  );
};

export default JobDetailModalContent;
