import React, { useMemo, useEffect, useState } from "react";
import Button from "components/Button";

import { MdGetApp } from "react-icons/md";
import Pane from "../Pane";

const UserCvCustom = ({ getCVUrl, cvUrl = "#" }) => {
  const [currentCv, setCurrentCv] = useState("");

  useEffect(() => {
    getCVUrl(cvUrl).then(data => {
      if (data?.url) {
        setCurrentCv(data.url);
      }
    });
  }, []);

  const extension = currentCv ? currentCv.split(".").pop() : "";

  const handleStyles = useMemo(
    () =>
      !extension.includes("pdf")
        ? `
      background: #EEEEEE;
      display: flex;
      justify-content: center;
      height: 400px;

      .fz-pane__container_case-two {
        height: fit-content;
        margin: auto;
      }

      .fz-btn {
        color: #fff;
        margin: auto;
      }
    `
        : "",
    [extension]
  );

  return (
    <Pane.Two extraCss={handleStyles}>
      {extension.includes("pdf") ? (
        <iframe src={currentCv} width="100%" height="660px" frameBorder="0" style={{ borderRadius: "8px" }} />
      ) : (
        <>
          <h2 className="fz-heading-title">File preview unavailable</h2>
          <div className="fz-info-pane">
            <div className="fz-info-pane__body">
              <div className="fz-tag-container">
                <Button tag="a" href={currentCv} target="_blank" color="blue" size="wide" icon={<MdGetApp />}>
                  Download
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Pane.Two>
  );
};

export default UserCvCustom;
