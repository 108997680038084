import React, { useEffect, useMemo, useContext } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";

import { capitalize } from "utils/helpers";
import ModalConsumer from "contexts/modal";
import AppContext from "contexts/init";
import SubscriptionBar from "../../components/SubscriptionBar";
import StatusFiltersBar from "../../components/StatusFiltersBar";
import SortingFilter from "../../components/SortingFilter";
import Paginator from "../../components/Paginator";
import ScreenStateMessage from "../../components/ScreenStateMessage";
import Layout from "../../components/Layout";
import JobsFiltersView from "./components/JobsFiltersView";
import MyJobsModalContent from "./components/MyJobsModalContent";
import RenderJobs from "./components/RenderJobs";

const MyJobsScreen = inject("myJobsStore")(
  observer(
    ({
      myJobsStore: {
        setUp,
        updateJobs,
        archiveCampaign,
        deleteCampaign,
        scopeTotals,
        filters,
        myCampaigns,
        appliedFilters,
        state,
        pageCount
      } = {}
    }) => {
      // Initial state
      const {
        subscription,
        filters: initFilters,
        current_user_id,
        current_employer_id,
        is_current_user_external_collaborator: isExternal,
        current_user: currentUser
      } = useContext(AppContext);
      const isLeadHr = currentUser?.roles?.includes("lead_hr");

      const jobCount = subscription.valid
        ? Math.max(subscription.limits.active_job_limit - subscription.limits.active_job_count, 0)
        : 0;

      // Handling campaign list
      const jobs = useMemo(() => toJS(myCampaigns), [myCampaigns]);

      // Set up
      const history = useHistory();
      useEffect(() => {
        setUp(history, isLeadHr);
      }, []);

      // Parsing story properties as JS objects
      const filter = toJS(filters);
      const appFilters = useMemo(() => toJS(appliedFilters), [appliedFilters]);
      const screenState = toJS(state);
      const { type, loading, error, message } = screenState;

      const scopes = useMemo(
        () =>
          initFilters && initFilters.scopes
            ? initFilters.scopes.map(value => ({ label: capitalize(value), count: scopeTotals[value] || 0, value }))
            : [],
        [scopeTotals, initFilters]
      );

      // Computing loading state placeholder list length
      const placeholdersLength = useMemo(
        () => [...Array(Math.max(appFilters.per_page || 0, 10)).keys()],
        [appFilters.per_page]
      );

      const ContentHandler = ({ toggleModal, jobs: jobsItem, placeholdersLength: placeholdersLengthItem }) =>
        error && type === "server_error" ? (
          <Layout.Error onClick={() => updateJobs(filter, history)} message={message} />
        ) : (
          <RenderJobs
            loading={loading}
            isExternal={isExternal}
            jobs={jobsItem}
            placeholdersLength={placeholdersLengthItem}
            handleModal={(typeItem, callback, id) => handleModal(toggleModal, typeItem, callback, id)}
            archiveCampaign={archiveCampaign}
            deleteCampaign={deleteCampaign}
            jobCount={jobCount}
            license_state={subscription.license_state}
            active_job_limit={subscription.limits.active_job_limit}
            validSubscription={subscription.valid}
          />
        );

      const { per_page, page, sort_by: currentSortBy = "", sort_order = "" } = appFilters;

      // Async campaign removal
      const handleRemoval = pendingPromise => {
        pendingPromise.then(() => {
          updateJobs(filter, history);
        });
      };

      useEffect(() => {
        if (jobs.length === 0 && pageCount > 0) {
          updateJobs(filter, history);
        }
      }, [jobs, pageCount]);

      // Modal interaction
      const handleModal = (toggleModal, typeItem, callback, id) => {
        toggleModal(
          <MyJobsModalContent
            modalCallback={{ typeItem, callback, id }}
            handleRemoval={() => handleRemoval(callback(id), id)}
            close={() => toggleModal()}
          />
        );
      };

      const handleFilterChange = filtersList => {
        updateJobs({ filters: filtersList, page: 1 }, history);
      };

      return (
        <ScreenStateMessage
          state={screenState}
          refresh={() => updateJobs(filter, history)}
          loadingMessage="Just a moment! We are loading your jobs..."
        >
          <JobsFiltersView
            applied={appFilters}
            employers={filter.employers && filter.employers.sort(emp => (emp.id === current_employer_id ? -1 : 0))}
            locations={filter.locations}
            owners={filter.owners && filter.owners.sort(owner => (owner.id === current_user_id ? -1 : 0))}
            onChange={filtersList => updateJobs({ filters: filtersList, page: 1 }, history)}
          />
          <ModalConsumer>
            {({ toggle }) => (
              <section className="fz-page-container">
                <StatusFiltersBar
                  onChange={handleFilterChange}
                  onSearch={handleFilterChange}
                  scopes={scopes}
                  term={appFilters.term}
                  currentScope={appFilters.scope || "active"}
                  placeholder="Search jobs"
                  appliedFilters={appFilters}
                  listLength={jobs.length}
                />
                <SortingFilter
                  sortOrder={sort_order}
                  currentSortBy={currentSortBy}
                  fields={initFilters?.sort_bys}
                  update={filtersList => updateJobs(filtersList, history)}
                  perPage={per_page}
                />
                {!isExternal && <SubscriptionBar subscription={subscription} />}
                <div className="fz-panel-list" data-test-scope="campaigns-list">
                  <ContentHandler toggleModal={toggle} jobs={jobs} placeholdersLength={placeholdersLength} />

                  <Paginator
                    pageCount={pageCount}
                    updateList={filtersList => updateJobs(filtersList, history)}
                    page={page}
                  />
                </div>
              </section>
            )}
          </ModalConsumer>
        </ScreenStateMessage>
      );
    }
  )
);

export default MyJobsScreen;
