import React, { useState, useEffect, useContext } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { generate } from "utils/qsUtil";

import TabRouter from "./components/TabRouter";
import InitContext from "../../contexts/HeadhuntContext";
import HeadhuntingHeading from "./components/HeadhuntingHeading";

import ScreenStateMessage from "../../components/ScreenStateMessage";
import AppContext from "../../../contexts/init";

const HeadhuntingScreen = inject("store")(
  observer(
    ({
      store: {
        onSectionMount,
        getCommonCountries,
        applyFilter,
        applyParams,
        applyTerm,
        appliedFilters,
        searchTerm,
        resetFilters,
        searchesCount,
        params,
        state,
        paramsCount
      }
    }) => {
      // App initialization
      const history = useHistory();
      const [initial, setInitial] = useState({});
      const { current_employer: currentEmployer } = useContext(AppContext);
      const isHeadHuntingSavedFolders = history.location.pathname.includes("headhunting/saved/folders");

      // Setting up initial context, applying filters from query string
      useEffect(() => {
        const { filters, page, per_page } = qs.parse(history.location.search, { ignoreQueryPrefix: true });

        if (filters) {
          applyFilter(filters);

          if (filters.term) {
            applyTerm(filters.term);
          }
        }

        if (page && per_page) {
          applyParams({ page, per_page });
        }

        document.title = "Headhunting";

        onSectionMount(currentEmployer.id).then(data => {
          setInitial(data);
        });

        getCommonCountries();
      }, []);

      // Updating query string when filters change
      useEffect(() => {
        history.replace(
          `${history.location.pathname}?${generate(toJS({ ...appliedFilters, term: searchTerm }), toJS(params))}`
        );
      }, [appliedFilters, searchTerm, params]);

      const handleRedirection = () => {
        if (/shortlist/.test(state.type))
          window.location.replace(
            `${window.location.origin}/jobs/${state.callback}/applicants?filters[scope]=shortlisted`
          );
        else {
          resetFilters(true, true, true);
          history.push("/headhunting/searches");
        }
      };

      return (
        <ScreenStateMessage
          state={toJS(state)}
          loadingMessage={toJS(state).message || "Loading..."}
          redirect={handleRedirection}
        >
          <InitContext.Provider value={initial}>
            {!isHeadHuntingSavedFolders && (
              <HeadhuntingHeading
                mode={paramsCount > 0}
                searchTab={searchesCount > 0}
                reset={() => resetFilters(true, true, true)}
              />
            )}
            <TabRouter />
          </InitContext.Provider>

          <div id="modal-root" />
        </ScreenStateMessage>
      );
    }
  )
);

export default HeadhuntingScreen;
