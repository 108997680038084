import { Link } from "react-router-dom";
import styled from "styled-components";

export const Content = styled.div`
  margin-top: 20px;
`;

export const FlexHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .fz-dropdown-itm {
    height: auto;
    min-height: 36px;

    .fz-dropdown-itm__text {
      word-break: break-word;
      white-space: break-spaces;
      line-height: 18px;
    }
  }

  .fz-heading-title {
    margin: 0 0 8px 0;
  }
`;

export const ImageContent = styled.div`
  width: 106px;
  height: 138px;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #333333;
  display: block;
  margin-top: 24px;
`;

export const Count = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 24px;
`;

export const Item = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #eee;
  margin-bottom: 16px;
`;

export const ItemHeading = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
`;

export const ItemHeadingFlex = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

export const ItemImage = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fff;
  flex: none;

  img {
    max-width: 100%;
    display: block;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  path {
    fill: #c5c5c5;
  }
`;

export const ItemName = styled.h5`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #333;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin: 0 0 4px 0;
`;

export const ItemCompany = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const ItemFooter = styled.div`
  padding-top: 16px;
  display: flex;
  gap: 40px;
  align-items: flex-start;
`;

export const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const ItemLabel = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 6px;
`;

export const ItemDate = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 500;
`;

export const ItemStage = styled.p`
  padding: 3px 8px;
  gap: 4px;
  border-radius: 8px;
  background-color: rgba(243, 243, 243, 0.9);
  color: #000;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  display: inline-block;
  text-transform: capitalize;
`;

export const ItemStatus = styled.p`
  padding: 3px 8px;
  gap: 4px;
  border-radius: 8px;
  background-color: #e1f6e0;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  display: inline-block;
  text-transform: capitalize;
`;

export const ShowAllButton = styled.button`
  color: #408bfc;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  padding: 12px;
  background: #f3f3f3;
  width: calc(100% + 30px);
  margin: 0 -15px -8px -15px;
  display: block;
`;

export const ViewCandidate = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #408bfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const ViewCandidateUnavailable = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  /*
  div {
    width: 13px;
    height: 13px;
  }
    */
`;

export const TooltipContent = styled.div`
  width: 280px;
`;

export const tooltipContentStyles = {
  width: "13px",
  height: "13px"
};
