import React from "react";
import { Provider } from "mobx-react";
import { Switch, Route, Redirect } from "react-router-dom";

import HeadhuntScreen from "../../pages/HeadhuntScreen";
import TalentCvScreen from "../../pages/TalentCvScreen";
import store from "../../stores/Headhunt";

const HeadhuntApp = ({ match: { path } }) => {
  return (
    <Provider store={store}>
      <Switch>
        <Redirect exact from={path} to={`${path}/find`} />
        <Route
          exact
          path={[`${path}/:tab`, `${path}/saved/folders/`, `${path}/saved/folders/:folder`]}
          component={HeadhuntScreen}
        />
        <Route exact path={[`${path}/:tab/:id`, `${path}/saved/folders/:folder/:id`]} component={TalentCvScreen} />
      </Switch>
    </Provider>
  );
};

export default HeadhuntApp;
