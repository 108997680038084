import React, { useContext, useEffect, useRef, useState } from "react";
import { toJS } from "mobx";

import { buildOptionsByIdAndTitle, buildOptionsByIdAndName, buildOptionsBySlugAndName } from "b2b/utils/helpers";
import { SelectsViewComponent, ClearAll, SelectsViewFlex } from "./styled";
import SelectSearch from "../../../../components/SelectSearch";
import AppContext from "../../../../../contexts/init";

const Filters = ({ initFilters, onChange, appliedFilters, getSearchCandidatesUserFilter }) => {
  const stageOptions = buildOptionsBySlugAndName(initFilters.recruitment_phases || []);
  const campaignOptions = buildOptionsByIdAndTitle(initFilters.positions || []);
  const companyOptions = buildOptionsByIdAndName(initFilters.employers || []);
  const refContainer = useRef();

  const [campaignValue, setCampaignValue] = useState("");
  const [companyValue, setCompanyValue] = useState("");
  const [stageValue, setStageValue] = useState("");
  const { current_user: currentUser } = useContext(AppContext);
  const isLeadHr = currentUser?.roles?.includes("lead_hr");

  const handleChangeCompanyValue = value => {
    setCompanyValue(value);
  };

  const handleChangeCampaignValue = value => {
    setCampaignValue(value);
  };

  const handleChangeStageValue = value => {
    setStageValue(value);
  };

  const handleChangeCompany = value => {
    onChange({ ...appliedFilters, employer_ids: value?.value || "" });
  };

  const handleChangeCampaigns = value => {
    onChange({ ...appliedFilters, campaign_ids: value?.value || "" });
  };

  const handleChangeEmployer = value => {
    onChange({ ...appliedFilters, recruitment_phase: { ids: value?.value ? [value?.value] : "" } });
  };

  const handleClearAll = () => {
    onChange({ term: "", sort_order: "", recruitment_phase: { ids: "" }, campaign_ids: "", employer_ids: "" });
  };

  const { recruitment_phase: stageId, campaign_ids: campaignType, employer_ids: employerId, term } = appliedFilters;

  const isActiveSomeFilter = stageId?.ids.length || campaignType || employerId || term;

  const activeCampaign = campaignType
    ? campaignOptions.find(item => String(item.value) === String(campaignType))
    : null;

  const activeEmployer = employerId ? companyOptions.find(item => String(item.value) === String(employerId)) : null;

  const recruitmentPhaseActive = stageId?.ids?.length
    ? { value: toJS(stageId)?.ids?.[0], label: toJS(stageId)?.ids?.[0] }
    : null;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (companyValue.length >= 2 || campaignValue.length >= 2) {
        getSearchCandidatesUserFilter({ employer_term: companyValue, campaign_term: campaignValue });
      }
    }, 700);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [companyValue, campaignValue]);

  return (
    <SelectsViewComponent ref={refContainer}>
      <SelectsViewFlex>
        {isLeadHr && (
          <SelectSearch
            value={activeEmployer}
            defaultValue="Company"
            options={companyOptions}
            onInputChange={handleChangeCompanyValue}
            onSelect={handleChangeCompany}
            inputValue={companyValue}
            isHideCloseIcon
          />
        )}

        <SelectSearch
          value={activeCampaign}
          defaultValue="Campaign"
          options={campaignOptions}
          onInputChange={handleChangeCampaignValue}
          onSelect={handleChangeCampaigns}
          inputValue={campaignValue}
          isHideCloseIcon
        />

        <SelectSearch
          value={recruitmentPhaseActive}
          defaultValue="Stage"
          options={stageOptions}
          onInputChange={handleChangeStageValue}
          onSelect={handleChangeEmployer}
          inputValue={stageValue}
          isHideCloseIcon
        />
      </SelectsViewFlex>
      <ClearAll onClick={handleClearAll} $isActive={isActiveSomeFilter}>
        Clear all
      </ClearAll>
    </SelectsViewComponent>
  );
};

export default Filters;
