import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 24px;
  max-width: 1206px;
  margin: 0 auto;
  width: 100%;
`;

export const Title = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  display: block;
  margin: 0 0 8px 0;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 32px 0;
`;

export const AddQuestionButton = styled.button`
  border-radius: 8px;
  background: #408bfc;
  display: flex;
  gap: 4px;
  //height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  outline: none;
  text-decoration: none;
`;
