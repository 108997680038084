import React from "react";
import { inject, observer } from "mobx-react";

import SearchInput from "b2b/components/SearchInput";
import { SearchContent } from "./styled";

const SearchTalent = inject("store")(
  observer(({ store: { applyTerm, searchTerm } }) => {
    const handleChange = value => {
      applyTerm(value.term);
    };

    return (
      <SearchContent>
        <SearchInput onChange={handleChange} placeholder="Search in folder" initValue={searchTerm} />
      </SearchContent>
    );
  })
);

export default SearchTalent;
