import React, { useState, useRef, useEffect } from "react";
import { FileBlank } from "@styled-icons/boxicons-solid";
import { MdFileUpload, MdClose } from "react-icons/md";

import { Input } from "../../../b2c/components/Core";
import {
  Content,
  EmptyState,
  EmptyStateTitle,
  EmptyStateText,
  TransparentButton,
  FileContent,
  FileName,
  FileBlankIcon,
  CloseIcon
} from "./styled";

const FileUploader = ({ onUpload, accept = "pdf, .doc, .docx, .rtf", isEdit, editFileData }) => {
  const ref = useRef();
  const [fileName, setName] = useState("");
  const [fileSize, setFileSize] = useState(0);

  const onDragOver = e => {
    e.preventDefault();
  };

  const onDrop = e => {
    e.preventDefault();

    if (e.dataTransfer) {
      if (e.dataTransfer.items) {
        if (e.dataTransfer.items[0].kind === "file") {
          const file = e.dataTransfer.items[0].getAsFile();
          setFile(file);
          // setUploaded(true);
        }
      } else {
        setFile(e.dataTransfer.files[0]);
        // setUploaded(true);
      }
    }
  };

  const handleUpload = e => {
    const file = e.target.files[0];
    setFile(file);
    // setUploaded(true);
  };

  const setFile = file => {
    if (file) {
      setFileSize(file.size);
      setName(file.name);
      if (onUpload) {
        onUpload(file);
      }
    }
  };

  const bytesToMB = bytes => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  const handleRemoveFile = () => {
    setFileSize(0);
    setName("");
    // setUploaded(false);
    if (onUpload) {
      onUpload("");
    }
  };

  useEffect(() => {
    if (isEdit && editFileData.name && !fileName) {
      setName(editFileData.name);
      setFileSize(editFileData.size);
    }
  }, [editFileData.name]);

  return (
    <>
      {fileName ? (
        <FileContent>
          <FileBlankIcon>
            <FileBlank />
          </FileBlankIcon>
          <FileName>
            {fileName} {fileSize && <span>{bytesToMB(fileSize)}md</span>}
          </FileName>
          <CloseIcon onClick={handleRemoveFile}>
            <MdClose />
          </CloseIcon>
        </FileContent>
      ) : (
        <Content onDrop={onDrop} onDragOver={onDragOver}>
          <EmptyState>
            <MdFileUpload />
            <EmptyStateTitle>Click or Drag file here to upload</EmptyStateTitle>
            <EmptyStateText>
              Upload .pdf, .doc, .docx or .rtf
              <br />
              Max file size 5MB
            </EmptyStateText>
          </EmptyState>
          <Input.File
            accept={accept}
            onChange={e => handleUpload(e)}
            Component={props => <TransparentButton ref={ref} type="button" {...props} />}
          />
        </Content>
      )}
    </>
  );
};

export default FileUploader;
