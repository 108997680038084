import React, { useState, useMemo, useContext } from "react";
import { MdClose, MdDone, MdEmail, MdForward, MdPersonPin } from "react-icons/md";
import { FaFacebookSquare, FaTwitter, FaLinkedin } from "react-icons/fa";

import Button from "components/Button";
import Dropdown from "components/Selects";
import ModalConsumer from "contexts/modal";
import AppContext from "contexts/init";
import { TABS } from "constants/tabs";
import TagList from "../../../../components/TagList";
import ModalContent from "../ApplicantModalContent";
import MessageButton from "../../../../components/MessageButton";
import Subscription from "../../../../components/Subscription";
import UserCvCard from "../../../../components/UserCv/UserCvCard";
import RejectionMessagesModalContent from "../../../JobDetailScreen/components/RejectionMessagesModalContent";
import ContactOptionsModal from "../../../../components/ContactOptionsModal";
import ActionsDropdown from "../ActionsDropdown";
import SelectJobForInviteContent from "../../../JobDetailScreen/components/SelectJobForInviteContent";
import InviteToApplyContent from "../../../JobDetailScreen/components/InviteToApplyContent";
import { getCurrentUserAndEmployer } from "../../../../utils/helpers";

const ApplicantCvCard = ({
  id,
  user_id,
  unlockUser,
  name = "",
  avatar,
  country = "",
  current_position_title = "",
  current_position_employer = null,
  first_name: firstName,
  tab = "",
  type = 1,
  age = 0,
  current_phase: currentPhase,
  shortlist,
  removeTalent,
  move,
  message,
  scopes = [],
  tags = [],
  candidateTag,
  createPdf,
  activeTab = "Profile",
  handleTabChange,
  email = "",
  phone_number: phoneNumber = "",
  facebook = "",
  linkedin = "",
  twitter = "",
  website = "",
  shortcuts = [],
  customCv,
  reasons,
  campaign,
  candidate,
  rejectMessage,
  profileUnlocked,
  conversation,
  hideActions,
  isFastApply,
  showModalInviteCandidate,
  getAvailableJobForCandidate,
  showPersonalityAndTalentTab,
  // createShareCandidateUserForComment, delete after release
  // createCommentShareCandidate,
  isSharedCandidates,
  campaignEmployerId,
  details,
  isHeadhunt,
  isExternalCandidate,
  last_name: lastName = "",
  links,
  fuzu_country_id: fuzuCountryId = "",
  location_id: locationId = "",
  phone_code: phoneCode = "",
  source,
  file,
  prospect_id: prospectId = "",
  can_switch_candidate: canSwitchCandidate,
  candidate_source: candidateSource,
  id: candidateId,
  cv_match: cvMatch,
  highest_education_degree: highestEducationDegree,
  total_work_experience: totalWorkExperience,
  getActiveCompanies,
  guestAccessibleSections,
  systemTags
}) => {
  const [adding, toggleTags] = useState(false);

  const switchCandidateData = {
    cvMatch,
    highestEducationDegree,
    totalWorkExperience,
    candidateId
  };

  const externalCandidateEditData = {
    linkedIn: links?.linkedin || "",
    website: links?.website || "",
    email: email || details?.email,
    number: phoneNumber,
    cvUpload: file?.name || file,
    source,
    currentTitle: current_position_title,
    firstName,
    lastName,
    fuzuCountryId,
    locationId,
    code: phoneCode,
    prospectId
  };

  const {
    is_current_user_external_collaborator: isCurrentUserExternalCollaborator,
    current_employer: currentEmployer,
    current_user: currentUser
  } = useContext(AppContext);

  const Name = useMemo(
    () => (!profileUnlocked || currentPhase === "rejected" || tab === TABS.HEADHUNT ? firstName : name),
    [name, currentPhase, tab]
  );

  const Scopes = useMemo(
    () =>
      tab === TABS.HEADHUNT
        ? scopes.filter(option => !/rejected|shortlisted|applied|all/.test(option.value))
        : type > 1
          ? scopes.filter(option => !/rejected|all/.test(option.value))
          : scopes,
    [scopes]
  );

  const handleShortlist = () => {
    if (isFastApply) {
      showModalInviteCandidate();
    } else {
      shortlist();
    }
  };

  const messageButton = (isMessageText, icon) => (
    <MessageButton
      isUnlocked={profileUnlocked}
      unlockHandler={unlockHandler}
      messageUrl={message ? message() : null}
      type={tab === TABS.HEADHUNT ? "talent" : "candidate"}
      users={[messageUserObject]}
      campaignEmployerId={campaignEmployerId}
      Component={props => (
        <Subscription.Upgrade permission="messaging">
          <Button
            className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
            icon={icon ? <MdEmail className="fz-btn-secondary__icon" /> : null}
            {...props}
          >
            <span className="fz-btn-secondary__text">
              {typeof isMessageText === "boolean" && isMessageText ? "Message" : "Next"}
            </span>
          </Button>
        </Subscription.Upgrade>
      )}
    />
  );

  const handleOpenSelectJobForInvite = (toggle, current) => {
    toggle(
      <SelectJobForInviteContent
        selectedTalents={[current.id]}
        handleInviteToApply={() => handleInviteToApply(toggle, current)}
        handleClose={() => handleInviteToApply(toggle, current)}
      />,
      "Select job",
      false
    );
  };

  const handleInviteToApply = (toggle, current) => {
    toggle(
      <InviteToApplyContent
        currentId={current.user_id}
        currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
        toggle={toggle}
        talents={[candidate]}
        addToJob={() => handleOpenSelectJobForInvite(toggle, current)}
        handleInviteToApply={() => handleInviteToApply(toggle, current)}
        isJobHeadhunt
        campaign={campaign}
        isJobHeadhuntPage
      />,
      "Invite talent to apply"
    );
  };

  const showContactOptionsModal = toggle => {
    toggle(
      <ContactOptionsModal
        isUnlocked={profileUnlocked}
        unlockHandler={unlockHandler}
        messageButton={messageButton}
        handleInviteToApply={() => handleInviteToApply(toggle, candidate)}
        isJobHeadhuntPage
        candidate={candidate}
        getAvailableJobForCandidate={getAvailableJobForCandidate}
        getActiveCompanies={getActiveCompanies}
        isJobHeadhunt
      />,
      "How do you want to contact the candidate?"
    );
  };

  const handleRejectionMessages = toggle => {
    toggle(
      <RejectionMessagesModalContent
        reasons={reasons}
        ids={[user_id]}
        id={+id}
        campaign={campaign}
        rejectMessage={rejectMessage}
        currentEmployer={currentEmployer}
        currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser, true)}
        selectedCandidates={candidate.id ? [candidate.id] : [+id]}
        candidates={[candidate]}
        toggle={toggle}
      />,
      "Reject and provide candidates with feedback"
    );
  };

  const handleModalContent = (toggle, title, state, callback) => {
    toggle(
      <ModalContent
        close={toggle}
        state={state}
        callback={callback}
        email={isExternalCandidate && details?.email ? details.email : email}
        phone={isExternalCandidate && details?.phone_number ? details.phone_number : phoneNumber}
        facebook={facebook}
        linkedin={isExternalCandidate && links && links.linkedin ? links.linkedin : linkedin}
        twitter={twitter}
        website={isExternalCandidate && links && links.website ? links.website : website}
        nationalId={details?.passport_number}
      />,
      title
    );
  };

  const messageUserObject = {
    id: tab === TABS.HEADHUNT ? user_id : id,
    name,
    conversation
  };

  const unlockHandler = () => {
    return unlockUser(user_id, id, true, campaignEmployerId);
  };

  const unlockHandlerWithoutReload = () => {
    return unlockUser(user_id, id, false, campaignEmployerId);
  };

  return (
    <UserCvCard
      name={Name}
      avatar={avatar}
      country={country}
      current_position_title={current_position_title}
      current_position_employer={current_position_employer}
      age={age}
      activeTab={activeTab}
      handleTabChange={handleTabChange}
      customCv={customCv}
      profileUnlocked={profileUnlocked}
      unlockHandler={unlockHandler}
      hasPersonality={showPersonalityAndTalentTab}
      isSharedCandidates={isSharedCandidates}
      isExternalCandidate={isExternalCandidate}
      externalCandidateEditData={externalCandidateEditData}
      canSwitchCandidate={canSwitchCandidate}
      candidateSource={candidateSource}
      switchCandidateData={switchCandidateData}
      guestAccessibleSections={guestAccessibleSections}
      isHeadhunt={isHeadhunt}
      AdditionalContent={
        <>
          {isSharedCandidates ? (
            <></>
          ) : (
            <TagList
              shortcuts={shortcuts}
              visible={adding}
              tags={isExternalCandidate && !tags.includes("Sourced") ? tags.concat("Sourced") : tags}
              onCancel={() => toggleTags(false)}
              addTag={tag => candidateTag(tag, "add")}
              removeTag={tag => candidateTag(tag, "remove")}
              systemTags={systemTags}
            />
          )}
        </>
      }
    >
      {!hideActions && (
        <ModalConsumer>
          {({ toggle }) => (
            <>
              {!isSharedCandidates && (
                <div className="fz-applicant__additional fz-applicant__additional_absolute">
                  <div className="fz-buttons-row mt-2">
                    {(currentPhase !== "shortlisted" || tab === TABS.HEADHUNT) && (
                      <Subscription.Payment element="Candidate pipeline">
                        <div
                          role="button"
                          tabIndex={-1}
                          className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
                          onClick={handleShortlist}
                        >
                          <MdDone className="fz-btn-secondary__icon" />
                          <span className="fz-btn-secondary__text">Shortlist</span>
                        </div>
                      </Subscription.Payment>
                    )}
                    {tab === TABS.HEADHUNT && (
                      <Subscription.Payment element="Candidate pipeline">
                        <Button
                          className="fz-btn-secondary fz-btn-secondary_yellow mr-1"
                          icon={<MdEmail className="fz-btn-secondary__icon" />}
                          onClick={() => showContactOptionsModal(toggle)}
                        >
                          <span className="fz-btn-secondary__text">Contact</span>
                        </Button>
                      </Subscription.Payment>
                    )}

                    {tab !== TABS.HEADHUNT &&
                      !isCurrentUserExternalCollaborator &&
                      !isSharedCandidates &&
                      !isExternalCandidate &&
                      messageButton(true, true)}

                    <>
                      {type > 2 && currentPhase !== "rejected" && !isExternalCandidate && (
                        <Subscription.Payment element="Rejecting">
                          <div
                            tabIndex={-1}
                            role="button"
                            className="fz-btn-secondary mr-1"
                            onClick={() => handleRejectionMessages(toggle)}
                          >
                            <MdClose className="fz-btn-secondary__icon" />
                            <span className="fz-btn-secondary__text">Reject</span>
                          </div>
                        </Subscription.Payment>
                      )}

                      {type > 1 && (
                        <div
                          role="button"
                          tabIndex={-1}
                          className="fz-btn-secondary mr-1"
                          onClick={() => handleModalContent(toggle, "Confirmation", "Remove", removeTalent)}
                        >
                          <MdClose className="fz-btn-secondary__icon" />
                          <span className="fz-btn-secondary__text">Remove</span>
                        </div>
                      )}
                    </>

                    {!isSharedCandidates && (
                      <Dropdown
                        selectOptions={Scopes}
                        onSelect={phase => (phase === "rejected" ? handleRejectionMessages(toggle) : move(phase))}
                      >
                        <Button className="fz-btn-secondary mr-1" overwriteClass icon={<MdForward />}>
                          <Subscription.Payment element="Candidate pipeline">
                            <span className="fz-btn-secondary__text">Move to</span>
                          </Subscription.Payment>
                        </Button>
                      </Dropdown>
                    )}

                    <ActionsDropdown
                      hideActions={tab === TABS.HEADHUNT || tab === TABS.APPLICANTS}
                      phase={currentPhase}
                      addTag={() => toggleTags(true)}
                      move={move}
                      options={scopes}
                      message={message}
                      createPdf={createPdf}
                      adding={adding}
                      isExternal={isCurrentUserExternalCollaborator}
                      customCv={customCv}
                      profileUnlocked={profileUnlocked}
                      unlockHandler={unlockHandler}
                      user_id={user_id}
                      candidate={messageUserObject}
                      isExternalCandidate={isExternalCandidate}
                    />
                  </div>
                </div>
              )}
              <div className="fz-applicant__social">
                {isSharedCandidates ? (
                  <>
                    {profileUnlocked && (
                      <Button.Simple
                        icon={<MdPersonPin />}
                        onClick={() => handleModalContent(toggle, "Contact details", "Contact", shortlist)}
                      >
                        Contact details
                      </Button.Simple>
                    )}
                  </>
                ) : (
                  <>
                    <Subscription.Unlock
                      unlocked={profileUnlocked}
                      element="Contact details"
                      unlockHandler={unlockHandlerWithoutReload}
                      paymentCheck
                    >
                      <Button.Simple
                        icon={<MdPersonPin />}
                        onClick={() =>
                          handleModalContent(
                            toggle,
                            "Contact details",
                            profileUnlocked ? "Contact" : "Shortlist",
                            shortlist
                          )
                        }
                      >
                        Contact details
                      </Button.Simple>
                    </Subscription.Unlock>
                    {profileUnlocked && (
                      <>
                        {linkedin && (
                          <Button.Simple
                            icon={<FaLinkedin />}
                            tag="a"
                            href={linkedin}
                            target="_blank"
                            className="mr-1 ml-3"
                          />
                        )}
                        {twitter && (
                          <Button.Simple icon={<FaTwitter />} tag="a" href={twitter} target="_blank" className="mr-1" />
                        )}
                        {facebook && (
                          <Button.Simple icon={<FaFacebookSquare />} tag="a" href={facebook} target="_blank" />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </ModalConsumer>
      )}
    </UserCvCard>
  );
};

export default ApplicantCvCard;
