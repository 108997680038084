import React from "react";

import BaronaLogoSvg from "images/barona-logo.svg";
import { isBaronaBrand } from "contexts/init";
import { BaronaLogoContainer } from "./styled";

const BaronaLogo = ({ children, color }) => {
  const isBarona = isBaronaBrand();

  return (
    <>
      {isBarona ? (
        <BaronaLogoContainer color={color}>
          <BaronaLogoSvg />
        </BaronaLogoContainer>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default BaronaLogo;
