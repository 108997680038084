import React, { useContext, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";

import Layout from "../../../../components/Layout";
import FolderList from "../FolderList";
import SavedTalentList from "../SavedTalentList";
import AppContext from "../../../../../contexts/init";
import SelectJobForInviteContent from "../../../../components/SelectJobForInviteContent";
import InviteToApplyContent from "../../../../components/InviteToApplyContent";
import { getCurrentUserAndEmployer } from "../../../../utils/helpers";

const SavedTalent = inject("store")(
  observer(
    ({
      store: {
        folders,
        setFolder,
        resetFilters,
        removeFromFolder,
        deleteFolder,
        folderName,
        talents,
        applyFilter,
        appliedFilters,
        moveToStage,
        getFolderStages,
        folderStages,
        renameFolder,
        exportBatchCvProfile
      }
    }) => {
      const { current_employer: currentEmployer, current_user: currentUser } = useContext(AppContext);
      const isLeadHr = currentUser?.roles?.includes("lead_hr");

      const handleOpenSelectJobForInvite = (toggle, currentId) => {
        toggle(
          <SelectJobForInviteContent
            selectedTalents={[currentId]}
            handleInviteToApply={() => handleInviteToApply(toggle, currentId)}
            handleClose={() => handleInviteToApply(toggle, currentId)}
          />,
          "Select job",
          false
        );
      };

      const handleInviteToApply = (toggle, currentId, selectedTalents) => {
        toggle(
          <InviteToApplyContent
            currentId={currentId}
            currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
            selectedCandidatesIds={selectedTalents}
            toggle={toggle}
            talents={talents}
            addToJob={() => handleOpenSelectJobForInvite(toggle, currentId)}
          />,
          "Invite talent to apply"
        );
      };

      const { folder = "" } = useParams();

      useEffect(() => {
        if (folder) setFolder(folder);
      }, [folder]);

      return (
        <Layout.Container type="wide">
          <Switch>
            <Route exact path="/headhunting/saved">
              <FolderList folders={folders} deleteFolder={deleteFolder} />
            </Route>

            <Route exact path="/headhunting/saved/folders/:folder">
              <SavedTalentList
                name={folderName}
                folder={folder}
                folders={folders}
                reset={() => resetFilters(true, true)}
                removeFromFolder={removeFromFolder}
                handleInviteToApply={handleInviteToApply}
                applyFilter={applyFilter}
                appliedFilters={appliedFilters}
                moveToStage={moveToStage}
                folderStages={folderStages}
                getFolderStages={getFolderStages}
                renameFolder={renameFolder}
                exportBatchCvProfile={exportBatchCvProfile}
                isLeadHr={isLeadHr}
              />
            </Route>

            <Redirect exact from="/headhunting/saved/folders" to="/headhunting/saved" />
          </Switch>
        </Layout.Container>
      );
    }
  )
);

export default SavedTalent;
