import React, { useState, useEffect, useRef } from "react";
import InputRange from "react-input-range";
import cn from "classnames";

import { onEnterPress } from "utils/helpers";

const RangeSlider = ({
  onChange,
  reset,
  loading,
  minValue = 0,
  maxValue = 0,
  isShowDots,
  step = 1,
  applied = { min: 0, max: 1 }
}) => {
  const [value, setValue] = useState(applied);
  const [inputs, setInputs] = useState(applied);

  const minInput = useRef();
  const maxInput = useRef();

  useEffect(() => {
    if (inputs.min !== value.min || inputs.max !== value.max) {
      setValue({ min: onHandleMinValueChange(inputs.min), max: onHandleMaxValueChange(inputs.max) });
    }
  }, [inputs]);

  useEffect(() => {
    if (!reset) {
      const newVal = { min: Math.max(minValue, applied.min), max: Math.min(applied.max, maxValue) };
      setValue(prev => ({ ...prev, ...newVal }));
      setInputs(prev => ({ ...prev, ...newVal }));
    }
  }, [applied, minValue, maxValue]);

  useEffect(() => {
    if (reset) {
      setValue({ min: minValue, max: maxValue });
      setInputs({ min: minValue, max: maxValue });
    }
  }, [reset, minValue, maxValue]);

  const onHandleMinValueChange = val => {
    if (isNaN(parseInt(val)) || val <= minValue) {
      return minValue;
    }
    if (val >= value.max) {
      return value.max - 1;
    }
    return parseInt(val);
  };

  const onHandleMaxValueChange = val => {
    if (isNaN(parseInt(val)) || val <= value.min) {
      return value.min + 1;
    }
    if (val >= maxValue) {
      return maxValue;
    }
    return parseInt(val);
  };

  const onHandleChange = val => {
    setInputs(val);
  };

  const handleInputFocus = e => e.target.select();

  const handleInputBlur = () => onChange(value);

  if (!value) {
    return null;
  }

  const renderClassName = activeValue => {
    if (activeValue > value.min && activeValue < value.max) {
      return "fz-range-slider__values-item_active";
    }

    return "";
  };

  return value.min >= minValue && value.max <= maxValue ? (
    <div className="fz-range-slider">
      <InputRange
        disabled={loading}
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        onChange={val => onHandleChange(val)}
        onChangeComplete={() => onChange(value)}
        classNames={{
          activeTrack: "fz-input-range__track fz-input-range__track--active",
          disabledInputRange: "fz-input-range fz-input-range--disabled",
          inputRange: "fz-input-range",
          labelContainer: "fz-input-range__label-container",
          maxLabel: "fz-input-range__label fz-input-range__label--max",
          minLabel: "fz-input-range__label fz-input-range__label--min",
          slider: "fz-input-range__slider",
          sliderContainer: "fz-input-range__slider-container",
          track: "fz-input-range__track fz-input-range__track--background",
          valueLabel: "fz-input-range__label fz-input-range__label--value"
        }}
        step={step}
      />
      {isShowDots && (
        <div className="fz-range-slider__values">
          {["0", "2", "4", "6", "8", "10", "10+"].map(dotValue => (
            <div
              className={cn("fz-range-slider__values-item", renderClassName(minValue + dotValue))}
              key={`${minValue}-${dotValue}`}
            >
              <span className="fz-range-slider__values-text">{dotValue}</span>
              <span className="fz-range-slider__values-dot" />
            </div>
          ))}
        </div>
      )}
      {!isShowDots && (
        <div className="fz-range-slider__label-container">
          <label htmlFor="min">
            <span className="fz-range-slider__label-text">Min</span>
            <input
              className="fz-gray-input"
              type="number"
              name="min"
              ref={minInput}
              disabled={loading}
              onChange={e => setInputs({ ...value, min: Math.max(Number(e.target.value), 0) })}
              onKeyDown={e => onEnterPress(e, value.min, () => minInput.current.blur())}
              onFocus={e => handleInputFocus(e)}
              onBlur={handleInputBlur}
              value={inputs.min}
            />
          </label>

          <label htmlFor="max">
            <span className="fz-range-slider__label-text">Max</span>
            <input
              className="fz-gray-input"
              type="number"
              name="max"
              ref={maxInput}
              disabled={loading}
              onChange={e => setInputs({ ...value, max: Math.max(Number(e.target.value), 0) })}
              onKeyDown={e => onEnterPress(e, value.max, () => maxInput.current.blur())}
              onFocus={e => handleInputFocus(e)}
              onBlur={handleInputBlur}
              value={inputs.max}
            />
          </label>
        </div>
      )}
    </div>
  ) : null;
};

export default RangeSlider;
