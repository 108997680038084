import React, { useRef, useState } from "react";

const Input = ({
  placeholder = "",
  type = "text",
  icon,
  onChange,
  autoFocus = false,
  extraClass = "",
  defaultValue = "",
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);
  const handleInputFocus = e => e.target.select();

  const handleChange = values => {
    setValue(values);
    !!onChange && onChange(values);
  };

  return (
    <div className="fz-std-input">
      <input
        className={`${extraClass} fz-std-input__control `}
        placeholder={placeholder}
        autoFocus={autoFocus}
        type={type}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onFocus={e => handleInputFocus(e)}
        {...rest}
      />
      <div className="fz-campaign-searchbar__btn">{icon}</div>
    </div>
  );
};

Input.File = ({ children, ...rest }) => {
  const inputRef = useRef();

  return (
    <>
      {React.cloneElement(React.Children.only(children), { onClick: () => inputRef.current?.click() })}
      <input ref={inputRef} className="hide" type="file" {...rest} />
    </>
  );
};

export default Input;
