import React from "react";

import { Modal, Title, Text, Delete, Flex, Cancel } from "./styled";

const GuestLinkRemoveAccess = ({ close, deleteGuestLink, text, handleOpenGuestLinkModalEdit }) => {
  const handleRemove = () => {
    deleteGuestLink();
    close();
  };

  const handleClose = () => {
    close();
    handleOpenGuestLinkModalEdit();
  };

  return (
    <Modal>
      <Title>Remove access?</Title>

      <Text>{`${text} will lose access to the link immediately.`}</Text>

      <Flex>
        <Cancel onClick={handleClose}>Cancel</Cancel>
        <Delete onClick={handleRemove}>Yes, remove</Delete>
      </Flex>
    </Modal>
  );
};

export default GuestLinkRemoveAccess;
