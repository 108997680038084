import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";

import { getCompanyName } from "contexts/init";
import { validateEmail } from "utils/validation";
import { Input, Text } from "../../../b2c/components/Core";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";
import PasswordFooter from "../../components/PasswordFooter";
import { Form, StyledButton } from "./styled";
import { theme } from "../../../b2c/contexts/theme";

const defaultValidation = {
  email: { error: false }
};

const validateForm = ({ email }) => {
  const validation = {
    email: validateEmail(email)
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};

const ForgotPassword = ({ User: { forgotPassword, state } }) => {
  const { error, processing } = state;
  const history = useHistory();
  const email = history?.location?.state?.email;
  const [validationState, setValidation] = useState(defaultValidation);
  const [formState, setFormState] = useState({
    email: email || ""
  });

  const validation_errors = {};

  const handleSubmission = e => {
    e.preventDefault();
    setValidation(defaultValidation);

    const { valid, validation } = validateForm(formState);
    setValidation(validation);
    if (valid) {
      forgotPassword(formState).then(() => {
        history.push("/check-email");
      });
    }
  };

  return (
    <CreateLoginFlowWrapper
      title={`Reset the password to your ${getCompanyName()} employer account`}
      description="Enter the email address associated with your account, and we’ll email you a link to reset your password."
    >
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Form onSubmit={handleSubmission} noValidate>
        <div>
          <Input
            key="email"
            type="email"
            label={
              <Text fontSize={14} fontWeight={600} lineHeight="21px">
                Email address
              </Text>
            }
            autoComplete="email"
            fieldRequired={false}
            value={formState?.email}
            onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
            errorMessage={error ? " " : validationState?.email?.error || validation_errors?.email}
          />
        </div>

        {error === "Email address does not exist in our system." ? (
          <>
            <Text fontSize={14} fontWeight={400} width="100%" color="accent300">
              {"An account with this email address was not found. If you need   "}
            </Text>
            <Text fontSize={14} fontWeight={400} width="100%" color="accent300">
              {"any support, contact "}
              <a href="mailto:business@fuzu.com" style={{ textDecoration: "none" }}>
                <Text
                  color="accent300"
                  fontSize={14}
                  fontWeight={600}
                  style={{
                    display: "inline"
                  }}
                >
                  {"business@fuzu.com. "}
                </Text>
              </a>
            </Text>
          </>
        ) : (
          error && (
            <Text color="accent300" margin="4px 0 0">
              {error}
            </Text>
          )
        )}

        <StyledButton
          processing={processing}
          type="submit"
          fontSize="14px"
          margin="15px 0"
          width="100%"
          height="48px"
          color={theme.primaryButtonLabelColor}
          background={theme.primaryButtonBackground}
        >
          Reset password
        </StyledButton>
      </Form>
      <PasswordFooter />
    </CreateLoginFlowWrapper>
  );
};

export default inject("User")(observer(ForgotPassword));
