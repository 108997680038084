import React from "react";

import { getCompanyName } from "contexts/init";
import { TABS } from "constants/tabs";
import SearchInput from "../SearchInput";
import StatusFiltersBarOption from "../StatusFiltersBarOption";
import { InfoBar } from "../SubscriptionBar";

const StatusFiltersBar = ({ tab = "", onChange, onSearch, scopes = [], currentScope, placeholder, term }) => {
  const renderScopes = () =>
    scopes.map(scope => (
      <StatusFiltersBarOption
        key={scope.value}
        scope={scope}
        currentScope={currentScope}
        onChange={() => onChange({ scope: scope.value })}
      />
    ));

  return (
    <div className="fz-filter-bar">
      <div className="fz-filter-bar__main" data-test-scope="scopes">
        {tab !== TABS.HEADHUNT && scopes ? renderScopes() : <></>}

        {tab === TABS.HEADHUNT && (
          <InfoBar
            title={
              <span>
                These candidates have been picked from the <a href="\">{getCompanyName()} talent pool</a>
              </span>
            }
            text=" Please note that they have not applied for your position – you need to contact them directly."
          />
        )}
      </div>
      {tab !== "headhunt" && (
        <div className="fz-filter-bar__additional">
          <SearchInput initValue={term} placeholder={placeholder} onChange={filters => onSearch(filters)} />
        </div>
      )}
    </div>
  );
};

export default StatusFiltersBar;
