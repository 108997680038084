import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Provider } from "mobx-react";

import JobDetailScreen from "../../pages/JobDetailScreen";
import ApplicantCvScreen from "../../pages/ApplicantCvScreen";
import TutorialsScreen from "../../pages/TutorialsScreen";
import MyJobsScreen from "../../pages/MyJobsScreen";
import MyJobsStore from "../../stores/MyJobsStore";
import CampaignsStore from "../../stores/CampaignsStore";
import OnboardingStore from "../../stores/Onboarding";
import Onboarding from "../../components/Onboarding";
import Campaigns from "../../pages/Campaigns";

const JobRouter = ({ match: { path } }) => {
  const history = useHistory();

  return (
    <Provider myJobsStore={MyJobsStore} campaignsStore={CampaignsStore} onboardingStore={OnboardingStore}>
      <Switch>
        <Route exact path="/campaigns" component={Campaigns} />
        <Route exact path={["/tutorials", "/tutorials/:section"]} component={TutorialsScreen} />
        <Route exact path={`${path}/`} component={MyJobsScreen} />
        <Route exact path={`${path}/:id/:tab/`} component={JobDetailScreen} />
        <Route exact path={`${path}/:id/:tab/:candidateId`} component={ApplicantCvScreen} />
        <Route
          exact
          path={`${path}/onboarding`}
          render={() => {
            window.localStorage.setItem("viewedOnboarding", "show");
            return history.goBack();
          }}
        />
        <Redirect exact from={`${path}/:id`} to={`${path}/:id/applicants`} />
      </Switch>
      <Onboarding />
    </Provider>
  );
};

export default JobRouter;
