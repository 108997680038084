import React from "react";
import { MdContentCopy } from "react-icons/md";
import DOMPurify from "dompurify";

import { formatDate } from "b2b/utils/helpers";
import { AlreadyShareCopyField, ButtonCopy, Flex } from "./styled";
import { b2cUrl, copyCodeToClipboard } from "../../../../../utils/helpers";

const JobDetailShare = ({ jobDetail, initialState }) => {
  const {
    title,
    description,
    responsibility_text: responsibility,
    requirements,
    salary_max: salaryMax,
    salary_min: salaryMin,
    salary_type: salaryType,
    permanent_position: permanentPosition,
    industry_record: industryRecord,
    category,
    campaign_end_date: campaignEndDate,
    campaign_start_date: campaignStartDate,
    job_level: jobLevel,
    job_start_date: jobStartDate,
    personality_traits: personalityTraits,
    core_skills: coreSkills,
    required_skills: requiredSkills,
    required_tests: requiredTests,
    salary_max_currency: salaryMaxCurrency,
    path
    // salary_min_currency,
    // salary_max_currency
  } = jobDetail || initialState.job_details || {};

  const formatPrice = price => {
    return new Intl.NumberFormat().format(price);
  };

  const formatPath = () => {
    return b2cUrl(path);
  };

  const handleCopy = () => {
    copyCodeToClipboard(formatPath(), () => ({}));
  };

  const renderSalaryRange = () => {
    const minSalary = Number(salaryMin);
    const maxSalary = Number(salaryMax);

    if (minSalary && maxSalary) {
      return `${salaryMaxCurrency} ${formatPrice(minSalary)} - ${formatPrice(maxSalary)}`;
    }

    if (minSalary) {
      return `${salaryMaxCurrency} ${formatPrice(minSalary)} min`;
    }

    if (maxSalary) {
      return `${salaryMaxCurrency} ${formatPrice(maxSalary)} max`;
    }

    return "Not specified";
  };

  return (
    <div className="fz-container-with-sidebar">
      <div className="fz-container-with-sidebar__content">
        <div className="fz-pane">
          <div className="fz-pane__container_case-two">
            <h2 className="fz-heading-title fz-heading-title_bold">Job Description</h2>
            <div className="fz-info-pane">
              <div className="fz-info-pane__header">
                <div className="fz-info-pane__cell">
                  <h3 className="fz-heading-subtitle">Title</h3>
                </div>
              </div>
              <div className="fz-info-pane__body">
                <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-0">{title}</p>
                <div className="fz-tag-container mt-1" />
              </div>
            </div>
            <div className="fz-info-pane">
              <div className="fz-info-pane__header">
                <div className="fz-info-pane__cell">
                  <h3 className="fz-heading-subtitle">Description</h3>
                </div>
              </div>
              <div className="fz-info-pane__body">
                {description && (
                  <div
                    className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-0"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                  />
                )}

                <div className="fz-tag-container mt-1" />
              </div>
            </div>
            <div className="fz-info-pane">
              <div className="fz-info-pane__header">
                <div className="fz-info-pane__cell">
                  <h3 className="fz-heading-subtitle">Responsibilities</h3>
                </div>
              </div>
              <div className="fz-info-pane__body">
                {responsibility && (
                  <div
                    className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-0"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(responsibility) }}
                  />
                )}

                <div className="fz-tag-container mt-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="fz-pane">
          <div className="fz-pane__container_case-two">
            <h2 className="fz-heading-title  fz-heading-title_bold">Required Languages</h2>
            <div className="fz-info-pane">
              <div className="fz-info-pane__header">
                <div className="fz-info-pane__cell" />
              </div>
              <div className="fz-info-pane__body">
                {requirements && requirements?.languages?.length
                  ? requirements?.languages.map(item => (
                      <strong key={item} className="block">
                        {item}
                      </strong>
                    ))
                  : null}
              </div>
            </div>
            <div className="fz-info-pane">
              <div className="fz-info-pane__header">
                <div className="fz-info-pane__cell">
                  <h2 className="fz-heading-title  fz-heading-title_bold">Required Skills</h2>
                </div>
              </div>
              <div className="fz-info-pane__body">
                {coreSkills?.length ? (
                  <>
                    <strong className="block">Core Skills</strong>
                    {coreSkills.map(({ name }) => {
                      return (
                        <div className="fz-tag-container" key={name}>
                          <div className="fz-tag fz-tag_active">
                            <span className="fz-tag__text">{name}</span>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}
                {requiredSkills?.length ? (
                  <>
                    <strong className="block mt-2">Required Skills</strong>
                    {requiredSkills.map(({ name }) => {
                      return (
                        <div className="fz-tag-container" key={`${name}-required-skills`}>
                          <div className="fz-tag">
                            <span className="fz-tag__text">{name}</span>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {personalityTraits.length ? (
          <div className="fz-pane">
            <div className="fz-pane__container fz-pane__container_case-two">
              <h2 className="fz-heading-title  fz-heading-title_bold">Personality Traits</h2>
              {personalityTraits.map(({ name, description: descriptionTraits, tag }) => {
                return (
                  <div className="fz-info-pane" key={name}>
                    <div className="fz-info-pane__header">
                      <div className="fz-info-pane__cell">
                        <h3 className="fz-heading-subtitle">{name}</h3>
                        <p>{descriptionTraits}</p>
                      </div>
                    </div>
                    <div className="fz-info-pane__body">
                      <div className="fz-tag fz-tag_md">
                        <span className="fz-tag__text">{tag}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {requiredTests?.length ? (
          <div className="fz-pane">
            <div className="fz-pane__container fz-pane__container_case-two">
              <h2 className="fz-heading-title">Required Tests</h2>
              {requiredTests.map(name => {
                return (
                  <p className="one-two" key={name}>
                    {name}
                  </p>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      <div className="fz-container-with-sidebar__aside">
        <div className="fz-pane">
          <div className="fz-pane__container_case-two">
            <div className="fz-info-pane mb-2 pt-2">
              <strong className="block">Public link</strong>
              <Flex>
                <AlreadyShareCopyField>{formatPath()}</AlreadyShareCopyField>
                <ButtonCopy onClick={handleCopy}>
                  <MdContentCopy />
                </ButtonCopy>
              </Flex>
            </div>
            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Campaign type</strong>Public - visible to all Fuzu users
            </div>
            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Application process</strong>Managed inside Fuzu
            </div>
            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Duration</strong>
              {formatDate(new Date(campaignStartDate * 1000))} - {formatDate(new Date(campaignEndDate * 1000))}
            </div>

            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Salary range</strong>
              {renderSalaryRange()}
            </div>

            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Salary Type</strong>
              {salaryType}
            </div>
            {/*
            <div className="fz-info-pane mb-2">
              <strong className="block">Number of opened position</strong>_1
            </div>
            */}
            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Contract Type</strong>
              {permanentPosition}
            </div>
            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Job Category</strong>
              {category && <>{category.name}</>}
            </div>
            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Industry</strong>
              {industryRecord && <>{industryRecord.name}</>}
            </div>
            <div className="fz-info-pane fz-info-pane__text mb-2">
              <strong className="block">Job Level</strong>
              {jobLevel}
            </div>

            {requirements && requirements?.education ? (
              <div className="fz-info-pane fz-info-pane__text mb-2">
                <strong className="block">Education</strong>
                {requirements?.education}
              </div>
            ) : null}

            {requirements && requirements?.work_experience ? (
              <div className="fz-info-pane fz-info-pane__text mb-2">
                <strong className="block">Relevant Experience</strong> {requirements?.work_experience}
              </div>
            ) : null}

            {jobStartDate && (
              <div className="fz-info-pane fz-info-pane__text mb-2">
                <strong className="block">Job Start Date</strong>
                {jobStartDate}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailShare;
