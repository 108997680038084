import React from "react";
import Button from "components/Button";
import JobItem from "../JobItem";
import Layout from "../../../../components/Layout";

const RenderJobs = ({
  loading = true,
  jobs = [],
  placeholdersLength = 0,
  handleModal,
  archiveCampaign,
  deleteCampaign,
  isExternal,
  jobCount,
  license_state,
  active_job_limit,
  validSubscription
}) =>
  loading ? (
    placeholdersLength.map(key => <JobItem.Placeholder key={`jb_p${key}`} />)
  ) : jobs.length > 0 ? (
    jobs.map(job => (
      <JobItem
        {...job}
        key={`${job.title}-${job.id}`}
        isExternal={isExternal}
        archiveCampaign={id => handleModal("archive", archiveCampaign, id)}
        deleteCampaign={id => handleModal("delete", deleteCampaign, id)}
      />
    ))
  ) : (
    <Layout.EmptyState
      title="Post a new job"
      subtitle={"Market your position to the best candidates\nin fastest growing talent pool."}
      actionButton={
        <Button
          {...(validSubscription && (jobCount > 0 || !active_job_limit) ? { tag: "a" } : {})}
          disabled={!validSubscription || (jobCount === 0 && !!active_job_limit) || license_state === "inactive"}
          color="yellow"
          size="wide"
          href="/employers/campaign_for"
        >
          Post a job
        </Button>
      }
    />
  );

export default RenderJobs;
