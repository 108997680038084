import React, { useState, useEffect, useMemo, useContext } from "react";
import { MdOpenInNew, MdInfoOutline } from "react-icons/md";

import documentImage from "images/b2b/empty-personality.png";
import { applicationHistoryStatus } from "b2b/constants/campaign";
import getIndustryStyles from "utils/industryImageMap";
import Tooltip from "../../../../../components/Tooltip";
import {
  Content,
  Description,
  ImageContent,
  FlexHeading,
  Count,
  Item,
  ItemHeading,
  ItemHeadingFlex,
  ItemImage,
  ItemName,
  ItemCompany,
  ItemFooter,
  ItemLabel,
  ItemDate,
  ItemStage,
  ItemStatus,
  ShowAllButton,
  Heading,
  ViewCandidate,
  ViewCandidateUnavailable,
  TooltipContent,
  tooltipContentStyles
} from "./styled";
import SelectView from "../../../Campaigns/components/SelectView";
import { formatDate } from "../../../../utils/helpers";
import AppContext from "../../../../../contexts/init";

const ActivityTab = ({
  applicationHistory = [],
  applicationHistoryMeta = {},
  getCandidateApplicationHistory,
  campaignName,
  candidateName,
  isHeadHunting
}) => {
  const [selectValue, setSelectValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { current_user: currentUser } = useContext(AppContext);
  const isLeadHr = currentUser?.roles?.includes("lead_hr");

  const numberOfNextCountCandidates = useMemo(() => {
    const currentPerPage = 10;
    const countOfRestCandidates = applicationHistoryMeta.total_count - applicationHistory.length;
    return countOfRestCandidates >= currentPerPage ? currentPerPage : countOfRestCandidates;
  }, [applicationHistoryMeta.total_count, applicationHistory.length]);

  const isShowLoadMoreButtons = useMemo(() => {
    const totalCount = applicationHistoryMeta.total_count;
    const applicationHistoryLength = applicationHistory?.length;
    const miniPages = 5;

    return (
      (applicationHistoryLength && totalCount >= applicationHistoryLength && totalCount > miniPages) || currentPage > 1
    );
  }, [applicationHistoryMeta.total_count, applicationHistory.length]);

  const filterOptions = useMemo(() => {
    return [
      { label: "All applications", value: "all" },
      { label: campaignName, value: "current" },
      { label: "Other employers only", value: "other" }
    ];
  }, [campaignName]);

  const handleChangeEmployer = value => {
    setSelectValue(value);
    getCandidateApplicationHistory(1, true, value);
    setCurrentPage(currentPage);
  };

  const handlePaginationCandidateApplicationHistory = () => {
    if (applicationHistoryMeta.total_count > applicationHistory.length) {
      getCandidateApplicationHistory(currentPage + 1, !selectValue, selectValue);
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGetFirstApplicationHistory = () => {
    getCandidateApplicationHistory(1, true, selectValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!selectValue) {
      setSelectValue(filterOptions[0].value);
    }
  }, []);

  const renderEmptyText = () => {
    if (isHeadHunting) {
      if (isLeadHr) {
        return <>{candidateName} Isaac hasn`t applied to other positions</>;
      }

      return (
        <>
          {candidateName} hasn`t applied to other positions at {campaignName}
        </>
      );
    } else {
      if (selectValue === "other") {
        return <>{candidateName} hasn`t applied to positions at other employers</>;
      }

      if (selectValue === "all") {
        return <>{candidateName} hasn`t applied to other positions</>;
      }

      return (
        <>
          {candidateName} hasn`t applied to other positions at {campaignName}
        </>
      );
    }
  };

  const applicationHistoryList = applicationHistory?.length
    ? applicationHistory.map(item => {
        const { Icon } = getIndustryStyles(item.industry);

        return (
          <Item item={`${item.id}-${item.campaign_id}-${item.submitted_at}`}>
            <ItemHeading>
              <ItemHeadingFlex>
                <ItemImage>{item.company_logo ? <img src={item.company_logo} alt="" /> : <Icon />}</ItemImage>
                <Heading>
                  <div>
                    <ItemName>{item.campaign_name}</ItemName>
                    {selectValue === "current" && isHeadHunting ? null : (
                      <ItemCompany>{item.employer_name}</ItemCompany>
                    )}
                  </div>
                  {item.campaign_id ? (
                    <ViewCandidate target="_blank" to={`/jobs/${item.campaign_id}/applicants/${item.id}`}>
                      View
                      <MdOpenInNew />
                    </ViewCandidate>
                  ) : (
                    <ViewCandidateUnavailable>
                      Campaign unavailable
                      <Tooltip
                        tooltipPosition={["top right"]}
                        contentStyle={tooltipContentStyles}
                        tooltipContent={
                          <TooltipContent>
                            Campaign becomes unavailable 180 days after it was closed. Contact support if you need
                            access.
                          </TooltipContent>
                        }
                      >
                        <MdInfoOutline />
                      </Tooltip>
                    </ViewCandidateUnavailable>
                  )}
                </Heading>
              </ItemHeadingFlex>
            </ItemHeading>
            <ItemFooter>
              <div>
                <ItemLabel>Applied on</ItemLabel>
                {item.submitted_at ? (
                  <ItemDate>{formatDate(new Date(item.submitted_at * 1000))}</ItemDate>
                ) : (
                  <ItemDate>-</ItemDate>
                )}
              </div>

              <div>
                <ItemLabel>Candidate stage</ItemLabel>
                <ItemStage>{item.current_phase}</ItemStage>
              </div>

              <div>
                <ItemLabel>Campaign state:</ItemLabel>
                {item.campaign_phase === applicationHistoryStatus.active ? (
                  <ItemStatus>{item.campaign_phase}</ItemStatus>
                ) : (
                  <ItemStage>{item.campaign_phase}</ItemStage>
                )}
              </div>
            </ItemFooter>
          </Item>
        );
      })
    : null;

  return (
    <div className="fz-container-with-sidebar">
      <div className="fz-container-with-sidebar__content">
        <div>
          <div className="fz-pane">
            <div className="fz-pane__container fz-pane__container_activity">
              <FlexHeading>
                <div>
                  <h2 className="fz-heading-title">Applications history</h2>
                  {applicationHistoryMeta.total_count ? (
                    <Count>{`${applicationHistoryMeta.total_count} applications`}</Count>
                  ) : null}
                </div>

                {isLeadHr && (
                  <SelectView options={filterOptions} onSelect={handleChangeEmployer} initial={selectValue} />
                )}
              </FlexHeading>

              {applicationHistory?.length ? (
                <>{applicationHistoryList}</>
              ) : (
                <Content>
                  <ImageContent>
                    <img src={documentImage} alt="empty" />
                  </ImageContent>
                  <Description>{renderEmptyText()}</Description>
                </Content>
              )}
              {isShowLoadMoreButtons && isLeadHr ? (
                <>
                  {applicationHistoryMeta.total_count > applicationHistory.length ? (
                    <ShowAllButton onClick={handlePaginationCandidateApplicationHistory}>
                      View {numberOfNextCountCandidates} more applications
                    </ShowAllButton>
                  ) : (
                    <ShowAllButton onClick={handleGetFirstApplicationHistory}>Hide</ShowAllButton>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/*
      <div className="fz-container-with-sidebar__aside fz-container-with-sidebar__aside_big"></div>
      */}
    </div>
  );
};

export default ActivityTab;
