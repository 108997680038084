import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: inline-block;

  .fz-btn:hover {
    background-color: transparent;
    color: #4690ff;
  }
`;
