import React from "react";
import { Chat } from "@styled-icons/boxicons-solid";
import Button from "components/Button";
import ListMarker from "images/b2c/icons/listMarker.png";
import AbsentIcon from "images/b2c/icons/absent.png";
import { Modal, Line, Includes, IncludesItem, ListItem, ButtonContent } from "./styled";
import { Image, Text, Title } from "../../../b2c/components/Core";

export const WhatIncluded = ({ descriptions, permissions, subscriptionType, route = false, close }) => {
  const included = Object.keys(descriptions)
    .filter(key => permissions[key])
    .map(item => descriptions[item]);
  const notIncluded = Object.keys(descriptions)
    .filter(key => !permissions[key])
    .map(item => descriptions[item]);

  return (
    <Modal>
      <Line />

      <Text
        style={{
          fontSize: "12px",
          lineHeight: "130%",
          color: "#000000",
          marginTop: "24px",
          display: "block"
        }}
      >
        Upgrade your license in order to unlock all features. Contact our business support for any details.
      </Text>
      <Includes>
        {included.length ? (
          <IncludesItem>
            <Title
              style={{
                fontSize: "14px",
                lineHeight: "140%",
                color: "#000",
                marginBottom: "12px",
                display: "block",
                fontWeight: "600"
              }}
            >
              Your current license include:
            </Title>
            {included.map(text => (
              <ListItem key={text}>
                <Image width={24} height={24} style={{ marginRight: "8px" }} src={ListMarker} alt="Circle" />

                <Text
                  style={{
                    fontSize: "12px",
                    lineHeight: "130%",
                    color: "#000000",
                    margin: "0",
                    display: "block"
                  }}
                >
                  {text}
                </Text>
              </ListItem>
            ))}
          </IncludesItem>
        ) : null}

        {notIncluded.length ? (
          <IncludesItem>
            <Title
              style={{
                fontSize: "14px",
                lineHeight: "140%",
                color: "#000",
                marginBottom: "12px",
                display: "block",
                fontWeight: "600"
              }}
            >
              Your current license does not include:
            </Title>

            {notIncluded.map(text => (
              <ListItem key={text}>
                <Image width={24} height={24} style={{ marginRight: "8px" }} src={AbsentIcon} alt="Circle" />
                <Text
                  style={{
                    fontSize: "12px",
                    lineHeight: "130%",
                    color: "#000000",
                    margin: "0",
                    display: "block"
                  }}
                >
                  {text}
                </Text>
              </ListItem>
            ))}
          </IncludesItem>
        ) : null}
      </Includes>
      <Line />

      <ButtonContent>
        <Button
          onClick={() => close()}
          tabIndex="0"
          style={{
            backgroundColor: "#E1E1E1",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "140%",
            width: "50%",
            marginRight: "12px",
            color: "#3E3E3E",
            borderRadius: "5px"
          }}
        >
          Continue with {subscriptionType}
        </Button>
        <Button
          className="intercom-trigger"
          color="yellow"
          style={{
            width: "50%",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "140%",
            color: "#000000",
            backgroundColor: "#FFE040",
            borderRadius: "5px"
          }}
          icon={<Chat fill="#000000" width={20} />}
          role="button"
          tabIndex="0"
          {...(!route ? { onClick: () => setTimeout(() => close(), 200) } : {})}
        >
          Contact business support
        </Button>
      </ButtonContent>
    </Modal>
  );
};
