import React from "react";

import { Text } from "../../../b2c/components/Core";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";

const Invalid = () => {
  return (
    <CreateLoginFlowWrapper
      title="Invite link is invalid"
      description={
        <div>
          <Text fontSize={14} fontWeight={400} width="100%" margin="14px 0 0 0" textAlign="center">
            {"The invite link seems to be incorrect. Please check that you copied the link correctly. If  "}
          </Text>
          <Text fontSize={14} fontWeight={400} width="100%" textAlign="center">
            {"the issue persists, contact "}
            <a href="mailto:business@fuzu.com">
              <Text
                fontSize={14}
                fontWeight={600}
                style={{
                  display: "inline",
                  color: "#0e50b5"
                }}
              >
                {"business@fuzu.com. "}
              </Text>
            </a>
            for support.
          </Text>
        </div>
      }
    />
  );
};

export default Invalid;
