import styled from "styled-components";
import { theme } from "../../../b2c/contexts/theme";

export const iconStyle = {
  width: "25px",
  height: "25px",
  position: "absolute",
  top: "40px"
};

export const Option = styled.div`
  margin: 10px;
  width: 492px;
  height: ${({ height }) => height || "110px"};
  border: 1px solid #80808059;
  border: 1px solid ${({ selected }) => (selected ? theme.secondary200 : theme.black300)};
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  position: relative;
  background: ${({ selected }) => (selected ? "#7cc4f857" : "white")};
`;

export const OptionText = styled.div`
  width: 80%;
  display: inline-block;
  margin: 0 36px;
  color: #666666;
  font-size: 14px;
`;

export const OptionTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ color }) => color || theme.black500};
  display: flex;
  align-items: center;
`;

export const CaseIcon = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
`;
