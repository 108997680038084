import React, { useEffect, useState, useMemo } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import styled from "styled-components";

import Layout from "../../components/Layout";
import StatusFiltersBarOption from "../../components/StatusFiltersBarOption";
import TutorialCard from "./components/TutorialCard";
import ScreenStateMessage from "../../components/ScreenStateMessage";

const TutorialsScreen = inject("onboardingStore")(
  observer(
    ({
      onboardingStore: { state, videos, initializeTutorials, sections, getSectionVideos } = {},
      history: { push },
      match: {
        params: { section = "all" }
      }
    }) => {
      const [videoId, setVideoId] = useState();

      useEffect(() => {
        initializeTutorials();
      }, []);

      const videoList = useMemo(() => {
        if (videoId)
          return {
            [videoId]: videos.all
              ? [videos.all.some(video => video.id == videoId) && videos.all.find(video => video.id == videoId)]
              : []
          };
        return videos;
      }, [videoId, videos.all]);

      useEffect(() => {
        if (!isNaN(section)) setVideoId(section);
        else if (section !== "all" && sections) {
          const currentSection = sections.find(item => modifyString(item.name) == section);
          getSectionVideos(currentSection.id, section);
        }
      }, [section, sections]);

      const modifyString = str => str.toLowerCase().replace(/ /g, "_");

      const scopes = (() => {
        if (sections) {
          const modified = sections.slice().map(item => ({
            key: item.id,
            label: item.name,
            value: modifyString(item.name).slice(),
            count: videos[modifyString(item.name)] ? videos[modifyString(item.name)].slice().length : 0,
            onChange: () => {
              setVideoId(null);
              push(`/tutorials/${modifyString(item.name)}`);
            }
          }));
          modified.unshift({
            key: "all_videos",
            label: "All",
            value: "all",
            count: videos.all ? videos.all.length : 0,
            onChange: () => {
              setVideoId(null);
              push("/tutorials");
            }
          });
          return modified;
        }
        return [];
      })();

      return (
        <ScreenStateMessage state={toJS(state)} loadingMessage="Loading videos...">
          <Layout.PageHeading className="fz-page-heading_tall">
            <Layout.Container type="default" tag="div">
              <h1 className="fz-title mt-0 mb-2">Tutorials</h1>
              <p className="fz-text fz-text_normal fz-text-color__grey700 mb-3">
                Learn the essentials of Fuzu with our handcrafted video tutorials
              </p>
            </Layout.Container>
          </Layout.PageHeading>
          <Layout.Container type="default">
            <div className="fz-filter-bar">
              <div className="fz-filter-bar__main">
                {scopes.map(scope => (
                  <StatusFiltersBarOption
                    key={scope.key}
                    scope={scope}
                    currentScope={section}
                    onChange={scope.onChange}
                  />
                ))}
              </div>
            </div>

            <div>
              <Description>Description:</Description> This e-learning aims to sensitize employers and their teams on
              disability, and disability-inclusive employment. Students should leave this course with a basic
              understanding of what disability is, and why disability inclusion is important for business success. The
              course will aim to shift employers’ approach around hiring, retaining and promoting women and men with
              disabilities, to provide guidance around national legislation related to disability employment, and to
              leave employers with practical tips for how to adapt their workplace and their employment practices to be
              inclusive of female and male job seekers, and employees with disabilities. This course is provided you by
              Leonard Cheshire.
            </div>

            <div className="row mt-4">
              {(videoList[section] || []).map(itm => (
                <div key={itm.id} className={videoList[section].length > 1 ? "col-sm-6" : ""}>
                  <TutorialCard
                    title={itm.title}
                    description={itm.description}
                    source={itm.video_url}
                    link={itm.video_link}
                    disabilityInclusion={itm.disability_inclusion}
                    thumbnail={itm.preview_thumbnail_url}
                  />
                </div>
              ))}
            </div>
          </Layout.Container>
        </ScreenStateMessage>
      );
    }
  )
);

const Description = styled.span`
  font-weight: 600;
`;

export default TutorialsScreen;
