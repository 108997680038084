import React, { useState, useEffect } from "react";
import cn from "classnames";
import Popup from "reactjs-popup";

import ProfileAvatar from "components/ProfileAvatar";

const ProgressChart = ({
  title = "",
  legend = { right: "", left: "" },
  mean = 0,
  desired_score = 0.5,
  trait_description = "",
  user_score_unnormalized = 0,

  avatar
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [barPosition, setBarPosition] = useState(50);
  const [barWidth, setBarWidth] = useState(0);
  const [pointPosition, setPointPosition] = useState(49);
  const [userTooltipPosition, setUserTooltipPosition] = useState(0);

  useEffect(() => {
    setBarPosition(user_score_unnormalized * 100);
    setBarWidth(
      user_score_unnormalized >= 0.5 ? user_score_unnormalized * 100 - 50 : 50 - user_score_unnormalized * 100
    );
    setPointPosition(mean);
  }, [user_score_unnormalized, mean]);

  useEffect(() => {
    setUserTooltipPosition(
      user_score_unnormalized >= 0.5 ? (barPosition < 50 ? barPosition : 50) + barWidth : barPosition
    );
  }, [barWidth]);

  const onMouseEnterBarHandle = () => {
    setTooltipOpen(true);
  };
  const onMouseLeaveBarHandle = () => {
    setTooltipOpen(false);
  };

  const progressClasses = cn("fz-progress-chart__progress", {
    "fz-progress-chart__progress_right": barPosition < 50
  });
  const userTooltipClasses = cn("fz-chart-user-tooltip", {
    "is-active": isTooltipOpen
  });

  const PreferredBadge = ({ desired }) => desired && <div className="fz-progress-chart__badge">Preferred</div>;

  return (
    <div className="fz-info-pane" onMouseEnter={onMouseEnterBarHandle} onMouseLeave={onMouseLeaveBarHandle}>
      <div className="fz-info-pane__header">
        <div className="fz-info-pane__cell">
          <h3 className="fz-heading-subtitle">{title}</h3>
        </div>
      </div>
      <div className="fz-info-pane__body">
        <div className="fz-progress-chart">
          <div className="fz-progress-chart__container">
            <Popup
              className="fz-tooltip"
              trigger={<div className="fz-progress-chart__value-point" style={{ left: `${pointPosition}%` }} />}
              position="bottom center"
              on="hover"
              open={isTooltipOpen}
            >
              Average of all Fuzu users
            </Popup>
            <div
              className={userTooltipClasses}
              style={{
                left: `${userTooltipPosition}%`
              }}
            >
              <ProfileAvatar avatar={avatar} />
            </div>
            <div
              className={progressClasses}
              style={{
                left: `${barPosition < 50 ? barPosition : "50"}%`,
                width: `${barWidth}%`
              }}
            />
          </div>
          <div className="fz-progress-chart__legends">
            <div>
              {legend.left}
              <PreferredBadge desired={desired_score < 0.5} />
            </div>
            <div>
              <PreferredBadge desired={desired_score > 0.5} />
              {legend.right}
            </div>
          </div>
        </div>
        <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey700 fz-text_pre-wrap mb-0 mt-2">
          {trait_description}
        </p>
      </div>
    </div>
  );
};

export default ProgressChart;
