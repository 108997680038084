import styled from "styled-components";

export const SelectFromActiveJob = styled.div`
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 15px 20px 15px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ isActive }) => (isActive ? "#333333" : "#666666")};
  margin-bottom: 24px;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "default")};
`;

export const UnlockBlock = styled.div`
  border-top: 1px solid #c5c5c5;
  padding-top: 24px;
`;

export const UnlockItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "default")};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const UnlockText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  display: block;
  margin-bottom: 4px;
  display: flex;
`;

export const UnlockDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  padding-right: 40px;
`;

export const UnlockCount = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin-left: 15px;
`;

export const Notification = styled.div`
  background: #f3f3f3;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin-bottom: 24px;
`;
