import React from "react";
import { observer, inject } from "mobx-react";

import Button from "components/Button";
import { useModal } from "utils/hooks";
import Pane from "../../../../components/Pane";
import InfoPane from "../../../../components/InfoPane";

import CompanyIndustryContent from "../CompanyIndustryContent";
import HandleDescriptionContent from "../HandleDescriptionContent";
import UploadImageContent from "../UploadImageContent";
import ImagePreview from "../ImagePreview";

const Basics = ({
  Settings: { employer, industriesSubset: industries, updateIndustries, updateDescription, updateLogo, deleteLogo }
}) => {
  const selectedIndusties = industries?.filter(({ selected }) => selected).map(({ name }) => name);

  const openUploadLogoModal = useModal(
    <UploadImageContent updateImage={updateLogo} />,
    <>
      Upload a logo
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700">
        Recommended size is 280 x 280 pixels.
        <br /> The maximum size per file is 5 MB.
      </div>
    </>
  );

  const previewLogo = useModal(<ImagePreview image={employer?.logo} onChange={openUploadLogoModal} />, "Logo preview");

  const openCompanyIndustryModal = useModal(
    <CompanyIndustryContent industries={industries} updateIndustries={updateIndustries} />,
    <>
      Select company industry
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700">You can select multiple industries.</div>
    </>
  );

  const openAddDescriptionModal = useModal(
    <HandleDescriptionContent description={employer?.description} updateDescription={updateDescription} />,
    <>
      Company description
      <div className="fz-text fz-lh_1-4 mt-1 fz-text-color__grey700">
        Describe your company briefly. All Fuzu users will see this description in your company profile, so try to give
        a clear idea of what kind of company you are, what you do, and what makes you a great organisation to work for.
      </div>
    </>
  );

  return (
    <Pane.Two>
      <h2 className="fz-heading-title">Basics</h2>

      <InfoPane.Item
        className="mt-2 pb-2 mb-2"
        title="Logo"
        actions={
          employer?.logo ? (
            <>
              <Button.Simple onClick={previewLogo}>Preview</Button.Simple>
              <Button.Simple className="ml-2" onClick={openUploadLogoModal}>
                Change
              </Button.Simple>
              <Button.Simple color="red" className="ml-2" onClick={deleteLogo}>
                Remove
              </Button.Simple>
            </>
          ) : (
            <Button.Simple className="ml-2" onClick={openUploadLogoModal}>
              Add
            </Button.Simple>
          )
        }
      />

      <InfoPane.Item
        className="mt-2 pb-2 mb-2"
        title="Industries"
        data={
          selectedIndusties.length <= 2
            ? selectedIndusties.join(", ")
            : `${selectedIndusties.slice(0, 2).join(", ")} and ${selectedIndusties.length - 2} more`
        }
        actions={
          <Button.Simple className="ml-2" onClick={openCompanyIndustryModal}>
            {industries.some(item => item.selected) ? "Change" : "Add"}
          </Button.Simple>
        }
      />

      <InfoPane.Item
        className="mt-2 pb-2"
        title="Company description"
        subtitle="Tell a few words about your company and mission."
        actions={
          <Button.Simple className="ml-2" onClick={openAddDescriptionModal}>
            {employer?.description ? "Edit" : "Add"}
          </Button.Simple>
        }
      >
        {employer?.description && (
          <p className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__black fz-text_pre-wrap mt-2 mb-0 max-width-60-sm">
            {employer?.description || ""}
          </p>
        )}
      </InfoPane.Item>
    </Pane.Two>
  );
};

export default inject("Settings")(observer(Basics));
