import styled from "styled-components";

export const TagContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const Tag = styled.span`
  color: ${({ isNotActive }) => (isNotActive ? "#A4A4A4" : "#fff")};
  background: ${({ isNotActive }) => (isNotActive ? "#EEE" : "#408bfc")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 8px;
`;

export const Info = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  background: #f3f3f3;
  margin: 24px 0;

  p {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      color: #333;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #408bfc;
    flex: none;
  }
`;
