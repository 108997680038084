import React from "react";

import { SimpleSelect } from "components/Selects";
import { SortingContainer, SortingContainerDescription, SortingSelectContainer } from "./styled";

const Sorting = ({ foundCount, currentSortBy, applyFilter }) => {
  const sorting = [
    { id: 1, label: "Creation date: Newest first", value: "desc" },
    { id: 2, label: "Creation date: Oldest first", value: "asc" }
  ];

  const defaultSortingOption = sorting.find(({ value }) => value === currentSortBy);

  return (
    <SortingContainer>
      <SortingContainerDescription>
        {foundCount ? (
          <>
            Showing <span>{foundCount}</span> candidates
          </>
        ) : null}
      </SortingContainerDescription>
      <SortingSelectContainer>
        <span>Sort by</span>
        <SimpleSelect
          selectOptions={sorting}
          role="sort_by"
          getFilteredList={applyFilter}
          defaultOption={defaultSortingOption}
        />
      </SortingSelectContainer>
    </SortingContainer>
  );
};

export default Sorting;
