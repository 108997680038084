import React from "react";

import { Modal, Title, Description, Actions, Cancel, Remove } from "./styled";

const InterviewDelete = ({ handleRemove, close }) => {
  const handleDelete = () => {
    handleRemove().then(() => {
      close();
    }, []);
  };

  return (
    <Modal>
      <Title>Delete this note?</Title>
      <Description>This will permanently delete the note. This action cannot be undone. </Description>
      <Actions>
        <Cancel onClick={close}>Keep it</Cancel>
        <Remove onClick={handleDelete}>Yes, delete it</Remove>
      </Actions>
    </Modal>
  );
};

export default InterviewDelete;
