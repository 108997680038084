import { inject, observer } from "mobx-react";
import React, { useContext } from "react";
import format from "date-fns/format";
import { MdContentCopy } from "react-icons/md";

import {
  Modal,
  Title,
  Text,
  Submit,
  CreatedLinkBanner,
  CopyLinkContent,
  CopyLinkField,
  CopyLinkButton
} from "./styled";
import { getGuestLinkUrl } from "../../../../utils/helpers";
import { copyCodeToClipboard } from "../../../../../utils/helpers";
import AppContext from "../../../../../contexts/init";
import { guestLinkAccessType } from "../../../../constants/campaign";

const NewGuestLinkSuccess = ({ close, CampaignsStore: { selectedCampaign } }) => {
  const guestLink = getGuestLinkUrl(`share-candidates/${selectedCampaign.active_guest_link.token}`);
  const { global_brand } = useContext(AppContext);
  const isAccessTypeList = global_brand.guest_link_access_type === guestLinkAccessType.list;

  const handleCopy = () => {
    copyCodeToClipboard(guestLink, () => ({}));
  };

  return (
    <Modal>
      <Title>Your guest link is ready!</Title>
      <Text>
        {isAccessTypeList ? "Selected people will receive an email notification shortly." : ""}
        <br /> You can edit link from the <span>Share</span> menu.
      </Text>

      {!isAccessTypeList && (
        <CopyLinkContent>
          <CopyLinkField>{guestLink}</CopyLinkField>
          <CopyLinkButton onClick={handleCopy}>
            <MdContentCopy />
            Copy
          </CopyLinkButton>
        </CopyLinkContent>
      )}

      <CreatedLinkBanner>
        Expires <span>{format(new Date(selectedCampaign.active_guest_link.expires_at), "dd MMM EEEE 'at' hh:mm")}</span>
      </CreatedLinkBanner>

      <Submit onClick={close}>Ok</Submit>
    </Modal>
  );
};

export default inject("CampaignsStore", "MyJobsStore")(observer(NewGuestLinkSuccess));
