import React from "react";
import Checkbox from "../Checkbox";

const Experience = ({ activeExperienceRanges, workExperienceRanges = [], handleChange }) => {
  const checkboxes = workExperienceRanges.map(item => {
    return (
      <Checkbox
        key={item.label}
        id={`${item.id}-${item.label}`}
        labelClass="fz-checkbox__label_empty fz-checkbox__label_black"
        className="fz-checkbox_experience"
        checked={activeExperienceRanges?.includes(String(item.id))}
        onCheck={() => handleChange(String(item.id))}
      >
        {item.label}
      </Checkbox>
    );
  });

  return <div>{checkboxes}</div>;
};

export default Experience;
