import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";
import cn from "classnames";
import {
  MdMoreHoriz,
  MdContentCopy,
  MdArchive,
  MdKeyboardArrowLeft,
  MdLink,
  MdPeopleOutline,
  MdLock,
  MdModeEdit,
  MdDelete
} from "react-icons/md";
import { UserPlus, Plus, Key } from "@styled-icons/boxicons-regular";

import ProfileAvatar from "components/ProfileAvatar";
import Button from "components/Button";
import ModalConsumer, { ModalContext } from "contexts/modal";
import { getEditCampaignPath, getDuplicateCampaignPath } from "utils/urlHelpers";
import { useModal, useOnclickOutside } from "utils/hooks";
import { copyCodeToClipboard } from "utils/helpers";
import Subscription from "../../../../components/Subscription";
// todo all commented code will be used in the next phase
// import CollaborationTool from "../CollaborationTool";
import Logo from "../../../../../components/Logo";
import fuzuLogo from "../../../../../../assets/images/new_fuzu_logo_yellow.png";
import AddCandidateFlow from "../AddCandidateFlow";
import ShareCampaign from "../ShareCampaign";
import {
  LogoWrapper,
  HeaderAdditionalContent,
  CollaboratorsList,
  ProfileEmpty,
  GrayButton,
  AddCandidate,
  Actions,
  MoreDivider,
  Share
} from "./styled";
import BaronaLogo from "../../../../components/BaronaLogo";
import { theme } from "../../../../../b2c/contexts/theme";
import AppContext from "../../../../../contexts/init";

const ActionHeader = ({
  id,
  phase,
  path,
  archiveCampaign,
  deleteCampaign,
  colVisibility,
  changeVisibility,
  collaborators: records,
  getCollaborators,
  collaboratorContacts,
  invite,
  remove,
  isCurrentUserOwner,
  isCurrentUserExternalCollaborator = true,
  isLeadHr,
  isSharedCandidates,
  guestLinkThreshold,
  handleCreateGuestLink,
  activeGuestLink,
  changeGuestLink,
  deleteGuestLink,
  employerName,
  guestLinkContacts,
  resendGuestLink
}) => {
  const visibilityOptions = [
    {
      label: employerName,
      value: "everyone",
      icon: <MdPeopleOutline className="fz-dropdown-itm__icon" />
    },
    {
      label: "Only invited members",
      value: "invited",
      icon: <MdLock className="fz-dropdown-itm__icon" />
    },
    {
      label: "Private to me",
      value: "owner",
      icon: <Key className="fz-dropdown-itm__icon" />
    }
  ];

  const [visibility, setVisibility] = useState("");
  const [dropdownState, setDropdownState] = useState(false);
  // const [openPopup, setOpenPopup] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const collaboratorsEmptyText = collaborators.length > 3 ? collaborators.length - 3 : "";
  const { global_brand } = useContext(AppContext);
  const isHideInformationForSSOUsers = global_brand?.custom_authentication_b2b_url;
  const { toggle: toggleModal } = useContext(ModalContext);

  const getVisibility = value => {
    if (value === "invited" && !isHideInformationForSSOUsers) {
      return visibilityOptions[1];
    }

    if (value === "owner") {
      return visibilityOptions[2];
    }

    return visibilityOptions[0];
  };

  const sort = items => {
    return [...items].sort(({ removable }) => (!removable ? -1 : 0)).sort(({ owner }) => (owner ? -1 : 0));
  };

  useEffect(() => {
    if (!isCurrentUserExternalCollaborator) {
      getCollaborators(id);
    }
  }, [isCurrentUserExternalCollaborator]);

  useEffect(() => {
    setCollaborators(sort(records));
  }, [records]);

  useEffect(() => {
    const visibilityValue = isHideInformationForSSOUsers && colVisibility === "invited" ? "everyone" : colVisibility;

    setVisibility(getVisibility(visibilityValue));
  }, [colVisibility]);

  const ddRef = useRef();

  useOnclickOutside(ddRef, () => setDropdownState(false));

  const handleRemoval = invitationId => {
    remove(id, invitationId).then(() => {
      setCollaborators(prev => prev.filter(item => item.id !== invitationId));
    });
  };

  const handleAddition = (email, message, callback) => {
    invite(id, email, message).then(collaborator => {
      setCollaborators(prev => sort(prev.concat(collaborator)));

      if (callback) {
        callback();
      }
    });
  };

  const handleVisibilityChange = (value, visibilityValue, toggle) => {
    const callback = () =>
      changeVisibility(id, value).then(() => {
        setVisibility(() => getVisibility(value));
      });

    if (value !== visibilityValue.value) {
      visibilityValue.value !== "invited"
        ? callback()
        : toggleModal(
            <ModalContent
              close={toggle}
              callback={callback}
              message={parse(
                `Changing the access type to <b>${getVisibility(value).label}</b> will remove access from ${
                  value === "everyone" ? "invited" : "all"
                } participants`
              )}
              buttonText="Remove"
            />,
            "Remove access?"
          );
    }
  };

  const visibilityOptionsList = visibilityOptions
    .filter(option => !(!isCurrentUserOwner && option.value === "owner"))
    .filter(option => !(isHideInformationForSSOUsers && option.value === "invited"));

  const openAddCandidateFlow = useModal(<AddCandidateFlow />, "");

  const openShareCampaignModal = toggle => {
    return toggle(
      <ShareCampaign
        collaborators={collaborators}
        externalCollaborators={[]}
        colVisibility={visibility}
        getActiveVisibility={getVisibility}
        isCurrentUserExternalCollaborator={isCurrentUserExternalCollaborator}
        visibilityOptions={visibilityOptionsList}
        changeVisibility={(value, visibilityValue, toggle) => handleVisibilityChange(value, visibilityValue, toggle)}
        handleInviteCollaborators={handleAddition}
        remove={handleRemoval}
        guestLinkThreshold={guestLinkThreshold}
        handleCreateGuestLink={handleCreateGuestLink}
        activeGuestLink={activeGuestLink}
        changeGuestLink={changeGuestLink}
        deleteGuestLink={deleteGuestLink}
        collaboratorContacts={collaboratorContacts}
        handleOpenShareCampaignModal={() => openShareCampaignModal(toggle)}
        guestLinkContacts={guestLinkContacts}
        resendGuestLink={resendGuestLink}
      />,
      ""
    );
  };

  return (
    <div className="fz-action-header">
      <div className={cn("fz-action-header__container", isSharedCandidates ? "fz-action-header__container_guest" : "")}>
        <div className="fz-action-header__main">
          {!isSharedCandidates ? (
            <Link to={isLeadHr ? "/campaigns" : "/jobs"} className="fz-simple-btn fz-simple-btn_white">
              <MdKeyboardArrowLeft className="fz-simple-btn__icon" />
              Go to My jobs
            </Link>
          ) : (
            <BaronaLogo color="#fff">
              <LogoWrapper>
                <Logo logo={fuzuLogo} />
              </LogoWrapper>
            </BaronaLogo>
          )}
        </div>

        <ModalConsumer>
          {({ toggle }) => (
            <HeaderAdditionalContent>
              {(!isCurrentUserExternalCollaborator || isLeadHr) && (
                <>
                  <CollaboratorsList onClick={openShareCampaignModal}>
                    {collaborators.slice(0, 3).map(coll => (
                      <ProfileAvatar key={coll.id} avatar={coll.user?.avatar} />
                    ))}
                    {collaboratorsEmptyText ? <ProfileEmpty>{`+ ${collaboratorsEmptyText}`}</ProfileEmpty> : null}
                  </CollaboratorsList>

                  <Share onClick={() => openShareCampaignModal(toggle)}>
                    <UserPlus />
                    Share
                  </Share>
                  {/*
                  <Popup
                    className="fz-dropdown"
                    trigger={
                      <GrayButton>
                        <UserPlus />
                        Invite
                      </GrayButton>
                    }
                    position="bottom left"
                    arrow={false}
                    closeOnDocumentClick
                    open={openPopup}
                    onClose={() => setOpenPopup(false)}
                    onOpen={() => setOpenPopup(true)}
                  >
                    <CollaborationTool
                      collaborators={collaborators}
                      externalCollaborators={[]}
                      colVisibility={visibility}
                      isCurrentUserExternalCollaborator={isCurrentUserExternalCollaborator}
                      visibilityOptions={visibilityOptionsList}
                      changeVisibility={value => handleVisibilityChange(value, toggle)}
                      invite={handleAddition}
                      remove={handleRemoval}
                    />
                  </Popup>
                  */}
                  <hr />
                </>
              )}

              {!isSharedCandidates && (
                <AddCandidate
                  onClick={openAddCandidateFlow}
                  background={theme.primaryButtonBackground}
                  color={theme.primaryButtonLabelColor}
                >
                  <Plus />
                  Add candidate
                </AddCandidate>
              )}

              <Subscription.Upgrade permission="edit_job">
                <Actions>
                  {!isSharedCandidates && (
                    <GrayButton onClick={() => copyCodeToClipboard(path)} data-test-target="copy-action">
                      <MdLink className="fz-simple-btn__icon" />
                      Copy job link
                    </GrayButton>
                  )}

                  {!isCurrentUserExternalCollaborator && (
                    <Popup
                      closeOnDocumentClick
                      disabled={dropdownState}
                      className="fz-dropdown"
                      trigger={
                        <GrayButton>
                          <MdMoreHoriz className="fz-simple-btn__icon" />
                        </GrayButton>
                      }
                      position="bottom right"
                      arrow={false}
                    >
                      <div ref={ddRef} className="fz-dropdown-nav" role="listbox">
                        {!isCurrentUserExternalCollaborator && (
                          <Button
                            icon={<MdModeEdit className="fz-simple-btn__icon" />}
                            href={getEditCampaignPath(id)}
                            data-test-target="edit-action"
                            tag="a"
                            className="fz-dropdown-itm"
                            overwriteClass
                          >
                            <span className="fz-dropdown-itm__text">Edit</span>
                          </Button>
                        )}
                        <Button
                          icon={<MdContentCopy className="fz-dropdown-itm__icon" />}
                          data-test-target="duplicate-action"
                          tag="a"
                          href={getDuplicateCampaignPath(id)}
                          onClick={() => setDropdownState(true)}
                          className="fz-dropdown-itm"
                          overwriteClass
                          role="button"
                          tabIndex="0"
                        >
                          <span className="fz-dropdown-itm__text">Duplicate</span>
                        </Button>

                        <MoreDivider />

                        <Button
                          icon={/draft|archived/.test(phase) ? <MdDelete /> : <MdArchive />}
                          data-test-target="archive-action"
                          color="red"
                          onClick={() => {
                            toggle(
                              <ModalContent
                                close={toggle}
                                callback={() =>
                                  /draft|archived/.test(phase) ? deleteCampaign(id) : archiveCampaign(id)
                                }
                                message={`Are you sure you want to ${
                                  /draft|archived/.test(phase) ? "delete" : "archive"
                                } this job?`}
                                buttonText={`${/draft|archived/.test(phase) ? "Delete" : "Archive"} campaign`}
                              />
                            );
                            setDropdownState(true);
                          }}
                          className="fz-dropdown-itm fz-dropdown-itm_red"
                          overwriteClass
                          role="button"
                          tabIndex="0"
                        >
                          <span className="fz-dropdown-itm__text">
                            {`${/draft|archived/.test(phase) ? "Delete" : "Archive"}`}
                          </span>
                        </Button>
                      </div>
                    </Popup>
                  )}
                </Actions>
              </Subscription.Upgrade>
            </HeaderAdditionalContent>
          )}
        </ModalConsumer>
      </div>
    </div>
  );
};

const ModalContent = ({ close, message, callback, buttonText }) => {
  return (
    <>
      <div
        className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap mb-2"
        style={{ display: "inline-block" }}
      >
        {message}
      </div>
      <div className="fz-container-with-sidebar">
        <div className="fz-container-with-sidebar__content" />
        <Button className="mr-1" size="md" color="gray" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          size="md"
          color="error"
          onClick={() => {
            if (callback) {
              callback();
            }
            close();
          }}
        >
          <b>{buttonText}</b>
        </Button>
      </div>
    </>
  );
};

export default observer(ActionHeader);
