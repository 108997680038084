import { makeAutoObservable, flow } from "mobx";
import qs from "qs";
import { stringify } from "utils/qsUtil";
import * as API from "../utils/jobsApi";

const defaultApplied = {
  page: 1,
  per_page: 10,
  scope: "active",
  sort_by: "",
  sort_order: "desc"
};

class MyJobsStore {
  constructor() {
    makeAutoObservable(this);
  }

  myCampaigns = [];

  filters = {
    sorting: {}
  };

  appliedFilters = {};

  state = {
    type: "",
    loading: false,
    error: false,
    message: ""
  };

  scopeTotals = {};

  pendingFlows = [];

  commonCountries = [];

  commonCities = [];

  isLeadHr = false;

  get pageCount() {
    return Math.ceil(+this.appliedFilters.total_count / +this.appliedFilters.per_page);
  }

  setAppliedFilters = filters => {
    this.appliedFilters = {
      ...this.appliedFilters,
      ...filters
    };
  };

  setUp = (history, isLeadHr) => {
    this.state = {
      type: "",
      loading: true,
      error: false,
      message: ""
    };

    this.isLeadHr = isLeadHr;

    const { location: { search } = {} } = history;

    const parsedFiltersState = qs.parse(search.slice(1));

    this.setAppliedFilters({ ...parsedFiltersState.filters });

    const filterParams = {
      ...parsedFiltersState.filters,
      page: parsedFiltersState?.page || defaultApplied?.page,
      per_page: parsedFiltersState.per_page || defaultApplied.per_page
    };

    this.getFilteredList(filterParams, history, isLeadHr);
  };

  refreshFlows = newFlow => {
    this.state.type = "";
    Promise.resolve(() => this.pendingFlows.forEach(flowItem => flowItem.cancel()))
      .then(() => {
        this.pendingFlows = [];
      })
      .then(() => {
        if (newFlow) {
          this.pendingFlows.push(newFlow);
        }
      });
  };

  updateJobs = ({ page = this.appliedFilters?.page, per_page = this.appliedFilters.per_page, filters }, history) => {
    const filterParams = {
      ...this.appliedFilters,
      ...filters,
      per_page,
      page
    };

    this.refreshFlows(this.getFilteredList(filterParams, history));
  };

  getFilteredList = flow(function* (filters, history) {
    const {
      page = 1,
      per_page = 10,
      scope = "active",
      employer_id,
      term,
      location_id,
      sort_by,
      sort_order,
      owner_id,
      campaign_type,
      country_id,
      geoname_id
    } = {
      ...this.appliedFilters,
      ...filters
    };

    this.filterParams = {
      filters: {
        scope,
        employer_id,
        term,
        location_id,
        sort_by,
        sort_order,
        owner_id,
        campaign_type,
        country_id,
        geoname_id
      },
      page,
      per_page
    };

    yield this.setAppliedFilters(filters);

    const query = yield stringify(this.filterParams);

    if ((this.isLeadHr && history) || history.location.pathname === "/campaigns") {
      history.push(`/campaigns?${query}`);
    }

    if (!this.isLeadHr && history) {
      history.push(`/jobs?${query}`);
    }

    this.state.loading = true;

    try {
      const campaigns = yield API.getCampaigns({ query });

      this.filters = {
        ...this.filters,
        ...campaigns.data.meta.filters
      };

      const { total_count } = campaigns.data.meta;

      this.scopeTotals = { ...this.scopeTotals, [scope]: total_count };

      this.myCampaigns = campaigns.data.campaigns;

      yield this.setAppliedFilters({ total_count });

      if (this.pageCount > 0 && +this.appliedFilters.page > this.pageCount)
        this.getFilteredList({ page: this.pageCount }, history);
      if (+this.appliedFilters?.page <= 0) this.getFilteredList({ page: 1 }, history);
    } catch (err) {
      console.error(`Data layer: ${err}`);

      this.state = {
        type: "server_error",
        loading: false,
        error: true,
        message: "Failed to load jobs list",
        callback: () => {
          this.state.type = "";
          this.getFilteredList(filters, history);
        }
      };
    }

    this.state.loading = false;
  }).bind(this);

  archiveCampaign = id =>
    API.archiveCampaign(id)
      .then(() => {
        this.state = {
          type: `${id}_archive_success`,
          loading: false,
          error: false,
          message: "Job was successfully archived"
        };
      })
      .catch(err => {
        this.state = {
          type: `${id}_archive_failure`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to archive the job",
          callback: () => {
            this.state.type = "";
            this.archiveCampaign(id);
          }
        };
      });

  deleteCampaign = id =>
    API.deleteCampaign(id)
      .then(() => {
        this.state = {
          type: `${id}_delete_success`,
          loading: false,
          error: false,
          message: "Job was successfully deleted"
        };
      })
      .catch(err => {
        this.state = {
          type: `${id}_delete_failure`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to delete the job",
          callback: () => {
            this.state.type = "";
            this.deleteCampaign(id);
          }
        };
      });

  getCommonCountries = () => {
    return API.getCommonCountries()
      .then(res => {
        this.commonCountries = res.data.results;
      })
      .catch(err => {
        this.state = {
          type: "get_countries_failure",
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to getting geonames countries",
          callback: () => {
            this.state.type = "";
            this.getCommonCountries();
          }
        };
      });
  };

  getCommonCities = (countryId, search) => {
    return API.getCommonCities(countryId, search)
      .then(res => {
        this.commonCities = res.data.results;
      })
      .catch(err => {
        this.state = {
          type: "get_countries_failure",
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to getting geonames cities",
          callback: () => {
            this.state.type = "";
            this.getCommonCities(countryId, search);
          }
        };
      });
  };

  clearCommonCities = () => {
    this.commonCities = [];
  };
}

const myJobsStore = new MyJobsStore();

export default myJobsStore;
