import styled from "styled-components";

export const HeadingComponent = styled.div`
  width: 100%;
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  background: ${({ background }) => background || "linear-gradient(96deg, #ffe140 36%, #fdc824 85%, #ffc541 100%)"};
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: auto auto 0 auto;
  position: relative;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  color: #333333;
  display: block;
  position: relative;
  z-index: 1;
  margin: 0 0 6px 0;
`;

export const CountContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
`;

export const Count = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  margin-right: 5px;
`;

export const CountDescription = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333;
`;

export const FilterContent = styled.div`
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 16px;
  position: relative;
  z-index: 1;
`;

export const FilterSearchContent = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
`;

export const FilterSearch = styled.div`
  position: relative;
  width: 100%;
`;

export const FilterSearchIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  margin-top: -10px;

  svg {
    width: 100%;
    height: 100%;
    fill: #333333;
  }
`;

export const FilterCloseIcon = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  background: transparent;
  border: none;

  svg {
    width: 100%;
    height: 100%;
    fill: #333333;
  }
`;

export const SearchInput = styled.input`
  display: block;
  background-color: #fff;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  padding-left: 30px;
  padding-right: 20px;
  outline: none;
  border: none;
  &:placeholder {
    padding-left: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #666666;
  }
`;

export const SearchButton = styled.button`
  background: ${({ background }) => background || "#ffe140"};
  border-radius: 8px;
  width: 82px;
  padding: 7.5px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${({ color }) => color || "#333333"};
`;
