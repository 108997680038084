import React from "react";
import { Switch, Route } from "react-router-dom";
import { Provider } from "mobx-react";

import ShareCandidates from "../../pages/ShareCandidates";
import ShareCandidatesDetail from "../../pages/ShareCandidatesDetail";
import MyJobsStore from "../../stores/MyJobsStore";
import CampaignsStore from "../../stores/CampaignsStore";

const SharedCandidates = ({ match: { path }, initialState }) => {
  return (
    <Provider myJobsStore={MyJobsStore} campaignsStore={CampaignsStore}>
      <Switch>
        <Route
          exact
          path={[`${path}/:id`, `${path}/:id/:tab`]}
          component={() => <ShareCandidates initialState={initialState} />}
        />
        <Route
          exact
          path={[`${path}/:id`, `${path}/:id/candidates/:candidateId`]}
          component={() => <ShareCandidatesDetail initialState={initialState} isSharedCandidates />}
        />
      </Switch>
    </Provider>
  );
};

export default SharedCandidates;
