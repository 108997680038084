import styled from "styled-components";

export const SelectsViewComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`;

export const SelectsViewFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: calc(100% - 130px);
  flex: none;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
    max-width: none;
  }

  .fz-dropdown__additional-content {
    width: 100%;
    right: 0;
  }

  .fz-dropdown-nav_with-search {
    width: 100%;
    max-width: 100%;
  }

  .select__indicator-separator {
    display: none;
  }

  @media screen and (min-width: 1008px) {
    flex-wrap: nowrap;

    & > div {
      width: calc(100% - 8px);
      max-width: 190px;
    }
  }

  @media screen and (min-width: 1240px) {
    flex-wrap: nowrap;

    & > div {
      width: calc(100% - 8px);
      max-width: 50%;
    }
  }
`;

export const ClearAll = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${({ $isActive }) => ($isActive ? "#408BFC" : "#c5c5c5")};
  margin-left: 50px;
  background-color: transparent;
  width: calc(100% - 130px);
  pointer-events: ${({ $isActive }) => (!$isActive ? "none" : "auto")};
`;
