import styled from "styled-components";

export const ShowMore = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #408bfc;
  background: #f3f3f3;
  text-align: center;
  padding: 12px;
  border-top: 1px solid #c5c5c5;
  margin: 0 -15px;
  width: calc(100% + 30px);
  border-radius: 0 0 8px 8px;
`;
