import { Link } from "react-router-dom";
import styled from "styled-components";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

export const Content = styled.div`
  margin: 50px 0;
`;

export const FlexHeading = styled.div`
  margin-bottom: 24px;

  .fz-dropdown-itm {
    height: auto;
    min-height: 36px;

    .fz-dropdown-itm__text {
      word-break: break-word;
      white-space: break-spaces;
      line-height: 18px;
    }
  }

  .fz-heading-title {
    margin: 0 0 8px 0;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

export const ImageContent = styled.div`
  width: 248px;
  height: 84px;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
`;

export const Title = styled.h2`
  color: #333;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
  margin: 24px 0 8px 0;
  text-align: center;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #333333;
  display: block;
  margin-bottom: 24px;
`;

export const Count = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 24px;
`;

export const Item = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #eee;
  margin-bottom: 16px;
`;

export const ItemHeading = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
`;

export const ItemHeadingFlex = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

export const ItemName = styled.h5`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #333;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
`;

export const ItemCompany = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const ItemFooter = styled.div`
  padding-top: 16px;
  display: flex;
  gap: 40px;
  align-items: flex-start;
`;

export const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const Detail = styled.div`
  margin: 8px 0 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
`;

export const DetailLabel = styled.span`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
`;

export const DetailInfo = styled.p`
  overflow: hidden;
  color: #666;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const ItemLabel = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 6px;
`;

export const ItemDate = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 500;

  img {
    border-radius: 50%;
    border: 1px solid #eee;
    width: 24px;
    height: 24px;
  }
`;
export const ItemRemovedBy = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const ViewCandidate = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #408bfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const ViewCandidateUnavailable = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const TooltipContent = styled.div`
  width: 280px;
`;

export const tooltipContentStyles = {
  width: "13px",
  height: "13px"
};

export const AddInterviewButton = styled(Link)`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  padding: 8px 12px;
  border-radius: 8px;
  margin: ${({ $margin }) => $margin || "0 0 24px auto"};
  background: #444;
  text-align: center;
  display: block;
  width: 144px;
  flex: none;

  ${({ $disabled }) =>
    $disabled &&
    `
      background-color: #EEE;
      pointer-events: none;
  `}

  &:hover {
    color: #fff;
  }
`;

export const ListType = styled.h6`
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const ShareBlock = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  margin-bottom: 24px;

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
  }
`;

export const CopyLinkContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CopyLinkInput = styled.input`
  padding: 10px 16px;
  overflow: hidden;
  color: #666;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  width: calc(100% - 120px);

  @media screen and (min-width: 768px) {
    width: 400px;
  }
`;

export const CopyLinkButton = styled.button`
  border-radius: 8px;
  background: #eee;
  padding: 10px 16px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  flex: none;
`;

export const Label = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Socials = styled.div`
  margin-top: 24px;

  @media screen and (min-width: 1000px) {
    margin-top: 0;
  }
`;

export const SocialsFlex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;

const socialCommonStyles = `
  padding: 8px 4px !important;
  width: 110px;
  border-radius: 8px;
  border: 1px solid #c5c5c5 !important;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  svg {
    width: 24px;
    height: 24px;
  }
  
  @media screen and (min-width: 1200px) {
    width: 180px;
    padding: 8px 16px !important;
  }
`;

export const SocialsItemFacebook = styled(FacebookShareButton)`
  ${socialCommonStyles};

  svg {
    fill: #3e5893;
  }
`;

export const SocialsItemLinkedin = styled(LinkedinShareButton)`
  ${socialCommonStyles};

  svg {
    fill: #327bb8;
  }
`;

export const SocialsItemTwitter = styled(TwitterShareButton)`
  ${socialCommonStyles};

  svg {
    fill: #1da1f2;
  }
`;

export const FlexActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RemovePromotion = styled.button`
  background: transparent;
  border: none;
  color: #ff2727;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 16px;
  border-left: 1px solid #eee;

  svg {
    fill: #ff2727;
  }
`;

export const PendingStatus = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0;

  svg {
    width: 12px;
    height: 12px;
    fill: #666;
  }
`;

export const FailedStatus = styled.div`
  border-radius: 8px;
  border: 1px solid #f3a900;
  background: rgba(255, 251, 232, 0.5);
  padding: 16px 24px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  svg {
    width: 20px;
    height: 20px;
    fill: #f3a900;
  }
`;

const integrationIcon = `
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eee;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const JOIcon = styled.span`
  ${integrationIcon};

  background: #f45730;
  color: #fff;
`;

export const DUIcon = styled.span`
  ${integrationIcon};

  background: #092343;
  color: #39cdbe;
`;

export const TMTIcon = styled.span`
  ${integrationIcon};

  background: #8d3bb6;
  color: #f0e0f9;
`;
