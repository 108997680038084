import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { MdForward, MdClose, MdRemoveRedEye, MdStar, MdChatBubble, MdEmail } from "react-icons/md";
import { ChevronsRight } from "@styled-icons/boxicons-regular";
import cn from "classnames";

import ProfileAvatar from "components/ProfileAvatar";
import ModalConsumer from "contexts/modal";
import Button from "components/Button";
import { capitalize } from "utils/helpers";
import { TABS } from "constants/tabs";
import SaveModalContent from "../SaveModalContent";
// import CampaignsModalContent from "../CampaignsModalContent";
import ContactOptionsModal from "../ContactOptionsModal";
import AddTalentToJob from "../AddTalentToJob";
import Comments from "../Comments";
import TagList from "../TagList";
import Checkbox from "../Checkbox";
import UserCard from "../UserCard";
import Subscription from "../Subscription";
import MessageButton from "../MessageButton";
import { theme } from "../../../b2c/contexts/theme";
import Dropdown from "../../../components/Selects";

const checkAndFix = value => (value ? Number(value).toFixed(0) : 0);

const TalentCard = ({
  id,
  unlockUser,
  tab,
  name,
  age,
  avatar,
  country,
  tags,
  addTag,
  removeTag,
  viewed,
  selected,
  select,
  index,
  removed,
  removeFromFolder,
  addComment,
  removeComment,
  getComments,
  talent,
  candidate,
  handleInviteToApply,
  profileUnlocked = false,
  user_id: userId,
  applied_at: appliedAt,
  total_work_experience: totalWorkExperience,
  current_position_title: currentPositionTitle,
  current_position_employer: currentPositionEmployer,
  highest_education_degree: highestEducationDegree,
  total_match: totalMatch,
  talent_match: talentMatch,
  cv_match: cvMatch,
  headhunting_folder_ids: headhuntingFolderIds,
  comments_count: commentsCount,
  selectedTalents,
  handleStopUpdatePage,
  isShowMove,
  stageRelationsOptions,
  moveToStage,
  activeFolderStageId,
  createShareCandidateUserForComment,
  createCommentShareCandidate
}) => {
  const { url, params } = useRouteMatch();
  const candidatePlural = `${selectedTalents && selectedTalents.length > 1 ? "s" : ""}`;
  const [commentCount, setCommentCount] = useState(commentsCount || 0);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const handleShowCommentModal = () => {
    setShowCommentModal(true);
  };

  const handleHideCommentModal = () => {
    if (showCommentModal) {
      setShowCommentModal(false);
    }
  };

  const fixedMatches = {
    total: checkAndFix(totalMatch),
    cv: checkAndFix(cvMatch),
    talent: checkAndFix(talentMatch)
  };

  const messageButton = () => {
    return (
      <MessageButton
        isUnlocked={profileUnlocked}
        unlockHandler={() => unlockUser(userId, id, true)}
        type="talent"
        users={[
          {
            id: userId,
            name
          }
        ]}
        Component={props => (
          <Subscription.Upgrade permission="messaging">
            <Button className="fz-btn-secondary fz-btn-secondary_yellow mr-1" {...props}>
              <span className="fz-btn-secondary__text">Next</span>
            </Button>
          </Subscription.Upgrade>
        )}
      />
    );
  };

  const addToJob = (toggle, campaign) => {
    return toggle(
      <AddTalentToJob
        selectedTalents={selectedTalents.length ? selectedTalents : [id]}
        toggle={toggle}
        addToJob={campaignActive => {
          addToJob(toggle, campaignActive);
        }}
        campaignActive={campaign}
      />,
      `Add talent${selectedTalents.length ? "s" : ""} to job`
    );
  };

  const Option = ({ label, innerRef, value }) => {
    return (
      <button
        type="button"
        ref={innerRef}
        className={cn(
          "fz-dropdown-itm",
          String(value) === String(activeFolderStageId) ? "fz-dropdown-itm_disabled" : ""
        )}
      >
        <span className="fz-dropdown-itm__text">{label}</span>
      </button>
    );
  };

  const handleMoveToState = stageId => {
    if (String(stageId) !== String(activeFolderStageId)) {
      moveToStage(stageId, [id], params.folder);
    }
  };

  return (
    <>
      <UserCard
        name={name}
        age={age}
        applied_at={appliedAt}
        country={country}
        current_position_title={currentPositionTitle}
        current_position_employer={currentPositionEmployer}
        viewed={viewed}
        id={id}
        profile_unlocked={profileUnlocked}
        selected={selected}
        removed={removed}
        handleStopUpdatePage={handleStopUpdatePage}
        to={{
          pathname: `${url}/${id}`,
          index
        }}
        Checkarea={
          <div className="fz-panel__checkarea">
            <Checkbox checked={selected} onCheck={select} />
          </div>
        }
        UserAvatar={
          <div className="fz-applicant__aside">
            <ProfileAvatar avatar={avatar} />
          </div>
        }
        Details={
          <>
            <div className="fz-infobox fz-infobox_thin">
              <div className="fz-infobox__title">{`${fixedMatches?.cv} %`}</div>
              <div className="fz-infobox__info">CV</div>
            </div>
            {talentMatch ? (
              <div className="fz-infobox fz-infobox_thin">
                <div className="fz-infobox__title">{`${fixedMatches.talent} %`}</div>
                <div className="fz-infobox__info">Talent</div>
              </div>
            ) : (
              <></>
            )}
            {totalWorkExperience ? (
              <div className="fz-infobox fz-infobox_thin">
                <div className="fz-infobox__title">{capitalize(totalWorkExperience)}</div>
                <div className="fz-infobox__info">Total work exp.</div>
              </div>
            ) : (
              <></>
            )}
            {highestEducationDegree ? (
              <div className="fz-infobox fz-infobox_thin">
                <div className="fz-infobox__title">{highestEducationDegree}</div>
                <div className="fz-infobox__info">Education</div>
              </div>
            ) : (
              <></>
            )}
          </>
        }
        Actions={
          <ModalConsumer>
            {({ toggle }) => (
              <div className="fz-panel__actions">
                {isShowMove && (
                  <Dropdown
                    selectOptions={stageRelationsOptions}
                    onSelect={stageId => handleMoveToState(stageId)}
                    Option={Option}
                  >
                    <Button
                      className="fz-btn_small-padding"
                      icon={<ChevronsRight width="18px" />}
                      size="sm"
                      color="gray"
                      role="button"
                      tabIndex="0"
                    >
                      Move
                    </Button>
                  </Dropdown>
                )}

                {!params.folder && (
                  <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline" element="Talent pipeline">
                    <Button
                      size="sm"
                      color="gray"
                      onClick={() =>
                        toggle(
                          <SaveModalContent
                            selectedTalents={[id]}
                            inFolders={headhuntingFolderIds || []}
                            close={toggle}
                          />,
                          ""
                        )
                      }
                      icon={<MdStar />}
                      role="button"
                      tabIndex="0"
                    >
                      Save talent
                    </Button>
                  </Subscription.Upgrade>
                )}

                <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline">
                  <Button
                    size="sm"
                    color="gray"
                    onClick={() =>
                      toggle(
                        <ContactOptionsModal
                          selectedTalents={selectedTalents.length ? selectedTalents : [id]}
                          close={toggle}
                          handleInviteToApply={() =>
                            handleInviteToApply(toggle, selectedTalents.length ? selectedTalents : [id])
                          }
                          isUnlocked={profileUnlocked}
                          unlockHandler={() => unlockUser(userId, id, true)}
                          addToJob={() => addToJob(toggle)}
                        />,
                        `Do you also want to invite the candidate${candidatePlural} to apply?`
                      )
                    }
                    icon={<MdForward />}
                    role="button"
                    tabIndex="0"
                  >
                    Add to job
                  </Button>
                </Subscription.Upgrade>

                <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline">
                  <Button
                    size="sm"
                    color={theme.blue}
                    onClick={() =>
                      toggle(
                        <ContactOptionsModal
                          selectedTalents={selectedTalents.length ? selectedTalents : [id]}
                          close={toggle}
                          candidate={candidate || talent}
                          isJobHeadhuntPage
                          isJobHeadhunt
                          messageButton={messageButton}
                          handleInviteToApply={() =>
                            handleInviteToApply(toggle, selectedTalents.length ? selectedTalents : [id])
                          }
                          isUnlocked={profileUnlocked}
                          unlockHandler={() => unlockUser(userId, id, true)}
                        />,
                        `How do you want to contact the candidate${candidatePlural}?
                        `
                      )
                    }
                    icon={<MdEmail />}
                    role="button"
                  >
                    Contact
                  </Button>
                </Subscription.Upgrade>

                {params.folder && (
                  <Subscription.Upgrade paymentCheck permission="headhunting_talent_pipeline">
                    <Button
                      icon={<MdClose />}
                      size="sm"
                      color="warning"
                      onClick={() => removeFromFolder(params.folder)}
                      role="button"
                    >
                      Remove
                    </Button>
                  </Subscription.Upgrade>
                )}

                <div className="fz-panel__actions-separator" />

                <Link
                  to={{
                    pathname: `${url}/${id}`,
                    index
                  }}
                >
                  <Button icon={<MdRemoveRedEye />} size="sm" tag="span">
                    View CV
                  </Button>
                </Link>
              </div>
            )}
          </ModalConsumer>
        }
        Footer={
          <ModalConsumer>
            {() => (
              <div className="fz-panel__footer">
                <div className="fz-applicant-footer space-between ">
                  {tab !== "open" ? (
                    <TagList tags={tags} addTag={tag => addTag(id, tag)} removeTag={tag => removeTag(id, tag)} />
                  ) : (
                    <span />
                  )}
                  <Subscription.Upgrade permission="commenting">
                    <Button
                      icon={<MdChatBubble />}
                      size="sm"
                      color={commentCount > 0 ? "light-blue" : "gray"}
                      onClick={handleShowCommentModal}
                    >
                      {commentCount}
                    </Button>
                  </Subscription.Upgrade>
                </div>
              </div>
            )}
          </ModalConsumer>
        }
      />
      {showCommentModal && (
        <CommentsModal
          getComments={() => getComments(tab === TABS.OPEN ? userId : id)}
          createShareCandidateUserForComment={createShareCandidateUserForComment}
          createCommentShareCandidate={createCommentShareCandidate}
          add={(body, visibility) => {
            setCommentCount(commentCount + 1);
            return addComment(id, body, visibility);
          }}
          remove={comment_id => {
            setCommentCount(commentCount - 1);
            return removeComment(id, comment_id);
          }}
          toggle={handleHideCommentModal}
        />
      )}
    </>
  );
};

const CommentsModal = ({
  getComments,
  createShareCandidateUserForComment,
  createCommentShareCandidate,
  toggle,
  ...rest
}) => {
  const [comments, setComments] = useState();

  useEffect(() => {
    getComments().then(list => setComments(list));
  }, []);

  return (
    <Comments
      isModal
      toggleClose={toggle}
      comments={comments}
      createShareCandidateUserForComment={createShareCandidateUserForComment}
      createCommentShareCandidate={createCommentShareCandidate}
      isRemovePublic
      isSharedCandidates={false}
      {...rest}
    />
  );
};

export default TalentCard;
