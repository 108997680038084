import styled from "styled-components";

export const Modal = styled.div`
  max-width: 600px;
  min-height: 100px;
  margin-top: -55px;

  @media screen and (min-width: 768px) {
    min-width: 672px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin: 0 0 24px 0;
`;

export const Text = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin: 0 0 24px 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
`;

export const Delete = styled.button`
  background: #ffe140;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 10px;
  width: 170px;
  flex: none;
  border-radius: 8px;
  background: #eb3737;
`;

export const Cancel = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  background: #eeeeee;
  border-radius: 8px;
  margin: 0;
  width: 131px;
  padding: 10px;
`;
