import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import emptyImage from "images/b2b/emptyInterviews.png";
import InterviewItems from "../../../ApplicantCvScreen/components/InterviewItems";
import { tabs } from "../../../../constants/campaign";
import { Modal, Title, EmptyContent, Description, ImageContent, Heading, HeadingButton, Content } from "./styled";

const InterviewNotesModal = ({
  CampaignsStore: { interviewNotes, getInterviewNotes, setDefaultActiveTab },
  candidateName,
  candidateId,
  close,
  pathname
}) => {
  useEffect(() => {
    getInterviewNotes(candidateId, 1, true);
  }, []);

  const handleOpenInterviewTab = () => {
    setDefaultActiveTab(tabs.interviews);
    close();
  };

  return (
    <Modal>
      <Heading>
        <Title>{candidateName}</Title>
        <HeadingButton to={pathname} onClick={handleOpenInterviewTab}>
          Open in Application page
        </HeadingButton>
      </Heading>

      {interviewNotes.length ? (
        <Content>
          <InterviewItems candidateId={candidateId} handleCloseInterviewModal={close} />
        </Content>
      ) : (
        <EmptyContent>
          <ImageContent>
            <img src={emptyImage} alt="empty" />
          </ImageContent>
          <Description>No interview notes yet</Description>
        </EmptyContent>
      )}
    </Modal>
  );
};

export default inject("CampaignsStore")(observer(InterviewNotesModal));
