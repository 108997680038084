import styled from "styled-components";

export const Modal = styled.div`
  max-width: 640px;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin-bottom: 24px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin-bottom: 24px;
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  min-width: 170px;
  padding: 10px;
`;

export const Remove = styled.button`
  background: #eb3737;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px;
  min-width: 200px;

  ${({ isDisabled }) =>
    isDisabled
      ? `
      opacity: 0.2;
      pointer-events: none;
      `
      : ""}
`;
