import React, { useContext } from "react";
import Button from "components/Button";
import SubMain from "images/jobs/sub_main.svg";
import SubLock from "images/jobs/sub_lock.svg";
import SubNoperm from "images/jobs/sub_noperm.svg";
import { useModal } from "utils/hooks";
import { MdInfoOutline, MdQuestionAnswer } from "react-icons/md";
import HHInfoIcon from "images/hh_info_icon.svg";
import { WhatIncluded } from "./WhatIncluded";
import AppContext from "../../../contexts/init";

const SubscriptionBar = ({
  subscription: {
    permissions,
    descriptions,
    limits: { active_job_count, active_job_limit, unlock_profiles_limit, unlocked_profiles_count },
    type = "Free",
    valid_to,
    license_state,
    valid
  },
  alternative = false
}) => {
  const { current_user: currentUser } = useContext(AppContext);
  const jobCount = Math.max(active_job_limit - active_job_count, 0);
  const unlockCount = Math.max(unlock_profiles_limit - unlocked_profiles_count, 0);
  const isSupperAdmin = currentUser?.roles?.includes("lead_hr");
  const noPayment = license_state === "inactive" && !isSupperAdmin;
  const freeForeverLicense = type && type.indexOf("Free Forever") !== -1;

  const handleWhatIncluded = useModal(
    <WhatIncluded permissions={permissions} descriptions={descriptions} subscriptionType={type || "Free Forever"} />,
    `License details - ${type || "Free Forever"}`
  );

  return (
    <div
      className={`fz-informer${
        alternative || !valid || noPayment ? " locked" : jobCount > 0 && unlockCount > 0 ? "" : " empty"
      }`}
    >
      <div className="fz-informer__container">
        <div className="fz-informer__content">
          <div className="fz-media-pane">
            <div className="fz-media-pane__icon">
              {alternative || !valid || noPayment ? (
                <SubNoperm />
              ) : jobCount > 0 && unlockCount > 0 ? (
                <SubMain />
              ) : (
                <SubLock />
              )}
            </div>
            <div className="fz-media-pane__info">
              <h5 className="fz-media-pane__title">
                {valid ? (
                  !noPayment ? (
                    <>
                      You are on <span>{type}</span> license
                    </>
                  ) : (
                    "We haven't received a payment for current billing period"
                  )
                ) : (
                  "You don't currently have an active licence"
                )}
              </h5>
              <div className="fz-media-pane__text">
                {valid && !noPayment ? (
                  <>
                    {!freeForeverLicense && (
                      <>
                        Valid until{" "}
                        <span>
                          {new Date(valid_to).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "numeric"
                          })}
                        </span>
                      </>
                    )}

                    <Button.Simple
                      className="fz-link fz-link__secondary"
                      icon={<MdInfoOutline />}
                      onClick={handleWhatIncluded}
                    >
                      What’s included?
                    </Button.Simple>
                  </>
                ) : (
                  !freeForeverLicense && "Contact our business support for details"
                )}
              </div>
            </div>
          </div>
        </div>
        {!alternative && (
          <div className="fz-informer__additional">
            {valid && !noPayment && (
              <>
                <div
                  className={`fz-infobox fz-infobox_thin
                  ${jobCount > 0 || !active_job_limit ? "" : " fz-infobox_red"}`}
                >
                  <div className="fz-infobox__title">
                    {active_job_limit !== null
                      ? active_job_limit > 0
                        ? `${jobCount}/${active_job_limit}`
                        : 0
                      : "Unlimited"}
                  </div>
                  <div className="fz-infobox__info">Jobs available</div>
                </div>
                <div
                  className={`fz-infobox fz-infobox_thin
                  ${unlockCount > 0 ? "" : " fz-infobox_red"}`}
                >
                  <div className="fz-infobox__title">{unlockCount}</div>
                  <div className="fz-infobox__info">Unlocks left</div>
                </div>
              </>
            )}

            {valid && !noPayment ? (
              <Button
                {...(jobCount > 0 || !active_job_limit ? { tag: "a" } : {})}
                disabled={(jobCount === 0 && !!active_job_limit) || license_state === "inactive"}
                color="yellow"
                size="wide"
                href="/employers/campaign_for"
              >
                Create new job
              </Button>
            ) : (
              <Button
                className="intercom-trigger"
                size="md"
                color="yellow"
                icon={<MdQuestionAnswer />}
                role="button"
                tabIndex="0"
              >
                Contact business support
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const InfoBar = ({ title = "", text = "" }) => (
  <div className="fz-informer fz-informer_white">
    <div className="fz-informer__container">
      <div className="fz-informer__content">
        <div className="fz-media-pane">
          <div className="fz-media-pane__icon">
            <HHInfoIcon />
          </div>
          <div className="fz-media-pane__info">
            <h5 className="fz-media-pane__title">{title}</h5>
            <p className="fz-media-pane__text">{text}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SubscriptionBar;
