import React from "react";
import PropTypes from "prop-types";
import PlaceholderImage from "images/jobs/empy_state_img.svg";

const EmptyState = ({ narrow = "", showImage, customImage, title, subtitle, actionButton }) => (
  <div className={`fz-empty-state fadeInFromBottom ${narrow && "fz-empty-state_narrow"}`}>
    {showImage && <div className="fz-empty-state__image">{customImage || <PlaceholderImage />}</div>}
    <div className="fz-empty-state__content">
      {title && <h3 className="fz-empty-state__title">{title}</h3>}
      {subtitle && <p className="fz-empty-state__subtitle">{subtitle}</p>}
    </div>
    {actionButton && <div className="fz-empty-state__actions">{actionButton}</div>}
  </div>
);

EmptyState.propTypes = {
  showImage: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

EmptyState.defaultProps = {
  showImage: true,
  title: "",
  subtitle: ""
};

export default EmptyState;
