import styled from "styled-components";

export const Modal = styled.div`
  width: 320px;

  @media screen and (min-width: 768px) {
    width: 520px;
  }
`;

export const Token = styled.div`
  border-radius: 4px;
  background: #f3f3f3;
  padding: 8px 16px;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 24px;
`;

export const Description = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 24px;
`;

export const CheckboxText = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 24px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const Cancel = styled.button`
  border-radius: 8px;
  background: #eee;
  padding: 12px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
`;

export const Delete = styled.button`
  border-radius: 8px;
  background: #eb3737;
  padding: 12px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.2" : "`")};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "default")};
`;
