import React, { Component } from "react";
import { observer, inject, Provider } from "mobx-react";

import store from "../stores/CampaignsStore";

const withStoreCampaigns = WrappedComponent => {
  const Injected = inject("store")(observer(({ store, ...props }) => <WrappedComponent store={store} {...props} />));

  return class withStore extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Provider store={store}>
          <Injected {...this.props} />
        </Provider>
      );
    }
  };
};

export default withStoreCampaigns;
