import React from "react";

import Button from "components/Button";
import { SelectAllContent } from "./styled";
import Checkbox from "../Checkbox";

const BatchActions = ({
  selected = false,
  selectedSomeone,
  selectAll,
  clearSelection,
  children,
  selectedCandidates,
  navigationIds,
  isNewSelectAll,
  candidatesLength
}) => {
  const selectedCandidatesLength = selectedCandidates?.length;
  const navigationIdsLength = navigationIds?.length;
  const isSelectPart =
    selectedCandidatesLength !== candidatesLength &&
    selectedCandidatesLength !== navigationIdsLength &&
    selectedCandidatesLength;
  const isSelectedAllNavigation =
    selectedCandidatesLength && navigationIdsLength && selectedCandidatesLength === navigationIdsLength;
  const isShowSelectAllNavigation = navigationIdsLength && !isSelectedAllNavigation && selectedCandidatesLength;

  return (
    <div className="fz-card-control-bar fz-card-control-bar_indent">
      <div className="fz-card-control-bar__main">
        {isNewSelectAll ? (
          <>
            <Checkbox
              id="select_all"
              checked={selectedCandidates.length || selected}
              onCheck={selectAll}
              onUncheck={clearSelection}
              isSelectPart={isSelectPart}
            >
              {isSelectedAllNavigation ? "All " : ""}
              {selectedCandidates.length ? `${selectedCandidates.length} selected` : " Select all"}
            </Checkbox>

            {isShowSelectAllNavigation ? (
              <SelectAllContent>
                <Button.Simple className="fadeInFromLeft" onClick={() => selectAll(true)}>
                  {`Select all ${navigationIds.length} candidates`}
                </Button.Simple>
              </SelectAllContent>
            ) : null}
          </>
        ) : (
          <Checkbox id="select_all" checked={selected} onCheck={selectAll} onUncheck={clearSelection}>
            {selected ? "Clear selection" : " Select all"}
          </Checkbox>
        )}

        {selectedSomeone && !selected && !isNewSelectAll && (
          <>
            <div className="fz-card-control-bar__separator fadeInFromLeft" />
            <Button.Simple className="fadeInFromLeft" onClick={clearSelection}>
              Clear selection
            </Button.Simple>
          </>
        )}
      </div>
      {selectedSomeone && (
        <div className="fz-card-control-bar__additional fz-card-control-bar__additional_indent fadeInFromLeft">
          {children}
        </div>
      )}
    </div>
  );
};

export default BatchActions;
