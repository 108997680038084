import React, { useState, useEffect } from "react";

import Layout from "../Layout";

const TextExpander = ({ expanded = false, children }) => {
  const [state, expState] = useState(false);

  useEffect(() => {
    expState(expanded);
  }, [expanded]);

  return <Layout.Expander expanded={state}>{children}</Layout.Expander>;
};

export default TextExpander;
