import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import cn from "classnames";
import avatarPlaceholder from "images/empty_avatar.png";

const UserCvCircleMatch = ({
  percentValue = 0,
  avatar = avatarPlaceholder,
  innerStats = false,
  placeholder = false,
  isShowCircleMatch
}) => {
  const [avatarImage, setAvatar] = useState(avatar);

  useEffect(() => {
    setAvatar(avatar);
  }, [avatar]);

  const fillColor = () => {
    if (placeholder) {
      return {
        pathColor: "#FFF5D6",
        trailColor: "#FFF5D6"
      };
    }
    if (!placeholder && percentValue < 50) {
      return {
        pathColor: "#B00020",
        trailColor: "#F5E1E5"
      };
    }
    if (!placeholder && percentValue < 75) {
      return {
        pathColor: "#FFBE00",
        trailColor: "#FFF5D6"
      };
    }
    return {
      pathColor: "#00BB28",
      trailColor: "#DFF7E4"
    };
  };

  const strokeWidth = innerStats ? 8 : 6;

  const wrapperClasses = cn("fz-user-progres", {
    "fz-user-progres_with-placeholder": placeholder
  });

  const renderStats = () => (
    <div className="fz-user-progres__stats">
      <h6 className="fz-user-progres__stats-title">{percentValue}%</h6>
      <p className="fz-user-progres__stats-text">Match</p>
    </div>
  );

  const renderAvatar = () => (
    <img src={avatarImage} onError={() => setAvatar(avatarPlaceholder)} alt="Avatar" className="fz-user-progres__img" />
  );

  const renderContent = () => {
    if (placeholder) {
      return <div className="fz-user-progres__placeholder" />;
    }
    return innerStats ? renderStats() : renderAvatar();
  };

  return (
    <div className={wrapperClasses}>
      <div className="fz-user-progres__content">{renderContent()}</div>
      {isShowCircleMatch && (
        <CircularProgressbar value={percentValue} strokeWidth={strokeWidth} styles={buildStyles(fillColor())} />
      )}
    </div>
  );
};

export default UserCvCircleMatch;
