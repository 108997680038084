import React, { useContext, useState } from "react";

import {
  Modal,
  Title,
  Actions,
  Cancel,
  Update,
  Label,
  List,
  ListItem,
  ShowPersonalityTitle,
  ShowPersonalityText,
  ShowPersonalityContent
} from "./styled";
import Checkbox from "../../../../components/Checkbox";
import { ModalContext } from "../../../../../contexts/modal";
import InitContext from "../../../../../contexts/init";

const ExportProfile = ({ exportProfile, toggle, selectedTalents }) => {
  const [isActive, setIsActive] = useState(false);
  const { toggle: toggleModal } = useContext(ModalContext);
  const onCloseModal = () => (toggleModal ? toggleModal() : toggle());
  const { current_user } = useContext(InitContext);

  const handleSubmit = () => {
    exportProfile(!isActive);
    onCloseModal();
  };

  return (
    <Modal>
      <Title>Export profile{`${selectedTalents.length > 1 ? "s" : ""}`}</Title>
      <Label>Creates individual PDF files that include:</Label>
      <List>
        <ListItem>Talent profile information (Work experience, education, skills , etc.)</ListItem>
        <ListItem>Personality profile</ListItem>
        <ListItem>Test results</ListItem>
      </List>
      <Label>Once the export is done, we’ll email you a download link to {current_user.email}</Label>
      <ShowPersonalityContent>
        <div>
          <ShowPersonalityTitle>Show personal and contact details</ShowPersonalityTitle>
          <ShowPersonalityText>
            This will show the full name, profile picture, contact details, references and links in exported PDF.
          </ShowPersonalityText>
        </div>
        <Checkbox
          switchType
          checked={isActive}
          onCheck={() => setIsActive(true)}
          onUncheck={() => setIsActive(false)}
          isGray
        />
      </ShowPersonalityContent>
      <Actions>
        <Cancel onClick={onCloseModal}>Cancel</Cancel>
        <Update onClick={handleSubmit}>Export profiles</Update>
      </Actions>
    </Modal>
  );
};

export default ExportProfile;
