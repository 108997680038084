import React, { useState, useEffect } from "react";
import cn from "classnames";
import Popup from "reactjs-popup";

import InfoPane from "../InfoPane";

const QuestionCounterChart = ({ average_score = 0, max_score = 0, test_name = "", user_score = 0 }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [pointPosition, setPointPosition] = useState(0);
  const [stepWidth, setStepWidth] = useState(0);
  const [isStepActive, setStepActive] = useState(false);

  useEffect(() => {
    setPointPosition(Math.floor((average_score / max_score) * 100));
    setStepWidth(100 / max_score);
    setStepActive(true);
  });

  const stepClasses = cn("fz-question-counter-chart__step", {
    "is-active": isStepActive
  });
  const progressClasses = cn("fz-question-counter-chart__progress");

  const onMouseEnterBarHandle = () => {
    setTooltipOpen(true);
  };
  const onMouseLeaveBarHandle = () => {
    setTooltipOpen(false);
  };

  const renderSteps = () =>
    [...Array(user_score).keys()].map(itm => (
      <div className={stepClasses} key={itm} style={{ width: `${stepWidth}%` }} />
    ));

  return (
    <div className="margin-bottom-sm" onMouseEnter={onMouseEnterBarHandle} onMouseLeave={onMouseLeaveBarHandle}>
      <InfoPane.Header>
        <InfoPane.Cell>
          <h3 className="fz-heading-subtitle">{test_name}</h3>
        </InfoPane.Cell>
        <InfoPane.Cell>
          <div className="fz-info-text m-0">
            <span className="fz-info-text__text">
              Correct answers <strong>{user_score}</strong> of <strong>{max_score}</strong>
            </span>
          </div>
        </InfoPane.Cell>
      </InfoPane.Header>
      <InfoPane.Body>
        <div className="fz-question-counter-chart">
          <div className="fz-question-counter-chart__container">
            <Popup
              className="fz-tooltip"
              trigger={<div className="fz-question-counter-chart__value-point" style={{ left: `${pointPosition}%` }} />}
              position="bottom center"
              on="hover"
              open={isTooltipOpen}
            >
              Average
            </Popup>

            <div className={progressClasses}>{renderSteps()}</div>
          </div>
        </div>
      </InfoPane.Body>
    </div>
  );
};

export default QuestionCounterChart;
