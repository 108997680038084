import React, { useLayoutEffect, useMemo, useContext } from "react";
import { observer, inject } from "mobx-react";
import { ScreenClassContext } from "react-grid-system";

import Content from "../Content";
import { SingleConversationWrapper } from "./styled";

const SingleConversation = ({
  Messages: {
    groupedMessages: messages,
    draftMessages,
    setDraftMessage,
    getMessages,
    addMessage,
    updateMessage,
    removeMessage,
    disableMessenger
  },
  conversation,
  campaignEmployerId
}) => {
  const screen = useContext(ScreenClassContext);
  const isMobile = /sm|xs/.test(screen);

  useLayoutEffect(() => {
    if (conversation) getMessages(conversation.id);

    return () => {
      disableMessenger();
    };
  }, []);

  const draftMessage = useMemo(() => {
    if (draftMessages.some(item => item.id == conversation.id)) {
      return draftMessages.find(item => item.id == conversation.id).body;
    }
    return "";
  }, [draftMessages, conversation]);

  return (
    <SingleConversationWrapper>
      <Content
        isMobile={isMobile}
        deselectConversation={() => ({})}
        clearMessages={() => ({})}
        messages={messages}
        draftMessage={draftMessage}
        setDraftMessage={setDraftMessage}
        addMessage={addMessage}
        updateMessage={updateMessage}
        removeMessage={removeMessage}
        campaignEmployerId={campaignEmployerId}
        {...conversation}
      />
    </SingleConversationWrapper>
  );
};

export default inject("Messages")(observer(SingleConversation));
