import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import cn from "classnames";

const StyledGroup = styled.div(item => item.styled);

const InfoPane = ({ children, className = "" }) => <div className={`fz-info-pane ${className}`}>{children}</div>;

const Header = ({ children, className = "" }) => <div className={`fz-info-pane__header ${className}`}>{children}</div>;

const Body = ({ children, className = "" }) => <div className={`fz-info-pane__body ${className}`}>{children}</div>;

const Cell = ({ children, className = "" }) => <div className={`fz-info-pane__cell ${className}`}>{children}</div>;

const Group = ({ children, className, gray, ...rest }) => {
  const wrapperClassNames = cn(
    "ffz-info-pane__group",
    {
      "fz-info-pane__group_gray": gray
    },
    className
  );
  return (
    <StyledGroup className={wrapperClassNames} {...rest}>
      {children}
    </StyledGroup>
  );
};

const Item = ({ title, customTitle, subtitle, data, actions, children, ...rest }) => {
  return (
    <InfoPane {...rest}>
      <InfoPane.Header className="mb-0">
        <InfoPane.Cell>
          {customTitle || <h3 className="fz-heading-subtitle m-0">{title}</h3>}

          {subtitle && (
            <div className="fz-info-text mb-0">
              <span className="fz-info-text__text fz-info-text__text_light">{parse(subtitle)}</span>
            </div>
          )}
        </InfoPane.Cell>
        <InfoPane.Cell className="fz-info-pane__cell_centered">
          {data && <p className="fz-text text-right fz-text_normal fz-text-color__black m-0 ml-1">{data}</p>}
          {actions}
        </InfoPane.Cell>
      </InfoPane.Header>
      <InfoPane.Body>{children}</InfoPane.Body>
    </InfoPane>
  );
};

export default Object.assign(InfoPane, { Header, Body, Cell, Group, Item });
