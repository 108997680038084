import React, { useEffect, useState } from "react";
import Button from "components/Button";
import Pane from "../Pane";
import Input from "../Input";
import { Shortcuts } from "./Shortcuts";

export const TagInput = ({ list, shortcuts, add, cancel }) => {
  const [input, change] = useState("");
  const [tags, modify] = useState(list);
  const [toRemove, remove] = useState([]);
  const [shortcutsList, setShortcutsList] = useState([]);

  const handleInput = text => {
    const findElement = shortcuts.filter(item => item.toLowerCase().includes(text.toLowerCase()));
    if (text.length <= 32) {
      change(text);
    }
    if (text.length >= 3) {
      setShortcutsList(findElement);
    } else {
      setShortcutsList(shortcuts);
    }
  };

  const handleSave = () => {
    const findElement = shortcuts.find(item => item.toLowerCase().includes(input.toLowerCase()));

    add(input.length >= 3 && !findElement ? tags.concat(input) : tags, toRemove);
    if (input.length >= 3 && !findElement) {
      setShortcutsList(prev => [...prev, input]);
    }
  };

  const handleCheck = sc => {
    modify(tags.concat(sc));
  };

  const handleUncheck = sc => {
    modify(tags.filter(tag => tag !== sc));
    remove(toRemove.concat(sc));
  };

  useEffect(() => {
    if (shortcuts.length && !shortcutsList.length) {
      setShortcutsList(shortcuts);
    }
  }, [shortcuts]);

  return (
    <Pane.Split extraCss={{ position: "absolute", top: "42px", zIndex: 100, padding: "5px 0" }}>
      <div className="fz-info-text__text">
        <strong>Search or create new tag</strong>
      </div>
      <Input onChange={handleInput} placeholder="Start typing tag name" extraClass="height-35px" />

      <Shortcuts handleCheck={handleCheck} handleUncheck={handleUncheck} shortcutsList={shortcutsList} tags={tags} />
      <div style={{ display: "flex", width: "120px", marginLeft: "auto", justifyContent: "space-between" }}>
        <Button size="sm" color="gray" onClick={cancel} tabIndex="0" role="button">
          Cancel
        </Button>
        <Button size="sm" color="blue" onClick={handleSave} tabIndex="0" role="button">
          Save
        </Button>
      </div>
    </Pane.Split>
  );
};
