import React, { useEffect, useMemo, useRef, useState } from "react";

import { buildOptionsByIdAndName, buildOptionsByIdAndLabel, sortByAlphabet } from "b2b/utils/helpers";
import SelectView from "../SelectView";
import SelectSearch from "../SelectSearch";
import { SelectsViewComponent, ClearAll, SelectsViewFlex, CountryContainer } from "./styled";
import { priorityCountries } from "../../../../constants/headhunting";

const SelectsView = ({ initFilters, onChange, appliedFilters, assignedToEmployersCompanies, commonCountries }) => {
  const defaultLengthBorderEmployer = 2;
  const employers = buildOptionsByIdAndName(initFilters?.employers || []);
  const members = buildOptionsByIdAndName(initFilters?.owners || []);
  const campaignTypes = buildOptionsByIdAndLabel(initFilters.campaign_types || []);
  const countries = buildOptionsByIdAndName(commonCountries || []);
  const [currentActiveActions, setCurrentActiveActions] = useState("");
  const refContainer = useRef();
  const [countrySearchValue, setCountrySearchValue] = useState("");
  const [activeCountry, setActiveCountry] = useState("");

  const assignedToEmployersCompaniesIds = useMemo(() => {
    return assignedToEmployersCompanies.map(({ id }) => id);
  }, [assignedToEmployersCompanies]);

  const findEmployer = useMemo(() => {
    return employers.filter(({ value }) => assignedToEmployersCompaniesIds.includes(value));
  }, [employers]);

  const optionsEmployers = useMemo(() => {
    return sortByAlphabet(employers).sort(({ value }) => (assignedToEmployersCompaniesIds.includes(value) ? -1 : 1));
  }, [employers]);

  const countryOptions = useMemo(() => {
    return sortByAlphabet(countries).sort(({ label }) => (priorityCountries.includes(label) ? -1 : 1));
  }, [countries]);

  const optionsMembers = useMemo(() => sortByAlphabet(members), [members]);

  const handleChangeCampaigns = value => {
    onChange({ campaign_type: value });
  };

  const handleChangeEmployer = value => {
    onChange({ employer_id: value });
  };

  const handleChangeMember = value => {
    onChange({ owner_id: value });
  };

  const handleChangeLocation = country => {
    onChange({ country_id: country.value });
    setActiveCountry(country);
  };

  const handleClearAll = () => {
    onChange({ term: "", country_id: "", owner_id: "", employer_id: "", campaign_type: "" });
  };

  const handleCountrySearch = value => {
    setCountrySearchValue(value);
  };

  const {
    employer_id: employerId,
    country_id: countryId,
    campaign_type: campaignType,
    owner_id: ownerId,
    term
  } = appliedFilters;

  const isActiveSomeFilter = employerId || countryId || campaignType || ownerId || term;

  useEffect(() => {
    if (!activeCountry && countryId) {
      const findActiveCountry = countries.find(item => Number(item.value) === Number(countryId));

      if (findActiveCountry && findActiveCountry.value) {
        setActiveCountry(findActiveCountry);
      }
    }
  }, [countryId, countries.length, commonCountries.length]);

  return (
    <>
      <SelectsViewComponent ref={refContainer}>
        <SelectsViewFlex>
          <SelectView
            options={campaignTypes}
            prompt="All campaign types"
            onSelect={value => handleChangeCampaigns(value)}
            width="200px"
            initial={campaignType}
            isNewPopup
            setCurrentActiveActions={prompt => setCurrentActiveActions(prompt)}
            currentActiveActions={currentActiveActions}
            refContainer={refContainer}
          />
          {/*
          <SelectView
            options={countryOptions}
            prompt="All locations"
            onSelect={handleChangeLocation}
            isShowPriority
            initial={countryId}
            isNewPopup
            setCurrentActiveActions={prompt => setCurrentActiveActions(prompt)}
            currentActiveActions={currentActiveActions}
            refContainer={refContainer}
          />
          */}
          <CountryContainer>
            <SelectSearch
              value={activeCountry}
              defaultValue="All locations"
              options={countrySearchValue.length > 2 ? countryOptions : []}
              onInputChange={handleCountrySearch}
              onSelect={handleChangeLocation}
              inputValue={countrySearchValue}
              isSearchable
            />
          </CountryContainer>
          <SelectView
            options={optionsEmployers}
            prompt="All companies"
            customPrompt="Your companies"
            onSelect={handleChangeEmployer}
            initial={employerId}
            dividerLength={findEmployer.length + defaultLengthBorderEmployer}
            isNewPopup
            setCurrentActiveActions={prompt => setCurrentActiveActions(prompt)}
            currentActiveActions={currentActiveActions}
            refContainer={refContainer}
          />
          <SelectView
            options={optionsMembers}
            prompt="All members"
            onSelect={handleChangeMember}
            initial={ownerId}
            isNewPopup
            setCurrentActiveActions={prompt => setCurrentActiveActions(prompt)}
            currentActiveActions={currentActiveActions}
            refContainer={refContainer}
          />
        </SelectsViewFlex>
        <ClearAll onClick={handleClearAll} isActive={isActiveSomeFilter}>
          Clear all
        </ClearAll>
      </SelectsViewComponent>
    </>
  );
};

export default SelectsView;
