import React, { useState } from "react";
import { toJS } from "mobx";
import { onEnterPress } from "utils/helpers";

import Button from "components/Button";
import withStore from "../../hocs/withStore";

const SaveModalContent = ({ store: { saveSearch, getSearches, appliedFilters }, close }) => {
  const [searchName, setSearchName] = useState("");
  const [createMessage, setMessage] = useState({
    message: "Save search parameters to discover new talents later",
    error: false
  });

  const handleSaving = () => {
    if (searchName.length > 3) {
      saveSearch(searchName, toJS(appliedFilters)).then(() => {
        getSearches();
        close();
      });
    } else {
      setMessage({
        message: "Folder name is too short",
        error: true
      });
      setSearchName("");
    }
  };

  return (
    <div className="headhunting_modal">
      <div className={`modal_info ${createMessage.error ? "error" : ""}`}>{createMessage.message}</div>
      <h5>Search name</h5>
      <input
        className="modal_input"
        onKeyDown={e => onEnterPress(e, searchName, handleSaving)}
        onChange={e => setSearchName(e.target.value)}
        value={searchName}
      />
      <div className="fz-modal__actions">
        <Button size="md" color="gray" onClick={() => close()}>
          Cancel
        </Button>
        <Button size="md" color="blue" onClick={handleSaving}>
          Save search
        </Button>
      </div>
    </div>
  );
};

export default withStore(SaveModalContent);
