import React, { useContext } from "react";
import { MdEmail, MdStar, MdPersonPin, MdForward, MdFileDownload } from "react-icons/md";
import { FaFacebookSquare, FaTwitter, FaLinkedin } from "react-icons/fa";
import { toJS } from "mobx";

import ModalConsumer from "contexts/modal";
import Button from "components/Button";
import Spinner from "b2c/components/Core/Spinner";
import { TABS } from "constants/tabs";
import Subscription from "../../../../components/Subscription";
import ContactDetails from "../../../../components/ContactDetails";
import SaveModalContent from "../../../../components/SaveModalContent";
import ContactOptionsModal from "../../../../components/ContactOptionsModal";
import MessageButton from "../../../../components/MessageButton";
import AddTalentToJob from "../../../../components/AddTalentToJob";
import ExportProfileModal from "../../../HeadhuntScreen/components/ExportProfile";
import AppContext from "../../../../../contexts/init";

const TalentCvActions = ({
  path = "",
  name = "",
  id,
  userId,
  tab,
  profileUnlocked,
  linkedin,
  facebook,
  twitter,
  email,
  phone_number: phoneNumber,
  inFolders = [],
  updateTalent,
  unlockUser,
  handleInviteToApply,
  exportCvProfile,
  nationalId,
  state,
  isSearchCandidates,
  getAvailableJobForCandidate,
  isSearchCandidatesExternalCandidate
}) => {
  const isExportCvLoading = toJS(state).type.includes("export_cv") && toJS(state)?.loading;
  const { current_user: currentUser } = useContext(AppContext);
  const isLeadHr = currentUser?.roles?.includes("lead_hr");

  const handleOpenExportProfileModal = toggle => {
    return toggle(
      <ExportProfileModal
        toggle={toggle}
        exportProfile={isIncognito => exportCvProfile(userId, isIncognito)}
        selectedTalents={[userId]}
      />,
      ""
    );
  };
  const messageButton = () => {
    return (
      <MessageButton
        isUnlocked={profileUnlocked}
        unlockHandler={() => unlockUser(userId, id)}
        type="talent"
        users={[
          {
            id: userId,
            name
          }
        ]}
        Component={props => (
          <Subscription.Upgrade permission="messaging">
            <Button className="fz-btn-secondary fz-btn-secondary_yellow mr-1" {...props}>
              <span className="fz-btn-secondary__text">Next</span>
            </Button>
          </Subscription.Upgrade>
        )}
      />
    );
  };

  const addToJob = (toggle, campaign) => {
    return toggle(
      <AddTalentToJob
        selectedTalents={[id]}
        toggle={toggle}
        addToJob={campaignActive => {
          addToJob(toggle, campaignActive);
        }}
        campaignActive={campaign}
      />,
      "Add talent to job"
    );
  };

  return (
    <ModalConsumer>
      {({ toggle }) => (
        <>
          <div className="fz-applicant__additional fz-applicant__additional_absolute">
            <div className="fz-buttons-row mt-2">
              {/* hid during ticket  https://www.notion.so/Messaging-QA-23-11-f15be3c65db84388b0b960b1c7b844f6
                {tab !== "open" && (path.includes("saved") || inFolders.length > 0) &&
                  <a
                    className='fz-btn-secondary fz-btn-secondary_yellow'
                    href={getMessageUserPath(id)}
                  >

                    <MdEmail className='fz-btn-secondary__icon' />
                    <span className='fz-btn-secondary__text'>Message</span>

                  </a>
                }
                {tab === "open" &&
                  <Subscription.Unlock
                    unlocked={profileUnlocked}
                    element="Messaging"Zf
                    unlockHandler={() => unlockUser(userId)}
                    paymentCheck
                  >
                    <a
                      className='fz-btn-secondary fz-btn-secondary_yellow'
                      href={getMessageUserPath(userId)}
                    >

                      <MdEmail className='fz-btn-secondary__icon' />
                      <span className='fz-btn-secondary__text'>Message</span>
                    </a>
                  </Subscription.Unlock>
                }
              */}
              {!path.includes("saved") && inFolders.length === 0 && !isSearchCandidatesExternalCandidate && (
                <Subscription.Upgrade permission="headhunting_talent_pipeline" paymentCheck>
                  <Button
                    className="fz-btn-secondary fz-btn-secondary_yellow ml-1"
                    color="yellow"
                    overwriteClass
                    icon={<MdStar />}
                    style={{ minWidth: "140px" }}
                    onClick={() =>
                      toggle(
                        <SaveModalContent
                          inFolders={inFolders}
                          selectedTalents={[userId || id]}
                          close={() => {
                            toggle();
                            if (!isSearchCandidates) {
                              updateTalent();
                            }
                          }}
                        />,
                        ""
                      )
                    }
                  >
                    <span className="fz-btn-secondary__text">
                      {tab === TABS.OPEN ? "Save candidate" : "Save talent"}
                    </span>
                  </Button>
                </Subscription.Upgrade>
              )}

              {!isSearchCandidatesExternalCandidate && (
                <>
                  <Subscription.Upgrade permission="headhunting_talent_pipeline" paymentCheck>
                    <>
                      {!isSearchCandidates && (
                        <Button
                          className="fz-btn-secondary ml-1"
                          overwriteClass
                          icon={<MdForward />}
                          style={{ minWidth: "132px" }}
                          onClick={() =>
                            toggle(
                              <ContactOptionsModal
                                close={toggle}
                                candidate={{ email, phone_number: phoneNumber }}
                                handleInviteToApply={() => handleInviteToApply(toggle, id)}
                                isUnlocked={profileUnlocked}
                                unlockHandler={() => unlockUser(userId, id)}
                                addToJob={() => addToJob(toggle)}
                                isJobHeadhunt={isSearchCandidates}
                                isJobHeadhuntPage={isSearchCandidates}
                                getAvailableJobForCandidate={getAvailableJobForCandidate}
                              />,
                              "Do you also want to invite the candidate to apply?"
                            )
                          }
                        >
                          <span className="fz-btn-secondary__text">Add to job</span>
                        </Button>
                      )}

                      {isLeadHr && !isSearchCandidates && (
                        <Button
                          className="fz-btn-secondary ml-1"
                          style={{ minWidth: "150px", pointerEvents: isExportCvLoading ? "none" : "inherit" }}
                          overwriteClass
                          icon={
                            isExportCvLoading ? (
                              <Spinner primaryFill="black350" secondaryFill="white" margin="0 8px 0 0" />
                            ) : (
                              <MdFileDownload />
                            )
                          }
                          onClick={() => handleOpenExportProfileModal(toggle)}
                        >
                          <span className="fz-btn-secondary__text">
                            {isExportCvLoading ? "Preparing..." : "Export profile"}
                          </span>
                        </Button>
                      )}
                    </>
                  </Subscription.Upgrade>
                  <Subscription.Upgrade permission="headhunting_talent_pipeline" paymentCheck>
                    <Button
                      className="fz-btn-secondary ml-1"
                      overwriteClass
                      icon={<MdEmail />}
                      onClick={() =>
                        toggle(
                          <ContactOptionsModal
                            close={toggle}
                            candidate={{ email, phone_number: phoneNumber }}
                            handleInviteToApply={() => handleInviteToApply(toggle, id)}
                            messageButton={messageButton}
                            isUnlocked={profileUnlocked}
                            unlockHandler={() => unlockUser(userId, id, true)}
                            isJobHeadhuntPage
                            isJobHeadhunt
                            isSearchCandidates={isSearchCandidates}
                          />,
                          "How do you want to contact the candidate?"
                        )
                      }
                    >
                      <span className="fz-btn-secondary__text">Contact</span>
                    </Button>
                  </Subscription.Upgrade>
                </>
              )}
            </div>
          </div>
          <div className="fz-applicant__social">
            <Subscription.Unlock
              unlocked={profileUnlocked}
              element="Contact details"
              unlockHandler={() => unlockUser(userId || id)}
              paymentCheck
            >
              <Button.Simple
                icon={<MdPersonPin />}
                onClick={() =>
                  toggle(
                    <ContactDetails
                      linkedin={linkedin}
                      facebook={facebook}
                      twitter={twitter}
                      email={email}
                      phone={phoneNumber}
                      nationalId={nationalId}
                    />,
                    "Contact details"
                  )
                }
              >
                Contact details
              </Button.Simple>
            </Subscription.Unlock>
            {linkedin && (
              <Button.Simple icon={<FaLinkedin />} tag="a" href={linkedin} target="_blank" className="mr-1 ml-3" />
            )}
            {twitter && <Button.Simple icon={<FaTwitter />} tag="a" href={twitter} target="_blank" className="mr-1" />}
            {facebook && <Button.Simple icon={<FaFacebookSquare />} tag="a" href={facebook} target="_blank" />}
          </div>
        </>
      )}
    </ModalConsumer>
  );
};
/*
const SavePrompt = ({ saveTalent, close }) => (
  <>
    <div className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0">
      You can only view contact details of saved talents.
    </div>
    <div className="fz-modal__actions">
      <Button size="md" color="gray" onClick={() => close()}>
        Cancel
      </Button>
      <Button size="md" color="yellow" icon={<MdStar />} onClick={saveTalent}>
        Save talent
      </Button>
    </div>
  </>
);
*/

export default TalentCvActions;
