import React, { useMemo, useContext } from "react";

import ModalConsumer from "contexts/modal";
import AppContext from "contexts/init";
import { TABS } from "constants/tabs";
import Layout from "../../../../components/Layout";
import ApplicantCard from "../ApplicantCard";
import TalentCard from "../TalentCard";

const RenderCandidates = ({
  isFastApply,
  candidates = [],
  tab = "applicants",
  campaign,
  campaignEmployerId,
  shortcuts,
  voteForCandidate,
  shortlist,
  contact,
  shortlistTalents,
  move,
  moveTalents,
  reject,
  removeTalent,
  rate,
  message,
  loading,
  perPage,
  candidateTag,
  options = [],
  page,
  select,
  selectedCandidates,
  scope = "",
  isApplied = false,
  commented,
  filtered = [],
  unlockUser,
  showSaveModalContent,
  isSharedCandidates,
  activeGuestLinkToken,
  remove,
  add,
  editComment,
  getComments,
  appliedFiltersScope,
  createShareCandidateUserForComment,
  createCommentShareCandidate,
  setKeepPageInfo,
  getCandidateComments
}) => {
  const { is_current_user_external_collaborator } = useContext(AppContext);
  const placeholders = useMemo(() => [...Array(Number(perPage)).keys()], [perPage]);

  const empty = useMemo(
    () =>
      !isApplied
        ? {
            title: `You have no ${tab === TABS.APPLICANTS ? `${scope} applicants` : "recommended talents"} yet.`,
            subtitle: `New ${tab === TABS.APPLICANTS ? `${scope} applicants` : "recommended talent"} will appear here.`
          }
        : {
            title: `No matching ${tab === TABS.APPLICANTS ? "applicants" : "recommended talents"}.`,
            subtitle: ""
          },
    [tab, scope, isApplied]
  );

  const getTotalCommentCount = candidate =>
    candidate.comments_count +
    (commented.some(item => item.id === candidate.id) ? commented.find(item => item.id === candidate.id).count : 0);

  // Filters scopes based on candidates current phase and type
  const filteredCandidateScopes = phase =>
    options.filter(({ value }) => value !== phase && value !== "all" && value !== "applied");

  return (
    <>
      {loading ? (
        placeholders.map(key => <ApplicantCard.Placeholder key={`jb_p${key}`} />)
      ) : candidates.length - filtered.length > 0 ? (
        <ModalConsumer>
          {({ toggle }) =>
            candidates.map((candidate, index) =>
              tab === TABS.APPLICANTS ? (
                <ApplicantCard
                  isExternalCandidate={Boolean(candidate.prospect_id)}
                  interviewNotesCount={candidate.interview_notes_count}
                  canSwitchCandidate={candidate.can_switch_candidate}
                  {...candidate?.user}
                  {...candidate}
                  key={`${candidate?.user?.name}-${candidate.id}`}
                  scope={scope}
                  systemTags={candidate.system_tags}
                  index={index + 1 + 10 * (page - 1)}
                  selected={selectedCandidates.includes(candidate.id)}
                  select={() => select(candidate.id)}
                  campaign={campaign}
                  campaignEmployerId={campaignEmployerId}
                  shortcuts={shortcuts}
                  options={filteredCandidateScopes(candidate.current_phase)}
                  addTag={tag => candidateTag(candidate.id, tag, "add")}
                  removeTag={tag => candidateTag(candidate.id, tag, "remove")}
                  shortlist={() => shortlist(candidate.id)}
                  move={phase => (phase === "rejected" ? reject(toggle, candidate.id) : move(candidate.id, phase))}
                  reject={() => reject(toggle, candidate.id)}
                  message={() => message(candidate.id)}
                  rate={type => rate(candidate.id, type)}
                  voteForCandidate={voteForCandidate}
                  comments_count={getTotalCommentCount(candidate)}
                  getComments={getComments}
                  add={add}
                  editComment={editComment}
                  createShareCandidateUserForComment={createShareCandidateUserForComment}
                  createCommentShareCandidate={createCommentShareCandidate}
                  remove={remove}
                  removed={filtered.some(item => item.id === candidate.id)}
                  isExternal={is_current_user_external_collaborator}
                  profileUnlocked={candidate.profile_unlocked}
                  removeTalent={() => removeTalent(toggle, candidate.id)}
                  unlockUser={unlockUser}
                  showSaveModalContent={() => showSaveModalContent(toggle, candidate)}
                  isSharedCandidates={isSharedCandidates}
                  isNotShowViewCv={
                    isSharedCandidates && !campaign.guest_accessible_sections?.includes("personal_details")
                  }
                  activeGuestLinkToken={activeGuestLinkToken}
                  appliedFiltersScope={appliedFiltersScope}
                  setKeepPageInfo={setKeepPageInfo}
                  getCandidateComments={getCandidateComments}
                />
              ) : (
                <TalentCard
                  isSharedCandidates={isSharedCandidates}
                  {...candidate.user}
                  {...candidate}
                  isFastApply={isFastApply}
                  key={`${candidate?.user?.name}-${candidate?.id}`}
                  index={index + 1 + 10 * (page - 1)}
                  selected={selectedCandidates.includes(candidate.id)}
                  select={() => select(candidate.id)}
                  campaign={campaign}
                  options={options.filter(option => !/rejected|shortlisted|applied|all/.test(option.value))}
                  shortlist={() => shortlistTalents(candidate.id)}
                  contact={() => contact(toggle, candidate)}
                  move={phase => moveTalents(candidate.id, phase)}
                  removed={filtered.some(item => item.id === candidate.id)}
                  isExternal={is_current_user_external_collaborator}
                  profileUnlocked={candidate.profile_unlocked}
                  candidate={candidate}
                  unlockUser={unlockUser}
                  showSaveModalContent={() => showSaveModalContent(toggle, candidate)}
                  viewed={false}
                />
              )
            )
          }
        </ModalConsumer>
      ) : (
        <Layout.EmptyState showImage title={empty.title} subtitle={empty.subtitle} />
      )}
    </>
  );
};

export default RenderCandidates;
