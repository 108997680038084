import { makeAutoObservable, flow } from "mobx";
import qs from "qs";
import parse from "html-react-parser";
import axios from "axios";

import { stringify } from "utils/qsUtil";
import * as State from "utils/storeHelpers";
import { TABS } from "constants/tabs";
import { getCampaignDetailsTabContentsPath, getCampaignUpdatesTabContentsPath } from "utils/urlHelpers";
import * as API from "../utils/jobsApi";
import { unlockUser } from "../utils/api";
import { getUrlPath } from "../utils/helpers";

const defaultApplied = {
  filters: {
    scope: "applied",
    term: "",
    // sort_by: "total_match",
    sort_order: "desc",
    location_ids: undefined,
    job_category_ids: undefined,
    industry_ids: undefined,
    language_ids: undefined,
    language: {
      ids: undefined,
      operator: "or"
    },
    skill: {
      ids: undefined,
      operator: "or"
    },
    certificate: {
      ids: undefined,
      operator: "or"
    },
    certificate_ids: undefined,
    education_ids: undefined,
    skill_ids: undefined,
    degree_ids: undefined,
    age_min: undefined,
    age_max: undefined,
    experience_max: undefined,
    experience_min: undefined,
    tag_ids: undefined
  },
  navigationIds: [],
  per_page: 10,
  page: 1
};

export class CampaignsStore {
  candidates = [];

  guestLinkMeta = {};

  reasons = [];

  isLoadingExternalCandidateCheckEmail = false;

  externalCandidateFoundUser = {};

  externalCandidateFormErrors = {};

  currentCandidate = {
    personality: {},
    talent: {}
  };

  selectedCampaign = {};

  isActiveInterviewModal = false;

  interviewModalCandidateId = "";

  isShowPreviewModalWhenCancel = true;

  interviewModalEditId = "";

  interviewNotes = [];

  interviewNotesMeta = {};

  defaultActiveTab = "";

  guestLinkContacts = [];

  appliedFilters = defaultApplied;

  availableJobsForCandidate = [];

  companies = [];

  applicationHistory = [];

  applicationHistoryMeta = {};

  navigationIds = [];

  integrations = [];

  integrationDetailForm = {
    form_data: {}
  };

  integrationDetailErrors = {};

  integrationEmployerNames = [];

  occupationAutocompleteList = [];

  occupationSearch = {};

  selectedAvailableJobForCandidate = {};

  guestLinkData = {};

  keepPageInfo = {
    page: "",
    scope: ""
  };

  state = {
    type: "",
    loading: false,
    error: false,
    message: ""
  };

  collaborators = [];

  collaboratorContacts = [];

  folders = [];

  isLoadingFolders = false;

  constructor() {
    makeAutoObservable(this);
  }

  total = 0;

  pendingFlows = [];

  switchUserData = {};

  filters = {
    scopes: [],
    filters: {},
    sorting: {}
  };

  get pageCount() {
    return Math.ceil(+this.total / +this.appliedFilters.per_page);
  }

  get filterCount() {
    const appliedKeys = Object.keys(this.appliedFilters.filters)
      .filter(key => !["scope", "sort_by", "sort_order", "term"].includes(key))
      .filter(key => this.appliedFilters.filters[key]);

    const appliedValues = appliedKeys
      .map(key => this.appliedFilters.filters[key])
      .filter(item => Array.isArray(item) || item?.ids?.length || typeof item === "number" || typeof item === "string")
      .flat();
    return appliedValues.length;
  }

  updateSelectedCampaign = data => {
    if (data.collaborators) {
      this.collaborators = data.collaborators;
    }
    this.selectedCampaign = {
      ...this.selectedCampaign,
      ...data
    };
  };

  setFilters = data => {
    this.filters = {
      ...this.filters,
      ...data
    };
  };

  setAppliedFilters = filters => {
    this.appliedFilters = {
      ...this.appliedFilters,
      ...filters
    };
  };

  resetAppliedFilters = async () => {
    const newFilters = this.appliedFilters.filters;
    const appliedKeys = Object.keys(this.appliedFilters.filters).filter(
      key => !["scope", "sort_by", "sort_order", "term"].includes(key)
    );

    appliedKeys.forEach(key => {
      if (newFilters[key]?.ids) {
        newFilters[key].ids = [];
        newFilters[key].operator = "or";
      } else {
        delete newFilters[key];
      }
    });

    this.setAppliedFilters({ filters: newFilters });
  };

  refreshFlows = newFlow => {
    Promise.resolve(() => this.pendingFlows.forEach(flowIten => flowIten.cancel()))
      .then(() => {
        this.pendingFlows = [];
      })
      .then(() => {
        if (newFlow) {
          this.pendingFlows.push(newFlow);
        }
      });
  };

  setUp = async (params, history) => {
    // sort_by = null
    const id = Number(params.id || history.location.pathname.replace(/\D+/g, ""));
    const { tab } = params;

    this.state = {
      type: "",
      loading: true,
      error: false,
      message: ""
    };

    const { location: { search } = {} } = history;
    const { filters, page = 1, per_page = 10 } = qs.parse(search.slice(1));

    if (this.selectedCampaign.id !== id || this.selectedCampaign.tab !== tab) {
      this.currentCandidate = {};
      this.setAppliedFilters({
        ...defaultApplied,
        filters: {
          ...defaultApplied.filters,
          ...filters,
          sort_by: tab === TABS.HEADHUNT ? "cv_match" : ""
          // sort_by: sort_by || (tab === TABS.HEADHUNT ? "cv_match" : "total_match")
        },
        page,
        per_page
      });
      if (tab === TABS.APPLICANTS || tab === TABS.HEADHUNT) {
        this.getCandidateFilters(id, tab);
      }
    }

    this.currentCandidate = {};

    if (this.selectedCampaign.tab !== tab) {
      this.updateSelectedCampaign({ tab });
    }

    if (this.selectedCampaign.id !== id) {
      await this.getCampaignDetails(id);
    }

    switch (tab) {
      default:
        this.state.loading = false;
        return this.state.loading;
      /*
        case "applicants":
        return this.refreshFlows(this.getCandidates(id, history, "", true));
        */
      case "headhunt":
        return this.refreshFlows(this.getHeadhunt(id, history));
    }
  };

  getCampaignDetails = async id => {
    API.getReasons(id)
      .then(({ data }) => {
        this.reasons = data.reasons;
      })
      .catch(err => {
        console.error(`Reasons data layer: ${err}`);
        throw err;
      });
    return API.getCampaignDetails(id)
      .then(res => {
        this.updateSelectedCampaign(res.data.campaign);
        this.getGuestLinkContacts(id);

        if (res.data.campaign.prioritize_industry_experience && res.data.campaign.industry_id) {
          this.setAppliedFilters({
            filters: {
              ...this.appliedFilters.filters,
              industry_ids: [res.data.campaign.industry_id]
            }
          });
        }
      })
      .catch(err => {
        console.error(`Campaign data layer: ${err}`);
        this.state = {
          type: `get_campaign_${id}_error`,
          loading: false,
          error: true,
          message: "Could not get campaign details",
          callback: () => {
            this.state.type = "";
            return this.getCampaignDetails(id);
          }
        };
      });
  };

  removeItemFromNavigationId = id => {
    this.navigationIds = this.navigationIds.filter(item => !String(id).includes(String(item)));
    this.total = this.navigationIds.length;
  };

  rejectMessage = (id, body) => {
    return API.rejectMessageRequest(id, body)
      .then(res => {
        API.getCandidates({
          id
        }).then(response => {
          const { meta: { scopes, filters, sorting } = {} } = response.data;

          if (!body.conversation) {
            this.state = State.setSuccess(this.state, {}, "Candidates were successfully moved to Rejected", true);
          } else {
            this.state = State.setSuccess(this.state, {}, "Rejection was successfully sent", true);
          }

          this.setFilters({
            scopes,
            filters,
            sorting
          });
        });
        return res;
      })
      .catch(err => {
        console.error(err);
        const error = err?.response?.data?.error;
        throw error;
      });
  };

  getIntegrations = id => {
    return API.getIntegrations(id)
      .then(response => {
        this.integrations = response.data.integrations;
      })
      .catch(err => {
        this.state = {
          type: err.status ? err.status : "500",
          loading: false,
          error: true,
          message: "Could not get integrations",
          callback: () => {
            this.state.type = "";
            return this.getIntegrations(id);
          }
        };
      });
  };

  // todo implement this in the next version
  getIntegrationStatus = (id, jobId) => {
    return API.getIntegrationStatus(id, jobId)
      .then(() => {})
      .catch(err => {
        this.state = {
          type: err.status ? err.status : "500",
          loading: false,
          error: true,
          message: "Could not get integrations",
          callback: () => {
            this.state.type = "";
            return this.getIntegrations(id);
          }
        };
      });
  };

  getEmployerNames = id => {
    return API.getEmployerNames(id)
      .then(response => {
        this.integrationEmployerNames = response.data.employers.map(item => ({
          id: item.id,
          value: item.business_id,
          label: item.name
        }));
      })
      .catch(err => {
        this.state = {
          type: err.status ? err.status : "500",
          loading: false,
          error: true,
          message: "Could not get employer names",
          callback: () => {
            this.state.type = "";
            return this.getEmployerNames(id);
          }
        };
      });
  };

  getOccupationAutocomplete = (id, query) => {
    return API.getOccupationAutocomplete(id, query)
      .then(response => {
        this.occupationAutocompleteList = response.data.labels;
      })
      .catch(err => {
        this.state = {
          type: err.status ? err.status : "500",
          loading: false,
          error: true,
          message: "Could not get occupation autocomplete",
          callback: () => {
            this.state.type = "";
            return this.getOccupationAutocomplete(id, query);
          }
        };
      });
  };

  getOccupationSearch = (id, query) => {
    return API.getOccupationSearch(id, query)
      .then(response => {
        this.occupationSearch = response.data;
      })
      .catch(err => {
        this.state = {
          type: err.status ? err.status : "500",
          loading: false,
          error: true,
          message: "Could not get employer names",
          callback: () => {
            this.state.type = "";
            return this.getOccupationSearch(id, query);
          }
        };
      });
  };

  getTMTIntegrations = (id, promotionId) => {
    return API.getTMTIntegrations(id, promotionId)
      .then(response => {
        this.integrationDetailForm = response.data.integration;
      })
      .catch(err => {
        this.state = {
          type: err.status ? err.status : "500",
          loading: false,
          error: true,
          message: "Could not retrieve integrations",
          callback: () => {
            this.state.type = "";
            return this.getTMTIntegrations(id, promotionId);
          }
        };
      });
  };

  createIntegration = (id, params) => {
    if (Object.keys(this.integrationDetailErrors).length) {
      this.resetIntegrationDetailErrors();
    }

    return API.createIntegration(id, params)
      .then(() => {
        this.state = {
          loading: false,
          type: `${id}_create_integration_success`,
          error: false,
          message: "Successfully created interview integration"
        };

        return this.state;
      })
      .catch(error => {
        this.integrationDetailErrors = error.response.data.errors;
        if (window.scrollY) {
          window.scrollTo(0, 0);
        }

        this.state = {
          type: `${id}_create_integration_failed`,
          loading: false,
          error: true,
          message: error.response.data.error || "Failed to create integration",
          callback: () => {
            this.state.type = "";
            this.createIntegration(id, params);
          }
        };
      });
  };

  updateIntegration = (id, params, promotionId) => {
    if (Object.keys(this.integrationDetailErrors).length) {
      this.resetIntegrationDetailErrors();
    }

    return API.updateIntegration(id, params, promotionId)
      .then(() => {
        this.state = {
          loading: false,
          type: `${id}_update_integration_success`,
          error: false,
          message: "Successfully updated interview integration"
        };

        return this.state;
      })
      .catch(error => {
        this.integrationDetailErrors = error.response.data.errors;
        if (window.scrollY) {
          window.scrollTo(0, 0);
        }

        this.state = {
          type: `${id}_update_integration_failed`,
          loading: false,
          error: true,
          message: error.response.data.error || "Failed to update integration",
          callback: () => {
            this.state.type = "";
            this.updateIntegration(id, params, promotionId);
          }
        };
      });
  };

  removeIntegration = (id, promotionId) => {
    return API.removeIntegration(id, promotionId)
      .then(() => {
        return this.state;
      })
      .catch(err => {
        this.state = {
          type: err.status ? err.status : "500",
          loading: false,
          error: true,
          message: "Could not remove integration",
          callback: () => {
            this.state.type = "";
            return this.removeIntegration(id, promotionId);
          }
        };
      });
  };

  resetOccupation = () => {
    this.occupationSearch = {};
    this.occupationAutocompleteList = [];
  };

  resetIntegrationDetailErrors = () => {
    this.integrationDetailErrors = {};
  };

  updateList = ({ page, per_page: perPage, filters }, history, tab) => {
    const newPerPage = perPage || this.appliedFilters.per_page;
    this.state = {
      type: "",
      loading: true,
      error: false,
      message: ""
    };

    if (this.selectedCampaign.id) {
      this.candidates = [];
      this.setAppliedFilters({
        filters: {
          ...this.appliedFilters.filters,
          ...filters
        },
        per_page: newPerPage,
        page: page || (this.appliedFilters?.page ? this.appliedFilters?.page : 1)
      });

      if (tab && this.selectedCampaign.tab !== tab) {
        this.selectedCampaign = { ...this.selectedCampaign, tab };
      }

      switch (this.selectedCampaign.tab) {
        default:
        case "applicants":
          return this.refreshFlows(this.getCandidates(this.selectedCampaign.id, history));
        case "headhunt":
          return this.refreshFlows(this.getHeadhunt(this.selectedCampaign.id, history));
      }
    }
  };

  getCandidates = flow(function* (id, history, candidateId, isFirstLoading) {
    const query = yield stringify({
      ...this.appliedFilters,
      page: this.keepPageInfo.page || this.appliedFilters.page,
      filters: {
        ...this.appliedFilters.filters,
        scope: this.keepPageInfo.scope || this.appliedFilters.filters.scope
      }
    });

    this.keepPageInfo = {
      page: "",
      scope: ""
    };

    if (history) history.push(`/jobs/${id}/applicants?${query}`);
    this.state.loading = true;

    try {
      const { meta: { total_count: totalCount, scopes, filters, sorting, ids } = {}, candidates } =
        yield API.getCandidates({
          id,
          query
        }).then(res => res.data);

      if (ids) {
        this.navigationIds = ids;
      }
      this.total = totalCount;

      const sortedCandidates = candidateId
        ? candidates.sort((a, b) => (String(b.id) === candidateId) - (String(a.id) === candidateId))
        : candidates;

      this.candidates = sortedCandidates;

      this.setFilters({
        scopes,
        filters,
        sorting
      });

      const currentSort = sorting.current_sort;

      if (currentSort && isFirstLoading) {
        yield this.setAppliedFilters({
          filters: {
            ...this.appliedFilters.filters,
            sort_by: currentSort
          }
        });
      }

      if (this.appliedFilters.filters.scope && this.appliedFilters.filters.scope.length === 0) {
        yield this.setAppliedFilters({
          filters: {
            ...this.appliedFilters.filters,
            scope: scopes[0].value
          }
        });
      }
    } catch (err) {
      console.error(`Candidates data layer: ${err}`);
      this.state = {
        type: err.status ? err.status : "500",
        loading: false,
        error: true,
        message: "Could not retrieve candidates",
        callback: () => {
          this.state.type = "";
          return this.getCandidates(id, history);
        }
      };
    }

    if (this.pageCount > 0 && this.appliedFilters && +this.appliedFilters.page > this.pageCount) {
      this.updateList({ page: this.pageCount }, history);
    }
    if (this.appliedFilters && +this.appliedFilters.page <= 0) {
      this.updateList({ page: 1 }, history);
    }

    this.state.loading = false;
  }).bind(this);

  getHeadhunt = flow(function* (id, history) {
    const query = yield stringify(this.appliedFilters);
    if (history) history.push(`/jobs/${id}/headhunt?${query}`);
    this.state.loading = true;

    try {
      const { meta: { total_count, filters, scopes, sorting, ids } = {}, candidates } = yield API.getHeadhuntTalents({
        id,
        query
      }).then(res => res.data);

      if (ids?.length) {
        this.navigationIds = ids;
      }

      this.total = total_count;
      this.candidates = candidates;
      this.setFilters({
        filters,
        sorting
      });

      if (this.appliedFilters.filters.scope && this.appliedFilters.filters.scope.length === 0) {
        yield this.setAppliedFilters({
          filters: {
            ...this.appliedFilters.filters,
            scope: scopes[0].value
          }
        });
      }
    } catch (err) {
      console.error(`Candidates data layer: ${err}`);
      this.state = {
        type: err.status ? err.status : "500",
        loading: false,
        error: true,
        message: "Could not retrieve headhunt talents",
        callback: () => {
          this.state.type = "";
          return this.getHeadhunt(id, history);
        }
      };
    }

    if (this.pageCount > 0 && +this.appliedFilters.page > this.pageCount)
      this.updateList({ page: this.pageCount }, history);
    if (+this.appliedFilters?.page <= 0) this.updateList({ page: 1 }, history);

    this.state.loading = false;
  }).bind(this);

  getCandidate = async (candidateId, id, fetchComments, isFirstLoading, currentPhase) => {
    const replacedCandidateId = candidateId?.includes("?filters[scope]")
      ? candidateId.replace(`?filters[scope]=${currentPhase || this.appliedFilters?.filters?.scope}`, "")
      : candidateId;
    await this.updateCandidate(replacedCandidateId, fetchComments, isFirstLoading, currentPhase);

    if (!this.selectedCampaign.id) {
      await this.getCandidates(id, false, candidateId, isFirstLoading);
      this.getCampaignDetails(id);
    }
  };

  getTalent = async (candidateId, id) => {
    if (!this.selectedCampaign.id) {
      await this.getHeadhunt(id);
      this.getCampaignDetails(id);
    }

    this.updateTalent(candidateId);
  };

  updateCandidate = flow(function* (id, fetchComments, isFirstLoading, currentPhase) {
    this.state.loading = true;

    try {
      const candidate = yield API.getCandidateDetails(id).then(res => res.data.candidate_details);

      if (isFirstLoading) {
        this.setAppliedFilters({
          filters: {
            ...this.appliedFilters.filters,
            scope: currentPhase || candidate.current_phase
          }
        });
      }
      yield (this.currentCandidate = {
        ...candidate,
        ...candidate.user,
        ...candidate.user_details,
        tags: candidate.tags,
        candidateIdStore: candidate.id,
        user: null,
        user_details: null
      });

      fetchComments && this.getCandidateComments(id);
    } catch (err) {
      console.error(`Candidates data layer: ${err}`);
      this.state = {
        type: `${id}_details_failure`,
        error: true,
        message: "Failed to get candidate details",
        callback: () => {
          this.state.type = "";
          return this.updateCandidate(id, fetchComments, isFirstLoading, currentPhase);
        }
      };
    }

    this.state.loading = false;
  });

  updateTalent = flow(function* (id) {
    this.state.loading = true;

    try {
      const candidate = yield API.getTalent(id).then(res => res.data.headhunting_candidate_details);

      this.currentCandidate = {
        ...candidate,
        ...candidate.user,
        ...candidate.user_details,
        user: null,
        user_details: null
      };
      this.getTalentPersonality(id);
      this.state.loading = false;
    } catch (err) {
      console.error(`Candidates data layer: ${err}`);
      this.state = {
        type: `${id}_details_failure`,
        error: true,
        message: err?.response?.data?.error || "Failed to get talent details",
        callback: () => {
          this.state.type = "";
          return this.updateTalent(id);
        }
      };
    }
  });

  getCandidateFilters = (id, tab) => {
    const query = stringify(this.appliedFilters);

    if (tab === TABS.APPLICANTS) {
      return API.getCandidateFilters(id, query)
        .then(res => {
          this.setFilters(res.data.filters);
        })
        .catch(err => {
          this.state = {
            type: `${id}_filters_error`,
            loading: false,
            error: true,
            message: err.response.data.error || "Could not retrieve candidate filters",
            callback: () => {
              this.state.type = "";
              return this.getCandidateFilters(id, tab);
            }
          };
        });
    }
    if (tab === TABS.HEADHUNT) {
      return API.getHeadhuntFilters(id, query)
        .then(res => {
          this.setFilters(res.data.filters);
        })
        .catch(err => {
          this.state = {
            type: `${id}_filters_error`,
            loading: false,
            error: true,
            message: err.response.data.error || "Could not retireve headhunt filters",
            callback: () => {
              this.state.type = "";
              return this.getCandidateFilters(id, tab);
            }
          };
        });
    }
  };

  getCandidatePersonality = id => {
    return API.getCandidatePersonality(id)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          personality: res[0].data.test_result,
          talent: res[1].data
        };
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `personality_${id}_failure`,
          error: true,
          message: err.response.data.error || "Failed to retrieve candidate personality data",
          callback: () => {
            this.state.type = "";
            return this.getCandidatePersonality(id);
          }
        };

        return this.state;
      });
  };

  getTalentPersonality = id => {
    return API.getTalentPersonality(id)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          personality: res.data
        };
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `talent_personality_${id}_failure`,
          error: true,
          message: err.response.data.error || "Failed to retrieve talent's personality data",
          callback: () => {
            this.state.type = "";
            return this.getTalentPersonality(id);
          }
        };

        return this.state;
      });
  };

  voteForCandidate = ({ type, candidateId }) => {
    API.voteForCandidate(type, this.selectedCampaign.id, candidateId);
  };

  moveCandidates = (ids, phase, name, isGetCandidates) => {
    this.state.type = "";

    this.state = {
      loading: false,
      type: "moving_candidates_loading",
      error: false,
      message: "Moving candidates..."
    };

    return API.moveCandidates(this.selectedCampaign.id, phase, ids)
      .then(res => {
        const successMessage = res.data.success;
        const replacedSuccessName = name ? `${successMessage.replace("Candidates", name)}` : successMessage;

        this.state = {
          loading: false,
          type: `${[ids].join("_")}${phase}_success`,
          error: false,
          message: replacedSuccessName,
          callback: () => phase
        };

        if (isGetCandidates) {
          this.getCandidates(this.selectedCampaign.id);
        }

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[ids].join("_")}${phase}_failure`,
          error: true,
          message: err.response.data.error || "Failed to move candidates",
          callback: () => {
            this.state.type = "";
            return this.moveCandidates(ids, phase);
          }
        };

        return this.state;
      });
  };

  shortlistCandidates = ids => {
    return API.shortlistCandidates(this.selectedCampaign.id, ids)
      .then(res => {
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_shortlisted_success`,
          error: false,
          message: res.data.success,
          callback: () => "shortlisted"
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_shortlisted_failure`,
          error: true,
          message: err.response.data.error || "Failed to move shortlistCandidates",
          callback: () => {
            this.state.type = "";
            return this.shortlistCandidates(ids);
          }
        };

        return this.state;
      });
  };

  rejectCandidates = ids => {
    return API.rejectCandidates(this.selectedCampaign.id, ids)
      .then(res => {
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_rejected_success`,
          error: false,
          message: res.data.success,
          callback: () => "rejected"
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_rejected_failure`,
          error: true,
          message: err.response.data.error || "Failed to move rejectCandidates",
          callback: () => {
            this.state.type = "";
            return this.rejectCandidates(ids);
          }
        };

        return this.state;
      });
  };

  rateCandidate = (id, type = "like") =>
    API.rateCandidate(id, type).catch(err => {
      console.error(`Data layer ${err}`);
      this.state = {
        loading: false,
        type: `${id}_${type}_failure`,
        error: true,
        callback: () => {
          this.state.type = "";
          return this.rateCandidate(id, type);
        }
      };

      return this.state;
    });

  candidateTag = (id, tag, type) =>
    API.candidateTag(this.selectedCampaign.id, id, tag, type)
      .then(res => res.data)
      .catch(err => {
        console.error(`Data layer ${err}`);

        this.state = {
          loading: false,
          type: `${id}_${type}_tag_failure`,
          error: true,
          message: err.response.data.error || "Failed to move candidateTag",
          callback: () => {
            this.state.type = "";
            return this.candidateTag(id, tag, type);
          }
        };

        return this.state;
      });

  createPdfs = (candidateIdList, isSelf) => {
    API.createPdfs(this.selectedCampaign.id, candidateIdList, isSelf)
      .then(() => {
        this.state = {
          loading: false,
          type: `${[candidateIdList].join("_")}pdf_success`,
          error: false,
          message: "You will shortly receive an email with a download link."
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[candidateIdList].join("_")}pdf_failure`,
          error: true,
          message: err.response.data.error || "Download error",
          callback: () => {
            this.state.type = "";
            return this.createPdfs(candidateIdList);
          }
        };
        return this.state;
      });
  };

  exportCsv = candidateIdList => {
    API.exportCsv(this.selectedCampaign.id, candidateIdList)
      .then(() => {
        this.state = {
          loading: false,
          type: `${[candidateIdList].join("_")}csv_success`,
          error: false,
          message: "Downloaded successfully"
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[candidateIdList].join("_")}csv_failure`,
          error: true,
          message: err.response.data.error || "Download error",
          callback: () => {
            this.state.type = "";
            return this.exportCsv(candidateIdList);
          }
        };
        return this.state;
      });
  };

  getCandidateComments = (id, isOtherCampaigns) => {
    const queryParams = stringify({
      employer_id: this.selectedCampaign.employer_id,
      filters: { other_campaigns: isOtherCampaigns }
    });

    return API.getCandidateComments(id, queryParams)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          comments: res.data.comments,
          commentsMeta: res.data.meta
        };

        const comments = isOtherCampaigns ? res.data.comments.sort((a, b) => b.job.id - a.job.id) : res.data.comments;

        return { list: comments, meta: res.data.meta };
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_comments_failure`,
          error: true,
          message: err.response.data.error || "Failed to retrieve comments",
          callback: () => {
            this.state.type = "";
            return this.getCandidateComments(id, isOtherCampaigns);
          }
        };
      });
  };

  clearAllComments = () => {
    this.currentCandidate = {
      ...this.currentCandidate,
      comments: [],
      commentsMeta: {}
    };
  };

  moveTalents = (ids, phase) =>
    API.moveTalents(this.selectedCampaign.id, phase, ids)
      .then(res => {
        this.state = {
          loading: false,
          type: `${[ids].join("_")}${phase}_success`,
          error: false,
          message: res.data.success,
          callback: () => phase
        };
        return res.data.candidates;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[ids].join("_")}${phase}_failure`,
          error: true,
          message: err.response.data.error || "Failed to move candidates",
          callback: () => {
            this.state.type = "";
            return this.moveTalents(ids, phase);
          }
        };
        return this.state;
      });

  shortlistTalents = ids =>
    API.shortlistTalents(this.selectedCampaign.id, ids)
      .then(res => {
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_shortlisted_success`,
          error: false,
          message: res.data.success,
          callback: () => "shortlisted"
        };
        return res.data.candidates;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_shortlisted_failure`,
          error: true,
          message: err.response.data.error || "Failed to move shortlistTalents",
          callback: () => {
            this.state.type = "";
            return this.shortlistTalents(ids);
          }
        };

        return this.state;
      });

  rejectTalents = ids =>
    API.rejectTalents(this.selectedCampaign.id, ids)
      .then(res => {
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_rejected_success`,
          error: false,
          message: res.data.success,
          callback: () => "rejected"
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[ids].join("_")}_rejected_failure`,
          error: true,
          message: err.response.data.error || "Failed to move rejectTalents",
          callback: () => {
            this.state.type = "";
            return this.rejectTalents(ids);
          }
        };
        return this.state;
      });

  removeTalent = id =>
    API.removeTalent(id)
      .then(() => {
        this.state = {
          loading: false,
          type: `${id}_remove_talent_success`,
          error: false,
          message: "Succesfully removed"
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${id}_remove_talent_failure`,
          error: true,
          message: err.response.data.error || "Failed to move rejectTalents",
          callback: () => {
            this.state.type = "";
            return this.removeTalent(id);
          }
        };

        return this.state;
      });

  createComment = (id, body, visibility) => {
    return API.createComment(id, body, visibility)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          comments: [...this.currentCandidate.comments, res.data.comment],
          commentsMeta: {
            ...this.currentCandidate.commentsMeta,
            current_campaign_comments_count: this.currentCandidate.commentsMeta.current_campaign_comments_count + 1
          }
        };

        return res.data.comment;
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_comment_failure`,
          error: true,
          message: err.response.data.error || "Failed to add comment",
          callback: () => {
            this.state.type = "";
            return this.createComment(id, body, visibility);
          }
        };
      });
  };

  editComment = (id, body, visibility, commentId) => {
    return API.editComment(id, body, visibility, commentId)
      .then(res => res.data.comment)
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_comment_failure`,
          error: true,
          message: err.response.data.error || "Failed to edit comment",
          callback: () => {
            this.state.type = "";
            return this.editComment(id, body, visibility, commentId);
          }
        };
      });
  };

  deleteComment = (candidateId, commentId) =>
    API.deleteComment(candidateId, commentId)
      .then(() => {
        this.currentCandidate = {
          ...this.currentCandidate,
          commentsMeta: {
            ...this.currentCandidate.commentsMeta,
            current_campaign_comments_count: this.currentCandidate.commentsMeta.current_campaign_comments_count - 1
          }
        };
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${candidateId}_comments_${commentId}_deletion_failure`,
          error: true,
          message: err.response.data.error || "Failed to delete comment",
          callback: () => {
            this.state.type = "";
            return this.deleteComment(candidateId, commentId);
          }
        };
      });

  getJobDetails = (id, options = {}) => {
    this.state.loading = true;

    return fetch(getCampaignDetailsTabContentsPath(id), options)
      .then(res => res.text())
      .then(html => {
        this.state.loading = false;
        return parse(html);
      })
      .catch(err => {
        console.error(err);
        this.state = {
          loading: false,
          type: `${id}_details_failure`,
          error: true,
          message: err?.response?.data?.error || "Job details page failure",
          callback: () => {
            this.state.type = "";
            return this.getJobDetails(id, options);
          }
        };
      });
  };

  getJobUpdates = (id, options = {}) => {
    this.state.loading = true;

    return fetch(getCampaignUpdatesTabContentsPath(id), options)
      .then(res => res.text())
      .then(html => {
        this.state.loading = false;
        return parse(html);
      })
      .catch(err => {
        console.error(err);
        this.state = {
          loading: false,
          type: `${id}_update_failure`,
          error: true,
          message: err?.response?.data?.error || "Job updates page failure",
          callback: () => {
            this.state.type = "";
            return this.getJobUpdates(id, options);
          }
        };
      });
  };

  getCollaborators = id => {
    return API.getCampaignCollaborators(id)
      .then(res => {
        this.collaborators = res.data.collaborators;
        this.collaboratorContacts = res.data.contacts;
      })
      .catch(err => {
        console.error(err);
        this.state = {
          loading: false,
          type: `${id}_collaborators_fetched_failure`,
          error: true,
          message: err.response.data.error || "Failed to get collaborators",
          callback: () => {
            this.state.type = "";
            return this.getCollaborators(id);
          }
        };
      });
  };

  changeCollaborationVisibility = (id, visibility) =>
    API.changeCollaborationVisibility(id, visibility)
      .then(res => {
        this.state = {
          loading: false,
          type: `${id}_${visibility}_coll_visibility_success`,
          error: false,
          message: res.data.success
        };

        this.updateSelectedCampaign({ collaboration_visibility: visibility });
      })
      .then(() => this.getCollaborators(id))
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_${visibility}_coll_visibility_failure`,
          error: true,
          message: err.response.data.error,
          callback: () => {
            this.state.type = "";
            return this.changeCollaborationVisibility(id, visibility);
          }
        };

        return this.state;
      });

  inviteCollaborator = (id, email, message) =>
    API.inviteCollaborator(id, email, message)
      .then(res => {
        this.state = {
          loading: false,
          type: `${id}_${email}_coll_invite_success`,
          error: false,
          message: res.data.success
        };

        this.collaborators = [...this.collaborators, res.data.collaborator];
        return res.data.collaborator;
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_${email}_coll_invite_failure`,
          error: true,
          message: err.response.data.error || "Failed to invite collaborator",
          callback: () => {
            this.state.type = "";
            console.error(err.data);
            return this.inviteCollaborator(id, email);
          }
        };

        return this.state;
      });

  removeCollaborator = (campaignId, id) =>
    API.removeCollaborator(campaignId, id)
      .then(res => {
        this.state = {
          loading: false,
          type: `${campaignId}_${id}_coll_remove_success`,
          error: false,
          message: res.data.success
        };

        this.collaborators = this.collaborators.filter(collaborator => collaborator.id !== id);
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${campaignId}_${id}_coll_remove_failure`,
          error: true,
          message: err.response.data.error || "Failed to remove collaborator",
          callback: () => {
            this.state.type = "";
            return this.removeCollaborator(campaignId, id);
          }
        };

        return this.state;
      });

  // User unlocking
  unlockUser = (userId, candidateId, isReloadPage, employerId) => {
    return unlockUser(userId, candidateId, employerId)
      .then(res => {
        this.currentCandidate = { ...this.currentCandidate, ...res.data.candidate_details };
        this.state = {
          loading: false,
          type: `${userId}_user_unlocked_success`,
          error: false,
          message: "User unlocked"
        };

        if (isReloadPage) {
          window.location.reload();
        }

        return res.data.meta;
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${userId}_user_unlocked_failure`,
          error: true,
          message: err.response.data.error || "Failed to unlock user",
          callback: () => {
            this.state.type = "";
            return this.unlockUser(userId, candidateId, isReloadPage, employerId);
          }
        };

        return this.state;
      });
  };

  shortlistWithAssignmentsTalents = body => {
    return API.shortlistWithAssignmentsTalents(this.selectedCampaign.id, body)
      .then(res => {
        this.state = {
          loading: false,
          type: `${[body.candidate_ids].join("_")}_shortlisted_success`,
          error: false,
          message: res.data.success,
          callback: () => "shortlisted"
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[body.candidate_ids].join("_")}_shortlisted_failure`,
          error: true,
          message: err.response.data.error || "Failed to move shortlistCandidates",
          callback: () => {
            this.state.type = "";
            return this.shortlistCandidates(this.selectedCampaign.id);
          }
        };

        return this.state;
      });
  };

  shortlistWithAssignments = body => {
    return API.shortlistWithAssignments(this.selectedCampaign.id, body)
      .then(res => {
        this.state = {
          loading: false,
          type: `${[body.candidate_ids].join("_")}_shortlisted_success`,
          error: false,
          message: res.data.success,
          callback: () => "shortlisted"
        };

        return this.state;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${[body.candidate_ids].join("_")}_shortlisted_failure`,
          error: true,
          message: err.response.data.error || "Failed to move shortlistCandidates",
          callback: () => {
            this.state.type = "";
            return this.shortlistCandidates(this.selectedCampaign.id);
          }
        };

        return this.state;
      });
  };

  handleSelectedAvailableJobForCandidate = availableJob => {
    this.selectedAvailableJobForCandidate = availableJob;
  };

  getAvailableJobForCandidate = candidateIds => {
    return API.getAvailableJobForCandidate(stringify({ candidates: candidateIds }))
      .then(res => {
        this.availableJobsForCandidate = res.data.campaigns;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          message: "Failed to remove get available job for candidate",
          callback: () => this.getAvailableJobForCandidate(stringify({ candidates: candidateIds }))
        };
      });
  };

  getActiveCampaigns = (employerId, employer, candidateIds) => {
    return API.getActiveCampaigns(employerId, stringify({ candidates: candidateIds })).then(res => {
      this.campaigns = res.data.campaigns;
      this.availableJobsForCandidate = res.data.campaigns;
      this.selectedEmployer = employer || this.selectedEmployer;
      this.selectedAvailableJobForCandidate = {};
    });
  };

  resetSelectedEmployer = () => {
    this.selectedEmployer = {};
  };

  getActiveCompanies = () => {
    return API.getActiveCompanies().then(res => {
      this.companies = res.data.companies;
    });
  };

  inviteCandidate = (campaignId, query) => {
    return API.inviteCandidate(campaignId, query)
      .then(response => {
        if (response.data?.invitations?.length) {
          this.handleSelectedAvailableJobForCandidate({});
          this.state = {
            loading: false,
            error: false,
            type: `${campaignId}_talent_invite_success`,
            message: "Successfully invited candidate"
          };
        } else {
          this.state = {
            loading: false,
            error: true,
            message: "Failed to invite candidate",
            callback: () => this.inviteCandidate(campaignId, query)
          };
        }
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          message: "Failed to invite candidate",
          callback: () => this.inviteCandidate(campaignId, query)
        };
      });
  };

  getFolders = () => {
    this.isLoadingFolders = true;
    API.getFolderList(this.selectedCampaign.employer_id)
      .then(res => {
        this.folders = res.data.folders;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "hh_folders_failure",
          message: "Failed to retrieve saved folders",
          callback: () => this.getFolders(this.selectedCampaign.employer_id)
        };
      });
  };

  createFolder = name => {
    return API.createFolder(name, this.selectedCampaign.employer_id)
      .then(res => res.data.folder)
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${name}_create_failure`,
          message: err.message
        };
      });
  };

  addToFolder = (folder, candidateIds, stageId) => {
    return API.addToFolder(folder, candidateIds, stageId)
      .then(() => {
        this.state = {
          loading: false,
          error: false,
          type: `${folder}_${candidateIds}_success`,
          message: "Successfully added to folder",
          callback: () => {
            this.state.type = "";
          }
        };
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${folder}_${candidateIds}_failure`,
          message: err.message,
          callback: () => this.addToFolder(folder, candidateIds, stageId)
        };
      });
  };

  addFolderIdToCandidate = (candidateId, folderId) => {
    this.candidates = this.candidates.map(item => {
      if (item.id === candidateId && !item.headhunting_folder_ids.includes(folderId)) {
        return { ...item, headhunting_folder_ids: [...item.headhunting_folder_ids, folderId] };
      }
      return item;
    });
  };

  markAsViewedFastApply = () => API.markAsViewedFastApply();

  setGuestInitialState = initialState => {
    if (window.location.href.includes("share-candidates")) {
      this.updateSelectedCampaign({ active_guest_link: initialState.guest_link, ...initialState.job_details });
      this.jobDetailShare = initialState.job_details;
      this.getGuestCandidates(initialState.guest_link.token);
    }
  };

  getGuestCandidates = (token, query) => {
    this.state = {
      loading: true,
      type: `${token}get_candidates_loading`,
      error: false,
      message: "Just a moment we are loading candidate"
    };
    const formatQuery = {
      filters: {
        term: query?.term || this.appliedFilters.term
      },
      page: query?.page || this.appliedFilters.page,
      per_page: query?.per_page || this.appliedFilters.per_page
    };

    API.getGuestCandidates(token, stringify(formatQuery))
      .then(res => {
        this.state = {
          loading: false,
          type: `${token}get_candidates`,
          error: false,
          message: "Get candidates successfully"
        };
        this.candidates = res.data.candidates;
        this.guestLinkMeta = res.data.meta;
      })
      .catch(err => {
        console.error(`Data layer ${err}`);
        this.state = {
          loading: false,
          type: `${token}get_failure`,
          error: true,
          message: err.response.data.error || "Download error",
          callback: () => {
            this.state.type = "";
            return this.getGuestCandidates(token);
          }
        };
      });
  };

  createGuestLink = ({
    campaignId,
    phase,
    guestAccessibleSections,
    showModal,
    expiresAt,
    accessType = "public",
    contactIds,
    guestPitch
  }) => {
    this.state = {
      loading: true,
      error: false,
      type: `${campaignId}_get-guest-link_loading`,
      message: "wait please",
      callback: () => {
        this.state.type = "";
      }
    };

    const params = {
      expires_at: expiresAt,
      access_type: accessType,
      guest_pitch: guestPitch,
      phase,
      ...(contactIds && contactIds.length > 0 && { contact_ids: contactIds }),
      guest_accessible_sections: guestAccessibleSections
    };

    return API.createGuestLink(campaignId, params)
      .then(res => {
        this.state = {
          loading: false,
          error: false,
          type: `${campaignId}_get-guest-link_success`,
          message: res.data.success
        };

        this.updateSelectedCampaign({ active_guest_link: res.data.guest_link });

        if (showModal) {
          showModal();
        }

        return res;
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${campaignId}_get-guest-link_fail`,
          message: err.message,
          callback: () =>
            this.createGuestLink({
              campaignId,
              phase,
              guestAccessibleSections,
              showModal,
              expiresAt,
              accessType,
              contactIds
            })
        };
      });
  };

  changeGuestLink = ({
    campaignId,
    tokenId,
    guestAccessibleSections,
    showModal,
    expiresAt,
    accessType,
    contactIds,
    guestPitch
  }) => {
    this.state = {
      loading: true,
      error: false,
      type: `${campaignId}_get-guest-link_loading`,
      message: "wait please",
      callback: () => {
        this.state.type = "";
      }
    };

    const params = {
      expires_at: expiresAt,
      access_type: accessType,
      guest_pitch: guestPitch,
      ...(contactIds && contactIds.length > 0 && { contact_ids: contactIds }),
      guest_accessible_sections: guestAccessibleSections
    };

    return API.changeGuestLink(campaignId, tokenId, params)
      .then(res => {
        this.state = {
          loading: false,
          error: false,
          type: `${campaignId}_get-guest-link_success`,
          message: res.data.success
        };

        this.updateSelectedCampaign({ active_guest_link: res.data.guest_link });

        if (showModal) {
          showModal();
        }

        return res;
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${campaignId}_get-guest-link_fail`,
          message: err.message,
          callback: () =>
            this.changeGuestLink({
              campaignId,
              tokenId,
              guestAccessibleSections,
              showModal,
              expiresAt,
              accessType,
              contactIds
            })
        };
      });
  };

  deleteGuestLink = (campaignId, tokenId) => {
    this.state = {
      loading: true,
      error: false,
      type: `${campaignId}_delete-guest-link_loading`,
      message: "Just a moment we are deleting link"
    };
    return API.deleteGuestLink(campaignId, tokenId)
      .then(res => {
        this.state = {
          loading: false,
          error: false,
          type: `${campaignId}_delete-guest-link_success`,
          message: res.data.success
        };

        this.updateSelectedCampaign({ active_guest_link: null });
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${campaignId}_delete-guest-link_fail`,
          message: err.message,
          callback: () => this.deleteGuestLink(campaignId, tokenId)
        };
      });
  };

  resendGuestLink = (campaignId, tokenId, contactId) => {
    return API.resendGuestLink(campaignId, tokenId, { contact_id: contactId })
      .then(res => {
        this.state = State.setSuccess(this.state, res, "You have successfully resent guest link");
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${campaignId}_resend-guest-link_fail`,
          message: err.message,
          callback: () => this.resendGuestLink(campaignId, tokenId, contactId)
        };
      });
  };

  getGuestLinkContacts = campaignId => {
    return API.getGuestLinkContacts(campaignId)
      .then(res => {
        this.guestLinkContacts = res.data.contacts;
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${campaignId}_get-guest-link-contacts_fail`,
          message: err.message,
          callback: () => this.getGuestLinkContacts(campaignId)
        };
      });
  };

  getDetailShareCandidate = (token, id) => {
    this.state = {
      type: `${id}_get_detail_share_candidate_loading`,
      loading: true,
      error: false,
      message: "Just a moment we are loading candidate"
    };

    return API.getDetailShareCandidate(token, id)
      .then(res => {
        const { user, user_details, ...rest } = res.data.candidate_details;

        this.currentCandidate = {
          ...user,
          ...user_details,
          ...rest
        };

        this.getDetailShareCandidateComments(token, id);
        this.getDetailShareCandidatePersonality(token, id);
        this.getDetailShareCandidateTalent(token, id);

        this.state = {
          type: `${id}_get_detail_share_candidate_success`,
          loading: false,
          error: false,
          message: "",
          callback: () => {
            this.state.type = "";
          }
        };
      })
      .catch(err => {
        this.state = {
          type: `${id}_get_detail_share_candidate_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to delete the job",
          callback: () => {
            this.state.type = "";
            this.getDetailShareCandidate(token, id);
          }
        };
      });
  };

  getDetailShareCandidatePersonality = (token, id) => {
    return API.getDetailShareCandidatePersonality(token, id)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          personality: res.data.test_result
        };
      })
      .catch(err => {
        this.state = {
          type: `${id}_get_detail_share_candidate_personality_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to get candidate personality",
          callback: () => {
            this.state.type = "";
            this.getDetailShareCandidatePersonality(token, id);
          }
        };
      });
  };

  getDetailShareCandidateComments = (token, id) => {
    this.currentCandidate = {
      ...this.currentCandidate,
      comments: [],
      commentsMeta: {}
    };

    return API.getDetailShareCandidateComments(token, id)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          comments: res.data.comments,
          commentsMeta: res.data.meta
        };
        return { list: res.data.comments };
      })
      .catch(err => {
        this.state = {
          type: `${id}_get_detail_share_candidate_talent_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to get comments",
          callback: () => {
            this.state.type = "";
            this.getDetailShareCandidateComments(token, id);
          }
        };
      });
  };

  getDetailShareCandidateTalent = (token, id, employer) => {
    return API.getDetailShareCandidateTalent(token, id, employer)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          talent: res.data
        };

        this.state = {
          type: `${id}_get_detail_share_candidate_talent_failed`,
          loading: false,
          error: false
        };
      })
      .catch(err => {
        this.state = {
          type: `${id}_get_detail_share_candidate_talent_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to get candidate talent",
          callback: () => {
            this.state.type = "";
            this.getDetailShareCandidateTalent(token, id, employer);
          }
        };
      });
  };

  getCandidateApplicationHistory = (id, page = 1, isShowOnlyNew, employer) => {
    const query = employer ? stringify({ filters: { employer } }) : "";
    const numbers = id.match(/\d+/);
    const replacedCandidateId = numbers ? numbers[0] : null;

    return API.getCandidateApplicationHistory(replacedCandidateId, page, query)
      .then(res => {
        const newCandidates = this.applicationHistory.length
          ? [...this.applicationHistory, ...res.data.candidates]
          : res.data.candidates;

        this.applicationHistory = isShowOnlyNew ? res.data.candidates : newCandidates;
        this.applicationHistoryMeta = res.data.meta;

        this.state = {
          type: `${id}_get_application_history_candidates_success`,
          loading: false,
          error: false
        };
      })
      .catch(err => {
        this.state = {
          type: `${id}_get_application_history_candidates_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to get candidate talent",
          callback: () => {
            this.state.type = "";
            this.getCandidateApplicationHistory(replacedCandidateId, page, isShowOnlyNew);
          }
        };
      });
  };

  createCommentShareCandidate = (token, id, body) => {
    return API.createCommentShareCandidate(token, id, body)
      .then(res => {
        this.currentCandidate = {
          ...this.currentCandidate,
          comments: [...this.currentCandidate.comments, res.data.comment]
        };

        return res.data.comment;
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_share_comment_failure`,
          error: true,
          message: err.response.data.error || "Failed to add comment",
          callback: () => {
            this.state.type = "";
            return this.createCommentShareCandidate(token, id, body);
          }
        };
      });
  };

  createShareCandidateUserForComment = (token, body) => {
    return API.createShareCandidateUserForComment(token, body).catch(err => {
      this.state = {
        loading: false,
        type: `${token}_share_add_guest`,
        error: true,
        message: err.response.data.error || "Failed to add comment",
        callback: () => {
          this.state.type = "";
          return this.createShareCandidateUserForComment(token, body);
        }
      };
    });
  };

  deleteCommentShareCandidate = (token, id, commentId) =>
    API.deleteCommentShareCandidate(token, id, commentId).catch(err => {
      this.state = {
        loading: false,
        type: `${id}_share_comments_${commentId}_deletion_failure`,
        error: true,
        message: err.response.data.error || "Failed to delete comment",
        callback: () => {
          this.state.type = "";
          return this.deleteCommentShareCandidate(token, id, commentId);
        }
      };
    });

  editCommentShareCandidate = (token, id, commentId, body) => {
    return API.editCommentShareCandidate(token, id, commentId, body)
      .then(res => res.data.comment)
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_share_comment_failure`,
          error: true,
          message: err.response.data.error || "Failed to edit comment",
          callback: () => {
            this.state.type = "";
            return this.editCommentShareCandidate(token, id, commentId, body);
          }
        };
      });
  };

  getShareCandidatesInterviewNotes = (token, id, page = 1, isShowOnlyNew, filters) => {
    const queryFilters = filters ? stringify({ filters }) : "";
    this.interviewNotes = isShowOnlyNew ? [] : this.interviewNotes;

    return API.getShareCandidatesInterviewNotes(token, id, page, queryFilters)
      .then(res => {
        const newInterviews = this.interviewNotes.length
          ? [...this.interviewNotes, ...res.data.interview_notes]
          : res.data.interview_notes;

        this.interviewNotes = isShowOnlyNew ? res.data.interview_notes : newInterviews;
        this.interviewNotesMeta = res.data.meta;

        this.state = {
          type: `${id}_get_interview_notes_success`,
          loading: false,
          error: false
        };
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${id}_share_comment_failure`,
          error: true,
          message: err.response.data.error || "Failed to get interview notes",
          callback: () => {
            this.state.type = "";
            return this.getShareCandidatesInterviewNotes(token, id);
          }
        };
      });
  };

  externalCandidateCheckEmail = (employerId, email) => {
    this.isLoadingExternalCandidateCheckEmail = true;
    this.externalCandidateFoundUser = {};
    this.externalCandidateFormErrors = {};

    return API.externalCandidateCheckEmail(employerId, email)
      .then(res => {
        this.isLoadingExternalCandidateCheckEmail = false;
        this.externalCandidateFoundUser = res?.data;

        return res.data;
      })
      .catch(err => {
        this.isLoadingExternalCandidateCheckEmail = false;
        this.externalCandidateFormErrors = { email: err.response.data.error };

        this.state = {
          loading: false,
          type: `${employerId}_check-external_candidate_loading`,
          error: true,
          message: err?.response?.data?.error || "Failed to check email for external candidate",
          callback: () => {
            return this.externalCandidateCheckEmail(employerId, email);
          }
        };
      });
  };

  createExternalCandidate = body => {
    this.externalCandidateFormErrors = {};

    return API.createExternalCandidate(body)
      .then(res => {
        this.candidates = [res.data.candidate, ...this.candidates];

        this.state = State.setSuccess(this.state, res, true);
      })
      .catch(err => {
        if (err.response.data.errors) {
          this.externalCandidateFormErrors = err.response.data.errors;
        }
        this.state = {
          loading: false,
          type: `${body.employer_id}_create_external_candidate`,
          error: true,
          message: err?.response?.data?.error || "Failed to create external candidate",
          callback: () => {
            return this.createExternalCandidate(body);
          }
        };
      });
  };

  createExternalCandidateAndApply = (campaignId, body) => {
    this.externalCandidateFormErrors = {};
    return API.createExternalCandidateAndApply(campaignId, body)
      .then(res => {
        this.candidates = [res.data.candidate, ...this.candidates];
        this.state = State.setSuccess(this.state, res, "");
        return res;
      })
      .catch(err => {
        if (err.response.data.errors) {
          this.externalCandidateFormErrors = err.response.data.errors;
        }

        this.state = {
          loading: false,
          type: `${body.employer_id}_create_external_candidate`,
          error: true,
          message: err?.response?.data?.error || "Failed to create external candidate",
          callback: () => {
            return this.createExternalCandidate(body);
          }
        };
      });
  };

  editExternalCandidateAndApply = (campaignId, body) => {
    this.externalCandidateFormErrors = {};
    return API.editExternalCandidateAndApply(campaignId, body)
      .then(res => {
        this.currentCandidate = { ...this.currentCandidate, ...res.data.candidate };
        // this.candidates = [res.data.candidate, ...this.candidates];
        this.state = State.setSuccess(this.state, res, "");
        return res;
      })
      .catch(err => {
        if (err.response.data.errors) {
          this.externalCandidateFormErrors = err.response.data.errors;
        }

        this.state = {
          loading: false,
          type: `${body.employer_id}_edit_and_apply_external_candidate`,
          error: true,
          message: err?.response?.data?.error || "Failed to edit external candidate and apply",
          callback: () => {
            return this.editExternalCandidateAndApply(campaignId, body);
          }
        };
      });
  };

  applyExternalCandidate = (campaignId, id) => {
    this.externalCandidateFormErrors = {};
    return API.applyExternalCandidate(campaignId, id)
      .then(res => {
        this.candidates = [res.data.candidate, ...this.candidates];
        this.state = State.setSuccess(this.state, res, true);
        return res;
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          this.externalCandidateFormErrors = err.response.data.errors;
        }
      });
  };

  switchExternalCandidate = (id, successMessage) => {
    const numberId = id.match(/\d+/);

    return API.switchExternalCandidate(numberId)
      .then(res => {
        const { user, user_details, ...rest } = res.data.candidate_details;

        this.currentCandidate = {
          ...user,
          ...user_details,
          ...rest,
          comments: this.currentCandidate.comments
        };

        this.state = {
          loading: false,
          error: false,
          type: `${id}_switch_external_candidate_success`,
          message: successMessage,
          isShowSuccessMessage: true
        };

        return res;
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: false,
          type: `${id}_switch_external_candidate_fail`,
          message: err?.response?.data?.error || "Failed to switch external candidate"
        };
      });
  };

  getSwitchInfo = id => {
    const numberId = id.match(/\d+/);

    return API.getSwitchInfo(numberId)
      .then(res => {
        this.setSwitchUserData(res.data.user_details);

        return res;
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: false,
          type: `${numberId}_get_switch_external_candidate_fail`,
          message: err?.response?.data?.error || "Failed to get switch info"
        };
      });
  };

  setSwitchUserData = data => {
    this.switchUserData = data;
  };

  toggleInterviewModal = (isActive, candidateId) => {
    this.isActiveInterviewModal = isActive;
    this.interviewModalCandidateId = candidateId;
  };

  openEditInterviewModal = (noteId, isShowPreviewModalWhenCancel, candidateId) => {
    this.isActiveInterviewModal = true;
    this.isShowPreviewModalWhenCancel = isShowPreviewModalWhenCancel;
    this.interviewModalEditId = noteId;
    this.interviewModalCandidateId = candidateId;
  };

  resetIsShowPreviewModalWhenCancel = () => {
    this.isShowPreviewModalWhenCancel = true;
  };

  resetInterviewModalEditId = () => {
    this.interviewModalEditId = "";
  };

  setDefaultActiveTab = tab => {
    this.defaultActiveTab = tab;
  };

  getInterviewNotes = (id, page = 1, isShowOnlyNew, filters) => {
    const queryFilters = filters ? stringify({ filters }) : "";
    const numbers = id.match(/\d+/);
    const replacedCandidateId = numbers ? numbers[0] : null;
    this.interviewNotes = isShowOnlyNew ? [] : this.interviewNotes;

    this.state = {
      type: `${id}_get_interview_notes_loading`,
      loading: true,
      error: false
    };

    return API.getInterviewNotes(replacedCandidateId, page, queryFilters)
      .then(res => {
        const newInterviews = this.interviewNotes.length
          ? [...this.interviewNotes, ...res.data.interview_notes]
          : res.data.interview_notes;

        this.interviewNotes = isShowOnlyNew ? res.data.interview_notes : newInterviews;
        this.interviewNotesMeta = res.data.meta;

        this.state = {
          type: `${replacedCandidateId}_get_interview_notes_success`,
          loading: false,
          error: false
        };
      })
      .catch(err => {
        this.state = {
          type: `${replacedCandidateId}_get_interview_notes_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to get interview notes",
          callback: () => {
            this.state.type = "";
            this.getInterviewNotes(replacedCandidateId, (page = 1), isShowOnlyNew, filters);
          }
        };
      });
  };

  createInterviewNote = (id, body) => {
    const numbers = id.match(/\d+/);
    const replacedCandidateId = numbers ? numbers[0] : null;

    return API.createInterviewNote(replacedCandidateId, body)
      .then(res => {
        this.state = {
          loading: false,
          type: `${replacedCandidateId}_create_interview_note_success`,
          error: false,
          message: "Successfully created interview note"
        };

        this.interviewNotes = [res.data.interview_note, ...this.interviewNotes];
        this.interviewNotesMeta = {
          ...this.interviewNotesMeta,
          total_count: this.interviewNotesMeta.total_count + 1,
          user_count: this.interviewNotesMeta.user_count + 1
        };
      })
      .catch(err => {
        this.state = {
          type: `${replacedCandidateId}_create_interview_note_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to create interview note",
          callback: () => {
            this.state.type = "";
            this.createInterviewNote(replacedCandidateId, body);
          }
        };
      });
  };

  editInterviewNote = (id, noteId, body) => {
    const numbers = id.match(/\d+/);
    const replacedCandidateId = numbers ? numbers[0] : null;

    return API.editInterviewNote(replacedCandidateId, noteId, body)
      .then(res => {
        this.interviewNotes = this.interviewNotes.map(item => (noteId === item.id ? res.data.interview_note : item));
      })
      .catch(err => {
        this.state = {
          type: `${id}_edit_interview_note_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to edit interview note",
          callback: () => {
            this.state.type = "";
            this.editInterviewNote(replacedCandidateId, noteId, body);
          }
        };
      });
  };

  deleteInterviewNote = (id, noteId) => {
    const numbers = id.match(/\d+/);
    const replacedCandidateId = numbers ? numbers[0] : null;

    return API.deleteInterviewNote(replacedCandidateId, noteId)
      .then(() => {
        this.interviewNotes = this.interviewNotes.filter(item => item.id !== noteId);
        this.interviewNotesMeta = {
          ...this.interviewNotesMeta,
          total_count: this.interviewNotesMeta.total_count - 1,
          user_count: this.interviewNotesMeta.user_count - 1
        };
      })
      .catch(err => {
        this.state = {
          type: `${replacedCandidateId}_delete_interview_note_failed`,
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to delete interview note",
          callback: () => {
            this.state.type = "";
            this.deleteInterviewNote(replacedCandidateId, noteId);
          }
        };
      });
  };

  getCVUrl = url => {
    return axios
      .get(getUrlPath(url))
      .then(res => {
        return res.data;
      })
      .catch(() => {
        this.state = {
          loading: true,
          error: true,
          type: "get_cv_url_error",
          message: "Failed to get CV",
          callback: () => this.getCVUrl(url)
        };
      });
  };

  handleKeepPageInfo = pageInfo => {
    this.keepPageInfo = pageInfo;
  };
}

const campaignsStore = new CampaignsStore();

export default campaignsStore;
