import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-grid-system";
import { InfoCircle } from "@styled-icons/boxicons-regular";

import Button from "components/Button";
import { Textarea, Text, SwitchInput, Spinner } from "../../../../../b2c/components/Core";
import { theme } from "../../../../../b2c/contexts/theme";
import Radio from "../../../../components/Radio";
import { SenderContent, SenderItem, SenderTitle, SenderText, Info, TagContent, Tag, SelectContainer } from "./styled";
import { getCompanyName } from "../../../../../contexts/init";
import SelectSearch from "../../../../components/SelectSearch";

const RejectionMessagesModalContent = ({
  reasons,
  currentUserAndEmployer,
  selectedCandidates: selectedCandidatesIds,
  candidates,
  id: candidateId,
  ids,
  campaign,
  rejectMessage,
  currentEmployer,
  toggle,
  navigationIds
}) => {
  const [sender, setSender] = useState();
  const [loading, setLoading] = useState(0);
  const [selectedSenderIndex, setSelectedSenderIndex] = useState();
  const [selectedReason, setSelectedReason] = useState();
  const [replyMessage, setReplyMessage] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeReject, setActiveReject] = useState("");

  const reasonOptions = useMemo(
    () => reasons.map(item => ({ value: item.id, label: item.reason, message: item.message })),
    [reasons]
  );

  const selectedCandidatesLength = selectedCandidatesIds?.length;
  const navigationIdsLength = navigationIds?.length;

  const isSelectedAllNavigation =
    selectedCandidatesLength && navigationIdsLength && selectedCandidatesLength === navigationIdsLength;
  const selectedCandidatesOther =
    candidates?.length && navigationIdsLength ? navigationIdsLength - candidates?.length : 0;
  const selectedCandidates = candidates.filter(({ id }) => [...selectedCandidatesIds, ...ids].includes(id));

  const externalCandidates = selectedCandidates.filter(item => Boolean(item.prospect_id));
  const isOnlyExternalCandidates = externalCandidates.length === selectedCandidates.length;

  const handleRejectWithoutMessage = externalCandidatesIds => {
    const bodyCandidatesIds = () => {
      if (externalCandidatesIds) {
        return externalCandidatesIds;
      }

      if (candidateId) {
        return [candidateId];
      }

      return selectedCandidatesIds;
    };

    const body = {
      candidate_ids: bodyCandidatesIds()
    };

    setLoading(1);

    rejectMessage(campaign.id, body).then(() => {
      setLoading(0);
      toggle();
    });
  };

  const handleReject = () => {
    if (!selectedReason) {
      handleRejectWithoutMessage();
    } else {
      const externalCandidatesIds = externalCandidates.map(item => item.id);
      const rejectedIds = externalCandidatesIds.length
        ? selectedCandidatesIds.filter(item => !externalCandidatesIds.includes(item))
        : selectedCandidatesIds;

      const body = {
        candidate_ids: candidateId ? [candidateId] : rejectedIds,
        conversation: {
          message: {
            body: formatTextMessage(selectedReason, selectedSenderIndex)
          },
          settings: {
            anonymous: true,
            read_only: !replyMessage
          },
          separate_conversations: true
        }
      };

      setLoading(2);

      rejectMessage(campaign.id, body).then(() => {
        if (externalCandidatesIds.length) {
          handleRejectWithoutMessage(externalCandidatesIds);
        } else {
          toggle();
          setLoading(0);
        }
      });
    }
  };

  const formatTextMessage = (message, index) => {
    if (!message) {
      return message;
    }
    const candidate = selectedCandidates[0];
    const firstName = candidate?.user?.first_name || candidate?.first_name;
    const senderAndCompanyName = `${
      !index ? currentUserAndEmployer[0].name : `${currentUserAndEmployer[1].name}, ${currentUserAndEmployer[0].name}`
    }`;
    return message
      .replace("#company_name", currentUserAndEmployer[0].name)
      .replace("#sender_name", senderAndCompanyName)
      .replace(/#link_to_company_page/g, `https://www.fuzu.com${currentEmployer.path}`)
      .replace("#company_name", senderAndCompanyName)
      .replace(/#job_title/g, campaign.title)
      .replace(/#ic_role_name/g, campaign.title)
      .replace(/#applicant_first_name/g, selectedCandidates.length === 1 ? firstName : "#applicant_first_name")
      .replace(/#candidate_name/g, selectedCandidates.length === 1 ? firstName : "#candidate_name");
  };

  const handleRejectSearch = value => {
    setSearchValue(value);
  };

  const handleSelectReject = value => {
    setActiveReject(value);
    setSelectedReason(value.message);
  };

  useEffect(() => {
    if (currentUserAndEmployer.length && !sender && !selectedSenderIndex) {
      setSender(currentUserAndEmployer[0].id);
      setSelectedSenderIndex(0);
    }
  }, []);

  return (
    <div className="subscription-upgrade">
      <div
        className="fz-text fz-text_normal fz-lh_1-4 fz-text-color__grey800 fz-text_pre-wrap m-0"
        style={{ color: theme.black300 }}
      >
        Providing feedback is an important task! Candidates love getting updates on the application process. Even bad
        news is better than no news at all. Giving candidates clear and constructive feedback will give them a positive
        impression of your organization and make your employer brand stronger.
      </div>
      <div style={{ marginTop: "20px" }}>
        <Text margin="5px 0 0 0" fontWeight={600}>
          Recipients
        </Text>

        <TagContent>
          {selectedCandidates.map(item => {
            return (
              <Tag key={item.id} isNotActive={item.prospect_id}>
                {item?.user?.name || item.name}
              </Tag>
            );
          })}
        </TagContent>

        {isSelectedAllNavigation && Number(selectedCandidatesOther) ? (
          <div
            key={isSelectedAllNavigation}
            style={{
              background: "#EEE",
              color: "#333",
              display: "inline-block",
              padding: "6px 12px",
              borderRadius: "13px",
              margin: "10px 7px"
            }}
          >
            {`+ ${selectedCandidatesOther} more`}
          </div>
        ) : null}
      </div>

      {externalCandidates.length ? (
        <Info>
          <InfoCircle />
          <div>
            <p>
              {isOnlyExternalCandidates && externalCandidates.length === 1 ? (
                <>The selected recipient is an external candidate.</>
              ) : (
                <>
                  Your selection includes <span>{externalCandidates.length}</span> external candidate.
                </>
              )}
            </p>
            <p>Sending messages to external candidates isn`t supported yet. They`ll still be moved to the Rejected</p>
          </div>
        </Info>
      ) : null}

      <Text margin="10px 0 0 0" fontWeight={600}>
        Sender
      </Text>
      <SenderContent>
        {currentUserAndEmployer.map(({ id, name, tooltip }, index) => {
          return (
            <SenderItem key={id} isActive={sender === id}>
              <Radio
                id={name}
                name={name}
                value={id}
                checked={sender === id}
                onChange={e => {
                  setSender(+e.target.value);
                  setSelectedSenderIndex(index);
                }}
              >
                <div>
                  <SenderTitle>{`${name}${index && name ? `, ${currentUserAndEmployer[0].name}` : ""}`}</SenderTitle>
                  <SenderText>{tooltip}</SenderText>
                </div>
              </Radio>
            </SenderItem>
          );
        })}
      </SenderContent>

      <div style={{ marginTop: "20px" }}>
        <Text fontWeight={600}>Reason to reject</Text>
        <div>
          <Text style={{ color: "#666666", marginBottom: "8px" }} fontSize="14px">
            Select reason for selecting candidate. Each reason has a suitable message template you can get started with.
          </Text>
        </div>
        <SelectContainer>
          <SelectSearch
            value={activeReject}
            defaultValue="Select reason"
            options={reasonOptions}
            onInputChange={handleRejectSearch}
            onSelect={handleSelectReject}
            inputValue={searchValue}
          />
        </SelectContainer>
      </div>
      <Row>
        <Col sm={6}>
          <Textarea
            borderRadius="2%"
            style={{ outline: "none", width: "600px" }}
            value={selectedReason ? formatTextMessage(selectedReason, selectedSenderIndex) : ""}
            onChange={e => setSelectedReason(e.target.value)}
            label="Message"
            name="message"
            placeholder="Write a message..."
          />
        </Col>
      </Row>

      <Row
        style={{
          margin: "24px -12px 40px -12px"
        }}
      >
        <Col>
          <Text margin="0 0 4px" fontWeight="600">
            Allow candidate to reply
          </Text>
          <span
            style={{
              display: "inline-block",
              color: "grey",
              width: "487px"
            }}
          >
            Candidate will be able to reply to this message in their {getCompanyName()} account
          </span>
        </Col>
        <Col>
          <SwitchInput
            style={{ outline: "none", display: "block", marginLeft: "auto" }}
            aria-label="Allow editing work experience related skills"
            checked={replyMessage}
            onChange={() => setReplyMessage(() => !replyMessage)}
          />
        </Col>
      </Row>

      <div className="fz-modal__actions">
        <Button
          onClick={() => toggle()}
          height="36px"
          style={{ color: "black", background: "#EEE", marginRight: "auto", width: "130px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleRejectWithoutMessage()}
          height="36px"
          disabled={loading}
          processing={loading}
          style={{ color: "#b23321", background: theme.white, border: "1px solid #b23321" }}
        >
          Reject without message
          {loading === 1 && (
            <Spinner
              size="25px"
              primaryFill="secondary200"
              secondaryFill="transparent"
              style={{
                position: "absolute",
                top: "12%",
                right: "50%",
                transform: "translateX(50%)"
              }}
            />
          )}
        </Button>
        {!isOnlyExternalCandidates && (
          <Button
            onClick={handleReject}
            disabled={loading}
            height="36px"
            style={{ color: theme.white, background: "#b23321" }}
          >
            Reject
            {loading === 2 && (
              <Spinner
                size="25px"
                primaryFill="secondary200"
                secondaryFill="transparent"
                style={{
                  position: "absolute",
                  top: "12%",
                  right: "50%",
                  transform: "translateX(50%)"
                }}
              />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default RejectionMessagesModalContent;
