import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #e0edff;

  @media screen and (min-width: 1008px) {
    padding: 48px;
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 380px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
`;

export const Image = styled.p`
  width: 110px;
  height: 148px;
  margin-bottom: 24px;

  img {
    max-width: 100%;
  }
`;

export const Description = styled.p`
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 16px;
`;
