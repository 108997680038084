import React, { useEffect, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import { toJS } from "mobx";

import { theme } from "b2c/contexts/theme";
import { isBaronaBrand } from "contexts/init";
import {
  HeadingComponent,
  CountContent,
  Count,
  CountDescription,
  Title,
  Content,
  CountSeparator,
  FilterContent,
  FilterSearchContent,
  FilterSearch,
  FilterSearchIcon,
  FilterCloseIcon,
  SearchInput,
  SearchButton
} from "./styled";
import SelectsView from "../SelectsView";

const Heading = ({
  initFilters,
  totalCountCampaigns,
  onChange,
  appliedFilters,
  employersCount,
  assignedToEmployersCompanies,
  commonCountries
}) => {
  const [term, setTerm] = useState("");
  const isBarona = isBaronaBrand();

  const handleChange = () => {
    onChange({ term });
  };

  const handleClear = () => {
    setTerm("");
    onChange({ term: "" });
  };

  useEffect(() => {
    if (appliedFilters.term !== term) {
      setTerm(appliedFilters.term);
    }
  }, [appliedFilters.term]);

  return (
    <HeadingComponent background={isBarona ? "linear-gradient(90deg, #6CBEFF 37%, #2496F5 85%, #1075D1 100%)" : ""}>
      <Content>
        <Title>View and manage campaigns</Title>
        <CountContent>
          <Count>{totalCountCampaigns}</Count>
          <CountDescription>campaigns</CountDescription>

          <CountSeparator />
          <Count>{employersCount}</Count>
          <CountDescription>companies</CountDescription>
        </CountContent>
        <FilterContent>
          <FilterSearchContent>
            <FilterSearch>
              <FilterSearchIcon>
                <MdSearch />
              </FilterSearchIcon>
              <SearchInput
                type="text"
                placeholder="Search jobs by title or keyword"
                onChange={e => setTerm(e.target.value)}
                value={term}
              />
              {term && (
                <FilterCloseIcon onClick={handleClear}>
                  <MdClose fill="#666666" />
                </FilterCloseIcon>
              )}
            </FilterSearch>
            <SearchButton
              onClick={handleChange}
              background={theme.primaryButtonBackground}
              color={theme.primaryButtonLabelColor}
            >
              Search
            </SearchButton>
          </FilterSearchContent>
          <SelectsView
            initFilters={initFilters}
            commonCountries={toJS(commonCountries)}
            onChange={onChange}
            appliedFilters={appliedFilters}
            assignedToEmployersCompanies={assignedToEmployersCompanies}
          />
        </FilterContent>
      </Content>
    </HeadingComponent>
  );
};

export default Heading;
