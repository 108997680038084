import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MdDone } from "react-icons/md";

import { SelectPart } from "./styled";

const Checkbox = ({
  onCheck,
  onUncheck,
  checked = false,
  id,
  switchType,
  labelClass = "",
  children,
  disabled,
  className = "",
  isGray,
  isSelectPart,
  ...rest
}) => {
  const [isChecked, check] = useState(checked);

  useEffect(() => {
    check(checked);
  }, [checked]);

  const handleClick = e => {
    if (!!onCheck || !!onUncheck) {
      e.preventDefault();
      if (isChecked && onUncheck) {
        onUncheck();
        check(!isChecked);
      } else {
        onCheck();
        check(!isChecked);
      }
    }
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      className={`fz-checkbox ${disabled ? "disabled" : ""}${switchType ? " fz-checkbox_switch " : ""}${
        className || ""
      }${isGray ? " fz-checkbox_gray" : ""}`}
      onClick={handleClick}
    >
      <input checked={isChecked} id={id} type="checkbox" readOnly className="fz-checkbox__input" {...rest} />
      <label className={`fz-checkbox__label ${labelClass}`} htmlFor={id}>
        {isSelectPart ? (
          <SelectPart className="fz-checkbox__label-icon" />
        ) : (
          <MdDone className="fz-checkbox__label-icon" />
        )}
        {children}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  /** whether the box is checked */
  checked: PropTypes.bool,
  /** func executed on box checking */
  onCheck: PropTypes.func,
  /** func executed on box unchecking */
  onUncheck: PropTypes.func,
  /** nested children */
  children: PropTypes.node
};

export default Checkbox;
