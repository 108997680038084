import React from "react";
import { MdAdd } from "react-icons/md";

import Disclaimer from "../Disclaimer";
import Questions from "../Questions";
import HelpFul from "../HelpFul";
import AddQuestion from "../AddQuestion";
import Empty from "../Empty";
import NoAccess from "../NoAccess";

import { Container, Title, Description, AddQuestionButton } from "./styled";
import { useModal } from "../../../../../utils/hooks";
import { getCompanyName } from "../../../../../contexts/init";

const Content = ({
  questions,
  positionQuestions,
  postQuestions,
  availableQuestionLanguages,
  isUserHaveAccessToMessage
}) => {
  const openPublicCommentModal = useModal(
    <AddQuestion
      availableQuestionLanguages={availableQuestionLanguages}
      positionQuestions={positionQuestions}
      questions={questions}
      postQuestions={postQuestions}
    />,
    ""
  );

  const handleRemove = questionId => {
    const updatedQuestions = positionQuestions
      .filter(item => item.question_id !== questionId)
      .map((item, index) => {
        const options = item.options
          ? { options: item.options.map(option => ({ ...option, ...{ id: option.question_option_id } })) }
          : {};

        return {
          question_id: item.question_id,
          item_order: index,
          mandatory: true,
          ...options
        };
      });

    postQuestions({ questions: updatedQuestions });
  };

  return (
    <Container>
      {isUserHaveAccessToMessage ? (
        <>
          <Title>Do you have questions for your candidates?</Title>
          <Description>
            Add screening questions created by {getCompanyName()} experts to collect additional information from the
            candidates. All candidates will be asked to answer each question.
          </Description>

          <HelpFul isDefaultActive={!positionQuestions.length} />

          {positionQuestions.length ? (
            <>
              <Questions handleRemoveQuestion={questionId => handleRemove(questionId)} questions={positionQuestions} />

              <AddQuestionButton onClick={openPublicCommentModal}>
                <MdAdd />
                Add question
              </AddQuestionButton>

              <Disclaimer />
            </>
          ) : (
            <>
              <Empty>
                <AddQuestionButton onClick={openPublicCommentModal}>
                  <MdAdd />
                  Add question
                </AddQuestionButton>
              </Empty>
            </>
          )}
        </>
      ) : (
        <NoAccess />
      )}
    </Container>
  );
};

export default Content;
