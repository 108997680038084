import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { MdClose, MdPermIdentity, MdRestore } from "react-icons/md";

import Button from "components/Button";
import { capitalize } from "utils/helpers";
import Tag from "../../../../components/Tag";

const SavedSearchBrowseLists = ({ searches = [], apply, deleteSearch, refresh }) => {
  return (
    <>
      <h3 className="fz-heading-title mt-5">Saved searches</h3>

      <div className="panel-list">
        {searches.map(search => (
          <SavedSearch key={search.id} {...search} apply={apply} deleteSearch={deleteSearch} refresh={refresh} />
        ))}
      </div>
    </>
  );
};

const SavedSearch = ({
  id,
  name,
  owner = {},
  skills = [],
  viewed_at,
  unviewed_talents_count = 0,
  filters,
  apply,
  deleteSearch,
  ...rest
}) => {
  const [isRemoved, setRemoved] = useState(false);

  const getDetails = () => {
    const filterParams = { ...filters };
    const keyList = Object.keys(filterParams);
    keyList.forEach(key => {
      if (Array.isArray(filterParams[key])) delete filterParams[key];
    });
    keyList.forEach(key => {
      if (/age_m/.test(key)) {
        filterParams.age = filterParams.age_max
          ? `${filterParams.age_min || 0} ~ ${filterParams.age_max}`
          : filterParams.age_min;
      }
      if (/experience_m/.test(key)) {
        filterParams.age = filterParams.experience_max
          ? `${filterParams.experience_min || 0} ~ ${filterParams.experience_max}`
          : filterParams.experience_min;
      }
    });
    keyList.forEach(key => /min|max/.test(key) && delete filterParams[key]);

    return { ...rest, ...filterParams };
  };

  const details = getDetails();

  return (
    <CSSTransition in={!isRemoved} timeout={500} onExited={() => deleteSearch(id)} classNames="panel">
      <div className="fz-panel fadeInFromBottom">
        <div className="fz-panel__container">
          <div className="fz-applicant">
            <div
              role="button"
              tabIndex={-1}
              className="fz-applicant__content"
              style={{ cursor: "pointer" }}
              onClick={() => apply(name, filters)}
            >
              <div className="fz-applicant__name">
                {name}
                {unviewed_talents_count > 0 && <div className="fz-badge ml-1">+{unviewed_talents_count}</div>}
              </div>
              <div className="fz-panel__info mb-2">
                {/* <p className='fz-info-string'>
                  <MdPinDrop className='fz-info-string__icon' />
                  <span className='fz-info-string__text'>Nairobi, Kenya</span>
                </p> */}
                {owner.name && (
                  <p className="fz-info-string">
                    <MdPermIdentity className="fz-info-string__icon" />
                    <span className="fz-info-string__text">{owner.name}</span>
                  </p>
                )}
                {viewed_at && (
                  <p className="fz-info-string">
                    <MdRestore className="fz-info-string__icon" />
                    <span className="fz-info-string__text">
                      {new Date(viewed_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric"
                      })}
                    </span>
                  </p>
                )}
              </div>
              <div className="fz-applicant__details">
                {Object.keys(details).length > 0 ? (
                  <>
                    {Object.keys(details)
                      .filter(key => Array.isArray(details[key]) && details[key].length > 0)
                      .map(key => (
                        <div key={`${id}_${key}`} className="fz-infobox fz-infobox_thin">
                          <div className="fz-infobox__title">
                            {details[key]
                              .slice(0, 3)
                              .map(({ name: nameItem }, i) => `${nameItem}${i < details[key].length - 1 ? "; " : ""}`)}
                            {details[key].length > 3 && "etc."}
                          </div>
                          <div className="fz-infobox__info">{capitalize(key.split("_").join(" "))}</div>
                        </div>
                      ))}
                    {Object.keys(details)
                      .filter(key => typeof details[key] === "string")
                      .map(key => (
                        <div key={`${id}_${key}`} className="fz-infobox fz-infobox_thin">
                          <div className="fz-infobox__title">{details[key]}</div>
                          <div className="fz-infobox__info">{capitalize(key.split("_").join(" "))}</div>
                        </div>
                      ))}
                  </>
                ) : (
                  <div className="fz-infobox fz-infobox_thin">
                    <div className="fz-infobox__title">...Other Filter Parameters</div>
                    <div className="fz-infobox__info">Applied filters</div>
                  </div>
                )}
              </div>
              {skills.length > 0 && (
                <div className="fz-tag-container mt-2">
                  {skills.slice(0, 8).map(skill => (
                    <Tag key={skill.name} name={skill.name} size="md" />
                  ))}
                </div>
              )}
            </div>
            <div className="fz-applicant__actions">
              <div className="fz-panel__actions">
                <Button
                  icon={<MdClose />}
                  size="sm"
                  color="warning"
                  onClick={() => setRemoved(true)}
                  role="button"
                  tabIndex="0"
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SavedSearchBrowseLists;
