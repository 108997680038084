import styled from "styled-components";

export const DisabilityImage = styled.img`
  margin: 25px;

  max-width: 90%;

  @media screen and (min-width: 1100px) {
    max-width: none;
  }
`;
