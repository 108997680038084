import React from "react";

import { Container, StepCounter, Title, Actions, Back, Next } from "./styled";

const Navigation = ({ backHref, nextHref }) => {
  const handleClickNavigation = () => {
    window.removeEventListener("beforeunload", window.beforeUnloadHandler);
  };

  return (
    <Container>
      <div>
        <StepCounter>Step 4 of 6</StepCounter>
        <Title>Screening questions</Title>
      </div>

      <Actions>
        <Back href={backHref} onClick={handleClickNavigation}>
          Back
        </Back>
        <Next href={nextHref} onClick={handleClickNavigation}>
          Next
        </Next>
      </Actions>
    </Container>
  );
};

export default Navigation;
