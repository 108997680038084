import React, { useEffect } from "react";
import { observer, inject, Provider } from "mobx-react";
import { ToastContainer, Slide } from "react-toastify";

import "b2c/i18n";
import MessagesStore from "stores/MessagesStore";
import { ApplicationContext } from "contexts/init";
import { ModalProvider } from "contexts/modal";
import { ErrorBoundaryProvider } from "utils/hocs/errorBoundary";
import RootRouter from "./routes/RootRouter";
import UserStore from "./stores/User";
import SettingsStore from "./stores/Settings";
import CampaignsStore from "./stores/CampaignsStore";
import MyJobsStore from "./stores/MyJobsStore";
import CampaignCreation from "./stores/CampaignCreation";

const App = initialState => {
  return (
    <ErrorBoundaryProvider>
      <ToastContainer transition={Slide} />

      <ApplicationContext {...initialState}>
        <Provider
          User={UserStore}
          Messages={new MessagesStore(UserStore)}
          Settings={SettingsStore}
          CampaignsStore={CampaignsStore}
          MyJobsStore={MyJobsStore}
          CampaignCreation={CampaignCreation}
        >
          <UserInjected initialUser={initialState.current_user}>
            <ModalProvider>
              <RootRouter initialState={initialState} />
            </ModalProvider>
          </UserInjected>
        </Provider>
      </ApplicationContext>
    </ErrorBoundaryProvider>
  );
};

const UserSetter = ({ User: { setUser }, initialUser, children }) => {
  useEffect(() => {
    setUser(initialUser);

    document.body.classList.add("b2b-body-min-width");
  }, []);

  return children;
};

const UserInjected = inject("User")(observer(UserSetter));

export default App;
