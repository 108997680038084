import React from "react";
import Select from "react-select";
import cn from "classnames";

const SimpleSelect = ({
  selectOptions,
  defaultOption,
  value,
  flatView,
  getFilteredList,
  role,
  children,
  isSendFullObject,
  noOptionsMessage = () => "Select country first",
  isMulti = false
}) => {
  const defaultValue = defaultOption || selectOptions[0];

  const onHandleChange = value => {
    if (isSendFullObject) {
      getFilteredList(value);
    } else {
      getFilteredList({ [role]: value.value });
    }
  };

  const viewClassName = cn("fz-simple-select", {
    "fz-simple-select_flat": flatView
  });

  return (
    <Select
      defaultValue={defaultValue}
      options={selectOptions}
      isMulti={isMulti}
      className={viewClassName}
      classNamePrefix="fz-simple-select"
      noOptionsMessage={noOptionsMessage}
      isSearchable={false}
      onChange={evt => onHandleChange(evt)}
      value={value}
    >
      {children}
    </Select>
  );
};

export default SimpleSelect;
