import React, { useEffect, useState } from "react";
import { SimpleSelect } from "components/Selects";

import { Modal, Description, Title, Submit, SelectContainer } from "./styled";
import { useModal } from "../../../../../utils/hooks";
import SuccessToken from "../SuccessToken";

const CreateToken = ({ createApiKeys, activeGlobalBrands }) => {
  const [brandValue, setBrandValue] = useState({});
  const showSuccessTokenModal = useModal(<SuccessToken />, "", false, false);

  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    createApiKeys(brandValue.id).then(res => {
      setLoading(false);

      if (res.key) {
        showSuccessTokenModal({ activeKey: res });
      }
    });
  };

  const handleSelect = value => {
    setBrandValue(value);
  };

  useEffect(() => {
    if (!brandValue.id && activeGlobalBrands.length) {
      setBrandValue(activeGlobalBrands[0]);
    }
  }, []);

  return (
    <Modal>
      <Title>Create API key</Title>
      <Description>Select the global brand you want to link this API key to.</Description>

      <SelectContainer>
        <SimpleSelect
          selectOptions={activeGlobalBrands}
          getFilteredList={handleSelect}
          defaultValue={brandValue}
          isSendFullObject
        />
      </SelectContainer>

      <Submit onClick={onSubmit} $disabled={loading}>
        Create
      </Submit>
    </Modal>
  );
};

export default CreateToken;
