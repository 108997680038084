import React, { useEffect, useContext, forwardRef } from "react";
import { Route, useHistory } from "react-router-dom";
import { MdQuestionAnswer, MdLock } from "react-icons/md";

import AppContext from "contexts/init";
import ModalConsumer from "contexts/modal";
import NoPerm from "images/jobs/sub_noperm.svg";
import Tooltip from "../../../components/Tooltip";
import Button from "../../../components/Button";
import ModalContent from "./ModalContent";

const Subscription = ({ permission = "none", className = "", horizontal = false, children }) => {
  const { subscription = {} } = useContext(AppContext);
  const permitted = !!subscription?.permissions?.[permission];
  const history = useHistory();
  const isGuestPage = history.location.pathname.includes("share-candidates");

  // disable
  if (isGuestPage) {
    return <div className={`${className}`}>{children(true)}</div>;
  }

  const handleUpgradePrompt = toggle =>
    toggle(
      <ModalContent.Upgrade
        close={() => toggle()}
        subscription={subscription}
        element={subscription.descriptions[permission]}
      />,
      `${subscription.descriptions[permission]} is unavailable`
    );

  return children ? (
    <div className={`${className}${permitted ? "" : " subscription"}`}>
      {permitted ? (
        children(permitted)
      ) : (
        <>
          <div className={`cover ${horizontal ? "horizontal" : ""}`}>
            <NoPerm />

            <div className="text">
              <div className="main">
                You are on <span className="type">{subscription.type || "Basic"}</span> license
              </div>
              <div className="sub">
                Please upgrade your license to unlock {subscription.descriptions[permission]}
                <Button.Simple className="intercom-trigger" icon={<MdQuestionAnswer />}>
                  Contact support
                </Button.Simple>
              </div>
            </div>
          </div>

          <ModalConsumer>
            {({ toggle }) =>
              React.cloneElement(children(permitted), {
                onClickCapture: e => {
                  if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  handleUpgradePrompt(toggle);
                }
              })
            }
          </ModalConsumer>
        </>
      )}
    </div>
  ) : null;
};

Subscription.Upgrade = forwardRef(({ permission, className, paymentCheck = false, children, ...props }, ref) => {
  const { subscription = {} } = useContext(AppContext);
  const permitted = permission ? subscription.permissions?.[permission] : true;
  const payment = paymentCheck ? subscription.license_state !== "inactive" : true;

  const handleUpgradePrompt = toggle =>
    toggle(
      payment ? (
        <ModalContent.Upgrade
          close={() => toggle()}
          subscription={subscription}
          element={subscription.descriptions[permission]}
        />
      ) : (
        <ModalContent.Payment close={() => toggle()} />
      ),
      `${subscription.descriptions[permission]} is unavailable`
    );

  return permitted && payment ? (
    React.cloneElement(children, { ...props, ref })
  ) : (
    <ModalConsumer>
      {({ toggle }) => (
        <Tooltip
          className={className}
          tooltipContent={
            <div style={{ textAlign: "center", width: 200 }}>
              {payment
                ? `${subscription.descriptions[permission]} is not available for your license`
                : "This feature requires payment"}
            </div>
          }
        >
          {React.cloneElement(children, {
            onClickCapture: e => {
              if (e) {
                e.preventDefault();
                e.stopPropagation();
              }
              handleUpgradePrompt(toggle);
            },
            tag: "div",
            disabled: true
          })}
        </Tooltip>
      )}
    </ModalConsumer>
  );
});

Subscription.Unlock = ({ unlocked, element, unlockHandler, paymentCheck = false, children, ...props }) => {
  const history = useHistory();
  const isGuestPage = history.location.pathname.includes("share-candidates");

  // disable
  if (isGuestPage) {
    return React.cloneElement(children, props);
  }

  const {
    subscription: {
      limits: { unlocked_profiles_count = 0, unlock_profiles_limit },
      license_state
    },
    updateSubscription
  } = useContext(AppContext);

  const unlocksLeft = Math.max(unlock_profiles_limit - unlocked_profiles_count, 0);
  const payment = paymentCheck ? license_state !== "inactive" : true;

  const handleUnlockPrompt = toggle =>
    toggle(
      payment ? (
        <ModalContent.Unlock
          close={() => toggle()}
          left={unlocksLeft}
          unlockHandler={unlockHandler}
          updateSubscription={updateSubscription}
          element={element}
        />
      ) : (
        <ModalContent.Payment close={() => toggle()} />
      ),
      <>
        <MdLock /> {element}
      </>
    );

  return unlocked ? (
    React.cloneElement(children, props)
  ) : (
    <ModalConsumer>
      {({ toggle }) =>
        React.cloneElement(children, {
          onClickCapture: e => {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            handleUnlockPrompt(toggle);
          }
        })
      }
    </ModalConsumer>
  );
};

Subscription.Payment = forwardRef(({ element = "", children, ...props }, ref) => {
  const { subscription } = useContext(AppContext);
  const payment = subscription.license_state !== "inactive";

  const handlePaymentPrompt = toggle =>
    toggle(<ModalContent.Payment close={() => toggle()} />, `${element || "Feature"} is unavailable`);

  return payment ? (
    React.cloneElement(children, { ...props, ref })
  ) : (
    <ModalConsumer>
      {({ toggle }) =>
        React.cloneElement(children, {
          onClickCapture: e => {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            handlePaymentPrompt(toggle);
          }
        })
      }
    </ModalConsumer>
  );
});

Subscription.Route = ({ exact, path, to, permission = "none", component }) => {
  const { subscription } = useContext(AppContext);
  const permitted = !!subscription.permissions[permission];
  const payment = subscription.license_state !== "inactive";

  return permitted && payment ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <ModalConsumer>
      {({ toggle }) => (
        <UpgradeOnMount
          onMount={() => {
            toggle(
              <ModalContent.Upgrade
                route
                close={() => window.location.replace(`${window.location.origin}${to}`)}
                subscription={subscription}
                element={subscription.descriptions[permission]}
              />,
              `${subscription.descriptions[permission] || "Feature"} is unavailable`,
              false
            );
          }}
          exact={exact}
          path={path}
          component={component}
        />
      )}
    </ModalConsumer>
  );
};

Subscription.Tab = ({ permission = "none", tab, redirectPath, children, ...props }) => {
  const { subscription = {} } = useContext(AppContext);
  const permitted = tab ? !!subscription.permissions[permission] : true;
  const history = useHistory();

  useEffect(() => {
    if (redirectPath && !permitted) {
      history.push(redirectPath);
    }
  }, [redirectPath, permitted]);

  return permitted ? (
    React.cloneElement(children, props)
  ) : (
    <ModalConsumer>
      {({ toggle }) => (
        <BackOnMount
          to="/applicants"
          onMount={() => {
            toggle(
              <ModalContent.Upgrade
                close={() => toggle()}
                subscription={subscription}
                element={subscription.descriptions[permission]}
              />,
              `${subscription.descriptions[permission] || "Feature"} is unavailable`
            );
          }}
        >
          {children}
        </BackOnMount>
      )}
    </ModalConsumer>
  );
};

const BackOnMount = ({ onMount, children }) => {
  const { goBack } = useHistory();

  useEffect(() => {
    onMount();
    goBack();
  }, []);

  return children;
};

const UpgradeOnMount = ({ onMount, exact, path, component }) => {
  useEffect(() => {
    onMount();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
      onClickCapture={e => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        onMount();
      }}
    >
      <Route exact={exact} path={path} component={component} />
    </div>
  );
};

export default Subscription;
