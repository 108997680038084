import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const CollapseButton = ({ expandText, collapseText, withoutShadow, expanded, onClickHandle, ...rest }) => {
  const wrapperClassNames = cn("fz-collapse-btn", {
    "fz-collapse-btn_expanded": expanded,
    "fz-collapse-btn_clear": withoutShadow
  });

  return (
    <div role="button" tabIndex={-1} className={wrapperClassNames} onClick={onClickHandle} {...rest}>
      <div className="fz-collapse-btn__text">{expanded ? expandText : collapseText}</div>
    </div>
  );
};

CollapseButton.propTypes = {
  collapseText: PropTypes.string,
  expandText: PropTypes.string,
  withoutShadow: PropTypes.bool,
  expanded: PropTypes.bool
};

CollapseButton.defaultProps = {
  collapseText: "Show",
  expandText: "Hide",
  withoutShadow: false,
  expanded: false
};

export default CollapseButton;
