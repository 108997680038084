import styled from "styled-components";

export const IntroductionContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 32px;
`;

export const IntroductionTitle = styled.h5`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin: 0 0 8px 0;
`;

export const IntroductionContent = styled.div`
  overflow: hidden;
  color: #666;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;

  ${({ $isActive }) =>
    $isActive
      ? `
        -webkit-line-clamp: none;
        `
      : ""}
`;

export const IntroductionButton = styled.button`
  color: #408bfc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  margin-top: 8px;
`;
