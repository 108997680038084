import Popup from "reactjs-popup";
import cn from "classnames";
import { MdGetApp } from "react-icons/md";
import React from "react";

const DownloadDropdown = ({ personalityResults, downloadAll, customCv }) => {
  return customCv || downloadAll || personalityResults ? (
    <Popup
      className="fz-dropdown"
      trigger={open => (
        <button type="button" className={cn("fz-dropdown-itm", { "fz-dropdown-itm_active": open })}>
          <MdGetApp className="fz-dropdown-itm__icon" />
          <span className="fz-dropdown-itm__text">Download CV</span>
        </button>
      )}
      position="left top"
      on="hover"
      mouseEnterDelay={0}
      mouseLeaveDelay={100}
      offsetY={-16}
      arrow={false}
    >
      <div className="fz-dropdown-nav">
        {customCv && (
          <a className="fz-dropdown-itm" download href={customCv}>
            <span className="fz-dropdown-itm__text">Self-prepared CV</span>
          </a>
        )}
      </div>
    </Popup>
  ) : null;
};

export default DownloadDropdown;
