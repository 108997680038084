import React, { useMemo, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { MdInsertLink, MdPhone } from "react-icons/md";
import { toJS } from "mobx";

import {
  Modal,
  Title,
  Description,
  Container,
  Actions,
  Cancel,
  Next,
  Content,
  Heading,
  Footer,
  ProfileCompletenessText,
  ProfileCompletenessProgressContent,
  ProfileCompletenessProgress,
  Avatar,
  Name,
  Company,
  State,
  StateItem,
  Dot,
  Position,
  StateName,
  StateText,
  CvUploaded,
  LoadingContent,
  Social
} from "./styled";
import { getCompanyName } from "../../../../../contexts/init";
import Loading from "../../../../../../assets/images/loading.svg";

const ExternalSwitchToProfile = ({
  CampaignsStore: { switchExternalCandidate, getSwitchInfo, switchUserData, setSwitchUserData },
  candidate,
  close,
  isSwitchToExternal
}) => {
  const { candidateId } = candidate;
  const {
    avatar,
    name,
    country,
    current_position_employer: companyName,
    current_position_title: positionTitle,
    highest_education_degree: education,
    total_work_experience: totalWorkExperience,
    profile_completeness_percentage: progress,
    user_uploaded_cv_url: cvUpload,
    links: { linkedin } = { linkedin: "" },
    phone = "_phone"
  } = toJS(switchUserData);

  const successMessage = `Application data switched to ${getCompanyName()} profile`;

  const initials = name
    ? name
        .split(" ")
        .map(part => part[0])
        .join("")
    : "";

  const getProgressColor = useMemo(() => {
    if (!progress) {
      return {
        fill: "",
        progress: ""
      };
    }

    const minProgress = 33;
    const middleProgress = 67;

    if (progress < minProgress) {
      return {
        fill: "#F5E1E5",
        progress: "#FF2727"
      };
    }
    if (progress >= minProgress && progress < middleProgress) {
      return {
        fill: "#FFF7CB",
        progress: "#FFE140"
      };
    }

    if (progress >= middleProgress) {
      return {
        fill: "#E1F6E0",
        progress: "#00B55E"
      };
    }
  }, [progress]);

  const handleSubmit = () => {
    switchExternalCandidate(candidateId, successMessage).then(res => {
      if (res) {
        close();
      }
    });
  };

  useEffect(() => {
    getSwitchInfo(candidateId);

    return () => {
      setSwitchUserData({});
    };
  }, []);

  return (
    <Modal>
      {name ? (
        <>
          <Title>Switch to {!isSwitchToExternal ? <>{getCompanyName()}</> : "External"} profile?</Title>
          <Description>
            {!isSwitchToExternal ? (
              <>
                This will replace the External candidate data with the information from the candidate`s{" "}
                {getCompanyName()} profile.
              </>
            ) : (
              <>
                This will replace the information from the candidate’s {getCompanyName()} profile to External candidate
                data.
              </>
            )}
          </Description>

          <Container>
            <Content>
              <Heading>
                <Avatar>{avatar ? <img src={avatar} alt={initials} /> : <>{initials}</>}</Avatar>
                <div>
                  <Name>{name}</Name>
                  <Position>
                    {positionTitle && (
                      <>
                        {positionTitle}
                        <Dot />
                      </>
                    )}
                    {country && <>{country}</>}
                  </Position>
                  {companyName && <Company>{companyName}</Company>}
                  <State>
                    {totalWorkExperience ? (
                      <StateItem>
                        <StateName>Total work exp.</StateName>
                        <StateText>{totalWorkExperience}</StateText>
                      </StateItem>
                    ) : null}

                    {education ? (
                      <StateItem>
                        <StateName>Education</StateName>
                        <StateText>{education}</StateText>
                      </StateItem>
                    ) : null}
                  </State>
                </div>
              </Heading>
              {progress || cvUpload || linkedin || phone ? (
                <Footer>
                  {!isSwitchToExternal ? (
                    <>
                      {progress && (
                        <div>
                          <ProfileCompletenessText>Profile completeness</ProfileCompletenessText>
                          <ProfileCompletenessProgressContent>
                            <ProfileCompletenessProgress
                              fill={getProgressColor.fill}
                              progress={getProgressColor.progress}
                              progressWidth={progress}
                            >
                              <span />
                            </ProfileCompletenessProgress>

                            <span>{progress}%</span>
                          </ProfileCompletenessProgressContent>
                        </div>
                      )}

                      {cvUpload && <CvUploaded>CV uploaded</CvUploaded>}
                    </>
                  ) : (
                    <>
                      {linkedin && (
                        <Social>
                          <MdInsertLink />

                          {linkedin}
                        </Social>
                      )}
                      {phone && (
                        <Social>
                          <MdPhone />
                          {phone}
                        </Social>
                      )}
                    </>
                  )}
                </Footer>
              ) : null}
            </Content>
          </Container>

          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Next onClick={handleSubmit}>Switch</Next>
          </Actions>
        </>
      ) : (
        <LoadingContent>
          <Loading className="spin" />
        </LoadingContent>
      )}
    </Modal>
  );
};

export default inject("CampaignsStore", "Settings")(observer(ExternalSwitchToProfile));
