import React from "react";

import Button from "components/Button";

const ImagePreview = ({ image, onChange, close }) => {
  return (
    <>
      <div className="fz-data-row">
        <img className="mb-2 m-l-auto m-r-auto max-width-370px" src={image} alt="preview" />
      </div>
      <div className="fz-modal__actions mt-0">
        <Button color="gray" onClick={close}>
          Cancel
        </Button>
        <Button color="blue" onClick={onChange}>
          Change
        </Button>
      </div>
    </>
  );
};

export default ImagePreview;
