import styled from "styled-components";

export const Modal = styled.div`
  max-width: 640px;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin-bottom: 24px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin-bottom: 24px;
`;

export const CheckboxText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
`;

export const Remove = styled.button`
  background: #eb3737;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 40px;

  ${({ isDisabled }) =>
    isDisabled
      ? `
      opacity: 0.2;
      pointer-events: none;
      `
      : ""}
`;

export const FolderInfo = styled.div`
  background: #f3f3f3;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
`;

export const FolderName = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0 0 4px 0;
`;

export const FolderCount = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
`;
