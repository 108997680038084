import React, { useLayoutEffect } from "react";
import { observer, inject } from "mobx-react";

import { withRoute } from "utils/hocs/withRoute";
import Pane from "../../../../components/Pane";
import InfoPane from "../../../../components/InfoPane";
import Checkbox from "../../../../components/Checkbox";

const ApplicationsTab = ({
  Settings: {
    state: { loading, processing },
    employer,
    getApplicationSettings,
    toggleExternalAts
  }
}) => {
  useLayoutEffect(() => {
    getApplicationSettings();
  }, []);

  return (
    <>
      <Pane.Two>
        <InfoPane.Item
          className="mt-2 pb-2"
          title="Use external ATS"
          subtitle={`By enabling use of an external application tracking system (ATS), the candidates will be directed<br/>
                            automatically to another ATS to complete their application. When publishing a new job, you will need to<br/>
                            provide the web address to which the candidate will be directed to on your external ATS.`}
          actions={
            <Checkbox
              switchType
              disabled={loading || processing}
              checked={employer?.applicationSettings?.ats_applications}
              onCheck={toggleExternalAts}
              onUncheck={toggleExternalAts}
            />
          }
        />
      </Pane.Two>
    </>
  );
};

export default withRoute(inject("Settings")(observer(ApplicationsTab)));
