import React, { useLayoutEffect } from "react";
import { observer, inject } from "mobx-react";
import { MdModeEdit, MdAdd } from "react-icons/md";

import Button from "components/Button";
import { useModal } from "utils/hooks";
import { capitalize } from "utils/helpers";
import { withRoute } from "utils/hocs/withRoute";
import Ico1 from "images/b2b/ico.svg";
import UpdateAccessibilityContent from "../UpdateAccessibilityContent";
import Layout from "../../../../components/Layout";
import InfoPane from "../../../../components/InfoPane";
import Pane from "../../../../components/Pane";

const derriveAccessibilities = accessibilities =>
  accessibilities
    ? Object.values(accessibilities).map(({ question, options }) => {
        const selectedOptions = options.filter(option => option.selected).map(option => option.name);
        return {
          name: question,
          value:
            selectedOptions.length <= 2
              ? selectedOptions.join(", ")
              : `${selectedOptions.slice(0, 2).join(", ")} and ${selectedOptions.length - 2} more`
        };
      })
    : null;

const AccessibilityTab = ({
  Settings: {
    employer: { accessibilities },
    accessibilityOptions,
    getAccessibilityOptions,
    updateAccessibilities
  }
}) => {
  const derrived = derriveAccessibilities(accessibilityOptions);
  const openUpdateAccessibilityModal = useModal(
    <UpdateAccessibilityContent
      accessibilities={accessibilities}
      options={accessibilityOptions}
      updateAccessibilities={updateAccessibilities}
    />,
    "Define accessibility settings"
  );

  useLayoutEffect(() => {
    getAccessibilityOptions();
  }, []);

  return accessibilityOptions ? (
    <Pane.Two>
      <h2 className="fz-heading-title exp mb-3">
        Accessibility settings
        {accessibilities && (
          <Button size="md" icon={<MdModeEdit />} color="yellow" onClick={openUpdateAccessibilityModal}>
            Edit settings
          </Button>
        )}
      </h2>

      {derrived ? (
        <InfoPane.Group gray className="mb-1">
          {derrived.map(item => (
            <InfoPane.Item
              key={item.name}
              className="m-0 p-2"
              customTitle={
                <div className="fz-text fz-text_md fz-text_normal fz-text-color__black m-0">{item.name}</div>
              }
              data={capitalize(item.value)}
            />
          ))}
        </InfoPane.Group>
      ) : (
        <Layout.EmptyState
          narrow
          showImage
          customImage={<Ico1 />}
          title="Define your accessibility settings"
          subtitle="Tell job seekers how your organisation supports employees with disabilities. These answers will be visible to disabled job seekers on your organisation’s profile"
          actionButton={
            <Button icon={<MdAdd />} color="yellow" onClick={openUpdateAccessibilityModal}>
              Define settings
            </Button>
          }
        />
      )}
    </Pane.Two>
  ) : null;
};

export default withRoute(inject("Settings")(observer(AccessibilityTab)));
