import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import cn from "classnames";
import Popup from "reactjs-popup";
import { Globe, Collapse, Expand, Hourglass } from "@styled-icons/boxicons-regular";
import {
  MdDelete,
  MdKeyboardArrowDown,
  MdMoreHoriz,
  MdPeopleOutline,
  MdLockOutline,
  MdEdit,
  MdRemoveRedEye
} from "react-icons/md";
import DOMPurify from "dompurify";
import { User } from "@styled-icons/boxicons-solid";

import Button from "components/Button";
import ProfileAvatar from "components/ProfileAvatar";
import AppContext from "contexts/init";
import { daysSince, getTimeSpanString } from "utils/helpers";
import { visibilityComment } from "b2b/constants/main";
import { commentsTab } from "b2b/constants/campaign";
import emptyStateImage from "../../../../assets/images/b2b/empty-comment.png";
import Subscription from "../Subscription";
import {
  ModalTitle,
  ModalCommentContainer,
  ModalHeading,
  ModalContent,
  ModalContainer,
  ModalPreloader,
  AddComment,
  GuestLabel,
  GuestLabelContent,
  Tabs,
  TabsItem,
  Job,
  JobTitle,
  JobDescription,
  TooltipContent,
  TooltipTitle,
  TooltipDescription,
  TooltipStats,
  TooltipStatsItem,
  TooltipHeading,
  PromotionActive,
  Overlay
} from "./styled";
import { useModal } from "../../../utils/hooks";
import PublicComment from "../PublicComment";
import DeleteComment from "../DeleteComment";
import RichTextTextarea from "../RichTextTextarea";
import GetGuestCommentName from "../GetGuestCommentName";
import Tooltip from "../../../components/Tooltip";
import JobPhase from "../../pages/JobDetailScreen/components/JobPhase";
import { Spinner } from "../../../b2c/components/Core";

const dropdownOptions = [
  {
    title: "Team",
    description: "All team members can see this comment",
    Icon: MdPeopleOutline,
    visibility: "team"
  },
  {
    title: "Public",
    description: "Team members, collaborators and guests can see this comment",
    Icon: Globe,
    visibility: "public"
  },
  {
    title: "Private",
    description: "Only you can see this comment",
    Icon: MdLockOutline,
    visibility: "private"
  }
];

const Comments = ({
  comments = [],
  isRemovePublic,
  isSharedCandidates,
  add,
  editComment,
  remove,
  isModal,
  createShareCandidateUserForComment,
  toggleClose,
  getCandidateComments,
  commentsMeta,
  isShowTabsOtherCampaigns
}) => {
  const [empty, setEmpty] = useState(comments.length > 0);
  const options = isRemovePublic ? dropdownOptions.filter(item => item.visibility !== "public") : dropdownOptions;

  const [inputValue, setInputValue] = useState("");
  const [activeCommentsTab, setActiveCommentsTab] = useState("");
  const [isFieldFocus, setFieldFocus] = useState(false);
  const [isFieldFilled, setFieldFilled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [visibilityValue, setVisibilityValue] = useState("");
  const [activeCollapse, setActiveCollapse] = useState(false);
  const [activeEdit, setActiveEdit] = useState({ isActive: false, body: {} });
  const [isShowGuestCommentName, setIsShowGuestCommentName] = useState(false);
  const [loggedUser, setLoggedUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => {
    setActiveCollapse(true);
  };

  const handleSetThisCampaignTab = () => {
    setActiveCommentsTab(commentsTab.thisCampaign);
    setIsLoading(true);
    getCandidateComments(false).then(() => {
      setIsLoading(false);
    });
  };

  const handleSetOtherCampaignsTab = () => {
    setActiveCommentsTab(commentsTab.otherCampaigns);
    setIsLoading(true);
    getCandidateComments(true).then(() => {
      setIsLoading(false);
    });
  };

  const isActiveOtherCampaignTab = useMemo(() => activeCommentsTab === commentsTab.otherCampaigns, [activeCommentsTab]);

  const handleCloseModal = () => {
    setActiveCollapse(false);

    if (toggleClose) {
      toggleClose();
    }
  };

  useEffect(() => {
    if (!activeCommentsTab) {
      setActiveCommentsTab(commentsTab.thisCampaign);
    }
  }, []);

  if (activeCollapse || isModal) {
    return (
      <CommentsModal
        isLoading={isLoading}
        comments={comments}
        isSharedCandidates={isSharedCandidates}
        setEmpty={setEmpty}
        options={options}
        inputValue={inputValue}
        setActiveDropdown={setActiveDropdown}
        activeDropdown={activeDropdown}
        setInputValue={setInputValue}
        setLoggedUser={setLoggedUser}
        loggedUser={loggedUser}
        setIsShowGuestCommentName={setIsShowGuestCommentName}
        isShowGuestCommentName={isShowGuestCommentName}
        setFieldFocus={setFieldFocus}
        isFieldFocus={isFieldFocus}
        isFieldFilled={isFieldFilled}
        setFieldFilled={setFieldFilled}
        visibilityValue={visibilityValue}
        setVisibilityValue={setVisibilityValue}
        add={add}
        editComment={editComment}
        remove={remove}
        onClose={handleCloseModal}
        isModal
        activeEdit={activeEdit}
        setActiveEdit={setActiveEdit}
        createShareCandidateUserForComment={createShareCandidateUserForComment}
        activeCommentsTab={activeCommentsTab}
        setActiveCommentsTab={setActiveCommentsTab}
        handleSetOtherCampaignsTab={handleSetOtherCampaignsTab}
        handleSetThisCampaignTab={handleSetThisCampaignTab}
        commentsMeta={commentsMeta}
        isActiveOtherCampaignTab={isActiveOtherCampaignTab}
        isShowTabsOtherCampaigns={isShowTabsOtherCampaigns}
      />
    );
  }

  return (
    <Subscription
      className={`fz-comments${empty ? " fz-comments_empty" : ""}`}
      permission="commenting"
      element="Commenting"
    >
      {() => (
        <>
          {!isModal && (
            <>
              <div className="fz-comments__header">
                <h4 className="fz-comments__title">
                  Comments
                  {!isShowTabsOtherCampaigns ? <span className="fz-comments__count">{comments?.length}</span> : null}
                </h4>

                <button type="button" className="fz-comments__collapse" onClick={handleOpenModal}>
                  <Expand />
                </button>
              </div>

              {isShowTabsOtherCampaigns && (
                <Tabs>
                  <TabsItem
                    $isActive={activeCommentsTab === commentsTab.thisCampaign}
                    onClick={handleSetThisCampaignTab}
                  >
                    This campaign{" "}
                    {commentsMeta?.current_campaign_comments_count && (
                      <span>{commentsMeta?.current_campaign_comments_count}</span>
                    )}
                  </TabsItem>
                  <TabsItem
                    $isActive={activeCommentsTab === commentsTab.otherCampaigns}
                    onClick={handleSetOtherCampaignsTab}
                  >
                    Other campaigns{" "}
                    {commentsMeta?.other_campaign_comments_count && (
                      <span>{commentsMeta?.other_campaign_comments_count}</span>
                    )}
                  </TabsItem>
                </Tabs>
              )}

              <CommentBody
                isLoading={isLoading}
                comments={comments}
                isSharedCandidates={isSharedCandidates}
                setEmpty={setEmpty}
                options={options}
                inputValue={inputValue}
                setActiveDropdown={setActiveDropdown}
                activeDropdown={activeDropdown}
                setInputValue={setInputValue}
                setLoggedUser={setLoggedUser}
                loggedUser={loggedUser}
                setIsShowGuestCommentName={setIsShowGuestCommentName}
                isShowGuestCommentName={isShowGuestCommentName}
                setFieldFocus={setFieldFocus}
                isFieldFocus={isFieldFocus}
                isFieldFilled={isFieldFilled}
                setFieldFilled={setFieldFilled}
                visibilityValue={visibilityValue}
                setVisibilityValue={setVisibilityValue}
                activeEdit={activeEdit}
                setActiveEdit={setActiveEdit}
                add={add}
                remove={remove}
                editComment={editComment}
                createShareCandidateUserForComment={createShareCandidateUserForComment}
                isActiveOtherCampaignTab={isActiveOtherCampaignTab}
              />
            </>
          )}
        </>
      )}
    </Subscription>
  );
};

const CommentsContent = ({
  setInputValue,
  inputValue,
  options,
  comments,
  add,
  editComment,
  remove,
  setEmpty,
  isSharedCandidates,
  isFieldFocus,
  setFieldFocus,
  setFieldFilled,
  isFieldFilled,
  activeDropdown,
  setActiveDropdown,
  setVisibilityValue,
  visibilityValue,
  isModal,
  setActiveEdit,
  activeEdit,
  createShareCandidateUserForComment,
  isShowGuestCommentName,
  setIsShowGuestCommentName,
  loggedUser,
  setLoggedUser,
  isActiveOtherCampaignTab,
  isLoading
}) => {
  return (
    <CommentBody
      comments={comments}
      isSharedCandidates={isSharedCandidates}
      setEmpty={setEmpty}
      options={options}
      inputValue={inputValue}
      setActiveDropdown={setActiveDropdown}
      activeDropdown={activeDropdown}
      setInputValue={setInputValue}
      setLoggedUser={setLoggedUser}
      loggedUser={loggedUser}
      setIsShowGuestCommentName={setIsShowGuestCommentName}
      isShowGuestCommentName={isShowGuestCommentName}
      setFieldFocus={setFieldFocus}
      isFieldFocus={isFieldFocus}
      isFieldFilled={isFieldFilled}
      setFieldFilled={setFieldFilled}
      visibilityValue={visibilityValue}
      setVisibilityValue={setVisibilityValue}
      add={add}
      editComment={editComment}
      isLoading={isLoading}
      remove={remove}
      isModal={isModal}
      setActiveEdit={setActiveEdit}
      activeEdit={activeEdit}
      createShareCandidateUserForComment={createShareCandidateUserForComment}
      isActiveOtherCampaignTab={isActiveOtherCampaignTab}
    />
  );
};

export const CommentBody = ({
  setInputValue,
  inputValue,
  options,
  comments,
  add,
  editComment,
  remove,
  setEmpty,
  isSharedCandidates,
  isFieldFocus,
  setFieldFocus,
  setFieldFilled,
  isFieldFilled,
  activeDropdown,
  setActiveDropdown,
  setVisibilityValue,
  visibilityValue,
  isModal,
  activeEdit,
  setActiveEdit,
  createShareCandidateUserForComment,
  isShowGuestCommentName,
  setIsShowGuestCommentName,
  loggedUser,
  setLoggedUser,
  isActiveOtherCampaignTab,
  isLoading
}) => {
  const openPublicCommentModal = useModal(<PublicComment />, "");

  const [list, updateList] = useState([]);

  const { current_user_id, user_avatar, current_user: currentUser } = useContext(AppContext);
  const isLoggedIn = Boolean(current_user_id || loggedUser.id);

  const area = useRef();
  const content = useRef();

  const typeAreaClassNames = cn("fz-comments__typearea", {
    isFocused: isFieldFocus || isFieldFilled,
    isFilled: isFieldFilled
  });

  const typeFieldChange = text => {
    setInputValue(text);
  };

  const onFocusHandle = () => {
    setFieldFocus(true);
  };

  const clearInputValue = () => {
    setInputValue("");
  };

  const handleCancel = () => {
    setInputValue("");
    setFieldFocus(false);
  };

  const handleAddition = () => {
    if (inputValue.length > 0) {
      updateList(prev =>
        prev.concat({
          body: "",
          isPosting: true,
          owner: {
            name: "Posting...",
            id: 0
          }
        })
      );
      clearInputValue();

      add(inputValue, isSharedCandidates ? "public" : visibilityValue).then(comment => {
        setFieldFocus(false);
        setVisibilityValue(options[0].visibility);

        const commentsList = [...list, comment].filter(item => !item.isPosting);
        updateList(commentsList);
      });
    }
  };

  const handleEditComment = () => {
    if (inputValue.length > 0) {
      updateList(prev =>
        prev.concat({
          body: "",
          owner: {
            name: "Posting...",
            id: 0
          }
        })
      );
      clearInputValue();

      editComment(inputValue, visibilityValue, activeEdit.body.id).then(comment => {
        setFieldFocus(false);
        setVisibilityValue(options[0].visibility);
        handleCloseEdit();
        const updatedList = list.map(item => {
          if (item.id === comment.id) {
            return comment;
          }
          return item;
        });
        updateList(updatedList);
      });
    }
  };

  const handleSend = () => {
    if (visibilityValue === "public") {
      openPublicCommentModal({ handleClick: handleAddition });
    } else {
      handleAddition();
    }
  };

  const onEditComment = () => {
    if (visibilityValue === "public") {
      openPublicCommentModal({ handleClick: handleEditComment });
    } else {
      handleEditComment();
    }
  };

  const handleRemoval = id => {
    remove(id).then(() => {
      updateList(prev => prev.filter(comment => comment.id !== id));
    });
  };

  const handleEdit = comment => {
    const findVisibility = options.find(item => comment.visibility.includes(item.visibility));
    setActiveEdit({ isActive: true, body: comment });
    setInputValue(comment.body);
    setVisibilityValue(findVisibility.visibility);
  };

  const handleCloseEdit = () => {
    setActiveEdit({ isActive: false, body: {} });
    setVisibilityValue("");
    setInputValue("");

    if (isFieldFocus) {
      setFieldFocus(false);
    }
  };

  const handleOpenCommentArea = () => {
    setFieldFocus(true);

    if (activeEdit.isActive) {
      setActiveEdit({ isActive: false, body: {} });
      setVisibilityValue("");
      setInputValue("");
    }
  };

  useEffect(() => {
    if (isFieldFocus && area.current && area.current.focusEditor) {
      area.current.focusEditor();
    }
  }, [isFieldFocus]);

  useEffect(() => {
    if (inputValue.length > 0) {
      setFieldFilled(true);
    } else {
      setFieldFilled(false);
    }
  }, [inputValue]);

  useEffect(() => {
    updateList(comments);
  }, [comments]);

  useEffect(() => {
    if (isLoading) {
      updateList([]);
    }
  }, [isLoading]);

  useEffect(() => {
    const inner = content.current;
    if (inner) {
      inner.scrollTo(0, inner.scrollHeight);
    }
  }, [content, list]);

  useEffect(() => {
    if (setEmpty) setEmpty(!(list.length > 0));
  }, [list]);

  const renderComments = () => {
    let lastJobId = 0;

    return list.map((comment, index) => {
      const isShowJob = comment.job?.id !== lastJobId;
      if (isShowJob) {
        lastJobId = comment.job?.id;
      }

      return (
        <React.Fragment key={`message_${index}-${comment.id}`}>
          {isActiveOtherCampaignTab && isShowJob && comment.job ? (
            <Tooltip
              classNamePopup="fz-tooltip_comment"
              tooltipContent={
                <TooltipContent>
                  <TooltipHeading>
                    <JobPhase phase={comment.job?.phase} />

                    {comment.job?.phase !== "draft" && comment.job.days_remaining ? (
                      <PromotionActive>
                        <Hourglass />
                        {daysSince(Number(comment.job.days_remaining))}
                      </PromotionActive>
                    ) : null}
                  </TooltipHeading>
                  <TooltipTitle>{comment.job.title}</TooltipTitle>
                  <TooltipDescription>{comment.job.employer}</TooltipDescription>
                  <TooltipStats>
                    <TooltipStatsItem>
                      <MdRemoveRedEye />
                      {comment.job.views_count} views
                    </TooltipStatsItem>
                    <TooltipStatsItem>
                      <User />
                      {comment.job.applicants_count} applicants
                    </TooltipStatsItem>
                  </TooltipStats>
                </TooltipContent>
              }
              contentStyle={{ margin: "auto 0" }}
              tooltipPosition={["center left"]}
            >
              <Job to={comment.job.path} target="_blank">
                <JobTitle>{comment.job.title}</JobTitle>
                <JobDescription>{comment.job.employer}</JobDescription>
              </Job>
            </Tooltip>
          ) : null}
          <Message
            remove={() => handleRemoval(comment.id)}
            onEdit={() => handleEdit(comment)}
            isEditedComment={comment.edited}
            owned={comment && comment.owner.id === current_user_id}
            isGuestComment={comment && comment.owner.name.includes("(guest)")}
            isEditedMode={activeEdit.isActive && comment.id === activeEdit.body.id}
            isSharedCandidates={isSharedCandidates}
            isActiveOtherCampaignTab={isActiveOtherCampaignTab}
            {...comment}
          >
            {activeEdit.isActive && comment.id === activeEdit.body.id ? (
              <CommentMessageDetail
                handleSend={onEditComment}
                handleCancel={handleCloseEdit}
                typeFieldChange={typeFieldChange}
                onFocusHandle={onFocusHandle}
                inputValue={inputValue}
                area={area}
                isModal={isModal}
                options={options}
                setVisibilityValue={setVisibilityValue}
                isSharedCandidates={isSharedCandidates}
                visibilityValue={visibilityValue}
                setActiveDropdown={setActiveDropdown}
                user_avatar={user_avatar || loggedUser.avatar}
                currentUser={currentUser || { name: loggedUser.name }}
                activeDropdown={activeDropdown}
                isEdit
              />
            ) : null}
          </Message>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <div className="fz-comments__list">
        {isLoading ? (
          <Overlay>
            <Spinner size="40px" primaryFill="secondary200" secondaryFill="transparent" />
          </Overlay>
        ) : null}
        <div ref={content} className="fz-comments__content">
          {list && list.length > 0 ? (
            <>{renderComments()}</>
          ) : (
            <Placeholder isActiveOtherCampaignTab={isActiveOtherCampaignTab} />
          )}
        </div>
      </div>

      {isShowGuestCommentName && !isLoggedIn && (
        <GetGuestCommentName
          createShareCandidateUserForComment={createShareCandidateUserForComment}
          setLoggedUser={setLoggedUser}
          onClose={() => setIsShowGuestCommentName(false)}
          handleOpenCommentArea={handleOpenCommentArea}
        />
      )}

      {isSharedCandidates && !isShowGuestCommentName && !isLoggedIn && (
        <AddComment onClick={() => setIsShowGuestCommentName(true)}>Add comment</AddComment>
      )}

      {(!isSharedCandidates || isLoggedIn) && !isActiveOtherCampaignTab && (
        <>
          {!isFieldFocus || activeEdit.isActive ? (
            <div className={typeAreaClassNames}>
              <ProfileAvatar avatar={user_avatar || loggedUser.avatar} />
              <button type="button" className="fz-comments__active-message" onClick={handleOpenCommentArea}>
                Share your thoughts
              </button>
            </div>
          ) : (
            <>
              {isSharedCandidates && (
                <GuestLabel>
                  <GuestLabelContent>
                    Make sure to review your message before posting. You won`t be able to edit it afterwards.
                  </GuestLabelContent>
                </GuestLabel>
              )}

              <CommentMessageDetail
                handleSend={handleSend}
                handleCancel={handleCancel}
                typeFieldChange={typeFieldChange}
                onFocusHandle={onFocusHandle}
                inputValue={inputValue}
                area={area}
                isModal={isModal}
                options={options}
                setVisibilityValue={setVisibilityValue}
                isSharedCandidates={isSharedCandidates}
                visibilityValue={visibilityValue}
                setActiveDropdown={setActiveDropdown}
                user_avatar={user_avatar || loggedUser.avatar}
                currentUser={currentUser || { name: loggedUser.name }}
                activeDropdown={activeDropdown}
                isShowProfileAvatar
              />
            </>
            /*
              <div className="fz-comments__container">
              <div className="fz-comments__textarea-content">
                <div className="fz-comments__user-info">
                  <ProfileAvatar avatar={user_avatar} />
                  <div className="fz-comments__user-name">{currentUser.name}</div>
                </div>

                <Visibility
                  activeDropdown={activeDropdown}
                  setActiveDropdown={setActiveDropdown}
                  visibilityValue={visibilityValue}
                  setVisibilityValue={setVisibilityValue}
                  options={options}
                  isModal={isModal}
                />
                <div className="fz-comments__type-holder-active">
                  <textarea
                    ref={area}
                    cols={30}
                    rows={30}
                    className="fz-type-control"
                    placeholder="Share your thoughts"
                    value={inputValue}
                    onFocus={onFocusHandle}
                    // onBlur={onBlurHandle}
                    onChange={typeFieldChange}
                  />
                </div>
                <div className="fz-comments__controls">
                  <Button className="fz-comments__cancel" onClick={handleCancel} role="button" tabIndex="0">
                    Cancel
                  </Button>

                  <Button
                    disabled={!inputValue.length}
                    className="fz-comments__post"
                    size="sm"
                    color="blue"
                    onClick={handleSend}
                    role="button"
                    tabIndex="0"
                  >
                    Post
                  </Button>
                </div>
              </div>
            </div>
            */
          )}
        </>
      )}
    </>
  );
};

const CommentMessageDetail = ({
  user_avatar,
  currentUser,
  activeDropdown,
  setActiveDropdown,
  visibilityValue,
  setVisibilityValue,
  isSharedCandidates,
  options,
  isModal,
  area,
  inputValue,
  // onFocusHandle,
  typeFieldChange,
  handleCancel,
  handleSend,
  isShowProfileAvatar,
  isEdit
}) => {
  return (
    <div className="fz-comments__container">
      <div className="fz-comments__textarea-content">
        {isShowProfileAvatar ? (
          <div className="fz-comments__user-info">
            <ProfileAvatar avatar={user_avatar} />
            <div className="fz-comments__user-name">{currentUser?.name}</div>
          </div>
        ) : null}

        <Visibility
          activeDropdown={activeDropdown}
          setActiveDropdown={setActiveDropdown}
          visibilityValue={visibilityValue}
          setVisibilityValue={setVisibilityValue}
          isSharedCandidates={isSharedCandidates}
          options={options}
          isModal={isModal}
        />

        <RichTextTextarea area={area} description={inputValue} onChange={typeFieldChange} />

        <div className="fz-comments__controls">
          <Button className="fz-comments__cancel" onClick={handleCancel} role="button" tabIndex="0">
            Cancel
          </Button>

          <Button
            disabled={!inputValue.length}
            className="fz-comments__post"
            size="sm"
            color="blue"
            onClick={handleSend}
            role="button"
            tabIndex="0"
          >
            {isEdit ? "Save" : "Post"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const Placeholder = ({ isActiveOtherCampaignTab }) => {
  return (
    <div className="fz-comments-placeholder">
      <img alt="empty state" src={emptyStateImage} className="fz-comments-placeholder__icon" />

      <p className="fz-comments-placeholder__empty-title">No comments yet</p>
      <p className="fz-comments-placeholder__empty-description">
        {isActiveOtherCampaignTab
          ? "Comments from other campaigns will appear here"
          : "Be the first to share your thoughts or feedback"}
      </p>
    </div>
  );
};

const Message = ({
  body = "",
  created_at = "",
  visibility,
  owner: { avatar, name = "" } = {},
  owned,
  remove,
  onEdit,
  children,
  isEditedMode,
  isSharedCandidates,
  isEditedComment,
  isGuestComment,
  isActiveOtherCampaignTab
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const openDeleteCommentModal = useModal(
    <DeleteComment handleClick={remove} avatar={avatar} created_at={created_at} name={name} body={body} />,
    ""
  );

  const renderTag = () => {
    if (visibility?.includes(visibilityComment.private)) {
      return (
        <div className="fz-message__tag fz-message__tag_private">
          <MdLockOutline />
          Private
        </div>
      );
    }

    if (visibility?.includes(visibilityComment.public)) {
      return (
        <div className="fz-message__tag fz-message__tag_public">
          <MdPeopleOutline />
          Public
        </div>
      );
    }

    if (visibility?.includes(visibilityComment.team)) {
      return (
        <div className="fz-message__tag fz-message__tag_team">
          <MdPeopleOutline />
          Team
        </div>
      );
    }
  };

  return (
    <div className="fz-message">
      <div className="fz-message__content">
        <div className="fz-message__header">
          <ProfileAvatar avatar={avatar} />

          <div>
            <h6 className="fz-message__name">{name}</h6>
            <span className="fz-message__time">
              {created_at && <>{`${getTimeSpanString(new Date() - new Date(created_at))} `}</>}
              {isEditedComment && "(edited)"}
            </span>
          </div>
          {owned && !isSharedCandidates && !isGuestComment && !isActiveOtherCampaignTab && (
            <Popup
              closeOnDocumentClick
              open={openPopup}
              onClose={() => setOpenPopup(false)}
              onOpen={() => setOpenPopup(true)}
              className="fz-dropdown"
              trigger={
                <button
                  type="button"
                  className="fz-simple-btn fz-simple-btn_more-actions fz-simple-btn_more-actions_indent"
                >
                  <MdMoreHoriz className="fz-simple-btn__icon m-0" />
                </button>
              }
              position="bottom right"
              arrow={false}
            >
              <div className="fz-dropdown__more-content">
                <Button.Simple
                  onClick={() => {
                    onEdit();
                    setOpenPopup(false);
                  }}
                  size="sm"
                  className="m-l-auto fz-simple-btn_width-100 fz-simple-btn_gray"
                >
                  <MdEdit className="fz-dropdown-itm__icon" />
                  Edit comment
                </Button.Simple>
                <Button.Simple
                  onClick={() => remove()}
                  size="sm"
                  color="red"
                  className="m-l-auto fz-simple-btn_width-100 fz-simple-btn_red"
                >
                  <MdDelete className="fz-dropdown-itm__icon" />
                  Delete comment
                </Button.Simple>
              </div>
            </Popup>
          )}

          {!owned && !isSharedCandidates && isGuestComment && !isActiveOtherCampaignTab && (
            <Popup
              closeOnDocumentClick
              open={openPopup}
              onClose={() => setOpenPopup(false)}
              onOpen={() => setOpenPopup(true)}
              className="fz-dropdown"
              trigger={
                <button
                  type="button"
                  className="fz-simple-btn fz-simple-btn_more-actions fz-simple-btn_more-actions_indent"
                >
                  <MdMoreHoriz className="fz-simple-btn__icon m-0" />
                </button>
              }
              position="bottom right"
              arrow={false}
            >
              <div className="fz-dropdown__more-content">
                <Button.Simple
                  onClick={openDeleteCommentModal}
                  size="sm"
                  color="red"
                  className="m-l-auto fz-simple-btn_width-100 fz-simple-btn_red"
                >
                  <MdDelete className="fz-dropdown-itm__icon" />
                  Delete comment
                </Button.Simple>
              </div>
            </Popup>
          )}
        </div>
        {!isEditedMode && (
          <>
            {renderTag()}
            <p
              className="fz-message__text"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body, { ADD_ATTR: ["target"] }) }}
            />
          </>
        )}
        {children ? <>{children}</> : null}
      </div>
    </div>
  );
};

const Visibility = ({
  activeDropdown,
  setActiveDropdown,
  visibilityValue,
  setVisibilityValue,
  options,
  isSharedCandidates,
  isModal
}) => {
  const handleActiveDropdown = () => {
    setActiveDropdown(prev => !prev);
  };

  const handleDropdown = visibility => {
    setActiveDropdown(false);
    setVisibilityValue(visibility);
  };

  useEffect(() => {
    if (!visibilityValue) {
      setVisibilityValue(options[0].visibility);
    }
  }, []);

  return (
    <>
      {!isSharedCandidates && (
        <div className="fz-comments__visibility-settings">
          <span>Visible to:</span>
          <div className="fz-comments__visibility-dropdown" tabIndex={-1} role="button" onClick={handleActiveDropdown}>
            {visibilityValue}
            <MdKeyboardArrowDown />
          </div>
          {activeDropdown && (
            <div
              className={cn(
                "fz-comments__visibility-dropdown-body",
                isModal ? "fz-comments__visibility-dropdown-body_modal" : ""
              )}
            >
              {options.map(({ title, description, Icon, visibility }) => {
                return (
                  <div
                    key={title}
                    className={`fz-comments__visibility-dropdown__item ${
                      visibilityValue === visibility ? "fz-comments__visibility-dropdown__item_active" : ""
                    }`}
                    tabIndex={-1}
                    role="button"
                    onClick={() => handleDropdown(visibility)}
                  >
                    <div className="fz-comments__icon">
                      <Icon />
                    </div>
                    <div>
                      <h4 className="fz-comments__visibility-dropdown__title">{title}</h4>
                      <h4 className="fz-comments__visibility-dropdown__description">{description}</h4>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const CommentsModal = ({
  setInputValue,
  inputValue,
  options,
  comments,
  add,
  editComment,
  remove,
  setEmpty,
  isSharedCandidates,
  isFieldFocus,
  setFieldFocus,
  setFieldFilled,
  isFieldFilled,
  activeDropdown,
  setActiveDropdown,
  setVisibilityValue,
  visibilityValue,
  onClose,
  setActiveEdit,
  activeEdit,
  createShareCandidateUserForComment,
  isShowGuestCommentName,
  setIsShowGuestCommentName,
  loggedUser,
  setLoggedUser,
  isModal,
  activeCommentsTab,
  handleSetOtherCampaignsTab,
  handleSetThisCampaignTab,
  commentsMeta,
  isActiveOtherCampaignTab,
  isShowTabsOtherCampaigns,
  isLoading
}) => {
  const isSetHeight = isFieldFocus && comments?.length >= 3;

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <ModalContainer>
      <ModalPreloader onClick={onClose} />
      <ModalCommentContainer isSetHeight={isSetHeight}>
        <ModalContent>
          <ModalHeading>
            <ModalTitle>
              Comments{" "}
              {!isShowTabsOtherCampaigns ? <span className="fz-comments__count">{comments.length}</span> : null}
            </ModalTitle>
            <button type="button" className="fz-comments__collapse" onClick={onClose}>
              <Collapse />
            </button>
          </ModalHeading>

          {isShowTabsOtherCampaigns && (
            <Tabs>
              <TabsItem $isActive={activeCommentsTab === commentsTab.thisCampaign} onClick={handleSetThisCampaignTab}>
                This campaign{" "}
                {commentsMeta?.current_campaign_comments_count && (
                  <span>{commentsMeta?.current_campaign_comments_count}</span>
                )}
              </TabsItem>
              <TabsItem
                $isActive={activeCommentsTab === commentsTab.otherCampaigns}
                onClick={handleSetOtherCampaignsTab}
              >
                Other campaigns{" "}
                {commentsMeta?.other_campaign_comments_count && (
                  <span>{commentsMeta?.other_campaign_comments_count}</span>
                )}
              </TabsItem>
            </Tabs>
          )}

          <CommentsContent
            isLoading={isLoading}
            comments={comments}
            isSharedCandidates={isSharedCandidates}
            setEmpty={setEmpty}
            options={options}
            inputValue={inputValue}
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            setInputValue={setInputValue}
            setLoggedUser={setLoggedUser}
            loggedUser={loggedUser}
            setIsShowGuestCommentName={setIsShowGuestCommentName}
            isShowGuestCommentName={isShowGuestCommentName}
            setFieldFocus={setFieldFocus}
            isFieldFocus={isFieldFocus}
            isFieldFilled={isFieldFilled}
            setFieldFilled={setFieldFilled}
            visibilityValue={visibilityValue}
            setVisibilityValue={setVisibilityValue}
            add={add}
            editComment={editComment}
            remove={remove}
            isModal={isModal}
            activeEdit={activeEdit}
            setActiveEdit={setActiveEdit}
            createShareCandidateUserForComment={createShareCandidateUserForComment}
            isActiveOtherCampaignTab={isActiveOtherCampaignTab}
          />
        </ModalContent>
      </ModalCommentContainer>
    </ModalContainer>
  );
};

export default Object.assign(Comments, { CommentBody });
