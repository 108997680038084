import React, { useState, useEffect, useMemo } from "react";

import { getCompanyName, isBaronaBrand } from "contexts/init";
import Button from "components/Button";
import { superImpose } from "utils/helpers";
import FilterExpander from "../FilterExpander";
import RangeSlider from "../RangeSlider";
import Experience from "../Experience";
import FilterTags from "../FilterTags";
import Subscription from "../Subscription";
import Checkbox from "../Checkbox";
import ModeOrAnd from "../ModeOrAnd";

const keyMap = {
  location_ids: "locations",
  job_category_ids: "job_categories",
  industry_ids: "industries",
  language: "languages",
  certificate_ids: "certificates",
  certificate: "certificates",
  education_ids: "educations",
  skill_ids: "skills",
  skill: "skills",
  degree_ids: "degrees",
  age_min: "age_min",
  age_max: "age_max",
  experience_max: "experience_max",
  experience_min: "experience_min",
  tag_ids: "tags",
  types: "types",
  seniority_level_ids: "seniority_levels",
  profile: "profile",
  lastActivity: "lastActivity",
  last_activity: "last_activity",
  good_fuzu_profile: "good_fuzu_profile",
  custom_cv: "custom_cv",
  none: "none"
};

const FilterTools = ({
  applyFilter,
  resetAllFilters,
  resetPrevApplied,
  filters = {},
  appliedFilters: currentFilter,
  requiredSkills = [],
  job = 0,
  industry = 0,
  defaultAgeMin = 16,
  defaultAgeMax = 100,
  filterCount = 0,
  tagShortcuts = [],
  loading = false
}) => {
  const [isFilterOpen, setFilterOpen] = useState(false);

  const [allFilters, setFilters] = useState({});

  const [resetAge, setAgeReset] = useState(false);
  const [resetExp, setExpReset] = useState(false);

  // Memorizing previous filter section changed
  const [prevApplied, setApplied] = useState(["none"]);

  // Checking whether the category is non-empty
  const categoryLength = key => (currentFilter[key] ? currentFilter[key].length : 0);
  const categoryIdsLength = key => (currentFilter[key]?.ids ? currentFilter[key].ids?.length : 0);

  const isBarona = isBaronaBrand();
  const companyName = getCompanyName();
  const {
    age_min = defaultAgeMin,
    age_max = defaultAgeMax,
    job_categories = [],
    languages = [],
    locations = [],
    industries = [],
    certificates = [],
    educations = [],
    degrees = [],
    skills = [],
    tags = [],
    types = [],
    seniority_levels = [],
    // hide for this ticket https://app.shortcut.com/fuzu/story/3364/b2b-hide-last-activity-on-fuzu-filter-from-campaigns
    // last_activity: lastActivity = [],
    work_experience_ranges: workExperienceRanges = []
  } = allFilters;

  const newMaxExperienceYear = 100;
  const newMinExperienceYear = 0;

  // Computing expanded state heights
  const expandHeights = useMemo(() => {
    const heights = {};

    Object.keys(currentFilter).map(key => {
      if (Array.isArray(currentFilter[key])) {
        const names = currentFilter[key].map(idString => {
          if (allFilters[keyMap[key]] && allFilters[keyMap[key]].find(item => item.id === +idString)?.name) {
            return allFilters[keyMap[key]].find(item => item.id === +idString).name.length;
          }
          return 0;
        });

        heights[key] =
          names.length > 0
            ? Math.ceil((names.reduce((acc, val) => acc + val) + currentFilter[key].length * 16) / 58, 0)
            : 0;
      } else if (Array.isArray(currentFilter[key]?.ids)) {
        const names = currentFilter[key].ids.map(idString => {
          if (allFilters[keyMap[key]] && allFilters[keyMap[key]].find(item => item.id === +idString)?.name) {
            return allFilters[keyMap[key]].find(item => item.id === +idString).name.length;
          }

          return 0;
        });

        heights[key] =
          names.length > 0
            ? Math.ceil((names.reduce((acc, val) => acc + val) + currentFilter[key].ids.length * 16) / 58, 0)
            : 0;
        // heights[key] = 10;
      } else {
        heights[key] = 0;
      }
    });
    return heights;
  }, [allFilters, currentFilter]);

  // Re-structuring filters received
  useEffect(() => {
    const newFilters = { ...filters };
    prevApplied.forEach(key => {
      newFilters[keyMap[key]] = allFilters[keyMap[key]];
    });

    if (resetAge) {
      newFilters.age_min = defaultAgeMin;
      newFilters.age_max = defaultAgeMax;
    }

    if (resetExp) {
      newFilters.experience_min = newMinExperienceYear;
      newFilters.experience_max = newMaxExperienceYear;
    }

    setFilters({ ...newFilters, locations: filters.locations });
  }, [filters, filters.locations?.length]);

  // Reseting previously applied filter variable
  useEffect(() => {
    setApplied([]);
  }, [resetPrevApplied]);

  // Applying filters
  const handleFilter = (array, key, tag, isIds, isGeoname) => {
    setAgeReset(false);
    setExpReset(false);
    const item = array.find(elem => elem.name === tag);

    if (item) {
      const idString = item.id.toString();

      if (key !== "skill_ids") {
        setApplied([key]);
      }

      if (isIds) {
        applyFilter({
          [key]: {
            ids:
              currentFilter[key] &&
              currentFilter[key].ids &&
              (currentFilter[key].ids.includes(idString) || currentFilter[key].ids.includes(item.id))
                ? currentFilter[key].ids.filter(id => id.toString() !== idString)
                : [...(currentFilter[key]?.ids || []), idString],
            operator: currentFilter[key]?.operator
          }
        });
      } else {
        const activeValue =
          currentFilter[key] && (currentFilter[key].includes(idString) || currentFilter[key].includes(item.id))
            ? currentFilter[key].filter(id => id.toString() !== idString)
            : [...(currentFilter[key] || []), idString];
        const geonameValue = isGeoname ? { geoname_id: activeValue } : {};
        applyFilter({
          [key]: activeValue,
          ...geonameValue
        });
      }
    }
  };

  const handleSlider = (filter, keys) => {
    setAgeReset(false);
    setExpReset(false);
    setApplied(keys);
    applyFilter(filter);
  };

  const handleFilterButtonClick = () => {
    setFilterOpen(!isFilterOpen);
  };

  // Filter reset
  const onReset = (key, isIds) => {
    if (isIds) {
      applyFilter({ [key]: { ids: [], operator: "or" } });
    } else {
      applyFilter({ [key]: [] });
    }
  };

  const onResetRange = (min, max, defMin, defMax) => {
    if (min === "age_min") setAgeReset(true);
    if (min === "experience_min") setExpReset(true);
    applyFilter({
      [min]: 0,
      [max]: defMax
    });
  };

  // Setting up skills priority
  const skillsPriority = useMemo(() => {
    let priority = requiredSkills;
    if (currentFilter.job_category_ids && allFilters.skills) {
      currentFilter.job_category_ids.forEach(jc_id => {
        priority = priority.concat(
          allFilters.skills.filter(skill => skill.job_category_ids.includes(+jc_id)).map(skill => +skill.id)
        );
      });
    }
    return [...new Set(priority)];
  }, [requiredSkills, currentFilter, allFilters]);

  const emptyCheck = useMemo(
    () =>
      Object.values({ ...filters, scopes: null, sorting: null, filters: null })
        .filter(item => item)
        .filter(item => item.length > 0)
        .concat(tagShortcuts).length > 0,
    [filters]
  );

  const handleChangeOrAndMode = (type, isActive) => {
    applyFilter({
      [type]: {
        ids: currentFilter[type]?.ids,
        operator: isActive ? "and" : "or"
      }
    });
  };

  const handleChangeYear = currentId => {
    const idString = String(currentId);
    const key = "work_experience_ranges";

    applyFilter({
      [key]:
        currentFilter[key] && currentFilter[key].includes(idString)
          ? currentFilter[key].filter(id => id.toString() !== idString)
          : [...(currentFilter[key] || []), idString]
    });
  };

  /*
  hide for this ticket https://app.shortcut.com/fuzu/story/3364/b2b-hide-last-activity-on-fuzu-filter-from-campaigns
  const handleApplyLastActivity = id => {
      applyFilter({
        last_activity: id
      });
    };
    */

  const handleApplyGoodFuzuProfile = isActive => {
    applyFilter({
      good_fuzu_profile: isActive
    });
  };

  const handleApplyCustomCv = isActive => {
    applyFilter({
      custom_cv: isActive
    });
  };

  const priorityCountries = ["Kenya", "Nigeria", "Uganda"];
  const priorityLanguages = ["English", "Swahili", "French", "Portuguese", "Chinese", "Spanish", "Arabic"];

  return (
    <Subscription permission="filtering_logic" element="Filtering tools">
      {() => (
        <div className="fz-filterpane">
          {emptyCheck ? (
            <>
              <div className="fz-filterpane__header">
                <h5 className="fz-filterpane__title">Filters</h5>
                <div className="fz-filterpane__controls">
                  <Button.Simple onClick={handleFilterButtonClick} className="fz=filterpane__button">
                    {isFilterOpen ? "Hide all" : "Expand all"}
                  </Button.Simple>
                </div>
              </div>
              <FilterTags.Footer resetAll count={filterCount} onReset={() => resetAllFilters()} />
              <hr className="fz-filterpane__line" />
              {(tags.length > 0 || categoryLength("tag_ids") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.tag_ids}
                    isFilterOpen={isFilterOpen || categoryLength("tag_ids") > 0}
                    sectionTitle="Tags"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        priority={tagShortcuts.map(tag => tag.id)}
                        tags={superImpose(tags, currentFilter.tag_ids)}
                        tagClickHandle={tag => handleFilter(tags.concat(tagShortcuts), "tag_ids", tag)}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryLength("tag_ids")} onReset={() => onReset("tag_ids")} />
                </section>
              )}
              {(types.length > 1 || categoryLength("types") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.types}
                    isFilterOpen={isFilterOpen || categoryLength("types") > 0}
                    sectionTitle="Source"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        tags={superImpose(types, currentFilter.types)}
                        tagClickHandle={tag => handleFilter(types, "types", tag)}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryLength("types")} onReset={() => onReset("types")} />
                </section>
              )}
              {workExperienceRanges && (
                <section className="fz-filter-section">
                  <FilterExpander
                    isFilterOpen={isFilterOpen || !!currentFilter.work_experience_ranges?.length}
                    sectionTitle="Years of experience"
                  >
                    {() => (
                      <Experience
                        activeExperienceRanges={currentFilter.work_experience_ranges}
                        workExperienceRanges={workExperienceRanges}
                        handleChange={id => handleChangeYear(id)}
                      />
                    )}
                  </FilterExpander>
                </section>
              )}
              {(job_categories.length > 1 || categoryLength("job_category_ids") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.job_category_ids}
                    isFilterOpen={isFilterOpen || categoryLength("job_category_ids") > 0}
                    sectionTitle="Job category"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        priority={[job]}
                        tags={superImpose(job_categories, currentFilter.job_category_ids)}
                        tagClickHandle={tag => handleFilter(job_categories, "job_category_ids", tag)}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer
                    count={categoryLength("job_category_ids")}
                    onReset={() => onReset("job_category_ids")}
                  />
                </section>
              )}
              {(industries.length > 1 || categoryLength("industry_ids") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.industry_ids}
                    isFilterOpen={isFilterOpen || categoryLength("industry_ids") > 0}
                    sectionTitle="Industries"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        priority={[industry]}
                        tags={superImpose(industries, currentFilter.industry_ids)}
                        tagClickHandle={tag => handleFilter(industries, "industry_ids", tag)}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryLength("industry_ids")} onReset={() => onReset("industry_ids")} />
                </section>
              )}
              {(locations.length >= 1 || categoryLength("location_ids") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.location_ids}
                    isFilterOpen={isFilterOpen || categoryLength("location_ids") > 0}
                    sectionTitle="Location"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        tags={superImpose(locations, currentFilter.location_ids)}
                        tagClickHandle={tag => handleFilter(locations, "location_ids", tag, false, true)}
                        isShowPriority
                        priorityList={priorityCountries}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryLength("location_ids")} onReset={() => onReset("location_ids")} />
                </section>
              )}
              {(languages.length > 1 || categoryIdsLength("language") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.language}
                    isFilterOpen={isFilterOpen || categoryIdsLength("language") > 0}
                    sectionTitle="Language skills"
                  >
                    {isOpen => (
                      <>
                        <ModeOrAnd
                          isActiveAndMode={currentFilter.language?.operator === "and"}
                          handleChangeOrAndMode={isActive => {
                            handleChangeOrAndMode("language", isActive);
                          }}
                        />
                        <FilterTags
                          isOpen={isOpen}
                          tags={superImpose(languages, currentFilter.language?.ids)}
                          tagClickHandle={tag => handleFilter(languages, "language", tag, true)}
                          isShowPriority
                          priorityList={priorityLanguages}
                          limit={14}
                        />
                      </>
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryIdsLength("language")} onReset={() => onReset("language", true)} />
                </section>
              )}
              {(skills.length > 1 || categoryIdsLength("skill") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.skill}
                    isFilterOpen={isFilterOpen || categoryIdsLength("skill") > 0}
                    sectionTitle="Skills"
                  >
                    {isOpen => (
                      <>
                        <ModeOrAnd
                          isActiveAndMode={currentFilter.skill?.operator === "and"}
                          handleChangeOrAndMode={isActive => {
                            handleChangeOrAndMode("skill", isActive);
                          }}
                        />
                        <FilterTags
                          isOpen={isOpen}
                          limit={20}
                          priority={skillsPriority}
                          tags={superImpose(skills, currentFilter.skill?.ids)}
                          tagClickHandle={tag => handleFilter(skills, "skill", tag, true)}
                        />
                      </>
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryIdsLength("skill")} onReset={() => onReset("skill", true)} />
                </section>
              )}
              {(certificates.length > 1 || categoryIdsLength("certificate") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.certificate}
                    isFilterOpen={isFilterOpen || categoryIdsLength("certificate") > 0}
                    sectionTitle="Certificates"
                  >
                    {isOpen => (
                      <>
                        <ModeOrAnd
                          isActiveAndMode={currentFilter.certificate?.operator === "and"}
                          handleChangeOrAndMode={isActive => {
                            handleChangeOrAndMode("certificate", isActive);
                          }}
                        />
                        <FilterTags
                          isOpen={isOpen}
                          tags={superImpose(certificates, currentFilter.certificate?.ids)}
                          tagClickHandle={tag => handleFilter(certificates, "certificate", tag, true)}
                        />
                      </>
                    )}
                  </FilterExpander>
                  <FilterTags.Footer
                    count={categoryIdsLength("certificate")}
                    onReset={() => onReset("certificate", true)}
                  />
                </section>
              )}
              {(educations.length > 1 || categoryLength("education_ids") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.education_ids}
                    isFilterOpen={isFilterOpen || categoryLength("education_ids") > 0}
                    sectionTitle="Education"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        tags={superImpose(educations, currentFilter.education_ids)}
                        tagClickHandle={tag => handleFilter(educations, "education_ids", tag)}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryLength("education_ids")} onReset={() => onReset("education_ids")} />
                </section>
              )}
              {(seniority_levels.length > 1 || categoryLength("seniority_level_ids") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.seniority_level_ids}
                    isFilterOpen={isFilterOpen || categoryLength("seniority_level_ids") > 0}
                    sectionTitle="Seniority"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        tags={superImpose(seniority_levels, currentFilter.seniority_level_ids)}
                        tagClickHandle={tag => handleFilter(seniority_levels, "seniority_level_ids", tag)}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer
                    count={categoryLength("seniority_level_ids")}
                    onReset={() => onReset("seniority_level_ids")}
                  />
                </section>
              )}
              {(degrees.length > 1 || categoryLength("degree_ids") > 0) && (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.degree_ids}
                    isFilterOpen={isFilterOpen || categoryLength("degree_ids") > 0}
                    sectionTitle="Degree"
                  >
                    {isOpen => (
                      <FilterTags
                        isOpen={isOpen}
                        tags={superImpose(degrees, currentFilter.degree_ids)}
                        tagClickHandle={tag => handleFilter(degrees, "degree_ids", tag)}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer count={categoryLength("degree_ids")} onReset={() => onReset("degree_ids")} />
                </section>
              )}
              {/*
              hide for this ticket https://app.shortcut.com/fuzu/story/3364/b2b-hide-last-activity-on-fuzu-filter-from-campaigns
              {lastActivity.length ? (
                <section className="fz-filter-section">
                  <FilterExpander
                    expandHeight={expandHeights.last_activity}
                    isFilterOpen={
                      isFilterOpen ||
                      (currentFilter.last_activity ? String(currentFilter.last_activity)?.length : 0) > 0
                    }
                    sectionTitle={`Last activity on ${companyName}`}
                  >
                    {() => (
                      <div className="fz-filter-section__profile">
                        {lastActivity.map(({ id, label }) => (
                          <div className="fz-filter-section__profile-item" key={`${id}=${label}`}>
                            <Radio
                              id={id}
                              checked={Number(currentFilter.last_activity) === Number(id)}
                              onChange={() => {
                                handleApplyLastActivity(id);
                              }}
                              labelClass="fz-radio__label_indent"
                            >
                              <h3 className="fz-filter-section__profile-title">{label}</h3>
                            </Radio>
                          </div>
                        ))}
                      </div>
                    )}
                  </FilterExpander>
                  <FilterTags.Footer
                    count={currentFilter.last_activity ? String(currentFilter.last_activity)?.length : 0}
                    onReset={() => onReset("last_activity")}
                  />
                </section>
              ) : null}
              */}
              {/* {(currentFilter.custom_cv || currentFilter.good_fuzu_profile) && ( */}
              <section className="fz-filter-section">
                <FilterExpander
                  expandHeight={expandHeights.good_fuzu_profile || expandHeights.custom_cv}
                  isFilterOpen={
                    isFilterOpen ||
                    [true, "true"].includes(currentFilter.good_fuzu_profile) ||
                    [true, "true"].includes(currentFilter.custom_cv)
                  }
                  sectionTitle={`${companyName} Profile / Custom CV`}
                >
                  {() => (
                    <div className="fz-filter-section__profile">
                      <div className="fz-filter-section__profile-item">
                        <Checkbox
                          labelClass="fz-checkbox__label_empty"
                          checked={[true, "true"].includes(currentFilter.good_fuzu_profile)}
                          onCheck={() =>
                            handleApplyGoodFuzuProfile(
                              [true, "true"].includes(currentFilter.good_fuzu_profile) ? "false" : "true"
                            )
                          }
                        />
                        <div className="fz-filter-section__profile-content">
                          <h3 className="fz-filter-section__profile-title">Good {companyName} Profile</h3>
                          <p className="fz-filter-section__profile-description">80% or higher completion rate</p>
                        </div>
                      </div>
                      <div className="fz-filter-section__profile-item">
                        <Checkbox
                          labelClass="fz-checkbox__label_empty"
                          checked={[true, "true"].includes(currentFilter.custom_cv)}
                          onCheck={() =>
                            handleApplyCustomCv([true, "true"].includes(currentFilter.custom_cv) ? "false" : "true")
                          }
                        />
                        <div className="fz-filter-section__profile-content">
                          <h3 className="fz-filter-section__profile-title">Custom CV</h3>
                          <p className="fz-filter-section__profile-description">
                            User has uploaded a CV file on {companyName}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </FilterExpander>
                <FilterTags.Footer
                  count={
                    Number([true, "true"].includes(currentFilter.good_fuzu_profile)) +
                    Number([true, "true"].includes(currentFilter.custom_cv))
                  }
                  onReset={() => {
                    onReset("good_fuzu_profile");
                    onReset("custom_cv");
                  }}
                />
              </section>

              {age_max !== age_min && !isBarona && (
                <section className="fz-filter-section">
                  <FilterExpander
                    isFilterOpen={isFilterOpen || !!(currentFilter.age_max || currentFilter.age_min)}
                    sectionTitle="Age"
                  >
                    {() => (
                      <RangeSlider
                        loading={loading}
                        reset={resetAge}
                        onChange={({ min, max }) =>
                          handleSlider({ age_min: min, age_max: max }, ["age_min", "age_max"])
                        }
                        minValue={age_min || defaultAgeMin}
                        maxValue={age_max || defaultAgeMax}
                        applied={{
                          min: Number(currentFilter.age_min) || age_min,
                          max: Number(currentFilter.age_max) || age_max
                        }}
                      />
                    )}
                  </FilterExpander>
                  <FilterTags.Footer
                    range={!!(currentFilter.age_max || currentFilter.age_min)}
                    onReset={() => onResetRange("age_min", "age_max", defaultAgeMin, defaultAgeMax)}
                  />
                </section>
              )}
            </>
          ) : filterCount > 0 ? (
            <FilterTags.Footer resetAll count={filterCount} onReset={() => resetAllFilters()} />
          ) : (
            <div className="fz-filterpane__header">
              <div className="fz-filterpane__title">No filters to apply</div>
            </div>
          )}
        </div>
      )}
    </Subscription>
  );
};

export default FilterTools;
