import React, { useState, useRef, useEffect } from "react";

import { TalentList, Talent, ShowAll } from "./styled";

const SelectedTalents = ({ talents }) => {
  const [isShortHeight, setIsShortHeight] = useState(false);
  const [isShowAllButton, setSsShowAllButton] = useState(false);
  const talentList = talents.map(item => <Talent key={item}>{item}</Talent>);
  const ref = useRef();
  const maxHeight = 72;

  const handleShowAll = () => {
    if (isShortHeight) {
      setIsShortHeight(false);
    } else {
      setIsShortHeight(true);
    }
  };

  useEffect(() => {
    if (ref.current && ref.current.clientHeight > maxHeight) {
      setIsShortHeight(true);
      setSsShowAllButton(true);
    }
  }, []);

  return (
    <>
      <TalentList ref={ref} isShortHeight={isShortHeight}>
        {talentList}
      </TalentList>
      {isShowAllButton && (
        <ShowAll onClick={handleShowAll}>{isShortHeight ? `Show all ${talents.length} selected` : "Hide"}</ShowAll>
      )}
    </>
  );
};

export default SelectedTalents;
