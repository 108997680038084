import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

import SortingSelect from "./SortingSelect";
import SimpleSelect from "./SimpleSelect";
import Searchbar from "./Searchbar";
import { useOnclickOutside } from "../../utils/hooks";

const Dropdown = ({
  className = "fz-simple-select",
  selectOptions = [],
  Option = null,
  defaultOption,
  value,
  hide,
  onSelect = e => console.info(e), // eslint-disable-line
  placeholder = "",
  children,
  isSelectObject,
  isMulti = false
}) => {
  const [isOpen, open] = useState(false);
  const wrapper = useRef();

  useEffect(() => {
    if (hide) open(false);
  }, [hide]);

  useOnclickOutside(wrapper, () => open(false), true);

  const handleSelect = e => {
    if (isSelectObject) {
      onSelect(e);
    } else {
      onSelect(e.value);
    }
    open(false);
  };

  const selectStyles = {
    control: provided => ({ ...provided, display: "none" }),
    menu: menu => ({ ...menu, width: "fit-content" })
  };

  if (Option)
    selectStyles.option = () => ({
      padding: 0
    });

  return (
    <div ref={wrapper}>
      {React.cloneElement(children, { onClick: () => open(prev => !prev) })}
      <Select
        defaultValue={defaultOption}
        options={selectOptions}
        formatOptionLabel={Option}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        hideSelectedOptions={false}
        isClearable={false}
        className={className}
        classNamePrefix={className}
        placeholder={placeholder}
        tabSelectsValue={false}
        menuIsOpen={isOpen}
        menuPlacement="auto"
        isMulti={isMulti}
        isSearchable={false}
        styles={selectStyles}
        onChange={handleSelect}
        value={value}
      />
    </div>
  );
};

export default Object.assign(Dropdown, { SortingSelect, SimpleSelect, Searchbar });

export { SortingSelect, SimpleSelect, Searchbar };
