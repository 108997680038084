import React, { useState, useMemo, useContext, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { MdDone, MdForward, MdClose, MdRemoveRedEye, MdChatBubble, MdStar } from "react-icons/md";
import { inject, observer } from "mobx-react";

import AppContext from "contexts/init";
import { capitalize, convertMonths } from "utils/helpers";
import Dropdown from "components/Selects";
import Button from "components/Button";
import cn from "classnames";
import UserCvCircleMatch from "../../../../components/UserCvCircleMatch";
import TagList from "../../../../components/TagList";
import Checkbox from "../../../../components/Checkbox";
import Subscription from "../../../../components/Subscription";
import MessageButton from "../../../../components/MessageButton";
import UserCard from "../../../../components/UserCard";
import VoteCounter from "../../../../components/VoteCounter";
import Comments from "../../../../components/Comments";
import { ExternalCandidateAvatar, ViewApplication } from "./styled";
import { useModal } from "../../../../../utils/hooks";
import InterviewNotesModal from "../InterviewNotesModal";
import AddInterview from "../../../ApplicantCvScreen/components/AddInterview";
import { theme } from "../../../../../b2c/contexts/theme";

const checkAndFix = value => (value ? Number(value).toFixed(0) : 0);

const ApplicantCard = ({
  CampaignsStore: { interviewNotes, isActiveInterviewModal, interviewModalEditId },
  name,
  age,
  avatar,
  country,
  current_position_title,
  current_position_employer,
  highest_education_degree,
  first_name: firstName,
  last_name: lastName,
  id,
  total_match,
  talent_match,
  cv_match,
  type,
  current_phase,
  viewed,
  new: isNew,
  selected,
  select,
  voting: { like_count = 0, dislike_count = 0, is_like, is_dislike } = {},
  tags,
  shortlist,
  reject,
  move,
  options,
  rate,
  message,
  addTag,
  removeTag,
  index,
  campaign,
  campaignEmployerId,
  shortcuts,
  comments_count,
  scope,
  removed,
  profileUnlocked,
  removeTalent,
  user_id,
  conversation,
  unlockUser,
  relevant_experience_months,
  isExternal = true,
  showSaveModalContent,
  isSharedCandidates,
  activeGuestLinkToken,
  remove,
  add,
  editComment,
  getComments,
  appliedFiltersScope,
  createShareCandidateUserForComment,
  createCommentShareCandidate,
  isExternalCandidate,
  canSwitchCandidate,
  details,
  interviewNotesCount,
  setKeepPageInfo,
  isNotShowViewCv,
  systemTags
}) => {
  const { subscription } = useContext(AppContext);
  const rankingEnabled = isSharedCandidates ? true : subscription?.permissions?.job_matching_with_ranking;
  const [showCommentModal, setShowCommentModal] = useState(false);
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "A";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  const initialsName = `${firstInitial}${lastInitial}`;

  const handleShowCommentModal = () => {
    setShowCommentModal(true);
  };

  const handleHideCommentModal = () => {
    setShowCommentModal(false);
  };

  const [likes, setLikes] = useState(like_count);
  const [dislikes, setDislikes] = useState(dislike_count);
  const [isLiked, setIsLiked] = useState(is_like);
  const [isDisliked, setIsDisliked] = useState(is_dislike);
  const { params } = useRouteMatch();

  const [isUnlocked, unlock] = useState(profileUnlocked);

  const tagList = useMemo(() => {
    if ([2, 3].includes(type)) {
      return tags.concat("From talent pool");
    }

    if (type === 4) {
      return tags.concat("Open Application");
    }

    return tags;
  }, [tags, type]);

  const fixedMatches = {
    total: checkAndFix(total_match),
    cv: checkAndFix(cv_match),
    talent: checkAndFix(talent_match)
  };

  const likeCandidate = () => {
    if (isDisliked) {
      setDislikes(dislikes - 1);
      setIsDisliked(false);
    }

    const likes_amount = isLiked ? likes - 1 : likes + 1;
    setLikes(likes_amount);
    setIsLiked(!isLiked);

    rate("like");
  };

  const dislikeCandidate = () => {
    if (isLiked) {
      setLikes(likes - 1);
      setIsLiked(false);
    }

    const dislikes_amount = isDisliked ? dislikes - 1 : dislikes + 1;
    setDislikes(dislikes_amount);
    setIsDisliked(!isDisliked);

    rate("dislike");
  };

  const getInfoboxColor = value => (value > 75 ? "green" : value > 50 ? "yellow" : "red");

  const getExpColor = months => (months ? (months / 12 > 2 ? "green" : months / 12 > 1 ? "yellow" : "red") : "red");

  const pathname = isSharedCandidates
    ? `/share-candidates/${activeGuestLinkToken}/candidates/${id}`
    : `/jobs/${campaign}/applicants/${id}?filters[scope]=${appliedFiltersScope}`;

  const showInterviewNotesModal = useModal(
    <InterviewNotesModal pathname={pathname} candidateName={`Interviews with ${name}`} candidateId={String(id)} />,
    ""
  );

  return (
    <>
      <UserCard
        name={current_phase !== "rejected" && isUnlocked ? name : firstName}
        age={age}
        isExternalCandidate={isExternalCandidate}
        interviewNotesCount={interviewNotesCount}
        showInterviewNotesModal={showInterviewNotesModal}
        canSwitchCandidate={canSwitchCandidate}
        country={country}
        current_position_title={current_position_title}
        current_position_employer={current_position_employer}
        viewed={isSharedCandidates ? false : viewed}
        isSharedCandidates={isSharedCandidates}
        new={isNew}
        id={id}
        profile_unlocked={isUnlocked}
        campaign={campaign}
        selected={selected}
        removed={removed}
        email={details?.email}
        setKeepPageInfo={setKeepPageInfo}
        to={{
          pathname,
          index
        }}
        Badge={
          scope === "all" ? (
            <div className="fz-badge">{capitalize(current_phase)}</div>
          ) : (
            isNew && <div className="fz-badge">NEW</div>
          )
        }
        Checkarea={
          isSharedCandidates ? null : (
            <div className="fz-panel__checkarea">
              <Checkbox checked={selected} onCheck={select} />
            </div>
          )
        }
        UserAvatar={
          <>
            <div className="fz-applicant__aside">
              {isExternalCandidate ? (
                <ExternalCandidateAvatar>{initialsName}</ExternalCandidateAvatar>
              ) : (
                <>
                  <UserCvCircleMatch
                    isShowCircleMatch={typeof total_match === "number" && !isSharedCandidates}
                    avatar={avatar}
                    percentValue={rankingEnabled ? fixedMatches.total : 0}
                  />
                  {rankingEnabled && typeof total_match === "number" && !isSharedCandidates ? (
                    <div className="fz-applicant-match-info">
                      <p className="fz-applicant-match-info__title">{`${fixedMatches.total} %`}</p>
                      <p className="fz-applicant-match-info__text">Match</p>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </>
        }
        Details={
          !isExternalCandidate &&
          rankingEnabled && (
            <>
              {!isSharedCandidates && (
                <div className={`fz-infobox fz-infobox_${getInfoboxColor(fixedMatches.cv)} fz-infobox_thin`}>
                  <div className="fz-infobox__title">{`${fixedMatches.cv} %`}</div>
                  <div className="fz-infobox__info">CV</div>
                </div>
              )}

              {talent_match && !isSharedCandidates ? (
                <div className={`fz-infobox fz-infobox_${getInfoboxColor(fixedMatches.talent)} fz-infobox_thin`}>
                  <div className="fz-infobox__title">{`${fixedMatches.talent} %`}</div>
                  <div className="fz-infobox__info">Talent</div>
                </div>
              ) : (
                <></>
              )}
              {relevant_experience_months ? (
                <div className={`fz-infobox fz-infobox_${getExpColor(relevant_experience_months)} fz-infobox_thin`}>
                  <div className="fz-infobox__title">{convertMonths(relevant_experience_months)}</div>
                  <div className="fz-infobox__info">Relevant exp.</div>
                </div>
              ) : (
                <></>
              )}
              {highest_education_degree ? (
                <div className="fz-infobox fz-infobox_thin">
                  <div className="fz-infobox__title">{highest_education_degree}</div>
                  <div className="fz-infobox__info">Education</div>
                </div>
              ) : (
                <></>
              )}
            </>
          )
        }
        Actions={
          <>
            <div className="fz-panel__actions">
              {!isSharedCandidates && (
                <>
                  {current_phase === "applied" && (
                    <Subscription.Upgrade permission="campaign_stages" paymentCheck>
                      <Button
                        icon={<MdDone />}
                        className="fz-btn_small-padding"
                        size="sm"
                        color="gray"
                        onClick={shortlist}
                        role="button"
                        tabIndex="0"
                      >
                        Shortlist
                      </Button>
                    </Subscription.Upgrade>
                  )}
                  <Dropdown selectOptions={options} onSelect={move}>
                    <Subscription.Upgrade permission="campaign_stages" paymentCheck>
                      <Button
                        className="fz-btn_small-padding"
                        icon={<MdForward />}
                        size="sm"
                        color="gray"
                        role="button"
                        tabIndex="0"
                      >
                        Move to
                      </Button>
                    </Subscription.Upgrade>
                  </Dropdown>
                  {!isExternalCandidate && !params.folder && (
                    <Subscription.Upgrade
                      paymentCheck
                      permission="headhunting_talent_pipeline"
                      element="Talent pipeline"
                    >
                      <Button
                        className="fz-btn_small-padding"
                        size="sm"
                        color="gray"
                        onClick={showSaveModalContent}
                        icon={<MdStar />}
                        role="button"
                        tabIndex="0"
                      >
                        Save talent
                      </Button>
                    </Subscription.Upgrade>
                  )}
                  <div className="fz-panel__actions-separator fz-panel__actions-separator_small-indent" />
                  {current_phase !== "rejected" && type > 2 && !isExternalCandidate && (
                    <Subscription.Upgrade permission="campaign_stages" paymentCheck>
                      <Button
                        className="fz-btn_small-padding"
                        icon={<MdClose />}
                        size="sm"
                        color="warning"
                        onClick={reject}
                        role="button"
                        tabIndex="0"
                      >
                        Reject
                      </Button>
                    </Subscription.Upgrade>
                  )}
                  {type > 1 && (
                    <Button
                      className="fz-btn_small-padding"
                      icon={<MdClose />}
                      size="sm"
                      color="warning"
                      onClick={removeTalent}
                      role="button"
                      tabIndex="0"
                    >
                      Remove
                    </Button>
                  )}
                  {!isExternalCandidate && !isExternal && (
                    <Subscription.Upgrade permission="messaging" paymentCheck>
                      <div>
                        <MessageButton
                          isUnlocked={isUnlocked}
                          messageUrl={message()}
                          campaignEmployerId={campaignEmployerId}
                          unlockHandler={() => {
                            unlock(true);
                            return unlockUser(user_id, id, false, campaignEmployerId);
                          }}
                          type="candidate"
                          users={[{ id, name: `${firstName} ${lastName}`, conversation }]}
                          campaign={campaign}
                        />
                      </div>
                    </Subscription.Upgrade>
                  )}
                </>
              )}
              {!isExternalCandidate && !isNotShowViewCv && (
                <Link
                  to={{
                    pathname: isSharedCandidates
                      ? `/share-candidates/${activeGuestLinkToken}/candidates/${id}`
                      : `/jobs/${campaign}/applicants/${id}?filters[scope]=${appliedFiltersScope}`,
                    index
                  }}
                >
                  <Button className="fz-btn_small-padding" icon={<MdRemoveRedEye />} size="sm" color="gray" tag="span">
                    View CV
                  </Button>
                </Link>
              )}
            </div>

            {isSharedCandidates ? (
              <ViewApplication
                to={pathname}
                color={theme.primaryButtonLabelColor}
                background={theme.primaryButtonBackground}
              >
                View application
              </ViewApplication>
            ) : null}
          </>
        }
        Footer={
          <>
            <div className={cn("fz-panel__footer", { "fz-panel__footer_grey": isSharedCandidates })}>
              <div className={cn("fz-applicant-footer", isSharedCandidates ? "fz-applicant-footer_flex-end" : "")}>
                {!isSharedCandidates && (
                  <>
                    <TagList
                      shortcuts={shortcuts}
                      tags={isExternalCandidate && !tagList.includes("Sourced") ? tagList.concat("Sourced") : tagList}
                      addTag={addTag}
                      removeTag={removeTag}
                      systemTags={systemTags}
                    />
                    <VoteCounter
                      voting={{
                        like_count: likes,
                        dislike_count: dislikes,
                        is_like: isLiked,
                        is_dislike: isDisliked
                      }}
                      onLike={() => likeCandidate(id)}
                      onDislike={() => dislikeCandidate(id)}
                    />
                  </>
                )}
                <Subscription.Upgrade permission={!isSharedCandidates ? "commenting" : ""}>
                  <Button
                    icon={<MdChatBubble />}
                    className="ml-2"
                    size="sm"
                    color={comments_count > 0 ? "light-blue" : "gray"}
                    onClick={handleShowCommentModal}
                    tabIndex="0"
                    role="button"
                  >
                    {comments_count || 0}
                  </Button>
                </Subscription.Upgrade>
              </div>
            </div>
          </>
        }
      />
      {showCommentModal && (
        <CommentsModal
          getComments={isOtherCampaigns => getComments(id, isOtherCampaigns)}
          add={(body, visibility) => {
            return add(id, body, visibility);
          }}
          editComment={(body, visibility, commentId) => {
            return editComment(id, body, visibility, commentId);
          }}
          remove={commentId => {
            return remove(id, commentId);
          }}
          isSharedCandidates={isSharedCandidates}
          createShareCandidateUserForComment={createShareCandidateUserForComment}
          createCommentShareCandidate={createCommentShareCandidate}
          toggle={handleHideCommentModal}
        />
      )}

      {isActiveInterviewModal && (
        <AddInterview
          candidateName={name}
          candidateId={id}
          numberOfInterview={interviewNotes.length + 1}
          interviewItem={
            interviewNotes.length && interviewModalEditId
              ? interviewNotes.find(item => item.id === interviewModalEditId)
              : {}
          }
        />
      )}
    </>
  );
};

const CommentsModal = ({
  getComments,
  editComment,
  toggle,
  createShareCandidateUserForComment,
  createCommentShareCandidate,
  isSharedCandidates,
  remove,
  add,
  ...rest
}) => {
  const [comments, setComments] = useState([]);
  const [commentsMeta, setCommentsMeta] = useState({});

  useEffect(() => {
    getComments().then(response => {
      setComments(response.list);
      setCommentsMeta(response.meta);
    });
  }, []);

  const handleGetComments = isOtherCampaigns => {
    return getComments(isOtherCampaigns).then(response => {
      setComments(response.list);
      setCommentsMeta(response.meta);
    });
  };

  const handleAddComment = (id, body, visibility) => {
    return add(id, body, visibility).then(comment => {
      if (!isSharedCandidates) {
        setCommentsMeta(prev => ({
          ...prev,
          current_campaign_comments_count: prev.current_campaign_comments_count + 1
        }));
      }

      return comment;
    });
  };

  const handleRemoveComment = (id, commentId) => {
    return remove(id, commentId).then(() => {
      if (!isSharedCandidates) {
        setCommentsMeta(prev => ({
          ...prev,
          current_campaign_comments_count: prev.current_campaign_comments_count - 1
        }));
      }
    });
  };

  return (
    <Comments
      isModal
      getCandidateComments={handleGetComments}
      toggleClose={toggle}
      comments={comments}
      isRemovePublic={false}
      isSharedCandidates={isSharedCandidates}
      add={handleAddComment}
      remove={handleRemoveComment}
      editComment={editComment}
      createShareCandidateUserForComment={createShareCandidateUserForComment}
      createCommentShareCandidate={createCommentShareCandidate}
      commentsMeta={commentsMeta}
      isShowTabsOtherCampaigns={!isSharedCandidates}
      {...rest}
    />
  );
};

ApplicantCard.Placeholder = UserCard.Placeholder;

export default inject("CampaignsStore")(observer(ApplicantCard));
