import React from "react";
import { observer, inject } from "mobx-react";
import { MdSearch, MdPinDrop, MdPermIdentity } from "react-icons/md";

import Button from "components/Button";
import ModalConsumer from "contexts/modal";
import Carousel from "../../../../components/Carousel";
import CampaignsModalContent from "../../../../components/CampaignsModalContent";

const BrowseCampaigns = inject("store")(
  observer(({ store: { campaigns = [], getCampaignFilters } }) => {
    return campaigns.length > 0 ? (
      <ModalConsumer>
        {({ toggle }) => (
          <>
            <div className="flex space-between">
              <h2 className="fz-heading-title mt-3">Search by active job</h2>
              <Button.Simple
                onClick={() =>
                  toggle(<CampaignsModalContent add={false} close={() => toggle()} />, "Search by active job")
                }
              >
                View all
              </Button.Simple>
            </div>
            <Carousel>
              {[...new Array(Math.ceil(campaigns.length / 3)).keys()].map(i => (
                <div
                  key={`category_${i}`}
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 32%)",
                    gridGap: "2%"
                  }}
                >
                  {campaigns.slice(i * 3, i * 3 + 3).map(campaign => (
                    <CampaignItem
                      key={campaign.id}
                      getCampaignFilters={() => getCampaignFilters(campaign.id)}
                      {...campaign}
                    />
                  ))}
                </div>
              ))}
            </Carousel>
          </>
        )}
      </ModalConsumer>
    ) : null;
  })
);

const CampaignItem = ({ location, title, owner, getCampaignFilters }) => {
  return (
    <div role="button" tabIndex={-1} className="fz-pane campaign" onClick={() => getCampaignFilters()}>
      <div className="info ml-1" style={{ maxWidth: "90%" }}>
        <h4 className="mt-1 mb-1 overflow-hidden overflow-ellipsis">{title}</h4>

        <div className="fz-data-row">
          {location && (
            <p className="fz-info-string mr-1">
              <MdPinDrop className="fz-info-string__icon" />
              <span className="fz-info-string__text">{location}</span>
            </p>
          )}
          {owner?.name && (
            <p className="fz-info-string">
              <MdPermIdentity className="fz-info-string__icon" />
              <span className="fz-info-string__text">{owner.name}</span>
            </p>
          )}
        </div>
      </div>

      <Button.Iconed>
        <MdSearch fill="#408bfc" />
      </Button.Iconed>
    </div>
  );
};

export default BrowseCampaigns;
