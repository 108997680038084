import React, { useContext, useMemo } from "react";
import { MdPinDrop, MdBusiness, MdPermIdentity, MdLink, MdCheck, MdWarning } from "react-icons/md";
import { InfoCircle, Link, Hourglass } from "@styled-icons/boxicons-regular";
import cn from "classnames";

import AppContext from "contexts/init";
import { promotionStatuses } from "b2b/constants/campaign";
import { daysSince } from "utils/helpers";
import { TABS } from "constants/tabs";
import Tooltip from "components/Tooltip";
import TabNavigation from "../../../../components/TabNavigation";
import FastApply from "../../../../components/FastApply";
import {
  TopContent,
  PromotionActive,
  PromotionExpired,
  PromotionContent,
  TooltipContent,
  tooltipContentStyles
} from "./styled";
import JobPhase from "../JobPhase";

const JobDetailHeading = ({
  id = "",
  tab = "",
  phase = "active",
  left,
  employer = "Employer",
  location = "Location",
  owner = "Owner",
  title = "Campaign Title",
  applicantCount = 0,
  isExternal,
  isFastApply,
  handleHowFastApplyWork,
  activeGuestLink,
  isSharedCandidates,
  promotionStatus
}) => {
  const { is_current_user_external_collaborator, current_brand, feature_toggles } = useContext(AppContext);
  const isEnablePromotions = feature_toggles?.employer_promotions;

  const renderPromotionStatus = () => {
    if (promotionStatus === promotionStatuses.failed) {
      return (
        <PromotionContent>
          <PromotionExpired>
            <MdWarning /> Job board issues
          </PromotionExpired>
          <Tooltip
            classNamePopup="fz-tooltip-content_modal"
            tooltipPosition={["right center"]}
            contentStyle={tooltipContentStyles}
            className="tooltipTrigger"
            tooltipContent={
              <TooltipContent>
                Post to this job board was added via API and cannot be edited directly in the app.
              </TooltipContent>
            }
          >
            <InfoCircle />
          </Tooltip>
        </PromotionContent>
      );
    }

    if (promotionStatus === promotionStatuses.active) {
      return (
        <PromotionActive>
          <MdCheck />
          Posted on job boards
        </PromotionActive>
      );
    }

    return null;
  };

  const isShared = useMemo(() => {
    return Boolean(activeGuestLink?.token) && !isSharedCandidates;
  }, [activeGuestLink?.token]);

  const tabItems = () => {
    let tabs;

    if (isSharedCandidates) {
      tabs = [
        {
          name: "Candidates",
          to: `/share-candidates/${id}`,
          count: applicantCount,
          active: tab === TABS.APPLICANTS || tab === ""
        },
        { name: "Job details", to: `/share-candidates/${id}/details`, active: tab === TABS.DETAILS }
      ];
    } else {
      tabs = [
        { name: "Candidates", to: `/jobs/${id}/applicants`, count: applicantCount, active: tab === TABS.APPLICANTS }
      ];

      if (!isExternal && current_brand?.slug !== "jkuates") {
        tabs = tabs.concat([
          {
            name: "Recommended talent",
            to: `/jobs/${id}/headhunt`,
            count: 0,
            active: tab === TABS.HEADHUNT,
            permission: "headhunted_talent"
          }
        ]);
      }

      if (!is_current_user_external_collaborator) {
        tabs = tabs.concat([{ name: "Job details", to: `/jobs/${id}/details`, active: tab === TABS.DETAILS }]);

        if (isEnablePromotions) {
          tabs = tabs.concat([{ name: "Promotions", to: `/jobs/${id}/promotions`, active: tab === TABS.promotions }]);
        }

        tabs = tabs.concat([{ name: "Updates", to: `/jobs/${id}/updates`, active: tab === TABS.UPDATES }]);
      }
    }

    return tabs;
  };

  return (
    <div className="fz-page-heading">
      <div className={cn("fz-page-wide-container", isSharedCandidates ? "fz-page-wide-container_guest" : "")}>
        <div className="fz-page-flex-heading">
          <div>
            <div className="fz-status-row">
              <JobPhase phase={phase} />
              {phase !== "draft" && left ? (
                <PromotionActive>
                  <Hourglass />
                  {daysSince(Number(left))}
                </PromotionActive>
              ) : null}

              {renderPromotionStatus()}
              {isShared && (
                <PromotionActive>
                  <Link />
                  Shared
                </PromotionActive>
              )}
            </div>

            <TopContent>
              <h1 className="fz-title mt-2 mb-2">{title}</h1>
            </TopContent>

            <div className="fz-data-row">
              <p className="fz-info-string mr-2">
                <MdPinDrop className="fz-info-string__icon" />
                <span className="fz-info-string__text">{location}</span>
              </p>
              <p className="fz-info-string mr-2">
                <MdBusiness className="fz-info-string__icon" />
                <span className="fz-info-string__text">{employer}</span>
              </p>
              <p className="fz-info-string mr-2">
                <MdPermIdentity className="fz-info-string__icon" />
                <span className="fz-info-string__text">{owner}</span>
              </p>

              {isSharedCandidates && activeGuestLink?.owner && (
                <p className="fz-info-string">
                  <MdLink className="fz-info-string__icon" />
                  <span className="fz-info-string__label mr-1">Shared by </span>
                  <span className="fz-info-string__text">{activeGuestLink.owner.name}</span>
                </p>
              )}
            </div>
          </div>
          <div>
            <FastApply isShow={isFastApply} onClick={handleHowFastApplyWork} />
          </div>
        </div>

        <TabNavigation className="mt-4" tabItems={tabItems()} />
      </div>
    </div>
  );
};

export default JobDetailHeading;
