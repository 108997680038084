import React, { useState, useEffect } from "react";
import { Col, Row } from "react-grid-system";

import { Text, Button, List, Input, Textarea, SwitchInput } from "b2c/components/Core";
import { InfoCircle } from "@styled-icons/boxicons-regular";
import { Modal, Banner, DaysContent, ButtonsRow, Tag, TagContent } from "./styled";
import { getCompanyName } from "../../../../../contexts/init";
import { Info } from "../../../JobDetailScreen/components/RejectionMessagesModalContent/styled";

const InviteCandidate = ({
  close,
  handleRemovalShortlist,
  assignments,
  candidates,
  selectedCandidatesIds,
  candidateName,
  jobTitle,
  employerName,
  shortlistWithAssignments,
  handleRemoval,
  selectedCandidates,
  navigationIds
}) => {
  const [text, setText] = useState("");
  const [days, setDays] = useState("10");
  const [replyMessage, setReplyMessage] = useState(false);

  const selectedCandidatesLength = selectedCandidates?.length;
  const navigationIdsLength = navigationIds?.length;
  const isSelectedAllNavigation =
    selectedCandidatesLength && navigationIdsLength && selectedCandidatesLength === navigationIdsLength;
  const selectedCandidatesOther =
    candidates?.length && navigationIdsLength ? navigationIdsLength - candidates?.length : 0;
  const existSelectedCandidates = candidates.filter(item => selectedCandidatesIds.includes(item.id));
  const externalCandidates = existSelectedCandidates.filter(item => Boolean(item.prospect_id));
  const isOnlyExternalCandidates = externalCandidates.length === existSelectedCandidates.length;

  const handleShortlistWithAssignments = () => {
    const externalCandidatesIds = externalCandidates.map(item => item.id);
    const rejectedIds = externalCandidatesIds.length
      ? selectedCandidatesIds.filter(item => !externalCandidatesIds.includes(item))
      : selectedCandidatesIds;

    handleRemoval(
      shortlistWithAssignments({
        candidate_ids: rejectedIds,
        assignments_details: {
          days_to_complete: Number(days),
          message: text,
          allow_to_replay: replyMessage
        }
      }).then(() => {
        handleRemovalShortlist(externalCandidatesIds);
      }),
      selectedCandidatesIds,
      "shortlisted"
    );
    close();
  };

  const onShortlist = () => {
    handleRemovalShortlist(selectedCandidatesIds);
    close();
  };

  const currentDate = new Date();
  const date = currentDate.setDate(currentDate.getDate() + Number(days));
  const formattedDate = new Date(date).toLocaleString("en-US", { month: "long", year: "numeric", day: "numeric" });
  const messageName =
    existSelectedCandidates.length > 1
      ? "#applicant_first_name"
      : candidateName || existSelectedCandidates[0].user.name;
  const message = `Dear ${messageName}, \n\nThank you again for applying for the ${jobTitle} position at ${employerName}. As part of the selection process, we ask the shortlisted candidates to complete short assignments. You are one of the lucky candidates, so congratulations! \n\nBelow, you will find the assignments you need complete to give us a bit more information about your skills and personality. Please make sure to read the instructions carefully as they will help you complete the assignments successfully. \n\nThe deadline for completing the assignment is ${formattedDate}. \n\nGood luck with the assignment! I am looking forward to reviewing your responses. \n\nBest regards,\n${employerName} HR`;

  useEffect(() => {
    setText(message);
  }, [days]);

  return (
    <Modal>
      <Text fontSize="14px" lineHeight="140%" color="#707378" margin="0 0 28px 0" style={{ display: "block" }}>
        Ask candidate to complete tests and other assignments or shortlist them immediately. We will let you know when
        the candidates have completed their assignments.
      </Text>

      <Text margin="0" fontWeight="600">
        Recipients
      </Text>

      <TagContent>
        {existSelectedCandidates.map(item => {
          return (
            <Tag key={item.id} isNotActive={item.prospect_id}>
              {item?.user?.first_name}
            </Tag>
          );
        })}
      </TagContent>

      {isSelectedAllNavigation && Number(selectedCandidatesOther) ? (
        <Banner key={selectedCandidatesOther}> {`+ ${selectedCandidatesOther} more`}</Banner>
      ) : null}

      {externalCandidates.length ? (
        <Info>
          <InfoCircle />
          <div>
            <p>
              {isOnlyExternalCandidates && externalCandidates.length === 1 ? (
                <>The selected recipient is an external candidate.</>
              ) : (
                <>
                  Your selection includes <span>{externalCandidates.length}</span> external candidate.
                </>
              )}
            </p>
            <p>
              Sending assignments to external candidates isn`t supported yet. They`ll still be moved to the
              <span> Shortlisted</span> stage.
            </p>
          </div>
        </Info>
      ) : null}

      {assignments && (
        <>
          <Text
            fontSize="14px"
            lineHeight="100%"
            color="#333333"
            margin="0 0 8px 0"
            style={{ display: "block" }}
            fontWeight="500"
          >
            Assignments
          </Text>

          <List style={{ padding: "0", margin: 16 }}>
            {assignments.map(assigment => (
              <List.Item key={assigment} bulletColor="black200">
                {assigment}
              </List.Item>
            ))}
          </List>
        </>
      )}

      <DaysContent>
        <div>
          <Text
            fontSize="14px"
            lineHeight="100%"
            color="#333333"
            margin="0 0 8px 0"
            style={{ display: "block" }}
            fontWeight="500"
          >
            Days to complete
          </Text>
          <Text fontSize="14px" lineHeight="140%" color="#707378" margin="0" style={{ display: "block" }}>
            Set a deadline for a candidate to complete the assignment
          </Text>
        </div>
        <Input
          width="80px"
          inputHeight="42px"
          padding="5px 6px 5px 12px"
          value={days}
          onChange={event => setDays(event.target.value)}
          inputColor="black200"
          margin="0"
          type="number"
        />
      </DaysContent>

      <Textarea
        borderRadius="2%"
        style={{ outline: "none", width: "600px" }}
        value={text}
        onChange={event => setText(event.target.value)}
        label="Message"
        name="message"
        inputHeight="350px"
      />
      <Row
        style={{
          margin: "24px -12px"
        }}
      >
        <Col>
          <Text margin="0 0 4px" fontWeight="600">
            Allow candidate to reply
          </Text>
          <span
            style={{
              display: "inline-block",
              color: "grey",
              width: "487px"
            }}
          >
            Candidate will be able to reply to this message in their {getCompanyName()} account
          </span>
        </Col>
        <Col>
          <SwitchInput
            style={{ outline: "none", display: "block", marginLeft: "auto" }}
            aria-label="Allow candidate to reply to this message"
            checked={replyMessage}
            onChange={() => setReplyMessage(prev => !prev)}
          />
        </Col>
      </Row>
      <ButtonsRow>
        <Button
          onClick={close}
          height="36px"
          theme="grey300"
          style={{ color: "black", background: "#EEE", marginRight: "auto", width: "130px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onShortlist}
          height="36px"
          theme="white"
          style={{ borderRadius: "8px", color: "#4690FF", marginRight: "12px", border: "1px solid #4690FF" }}
          bordered
        >
          Shortlist without assignments
        </Button>
        {!isOnlyExternalCandidates && (
          <Button
            onClick={handleShortlistWithAssignments}
            height="36px"
            theme="secondary200"
            style={{ borderRadius: "8px", color: "#fff" }}
          >
            Shortlist
          </Button>
        )}
      </ButtonsRow>
    </Modal>
  );
};

export default InviteCandidate;
