import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { MdSearch } from "react-icons/md";

import { useOutsideWindow, useOnclickOutside } from "utils/hooks";
import Button from "../Button";

const DefaultTrigger = () => (
  <Button size="xs" color="blue">
    more
  </Button>
);

const Searchbar = ({
  Trigger = DefaultTrigger,
  options = [],
  priority = [],
  position = "bottom right",
  onSelect,
  open,
  onClose,
  onOpen,
  isNewPopup,
  currentActiveActions,
  setCurrentActiveActions,
  prompt,
  refContainer
}) => {
  const [value, setValue] = useState(false);
  const dropdown = useRef();

  const toggleActiveActions = event => {
    if (currentActiveActions === prompt) {
      if (event.target.tagName.toLowerCase() !== "input") {
        setCurrentActiveActions("");
      }
    } else {
      setCurrentActiveActions(prompt);
    }
  };

  useEffect(() => {
    setValue(false);
  }, [value]);

  const handleClose = () => {
    if (onClose) {
      onClose(false);
    }
  };

  useEffect(() => {
    const listener = event => {
      event.stopPropagation();
      if (refContainer?.current && !refContainer.current.contains(event.target)) {
        setCurrentActiveActions("");
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [refContainer]);

  const handleClick = name => {
    onSelect(name);
    setValue(true);

    handleClose();
  };

  const handleOpen = () => {
    if (onOpen) {
      onOpen(true);
    }
  };

  return (
    <>
      {isNewPopup ? (
        <div
          tabIndex="-1"
          role="button"
          className="fz-dropdown fz-dropdown__new-container fz-dropdown__new-container_select"
          onClick={toggleActiveActions}
        >
          <button type="button" className="fz-simple-btn fz-simple-btn_lightgray">
            <Trigger />
          </button>
          <div ref={dropdown} className="fz-dropdown__additional-content">
            {currentActiveActions === prompt && (
              <Menu
                options={
                  !priority?.length ? options : options.sort((a, b) => (priority.includes(a) ? (a < b ? -1 : 0) : 1))
                }
                priority={priority}
                handleClick={name => {
                  handleClick(name);
                  setCurrentActiveActions("");
                }}
                callback={() => {
                  setValue(true);
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <Popup
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          className="fz-dropdown"
          closeOnDocumentClick
          closeOnEscape
          trigger={() => (
            <span className="fz-dropdown__trigger" aria-haspopup="listbox" role="button" tabIndex="0">
              <Trigger />
            </span>
          )}
          position={position}
          arrow={false}
        >
          {close => (
            <Menu
              options={
                !priority?.length ? options : options.sort((a, b) => (priority.includes(a) ? (a < b ? -1 : 0) : 1))
              }
              priority={priority}
              handleClick={name => {
                handleClick(name);
                close();
              }}
              callback={() => setValue(true)}
            />
          )}
        </Popup>
      )}
    </>
  );
};

const Menu = ({ options, priority = [], handleClick, callback }) => {
  const [filteredOptions, filterOut] = useState(options);

  const handleSearch = value => {
    filterOut(options.filter(item => item.toLowerCase().includes(value.toLowerCase())));
  };

  const ddRef = useRef();

  useOutsideWindow(ddRef, callback);
  useOnclickOutside(ddRef, callback);

  return (
    <div ref={ddRef} className="fz-dropdown-nav fz-dropdown-nav_with-search" tabIndex="-1" role="listbox">
      {options.length > 8 && (
        <div className="fz-dropdown-nav__header">
          <div className="fz-std-input fz-std-input_sm">
            <div className="fz-std-input__field">
              <MdSearch className="fz-std-input__icon" />
              <input
                type="text"
                onChange={e => handleSearch(e.target.value)}
                className="fz-std-input__control"
                placeholder="Type here"
              />
            </div>
          </div>
        </div>
      )}

      <div className="fz-dropdown-nav__list">
        {filteredOptions.map((name, index) => (
          <div
            role="button"
            aria-label="close"
            tabIndex={-1}
            key={`filter_item_${name}_${index}`}
            className={`fz-dropdown-itm${priority.includes(name) ? " fz-dropdown-itm_highlight" : ""}`}
            onClick={() => handleClick(name)}
          >
            <span className="fz-dropdown-itm__text">{name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Searchbar;
