import React from "react";
import emptyQuestions from "images/b2b/emptyQuestions.png";

import { Container, Content, Image, Description } from "./styled";

const Empty = ({ children }) => {
  return (
    <Container>
      <Content>
        <Image>
          <img src={emptyQuestions} alt="empty" />
        </Image>
        <Description>No questions have been added yet</Description>
        {children}
      </Content>
    </Container>
  );
};

export default Empty;
