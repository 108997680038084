import React from "react";

import { Container, Title, Description, List } from "./styled";
import { getCompanyName } from "../../../../../contexts/init";

const Disclaimer = () => {
  return (
    <Container>
      <Title>Disclaimer</Title>
      <Description>
        By adding screening questions and clicking `Next`, you agree, on behalf of the company that will post this
        campaign, that:
      </Description>
      <List>
        <li>
          You`re responsible that any questions added comply with all relevant laws, including (without limitations)
          anti-discrimination laws
        </li>
        <li>
          <span>{getCompanyName()}</span> may remove any questions that are deemed inappropriate by us (in our absolute
          discretion) or if we receive complaints from candidates
        </li>
        <li>
          <span>{getCompanyName()}</span> will not be held liable to you or any applicant to your job postings in any
          way in connection with the screening questions you selected to ask. You agree to defend and indemnify{" "}
          {getCompanyName()} from and against all third party claims and all liabilities, assessments, losses, costs or
          damages in connection with any such liability.
        </li>
      </List>
    </Container>
  );
};

export default Disclaimer;
