import styled from "styled-components";

export const Modal = styled.div`
  max-width: 600px;
  min-height: 200px;
  margin-top: -55px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  margin-bottom: 24px;
`;

export const Text = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
`;

export const ShowPersonalityContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #c5c5c5;
`;

export const ShowPersonalityTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0 0 4px 0;
`;

export const ShowPersonalityText = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin: 0;
  padding-right: 20px;
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;

export const Submit = styled.button`
  background: #ffe140;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 10px 40px;
  border-radius: 8px;
  margin-left: auto;
`;

export const DeleteLink = styled.button`
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #eb3737;

  svg {
    width: 16px;
    height: 16px;
    fill: #eb3737;
  }
`;

export const Banner = styled.div`
  background-color: #f3f8ff;
  border-radius: 6px;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  margin-top: 24px;

  span {
    font-weight: 700;
    font-size: #333;
  }
`;

export const CreatedBy = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  display: block;
  margin-bottom: 8px;
`;

export const UserInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const UserPicture = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #eeeeee;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
  }
`;

export const Name = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  display: block;
  margin: 0;
`;

export const Count = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  display: block;
  margin: 0;
`;

export const Info = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
`;
