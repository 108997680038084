import styled from "styled-components";
import { Link } from "react-router-dom";

export const Modal = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }

  @media screen and (min-width: 1008px) {
    min-width: 800px;
  }

  @media screen and (min-width: 1240px) {
    min-width: 1000px;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
`;

export const HeadingButton = styled(Link)`
  color: #408bfc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0;

  &:hover {
    color: #408bfc;
  }
`;

export const EmptyContent = styled.div`
  margin-top: 20px;
`;

export const Content = styled.div`
  max-height: 600px;
  overflow: auto;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #333333;
  display: block;
  margin: 24px 0 16px 0;
`;

export const ImageContent = styled.div`
  width: 106px;
  height: 138px;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
`;
