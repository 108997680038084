import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const Status = ({ className, type, icon, iconAlign, children, ...rest }) => {
  const statusClassNames = cn(
    "fz-status",
    {
      [`fz-status_${type}`]: type
    },
    className
  );

  const statusIcon =
    icon &&
    React.cloneElement(icon, {
      className: "fz-status__icon"
    });

  return (
    <div className={statusClassNames} {...rest}>
      {icon && iconAlign === "left" && statusIcon}
      <div className="fz-status__text">{children}</div>
      {icon && iconAlign === "right" && statusIcon}
    </div>
  );
};

Status.propTypes = {
  type: PropTypes.oneOf(["", "secondary", "gray", "gray-outline"])
};

Status.defaultProps = {
  iconAlign: "left"
};

export default Status;
