import styled from "styled-components";

export const Content = styled.div`
  margin: 25px 0;
  @media screen and (min-width: 768px) {
    width: 80%;
    margin: 25px auto;
    min-width: 520px;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: -70px;
  right: 10px;
  outline: none;
  background: transparent;
  border: none;
`;

export const ButtonShortList = styled.button`
  font-size: 13px;
  font-weight: 400;
  line-height: 140%;
  height: 20px;
  padding: 0 4px 0 0;
  background: none;
  color: #4690ff;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
`;

export const ImageContent = styled.div`
  width: 106px;
  height: 138px;
  margin: 0 auto 16px;

  img {
    max-width: 100%;
  }
`;
