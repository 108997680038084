import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import format from "date-fns/format";
import { TrashAlt, Globe } from "@styled-icons/boxicons-regular";
import { MdDelete, MdKeyboardArrowLeft, MdRefresh, MdRemoveCircleOutline } from "react-icons/md";
import { MoreHorizontalOutline } from "@styled-icons/evaicons-outline";

import { guestLinkAccessType, guestLinkSteps } from "b2b/constants/campaign";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import {
  Modal,
  Title,
  Content,
  SubTitle,
  Back,
  StepCount,
  StepHeading,
  GuestLinkTitle,
  Description,
  Info,
  Flex,
  Add,
  Banner,
  Actions,
  Submit,
  SelectedListItem,
  SelectedListInitials,
  SelectedListName,
  SelectedListEmail,
  SelectedListRemove,
  AddPeople,
  HideAccessDropdown,
  DeleteLink,
  Progress,
  CheckboxContent,
  CheckboxTitle,
  CheckboxText,
  AccessEveryone,
  CreatedBy,
  CreatedByTitle,
  CreatedByContent,
  CreatedByViews,
  CreatedByAvatar,
  CreatedByName,
  CreatedByDate,
  Tabs,
  TabsItem,
  ButtonEdit,
  EditContent,
  EditShareButton,
  RemoveShareButton,
  PitchContainer,
  ReachTextContainer,
  CharacterCount
} from "./styled";
import { useModal } from "../../../../../utils/hooks";
import NewGuestLinkSuccess from "../NewGuestLinkSuccess";
import NewGuestLinkSelect from "../NewGuestLinkSelect";
import Checkbox from "../../../../components/Checkbox";
import AppContext, { isBaronaBrand } from "../../../../../contexts/init";
import { theme } from "../../../../../b2c/contexts/theme";
import RichTextTextarea from "../../../../components/RichTextTextarea";

const options = [
  {
    title: "CV and personal details",
    description: "Attached CV, full name, profile picture, phone number, email and shared links",
    id: "personal_details"
  },
  { title: "Cover letter", description: "Cover letter the candidate wrote for this job", id: "cover_letter" },
  {
    title: "Answers to screening questions",
    description: "The candidate’s responses to your job-specific questions",
    id: "screening_answers"
  },
  {
    title: "Assessment test results",
    description: "Results of all required tests for this job",
    id: "talent_results"
  },
  {
    title: "Personality test results",
    description: "Results of the candidate’s personality evaluation",
    id: "personality_results"
  },
  { title: "Interview notes", description: "Notes created by your team members", id: "interview_notes" }
];

const GuestLinkModal = ({
  isEdit,
  activeGuestLink,
  guestLinkThreshold,
  handleCreateGuestLink,
  showDeleteShareShortlisted,
  defaultSelectedValue,
  showGuestLinkRemoveAccess,
  collaboratorContacts,
  guestLinkContacts,
  close,
  resendGuestLink
}) => {
  const { global_brand } = useContext(AppContext);
  const isAccessTypeList = global_brand.guest_link_access_type === guestLinkAccessType.list;
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("");
  const [activeApplicationDetail, setActiveApplicationDetail] = useState([]);
  const openNewGuestLinkSuccess = useModal(<NewGuestLinkSuccess />, "");
  const [preSelectedValues, setPreSelectedValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isShowAccessDropdown, setIsShowAccessDropdown] = useState(true);
  const [isChangedValuesInEditedMode, setIsChangedValuesInEditedMode] = useState(false);
  const [isChangedVisibility, setIsChangedVisibility] = useState(false);
  const [guestPitchValue, setGuestPitchValue] = useState("");
  const [isEditGuestValue, setIsEditGuestValue] = useState(false);

  const handleChangeValuesInEditeMode = () => {
    if (isEdit && !isChangedValuesInEditedMode) {
      setIsChangedValuesInEditedMode(true);
    }
  };

  const handleSetActiveApplicationDetail = item => {
    setActiveApplicationDetail(prevItems =>
      prevItems.includes(item) ? prevItems.filter(i => i !== item) : [...prevItems, item]
    );

    if (isEdit && !isChangedVisibility) {
      setIsChangedVisibility(true);
    }
  };

  const handleSelect = value => {
    setPreSelectedValues(value);
  };

  const handleAddSelectedItem = () => {
    setSelectedValues([...selectedValues, ...preSelectedValues]);
    setPreSelectedValues([]);
    handleChangeValuesInEditeMode();
  };

  const handleRemove = removedValue => {
    setSelectedValues(selectedValues.filter(value => String(value.id) !== String(removedValue)));
    handleChangeValuesInEditeMode();
  };

  const expiresDate = isEdit
    ? format(new Date(activeGuestLink.expires_at), "dd MMM EEEE 'at' hh:mm")
    : format(new Date(new Date().getTime() + guestLinkThreshold * 1000), "dd MMM EEEE 'at' hh:mm");

  const handleSubmit = () => {
    setIsLoading(true);
    handleCreateGuestLink(
      activeApplicationDetail,
      false,
      selectedValues.map(item => item.id),
      guestPitchValue
    )
      .then(res => {
        if (res) {
          if (isEdit) {
            close();
          } else {
            openNewGuestLinkSuccess();
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleShowAccessDropdown = () => {
    setIsShowAccessDropdown(true);
  };

  const handleHideAccessDropdown = () => {
    setIsShowAccessDropdown(false);
  };

  useEffect(() => {
    if (isEdit) {
      setIsShowAccessDropdown(false);
    }

    if (isEdit && defaultSelectedValue.length) {
      setSelectedValues(
        defaultSelectedValue.map(item => {
          return {
            id: item.id,
            lastName: item.last_name || "",
            name: item.first_name || "",
            email: item.email
          };
        })
      );
    }

    if (!step) {
      setStep(guestLinkSteps.first);
    }

    if (activeGuestLink?.guest_accessible_sections?.length && !activeApplicationDetail.length) {
      setActiveApplicationDetail(activeGuestLink.guest_accessible_sections);
    }

    if (activeGuestLink?.guest_pitch && !guestPitchValue) {
      setGuestPitchValue(activeGuestLink.guest_pitch);
    }
  }, []);

  const titleText = useMemo(() => {
    const date = isEdit ? new Date(activeGuestLink.created_at) : new Date();
    return `Guest link ${format(date, "dd MMM EEEE")}`;
  }, [isEdit]);

  const isDisableSubmitFirstStep = useMemo(() => {
    const isRequiredContacts = isAccessTypeList ? !selectedValues.length : false;
    const isRequiredEdit = isEdit ? !isChangedValuesInEditedMode : false;
    return isLoading || isRequiredContacts || isRequiredEdit;
  }, [isEdit, isLoading, selectedValues.length, isChangedValuesInEditedMode]);

  const isDisableSubmitSecondStep = useMemo(() => {
    const isRequiredEdit = isEdit ? ![isChangedVisibility, isEditGuestValue].some(item => item) : false;
    return isLoading || isRequiredEdit;
  }, [isEdit, isLoading, isChangedVisibility, isEditGuestValue]);

  const handleSetStep = currentStep => {
    setStep(currentStep);
  };

  const isBarona = isBaronaBrand();

  return (
    <Modal>
      <Title>{isEdit ? "Edit link" : "New guest link"} </Title>

      {!isEdit ? (
        <Progress $background={theme.primaryGradient} $width={step === guestLinkSteps.first ? "50%" : "100%"}>
          <span />
        </Progress>
      ) : (
        <Tabs>
          <TabsItem $isActive={step === guestLinkSteps.first} onClick={() => handleSetStep(guestLinkSteps.first)}>
            Link settings
          </TabsItem>
          <TabsItem $isActive={step === guestLinkSteps.second} onClick={() => handleSetStep(guestLinkSteps.second)}>
            Shared content
          </TabsItem>
        </Tabs>
      )}

      {step === guestLinkSteps.first && (
        <GuestLinkFirstStep
          isDisableSubmit={isDisableSubmitFirstStep}
          titleText={titleText}
          isShowAccessDropdown={isShowAccessDropdown}
          handleShowAccessDropdown={handleShowAccessDropdown}
          isEdit={isEdit}
          selectedValues={selectedValues}
          preSelectedValues={preSelectedValues}
          handleSelect={handleSelect}
          collaboratorContacts={collaboratorContacts}
          handleHideAccessDropdown={handleHideAccessDropdown}
          handleAddSelectedItem={handleAddSelectedItem}
          handleRemove={handleRemove}
          handleSetStep={handleSetStep}
          expiresDate={expiresDate}
          showDeleteShareShortlisted={showDeleteShareShortlisted}
          guestLinkContacts={guestLinkContacts}
          owner={activeGuestLink?.owner || {}}
          viewsCount={activeGuestLink?.views_count}
          createdAt={activeGuestLink?.created_at}
          handleSubmit={handleSubmit}
          showGuestLinkRemoveAccess={showGuestLinkRemoveAccess}
          isChangedValuesInEditedMode={isChangedValuesInEditedMode}
          isBarona={isBarona}
          isAccessTypeList={isAccessTypeList}
          resendGuestLink={resendGuestLink}
        />
      )}

      {step === guestLinkSteps.second && (
        <GuestLinkSecondStep
          handleSetStep={handleSetStep}
          isDisableSubmit={isDisableSubmitSecondStep}
          handleSetActiveApplicationDetail={handleSetActiveApplicationDetail}
          activeApplicationDetail={activeApplicationDetail}
          handleSubmit={handleSubmit}
          showDeleteShareShortlisted={showDeleteShareShortlisted}
          isEdit={isEdit}
          isBarona={isBarona}
          setGuestPitchValue={setGuestPitchValue}
          guestPitchValue={guestPitchValue}
          setIsEditGuestValue={setIsEditGuestValue}
          isEditGuestValue={isEditGuestValue}
        />
      )}
    </Modal>
  );
};

const SelectedItemComponent = ({ user, handleRemove, index, resendGuestLink, isEdit }) => {
  const backgroundColor = ["#FBCF8E", "#D9EBA8", "#F7D5A8", "#E0A9B8", "#C8E4D4", "#F1C3CF"][(index + 1) % 6];
  const [active, setActive] = useState(false);

  const getInitials = (name = "", lastName = "") => {
    return `${name.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const toggleActiveEdit = () => {
    setActive(prev => !prev);
  };

  return (
    <SelectedListItem>
      <SelectedListInitials
        style={{
          backgroundColor
        }}
      >
        {getInitials(user.name, user.lastName)}
      </SelectedListInitials>

      <div>
        <SelectedListName>{user.name}</SelectedListName>
        <SelectedListEmail>{user.email}</SelectedListEmail>
      </div>

      {!isEdit ? (
        <SelectedListRemove onClick={() => handleRemove(user.id)}>
          <TrashAlt />️
        </SelectedListRemove>
      ) : (
        <>
          <ButtonEdit onClick={toggleActiveEdit}>
            <MoreHorizontalOutline />
          </ButtonEdit>

          {active && (
            <EditContent>
              <EditShareButton onClick={() => resendGuestLink(user.id)}>
                <MdRefresh />
                Resend link
              </EditShareButton>
              <RemoveShareButton onClick={() => handleRemove(user.id)}>
                <MdRemoveCircleOutline />
                Remove
              </RemoveShareButton>
            </EditContent>
          )}
        </>
      )}
    </SelectedListItem>
  );
};

const GuestLinkFirstStep = ({
  isDisableSubmit,
  titleText,
  isShowAccessDropdown,
  handleShowAccessDropdown,
  isEdit,
  selectedValues,
  preSelectedValues,
  handleSelect,
  collaboratorContacts,
  handleHideAccessDropdown,
  handleAddSelectedItem,
  handleRemove,
  handleSetStep,
  expiresDate,
  showDeleteShareShortlisted,
  owner,
  viewsCount,
  createdAt,
  handleSubmit,
  showGuestLinkRemoveAccess,
  isAccessTypeList,
  isBarona,
  resendGuestLink
}) => {
  const renderDescription = () => {
    if (isBarona) {
      if (isEdit) {
        return "Guests will need to verify access with a one-time passcode sent to their email before viewing the content.";
      }
      return "Select who can access the link. Guests will need to verify their access with a one-time passcode sent to their email before viewing the content.";
    }

    return "Anyone with the link will be able to access the shared page. Share this link only with the client and never share it publicly. Other access options are not yet available.";
  };

  return (
    <>
      {!isEdit && <StepHeading>Configure link settings</StepHeading>}

      <Content>
        <SubTitle>Link name</SubTitle>
        <GuestLinkTitle>{titleText}</GuestLinkTitle>
      </Content>

      <Content>
        <Flex>
          <div>
            <SubTitle>Who has access</SubTitle>
            <Description>{renderDescription()} </Description>
          </div>
          {isEdit && !isShowAccessDropdown && isBarona && (
            <AddPeople onClick={handleShowAccessDropdown}>Add people</AddPeople>
          )}
        </Flex>
        {isBarona && isAccessTypeList ? (
          <>
            {isShowAccessDropdown && (
              <Flex $margin="0 0 24px 0">
                <NewGuestLinkSelect
                  preSelectedValues={preSelectedValues}
                  selectedValueIds={selectedValues.map(item => item.id)}
                  handleSelect={handleSelect}
                  collaboratorContacts={collaboratorContacts}
                />

                {isEdit && <HideAccessDropdown onClick={handleHideAccessDropdown}>Cancel</HideAccessDropdown>}
                <Add disabled={!preSelectedValues.length} onClick={handleAddSelectedItem}>
                  Add
                </Add>
              </Flex>
            )}
          </>
        ) : (
          <AccessEveryone>
            <Globe />
            Anyone with the link
          </AccessEveryone>
        )}

        <Content>
          {selectedValues.map((user, index) => {
            return (
              <SelectedItemComponent
                key={user.id}
                user={user}
                index={index}
                showGuestLinkRemoveAccess={showGuestLinkRemoveAccess}
                handleRemove={handleRemove}
                isEdit={isEdit}
                resendGuestLink={resendGuestLink}
              />
            );
          })}
        </Content>
        {isEdit && (
          <CreatedBy>
            <div>
              <CreatedByTitle>Created by</CreatedByTitle>
              <CreatedByContent>
                <CreatedByAvatar src={owner.avatar} />
                <div>
                  <CreatedByName>{owner.name}</CreatedByName>

                  <CreatedByDate>{format(new Date(createdAt), "dd MMM EEEE 'at' hh:mm")}</CreatedByDate>
                </div>
              </CreatedByContent>
            </div>
            <CreatedByViews>
              Views
              <span>{viewsCount}</span>
            </CreatedByViews>
          </CreatedBy>
        )}
      </Content>

      <Content>
        {!isEdit && (
          <>
            <SubTitle>Expiration</SubTitle>
            <Description>This link will automatically expire in 90 days</Description>
          </>
        )}

        <Banner>
          Expires <span>{expiresDate}</span>
        </Banner>
      </Content>

      <Actions>
        {isEdit && (
          <DeleteLink onClick={showDeleteShareShortlisted}>
            <MdDelete />
            Delete link
          </DeleteLink>
        )}

        {isEdit ? (
          <Submit disabled={isDisableSubmit} onClick={handleSubmit}>
            Update link
          </Submit>
        ) : (
          <>
            <StepCount>Step 1 of 2</StepCount>
            <Submit disabled={isDisableSubmit} onClick={() => handleSetStep(guestLinkSteps.second)}>
              Next
            </Submit>
          </>
        )}
      </Actions>
    </>
  );
};

const GuestLinkSecondStep = ({
  handleSetStep,
  isDisableSubmit,
  activeApplicationDetail,
  handleSetActiveApplicationDetail,
  handleSubmit,
  showDeleteShareShortlisted,
  guestPitchValue,
  setGuestPitchValue,
  isEdit,
  setIsEditGuestValue,
  isEditGuestValue
}) => {
  const areaRef = useRef();
  const maxInputLength = 3008;

  const onChangeText = text => {
    setGuestPitchValue(text);
    if (isEdit && !isEditGuestValue) {
      setIsEditGuestValue(true);
    }
  };

  const inputLength = useMemo(() => {
    if (!guestPitchValue) return 0;

    const blocksFromHTML = convertFromHTML(guestPitchValue);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
    const editorStateDefault = EditorState.createWithContent(contentState);
    const htmlString = draftToHtml(convertToRaw(editorStateDefault.getCurrentContent()));

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    let plainText = tempDiv.textContent || tempDiv.innerText || "";

    plainText = plainText.replace(/\n/g, "").trim();

    return plainText.length;
  }, [guestPitchValue?.length]);

  return (
    <>
      <StepHeading>Choose what to share</StepHeading>
      <SubTitle>Application details to show</SubTitle>

      <Description>
        Pick which parts of the application guests can see. These settings apply to all shared candidates.
      </Description>

      <Info>
        <span>These details are always shown:</span> Skills, Work experience, Education, Languages and References
      </Info>

      {options.map(item => (
        <CheckboxContent key={item.id}>
          <Checkbox
            checked={activeApplicationDetail.includes(item.id)}
            onCheck={() => handleSetActiveApplicationDetail(item.id)}
            onUncheck={() => handleSetActiveApplicationDetail(item.id)}
          >
            <span>
              <CheckboxTitle>{item.title}</CheckboxTitle>
              <CheckboxText>{item.description}</CheckboxText>
            </span>
          </Checkbox>
        </CheckboxContent>
      ))}

      <PitchContainer>
        <SubTitle>Guest pitch</SubTitle>

        <Description>
          Write a pitch note for your guests. You can use it to describe why the candidates were chosen, or include
          instructions for external reviewers.
        </Description>

        <ReachTextContainer>
          <RichTextTextarea
            placeholder="Write your pitch"
            area={areaRef}
            description={guestPitchValue}
            onChange={onChangeText}
            maxLength={maxInputLength}
          />
        </ReachTextContainer>

        <CharacterCount>{`${inputLength <= 3000 ? inputLength : 3000}`}</CharacterCount>
      </PitchContainer>

      <Actions>
        {isEdit ? (
          <DeleteLink onClick={showDeleteShareShortlisted}>
            <MdDelete />
            Delete link
          </DeleteLink>
        ) : (
          <>
            <Back onClick={() => handleSetStep(guestLinkSteps.first)}>
              <MdKeyboardArrowLeft />
              Back
            </Back>

            <StepCount>Step 2 of 2</StepCount>
          </>
        )}
        <Submit disabled={isDisableSubmit} onClick={handleSubmit}>
          {isEdit ? "Update link" : "Create link"}
        </Submit>
      </Actions>
    </>
  );
};

export default GuestLinkModal;
