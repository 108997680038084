import styled from "styled-components";
import { Link } from "react-router-dom";

export const ExternalCandidateAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #f3f8ff;
  background: #f3f8ff;
  color: #408bfc;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
`;

export const ViewApplication = styled(Link)`
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 6px 24px;
  width: 148px;
  margin-left: auto;
`;
