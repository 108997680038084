import { inject, observer } from "mobx-react";
import React from "react";

import { getGuestLinkUrl } from "b2b/utils/helpers";
import { Modal, Title, Text, Copy, Flex, Created, CreatedLinkBanner, Banner, CreatedLink } from "./styled";
import { copyCodeToClipboard } from "../../../../../utils/helpers";
import { formatDate } from "../../../../utils/helpers";
import { theme } from "../../../../../b2c/contexts/theme";

const InviteCandidateSuccess = ({ CampaignsStore: { selectedCampaign } }) => {
  const guestLink = getGuestLinkUrl(`share-candidates/${selectedCampaign.active_guest_link.token}`);
  const handleCopy = () => {
    copyCodeToClipboard(guestLink, () => ({}));
  };

  return (
    <Modal>
      <Title>Your link has been created</Title>

      <Text>
        Copy the link bellow and share it with the client. You can always copy, edit or delete the link from the
        campaign page.
      </Text>

      <Flex>
        <Created>
          <CreatedLinkBanner>
            Expires on <span>{formatDate(new Date(selectedCampaign.active_guest_link.expires_at))}</span>
          </CreatedLinkBanner>
          <CreatedLink>{guestLink}</CreatedLink>
        </Created>
        <Copy onClick={handleCopy} background={theme.primaryButtonBackground} color={theme.primaryButtonLabelColor}>
          Copy link
        </Copy>
      </Flex>

      <Banner>Share this link only with the client and never share it publicly</Banner>
    </Modal>
  );
};

export default inject("CampaignsStore", "MyJobsStore")(observer(InviteCandidateSuccess));
