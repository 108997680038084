import styled from "styled-components";

export const Modal = styled.div`
  max-width: 640px;
  margin-top: -45px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }

  @media screen and (min-width: 1008px) {
    min-width: 748px;
    max-width: 748px;
  }
`;

export const Title = styled.h3`
  color: #333;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  display: block;
  margin: 0 0 16px 0;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const Container = styled.div`
  margin: 16px 0 24px;
  padding: 24px 0;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const Cancel = styled.button`
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  width: 130px;
  padding: 10px 40px;
`;

export const Next = styled.button`
  background: #ffe140;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 10px 40px;

  ${({ isDisabled }) =>
    isDisabled
      ? `
      opacity: 0.4;
      pointer-events: none;
      `
      : ""}
`;

export const Content = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ isSelectUser }) => (isSelectUser ? "#408BFC" : "#c5c5c5")};
  background: #fff;
  position: relative;
`;

export const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  padding-top: 16px;
  border-top: 1px solid #eee;
`;

export const ProfileCompletenessText = styled.p`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 4px;
`;

export const ProfileCompletenessProgressContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProfileCompletenessProgress = styled.div`
  width: 128px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ fill }) => fill};
  overflow: hidden;

  span {
    width: ${({ progressWidth }) => `${progressWidth}%`};
    background-color: ${({ progress }) => progress};
    height: 100%;
    display: block;
  }
`;

export const Avatar = styled.span`
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #99c2ff;
  background: #e0edff;
  color: #408bfc;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Name = styled.h6`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 2px 0;
  display: block;
`;

export const Position = styled.p`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Company = styled.p`
  display: block;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 16px 0;
`;

export const Dot = styled.span`
  width: 4px;
  height: 4px;
  background-color: #c5c5c5;
  border-radius: 50%;
`;

export const State = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StateItem = styled.div`
  border-radius: 4px;
  background: #f3f3f3;
  padding: 8px;
  min-width: 150px;
`;

export const StateName = styled.h6`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 2px;
`;

export const StateText = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 260px;
  white-space: nowrap;
`;

export const CvUploaded = styled.span`
  border-radius: 4px;
  background: #e0edff;
  padding: 4px 8px;
  color: #408bfc;
  font-size: 12px;
  font-weight: 400;
`;

export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin: 48px 0;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const Social = styled.div`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    fill: #666;
  }
`;
