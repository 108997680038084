import React, { useState, useEffect } from "react";
import Button from "components/Button";

const UserCvReference = ({ reference = [] }) => {
  const [state, setAllReference] = useState([]);

  useEffect(() => {
    if (reference.length > 0) setAllReference(Array(reference.length).fill(false));
  }, [reference]);

  const getTitle = item => {
    return item.title_and_organization ? `${item.name} • ${item.title_and_organization}` : item.name;
  };

  const getText = item => {
    let text = item.email || "";
    if (item.phone_code && item.phone_number) {
      text = text ? `${text}, ${item.phone_code}${item.phone_number}` : `${item.phone_code}${item.phone_number}`;
    }

    return text;
  };

  return (
    <div className="fz-pane">
      <div className="fz-pane__container fz-pane__container_case-two">
        <h2 className="fz-heading-title exp">
          References
          {reference.length ? (
            <Button.Simple
              onClick={() =>
                setAllReference(prev =>
                  prev.some(item => item === true) ? prev.map(() => false) : prev.map(() => true)
                )
              }
            >
              {state.some(item => item === true) ? "Hide" : "Show"} all references
            </Button.Simple>
          ) : null}
        </h2>
        <div className="fz-info-text mb-3">
          <span className="fz-info-text__text">
            Total reference <strong>{reference.length}</strong>
          </span>
        </div>

        {reference.map((item, index) => (
          <div key={`wexp_${item.job_title}_${index}`} className="fz-info-pane">
            <div className="fz-info-pane__header">
              <div className="fz-info-pane__cell">
                <h3 className="fz-heading-subtitle">{getTitle(item)}</h3>
                <div className="fz-info-text mb-0">
                  <span className="fz-info-text__text">
                    <strong>{item.relation_to || "N/A"}</strong>
                  </span>
                  <span className="fz-info-text__separator">•</span>
                  <span className="fz-info-text__text">{getText(item)}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserCvReference;
