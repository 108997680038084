import React, { useEffect, useState } from "react";
import { MdLightbulbOutline } from "react-icons/md";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import {
  Modal,
  Title,
  Text,
  Confirm,
  Flex,
  Cancel,
  Info,
  SearchContent,
  SearchButton,
  Results,
  ResultsLabel,
  Tile,
  TileTitle,
  TileDescription
} from "./styled";
import { theme } from "../../../../../b2c/contexts/theme";
import SelectSearch from "../../../../components/SelectSearch";

const EscoOccupationsModal = ({
  CampaignsStore: { occupationAutocompleteList, occupationSearch },
  getOccupationAutocomplete,
  getOccupationSearch,
  selectOccupation,
  selectedOccupation,
  form,
  isEditPromotion,
  // occupationSearch,
  // occupationAutocompleteList,
  close
}) => {
  const [autoCompleteSearch, setAutocompleteSearch] = useState("");
  const [autoCompleteDetail, setAutoCompleteDetail] = useState("");
  const [occupation, setOccupation] = useState("");
  const options = occupationAutocompleteList.length
    ? occupationAutocompleteList.map(item => ({ value: item, label: item }))
    : [];

  const handleConfirm = () => {
    close();
    selectOccupation(toJS(occupation), autoCompleteDetail.value);
  };

  const handleChangeAutocomplete = value => {
    setAutocompleteSearch(value);
  };

  const handleSelectAutocomplete = value => {
    setAutoCompleteDetail(value);
  };

  const handleSelectOccupation = value => {
    setOccupation(value);
  };

  const handleFindOccupation = () => {
    getOccupationSearch(autoCompleteDetail.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (autoCompleteSearch.length >= 3) {
        getOccupationAutocomplete(autoCompleteSearch);
      }
    }, 800);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [autoCompleteSearch]);

  useEffect(() => {
    if (!occupation.id && selectedOccupation.id) {
      setOccupation(selectedOccupation);
    }
  }, [occupation.id]);

  useEffect(() => {
    if (form.occupation_search_value && !autoCompleteDetail?.value && !isEditPromotion) {
      setOccupation(selectedOccupation);
      setAutoCompleteDetail({ value: form.occupation_search_value, label: form.occupation_search_value });
    }
  }, [form.occupation_search_value]);

  /*

  useEffect(() => {
    if (isEditPromotion && form.occupation_search_value) {
      getOccupationSearch(form.occupation_search_value);
      setAutocompleteSearch(form.occupation_search_value);
    }
  }, []);
  */

  return (
    <Modal>
      <Title>ESCO Occupations</Title>

      <Text>Start by typing occupation or a bit more elaborate hint here</Text>

      <SearchContent>
        <SelectSearch
          value={autoCompleteDetail}
          defaultValue="Type occupation name"
          options={options}
          onInputChange={handleChangeAutocomplete}
          onSelect={filters => handleSelectAutocomplete(filters)}
          inputValue={autoCompleteSearch}
          isSearchable
        />

        <SearchButton
          disabled={!autoCompleteDetail?.value}
          $background={theme.primaryButtonBackground}
          $color={theme.primaryButtonLabelColor}
          onClick={handleFindOccupation}
        >
          Find
        </SearchButton>
      </SearchContent>

      {!occupationSearch.occupations?.length ? (
        <Info>
          <MdLightbulbOutline />
          Use more specific titles to narrow the search results. Try “Customer Experience Manager” instead of “Manager”
        </Info>
      ) : (
        <>
          <ResultsLabel>{occupationSearch.occupations?.length} results</ResultsLabel>

          <Results>
            {occupationSearch.occupations.map(item => (
              <Tile key={item.id} $isActive={occupation.id === item.id} onClick={() => handleSelectOccupation(item)}>
                <TileTitle>{`${item.code} ${item.preferred_label}`}</TileTitle>
                <TileDescription>{item.description}</TileDescription>
              </Tile>
            ))}
          </Results>

          <Flex>
            <Cancel onClick={close}>Cancel</Cancel>
            <Confirm
              disabled={!occupation}
              $background={theme.primaryButtonBackground}
              $color={theme.primaryButtonLabelColor}
              onClick={handleConfirm}
            >
              Confirm
            </Confirm>
          </Flex>
        </>
      )}
    </Modal>
  );
};

export default inject("CampaignsStore")(observer(EscoOccupationsModal));
