import React from "react";
import emptyQuestions from "images/b2b/emptyQuestions.png";

import { Content, Image, Title, Description, Button } from "./styled";

const NoAccess = () => {
  return (
    <Content>
      <Image>
        <img src={emptyQuestions} alt="empty" />
      </Image>
      <Title>Learn more about your candidates</Title>
      <Description>Upgrade you license to ask targeted questions and make smarter hiring decisions.</Description>
      <Button className="intercom-trigger">Contact our Business support</Button>
    </Content>
  );
};

export default NoAccess;
