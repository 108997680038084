import React from "react";
import { differenceInDays, differenceInWeeks, differenceInMonths, isToday, parseISO } from "date-fns";

// hide for this ticket https://app.shortcut.com/fuzu/story/3099/b2b-fix-candidate-profile-page-navigation
// import { SimpleSelect } from "../../../../../components/Selects";
import SearchInput from "../../../../components/SearchInput";
import { Content, Title, Flex, Description } from "./styled";

/* hide reg
const perPageOptions = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];
*/

const ShareCandidatesSortingFiltration = ({ term, placeholder, onSearch, date, totalCount }) => {
  /*
update, perPage,
  const perPageSelectProps = {
    getFilteredList: update,
    selectOptions: perPageOptions,
    role: "per_page",
    value: { value: perPage, label: perPage }
  };
*/

  const handleChange = filter => {
    onSearch(filter);
  };

  const getLastUpdatedLabel = () => {
    const lastUpdatedDate = parseISO(date);
    const now = new Date();

    if (isToday(lastUpdatedDate)) {
      return "Today";
    }

    const daysDiff = differenceInDays(now, lastUpdatedDate);
    if (daysDiff <= 6) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    }

    const weeksDiff = differenceInWeeks(now, lastUpdatedDate);
    if (weeksDiff < 4) {
      return `${weeksDiff} week${weeksDiff > 1 ? "s" : ""} ago`;
    }

    const monthsDiff = differenceInMonths(now, lastUpdatedDate);
    return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
  };

  return (
    <Content>
      <div>
        <Title>
          {totalCount} Candidate{totalCount > 1 ? "s" : ""}
        </Title>
        <Description>Last updated: {getLastUpdatedLabel()}</Description>
      </div>
      <Flex>
        <div className="fz-filter-bar__additional">
          <SearchInput initValue={term} placeholder={placeholder} onChange={handleChange} />
        </div>
      </Flex>
    </Content>
  );
};

export default ShareCandidatesSortingFiltration;
