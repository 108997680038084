import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  border: 1px solid #c5c5c5;
  margin: 32px auto;
  max-width: 1206px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
`;

export const StepCounter = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 4px;
`;

export const Title = styled.h2`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin: 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: auto;
`;

export const Back = styled.a`
  border-radius: 8px;
  background: #eee;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;

  &:hover {
    color: #333;
    text-decoration: none;
  }
`;

export const Next = styled.a`
  border-radius: 8px;
  background: #333;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;
