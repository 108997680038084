import React from "react";
import { MdShortText, MdRadioButtonChecked, MdCheckBox, MdFormatAlignLeft, MdDelete } from "react-icons/md";

import { questionsType } from "../../../../../constants/questions";
import {
  Content,
  Tile,
  TileFlex,
  TileCount,
  TileType,
  TileTitle,
  TileAnswers,
  TileAnswersItem,
  HeadingFlex,
  Remove,
  TileLabel,
  TileDescription
} from "./styled";

const Questions = ({ questions, handleRemoveQuestion }) => {
  const renderQuestions = questions.map((item, index) => {
    const position = index + 1;
    if (item.question_type === questionsType.multiChoice) {
      return (
        <Tile key={`multi-choice-default-${item.text}`}>
          <div>
            <HeadingFlex>
              <div>
                <TileTitle>
                  <TileCount>{position}</TileCount>
                  {item.text}
                </TileTitle>

                <TileFlex>
                  <TileType>
                    <MdCheckBox />
                    {item.question_type_label}
                  </TileType>
                </TileFlex>

                {item.description && (
                  <>
                    <TileLabel>Description</TileLabel>
                    <TileDescription>{item.description}</TileDescription>
                  </>
                )}
              </div>
              <Remove onClick={() => handleRemoveQuestion(item.question_id)}>
                <MdDelete />
              </Remove>
            </HeadingFlex>
            <TileAnswers>
              {item.options.map(option => {
                return <TileAnswersItem key={`multi-choice-${option.text}`}>{option.text}</TileAnswersItem>;
              })}
            </TileAnswers>
          </div>
        </Tile>
      );
    }

    if (item.question_type === questionsType.optionChoice) {
      return (
        <Tile key={`option-choice-default-${item.text}`}>
          <div>
            <HeadingFlex>
              <div>
                <TileTitle>
                  <TileCount>{position}</TileCount>
                  {item.text}
                </TileTitle>

                <TileFlex>
                  <TileType>
                    <MdRadioButtonChecked />
                    {item.question_type_label}
                  </TileType>
                </TileFlex>
                {item.description && (
                  <>
                    <TileLabel>Description</TileLabel>
                    <TileDescription>{item.description}</TileDescription>
                  </>
                )}
              </div>
              <Remove onClick={() => handleRemoveQuestion(item.question_id)}>
                <MdDelete />
              </Remove>
            </HeadingFlex>

            <TileAnswers>
              {item.options.map(option => {
                return <TileAnswersItem key={`option-choice-${option.text}`}>{option.text}</TileAnswersItem>;
              })}
            </TileAnswers>
          </div>
        </Tile>
      );
    }

    if (item.question_type === questionsType.shortText) {
      return (
        <Tile key={`short-text-default-${item.text}`}>
          <div>
            <HeadingFlex>
              <div>
                <TileTitle>
                  <TileCount>{position}</TileCount>
                  {item.text}
                </TileTitle>
                <TileFlex>
                  <TileType>
                    <MdShortText />
                    {item.question_type_label}
                  </TileType>
                </TileFlex>

                {item.description && (
                  <>
                    <TileLabel>Description</TileLabel>
                    <TileDescription>{item.description}</TileDescription>
                  </>
                )}
              </div>
              <Remove onClick={() => handleRemoveQuestion(item.question_id)}>
                <MdDelete />
              </Remove>
            </HeadingFlex>
          </div>
        </Tile>
      );
    }

    return (
      <Tile key={`text-default-${item.text}`}>
        <div>
          <HeadingFlex>
            <div>
              <TileTitle>
                <TileCount>{position}</TileCount>
                {item.text}
              </TileTitle>
              <TileFlex>
                <TileType>
                  <MdFormatAlignLeft />
                  {item.question_type_label}
                </TileType>
              </TileFlex>
              {item.description && (
                <>
                  <TileLabel>Description</TileLabel>
                  <TileDescription>{item.description}</TileDescription>
                </>
              )}
            </div>
            <Remove onClick={() => handleRemoveQuestion(item.question_id)}>
              <MdDelete />
            </Remove>
          </HeadingFlex>
        </div>
      </Tile>
    );
  });

  return <Content>{renderQuestions}</Content>;
};

export default Questions;
