import React from "react";
import { observer, inject } from "mobx-react";

import Layout from "../../../../components/Layout";
import SearchList from "../SearchList";
import FindTalentList from "../FindTalentList";

const SavedSearch = inject("store")(
  observer(
    ({
      store: { searches, searchName, applyFilter, resetFilters, setSearchName, deleteSearch, getSearches, paramsCount }
    }) => {
      return (
        <Layout.Container type="wide">
          {paramsCount > 0 ? (
            <FindTalentList searchName={searchName} reset={() => resetFilters(true, true, true)} allowSaving={false} />
          ) : (
            <SearchList
              searches={searches}
              apply={(name, filters) => {
                applyFilter(filters);
                setSearchName(name);
              }}
              deleteSearch={deleteSearch}
              refresh={getSearches}
            />
          )}
        </Layout.Container>
      );
    }
  )
);

export default SavedSearch;
