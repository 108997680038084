import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

import InitContext, { isBaronaBrand } from "contexts/init";
import { useQueryParams } from "utils/hooks";
import { Button, Input, Text } from "../../../b2c/components/Core";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";

const validateTextLength = (input, limit) => {
  if (input) {
    if (input.length > limit) {
      return { test: false, error: `Max ${limit} character` };
    }
    return { test: true };
  }

  return { test: false, error: "Field is required" };
};

const validatePasswordLength = (input, limit) => {
  if (input) {
    if (input.length < limit) {
      return { test: false, error: `Password should be at least ${limit} characters long` };
    }
    return { test: true };
  }

  return { test: false, error: "Please enter your password" };
};

const validateForm = ({ first_name, last_name, password }) => {
  const validation = {
    first_name: validateTextLength(first_name, 40),
    last_name: validateTextLength(last_name, 40),
    password: validatePasswordLength(password, 8)
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};

const defaultValidation = {
  first_name: { error: false },
  last_name: { error: false },
  password: { error: false }
};

const CreateAccount = ({ User: { createAccount, state } }) => {
  const { error, processing } = state;
  const history = useHistory();
  const { token } = useQueryParams();
  const { invitation } = useContext(InitContext);
  const employerName = invitation?.employer?.name;
  const [validationState, setValidation] = useState(defaultValidation);
  const isBarona = isBaronaBrand();

  const validation_errors = {};
  const [formState, setFormState] = useState({
    first_name: "",
    last_name: "",
    password: ""
  });

  const handleSubmission = e => {
    e.preventDefault();
    setValidation(defaultValidation);
    localStorage.removeItem("viewedOnboarding");
    const { valid, validation } = validateForm(formState);
    setValidation(validation);

    if (valid) {
      const body = {
        token,
        user: formState
      };

      createAccount(body, valid).then(() => {
        history.replace("/employers/select");
      });
    }
  };

  const buildDescription = () => {
    const jobName = invitation?.position?.title;

    if (invitation.collaboration) {
      return (
        <Text fontSize={14} fontWeight={400} width="100%" margin="14px 0 0 0" textAlign="center">
          You have been invited to collaborate on the recruitment of&nbsp;
          <b>{jobName}</b>&nbsp;at&nbsp;
          <b>{employerName}</b>.
        </Text>
      );
    }

    return (
      <Text fontSize={14} fontWeight={400} width="100%" margin="14px 0 0 0" textAlign="center">
        {"You have been invited to manage the employer account for "}
        <b>{employerName}</b>.
      </Text>
    );
  };

  return (
    <CreateLoginFlowWrapper
      title="Welcome to Fuzu for Employers!"
      description={
        <div>
          {buildDescription()}
          <Text fontSize={14} fontWeight={400} width="100%" textAlign="center">
            Please create your Fuzu user account.
          </Text>
        </div>
      }
    >
      <Form onSubmit={handleSubmission} noValidate>
        <div>
          <Input
            disabled
            key="email"
            type="email"
            autoComplete="email"
            fieldRequired={false}
            value={invitation?.email || ""}
          />
        </div>
        <Input
          key="first_name"
          label={
            <Text fontSize={14} fontWeight={600} lineHeight="21px">
              First name
            </Text>
          }
          type="text"
          autoComplete="off"
          fieldRequired={false}
          value={formState?.first_name}
          errorMessage={validationState?.first_name?.error || validation_errors?.first_name}
          onChange={({ target: { value } }) => {
            if (value.length <= 40) {
              setFormState(prev => ({ ...prev, first_name: value }));
            }
          }}
        />
        <Input
          key="last_name"
          label={
            <Text fontSize={14} fontWeight={600} lineHeight="21px">
              Last name
            </Text>
          }
          type="text"
          autoComplete="off"
          fieldRequired={false}
          value={formState?.last_name}
          onChange={({ target: { value } }) => {
            if (value.length <= 40) {
              setFormState(prev => ({ ...prev, last_name: value }));
            }
          }}
          errorMessage={validationState?.last_name?.error || validation_errors?.last_name}
        />
        <Input
          type="password"
          key="password"
          placeholder="Minimum 8 characters"
          label={
            <Text fontSize={14} fontWeight={600} lineHeight="21px">
              Create a password
            </Text>
          }
          fieldRequired={false}
          value={formState?.password}
          errorMessage={validationState?.password?.error || validation_errors?.password}
          onChange={({ target: { value } }) => {
            setFormState(prev => ({ ...prev, password: value }));
          }}
        />

        {error && (
          <Text color="accent300" margin="4px 0 0">
            {error}
          </Text>
        )}

        <StyledButton processing={processing} type="submit" fontSize="14px" margin="15px 0" width="100%" height="48px">
          Create account
        </StyledButton>

        <Text fontSize={16} fontWeight={400}>
          By clicking “Create account” you agree to Fuzu’s
          <a
            href={isBarona ? "https://policies.barona.fi/" : "https://www.fuzu.com/privacy-policy"}
            target="_blank"
            rel="noreferrer"
          >
            <Text
              fontSize={14}
              fontWeight={600}
              style={{
                display: "inline",
                color: "#0e50b5"
              }}
            >
              {" Privacy Policy "}
            </Text>
          </a>
          and
          <a href="https://www.fuzu.com/terms-and-conditions" target="_blank" rel="noreferrer">
            <Text
              fontSize={14}
              fontWeight={600}
              style={{
                display: "inline",
                color: "#0e50b5"
              }}
            >
              {" Service Terms."}
            </Text>
          </a>
        </Text>
      </Form>
    </CreateLoginFlowWrapper>
  );
};

const Form = styled.form`
  width: 420px;
  margin: 20px auto;
`;

const StyledButton = styled(Button)`
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
`;

export default inject("User")(observer(CreateAccount));
