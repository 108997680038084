import React, { useRef, useMemo } from "react";
import { MdClose, MdDone, MdEmail, MdForward, MdGetApp, MdStar } from "react-icons/md";
import Popup from "reactjs-popup";

import Dropdown from "components/Selects";
import ModalConsumer from "contexts/modal";
import Button from "components/Button";
import { TABS } from "constants/tabs";
import MessageButton from "../../../../components/MessageButton";
import BatchActions from "../../../../components/BatchActions";
import Subscription from "../../../../components/Subscription";

const CardControlBar = ({
  selected = false,
  candidates = [],
  tab,
  reject,
  scope,
  selectedSomeone,
  selectedCandidates,
  selectAll,
  clearSelection,
  options = [],
  shortlist,
  move,
  printCVs,
  messageToSelectedPath,
  exportCsv,
  campaignEmployerId,
  navigationIds,
  isNewSelectAll,
  candidatesLength,
  showSaveModalContent
}) => {
  const popOverRef = useRef();

  const handleDownloadCvs = isSelf => () => {
    printCVs(isSelf);
    popOverRef.current.closePopup();
  };
  /*
  const isSelectedOnlyExternalCandidates = useMemo(() => {
   ;
  }, [candidates.length]);
  */

  const isSelectedOnlyExternalCandidates = useMemo(() => {
    return candidates.every(item => Boolean(item.prospectId));
  }, [candidates.length]);

  return (
    <BatchActions
      selected={selected}
      selectedSomeone={selectedSomeone}
      selectAll={selectAll}
      clearSelection={clearSelection}
      navigationIds={navigationIds}
      selectedCandidates={selectedCandidates}
      isNewSelectAll={isNewSelectAll}
      candidatesLength={candidatesLength}
    >
      {tab === TABS.APPLICANTS && scope !== "shortlisted" && (
        <Button.Simple icon={<MdDone />} className="mr-3" onClick={shortlist}>
          Shortlist
        </Button.Simple>
      )}

      {tab === TABS.APPLICANTS && (
        <ModalConsumer>
          {({ toggle }) => (
            <Button.Simple onClick={() => reject(toggle)} icon={<MdClose />} className="mr-3">
              Reject
            </Button.Simple>
          )}
        </ModalConsumer>
      )}

      <ModalConsumer>
        {({ toggle }) => (
          <Dropdown
            selectOptions={options.filter(({ value }) => !/applied|all/.test(value))}
            onSelect={phase => (phase === "rejected" ? reject(toggle) : move(phase, toggle))}
          >
            <Button.Simple icon={<MdForward />}>Move to</Button.Simple>
          </Dropdown>
        )}
      </ModalConsumer>

      <ModalConsumer>
        {({ toggle }) => (
          <Button.Simple icon={<MdStar />} onClick={() => showSaveModalContent(toggle)}>
            Save Talent
          </Button.Simple>
        )}
      </ModalConsumer>

      <div className="fz-card-control-bar__separator mr-4 ml-4" />

      {!isSelectedOnlyExternalCandidates && (
        <MessageButton
          isUnlocked
          messageUrl={messageToSelectedPath}
          type="batch"
          users={candidates}
          campaignEmployerId={campaignEmployerId}
          navigationIds={navigationIds}
          selectedCandidates={selectedCandidates}
          Component={props => (
            <Subscription.Upgrade permission="messaging">
              <Button.Simple icon={<MdEmail />} tag="a" className="mr-3" {...props}>
                Message
              </Button.Simple>
            </Subscription.Upgrade>
          )}
        />
      )}

      {!/applied|rejected/.test(scope) && (
        <Popup
          arrow={false}
          className="fz-dropdown"
          position="bottom right"
          ref={popOverRef}
          trigger={
            <button className="fz-simple-btn mr-3" type="button">
              <MdGetApp className="fz-dropdown-itm__icon" />
              <span className="fz-dropdown-itm__text">Download CVs</span>
            </button>
          }
        >
          <div className="fz-dropdown-nav" role="listbox">
            <Button onClick={handleDownloadCvs()} className="fz-dropdown-itm" overwriteClass role="button" tabIndex="0">
              <span className="fz-dropdown-itm__text">Candidate&#39;s Fuzu profile</span>
            </Button>
            <Button
              onClick={handleDownloadCvs(true)}
              className="fz-dropdown-itm"
              overwriteClass
              role="button"
              tabIndex="0"
            >
              <span className="fz-dropdown-itm__text">Candidate&#39;s self-prepared CV</span>
            </Button>
          </div>
        </Popup>
      )}

      <Subscription.Upgrade permission="candidate_ranking">
        <Button.Simple icon={<MdGetApp />} onClick={exportCsv}>
          Export to CSV
        </Button.Simple>
      </Subscription.Upgrade>
    </BatchActions>
  );
};

export default CardControlBar;
