import styled from "styled-components";
import { Button } from "../../../b2c/components/Core";

export const Form = styled.form`
  width: 420px;
  margin: 20px auto;
`;

export const StyledButton = styled(Button)`
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
`;
