import React from "react";
import styled from "styled-components";

import B2bInvite from "images/b2b_invite_logo_fuzu.svg";
import { Text } from "b2c/components/Core";
import BaronaLogo from "../BaronaLogo";

const CreateLoginFlowWrapper = ({ children, title, description }) => {
  return (
    <div>
      <B2bInviteHeaderWrapper>
        <BaronaLogo>
          <B2bInvite />
        </BaronaLogo>
      </B2bInviteHeaderWrapper>

      <div>
        <Text
          fontSize={36}
          fontWeight={600}
          lineHeight="43px"
          width="100%"
          margin="50px 0 0 0"
          textAlign="center"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal"
          }}
        >
          {title}
        </Text>
      </div>
      <div>
        {typeof description === "string" ? (
          <Text
            fontSize={14}
            fontWeight={400}
            lineHeight="19px"
            width="100%"
            margin="14px 0 0 0"
            textAlign="center"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal"
            }}
          >
            {description}
          </Text>
        ) : (
          description
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

const B2bInviteHeaderWrapper = styled.div`
  padding: 20px 0;
  text-align: center;
`;

export default CreateLoginFlowWrapper;
