import React, { useRef, cloneElement, Children } from "react";

const Upload = ({ children, ...rest }) => {
  const uploader = useRef();

  const simulateClick = e => {
    e.persist();
    uploader.current.click();
  };

  return (
    <>
      {cloneElement(Children.only(children), { onClick: simulateClick })}
      <input ref={uploader} type="file" style={{ display: "none" }} {...rest} />
    </>
  );
};

export default Upload;
