import styled from "styled-components";

export const Modal = styled.div`
  max-width: 600px;
  min-height: 300px;

  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

export const Banner = styled.div`
  display: inline-block;
  padding: 8px 12px;
  color: #333;
  font-weight: 500;
  font-size: 12px;
  background-color: #eee;
  border-radius: 16px;
  margin-bottom: 16px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const TagContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const Tag = styled.span`
  color: ${({ isNotActive }) => (isNotActive ? "#A4A4A4" : "#fff")};
  background: ${({ isNotActive }) => (isNotActive ? "#EEE" : "#408bfc")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 8px;
`;

export const DaysContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  margin: 24px 0 0 0;
`;
