import styled from "styled-components";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";

export const Container = styled.section`
  max-width: 1206px;
  margin: 32px auto;
  width: 100%;
`;

export const Content = styled.div`
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  margin: 0 auto;
  width: 100%;
`;

export const Back = styled(Link)`
  padding: 8px 16px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 32px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100px;
  gap: 8px;

  &:hover {
    color: #333;
    text-decoration: none;
  }
`;

export const PageTitle = styled.h1`
  color: #333;
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 8px;
  margin-top: 0;
`;

export const Description = styled.p`
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

export const TilesContent = styled.div`
  margin: 32px 0;
`;

export const Tile = styled.div`
  padding: 15px 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .fz-checkbox {
    display: flex;
    align-items: center;
  }
`;

export const TileTitle = styled.h6`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 4px 0;
`;

export const TileDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;

const integrationIcon = `
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eee;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const JOIcon = styled.span`
  ${integrationIcon};

  background: #f45730;
  color: #fff;
`;

export const DUIcon = styled.span`
  ${integrationIcon};

  background: #092343;
  color: #39cdbe;
`;

export const TMTIcon = styled.span`
  ${integrationIcon};

  background: #8d3bb6;
  color: #f0e0f9;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const Cancel = styled(Link)`
  display: flex;
  padding: 9px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #eee;
  cursor: pointer;
  color: #333;
  text-decoration: none;

  &:hover {
    color: #333;
    text-decoration: none;
  }
`;

export const Button = styled.button`
  display: flex;
  padding: 9px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ $color }) => $color || "#333"};
  background: ${({ $background }) => $background || "#ffe140"};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const DetailContent = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }

  .fz-simple-select__menu {
    width: 100%;
    max-width: 100%;
  }

  ${({ $isDisable }) =>
    $isDisable
      ? `
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      `
      : ""}}
`;

export const DetailTitle = styled.h2`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 8px;
`;
export const DetailSubTitle = styled.h3`
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
  margin-top: 0;

  span {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const DetailDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: ${({ $margin }) => $margin || ""};
`;

export const DropdownArea = styled.div`
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
`;

const input = `
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: block;

  &::placeholder {
    color: #a4a4a4;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: #f3f3f3;
  }

  &:focus {
    outline: 1px solid #408bfc;
  }
`;

export const Input = styled.input`
  ${input}
`;

export const InputIdMask = styled(InputMask)`
  ${input}
`;

export const CheckboxContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
`;

export const JobCreatedBanner = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f5f5f5;
  margin-bottom: 8px;
`;

export const JobCreatedBannerDescription = styled.div`
  color: #444;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const JobCreatedBannerFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;

export const JobCreatedBannerIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;

  img {
    max-width: 100%;
  }
`;

export const JobCreatedBannerTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-weight: 500;
`;

export const TextArea = styled.textarea`
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  height: 128px;
  resize: none;
  display: block;
  width: 100%;

  &::placeholder {
    color: #a4a4a4;
  }
`;

export const ContactFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const ContactItem = styled.div`
  width: 100%;
`;

export const ContactLabel = styled.label`
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
  display: block;
`;

export const ContactInput = styled.input`
  padding: 15px 16px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  width: 100%;
  display: block;

  &::placeholder {
    color: #a4a4a4;
  }

  &:focus {
    outline: 1px solid #408bfc;
  }
`;

export const OccupationContent = styled.div`
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
`;

export const OccupationHeading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const OccupationEdit = styled.button`
  color: #408bfc;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 0;
  background: transparent;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const OccupationTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px;
`;

export const OccupationTypes = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
`;

export const OccupationDescription = styled.p`
    color: #666
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
`;

export const RemoveEmployer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Error = styled.div`
  border-radius: 8px;
  border: 1px solid #ff2727;
  background: #fdf4f4;
  padding: 16px 24px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 32px;

  svg {
    width: 20px;
    height: 20px;
    fill: #e71d36;
  }

  ul {
    padding-left: 24px;
  }
`;

export const ErrorTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 4px 0;
`;

export const ErrorDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 4px 0;
`;

export const ErrorItem = styled.li`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 4px;
`;

export const ErrorFooter = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  a {
    color: #408bfc;
  }
`;

export const Info = styled.div`
  border-radius: 8px;
  background: #f3f8ff;
  padding: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
    fill: #408bfc;
  }
`;

export const InfoTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px 0;
`;

export const InfoDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;
