import axios from "axios";

// Campaigns
export const getCampaigns = ({ query }) => axios.get(`/api/v1/employers/campaigns?${query}`);

export const getAvailableJobForCandidate = candidateIds => axios.get(`/api/v1/employers/campaigns?${candidateIds}`);

export const archiveCampaign = id => axios.post(`/api/v1/employers/campaigns/${id}/archive`);

export const getActiveCampaigns = (employerId, candidateIds) =>
  axios.get(
    `/api/v1/employers/campaigns/active${employerId ? `?employer_id=${employerId}` : ""}${
      candidateIds ? `&${candidateIds}` : ""
    }`
  );

export const getActiveCompanies = () => axios.get("/api/v1/employers/companies/with_active_campaigns");

export const deleteCampaign = id => axios.post(`/api/v1/employers/campaigns/${id}/delete`);

export const getCommonCountries = () => axios.get("/api/v1/common/locations/countries");

export const getCommonCities = (countryId, search) =>
  axios.get(`/api/v1/common/locations/cities?country_id=${countryId}&query=${search}&context=employer`);

export const inviteCandidate = (campaignId, query) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/invitations/invite`, query);

export const getCampaignDetails = id => axios.get(`/api/v1/employers/campaigns/${id}`);

// Candidates
export const getCandidates = ({ id, query }) =>
  axios.get(`/api/v1/employers/campaigns/${id}/candidates${query ? `?${query}` : ""}`);

export const getReasons = id => axios.get(`/api/v1/employers/campaigns/${id}/candidates/phases/rejection_reasons`);

export const getIntegrations = id => axios.get(`/api/v1/employers/campaigns/${id}/integrations`);

export const getIntegrationStatus = (id, jobId) =>
  axios.get(`/api/v1/employers/campaigns/${id}/integrations/status?job_id=${jobId}`);

export const getEmployerNames = id => axios.get(`/api/v1/employers/campaigns/${id}/integrations/employer_names`);

export const getOccupationAutocomplete = (id, query) =>
  axios.get(`/api/v1/employers/campaigns/${id}/occupations/autocomplete?query=${query}`);

export const getOccupationSearch = (id, query) =>
  axios.get(`/api/v1/employers/campaigns/${id}/occupations/search?query=${query}?page=1&per_page=100`);

export const getTMTIntegrations = (id, promotionId) =>
  axios.get(`/api/v1/employers/campaigns/${id}/integrations/${promotionId}`);

export const createIntegration = (id, params) => axios.post(`/api/v1/employers/campaigns/${id}/integrations`, params);

export const updateIntegration = (id, params, promotionId) =>
  axios.patch(`/api/v1/employers/campaigns/${id}/integrations/${promotionId}`, params);

export const removeIntegration = (id, promotionId) =>
  axios.delete(`/api/v1/employers/campaigns/${id}/integrations/${promotionId}`);

export const getCandidateFilters = (id, query) =>
  axios.get(`/api/v1/employers/campaigns/${id}/candidates/filters?${query}`);

export const voteForCandidate = (actionType, campaignId, candidateId) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/${candidateId}/${actionType}`);

export const moveCandidates = (campaignId, phase, candidates) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/phases/${phase}/move`, {
    candidate_ids: candidates
  });

export const shortlistCandidates = (campaignId, candidates) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/phases/shortlist`, {
    candidate_ids: candidates
  });

export const rejectCandidates = (campaignId, candidates) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/phases/reject`, {
    candidate_ids: candidates
  });

// Heanhunt talents
export const getHeadhuntFilters = (id, query) =>
  axios.get(`/api/v1/employers/campaigns/${id}/headhunting_candidates/filters?${query}`);

export const getHeadhuntTalents = ({ id, query }) =>
  axios.get(`/api/v1/employers/campaigns/${id}/headhunting_candidates?${query}`);

export const getTalent = id => axios.get(`/api/v1/employers/headhunting_candidates/${id}`);

export const getTalentPersonality = id =>
  axios.get(`/api/v1/employers/headhunting_candidates/${id}/personality_results`);

export const moveTalents = (campaignId, phase, candidates) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/instant_phases/${phase}/move`, {
    candidate_ids: candidates
  });

export const shortlistTalents = (campaignId, candidates) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/instant_phases/shortlist`, {
    candidate_ids: candidates
  });

export const rejectTalents = (campaignId, candidates) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/instant_phases/reject`, {
    candidate_ids: candidates
  });

export const removeTalent = id => axios.delete(`/api/v1/employers/candidates/${id}/remove_headhunted`);

// CV page
export const getCandidateDetails = id => axios.get(`/api/v1/employers/candidates/${id}`);

export const getCandidateApplicationHistory = (id, page, query) =>
  axios.get(`/api/v1/employers/candidates/${id}/applications?page=${page}${query ? `&${query}` : ""}`);

export const getCandidatePersonality = id =>
  Promise.all([
    axios.get(`/api/v1/employers/candidates/${id}/personality_results`),
    axios.get(`/api/v1/employers/candidates/${id}/talent_results`)
  ]);

export const rateCandidate = (id, type = "like") => axios.post(`/api/v1/employers/candidates/${id}/${type}`);

export const candidateTag = (campaignId, candidateId, tag, type = "add") =>
  axios.post(`/api/v1/employers/candidates/${candidateId}/tags`, {
    campaign_id: campaignId,
    operation: type,
    tag
  });

export const getCandidateComments = (id, query) => {
  return axios.get(`/api/v1/employers/candidates/${id}/comments${query ? `?${query}` : ""}`);
};

export const createComment = (id, body, visibility) =>
  axios.post(`/api/v1/employers/candidates/${id}/comments`, { body, visibility });

export const editComment = (id, body, visibility, commentId) =>
  axios.patch(`/api/v1/employers/candidates/${id}/comments/${commentId}`, { body, visibility });

export const deleteComment = (candidateId, commentId) =>
  axios.delete(`/api/v1/employers/candidates/${candidateId}/comments/${commentId}`);

export const createPdfs = (campaignId, candidateIdList, isSelf = false) =>
  axios({
    method: "POST",
    url: `/api/v1/employers/campaigns/${campaignId}/candidates/batch_download`,
    responseType: "blob",
    data: {
      candidate_ids: candidateIdList,
      self_prepared: isSelf
    }
  });

export const exportCsv = (campaignId, candidateIdList) =>
  axios({
    method: "POST",
    url: `/api/v1/employers/campaigns/${campaignId}/candidates/export.csv`,
    responseType: "blob",
    data: {
      candidate_ids: candidateIdList
    }
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);
    link.click();
  });

// Collaboration
export const getCampaignCollaborators = campaignId =>
  axios.get(`/api/v1/employers/campaigns/${campaignId}/collaborators`);

export const changeCollaborationVisibility = (id, visibility) =>
  axios.post(`/api/v1/employers/campaigns/${id}/collaborators/change_visibility`, {
    collaboration_visibility: visibility
  });

export const inviteCollaborator = (id, email, message) =>
  axios.post(`/api/v1/employers/campaigns/${id}/collaborators/invite`, {
    email,
    message
  });

export const removeCollaborator = (campaignId, id) =>
  axios.delete(`/api/v1/employers/campaigns/${campaignId}/collaborators/${id}`);

// Tutorials

export const getTutorialVideos = () => axios.get("/api/v1/employers/tutorial_videos");

export const getTutorialSections = () => axios.get("/api/v1/employers/video_sections");

export const getSectionVideos = id => axios.get(`/api/v1/employers/video_sections/${id}/tutorial_videos`);

export const rejectMessageRequest = (campaignId, body) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/phases/reject`, body);

export const shortlistWithAssignments = (campaignId, body) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/phases/shortlist_with_assignments`, body);

export const shortlistWithAssignmentsTalents = (campaignId, body) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/candidates/instant_phases/shortlist_with_assignments`, body);

export const markAsViewedFastApply = () =>
  axios.post("/api/v1/employers/user_attributes/mark_fast_apply_tutorial_as_viewed");

const folder_path = "/api/v1/employers/headhunting/folders";

export const getFolderList = () => axios.get(folder_path);

export const createFolder = name =>
  axios.post(folder_path, {
    headhunting_folder: {
      name
    }
  });

export const addToFolder = (folderId, candidate_ids, stageId) =>
  axios.post(`${folder_path}/${folderId}/add`, {
    candidate_ids,
    stage_relation_id: stageId
  });

export const getGuestLinkContacts = campaignId =>
  axios.get(`/api/v1/employers/campaigns/${campaignId}/guest_links/search_contacts`);

export const createGuestLink = (campaignId, params) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/guest_links`, params);

export const resendGuestLink = (campaignId, token, params) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/guest_links/${token}/resend_invitation`, params);

export const changeGuestLink = (campaignId, tokenId, params) =>
  axios.patch(`/api/v1/employers/campaigns/${campaignId}/guest_links/${tokenId}`, params);

export const deleteGuestLink = (campaignId, tokenId) =>
  axios.delete(`/api/v1/employers/campaigns/${campaignId}/guest_links/${tokenId}`);

export const getGuestCandidates = (token, query = "") =>
  axios.get(`/api/v1/sharing/guest_links/${token}/candidates${query !== "" ? `?${query}` : ""}`);

export const getDetailShareCandidate = (token, id) => {
  return axios.get(`/api/v1/sharing/guest_links/${token}/candidate/${id}`);
};

export const getDetailShareCandidatePersonality = (token, id) => {
  return axios.get(`/api/v1/sharing/guest_links/${token}/candidate/${id}/personality_results`);
};

export const getDetailShareCandidateTalent = (token, id) => {
  return axios.get(`/api/v1/sharing/guest_links/${token}/candidate/${id}/talent_results`);
};

export const getDetailShareCandidateComments = (token, id) => {
  return axios.get(`/api/v1/sharing/guest_links/${token}/candidates/${id}/comments`);
};

export const createCommentShareCandidate = (token, id, body) => {
  return axios.post(`/api/v1/sharing/guest_links/${token}/candidates/${id}/comments`, { body, visibility: "public" });
};

export const deleteCommentShareCandidate = (token, id, commentId) => {
  return axios.delete(`/api/v1/sharing/guest_links/${token}/candidates/${id}/comments/${commentId}`);
};

export const editCommentShareCandidate = (token, id, commentId, body) => {
  return axios.patch(`/api/v1/sharing/guest_links/${token}/candidates/${id}/comments/${commentId}`, {
    body,
    visibility: "public"
  });
};

export const createShareCandidateUserForComment = (token, body) => {
  return axios.post("/api/v1/employers/signup/add_guest", {
    token,
    user: {
      first_name: body
    }
  });
};

export const getShareCandidatesInterviewNotes = (token, id) => {
  return axios.get(`/api/v1/sharing/guest_links/${token}/candidate/${id}/interview_notes`);
};

export const getShareCandidatesScreeningAnswers = (token, id) => {
  return axios.get(`/api/v1/sharing/guest_links/${token}/candidate/${id}/screening_answers`);
};

export const externalCandidateCheckEmail = (employerId, email) => {
  return axios.get("/api/v1/employers/prospects/exists", {
    params: {
      employer_id: employerId,
      email
    }
  });
};

export const createExternalCandidate = body => {
  return axios.post("/api/v1/employers/prospects", body);
};

export const createExternalCandidateAndApply = (campaignId, body) => {
  return axios.post(`/api/v1/employers/campaigns/${campaignId}/prospects`, body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const editExternalCandidateAndApply = (prospectId, body) => {
  return axios.patch(`/api/v1/employers/prospects/${prospectId}`, body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const applyExternalCandidate = (campaignId, id) => {
  return axios.post(`/api/v1/employers/campaigns/${campaignId}/prospects/${id}/apply`);
};

export const switchExternalCandidate = id => {
  return axios.post(`/api/v1/employers/candidates/${id}/switch_candidate`);
};

export const getSwitchInfo = id => {
  return axios.get(`/api/v1/employers/candidates/${id}/switch_info`);
};

export const getInterviewNotes = (id, page, filters) => {
  return axios.get(`/api/v1/employers/candidates/${id}/interview_notes?page=${page}${filters ? `&${filters}` : ""}`);
};

export const createInterviewNote = (id, body) => {
  return axios.post(`/api/v1/employers/candidates/${id}/interview_notes`, body);
};

export const editInterviewNote = (id, noteId, body) => {
  return axios.patch(`/api/v1/employers/candidates/${id}/interview_notes/${noteId}`, body);
};

export const deleteInterviewNote = (id, noteId) => {
  return axios.delete(`/api/v1/employers/candidates/${id}/interview_notes/${noteId}`);
};
