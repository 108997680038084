import React, { useState, useEffect, useMemo, useContext } from "react";

import emptyImage from "images/b2b/emptyInterviews.png";
import SelectView from "../../../Campaigns/components/SelectView";
import AppContext from "../../../../../contexts/init";
import { interviewTab, interviewEmployerValues } from "../../../../constants/campaign";
import InterviewItems from "../InterviewItems";
import {
  Content,
  Description,
  ImageContent,
  FlexHeading,
  ShowAllButton,
  Filters,
  FiltersItem,
  AddInterviewButton
} from "./styled";

const InterviewsTab = ({
  campaignName,
  isActiveInterviewModal,
  toggleInterviewModal,
  candidateName,
  getInterviewNotes,
  candidateId,
  interviewNotes,
  interviewNotesMeta,
  isSharedCandidates
}) => {
  const [employer, setEmployer] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentActions, setCurrentActions] = useState("");
  const [tab, setTab] = useState(interviewTab.all);
  const { current_employer: currentEmployer, current_user: currentUser } = useContext(AppContext);
  const totalCount = tab === interviewTab.myNotes ? interviewNotesMeta.user_count : interviewNotesMeta.total_count;
  const isLeadHr = currentUser?.roles?.includes("lead_hr");

  const numberOfNextCountInterview = useMemo(() => {
    const currentPerPage = 10;
    const countOfRestCandidates = totalCount - interviewNotes.length;
    return countOfRestCandidates >= currentPerPage ? currentPerPage : countOfRestCandidates;
  }, [interviewNotesMeta.total_count, interviewNotesMeta.user_count, interviewNotes.length, tab, totalCount]);

  const isShowLoadMoreButtons = useMemo(() => {
    const interviewNotesLength = interviewNotes?.length;
    const perPage = 10;

    return (interviewNotesLength && totalCount >= interviewNotesLength && totalCount > perPage) || currentPage > 1;
  }, [interviewNotesMeta.total_count, interviewNotesMeta.user_count, interviewNotes.length, tab, totalCount]);

  const employerOptions = useMemo(() => {
    const otherEmployerOption = isLeadHr
      ? { label: "Other employers", value: interviewEmployerValues.otherEmployers }
      : {};
    return [
      { label: "Interviews for this campaign", value: interviewEmployerValues.all },
      { label: `Other campaigns by ${campaignName}`, value: interviewEmployerValues.other },
      otherEmployerOption
    ];
  }, [campaignName]);

  const handleOpenInterviewModal = () => {
    setEmployer(employer);
    toggleInterviewModal(true, candidateId);
  };

  const handleChangeEmployer = value => {
    setEmployer(value);
    setCurrentPage(1);

    if (value !== employer) {
      getInterviewNotes(candidateId, 1, true, {
        my_notes: tab === interviewTab.myNotes,
        other_positions: interviewEmployerValues.other === value,
        other_employers: interviewEmployerValues.otherEmployers === value
      });
    }
  };

  const handleShowAllInterviews = () => {
    if (tab !== interviewTab.all) {
      setTab(interviewTab.all);
      setCurrentPage(1);

      getInterviewNotes(candidateId, 1, true, {
        my_notes: false,
        other_positions: interviewEmployerValues.other === employer,
        other_employers: interviewEmployerValues.otherEmployers === value
      });
    }
  };

  const handleShowMyInterviews = () => {
    if (tab !== interviewTab.myNotes) {
      setTab(interviewTab.myNotes);
      setCurrentPage(1);

      getInterviewNotes(candidateId, 1, true, {
        my_notes: true,
        other_positions: interviewEmployerValues.other === employer,
        other_employers: interviewEmployerValues.otherEmployers === value
      });
    }
  };

  const handlePaginationInterviews = () => {
    if (totalCount > interviewNotes.length) {
      getInterviewNotes(candidateId, currentPage + 1, false, {
        my_notes: tab === interviewTab.myNotes,
        other_positions: interviewEmployerValues.other === employer,
        other_employers: interviewEmployerValues.otherEmployers === value
      });
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGetFirstInterviews = () => {
    getInterviewNotes(candidateId, 1, true, {
      my_notes: tab === interviewTab.myNotes,
      other_positions: interviewEmployerValues.other === employer,
      other_employers: interviewEmployerValues.otherEmployers === value
    });
    setCurrentPage(1);
  };

  const renderEmptyText = () => {
    if (interviewEmployerValues.all === employer) {
      if (tab === interviewTab.myNotes && Boolean(interviewNotesMeta.total_count)) {
        return "No interview notes yet";
      }

      return `${candidateName} doesn't have interview notes yet. ${!isSharedCandidates ? "Be the first to write one." : ""}`;
    }

    return `No interview notes from other ${currentEmployer?.name} campaigns.`;
  };

  useEffect(() => {
    if (!employer) {
      setEmployer(employerOptions[0].value);
    }

    getInterviewNotes(candidateId, 1, true);
  }, []);

  return (
    <div className="fz-container-with-sidebar">
      <div className="fz-container-with-sidebar__content">
        <div>
          <div className="fz-pane">
            <div className="fz-pane__container fz-pane__container_activity">
              <FlexHeading>
                <div>
                  <h2 className="fz-heading-title">Interviews</h2>

                  {!isSharedCandidates && (
                    <Filters>
                      <FiltersItem $isActive={tab === interviewTab.all} onClick={handleShowAllInterviews}>
                        All {"total_count" in interviewNotesMeta && <span>{interviewNotesMeta.total_count}</span>}
                      </FiltersItem>
                      <FiltersItem $isActive={tab === interviewTab.myNotes} onClick={handleShowMyInterviews}>
                        My notes {"user_count" in interviewNotesMeta && <span>{interviewNotesMeta.user_count}</span>}
                      </FiltersItem>
                    </Filters>
                  )}
                </div>

                {!isSharedCandidates && (
                  <div>
                    <AddInterviewButton
                      $margin="0 0 24px auto"
                      $disabled={isActiveInterviewModal}
                      onClick={handleOpenInterviewModal}
                    >
                      Write an interview note
                    </AddInterviewButton>

                    <SelectView
                      width="320px"
                      options={employerOptions}
                      onSelect={handleChangeEmployer}
                      setCurrentActiveActions={setCurrentActions}
                      currentActiveActions={currentActions}
                      initial={employer}
                      isNewPopup
                      isAddDots
                    />
                  </div>
                )}
              </FlexHeading>

              {interviewNotes.length ? (
                <>
                  <InterviewItems
                    candidateId={candidateId}
                    isShowPreviewModalWhenCancel={false}
                    isSharedCandidates={isSharedCandidates}
                  />
                  {isShowLoadMoreButtons ? (
                    <>
                      {totalCount > interviewNotes.length ? (
                        <ShowAllButton onClick={handlePaginationInterviews}>
                          View {numberOfNextCountInterview}
                        </ShowAllButton>
                      ) : (
                        <ShowAllButton onClick={handleGetFirstInterviews}>Hide</ShowAllButton>
                      )}
                    </>
                  ) : null}
                </>
              ) : (
                <Content>
                  <ImageContent>
                    <img src={emptyImage} alt="empty" />
                  </ImageContent>
                  <Description>{renderEmptyText()}</Description>

                  {!isSharedCandidates && (
                    <AddInterviewButton $disabled={isActiveInterviewModal} onClick={handleOpenInterviewModal}>
                      Write an interview note
                    </AddInterviewButton>
                  )}
                </Content>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewsTab;
