import React, { useState } from "react";

import PersonalityScore from "b2c/pages/ProfileScreen/components/PersonalityScore";
import emptyPersonalty from "images/b2b/empty-personality.png";
import emptyTalent from "images/b2b/empty-talent.png";
import { Content, Description, ImageContent } from "./styled";
import TalentTest from "../../../../components/TalentTest";
import { ShowMore } from "../../../ApplicantCvScreen/components/UserCvTalentProfile/styled";

export const PersonalityTalent = ({ talentResults, personality, name, isHeadHunting }) => {
  const isNotEmptyPersonality = Object.keys(personality.personalityResults)?.length;
  const isNotEmptyTalents = talentResults.results.length;
  const [showMore, setShowMore] = useState(false);
  const talents = showMore ? talentResults.results : talentResults.results.slice(0, 5);

  const toggleShowMore = () => {
    setShowMore(prev => !prev);
  };

  const containerClassName =
    talentResults.results.length > 5 ? "fz-pane__container_talent-profile" : "fz-pane__container_case-two";

  return (
    <>
      {isNotEmptyPersonality ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Personality profile</h2>
            {personality.personalityResults.map(({ mean, score, headline, bottom_text, top_text, description }, i) => (
              <PersonalityScore
                key={`${headline}_${i}`}
                title={headline}
                userScore={(score - 0.5) * 200}
                averageScore={(mean - 50) * 2}
                bottomText={bottom_text}
                topText={top_text}
                description={description}
                headHuntingName={name}
              />
            ))}
          </div>
        </div>
      ) : null}

      {isNotEmptyTalents && !isHeadHunting ? (
        <div className="fz-pane">
          <div className={`fz-pane__container ${containerClassName}`}>
            <h2 className="fz-heading-title">Talent profile</h2>
            {talents.map(item => (
              <TalentTest {...item} key={`talent_${item.test_id}`} />
            ))}
            {talentResults.results.length > 5 && (
              <ShowMore onClick={toggleShowMore}>
                {showMore ? "Show less" : <>{`Show all ${talentResults.results.length} tests`}</>}
              </ShowMore>
            )}
          </div>
        </div>
      ) : null}

      {!isNotEmptyPersonality ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Personality profile</h2>
            <Content>
              <ImageContent>
                <img src={emptyPersonalty} alt="empty" />
              </ImageContent>
              <Description>{name} hasn’t taken the personality test yet</Description>
            </Content>
          </div>
        </div>
      ) : null}

      {!isNotEmptyTalents && !isHeadHunting ? (
        <div className="fz-pane">
          <div className="fz-pane__container fz-pane__container_case-two">
            <h2 className="fz-heading-title">Talent profile</h2>
            <Content>
              <ImageContent>
                <img src={emptyTalent} alt="empty" />
              </ImageContent>
              <Description>{name} hasn’t taken any tests yet</Description>
            </Content>
          </div>
        </div>
      ) : null}
    </>
  );
};
