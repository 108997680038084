import React from "react";
import cn from "classnames";

import disabilityThumbnail from "images/disablity.jpg";
import { DisabilityImage } from "./styled";

const TutorialCard = ({ title, description, source, thumbnail, link, disabilityInclusion, flatView, ...props }) => {
  const wrapperClassNames = cn("fz-tutorial-card fadeInFromBottom", {
    "fz-tutorial-card_flat": flatView
  });
  return (
    <div className={wrapperClassNames} {...props}>
      <div className="fz-tutorial-card__content">
        {}
        {!disabilityInclusion ? (
          <video
            className="fz-tutorial-card__video"
            poster={thumbnail}
            width="560"
            height="315"
            controls
            allowFullScreen
          >
            <source src={source} type="video/mp4" />
          </video>
        ) : (
          <a href={link} target="_blank" rel="noreferrer">
            <DisabilityImage src={disabilityThumbnail} alt="placeholder" width="515px" height="315px" />
          </a>
        )}
      </div>
      <div className="fz-tutorial-card__info">
        <h5 className="fz-tutorial-card__title mt-0">{title}</h5>
        {description && <p className="fz-text fz-text_normal fz-text-color__black fz-lh_1-4 m-0">{description}</p>}
      </div>
    </div>
  );
};

export default TutorialCard;
