import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { MdMoreHoriz } from "react-icons/md";
import cn from "classnames";

import ProfileAvatar from "components/ProfileAvatar";
import { SelectedListInitials } from "./styled";

const UserPane = ({
  additionalActions,
  status,
  name,
  info,
  lastName = "",
  avatar,
  inactive,
  external,
  setCurrentActiveActions,
  currentActiveActions,
  isNewPopup,
  backgroundColor,
  isNewEmptyAvatar
}) => {
  const [activeActions, setActiveActions] = useState(false);
  const dropdown = useRef();

  const getInitials = () => {
    return `${name.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const wrapperClassNames = cn("fz-user-bar", {
    "fz-user-bar_inactive": inactive
  });

  const toggleActiveActions = () => {
    if (activeActions) {
      setActiveActions(false);
      setCurrentActiveActions("");
    } else {
      setActiveActions(true);
      setCurrentActiveActions(name);
    }
  };

  return (
    <div className={wrapperClassNames}>
      {isNewEmptyAvatar ? (
        <SelectedListInitials
          style={{
            backgroundColor
          }}
        >
          {getInitials()}
        </SelectedListInitials>
      ) : (
        <div className="fz-user-bar__avatar">
          <ProfileAvatar avatar={avatar} letter={external ? info.charAt(0) : null} />
        </div>
      )}

      <div className="fz-user-bar__content">
        {name && !external && <h5 className="fz-user-bar__title">{name}</h5>}
        {info && <p className="fz-user-bar__text">{info}</p>}
      </div>
      {status && <div className="fz-user-bar__status">{status}</div>}
      {additionalActions && (
        <div className="fz-user-bar__actions">
          {isNewPopup ? (
            <div className="fz-dropdown fz-dropdown__new-container">
              <button type="button" className="fz-simple-btn fz-simple-btn_lightgray" onClick={toggleActiveActions}>
                <MdMoreHoriz className="fz-simple-btn__icon m-0" />
              </button>

              <div ref={dropdown} className="fz-dropdown__additional-content">
                {activeActions && currentActiveActions === name && <>{additionalActions}</>}
              </div>
            </div>
          ) : (
            <Popup
              className="fz-dropdown"
              trigger={
                <button type="button" className="fz-simple-btn fz-simple-btn_lightgray">
                  <MdMoreHoriz className="fz-simple-btn__icon m-0" />
                </button>
              }
              position="bottom right"
              arrow={false}
              keepTooltipInside
              closeOnEscape
            >
              {additionalActions}
            </Popup>
          )}
        </div>
      )}
    </div>
  );
};

UserPane.propTypes = {
  inactive: PropTypes.bool,
  name: PropTypes.string,
  info: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node])
};

UserPane.defaultProps = {
  inactive: false,
  name: null,
  info: null,
  status: null
};

export default UserPane;
