import React, { useState } from "react";
import cn from "classnames";
import { MdForward } from "react-icons/md";

import { capitalize } from "utils/helpers";
import Subscription from "b2b/components/Subscription";

const MoveToDropdown = ({ options = [], onSelect, isShowListByDefault }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const handleSelect = value => {
    onSelect(value);
    setIsOpenDropdown(false);
  };

  if (isShowListByDefault) {
    return (
      <div className="fz-dropdown-nav" role="listbox">
        {options.map(({ value, label }) => (
          <button type="button" key={`move_${value}`} className="fz-dropdown-itm" onClick={() => handleSelect(value)}>
            <span className="fz-dropdown-itm__text">{capitalize(label)}</span>
          </button>
        ))}
      </div>
    );
  }

  const onMouseEnterBarHandle = () => {
    setIsOpenDropdown(true);
  };
  const onMouseLeaveBarHandle = () => {
    setIsOpenDropdown(false);
  };

  return (
    <div className="fz-dropdown" onMouseLeave={onMouseLeaveBarHandle}>
      <Subscription.Payment element="Candidate pipeline">
        <button type="button" className={cn("fz-dropdown-itm")} onMouseEnter={onMouseEnterBarHandle}>
          <MdForward className="fz-dropdown-itm__icon" />
          <span className="fz-dropdown-itm__text">Move to</span>
        </button>
      </Subscription.Payment>
      {isOpenDropdown && (
        <div className="fz-dropdown-nav fz-dropdown-nav_absolute" role="listbox">
          {options.map(({ value, label }) => (
            <button type="button" key={`move_${value}`} className="fz-dropdown-itm" onClick={() => handleSelect(value)}>
              <span className="fz-dropdown-itm__text">{capitalize(label)}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MoveToDropdown;
