import React from "react";

import { Modal, Title, Description, Actions, Cancel, Post } from "./styled";

const PublicComment = ({ handleClick, close }) => {
  const handlePost = () => {
    handleClick();
    close();
  };

  return (
    <Modal>
      <Title>Post public comment?</Title>
      <Description>
        This comment will be visible to everyone who can see this page, including team members, collaborators, and other
        users, using the guest link.
      </Description>
      <Actions>
        <Cancel onClick={close}>Cancel</Cancel>
        <Post onClick={handlePost}>Post</Post>
      </Actions>
    </Modal>
  );
};

export default PublicComment;
