import React, { useContext } from "react";
import AppContext from "contexts/init";
import UserCard from "../UserCard";
import TalentCard from "../TalentCard";

import Layout from "../Layout";
import InviteToApplyContent from "../InviteToApplyContent";
import SelectJobForInviteContent from "../SelectJobForInviteContent";
import { getCurrentUserAndEmployer } from "../../utils/helpers";

const TalentList = ({
  tab,
  loading = true,
  page = 1,
  per_page = 10,
  talents = [],
  handleSelection,
  selectedTalents = [],
  removed = [],
  getComments,
  addComment,
  removeComment,
  removeFromFolder,
  addTag,
  removeTag,
  unlockUser,
  handleStopUpdatePage,
  isShowMove,
  stageRelationsOptions,
  moveToStage,
  activeFolderStageId,
  createShareCandidateUserForComment,
  createCommentShareCandidate
}) => {
  const { current_employer: currentEmployer, current_user: currentUser } = useContext(AppContext);

  const handleOpenSelectJobForInvite = (toggle, ids) => {
    toggle(
      <SelectJobForInviteContent
        selectedTalents={ids}
        handleInviteToApply={() => handleInviteToApply(toggle, ids)}
        handleClose={() => handleInviteToApply(toggle, ids)}
      />,
      "Select job",
      false
    );
  };

  const handleInviteToApply = (toggle, ids) => {
    toggle(
      <InviteToApplyContent
        currentId={ids}
        currentUserAndEmployer={getCurrentUserAndEmployer(currentEmployer, currentUser)}
        selectedCandidatesIds={ids}
        toggle={toggle}
        talents={talents}
        handleInviteToApply={() => handleInviteToApply(toggle, ids)}
        addToJob={() => handleOpenSelectJobForInvite(toggle, ids)}
      />,
      "Invite talent to apply"
    );
  };

  return (
    <>
      {loading ? (
        [...Array(+per_page).keys()].map(key => <UserCard.Placeholder key={`jb_p${key}`} />)
      ) : talents.length > 0 ? (
        talents.map((talent, index) => (
          <TalentCard
            {...talent}
            tab={tab}
            key={`${talent.name}-${talent.id}`}
            index={index + 1 + per_page * (page - 1)}
            options={[]}
            removed={removed.includes(talent.id)}
            select={() => handleSelection(talent.id)}
            selected={selectedTalents.includes(talent.id)}
            removeFromFolder={folder => removeFromFolder(folder, [talent.id])}
            getComments={getComments}
            addComment={addComment}
            removeComment={removeComment}
            addTag={addTag}
            talent={talent}
            profileUnlocked={talent.profile_unlocked}
            removeTag={removeTag}
            selectedTalents={selectedTalents}
            handleInviteToApply={handleInviteToApply}
            unlockUser={unlockUser}
            handleStopUpdatePage={handleStopUpdatePage}
            isShowMove={isShowMove}
            stageRelationsOptions={stageRelationsOptions}
            moveToStage={moveToStage}
            activeFolderStageId={activeFolderStageId}
            createShareCandidateUserForComment={createShareCandidateUserForComment}
            createCommentShareCandidate={createCommentShareCandidate}
          />
        ))
      ) : (
        <Layout.EmptyState showImage title="No talents match this criteria" />
      )}
    </>
  );
};

export default TalentList;
