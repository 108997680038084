import React, { useState, useMemo, useRef, useEffect } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import Button from "components/Button";

const Carousel = ({ children }) => {
  const [enabled, setState] = useState(false);
  const content = useRef();
  const item = useRef();

  const scrollCarousel = (right = true) => {
    if (enabled) {
      setState(false);
      right
        ? (content.current.scrollLeft -= item.current.offsetWidth)
        : (content.current.scrollLeft += item.current.offsetWidth);
      setTimeout(() => setState(true), 600);
    }
  };

  const childCount = React.Children.count(children);

  const leftEnabled = useMemo(() => content.current && content.current.scrollLeft > 10, [content.current, enabled]);

  const rightEnabled = useMemo(
    () => content.current && content.current.scrollLeft < content.current.offsetWidth * (childCount - 1) - 10,
    [content.current, enabled]
  );

  useEffect(() => {
    setState(true);
  }, []);

  return (
    <div className="fz-carousel">
      {leftEnabled && (
        <Button.Iconed className="arrow-btn right" color="gray" onClick={scrollCarousel}>
          <MdKeyboardArrowLeft />
        </Button.Iconed>
      )}
      <div
        className="content"
        ref={content}
        style={{
          width: `${childCount * 100}%`
        }}
      >
        {React.Children.map(children, (child, i) => (
          <div key={`carousel_item_${i}`} ref={item} className="item">
            {child}
          </div>
        ))}
      </div>
      {rightEnabled && (
        <Button.Iconed className="arrow-btn left" color="gray" onClick={() => scrollCarousel(false)}>
          <MdKeyboardArrowRight />
        </Button.Iconed>
      )}
    </div>
  );
};

export default Carousel;
