import React from "react";

import Layout from "../../../../components/Layout";
import OpenApplicationList from "../OpenApplicationsList";

const OpenApplications = () => {
  return (
    <Layout.Container type="wide">
      <OpenApplicationList />
    </Layout.Container>
  );
};

export default OpenApplications;
