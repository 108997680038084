import React from "react";
import Tag from "../Tag";

export const RenderTags = ({ list = [], remove, removable, isActive }) => {
  return list.map((tag, index) => (
    <Tag
      key={`tag-${tag}-${index}`}
      name={tag}
      isCandidateTag
      rounded
      isActive={isActive}
      removable={
        !/talent|open application|assignments requested|assignments completed|invited to apply|applied from invite/.test(
          tag.toLowerCase()
        ) || removable
      }
      onRemove={() => remove(tag)}
      size="md"
      color={
        /talent|open application|assignments requested|assignments completed|invited to apply|applied from invite/.test(
          tag.toLowerCase()
        )
          ? "accent"
          : ""
      }
    />
  ));
};
