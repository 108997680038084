import styled, { css } from "styled-components";

export const Folder = styled.div`
  padding: 13px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background-color: #fff;
  transition: all 0.3s ease;

  svg {
    opacity: 0;
    width: 16px;
    fill: #408bfc;
  }

  &:hover {
    background-color: #e0edff;
    border-color: #e0edff;
    h5 {
      color: #408bfc;
    }
    svg {
      opacity: 1;
    }
  }
`;

export const FolderTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
  margin: 0;
  transition: all 0.3s ease;
`;

const ButtonStyle = css`
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  background-color: #408bfc;
  border-radius: 8px;
  padding: 6px 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  ${({ $styled }) => $styled}
`;

export const FolderButton = styled.button`
  opacity: 0;
  position: relative;

  ${ButtonStyle}
`;

export const CreateFolder = styled.button`
  ${ButtonStyle}
  width: max-content;
  margin: 24px auto 0;
`;

export const CreateAndSave = styled.button`
  ${ButtonStyle}
  width: max-content;
  margin-left: 10px;
`;

export const iconChecked = {
  height: "32px",
  width: "32px",
  padding: "10px",
  fill: "#408bfc"
};

export const Company = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 24px 0;
  padding: 12px 0;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% + 52px);
    left: -26px;
    height: 1px;
    background-color: #eeeeee;
  }

  &:after {
    bottom: 0;
  }

  &:before {
    top: 0;
  }
`;

export const CompanyImage = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;

  img {
    max-width: 100%;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const CompanyTitle = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin: 0 10px 0 0;
`;

export const CompanyTalent = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  display: block;
  margin: 0 0 0 10px;
`;

export const MoveToStage = styled.div`
  width: 100%;
  min-width: 300px;

  @media screen and (min-width: 768px) {
    min-width: 420px;
  }
`;

export const Title = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  margin: 0;
  display: block;
  margin-top: -40px;
  margin-bottom: 20px;
`;

export const TitleBack = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  margin: 0;
  margin-top: -40px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin-bottom: 20px;

  svg {
    width: 20px;
  }
`;

export const Stages = styled.div`
  margin: 0 -26px;
`;

export const Stage = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
  padding: 8px 26px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  position: relative;

  &:first-child {
    border-top: 1px solid #eeeeee;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #a4a4a4;
    position: absolute;
    right: 26px;
    top: 50%;
    margin-top: -8px;
    opacity: 1;
  }

  button {
    opacity: 0;
  }

  &:hover {
    background-color: #e0edff;
    border-color: #e0edff;
    h5 {
      color: #408bfc;
    }
    button {
      opacity: 1;
      z-index: 1;
    }

    span {
      opacity: 0;
    }
  }
`;

export const StageTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
  margin: 0;
  transition: all 0.3s ease;
`;
