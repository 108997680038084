import styled from "styled-components";

export const Modal = styled.div`
  max-height: 90vh;
`;

export const CountryContainer = styled.div`
  height: 41.6px;
  width: 100%;

  .select__indicator-separator,
  .select__indicator {
    display: none;
  }

  .select__control {
    background: #f3f3f3;
    border: none;
  }
`;
