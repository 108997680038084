import { makeAutoObservable } from "mobx";
import * as API from "../utils/jobsApi";

const defaultDisabilityVideos = [
  {
    description:
      "The goal of the module is to introduce the topic of disability and why disability makes business sense.",
    employer_video_section_id: 3,
    id: 100,
    disability_inclusion: true,
    title: "Disability Inclusion at Workplace – Module 1: Disability inclusion makes business sense",
    video_url: null,
    video_link: "https://assets.fuzu.com/learning/lc_kenya_module%201_scorm/index.html"
  },
  {
    description:
      "The goal of the module is to give frameworks and guidelines through which to approach disability employment. It includes information on international and national legislation, as well as an introduction to reasonable accommodation and accessibility.",
    employer_video_section_id: 3,
    id: 101,
    disability_inclusion: true,
    title: "Disability Inclusion at Workplace – Module 2: Guidelines for an accessible and inclusive workplace",
    video_url: null,
    video_link: "https://assets.fuzu.com/learning/lc_kenya_module%202_scorm/index.html"
  },
  {
    description:
      "The goal of this module is to leave people with practical, actionable tips that they can take now to become more accessible and inclusive. It includes information on etiquette and language, as well as actions you can take in the workplace now to be more inclusive and accessible. Following, the module lists role-based actions which highlight actions for specific roles, e.g., for human resources, recruiters, mid-level managers, and senior leaders.",
    employer_video_section_id: 3,
    id: 102,
    disability_inclusion: true,
    title: "Disability Inclusion at Workplace – Module 3: The inclusive workplace in practice",
    video_url: null,
    video_link: "https://assets.fuzu.com/learning/lc_kenya_module%203_scorm/index.html"
  }
];

class Onboarding {
  constructor() {
    makeAutoObservable(this);
  }

  state = {
    loading: false,
    message: "",
    error: false,
    type: ""
  };

  videos = {
    all: null
  };

  sections;

  initializeTutorials = () => {
    this.state.loading = true;
    return Promise.all([
      !this.videos.all ? API.getTutorialVideos() : Promise.resolve(),
      !this.sections ? API.getTutorialSections() : Promise.resolve()
    ])
      .then(res => {
        if (res[0]) this.videos.all = [...res[0].data.tutorial_videos, ...defaultDisabilityVideos];
        if (res[1])
          this.sections = [
            ...res[1].data.video_sections,
            {
              id: 3,
              name: "Disability Inclusion in the Workplace"
            }
          ];

        this.state = {
          loading: false,
          message: "",
          error: false,
          type: "all_fetched"
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          message: "Failed to retireve tutorial videos",
          error: true,
          type: "all_failure"
        };
      });
  };

  getSectionVideos = (id, section) => {
    if (!this.videos[section]) {
      if (section === "disability_inclusion_in_the_workplace") {
        this.videos[section] = defaultDisabilityVideos;

        this.state = {
          loading: false,
          message: "",
          error: false,
          type: `${section}_fetched`
        };
      } else {
        this.state.loading = true;
        return API.getSectionVideos(id)
          .then(res => {
            this.videos[section] = res.data.section_videos;

            this.state = {
              loading: false,
              message: "",
              error: false,
              type: `${section}_fetched`
            };
          })
          .catch(() => {
            this.state = {
              loading: false,
              message: "Failed to retireve tutorial videos",
              error: true,
              type: `${section}_failure`
            };
          });
      }
    }
  };
}

export default new Onboarding();
