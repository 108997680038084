import styled from "styled-components";

export const TopContent = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ShareShortlisted = styled.button`
  width: 170px;
  height: 36px;
  background: #f3f3f3;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 20px;
`;

export const IconShare = styled.span`
  transform: rotateY(180deg);
`;

const promotionStyles = `
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PromotionActive = styled.span`
  ${promotionStyles};
  border: 1px solid #c5c5c5;
  margin-right: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: #666;
  }
`;

export const PromotionExpired = styled.span`
  ${promotionStyles};
  border: 1px solid #f3a900;

  svg {
    width: 16px;
    height: 16px;
    fill: #f3a900;
  }
`;

export const PromotionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .tooltipTrigger {
    cursor: pointer;

    svg:hover {
      fill: #408bfc;
    }
  }
`;

export const TooltipContent = styled.div`
  width: 280px;
`;

export const tooltipContentStyles = {
  width: "13px",
  height: "22px"
};
