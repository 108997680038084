import React from "react";
import { useHistory } from "react-router-dom";

import { Text } from "../../../b2c/components/Core";
import CreateLoginFlowWrapper from "../../components/CreateLoginFlowWrapper";
import { Form, StyledButton } from "./styled";

const UsedResetLink = () => {
  const history = useHistory();

  const handleSubmission = e => {
    e.preventDefault();
    history.replace("/forgot-password");
  };

  return (
    <CreateLoginFlowWrapper
      title="Reset password link is invalid"
      description={
        <div>
          <Text fontSize={14} fontWeight={400} width="100%" margin="14px 0 0 0" textAlign="center">
            It seems you have already used this link. Please request a new password reset and try
          </Text>
          <Text fontSize={14} fontWeight={400} width="100%" textAlign="center">
            {"again. If you need any support, please contact "}
            <a href="mailto:business@fuzu.com">
              <Text
                fontSize={14}
                fontWeight={600}
                style={{
                  display: "inline",
                  color: "#0e50b5"
                }}
              >
                business@fuzu.com.
              </Text>
            </a>
          </Text>
        </div>
      }
    >
      <Form onSubmit={handleSubmission} noValidate>
        <StyledButton type="submit" fontSize="14px" margin="15px 0" width="100%" height="48px">
          Request a new password reset
        </StyledButton>
      </Form>
    </CreateLoginFlowWrapper>
  );
};

export default UsedResetLink;
