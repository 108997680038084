import React, { useRef } from "react";
import Select from "react-select";
import { MdClose, MdSearch } from "react-icons/md";
import { Container, Close, SearchIcon } from "./styles";

const SelectSearch = ({
  className = "fz-simple-select",
  options = [],
  defaultValue,
  value,
  onInputChange,
  inputValue,
  onSelect,
  isHideCloseIcon
}) => {
  const wrapper = useRef();

  const handleSelect = e => {
    onSelect(e);
  };

  const handleRemove = () => {
    onSelect("");
    onInputChange("");
  };

  return (
    <Container>
      <SearchIcon>
        <MdSearch />
      </SearchIcon>
      <Select
        className={className}
        classNamePrefix="select"
        placeholder={defaultValue}
        isClearable
        isSearchable
        options={options}
        onChange={handleSelect}
        onInputChange={onInputChange}
        inputValue={inputValue}
        value={value}
        ref={wrapper}
      />
      {!isHideCloseIcon && (
        <Close onClick={handleRemove}>
          <MdClose />
        </Close>
      )}
    </Container>
  );
};

export default SelectSearch;
