import React, { useState, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { InfoCircle } from "@styled-icons/boxicons-regular";
import { MdInsertLink, MdPhone } from "react-icons/md";
import { toJS } from "mobx";

import { validateNumber } from "utils/validation";
import {
  Modal,
  StepCount,
  Title,
  Description,
  SubTitle,
  Content,
  Label,
  Input,
  Actions,
  Cancel,
  Next,
  Back,
  LoadingContent,
  InputContent,
  FormContent,
  TooltipText,
  Flex,
  FoundUserContent,
  FoundUserTitle,
  FoundUserHeading,
  FoundUserFooter,
  FoundUserAvatar,
  FoundUserName,
  FoundUserEmail,
  Dot,
  FoundUserLocation,
  FoundUserFooterItem,
  FoundUserCheckbox,
  Required,
  Relative,
  RequiredPhone,
  CityContainer
} from "./styled";
import Loading from "../../../../../../assets/images/loading.svg";
import Tooltip from "../../../../../components/Tooltip";
import FileUploader from "../../../../components/FileUploader";
import Checkbox from "../../../../components/Checkbox";
import { theme } from "../../../../../b2c/contexts/theme";
import SelectSearch from "../../../Campaigns/components/SelectSearch";

const steps = {
  email: "email",
  form: "form"
};

const AddCandidateFlow = ({
  CampaignsStore: {
    externalCandidateCheckEmail,
    isLoadingExternalCandidateCheckEmail,
    externalCandidateFoundUser,
    externalCandidateFormErrors,
    createExternalCandidateAndApply,
    applyExternalCandidate,
    editExternalCandidateAndApply,
    selectedCampaign
  },
  Settings: { getPhoneCodes, codes },
  MyJobsStore: { getCommonCountries, commonCountries, getCommonCities, commonCities, clearCommonCities },
  close,
  editForm,
  isEdit
}) => {
  const [step, setStep] = useState("");
  const [isFoundUser, setIsFoundUser] = useState(false);
  const [citySearchValue, setCitySearchValue] = useState("");
  const [isSelectUser, setIsSelectUser] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    fuzuCountryId: "",
    locationId: "",
    code: "",
    number: "",
    linkedIn: "",
    website: "",
    source: "",
    currentTitle: "",
    cvUpload: "",
    email: ""
  });

  const isRequiredPhone = formValue.code && !formValue.number;
  const isDisabledFormSubmit = !formValue.firstName || !formValue.lastName || !formValue.email || isRequiredPhone;

  const isEmailStep = useMemo(() => {
    return step === steps.email;
  }, [step]);

  const stepCountText = isEmailStep ? "1" : "2";

  const handleSubmitEmail = () => {
    externalCandidateCheckEmail(selectedCampaign.employer_id, formValue.email).then(res => {
      if (res?.exists) {
        setIsFoundUser(true);
      }

      if (res) {
        setStep(steps.form);
      }
    });
  };

  const handleSubmitFoundUser = () => {
    applyExternalCandidate(selectedCampaign.id, externalCandidateFoundUser.prospect.id).then(res => {
      if (res?.data) {
        close();
      }
    });
  };

  const handleCitySearch = value => {
    setCitySearchValue(value);
  };

  const validation = useMemo(() => {
    const number = formValue.number ? !validateNumber(formValue.number).test : false;
    const firstName = isEdit && !formValue.firstName;
    const lastName = isEdit && !formValue.lastName;

    return { number, firstName, lastName };
  }, [formValue.number, formValue.firstName, formValue.lastName]);

  const handleSubmitForm = () => {
    const formData = new FormData();
    formData.append("first_name", formValue.firstName);

    if (!isEdit) {
      formData.append("email", formValue.email);
    }

    formData.append("last_name", formValue.lastName);

    if (formValue.fuzuCountryId) {
      formData.append("fuzu_country_id", String(formValue.fuzuCountryId.value));
    }

    if (formValue.locationId) {
      formData.append("geoname_id", String(formValue.locationId.value));
    }

    if (formValue.currentTitle) {
      formData.append("current_title", formValue.currentTitle);
    }

    if (formValue.cvUpload && typeof formValue.cvUpload !== "string") {
      formData.append("file", formValue.cvUpload);
    }

    if (formValue.source) {
      formData.append("source", formValue.source);
    }

    if (formValue.linkedIn) {
      formData.append("links[linkedin]", formValue.linkedIn);
    }

    if (formValue.website) {
      formData.append("links[website]", formValue.website);
    }

    if (formValue.code && formValue.number) {
      formData.append("phone[code]", formValue.code.value);
    }

    if (formValue.number) {
      formData.append("phone[number]", formValue.number);
    }

    if (isEdit) {
      editExternalCandidateAndApply(editForm.prospectId, formData).then(res => {
        if (res.data) {
          close();
        }
      });
    } else {
      createExternalCandidateAndApply(selectedCampaign.id, formData).then(res => {
        if (res.data) {
          close();
        }
      });
    }
  };

  const handleBack = () => {
    setStep(steps.email);

    if (isFoundUser) {
      setIsFoundUser(false);
    }
  };

  const handleChangeEmailValue = event => {
    setFormValue(prev => ({ ...prev, email: event.target.value }));
  };

  useEffect(() => {
    if (!step && !isEdit) {
      setStep(steps.email);
    }

    if (isEdit && !step) {
      setStep(steps.form);
    }

    if (isEdit && editForm) {
      setFormValue(prev => ({ ...prev, ...editForm }));
    }

    if (!codes) {
      getPhoneCodes();
    }

    if (!commonCountries.length) {
      getCommonCountries();
    }

    return () => {
      clearCommonCities();
    };
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (citySearchValue.length >= 3) {
        getCommonCities(formValue.fuzuCountryId.value, citySearchValue);
      }
    }, 800);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [citySearchValue]);

  const renderEmailStep = () => {
    if (isLoadingExternalCandidateCheckEmail) {
      return <EmailLoading />;
    }

    return (
      <EmailStep
        error={externalCandidateFormErrors.email}
        handleChangeEmailValue={handleChangeEmailValue}
        emailValue={formValue.email}
      />
    );
  };

  const renderFormStep = () => {
    if (externalCandidateFoundUser.exists) {
      return (
        <FoundUser
          isSelectUser={isSelectUser}
          setIsSelectUser={setIsSelectUser}
          user={externalCandidateFoundUser.prospect}
          error={externalCandidateFormErrors.prospect_id}
        />
      );
    }

    return (
      <Form
        formValue={formValue}
        setFormValue={setFormValue}
        countries={commonCountries}
        codes={codes}
        validation={validation}
        errors={externalCandidateFormErrors}
        isEdit={isEdit}
        citySearchValue={citySearchValue}
        handleCitySearch={handleCitySearch}
        towns={toJS(commonCities)}
      />
    );
  };

  return (
    <Modal>
      {!isEdit && <StepCount>Step {stepCountText} of 2</StepCount>}
      <Title>{!isEdit ? "Add a candidate" : "Edit external candidate"}</Title>
      {!isEdit && (
        <Description>Add candidates you found on LinkedIn or other sources to your hiring pipeline.</Description>
      )}

      <Content>{isEmailStep ? <>{renderEmailStep()}</> : <>{renderFormStep()}</>}</Content>

      <Actions>
        {!isEmailStep && !isEdit && <Back onClick={handleBack}>Back</Back>}
        {!externalCandidateFormErrors.prospect_id && <Cancel onClick={close}>Cancel</Cancel>}

        {isEmailStep ? (
          <Next
            isDisabled={!formValue.email || isLoadingExternalCandidateCheckEmail}
            onClick={handleSubmitEmail}
            background={theme.primaryButtonBackground}
            color={theme.primaryButtonLabelColor}
          >
            Next
          </Next>
        ) : (
          <>
            {isFoundUser ? (
              <>
                {externalCandidateFormErrors.prospect_id ? (
                  <Next
                    isDisabled={!isSelectUser}
                    onClick={close}
                    background={theme.primaryButtonBackground}
                    color={theme.primaryButtonLabelColor}
                  >
                    Ok
                  </Next>
                ) : (
                  <Next
                    isDisabled={!isSelectUser}
                    onClick={handleSubmitFoundUser}
                    background={theme.primaryButtonBackground}
                    color={theme.primaryButtonLabelColor}
                  >
                    Next
                  </Next>
                )}
              </>
            ) : (
              <Next
                isDisabled={isDisabledFormSubmit}
                onClick={handleSubmitForm}
                background={theme.primaryButtonBackground}
                color={theme.primaryButtonLabelColor}
              >
                {isEdit ? "Save" : "Next"}
              </Next>
            )}
          </>
        )}
      </Actions>
    </Modal>
  );
};

const EmailLoading = () => {
  return (
    <LoadingContent>
      <Loading className="spin" />
      Checking the email...
    </LoadingContent>
  );
};

const EmailStep = ({ handleChangeEmailValue, emailValue, error }) => {
  return (
    <>
      <SubTitle>Provide a candidate email first</SubTitle>
      <Label>Candidate email</Label>
      <Input
        type="email"
        placeholder="example@fuzu.com"
        onChange={handleChangeEmailValue}
        value={emailValue}
        isError={error}
      />
      {error && <Required>{error}</Required>}
    </>
  );
};

const FoundUser = ({ isSelectUser, setIsSelectUser, user, error }) => {
  const {
    first_name: firstName,
    last_name: lastName,
    name,
    current_position_title: positionTitle,
    country,
    details: { email, phone_number: phoneNumber, links }
  } = user;
  const initialsName = `${firstName[0]}${lastName[0]}`;

  return (
    <>
      {error ? (
        <FoundUserTitle isError>This external candidate is already added to this job</FoundUserTitle>
      ) : (
        <FoundUserTitle>We found a manually added candidate with this email:</FoundUserTitle>
      )}

      <FoundUserContent isSelectUser={isSelectUser}>
        {!error && (
          <FoundUserCheckbox>
            <Checkbox
              checked={isSelectUser}
              onCheck={() => setIsSelectUser(true)}
              onUncheck={() => setIsSelectUser(false)}
              isGray
            />
          </FoundUserCheckbox>
        )}

        <FoundUserHeading>
          <FoundUserAvatar>{initialsName}</FoundUserAvatar>
          <div>
            <FoundUserName>{name}</FoundUserName>
            <FoundUserEmail>{email}</FoundUserEmail>
            <FoundUserLocation>
              {positionTitle && (
                <>
                  {positionTitle}
                  <Dot />
                </>
              )}
              {country && <>{country}</>}
            </FoundUserLocation>
          </div>
        </FoundUserHeading>
        <FoundUserFooter>
          {links && links.linkedin && (
            <FoundUserFooterItem>
              <MdInsertLink /> {links.linkedin}
            </FoundUserFooterItem>
          )}

          {links && links.website && (
            <FoundUserFooterItem>
              <MdInsertLink /> {links.website}
            </FoundUserFooterItem>
          )}

          {phoneNumber && (
            <FoundUserFooterItem>
              <MdPhone /> {phoneNumber}
            </FoundUserFooterItem>
          )}
        </FoundUserFooter>
      </FoundUserContent>
    </>
  );
};

const Form = ({
  formValue,
  setFormValue,
  countries,
  codes,
  validation,
  errors,
  isEdit,
  citySearchValue,
  handleCitySearch,
  towns
}) => {
  const [countrySearchValue, setCountrySearchValue] = useState("");
  const [codeSearchValue, setCodeSearchValue] = useState("");

  const handleCountrySearch = value => {
    setCountrySearchValue(value);
  };

  const handleCodeSearch = value => {
    setCodeSearchValue(value);
  };

  const handleChangeSelect = (value, name) => {
    setFormValue(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const handleUploadCv = file => {
    setFormValue(prev => {
      return {
        ...prev,
        cvUpload: file
      };
    });
  };

  const handleChangeValue = event => {
    const { name, value } = event.target;
    setFormValue(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  return (
    <FormContent>
      <SubTitle>Candidate details</SubTitle>
      <InputContent>
        <Label>First name</Label>
        <Input
          name="firstName"
          value={formValue.firstName}
          type="text"
          placeholder="First name"
          onChange={handleChangeValue}
          isError={validation.firstName}
        />
        {validation.firstName && <Required>First name is required</Required>}
      </InputContent>
      <InputContent>
        <Label>Last name</Label>
        <Input
          name="lastName"
          value={formValue.lastName}
          type="text"
          placeholder="Last name"
          onChange={handleChangeValue}
          isError={validation.lastName}
        />
        {validation.lastName && <Required>Last name is required</Required>}
      </InputContent>
      <InputContent>
        <Label>
          Candidate email
          <Tooltip
            classNamePopup="fz-tooltip-content_modal"
            tooltipPosition={["right center"]}
            tooltipContent={
              <TooltipText>
                You can’t edit the email to avoid creating duplicate profiles. To change the email, go back to the
                previous step
              </TooltipText>
            }
          >
            <InfoCircle />
          </Tooltip>
        </Label>
        <Input disabled type="email" value={formValue.email} />
      </InputContent>

      <Flex>
        <div>
          <Label>
            Country <span>(Optional)</span>
          </Label>

          <CityContainer>
            <SelectSearch
              options={countries ? countries.map(item => ({ value: item.id, label: item.name })) : []}
              style={{ height: "54px" }}
              value={formValue.fuzuCountryId}
              defaultValue="Select Country"
              onInputChange={handleCountrySearch}
              onSelect={event => handleChangeSelect(event, "fuzuCountryId")}
              inputValue={countrySearchValue}
              noOptionsMessage={() => null}
              isShowClose={false}
            />
          </CityContainer>
        </div>
        <div>
          <Label>
            City <span>(Optional)</span>
          </Label>

          <CityContainer>
            <SelectSearch
              style={{ height: "54px" }}
              value={formValue.locationId}
              defaultValue="Select City"
              options={towns.length ? towns.map(item => ({ value: item.id, label: item.name })) : []}
              onInputChange={handleCitySearch}
              onSelect={event => handleChangeSelect(event, "locationId")}
              inputValue={citySearchValue}
              noOptionsMessage={() => "Select country first"}
              isShowClose={false}
            />
          </CityContainer>
        </div>
      </Flex>

      <Flex>
        <Relative>
          <Label>
            Phone number <span>(Optional)</span>
          </Label>

          <CityContainer>
            <SelectSearch
              options={
                codes ? codes.map(item => ({ value: item.phone_code, label: `${item.name} (${item.phone_code})` })) : []
              }
              style={{ height: "54px" }}
              value={formValue.code}
              defaultValue="Select Code"
              onSelect={event => handleChangeSelect(event, "code")}
              noOptionsMessage={() => null}
              isShowClose={false}
              onInputChange={handleCodeSearch}
              inputValue={codeSearchValue}
            />
          </CityContainer>
        </Relative>
        <Relative>
          <Input
            type="text"
            name="number"
            placeholder="XX XXX XXXX"
            value={formValue.number}
            onChange={handleChangeValue}
            isError={validation.number || errors?.phone?.number}
          />
          {errors?.phone?.number && <RequiredPhone>{errors?.phone?.number[0]}</RequiredPhone>}
        </Relative>
      </Flex>

      <InputContent>
        <Label>
          Current job title <span>(Optional)</span>
        </Label>
        <Input
          name="currentTitle"
          type="text"
          placeholder="E.g. Sales manager"
          value={formValue.currentTitle}
          onChange={handleChangeValue}
        />
      </InputContent>
      <InputContent>
        <Label>
          CV file <span>(Optional)</span>
        </Label>
        <FileUploader
          accept=".pdf, .doc, .docx"
          onUpload={handleUploadCv}
          isEdit={isEdit && typeof formValue.cvUpload === "string"}
          editFileData={
            isEdit && typeof formValue.cvUpload === "string" ? { name: formValue.cvUpload, size: 2222 } : {}
          }
        />
      </InputContent>

      <InputContent>
        <Label>
          LinkedIn <span>(Optional)</span>
        </Label>
        <Input
          type="text"
          placeholder="www.linkedin.com"
          name="linkedIn"
          value={formValue.linkedIn}
          onChange={handleChangeValue}
          isError={errors?.links?.linkedin}
        />
        {errors?.links?.linkedin && <Required>{errors?.links?.linkedin[0]}</Required>}
      </InputContent>

      <InputContent>
        <Label>
          Website <span>(Optional)</span>
        </Label>
        <Input
          name="website"
          type="text"
          placeholder="www.example.com "
          value={formValue.website}
          onChange={handleChangeValue}
          isError={errors?.links?.website}
        />
        {errors?.links?.website && <Required>{errors?.links?.website[0]}</Required>}
      </InputContent>

      <InputContent>
        <Label>
          Source <span>(Optional)</span>
          <Tooltip
            classNamePopup="fz-tooltip-content_modal"
            tooltipPosition={["right center"]}
            tooltipContent={
              <TooltipText>You can use this field to add a note on where you found this candidate.</TooltipText>
            }
          >
            <InfoCircle />
          </Tooltip>
        </Label>
        <Input
          name="source"
          type="text"
          placeholder="Where did you find this candidate?"
          value={formValue.source}
          onChange={handleChangeValue}
        />
      </InputContent>
    </FormContent>
  );
};

export default inject("CampaignsStore", "Settings", "MyJobsStore")(observer(AddCandidateFlow));
