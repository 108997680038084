import React, { useContext, useMemo } from "react";
import { MdSave } from "react-icons/md";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import AppContext from "contexts/init";
import TabNavigation from "../../../../components/TabNavigation";
import Layout from "../../../../components/Layout";
import SearchBar from "../SearchBar";

const HeadhuntingHeading = ({ mode, searchTab, reset }) => {
  const { pathname = "" } = useLocation();
  const { unread_open_applications } = useContext(AppContext);

  const tabItems = useMemo(() => {
    const items = [
      {
        name: "Find talent",
        to: "/headhunting/find",
        count: 0,
        active: pathname.includes("/find")
      },
      {
        name: "Talent pool",
        to: "/headhunting/saved",
        count: 0,
        active: pathname.includes("/saved")
      },
      {
        name: "Open applications talent",
        to: "/headhunting/open",
        count: unread_open_applications || 0,
        active: pathname.includes("/open"),
        permission: "open_applications"
      }
    ];

    if (searchTab)
      items.push({
        name: "Saved searches",
        to: "/headhunting/searches",
        active: pathname.includes("/searches"),
        count: 0,
        icon: <MdSave />
      });

    return items;
  }, [pathname, searchTab]);

  return (
    <Layout.PageHeading
      className={cn("fz-page-heading_animated", {
        "fz-page-heading_tall": pathname === "/headhunting/find" && !mode
      })}
    >
      <Layout.Container type={!(pathname === "/headhunting/find" && !mode) ? "wide" : "default"}>
        {pathname === "/headhunting/find" && !mode && <h1 className="fz-title mt-0 mb-2">Search for talent</h1>}
        <SearchBar checkType={!(pathname === "/headhunting/find" && !mode)} />
        <TabNavigation prevent={false} onChange={reset} className="mt-4" tabItems={tabItems} />
      </Layout.Container>
    </Layout.PageHeading>
  );
};

export default HeadhuntingHeading;
